import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="fillCam" d="M0 0H26V26H0z" />
      </Defs>
      <G
        transform="translate(7 7)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="fillCam11" fill={props.color2 || "#fff"}>
          <Use xlinkHref="#fillCam" />
        </Mask>
        <G mask="url(#fillCam11)">
          <Path d="M0 0H26V26H0z" />
          <Path
            d="M13 17.875a3.25 3.25 0 10-3.25-3.25 3.26 3.26 0 003.25 3.25zm0-9.75a6.5 6.5 0 11-6.5 6.5 6.52 6.52 0 016.5-6.5zM22.75 26H3.25a3.208 3.208 0 01-2.275-.975A3.208 3.208 0 010 22.75V8.125a3.26 3.26 0 013.25-3.25h2.6l2.438-2.438a2.691 2.691 0 011.95-.812h5.687a2.61 2.61 0 011.787.813l2.438 2.437h2.6A3.26 3.26 0 0126 8.125V22.75A3.26 3.26 0 0122.75 26z"
            fill={props.color || "#fff"}
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
