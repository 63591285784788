import React, { useContext, useEffect } from "react";
import { View, Image, Platform, Pressable } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartContext from "@presto-contexts/CartContext";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { useSafeArea } from "react-native-safe-area-context";
import _ from "lodash";
import DrawerSectionLink from "@presto-components/SectionLinks/DrawerSectionLink";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import SessionManager from "../../services/features/session/SessionManager";
import { VerticalSpacing } from "@presto-components/Spacing";
import { useMediaQuery } from "react-responsive";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import config from "@presto-common/config";
import * as Linking from "expo-linking";
import I18n from "i18n-js";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import { useAssets } from "presto-react-components";
import utils from "../../utils/index";

const Drawer = createDrawerNavigator();

function PrestoDrawer({}) {
  const { svgs, images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { refreshCart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const insets = useSafeArea();
  const isDesktop = useMediaQuery({ minWidth: 941 });
  const onLogout = () => {
    goHome();

    setTimeout(() => {
      SessionManager.logout(
        () => {
          setUser(null);
          setPaneState(false);
          if (isDesktop) {
            WebNavigator.emit({
              event: "replace",
              params: {
                screenName: "HOME_SCREEN",
              },
            });
          } else {
            Navigator.emit({
              event: "switchDrawerItem",
              params: { tabName: "HOME" },
            });
          }
          //refreshCart();
        },
        () => {}
      );
    }, 1000);
  };

  const showLogin = () => {
    toggleDrawer();
    Navigator.emit({
      event: "modal",
      params: { screenName: "LOGIN" },
    });
  };

  const goHome = () => {
    toggleDrawer();
    Navigator.emit({
      event: "switchDrawerItem",
      params: { tabName: "HOME" },
    });
  };

  const goToCategories = () => {
    toggleDrawer();
    Navigator.emit({
      event: "modal",
      params: { screenName: "CATEGORIES" },
    });
  };

  const goOffers = () => {
    toggleDrawer();
    Navigator.emit({
      event: "modal",
      params: { tabName: "OFFERS", screenName: "OFFERS_SCREEN" },
    });
  };
  const goNotifications = () => {
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { tabName: "NOTIFICATIONS", screenName: "NOTIFICATIONS_SCREEN" },
    });
  };

  const goToProfile = () => {
    toggleDrawer();
    if (!user) {
      showLogin();
      return;
    }

    Navigator.emit({
      event: "modal",
      params: { screenName: "PROFILE_SCREEN" },
    });
  };

  const goToOrders = () => {
    toggleDrawer();
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { tabName: "MY_ORDERS", screenName: "MY_ORDERS" },
    });
  };

  const goToWallet = () => {
    toggleDrawer();
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { tabName: "WALLET", screenName: "WALLET_SCREEN" },
    });
  };

  const goToContactUs = () => {
    toggleDrawer();
    Navigator.emit({
      event: "modal",
      params: { tabName: "CONTACT", screenName: "CONTACT_SCREEN" },
    });
  };

  const goToReferAndEarn = () => {
    toggleDrawer();
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { tabName: "REFER", screenName: "REFERRAL_SCREEN" },
    });
  };

  const goToNotifications = () => {
    toggleDrawer();
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { tabName: "REFER", screenName: "NOTIFICATIONS_SCREEN" },
    });
  };

  const goToGeneralStatements = () => {
    toggleDrawer();
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { screenName: "GENERAL_STATEMENT_SCREEN" },
    });
  };

  const goToPresto = () => {
    Platform.OS === "web"
      ? window.open(config.constants.presto_website, "_blank")
      : Linking.openURL(config.constants.presto_website);
  };

  const goToAboutFarm = () => {
    toggleDrawer();
    setPaneState(false);
    if (isDesktop) {
      WebNavigator.emit({
        event: "push",
        params: { screenName: "aboutus" },
      });
    } else {
      Navigator.emit({
        event: "modal",
        params: { screenName: "aboutus" },
      });
    }
  };

  const toggleDrawer = () => {
    Navigator.emit({
      event: "toggleDrawer",
    });
  };

  return (
    <DrawerContentScrollView showsVerticalScrollIndicator={false}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginVertical: 20,
        }}
      >
        <Image
          source={images.Logo}
          style={{
            height: 70,
            width: 70,
            alignSelf: "center",
            marginVertical: 10,
          }}
        />
      </View>
      {!user ? (
        <DrawerSectionLink
          theme={theme}
          leftText="Login"
          leftIcon={
            <svgs.UserOutline
              style={{ marginRight: 5 }}
              width="38"
              height="38"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={showLogin}
        />
      ) : null}
      {isDesktop ? null : (
        <DrawerSectionLink
          theme={theme}
          leftText={I18n.t("screen_messages.category.title")}
          leftIcon={
            <svgs.CategoriesIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => goToCategories()}
        />
      )}

      {user && !isDesktop ? (
        <DrawerSectionLink
          theme={theme}
          leftText={I18n.t("screen_messages.users.profile")}
          leftIcon={
            <svgs.UserOutline
              borderColor={theme.dark}
              strokeWidth={1.5}
              style={{ marginRight: 5 }}
              width="38"
              height="38"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => goToProfile()}
        />
      ) : null}
      {user ? (
        <DrawerSectionLink
          theme={theme}
          leftText={I18n.t("screen_messages.orders.my_orders")}
          leftIcon={
            <svgs.TransactionIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => goToOrders()}
        />
      ) : null}
      {user && !isDesktop ? (
        <DrawerSectionLink
          theme={theme}
          leftText={I18n.t("screen_messages.wallet.wallet_text")}
          leftIcon={
            <svgs.LineWalletIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => goToWallet()}
        />
      ) : null}

      {user ? (
        <DrawerSectionLink
          theme={theme}
          leftText={_.capitalize(I18n.t("screen_messages.offers.offers"))}
          leftIcon={
            <svgs.Offers
              style={{ marginRight: 10 }}
              width="32"
              height="32"
              color={"#333"}
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => goOffers()}
        />
      ) : null}
      {user && utils.isMobile() ? (
        <DrawerSectionLink
          theme={theme}
          leftText={I18n.t("screen_messages.notifications.title")}
          leftIcon={
            <svgs.Notifications
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => goToNotifications()}
        />
      ) : null}

      {user ? (
        <>
          <DrawerSectionLink
            theme={theme}
            leftText={I18n.t("screen_messages.refer_and_earn.title")}
            leftIcon={
              <svgs.Refer style={{ marginRight: 10 }} width="32" height="32" />
            }
            rightIcon={<svgs.RightArrow width="40" height="40" />}
            onPressButton={() => goToReferAndEarn()}
          />

          <DrawerSectionLink
            theme={theme}
            leftText={I18n.t("screen_messages.general_statement.title")}
            leftIcon={
              <svgs.ReportsIcon
                style={{ marginRight: 10 }}
                color={theme.dark}
                width="32"
                height="32"
              />
            }
            rightIcon={<svgs.RightArrow width="40" height="40" />}
            onPressButton={() => goToGeneralStatements()}
          />
        </>
      ) : null}
      <DrawerSectionLink
        theme={theme}
        leftText={I18n.t("screen_messages.contact.title")}
        leftIcon={
          <svgs.ContactUs style={{ marginRight: 10 }} width="32" height="32" />
        }
        rightIcon={<svgs.RightArrow width="40" height="40" />}
        onPressButton={() => goToContactUs()}
      />

      <DrawerSectionLink
        theme={theme}
        leftText={I18n.t("screen_messages.common.about_us")}
        leftIcon={
          <svgs.InfoIcon style={{ marginRight: 10 }} width="32" height="32" />
        }
        rightIcon={<svgs.RightArrow width="40" height="40" />}
        onPressButton={() => goToAboutFarm()}
      />

      {user ? (
        <DrawerSectionLink
          theme={theme}
          leftText="Logout"
          leftIcon={
            <svgs.Logout style={{ marginRight: 10 }} width="32" height="32" />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => onLogout()}
        />
      ) : null}
      <VerticalSpacing size={200} />
      <Pressable onPress={() => goToPresto()}>
        <Image
          source={images.PoweredByPrestoIcon}
          style={{
            height: 20,
            width: 120,
            alignSelf: "center",
            marginBottom: 50,
          }}
          resizeMode="contain"
        />
      </Pressable>
    </DrawerContentScrollView>
  );
}

export default function DrawerScreen(props, navigation) {
  const { screens, tabConfigs } = props.route.params;
  const items = _.zip(screens, tabConfigs);
  const { user, authState } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  useEffect(() => {
    if (navigation) {
      if (authState === AuthState.userLoggedOut) {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "LOGGED_OUT",
          },
        });
      }
    }
  }, [authState, navigation]);
  const isDesktop = useMediaQuery({ minWidth: 941 });
  return (
    <Drawer.Navigator
      openByDefault={isDesktop}
      drawerType={isDesktop ? "permanent" : "front"}
      drawerStyle={{ width: isDesktop ? "100%" : 310 }}
      drawerContent={(props) => (
        <PrestoDrawer {...props} configs={tabConfigs} />
      )}
    >
      {_.map(items, ([screen, tabConfig]) => {
        return (
          <Drawer.Screen
            key={tabConfig.screen}
            name={tabConfig.name}
            children={(props) => screen}
          ></Drawer.Screen>
        );
      })}
    </Drawer.Navigator>
  );
}
