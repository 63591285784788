import * as React from "react";
import Svg, { Polygon, G } from "react-native-svg";

function BuposClose({ width, height, color = "#707070" }) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="bupos-close-icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/close" fill={color} fill-rule="nonzero">
          <Polygon
            id="bupos-close-Union_3"
            points="20.097625 22.6605 8.562875 34.1970625 6 31.632375 17.53475 20.097625 6 8.562875 8.562875 6 20.097625 17.53475 31.632375 6 34.1970625 8.562875 22.6605 20.097625 34.19525 31.632375 31.6305625 34.1970625"
          ></Polygon>
        </G>
      </G>
    </Svg>
  );
}

export default BuposClose;
