import * as React from "react";
import Svg, { G, Path, Defs, Mask, Use, Ellipse } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Ellipse
          stroke={props.color || "#707070"}
          strokeWidth={1.8}
          cx={20}
          cy={28}
          rx={15.6}
          ry={7.1}
        />
        <Path fill={props.color2 || "#FFF"} d="M10 17H31V24H10z" />
        <G transform="translate(7 3)">
          <Mask id="b" fill={props.color2 || "#FFF"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <G transform="translate(.5)">
              <Path fill="none" d="M0 0H29V29H0z" />
              <Path
                d="M13.193 17.255a5.05 5.05 0 005.06-5.059 5.206 5.206 0 00-5.06-5.24 5.05 5.05 0 00-5.06 5.06 5.332 5.332 0 005.06 5.239zM4.7 3.524c4.69-4.69 12.295-4.69 16.985 0 4.69 4.69 4.69 12.294 0 16.984L13.193 29 4.7 20.508C.183 15.748.183 8.284 4.7 3.523z"
                fill={props.color || "#707070"}
                fillRule="evenodd"
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
