import React, { useContext } from "react";
import { View, StatusBar } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import DrawerSectionLink from "@presto-components/SectionLinks/DrawerSectionLink";
import * as Linking from "expo-linking";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import TopHeaderWithTitle from "@presto-screen-components/Headers/TopHeaderWithTitle";

export default function Contact({ navigation }) {
  const { svgs } = useAssets();
  const insets = useSafeAreaInsets();
  const { theme } = useContext(ThemeContext);
  const onClckMail = () => {
    Linking.openURL(`mailto:${I18n.t("screen_messages.contact.email")}`);
  };
  const onClickWhatsapp = () => {
    Linking.openURL(
      `whatsapp://send?phone=${I18n.t("screen_messages.contact.phone_number")}`
    );
  };
  const onClickPhoneNumber = () => {
    Linking.openURL(`tel:${I18n.t("screen_messages.contact.phone_number")}`);
  };
  return (
    <View
      style={{
        backgroundColor: theme.appBackgroundColor,
        flex: 1,
      }}
    >
      <View style={{ top: insets.top, backgroundColor: "transparent" }}>
        <StatusBar barStyle="dark-content" />
      </View>
      <TopHeaderWithTitle title={"Contact Us"} />
      <View
        style={{ paddingHorizontal: theme.containerPadding, marginTop: 25 }}
      >
        <PrestoText color={theme.darkText} fontStyle="400.normal">
          {I18n.t("screen_messages.contact.description")}
        </PrestoText>
      </View>
      <View style={{ marginTop: 30 }}>
        <DrawerSectionLink
          theme={theme}
          borderColor="#CCCCCC"
          leftText={I18n.t("screen_messages.contact.via_whatsapp")}
          leftIcon={
            <svgs.WhatsappIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => onClickWhatsapp()}
        />
        <DrawerSectionLink
          theme={theme}
          borderColor="#CCCCCC"
          leftText={I18n.t("screen_messages.contact.via_email")}
          leftIcon={
            <svgs.EmailIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => onClckMail()}
        />
        <DrawerSectionLink
          theme={theme}
          borderColor="#CCCCCC"
          leftText={I18n.t("screen_messages.contact.customer_care")}
          leftIcon={
            <svgs.CallOutlineIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightArrow width="40" height="40" />}
          onPressButton={() => onClickPhoneNumber()}
        />
      </View>
    </View>
  );
}
