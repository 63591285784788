import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function ActionCompletedIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke={props.fill ?? "#00AF62"}
          strokeWidth="0.9"
          fill={props.fill ?? "#00AF62" }
          cx={20}
          cy={20}
          r={17.55}
        />
        <Path
          stroke="#FFF"
          strokeWidth={3.6}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 20.5L17 27 31 14"
        />
      </G>
    </Svg>
  );
}

export default ActionCompletedIcon;
