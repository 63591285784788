import * as React from "react"
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"

function CouponIconFill(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.width || 40}
      viewBox="0 0 40.0 40.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <ClipPath id="cfi-1">
          <Path d="M31 0l-.512 15.311-15.142 14.843a2.964 2.964 0 01-4.172-.026L.856 19.757a2.933 2.933 0 01.01-4.15L15.973.562 31 0z" />
        </ClipPath>
        <ClipPath id="cfi-2">
          <Path d="M9.385.255c.31.31.339.794.084 1.135l-.084.098-7.89 7.856a.878.878 0 01-1.239 0 .87.87 0 01-.084-1.135l.084-.098L8.146.255a.878.878 0 011.239 0z" />
        </ClipPath>
        <ClipPath id="cfi-3">
          <Path d="M9.385.255c.31.31.339.794.084 1.135l-.084.098-7.89 7.856a.878.878 0 01-1.239 0 .87.87 0 01-.084-1.135l.084-.098L8.146.255a.878.878 0 011.239 0z" />
        </ClipPath>
        <ClipPath id="cfi-4">
          <Path d="M2.736 0a2.73 2.73 0 012.736 2.724 2.73 2.73 0 01-2.736 2.724A2.73 2.73 0 010 2.724 2.73 2.73 0 012.736 0zm0 1.09c-.907 0-1.642.731-1.642 1.634 0 .903.735 1.635 1.642 1.635.907 0 1.642-.732 1.642-1.635S3.643 1.09 2.736 1.09z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#cfi-1)" transform="translate(4 5)">
        <Path fill={props.color || "#707070"} d="M0 0L31 0 31 31 0 31 0 0z" />
      </G>
      <G clipPath="url(#cfi-2)" transform="translate(4 5) translate(6.271 10.952)">
        <Path
          fill={"#FFF"} 
          d="M-5.55111512e-17 0L9.64111994 0 9.64111994 9.59915675 -5.55111512e-17 9.59915675 -5.55111512e-17 0z"
        />
      </G>
      <G clipPath="url(#cfi-3)" transform="translate(4 5) translate(10.285 14.981)">
        <Path
          fill={"#FFF"}
          d="M0 0L9.64111994 0 9.64111994 9.59915675 0 9.59915675 0 0z"
        />
      </G>
      <G clipPath="url(#cfi-4)" transform="translate(4 5) translate(21.776 3.54)">
        <Path
          fill={"#FFF"}
          d="M0 0L5.47210551 0 5.47210551 5.44828805 0 5.44828805 0 0z"
        />
      </G>
    </Svg>
  )
}

export default CouponIconFill
