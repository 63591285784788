import * as React from "react"
import Svg, { Rect } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect
        transform="translate(10 19) rotate(90 10 1)"
        x={9}
        y={-9}
        width={2}
        height={20}
        rx={1}
        fill="#CCC"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default SvgComponent
