import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use, Rect } from "react-native-svg";

function CalendarIconWhiteFilled(props) {
  const fillColor = props?.fillColor || "#FFFFFF";
  const rectStrokeColor = props?.rectStrokeColor || "#707070";
  const rectFillColor = props?.rectFillColor || "#707070";

  return (
    <>
      <Svg
        width={props.width || 40}
        height={props.height || 40}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
      >
        <Defs>
          <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
        </Defs>
        <G
          id="icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <G id="Calendar" transform="translate(5.000000, 6.000000)">
            <Mask id="mask-2" fill="white">
              <Use xlinkHref="#path-1"></Use>
            </Mask>
            <G id="Rectangle"></G>
            <G mask="url(#mask-2)">
              <G transform="translate(-0.003090, -0.003090)">
                <Rect
                  id="Rectangle_557"
                  stroke="none"
                  fill="none"
                  x="0.00308998349"
                  y="0.00308998349"
                  width="29"
                  height="29"
                ></Rect>
                <Path
                  d="M23.56559,3.62808998 L27.19059,3.62808998 C27.6797173,3.59868797 28.1580154,3.78018544 28.504505,4.12667501 C28.8509945,4.47316459 29.032492,4.95146262 29.00309,5.44058998 L29.00309,27.19059 C29.032492,27.6797173 28.8509945,28.1580154 28.504505,28.504505 C28.1580154,28.8509945 27.6797173,29.032492 27.19059,29.00309 L1.81558998,29.00309 C1.32646262,29.032492 0.848164589,28.8509945 0.501675014,28.504505 C0.155185438,28.1580154 -0.0263120297,27.6797173 0.00308998349,27.19059 L0.00308998349,5.44058998 C-0.0263120297,4.95146262 0.155185438,4.47316459 0.501675014,4.12667501 C0.848164589,3.78018544 1.32646262,3.59868797 1.81558998,3.62808998 L5.44058998,3.62808998 L5.44058998,1.81558998 C5.41118797,1.32646262 5.59268544,0.848164589 5.93917501,0.501675014 C6.28566459,0.155185438 6.76396262,-0.0263120297 7.25308998,0.00308998349 C7.74221734,-0.0263120297 8.22051538,0.155185438 8.56700495,0.501675014 C8.91349453,0.848164589 9.094992,1.32646262 9.06558998,1.81558998 L9.06558998,3.62808998 L19.94059,3.62808998 L19.94059,1.81558998 C19.94059,0.814573874 20.7520739,0.00308998349 21.75309,0.00308998349 C22.7541061,0.00308998349 23.56559,0.814573874 23.56559,1.81558998 L23.56559,3.62808998 Z"
                  id="Path_149"
                  stroke="none"
                  fill={fillColor}
                  fillRule="evenodd"
                ></Path>
                <Rect
                  id="Rectangle"
                  stroke={rectStrokeColor}
                  strokeWidth="1"
                  fill={rectFillColor}
                  fillRule="evenodd"
                  x="13.00309"
                  y="12.00309"
                  width="3"
                  height="3"
                ></Rect>
                <Rect
                  id="Rectangle-Copy-2"
                  stroke={rectStrokeColor}
                  strokeWidth="1"
                  fill={rectFillColor}
                  fillRule="evenodd"
                  x="13.00309"
                  y="19.00309"
                  width="3"
                  height="3"
                ></Rect>
                <Rect
                  id="Rectangle-Copy-4"
                  stroke={rectStrokeColor}
                  strokeWidth="1"
                  fill={rectFillColor}
                  fillRule="evenodd"
                  x="8.00308998"
                  y="19.00309"
                  width="3"
                  height="3"
                ></Rect>
                <Rect
                  id="Rectangle-Copy-3"
                  stroke={rectStrokeColor}
                  strokeWidth="1"
                  fill={rectFillColor}
                  fillRule="evenodd"
                  x="18.00309"
                  y="19.00309"
                  width="3"
                  height="3"
                ></Rect>
                <Rect
                  id="Rectangle-Copy"
                  stroke={rectStrokeColor}
                  strokeWidth="1"
                  fill={rectFillColor}
                  fillRule="evenodd"
                  x="18.00309"
                  y="12.00309"
                  width="3"
                  height="3"
                ></Rect>
              </G>
            </G>
          </G>
        </G>
      </Svg>
    </>
  );
}

export default CalendarIconWhiteFilled;
