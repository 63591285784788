import React, { useContext } from "react";
import { View } from "react-native";

import ThemeContext from "@presto-contexts/ThemeContext";
import { FontAwesome } from "@expo/vector-icons";

export default function SelectedCheckBox(props) {
  const { theme } = useContext(ThemeContext);
  const size = props.size || 26;
  const gap = props.gap || 5;
  const { checkBoxProps } = props;

  const innerCircleSize = size - gap;
  return (
    <View
      style={{
        width: size,
        height: size,
        backgroundColor: theme.white,
        borderRadius: size,
        borderWidth: props.borderWidth || 2,
        borderColor:
          checkBoxProps?.borderColor || props.borderColor || theme.secondary,
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        ...props.style,
      }}
    >
      <View
        style={{
          backgroundColor:
            checkBoxProps?.iconColor || props.iconColor || theme.primary,
          width: innerCircleSize,
          height: innerCircleSize,
          borderRadius: innerCircleSize / 2,
        }}
      />
    </View>
  );
}
