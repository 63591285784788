import React, { useContext } from "react";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function SavingsText({ children, savingsColor }) {
  const { theme } = useContext(ThemeContext);
  return (
    <PrestoText
      fontStyle="400.normal"
      size={14}
      color={savingsColor || theme.tertiary}
      extraStyle={{ marginTop: 2 }}
    >
      {children}
    </PrestoText>
  );
}
