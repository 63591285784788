import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="Cal1" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 6)"
        stroke="none"
        strokeWidth={0}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="Cal2" fill={props.color || "#fff"}>
          <Use xlinkHref="#Cal1" />
        </Mask>
        <G mask="url(#Cal2)">
          <G transform="translate(-.003 -.003)">
            <Path
              fill="none"
              d="M0.00308998349 0.00308998349H29.00308998349V29.00308998349H0.00308998349z"
            />
            <Path
              d="M3.628 9.066v16.312h21.75V9.066H3.628zm19.938-5.438h3.625a1.713 1.713 0 011.812 1.813v21.75a1.713 1.713 0 01-1.812 1.812H1.816a1.713 1.713 0 01-1.813-1.812V5.44a1.713 1.713 0 011.813-1.813H5.44V1.816A1.713 1.713 0 017.253.003a1.713 1.713 0 011.813 1.813v1.812H19.94V1.816a1.813 1.813 0 013.625 0v1.812zm-1.813 18.125h-3.625v-3.625h3.625v3.625zm-5.437 0H12.69v-3.625h3.625v3.625zm5.437-5.437h-3.625V12.69h3.625v3.625zm-5.437 0H12.69V12.69h3.625v3.625zm-5.438 5.437H7.253v-3.625h3.625v3.625z"
              fill={props.color2 || "#707070"}
              fillRule="evenodd"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
