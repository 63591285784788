import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <Circle
        cx={15}
        cy={15}
        r={14.5}
        fill="transparent"
        stroke={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M8.99 15.961h12.02a.95.95 0 00.938-.946.955.955 0 00-.938-.946H8.99a.966.966 0 00-.938.946.96.96 0 00.938.946z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
