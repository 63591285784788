import * as React from "react";
import Svg, { Path } from "react-native-svg";

function TabbarBg(props) {
  return (
    <Svg
      width={props.width || "440px"}
      height={props.height || "95px"}
      viewBox="0 0 440 95"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M422 308c9.941 0 18 8.06 18 18v77H0v-77c0-9.94 8.059-18 18-18h172.602A34.836 34.836 0 00185 327c0 19.33 15.67 35 35 35s35-15.67 35-35a34.836 34.836 0 00-5.602-19H422z"
        transform="translate(0 -308)"
        fill={props.color || "#fff"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default TabbarBg;
