import MediaItemObject from "./MediaItemObject";
import $ from "../global/util";

const GalleryObject = function (MediaItemObject) {
  function GalleryObject() {
    this.name = "";
    this.title = "";
    this.description = "";
    this.media_items = [];
    this.id = "";
    this.other_data = {};
    this.alternate_name = "";
  }

  GalleryObject.prototype = {
    validateObject: function () {},

    buildObject: function (rawGalleyObject) {
      this.name = rawGalleyObject.name;
      this.title = rawGalleyObject.title;
      this.description = rawGalleyObject.description;
      this.alternate_name = rawGalleyObject.alternate_name;
      this.media_items = [];
      this.other_data = rawGalleyObject.other_data;
      var rawMediaItems = rawGalleyObject.media_items;
      if (rawMediaItems) {
        var parsedMediaItems = [];
        rawMediaItems.forEach(function (rawMediaItem, index, rawMediaItems) {
          var mediaItem = new MediaItemObject();
          mediaItem.buildObject(rawMediaItem);
          parsedMediaItems.push(mediaItem);
        });
        this.media_items = parsedMediaItems;
      }
      this.id = rawGalleyObject.id;
    },
  };

  return GalleryObject;
};

export default GalleryObject(MediaItemObject);
