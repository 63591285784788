import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M31.754 17.894a6.511 6.511 0 00-1.544-.33V14.21C30.21 8.581 25.63 4 19.998 4 14.367 4 9.783 8.581 9.783 14.21v3.355c-.512.05-1.029.146-1.569.339C5.696 18.802 4 21.191 4 23.847c0 2.656 1.696 5.045 4.214 5.944a6.207 6.207 0 002.12.387h1.93a.574.574 0 00.573-.573V18.086a.574.574 0 00-.572-.572H10.93v-3.305c0-4.998 4.066-9.062 9.067-9.062 4.998 0 9.064 4.065 9.064 9.062v3.305h-1.33a.574.574 0 00-.576.572v11.52c0 .316.258.572.575.572h1.355V33.487a1.352 1.352 0 01-1.351 1.35h-3.426c-.106-.443-.288-.865-.618-1.195a2.505 2.505 0 00-4.27 1.77c-.001.317.257.573.574.573h7.739c.671 0 1.3-.26 1.773-.735.47-.47.728-1.095.728-1.762v-3.36c.496-.05.994-.144 1.52-.327 2.536-.894 4.24-3.286 4.24-5.952 0-2.667-1.707-5.06-4.241-5.955zM7.833 28.364a5.179 5.179 0 01-2.686-4.515 5.179 5.179 0 012.686-4.519v9.035zm3.856-9.702v10.37h-1.355c-.442 0-.888-.066-1.355-.199v-9.971c.467-.136.912-.2 1.355-.2h1.355zm9.003 16.175a1.355 1.355 0 012.453 0h-2.453zm10.297-6c-.461.132-.898.194-1.331.194h-1.355V18.662h1.355c.433 0 .87.065 1.33.195v9.98zm1.148-.46V19.32a5.172 5.172 0 012.708 4.53c0 1.9-1.06 3.63-2.708 4.529z"
        fill="#333"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default SvgComponent;
