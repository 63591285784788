import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import GalleryResource from "./GalleryResource";
import GalleryObject from "../../data_models/GalleryObject";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const GalleryManager = function (
  Utils,
  ErrorConstants,
  GalleryResource,
  GalleryObject,
  PrestoSdk
) {
  function getGallery(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    GalleryResource.getGallery(params).then(
      function (galleryResponse) {
        var galleyObject = new GalleryObject();
        galleyObject.buildObject(galleryResponse.data);
        galleryResponse.data = galleyObject;
        successCallback(galleryResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function getMerchantGallery(params, successCallback, errorCallback) {
    GalleryResource.getGallery(params).then(
      function (galleryResponse) {
        var galleyObject = new GalleryObject();
        galleyObject.buildObject(galleryResponse.data);
        galleryResponse.data = galleyObject;
        successCallback(galleryResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  return {
    getGallery: getGallery,
    getMerchantGallery: getMerchantGallery,
  };
};

export default GalleryManager(
  Utils,
  ErrorConstants,
  GalleryResource,
  GalleryObject,
  PrestoSdk
);
