import React, { useContext, useState, useCallback } from "react";
import { View, ScrollView, FlatList, Dimensions } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import LargeButton from "@presto-components/PrestoButton";
import SectionHeaderWithRightLink from "@presto-components/SectionHeaders/SectionHeaderWithRightLink";
import TransactionCardComponent from "@presto-cards/TransactionCard/TransactionCardComponent";
import utils from "../../utils";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import LoadingContainer from "@presto-components/LoadingContainer";
import I18n from "i18n-js";
import { prestoAlert } from "../../common/Alert";
import UserContext from "@presto-contexts/UserContext";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { VerticalSpacing } from "@presto-components/Spacing";
import useFocusEffect from "@presto-common/useFocusEffect";
import PrestoText from "@presto-components/PrestoText";
import TransactionModel from "@presto-component-models/TransactionModel";
import { useAssets } from "presto-react-components";

export default function WalletPage(props) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const [wallet, setWallet] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const isDesktop = Dimensions.get("window").width > 940 ? true : false;

  useFocusEffect(
    useCallback(() => {
      if (user?.id) {
        getWalletInfo();
      }
    }, [user])
  );

  const getWalletInfo = () => {
    WalletManager.getWalletInfo(
      { walletId: user.wallet_id },
      (response) => {
        setWallet(response.data);
        getTransactions();
      },
      (error) => {
        setLoading(false);
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error) || "Please try again later"
        );
        console.log(JSON.stringify(error));
      }
    );
  };
  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };
  const getTransactions = () => {
    const params = {};
    WalletManager.getTransactionHistory(
      params,
      (transactions) => {
        setTransactions(transactions.transactions);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error) || "Please try again later"
        );
        console.log("transactions response : ");
        console.log(error);
      }
    );
  };
  const renderTransaction = ({ item, index }) => {
    if (index > 2) {
      return;
    }
    return (
      <TransactionCardComponent
        theme={theme}
        key={item.id}
        transaction={new TransactionModel({ transaction: item })}
      />
    );
  };
  const onGoTo = (str) => {
    if (str == "add") {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "ADD_MONEY",
          screenParams: {
            wallet: wallet,
          },
        },
      });
    } else {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "TRANSACTIONS",
          screenParams: {
            wallet: wallet,
          },
        },
      });
    }
  };
  return (
    <LoadingContainer
      style={{ paddingTop: 0 }}
      bgColor="transparent"
      loading={loading}
    >
      <ScrollView
        style={{
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 100,
          paddingHorizontal: theme.containerPadding,
        }}
      >
        <View>
          <View
            style={{
              paddingVertical: 10,
            }}
          >
            <VerticalSpacing size={20} />
            <FlexRowSpaceAligned>
              <PrestoText fontStyle="500.medium" color={theme.darkText}>
                {I18n.t("screen_messages.wallet.wallet_balance")}
              </PrestoText>
              <PrestoText
                fontStyle="400.normal"
                color={theme.secondary}
                extraStyle={{
                  display: "flex",
                  flexWrap: "no-wrap",
                }}
              >
                {wallet
                  ? utils.formattedCurrencyCents(
                      wallet.current_balance - wallet.expiry_balance
                    )
                  : 0}
              </PrestoText>
            </FlexRowSpaceAligned>
            <LineDivider
              style={{ marginTop: theme.primaryPadding }}
              dividerColor={theme.lightText}
              width="100%"
              theme={theme}
            />
          </View>
          <View
            style={{
              paddingVertical: 10,
            }}
          >
            <FlexRowSpaceAligned>
              <PrestoText fontStyle="500.medium" color={theme.darkText}>
                {I18n.t("screen_messages.points.expiry_title")}
              </PrestoText>
              <PrestoText
                fontStyle="400.normal"
                color={theme.secondary}
                extraStyle={{ display: "flex", flexWrap: "no-wrap" }}
              >
                {wallet
                  ? utils.formattedCurrencyCents(wallet.expiry_balance)
                  : 0}
              </PrestoText>
            </FlexRowSpaceAligned>
          </View>
          <LineDivider
            style={{ marginTop: theme.primaryPadding }}
            dividerColor={theme.lightText}
            width="100%"
            theme={theme}
          />
          <View
            style={{
              paddingVertical: 20,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <View style={{ width: 160, paddingRight: 7 }}>
              <LargeButton
                title={I18n.t("screen_messages.topups.title")}
                fontStyle={isDesktop ? "500.medium" : "600.semibold"}
                onPress={() => onGoTo("add")}
                icon={<svgs.LineWalletIcon width="50" height="50" />}
                width="100%"
                extraStyle={{
                  margin: 0,
                }}
              />
            </View>

            <View style={{ width: 160, paddingLeft: 7 }}>
              <LargeButton
                title={I18n.t("screen_messages.transactions.title")}
                fontStyle={isDesktop ? "500.medium" : "600.semibold"}
                icon={<svgs.TransactionIcon width="50" height="50" />}
                width="100%"
                onPress={() => {
                  onGoTo("transaction");
                }}
                extraStyle={{
                  margin: 0,
                }}
              />
            </View>
          </View>
          <View
            style={{
              paddingBottom: 16,
            }}
          >
            {transactions?.length > 0 ? (
              <SectionHeaderWithRightLink
                theme={theme}
                rightText={I18n.t("screen_messages.common.see_all")}
                leftText={I18n.t(
                  "screen_messages.transactions.recent_transactions"
                )}
                leftTextFontStyle="500.medium"
                onPressRightText={() => {
                  onGoTo("transaction");
                }}
              />
            ) : null}
            <FlatList
              data={transactions}
              keyExtractor={(item) => item.id}
              renderItem={renderTransaction}
            />
          </View>
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}
