import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";

export function DrawerSectionLink(props) {
  const {
    rightIcon,
    leftIcon,
    leftText,
    leftTextColor,
    rightText,
    onPressButton,
    theme,
  } = props;
  const styles = getStyles(theme, { ...props });
  let defaultStyles = [styles.default];
  if (Platform.OS === "web") {
    defaultStyles.push({ cursor: "pointer" });
  }
  return (
    <TouchableWithoutFeedback onPress={onPressButton}>
      <View style={[defaultStyles]}>
        <FlexRowSpaceAligned
          styleSet={{
            paddingLeft: theme.primaryPadding,
            height: "100%",
            paddingVertical: theme.primaryPadding - 3,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              flexShrink: 1,
            }}
          >
            <View
              style={{
                width: 40,
              }}
            >
              <PrestoIcon
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 2,
                }}
                icon={leftIcon}
              />
            </View>
            {leftText ? (
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <PrestoText
                  fontStyle="400.normal"
                  color={leftTextColor ? leftTextColor : theme.dark}
                  extraStyle={styles.linkText}
                >
                  {leftText}
                </PrestoText>
              </View>
            ) : null}
          </View>
          <View style={{ flexDirection: "row" }}>
            {rightText ? (
              <PrestoText
                color={theme.secondary}
                extraStyle={[styles.linkText, { paddingTop: 5 }]}
              >
                {rightText}
              </PrestoText>
            ) : null}
            {rightIcon ? (
              <PrestoIcon
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 1,
                }}
                icon={rightIcon}
              />
            ) : null}
          </View>
        </FlexRowSpaceAligned>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default DrawerSectionLink;

function getStyles(theme, options) {
  const { borderColor, extraStyle } = options;
  return {
    default: {
      height: 60,
      borderBottomWidth: 0.5,
      borderColor: borderColor ? borderColor : theme.lightText,
      ...extraStyle,
    },
    linkText: {
      fontSize: 14,
    },
  };
}
