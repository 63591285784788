import Utils from "../../common/Utils";
import CatalogResource from "./CatalogResource";
import CatalogObject from "../../data_models/CatalogObject";
import ErrorConstants from "../../common/ErrorConstants";
import CategoryItemObject from "../../data_models/CategoryItemObject";
import $ from "../../global/util";

const CatalogManager = function (
  Utils,
  CatalogResource,
  CatalogObject,
  ErrorConstants,
  CategoryItemObject
) {
  function getParsedCatalog(rawCatalog) {
    var catalogObj = new CatalogObject();
    catalogObj.buildObject(rawCatalog);
    return catalogObj;
  }

  function getGenericCatalog(params, successCallback, errorCallback) {
    CatalogResource.getGenericCatalog(params).then(
      function (catalog) {
        catalog.data = getParsedCatalog(catalog.data);
        successCallback(catalog);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getGenericCatalogStructure(params, successCallback, errorCallback) {
    CatalogResource.getGenericCatalogStructure(params).then(
      function (catalog) {
        catalog.data = getParsedCatalog(catalog.data);
        successCallback(catalog);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOutletBasedCatalog(params, successCallback, errorCallback) {
    console.log("inside getOutletBasedCatalog()");
    if (!params || Utils.isEmpty(params.outletId)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.OUTLET_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CatalogResource.getOutletBasedCatalog(params).then(
        function (catalog) {
          catalog.data = getParsedCatalog(catalog.data);
          successCallback(catalog);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getOutletBasedFullCatalog(params, successCallback, errorCallback) {
    console.log("inside getOutletBasedFullCatalog()");
    if (!params || Utils.isEmpty(params.outletId)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.OUTLET_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CatalogResource.getOutletBasedFullCatalog(params).then(
        function (catalog) {
          catalog.data = getParsedCatalog(catalog.data);
          successCallback(catalog);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getFilterBasedCatalog(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.filter)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.FILTER_TEXT_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CatalogResource.getFilterBasedCatalog(params).then(
        function (catalog) {
          console.log("catalog revcieved", catalog);

          catalog.data = getParsedCatalog(catalog.data);
          successCallback(catalog);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function searchProduct(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.search_string)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CatalogResource.searchProduct(params).then(
        function (searchResponse) {
          var rawItems = searchResponse.data.items;
          if (rawItems) {
            var parsedItems = [];
            rawItems.forEach(function (rawItem, index, rawItems) {
              var categoryItem = new CategoryItemObject();
              categoryItem.buildObject(rawItem);
              parsedItems.push(categoryItem);
            });
            searchResponse.data.items = parsedItems;
          }
          successCallback(searchResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getFullCatalog(params, successCallback, errorCallback) {
    CatalogResource.getFullCatalog(params).then(
      function (catalog) {
        catalog.data = getParsedCatalog(catalog.data);
        successCallback(catalog);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function getMasterCatalog(params, successCallback, errorCallback) {
    CatalogResource.getMasterCatalog(params).then(
      function (catalog) {
        // catalog.data = getParsedCatalog(catalog.data);
        successCallback(catalog);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function createCatalog(params, successCallback, errorCallback) {
    CatalogResource.createCatalog(params).then(
      function (catalog) {
        console.log("catalog");
        successCallback(catalog);
      },
      function (error) {
        console.log("error");
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getMasterCatalogForProducts(params, successCallback, errorCallback) {
    CatalogResource.getMasterCatalogForProducts(params).then(
      function (catalog) {
        successCallback(catalog);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getGenericCatalog: getGenericCatalog,
    getOutletBasedCatalog: getOutletBasedCatalog,
    getFilterBasedCatalog: getFilterBasedCatalog,
    searchProduct: searchProduct,
    getGenericCatalogStructure: getGenericCatalogStructure,
    getFullCatalog: getFullCatalog,
    getOutletBasedFullCatalog: getOutletBasedFullCatalog,
    getMasterCatalog: getMasterCatalog,
    createCatalog: createCatalog,
    getMasterCatalogForProducts: getMasterCatalogForProducts,
  };
};

export default CatalogManager(
  Utils,
  CatalogResource,
  CatalogObject,
  ErrorConstants,
  CategoryItemObject
);
