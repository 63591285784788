import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function MealIcon(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M.886 0A.9.9 0 000 .912v7.492c0 2.153 1.568 3.881 3.6 4.313v10.371a.899.899 0 101.8 0V12.717c2.032-.432 3.6-2.16 3.6-4.313V.912a.899.899 0 10-1.8 0v5.994c0 .176-.123.3-.3.3H5.7a.286.286 0 01-.3-.3V.912a.899.899 0 10-1.8 0v5.994c0 .176-.123.3-.3.3H2.1a.286.286 0 01-.3-.3V.912A.899.899 0 00.886 0zm.977 8.979c.08.009.156.024.237.024h4.8c.081 0 .158-.015.237-.024a2.68 2.68 0 01-2.688 2.112 2.68 2.68 0 01-2.586-2.112z"
          transform="translate(8 8) translate(15)"
          fill="#D97904"
        />
        <Path
          d="M12 .013C5.383.013 0 5.39 0 12s5.383 11.987 12 11.987c1.943 0 3.775-.474 5.4-1.297v-1.186c-1.568.983-3.416 1.186-5.4 1.186C6.376 22.69.997 17.618.997 12 .997 6.382 6.376.957 12 .957c.615 0 1.214.184 1.8.288V.163c-.589-.09-1.187-.15-1.8-.15z"
          fill="#707070"
          transform="translate(8 8)"
        />
        <Path
          d="M12.53 4.71c-.497.008-.537.5-.53.997V12l-2.94 3.026c-.235.225-.442.591-.36.906.082.315.045.165.36.248a.9.9 0 00.876-.248l3-2.997a.899.899 0 00.264-.635V5.707c.003-.243.172-.466 0-.637-.172-.172-.427-.364-.67-.36z"
          fill="#707070"
          transform="translate(8 8)"
        />
      </G>
    </Svg>
  );
}

export default MealIcon;
