import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  const width = props.width || props.size || 26;
  const height = props.height || props.size || 26;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#fff"}>
        <Path
          opacity={0.4}
          d="M10.563 4.063a6.5 6.5 0 11-4.597 1.903 6.457 6.457 0 014.596-1.904zm0-4.063C4.729 0 0 4.729 0 10.563c0 5.833 4.729 10.562 10.563 10.562 5.833 0 10.562-4.729 10.562-10.563C21.125 4.73 16.396 0 10.562 0z"
        />
        <Path d="M25.64 24.207l-1.438 1.438a1.212 1.212 0 01-1.721 0l-5.063-5.063a1.218 1.218 0 01-.356-.864v-.827l1.829-1.829h.827a1.218 1.218 0 01.864.356l5.063 5.063a1.225 1.225 0 01-.006 1.726z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill={props.primaryColor || "#fff"} d="M0 0h26v26H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
