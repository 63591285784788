import React from "react";
import { View, StyleSheet } from "react-native";

function SectionContainer({ style = {}, children }) {
  return (
    <React.Fragment>
      <View style={[styles.default, style]}>{children}</View>
    </React.Fragment>
  );
}

export default SectionContainer;

const styles = {
  default: {
    textAlign: "center",
    paddingVertical: 10,
    width: "100%",
  },
};
