import * as React from "react";
import Svg, { Path } from "react-native-svg";

function InventoryIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M31.036 16.074l3.955-3.953a.556.556 0 00.164-.469.573.573 0 00-.277-.418l-10.666-6.16a.56.56 0 00-.678.094L19.58 9.12l-3.955-3.95a.563.563 0 00-.68-.095L4.28 11.234a.545.545 0 00-.275.418.56.56 0 00.156.47l3.957 3.952-3.957 3.957a.557.557 0 00-.156.47.55.55 0 00.275.413l4.068 2.352v5.121c0 .203.11.387.281.488l10.668 6.16a.53.53 0 00.281.074.538.538 0 00.281-.074l10.664-6.16a.567.567 0 00.281-.488v-5.12l4.072-2.353a.577.577 0 00.277-.414.553.553 0 00-.164-.469l-3.953-3.957zm-1.922 0l-9.535 5.508-9.539-5.508 7.152-4.129 2.387-1.375 8.625 4.977.91.527zm-5.09-9.808l9.654 5.58-3.529 3.525-.578-.334-9.078-5.24 3.531-3.53zm-8.89 0l3.53 3.531-9.658 5.574-3.53-3.525 9.657-5.58zM9.005 16.777l9.654 5.578-3.529 3.525-5.939-3.428H9.19l-3.715-2.146 3.531-3.529zm.467 7.14l5.47 3.155a.543.543 0 00.28.076c.012 0 .02-.014.033-.014a.54.54 0 00.365-.148l3.395-3.395v9.984l-9.543-5.514v-4.145zm20.205 4.146l-9.537 5.508v-9.979l3.391 3.395c.1.1.23.14.363.148.012 0 .023.014.033.014a.535.535 0 00.281-.076l5.47-3.156v4.146zm-5.656-2.182l-3.529-3.525 9.656-5.578 3.53 3.53-9.657 5.573z"
        fill={props.color || "#707070"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default InventoryIcon;
