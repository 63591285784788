import * as React from "react";
import Svg, { G, Circle, Rect, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke={props.color || "#707070"}
          strokeWidth={0.9}
          fill={props.color || "#707070"}
          cx={20}
          cy={20}
          r={17.55}
        />
        <G
          transform="rotate(45 4.636 26.364)"
          fill={props.color1 || "#FFFFFF"}
          stroke={props.color1 || "#FFFFFF"}
          strokeWidth={1.3}
        >
          <Rect x={10.6794118} y={0.65} width={1} height={20.7} rx={0.5} />
          <Path
            d="M10.773.744c.316-.094.396-.058.454 0a.32.32 0 01.094.227h0v20.058a.32.32 0 01-.548.227.32.32 0 01-.094-.227h0V.971a.32.32 0 01.094-.227z"
            transform="rotate(90 11 11)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
