import * as React from "react"
import Svg, { G, Circle, Text, TSpan } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(6 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle stroke={props.color} strokeWidth={1.5} cx={14} cy={14} r={13.25} />
        <Text
          fontSize={14}
          fontStyle="italic"
          fontWeight={400}
          fill={props.color}
        >
          <TSpan x={12.5} y={19}>
            {"i"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  )
}

export default SvgComponent
