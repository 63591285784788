import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function BMCLogoutIcon(props) {
  const color = props.color ?? "#707070";
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(8 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path fill={color} fillRule="nonzero" d="M0 0H18V29H0z" />
        <Path
          fill={color}
          fillRule="nonzero"
          d="M22.1232443 22L20.3410485 20.1253641 24.4164194 15.8386094 15 15.8386094 15 13.159934 24.6781405 13.159934 20.3410485 8.87463585 22.1232443 7 29 14.2334434z"
        />
        <Path fill="#FFF" d="M6 13.1H18V15.85H6z" />
      </G>
    </Svg>
  );
}

export default BMCLogoutIcon;
