import React from "react";
import { View } from "react-native";
import _ from "lodash";

export default class HorizontalMultiRowButtonList extends React.Component {
  render() {
    this.scrollRef = React.createRef();
    const { renderButton, items, spacing, style } = this.props;

    let i = 0;
    return (
      <View
        horizontal={true}
        style={{
          width: "100%",
          flexWrap: "wrap",
          flexDirection: "row",
          ...style,
        }}
      >
        {_.map(items, (item, index) => {
          if (!item) {
            return null;
          }
          return (
            <View style={{ width: "33%" }} key={item.id + "-" + index}>
              <View
                key={index}
                ref={item.id || index}
                style={{
                  paddingVertical: 5,
                  paddingLeft: 5,
                }}
              >
                {renderButton(item, index)}
                <View style={{ width: spacing }}></View>
              </View>
            </View>
          );
        })}
      </View>
    );
  }

  scrollToStart() {
    if (this.scrollRef) {
      this.scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true });
    }
  }
}
