import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18 18-8.059 18-18S27.941 0 18 0zm4.655 25.2l-5.928-5.927A1.798 1.798 0 0116.2 18V9a1.8 1.8 0 013.6 0v8.255l5.4 5.4a1.801 1.801 0 010 2.545 1.801 1.801 0 01-2.545 0z"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={props.strokeWidth || 1}
      />
    </Svg>
  );
}

export default SvgComponent;
