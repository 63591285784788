import React, { useContext, useReducer, useEffect } from "react";
import { View, FlatList, Dimensions } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import _ from "lodash";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import I18n from "i18n-js";
import SeeAllText from "@presto-screen-components/SeeAllText/SeeAllText";
import SectionHeaderWithRightLink from "@presto-components/SectionHeaders/SectionHeaderWithRightLink";
import OnOfferItemsList from "@presto-screen-components/Home/OnOfferItemsList";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_CATEGORY_ITEMS":
      return { ...state, items: payload };
  }
  return state;
}

export default function OnOfferItems({
  showAll,
  getItemPromotions,
  promotions,
  fromScreen,
}) {
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);
  const windowWidth = Dimensions.get("window").width;

  let isDesktop = windowWidth > 940 ? true : false;
  const [state, dispatch] = useReducer(reducer, {
    items: [],
  });

  const { items } = state;
  const getItems = () => {
    const ids = _.map(state.favorites, (fav) => fav.item_id);
    SearchManager.searchCategoryItem(
      { on_offer: true, catalog_id: catalog?.id },
      (response) => {
        const items = _.map(response.hits.hits, (item) => item._source);
        dispatch({
          type: "SET_CATEGORY_ITEMS",
          payload: _.slice(items, 0, showAll ? undefined : 3),
        });
      },
      () => {}
    );
  };

  useEffect(() => {
    getItems();
  }, [catalog]);

  const renderItem = (item) => {
    if (item) {
      if (!isDesktop) {
        return (
          <View style={{ width: "100%" }}>
            <ItemCard
              key={item._id}
              theme={theme}
              item={item}
              getItemPromotions={getItemPromotions}
              promotions={promotions}
            ></ItemCard>
          </View>
        );
      } else {
        return (
          <View
            style={{ flex: 1, width: 400, marginRight: 15, marginBottom: 15 }}
          >
            <ItemCard key={item._id} theme={theme} item={item}></ItemCard>
          </View>
        );
      }
    } else {
      return null;
    }
  };
  if (items.length === 0) {
    return null;
  }
  if (showAll) {
    let containerStyle = {};
    if (isDesktop) {
      containerStyle = {
        flexFlow: "wrap",
        width: "100%",
        flex: 1,
      };
    }
    return (
      <View
        style={{ paddingVertical: theme.primaryPadding, marginBottom: 130 }}
      >
        <View style={{ paddingHorizontal: theme.primaryPadding }}>
          <SectionHeaderWithRightLink
            fontSize={18}
            leftText={I18n.t("screen_messages.offers.discounts_offers")}
          />
        </View>
        <OnOfferItemsList data={items} />
      </View>
    );
  }

  return (
    <View style={{}}>
      <View
        style={{
          paddingHorizontal: isDesktop ? 0 : theme.containerPadding,
        }}
      >
        <SeeAllText
          fontSize={isDesktop ? 22 : 18}
          rightText={
            !isDesktop ? I18n.t("screen_messages.common.see_all") : null
          }
          leftText={I18n.t("screen_messages.offers.discounts_offers")}
          onPressRightText={() => {
            Navigator.emit({
              event: "modal",
              params: {
                screenName: "ON_OFFER_SCREEN",
              },
            });
          }}
        />
      </View>
      {isDesktop ? (
        <>
          <FlatList
            keyExtractor={(_, index) => "index-" + index}
            data={items}
            contentContainerStyle={{
              flexFlow: "wrap",
              width: "100%",
              flex: 1,
            }}
            renderItem={({ item }) => renderItem(item)}
          />
        </>
      ) : (
        <View>
          <OnOfferItemsList
            data={items}
            fromScreen={fromScreen}
            getItemPromotions={getItemPromotions}
            promotions={promotions}
          />
        </View>
      )}
    </View>
  );
}
