import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M23.883 8.295A.9.9 0 0125.29 9.41l-.077.095-9.388 10.328a.1.1 0 00-.02.103l.02.031 9.388 10.328a.9.9 0 01-1.243 1.295l-.088-.085-9.389-10.327a1.9 1.9 0 01-.11-2.424l.11-.132 9.389-10.327z"
        transform="matrix(-1 0 0 1 39.449 0)"
        fill="#333"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  )
}

export default SvgComponent
