import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(9.793 7.25) translate(0 .25)">
          <Path
            d="M-5.02 9.25h15.454a.762.762 0 00.773-.75.762.762 0 00-.773-.75H-5.021a.762.762 0 00-.772.75c0 .414.346.75.772.75z"
            fill="#333"
            fillRule="nonzero"
            transform="rotate(90 2.707 8.5)"
          />
          <Path
            stroke="#333"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0 3L2.70670319 0 5.70670319 3"
          />
        </G>
        <G transform="translate(9.793 7.25) translate(7)">
          <Path
            d="M-5.02 9.25h15.454a.762.762 0 00.773-.75.762.762 0 00-.773-.75H-5.021a.762.762 0 00-.772.75c0 .414.346.75.772.75z"
            fill="#333"
            fillRule="nonzero"
            transform="rotate(90 2.707 8.5)"
          />
          <Path
            stroke="#333"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="matrix(1 0 0 -1 0 30.5)"
            d="M0 16.75L2.70670319 13.75 5.70670319 16.75"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
