import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function LactoseIcon(props) {
  return (
    <Svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#F2B705" stroke="none" strokeWidth={1} fillRule="evenodd">
        <Path d="M10 1.325a8.618 8.618 0 00-6.134 2.541A8.618 8.618 0 001.326 10c0 2.317.902 4.495 2.54 6.134A8.618 8.618 0 0010 18.674a8.618 8.618 0 006.134-2.54A8.617 8.617 0 0018.674 10a8.617 8.617 0 00-2.54-6.134A8.618 8.618 0 0010 1.326zM10 20a9.966 9.966 0 01-7.071-2.929A9.966 9.966 0 010 10a9.965 9.965 0 012.929-7.071A9.967 9.967 0 0110 0a9.968 9.968 0 017.071 2.929A9.966 9.966 0 0120 9.999a9.967 9.967 0 01-2.929 7.071A9.967 9.967 0 0110 20.001z" />
        <Path d="M13.13 6.817l.198.21c.07.075.108.172.108.273v.027l3.43-3.633-.964-.91-3.244 3.435.372.397a.397.397 0 01.1.201" />
        <Path d="M3.13406424 16.3055395L4.09780727 17.2156184 6.56424312 14.6036738 6.56424312 12.673014 3.13406424 16.3055395" />
        <Path d="M11.519 3.285h.002-.002M12.74 6.98v8.061c0 .479-1.36.867-3.039.867-1.206 0-2.249-.2-2.74-.491v.037c0 .478 1.361.866 3.039.866 1.678 0 3.038-.388 3.038-.866V7.3l-.299-.32" />
        <Path d="M11.208 5.253v-.397h.273c.174 0 .315-.14.315-.314v-.548a.314.314 0 00-.299-.313l-.016-.001H8.52a.315.315 0 00-.315.314v.548a.315.315 0 00.315.314h.273v.492H8.79L6.962 7.3v8.117c.49.29 1.533.49 2.74.49 1.677 0 3.037-.387 3.037-.866V6.887l-1.531-1.634" />
      </G>
    </Svg>
  );
}

export default LactoseIcon;
