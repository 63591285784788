import * as React from "react";
import Svg, { G, Polygon, Circle } from "react-native-svg";

function CloseIcon3({ width="40px", height="40px", color = "#707070" }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/close--with-circle">
          <Circle
            id="Oval"
            stroke={color}
            fill={color}
            cx="20"
            cy="20"
            r="19.5"
          ></Circle>
          <Polygon
            id="Union_3"
            fill="#FFFFFF"
            fill-rule="nonzero"
            points="19.9993572 21.8171884 11.8178312 30 10 28.1808832 18.181526 19.9993572 10 11.8178312 11.8178312 10 19.9993572 18.181526 28.1808832 10 30 11.8178312 21.8171884 19.9993572 29.9987144 28.1808832 28.1795976 30"
          ></Polygon>
        </G>
      </G>
    </Svg>
  );
}

export default CloseIcon3;
