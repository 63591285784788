import React from "react";
import { View } from "react-native";

function FlexRowSpaceAligned({ styleSet, children }) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
        ...styleSet,
      }}
    >
      {children}
    </View>
  );
}

export default FlexRowSpaceAligned;
