import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use, Circle } from "react-native-svg";

function LogoutIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H17V17H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill={props.color || "#D8D8D8"} cx={19.5} cy={19.5} r={17.5} />
        <G transform="translate(11 10)">
          <Mask id="b" fill={props.color2 || "#FFF"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <Path d="M0 0H17V17H0z" />
            <Path
              d="M8.5 11.688a2.125 2.125 0 10-2.125-2.126A2.131 2.131 0 008.5 11.688zm0-6.376a4.25 4.25 0 11-4.25 4.25 4.263 4.263 0 014.25-4.25zM14.875 17H2.125a2.097 2.097 0 01-1.488-.637A2.097 2.097 0 010 14.875V5.312a2.131 2.131 0 012.125-2.125h1.7l1.594-1.593a1.76 1.76 0 011.275-.532h3.718c.444.019.864.21 1.17.532l1.593 1.593h1.7A2.131 2.131 0 0117 5.313v9.562A2.131 2.131 0 0114.875 17z"
              fill={props.color3 || "#707070"}
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default LogoutIcon;
