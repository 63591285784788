import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent({
  width,
  height,
  fillColor = "#CCCCCC",
  color = "#7E5C5B",
}) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/white/camera-with-circle">
          <Circle
            id="Oval"
            fill-opacity="0.5"
            fill={fillColor}
            cx="20"
            cy="20"
            r="20"
          ></Circle>
          <G
            id="icons8-camera"
            transform="translate(8.000000, 10.000000)"
            fill={color}
            fill-rule="nonzero"
          >
            <Path
              d="M11.7,0 C10.7868749,0 9.93562512,0.7070315 9.57,1.6875 L9.27,2.5 L6.72,2.5 L6.72,2 C6.72,1.7246095 6.50437488,1.5 6.24,1.5 L2.88,1.5 C2.61562512,1.5 2.4,1.7246095 2.4,2 L2.4,2.703125 C1.02562512,3.142578 0,4.4296875 0,6 L0,16.5 C0,18.4277345 1.50937488,20 3.36,20 L20.64,20 C22.4906251,20 24,18.4277345 24,16.5 L24,6 C24,4.0722655 22.4906251,2.5 20.64,2.5 L19.53,2.5 L19.23,1.6875 C18.8643749,0.7070315 18.015,0 17.1,0 L11.7,0 Z M11.7,1 L17.1,1 C17.4675,1 18.1012502,1.433594 18.33,2.046875 L18.75,3.1875 C18.8231251,3.3789065 19.0031251,3.501953 19.2,3.5 L20.64,3.5 C21.9712502,3.5 23.04,4.6132815 23.04,6 L23.04,16.5 C23.04,17.886719 21.9712502,19 20.64,19 L3.36,19 C2.02875024,19 0.96,17.886719 0.96,16.5 L0.96,6 C0.96,4.7558595 1.82062512,3.730469 2.955,3.53125 C3.18562512,3.4941405 3.35625024,3.2890625 3.36,3.046875 L3.36,2.5 L5.76,2.5 L5.76,3 C5.76,3.2753905 5.97562512,3.5 6.24,3.5 L9.6,3.5 C9.79687488,3.501953 9.97687488,3.3789065 10.05,3.1875 L10.47,2.046875 C10.6987502,1.433594 11.3325,1 11.7,1 Z M14.4,4.5 C10.9612502,4.5 8.16,7.417969 8.16,11 C8.16,14.5820315 10.9612502,17.5 14.4,17.5 C17.8387502,17.5 20.64,14.5820315 20.64,11 C20.64,7.417969 17.8387502,4.5 14.4,4.5 Z M3.36,5 C2.82937488,5 2.4,5.4472655 2.4,6 C2.4,6.5527345 2.82937488,7 3.36,7 C3.89062512,7 4.32,6.5527345 4.32,6 C4.32,5.4472655 3.89062512,5 3.36,5 Z M14.4,5.5 C17.3212502,5.5 19.68,7.9570315 19.68,11 C19.68,14.042969 17.3212502,16.5 14.4,16.5 C11.4787502,16.5 9.12,14.042969 9.12,11 C9.12,7.9570315 11.4787502,5.5 14.4,5.5 Z"
              id="Shape"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
