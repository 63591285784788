import React, { useState, useContext, useCallback } from "react";
import {
  View,
  FlatList,
  TouchableWithoutFeedback,
  ImageBackground,
  Platform,
} from "react-native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { Dimensions } from "react-native";
import BannersSlide from "@presto-components/BannersSlide";
import PrestoText from "@presto-components/PrestoText";

export default function BannersGallery({
  navigation,
  title,
  name,
  slidesToShow,
  borderRadius,
  width,
  height,
  onItemClicked,
  fontStyle,
  fontSize,
  description,
}) {
  const { theme } = useContext(ThemeContext);
  const [gallery, setGallery] = useState([]);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response?.data?.media_items);
  };

  useFocusEffect(
    useCallback(() => {
      getGallery(name);
    }, [])
  );

  let isDesktop = windowWidth > 940 ? true : false;
  const renderItem = ({ item, index }) => {
    return (
      <TouchableWithoutFeedback
        onPress={() => onItemClicked && onItemClicked(item)}
      >
        <ImageBackground
          style={{
            width: width || windowWidth,
            height: height || 400,
            borderRadius: borderRadius || 0,
            overflow: "hidden",
          }}
          source={{ uri: item.file_url }}
          resizeMode="cover"
        />
      </TouchableWithoutFeedback>
    );
  };

  return (
    <View style={{}}>
      {title ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingHorizontal: theme.primaryPadding,
          }}
        >
          <PrestoText fontStyle="400.normal" color={theme.darkText} size={18}>
            {title}
          </PrestoText>
        </View>
      ) : null}
      {description ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingTop: 10,
            paddingHorizontal: theme.primaryPadding,
          }}
        >
          <PrestoText fontStyle="400.normal" color={theme.darkText} size={14}>
            {description}
          </PrestoText>
        </View>
      ) : null}
      <FlatList
        horizontal
        keyExtractor={(_, index) => "index-" + index}
        data={gallery}
        renderItem={renderItem}
        swipeThreshold={10}
        layout={"default"}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{}}
      />
    </View>
  );
}
