import React, { useEffect } from "react";
import { useGalleries } from "../stores/GalleryStore";
import { useCatalog } from "../stores/CatalogStore";
import config from "@presto-common/config";
import _ from "lodash";

export default function AppDataLoader({ children }) {
  const galleryStore = useGalleries();
  const catalogStore = useCatalog();
  const map = {
    galleries: galleryStore,
    catalog: catalogStore,
  };

  useEffect(() => {
    catalogStore.init(config?.constants?.default_catalog_name);
  }, []);

  useEffect(() => {
    _.forEach(config.app_data || [], (data) => {
      if (data.key == "catalog") {
        map[data.key].fetch(...(data.args || []));
      }
    });
  }, [catalogStore.ready]);
  return <>{children}</>;
}
