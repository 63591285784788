import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M22.769 11.223a.727.727 0 011-.046.662.662 0 01.107.889l-.06.072-7.38 7.811a.074.074 0 00-.016.078l.015.024 7.381 7.81a.663.663 0 01-.048.962.729.729 0 01-.93.018l-.069-.064-7.38-7.81a1.396 1.396 0 01-.087-1.834l.086-.1 7.381-7.81z"
        fill={props.primaryColor || "#000"}
      />
    </Svg>
  )
}

export default SvgComponent
