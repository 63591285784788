import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";

export function SectionLinkWithLeftIconComponent(props) {
  const {
    rightIcon,
    leftIcon,
    leftText,
    rightText,
    onPressButton,
    theme,
  } = props;
  const styles = getStyles(theme);
  let rightTextStyles = { paddingRight: 5 };
  if (Platform.OS == "web") {
    rightTextStyles.cursor = "pointer";
  }
  return (
    <TouchableWithoutFeedback onPress={onPressButton}>
      <View
        style={{
          ...styles.default,
        }}
      >
        <FlexRowSpaceAligned
          styleSet={{ paddingHorizontal: theme.primaryPadding }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <PrestoIcon
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={leftIcon}
            />
            {leftText ? (
              <PrestoText
                fontStyle="medium"
                color={theme.darkText}
                size={14}
                extraStyle={{ paddingTop: 5 }}
              >
                {leftText}
              </PrestoText>
            ) : null}
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {rightText ? (
              <PrestoText
                fontStyle="medium"
                color={theme.darkText}
                size={14}
                extraStyle={rightTextStyles}
              >
                {rightText}
              </PrestoText>
            ) : null}
            {rightIcon ? (
              <PrestoIcon
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 1,
                }}
                icon={rightIcon}
              />
            ) : null}
          </View>
        </FlexRowSpaceAligned>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default SectionLinkWithLeftIconComponent;

function getStyles(theme) {
  return {
    default: {
      paddingVertical: 15,
      borderBottomWidth: 1,
      borderColor: theme.linkDividerColor,
    },
  };
}
