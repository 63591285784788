import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const MerchantResource = function ($http, PrestoSdk) {
  function getOrderConfig(params) {
    let merchantId = PrestoSdk.getMerchantId();
    if (params.merchantId) {
      merchantId = params.merchantId;
    }
    var url =
      PrestoSdk.getHostName() +
      "/merchants/" +
      merchantId +
      "/ordering_config.json";

    return $http.get(url);
  }
  function getMerchantConfig(params) {
    var url =
      PrestoSdk.getHostName() +
      "/merchants/" +
      params.merchant_id +
      "/ordering_config.json";
    return $http.get(url);
  }
  function getVersionConfig() {
    var url =
      PrestoSdk.getHostName() +
      "/apps/" +
      PrestoSdk.getAppId() +
      "/version.json";
    return $http.get(url);
  }
  function getFavouriteMerchants() {
    var url = PrestoSdk.getHostName() + "/user/v0/favourite_merchants.json";
    return $http.get(url);
  }
  function createFavouriteMerchant(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/favourite_merchants.json";
    return $http.post(url, params);
  }
  function deleteFavouriteMerchant(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/favourite_merchants/" +
      params.favourite_merchant.merchant_id +
      ".json";
    console.log(url);
    return $http.delete(url);
  }

  function updateAdmin(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/merchant.json";
    return $http.put(url, params);
  }

  function createMerchant(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/merchant.json";
    return $http.post(url, params);
  }

  function getMerchant(params) {
    var url = PrestoSdk.getHostName() + "/merchant.json";
    console.log("url", url);
    return $http.get(url);
  }

  return {
    getOrderConfig: getOrderConfig,
    getVersionConfig: getVersionConfig,
    getFavouriteMerchants: getFavouriteMerchants,
    deleteFavouriteMerchant: deleteFavouriteMerchant,
    createFavouriteMerchant: createFavouriteMerchant,
    getMerchantConfig: getMerchantConfig,
    updateAdmin: updateAdmin,
    createMerchant: createMerchant,
    getMerchant: getMerchant,
  };
};

export default MerchantResource($http, PrestoSdk);
