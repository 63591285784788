import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.125a7.375 7.375 0 104.664 13.088c.06.12.138.232.238.332l4.42 4.42a1.25 1.25 0 101.767-1.769l-4.42-4.419a1.244 1.244 0 00-.462-.293A7.375 7.375 0 0010.5 3.125zm0 1a6.375 6.375 0 110 12.75 6.375 6.375 0 010-12.75z"
        fill="#999"
      />
      <Path
        d="M7.512 12.712c-.373.216-.843.101-1.05-.256-2.036-3.528 1.718-7.684 6.103-6.5.407.11.633.525.505.927a.806.806 0 01-.969.53c-3.105-.838-5.622 1.949-4.286 4.263.206.357.07.82-.303 1.036z"
        fill="#979797"
      />
    </Svg>
  );
}

export default SvgComponent;
