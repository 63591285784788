import * as React from "react";
import Svg, { Rect } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect
        x={30}
        y={19}
        width={2}
        height={20}
        rx={1}
        transform="rotate(90 30 19)"
        fill={props.color || "#000"}
      />
    </Svg>
  );
}

export default SvgComponent;
