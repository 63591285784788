import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#fff"}>
        <Path
          opacity={0.4}
          d="M17.736 5.078l-8.428 8.475L6.75 10.99l8.441-8.473a.896.896 0 011.268-.005l.005.005 1.272 1.28a.91.91 0 010 1.28z"
        />
        <Path d="M9.305 13.556l-1.919 1.929a.896.896 0 01-1.268.005l-.004-.005L.264 9.6a.908.908 0 010-1.28l1.272-1.28a.896.896 0 011.266-.006l.005.006 6.498 6.515z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h18v18H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
