import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={21} height={17} viewBox="0 0 21 17" fill="none" {...props}>
      <Path
        opacity="0.4"
        d="M0.210247 13.7278C0.0691099 13.8866 -0.0060014 14.0934 0.000272013 14.3059C0.00654542 14.5183 0.0937296 14.7203 0.243997 14.8706L1.63275 16.2593C1.78306 16.4095 1.98509 16.4967 2.19751 16.5029C2.40994 16.5091 2.61671 16.4339 2.77556 16.2928L10.7499 9.25338L7.24993 5.75338L0.210247 13.7278ZM9.82305 1.61963L9.11618 2.3265L14.1768 7.38713L14.8837 6.67994L9.82305 1.61963Z"
        fill={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M9.04806 2.39486C8.30306 3.13986 7.96806 4.13079 8.01681 5.10642L11.3968 8.48579C12.3721 8.53517 13.3634 8.20017 14.1084 7.45454L14.1768 7.38642L9.11619 2.32642L9.04806 2.39486ZM14.9518 1.55111C14.6196 1.2188 14.2251 0.955193 13.791 0.775345C13.3569 0.595497 12.8916 0.50293 12.4217 0.50293C11.9518 0.50293 11.4864 0.595497 11.0523 0.775345C10.6182 0.955193 10.2237 1.2188 9.8915 1.55111L9.82306 1.61923L14.8837 6.67986L14.9518 6.61173C15.2841 6.27949 15.5477 5.88504 15.7276 5.45092C15.9074 5.01679 16 4.55148 16 4.08158C16 3.61167 15.9074 3.14636 15.7276 2.71224C15.5477 2.27811 15.2841 1.88366 14.9518 1.55142V1.55111ZM6.99994 16.5033H8.99994V10.7983L6.99994 12.5636V16.5033Z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
