import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H32V32H0z" />
      </Defs>
      <G
        transform="translate(4 4)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <G transform="translate(-.003 -.003)">
            <Path
              stroke="none"
              fill="none"
              d="M0.00340963695 0.00340963695H32.00340963695V32.00340963695H0.00340963695z"
            />
            <Path
              d="M26.003 4.003h4a1.89 1.89 0 012 2v24a1.89 1.89 0 01-2 2h-28a1.89 1.89 0 01-2-2v-24a1.89 1.89 0 012-2h4v-2a1.89 1.89 0 012-2 1.89 1.89 0 012 2v2h12v-2a2 2 0 114 0v2z"
              stroke="none"
              fill="#707070"
              fillRule="evenodd"
            />
            <Path
              stroke="#FFF"
              strokeWidth={1.1}
              fill="#FFF"
              fillRule="evenodd"
              d="M14.3465131 13.2430648H17.6603062V16.5568579H14.3465131z"
            />
            <Path
              stroke="#FFF"
              strokeWidth={1.1}
              fill="#FFF"
              fillRule="evenodd"
              d="M14.3465131 20.9672027H17.6603062V24.2809958H14.3465131z"
            />
            <Path
              stroke="#FFF"
              strokeWidth={1.1}
              fill="#FFF"
              fillRule="evenodd"
              d="M8.82927171 20.9672027H12.14306481V24.2809958H8.82927171z"
            />
            <Path
              stroke="#FFF"
              strokeWidth={1.1}
              fill="#FFF"
              fillRule="evenodd"
              d="M19.8637545 20.9672027H23.177547599999997V24.2809958H19.8637545z"
            />
            <Path
              stroke="#FFF"
              strokeWidth={1.1}
              fill="#FFF"
              fillRule="evenodd"
              d="M19.8637545 13.2430648H23.177547599999997V16.5568579H19.8637545z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
