import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function WhtsappIcon(props) {
  const color = props.color || "#333";
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={color} stroke={color} strokeWidth={1} fillRule="evenodd">
        <Path
          d="M14.376 23.597c-.648.128-1.253.181-1.562.139-2.39 0-4.314-.521-6.11-1.562-.255-.16-.446-.202-.69-.117-1.222.308-2.412.627-3.624.935-.255.085-.276.042-.223-.213.319-1.126.627-2.262.978-3.431.074-.308.053-.531-.139-.797C.552 14.567.658 9.638 3.4 5.951 6.258 2.127 10.19.544 14.855 1.542c4.6.967 7.544 3.963 8.596 8.531 1.488 6.268-2.741 12.494-9.075 13.524zM24.663 9.59C23.303 3.933 18.398.088 12.495 0c-.783.03-1.625.097-2.388.253C1.825 1.9-2.443 10.993 1.57 18.323c.137.205.137.39.107.614A185.578 185.578 0 00.044 24.7c-.079.34-.079.34.234.243a275.628 275.628 0 016.05-1.548c.176-.058.294-.058.44.03 2.595 1.314 5.326 1.664 8.194 1.138 6.843-1.314 11.336-8.246 9.701-14.974z"
          transform="translate(7.25 7.25)"
        />
        <Path
          d="M16.576 17.723c-.443.04-.895-.05-1.328-.18-2.926-.848-5.148-2.633-6.928-5.048-.664-.898-1.297-1.866-1.488-2.993-.272-1.327.14-2.494 1.146-3.402.342-.31 1.317-.459 1.71-.26.13.12.2.22.291.35.362.898.744 1.736 1.116 2.604.05.17.04.349-.05.538-.201.39-.503.719-.794 1.038-.242.21-.242.459-.07.698 1.045 1.746 2.463 2.994 4.374 3.722.271.12.482.08.653-.14.332-.369.654-.778.956-1.177.19-.26.442-.3.713-.19.634.29 1.247.589 1.85.858.162.08.282.15.433.19.634.32.634.369.553 1.037-.08 1.327-1.106 1.996-2.282 2.325-.312.07-.574.07-.855.03"
          transform="translate(7.25 7.25)"
        />
      </G>
    </Svg>
  );
}

export default WhtsappIcon;
