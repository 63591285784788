import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M20 .625C9.297.625.625 9.297.625 20S9.297 39.375 20 39.375 39.375 30.703 39.375 20 30.703.625 20 .625zm12.134 15.259L17.759 30.259a1.25 1.25 0 01-1.768 0l-8.125-8.125a1.25 1.25 0 010-1.768l1.768-1.768a1.25 1.25 0 011.768 0l5.473 5.474 11.723-11.724a1.25 1.25 0 011.768 0l1.768 1.768a1.25 1.25 0 010 1.768z"
        fill={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M17.759 30.259a1.25 1.25 0 01-1.768 0l-8.125-8.125a1.25 1.25 0 010-1.767l1.768-1.768a1.25 1.25 0 011.768 0l5.473 5.473L28.598 12.35a1.25 1.25 0 011.768 0l1.768 1.768a1.25 1.25 0 010 1.767L17.759 30.259z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
