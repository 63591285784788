import * as React from "react";
import Svg, { G, Path, Rect, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={26} height={21} viewBox="0 0 26 21" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M5.85 18.2H2.6v-3.25a.65.65 0 00-.65-.65H.65a.65.65 0 00-.65.65v5.2a.65.65 0 00.65.65h5.2a.65.65 0 00.65-.65v-1.3a.65.65 0 00-.65-.65zm0-18.2H.65A.65.65 0 000 .65v5.2a.65.65 0 00.65.65h1.3a.65.65 0 00.65-.65V2.6h3.25a.65.65 0 00.65-.65V.65A.65.65 0 005.85 0zm19.5 0h-5.2a.65.65 0 00-.65.65v1.3a.65.65 0 00.65.65h3.25v3.25a.65.65 0 00.65.65h1.3a.65.65 0 00.65-.65V.65a.65.65 0 00-.65-.65zm0 14.3h-1.3a.65.65 0 00-.65.65v3.25h-3.25a.65.65 0 00-.65.65v1.3a.65.65 0 00.65.65h5.2a.65.65 0 00.65-.65v-5.2a.65.65 0 00-.65-.65z"
        />
        <Rect x={5.2} y={8.667} width={15.6} height={3.467} rx={1.733} />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h26v20.8H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
