import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import CategoryConstants from "./CategoryConstants";

const CategoryResource = function ($http, PrestoSdk, CategoryConstants) {
  function getCategoryDetails(params) {
    var url =
      PrestoSdk.getHostName() +
      CategoryConstants.GET_CATEGORY_DETAILS.PATH +
      "/" +
      params.categoryId +
      ".json";
    delete params["categoryId"];
    for (key in params) {
      if (params.hasOwnProperty(key)) {
        if (-1 == url.indexOf("?")) {
          url = url + "?" + key + "=" + params[key];
        } else {
          url = url + "&" + key + "=" + params[key];
        }
      }
    }
    return $http.get(url);
  }

  function getUBUCategories() {
    var url =
      "https://staging-websites.s3-ap-southeast-1.amazonaws.com/UBU/ubu_categories.json";
    if (
      PrestoSdk.getHostName().indexOf("prestoweb") >= 0 ||
      PrestoSdk.getHostName().indexOf("api.staging") >= 0 ||
      PrestoSdk.getHostName().indexOf("prestostaging") >= 0 ||
      PrestoSdk.getHostName().indexOf("prestotelco") >= 0
    ) {
      url =
        "https://staging-websites.s3-ap-southeast-1.amazonaws.com/UBU/ubu_categories_staging.json";
    }
    return $http.get(url, { withCredentials: false });
  }

  function getUBUFilterCategories() {
    var url = "https://dashboard.ubu.world/ubu_tags_production.json";
    if (
      PrestoSdk.getHostName().indexOf("prestoweb") >= 0 ||
      PrestoSdk.getHostName().indexOf("api.staging") >= 0 ||
      PrestoSdk.getHostName().indexOf("prestostaging") >= 0 ||
      PrestoSdk.getHostName().indexOf("prestotelco") >= 0
    ) {
      url =
        "https://staging-websites.s3.ap-southeast-1.amazonaws.com/UBU/ubu_tags_staging.json";
    }
    return $http.get(url, { withCredentials: false });
  }

  function getScreenMetaData(params) {
    var url =
      "https://staging-websites.s3.ap-southeast-1.amazonaws.com/" +
      params.merchantName +
      "/" +
      params.screenName +
      "/meta_data.json";
    console.log("getScreenMetaData Url==>", url);
    return $http.get(url, { withCredentials: false });
  }

  function removeCategory(params) {
    var url =
      PrestoSdk.getHostName() + "/categories/" + params.categoryId + ".json";
    return $http.delete(url);
  }

  return {
    getCategoryDetails: getCategoryDetails,
    getUBUCategories: getUBUCategories,
    getScreenMetaData: getScreenMetaData,
    removeCategory: removeCategory,
  };
};

export default CategoryResource($http, PrestoSdk, CategoryConstants);
