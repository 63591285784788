import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={46}
      height={46}
      viewBox="0 0 46 46"
      fill={props.primaryColor || "#fff"}
      {...props}
    >
      <Path
        opacity={0.4}
        d="M23 .719C10.691.719.719 10.69.719 23S10.69 45.281 23 45.281 45.281 35.31 45.281 23 35.31.719 23 .719zm13.701 27.267l-1.527 1.528a2.147 2.147 0 01-3.046 0L23 20.386l-9.128 9.128a2.149 2.149 0 01-3.046 0L9.3 27.986a2.146 2.146 0 010-3.045l12.174-12.174a2.157 2.157 0 013.054 0L36.701 24.94a2.149 2.149 0 010 3.045z"
      />
      <Path d="M24.527 12.767L36.701 24.94a2.145 2.145 0 010 3.045l-1.527 1.528a2.147 2.147 0 01-3.046 0L23 20.386l-9.128 9.128a2.145 2.145 0 01-3.046 0L9.3 27.986a2.148 2.148 0 010-3.045l12.174-12.174a2.156 2.156 0 013.054 0z" />
    </Svg>
  );
}

export default SvgComponent;
