import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill={props.color || "#fff"}>
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path d="M0 0H29V29H0z" />
          <Path
            d="M13.693 17.255a5.05 5.05 0 005.06-5.059 5.206 5.206 0 00-5.06-5.24 5.05 5.05 0 00-5.06 5.06 5.332 5.332 0 005.06 5.239zM5.2 3.524c4.69-4.69 12.295-4.69 16.985 0 4.69 4.69 4.69 12.294 0 16.984L13.693 29 5.2 20.508C.683 15.748.683 8.284 5.2 3.523z"
            fill={props.color2 || "#707070"}
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
