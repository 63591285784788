import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      enableBackground="new 0 0 50 50"
      {...props}
    >
      <Path
        id="rectangle"
        d="M41.6 1c2 0 3.9.8 5.2 2.2C48.2 4.4 49 6.3 49 8.3V49H8.4c-2 0-3.9-.8-5.2-2.2C1.9 45.5 1 43.7 1 41.6V8.3c0-2 .8-3.9 2.1-5.2S6.4 1 8.4 1h33.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        stroke="#8f8f8f"
        strokeWidth={2}
      />
    </Svg>
  )
}

export default SvgComponent
