import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
    width={props.width || 60}
    height={props.height || 60}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke="#F2749B"
          strokeWidth={0.9}
          fill={props.color || "#F2749B"}
          cx={30}
          cy={30}
          r={29.55}
        />
        <Path
          d="M18 21.124v5.355c0 .404.158.79.44 1.076A1.49 1.49 0 0019.5 28h9c.828 0 1.5-.68 1.5-1.52V13.034a2.45 2.45 0 00-.703-1.72L16.695.713A2.379 2.379 0 0015 0c-.636 0-1.246.256-1.695.713L.703 11.31A2.45 2.45 0 000 13.035V26.48c0 .404.158.79.44 1.076A1.49 1.49 0 001.5 28h9c.828 0 1.5-.68 1.5-1.52v-5.356c0-1.68 1.343-3.042 3-3.042s3 1.362 3 3.042z"
          transform="translate(15 16)"
          fill={props.color1 || "#FFF"}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
