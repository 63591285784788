import * as React from "react";
import Svg, { Path } from "react-native-svg";

function LeftIcon(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      {...props}
    >
      <Path
        stroke={props.color || "#fff"}
        strokeWidth={2}
        transform="matrix(0 -1 -1 0 32 32)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 12L15.9153844 20 25 12"
      />
    </Svg>
  );
}

export default LeftIcon;
