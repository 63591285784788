import * as React from "react";
import Svg, { Path, G, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
<Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      >
        <Path
          d="M32 5.188V3.5A3.5 3.5 0 0028.5 0h-25A3.5 3.5 0 000 3.5v1.688c0 .172.14.312.312.312h31.376c.172 0 .312-.14.312-.312zM0 7.812V18.5A3.5 3.5 0 003.5 22h25a3.5 3.5 0 003.5-3.5V7.812a.312.312 0 00-.312-.312H.312A.312.312 0 000 7.812zM8 16a1 1 0 01-1 1H6a1 1 0 01-1-1v-1a1 1 0 011-1h1a1 1 0 011 1v1z"
          transform="translate(4 9)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
