import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import BaseItemCard from "@presto-cards/ItemCards/BaseItemCard";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import I18n from "i18n-js";
import { DotIndicator } from "react-native-indicators";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function ItemCard(props) {
  const { variation, updating, onUpdate, color } = props;
  const { theme } = useContext(ThemeContext);

  const Updating = () => {
    return (
      <View
        style={{
          alignItems: "center",
          height: 40,
        }}
      >
        <DotIndicator
          color={color || "#FD5B53"}
          maxScale={1.2}
          minScale={0.2}
          size={8}
        />
      </View>
    );
  };
  const AddButton = () => {
    return (
      <View style={{ width: 100 }}>
        <PrestoFramedButton
          buttonStyle="primary"
          size={"large"}
          bgColor={theme.buttonBgColor}
          titleColor={theme.primary}
          borderWidth={1}
          title={I18n.t("screen_messages.common.add")}
          onPress={() => onUpdate && onUpdate(variation, 1)}
        />
      </View>
    );
  };
  return (
    <BaseItemCard {...props} right={updating ? <Updating /> : <AddButton />} />
  );
}
