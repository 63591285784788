import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";
import _ from "lodash";

const CategoryItemResource = function ($http, PrestoSdk) {
  function getCategoryItemDetails(params) {
    var url =
      PrestoSdk.getHostName() + "/category_items/" + params.productId + ".json";
    return $http.get(url);
  }

  function notifyWhenAvailable(params) {
    var url =
      PrestoSdk.getHostName() +
      "/category_items/" +
      params.productId +
      "/notify_in_stock.json";
    return $http.post(url, params);
  }

  function getPromotionItems(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/category_items/promotion_items.json";

    return $http.get(url, { params: params });
  }

  function createProduct(params) {
    var url = PrestoSdk.getHostName() + "/category_items.json";
    return $http.post(url, params);
  }
  function updateCategoryItemDetails(params) {
    var url =
      PrestoSdk.getHostName() + "/category_items/" + params.id + ".json";
    return $http.put(url, params);
  }

  function deleteCategoryItem(params) {
    var url =
      PrestoSdk.getHostName() + "/category_items/" + params.id + ".json";

    return $http.delete(url, params);
  }

  function employeeStockCountUpdate(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/category_items/" +
      params.id +
      ".json";
    return $http.put(url, params);
  }

  function getDynamicPriceForItems(params) {
    let ids = params.item_ids;
    let string = _.join(ids, ",");
    var url =
      PrestoSdk.getHostName() +
      "/user/v1/category_items/get_dynamic_prices.json?item_ids=" +
      string;
    return $http.get(url);
  }

  return {
    getCategoryItemDetails: getCategoryItemDetails,
    notifyWhenAvailable: notifyWhenAvailable,
    getPromotionItems: getPromotionItems,
    createProduct: createProduct,
    updateCategoryItemDetails: updateCategoryItemDetails,
    deleteCategoryItem: deleteCategoryItem,
    employeeStockCountUpdate: employeeStockCountUpdate,
    getDynamicPriceForItems: getDynamicPriceForItems,
  };
};

export default CategoryItemResource($http, PrestoSdk);
