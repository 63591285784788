import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={23} height={20} viewBox="0 0 23 20" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M22.364 9.43C20.246 5.296 16.052 2.5 11.25 2.5 6.448 2.5 2.253 5.298.136 9.43a1.264 1.264 0 000 1.14c2.118 4.133 6.312 6.93 11.114 6.93 4.802 0 8.997-2.798 11.114-6.93a1.264 1.264 0 000-1.14zm-11.109 6.195h-.005a5.622 5.622 0 11-.006-11.244 5.622 5.622 0 01.011 11.244z"
        />
        <Path d="M14.87 10.971a3.741 3.741 0 11-7.222-1.96 1.87 1.87 0 002.613-2.613 3.723 3.723 0 014.61 4.573h-.002z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill={props.secondaryColor || "#FFF"} d="M0 0h22.5v20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
