import * as React from "react";
import Svg, { Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      {...props}
    >
      <Circle
        cx={16}
        cy={16}
        r={13.25}
        stroke={props.color || "#000"}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default SvgComponent;
