import * as React from "react";
import Svg, { Path } from "react-native-svg";

function RightIconWithoutPadding(props) {
  return (
    <Svg
      width={props.width || 12}
      height={props.height || 24}
      viewBox="0 0 12 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M10.359.297A.97.97 0 0111.69.236c.354.31.407.817.143 1.185l-.08.097-9.84 10.414a.098.098 0 00-.02.104l.02.032 9.84 10.414c.351.37.323.945-.063 1.282a.972.972 0 01-1.24.024l-.092-.085L.518 13.289a1.86 1.86 0 01-.115-2.445l.115-.133L10.358.297z"
        fill={props.color || "#000"}
        fillRule="nonzero"
        transform="matrix(-1 0 0 1 12 0)"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default RightIconWithoutPadding;
