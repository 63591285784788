import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M27.089 3.67c-3.212-2.736-7.987-2.244-10.935.797L15 5.656l-1.154-1.19C10.904 1.427 6.124.935 2.912 3.67-.766 6.81-.96 12.448 2.333 15.85l11.338 11.707a1.834 1.834 0 002.653 0L27.662 15.85c3.3-3.403 3.105-9.04-.573-12.181zm-3.094 10.84l-8.385 8.428a.895.895 0 01-1.271 0L6.136 14.69c-1.662-1.67-1.98-4.394-.49-6.224a4.479 4.479 0 016.666-.345l2.665 2.678 2.483-2.495c1.663-1.67 4.374-1.992 6.194-.495a4.533 4.533 0 01.341 6.7v.002z"
        />
        <Path d="M14.34 22.938L6.135 14.69c-1.662-1.67-1.98-4.394-.49-6.224a4.478 4.478 0 016.666-.345l2.665 2.678 2.483-2.495c1.663-1.67 4.374-1.992 6.194-.495a4.532 4.532 0 01.344 6.7l-8.388 8.43a.894.894 0 01-1.27 0z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h30v30H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
