import React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent({ color, ...props }) {
  return (
    <Svg
      width="15px"
      height="25px"
      viewBox="0 0 15 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-40 -366) translate(0 146) translate(40.6 221.46)"
        stroke={color} // Use the color prop for the stroke color
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M6.9 22.08c-3.213-4.258-5.154-7.35-5.821-9.276C-1.658 4.918 1.003 0 6.9 0c5.897 0 8.674 4.341 5.938 12.228-.668 1.925-2.648 5.21-5.938 9.852z"
          strokeLinejoin="round"
        />
        <Circle cx={6.90071016} cy={7.04} r={3} />
      </G>
    </Svg>
  );
}

export default SvgComponent;
