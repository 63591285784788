import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 25}
      height={props.height || 12}
      viewBox="0 0 25 12"
      {...props}
    >
      <Path
        d="M16.883-5.705A.9.9 0 0118.29-4.59l-.077.095L8.826 5.833a.1.1 0 00-.02.103l.02.031 9.388 10.328a.9.9 0 01-1.243 1.295l-.088-.085L7.494 7.178a1.9 1.9 0 01-.11-2.424l.11-.132 9.389-10.327z"
        fill={props.color || "#000"}
        fillRule="nonzero"
        transform="rotate(-90 12.724 5.9)"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}
