import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function ReportsIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        fill={props.color || "#707070"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Path
          d="M26.25 14.414h-4.337V.586a.586.586 0 00-1-.414l-1.66 1.66-1.66-1.66a.586.586 0 00-.829 0l-1.66 1.66-1.66-1.66a.586.586 0 00-.828 0l-1.66 1.66-1.66-1.66a.586.586 0 00-.828 0l-1.66 1.66-1.66-1.66a.586.586 0 00-.829 0l-1.66 1.66L1 .172A.585.585 0 000 .602v26.351A3.05 3.05 0 003.047 30h20.741a3.05 3.05 0 003.047-3.047V15a.586.586 0 00-.586-.586zM3.046 28.828a1.877 1.877 0 01-1.875-1.875V2.001l1.073 1.073c.229.23.6.23.829 0l1.66-1.66 1.66 1.66c.228.23.6.23.828 0l1.66-1.66 1.66 1.66c.229.23.6.23.829 0l1.66-1.66 1.66 1.66c.228.23.6.23.828 0l1.66-1.66 1.66 1.66c.228.23.6.23.828 0L20.741 2v13l.001.016v11.937c0 .707.243 1.358.648 1.875H3.047zm22.616-1.875a1.877 1.877 0 01-1.875 1.875 1.877 1.877 0 01-1.874-1.875V15.586h3.75v11.367z"
          fillRule="nonzero"
          transform="translate(7 5)"
        />
        <Path
          d="M17.14 17.82H7.857a.586.586 0 100 1.173h9.283a.586.586 0 100-1.172zM5.188 17.992a.59.59 0 00-.415-.171.59.59 0 00-.586.586.59.59 0 00.586.586.59.59 0 00.586-.586.59.59 0 00-.171-.415zM17.14 5.82H7.857a.586.586 0 100 1.173h9.283a.586.586 0 100-1.172zM5.188 5.992a.59.59 0 00-.415-.171.59.59 0 00-.586.586.59.59 0 00.586.586.59.59 0 00.586-.586.59.59 0 00-.171-.415zM17.14 9.82H7.857a.586.586 0 100 1.173h9.283a.586.586 0 100-1.172zM5.188 9.992a.59.59 0 00-.415-.171.59.59 0 00-.586.586.59.59 0 00.586.586.59.59 0 00.586-.586.59.59 0 00-.171-.415zM17.14 13.82H7.857a.586.586 0 100 1.173h9.283a.586.586 0 100-1.172zM5.188 13.992a.59.59 0 00-.415-.171.59.59 0 00-.586.586.59.59 0 00.586.586.59.59 0 00.586-.586.59.59 0 00-.171-.415zM17.14 21.83H7.857a.586.586 0 100 1.172h9.283a.586.586 0 100-1.172zM5.188 22.002a.59.59 0 00-.415-.172.59.59 0 00-.586.586.59.59 0 00.586.586.59.59 0 00.586-.586.59.59 0 00-.171-.414z"
          transform="translate(7 5)"
        />
      </G>
    </Svg>
  );
}

export default ReportsIcon;
