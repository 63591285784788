import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      enableBackground="new 0 0 50 50"
      {...props}
    >
      <Path
        id="rectangle"
        d="M41.6 1c2 0 3.9.8 5.2 2.2C48.2 4.4 49 6.3 49 8.3V49H8.4c-2 0-3.9-.8-5.2-2.2C1.9 45.5 1 43.7 1 41.6V8.3c0-2 .8-3.9 2.1-5.2S6.4 1 8.4 1h33.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        stroke="#d97803"
        strokeWidth={2}
      />
      <Path
        d="M38 14.1c-.3 0-.5.1-.7.3L21 33.4l-8.3-8.6c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l9.1 9.4c.4.4 1 .4 1.4 0l.1-.1 17-19.8c.4-.4.3-1.1-.1-1.4-.3-.1-.6-.2-.8-.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#d97803"
        stroke="#d97803"
        strokeMiterlimit={10}
      />
    </Svg>
  )
}

export default SvgComponent
