import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import PrestoIcon from "@presto-components/PrestoIcon";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";
import ThemeContext from "@presto-contexts/ThemeContext";
import Pressable from "@presto-components/Pressable/Pressable";
import { useAssets, Components } from "presto-react-components";
const AmountInput = Components.AmountInput;

export default function PlusMinusButton({
  disable,
  onPressMinus,
  onPressPlus,
  count,
  onUpdate,
  variation,
  mainColor,
  textColor,
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const styles = getStyle(theme);
  return (
    <FlexRowSpaceAligned
      styleSet={{
        ...styles.plusMinusButtonStyle,
        borderColor: mainColor || theme.primary,
      }}
    >
      <Pressable disabled={disable} onPress={onPressMinus}>
        <View
          style={{
            borderWidth: 1,
            borderColor: mainColor || theme.primary,
            width: 34,
            height: 34,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 6,
            backgroundColor: theme.buttonBgColor,
          }}
        >
          <PrestoIcon
            icon={
              <svgs.Minus
                color={mainColor || theme.primary}
                primaryColor={textColor || mainColor || theme.primary}
              />
            }
          />
        </View>
      </Pressable>

      <View
        style={{
          width: 45,
          height: 34,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
        }}
      >
        <AmountInput
          size={40}
          amount={count}
          textSize={16}
          onUpdate={(amount) => onUpdate(variation, amount)}
          textColor={mainColor || theme.primary}
          borderColor={mainColor || theme.primary}
          backgroundColor={"white"}
          onBlur={(amount) => onUpdate(variation, amount)}
        />
      </View>
      <Pressable disabled={disable} onPress={onPressPlus}>
        <View
          style={{
            borderWidth: 1,
            borderColor: mainColor || theme.primary,
            width: 34,
            height: 34,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 6,
            backgroundColor: theme.buttonBgColor,
          }}
        >
          <PrestoIcon
            icon={
              <svgs.Plus
                color={mainColor || theme.primary}
                primaryColor={textColor || mainColor || theme.primary}
              />
            }
          />
        </View>
      </Pressable>
    </FlexRowSpaceAligned>
  );
}

function getStyle(theme) {
  return {
    primaryTitle: {
      fontSize: 18,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    primaryContainer: {
      minWidth: 145,
      width: "100%",
      height: 50,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      shadowOpacity: 0,
    },
    tertiaryButtonTittle: {
      fontSize: 18,
      fontWeight: "600",
      textTransform: "capitalize",
    },
    secondaryButtonTittle: {
      fontSize: 18,
      fontWeight: "600",
      textTransform: "capitalize",
    },
    secondaryButtonContainer: {
      minWidth: 145,
      width: "100%",
      height: 50,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      shadowOpacity: 0,
    },
    tertiaryButtonContainer: {
      width: "100%",
      shadowOpacity: 0,
      height: 50,
      width: "100%",
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      shadowOpacity: 0,
      alignSelf: "center",
      borderWidth: 0.8,
    },
    smallButton: {
      minWidth: 80,
      borderRadius: 6,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 1,
      paddingHorizontal: 5,
      maxWidth: 130,
      height: 30,
    },
    smallButtonText: {
      fontSize: 12,
      textTransform: "uppercase",
    },
    darkButtonText: {
      fontSize: 14,
      textTransform: "uppercase",
      lineHeight: 16,
    },
    darkButtonContainer: {
      borderRadius: 6,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      borderWidth: 1,
      paddingHorizontal: 5,
      maxWidth: 100,
      minWidth: 90,
      height: 40,
      backgroundColor: theme.darkBgColor,
      borderColor: theme.darkBgColor,
    },
    mediumButton: {
      minWidth: 80,
      borderRadius: 6,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 1,
      paddingHorizontal: 5,
      maxWidth: 130,
      height: 40,
    },
    mediumButtonText: {
      fontSize: 12,
      textTransform: "uppercase",
    },
    plusMinusButtonStyle: {
      paddingVertical: 5,
      height: 35,
      maxWidth: 120,
      minWidth: 120,
      justifyContent: "space-around",
      alignItems: "center",
    },
    minusIcon: {
      borderWidth: 1,
      width: 28,
      height: 28,
      borderRadius: 2,
      alignItems: "center",
      textAlign: "center",
      paddingVertical: 7,
    },
    plusIcon: {
      borderWidth: 1,
      borderRadius: 2,
      width: 28,
      height: 28,
      alignItems: "center",
      textAlign: "center",
      paddingVertical: 5,
    },
    largeButtonContainer: {
      height: 100,
      width: 160,
      borderRadius: 8,
    },
  };
}
