import React from "react";
import { ScrollView, View } from "react-native";
import _ from "lodash";

export default class HorizontalButtonList extends React.Component {
  render() {
    this.scrollRef = React.createRef();
    const { renderButton, items, spacing, style } = this.props;
    return (
      <ScrollView
        ref={this.scrollRef}
        horizontal={true}
        style={[style]}
        showsHorizontalScrollIndicator={
          this.props.showsHorizontalScrollIndicator || false
        }
      >
        {_.map(items, (item, index) => {
          return (
            <View
              key={item.id || index}
              ref={item.id || index}
              style={{
                flexDirection: "row",
                paddingVertical: 10,
                marginEnd: index === items.length - 1 ? 20 : 0,
                // elevation: 10,
              }}
            >
              {renderButton(item, index)}
              <View style={{ width: spacing }}></View>
            </View>
          );
        })}
      </ScrollView>
    );
  }

  scrollToStart() {
    if (this.scrollRef) {
      this.scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true });
    }
  }
}

export class HorizontalGridButtonList extends React.Component {
  render() {
    const { renderButton, items, spacing, style } = this.props;
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          ...style,
        }}
      >
        {_.map(items, (item, index) => {
          return (
            <View
              key={item.id || index}
              ref={item.id || index}
              style={{
                flexDirection: "row",
                paddingVertical: 5,
                paddingLeft: 5,
                elevation: 10,
              }}
            >
              {renderButton(item, index)}
              <View style={{ width: spacing }}></View>
            </View>
          );
        })}
      </View>
    );
  }
}
