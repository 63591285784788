import * as React from "react";
import Svg, { G, Rect, Text, TSpan } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Rect
          stroke="#000"
          strokeWidth={1.5}
          x={3.75}
          y={7.75}
          width={32.5}
          height={21.5}
          rx={4}
        />
        <Text
          // fontFamily="SegoeUI-Semibold, Segoe UI"
          fontSize={16}
          fontWeight={500}
          fill="#000"
        >
          <TSpan x={6} y={27}>
            {"****"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default SvgComponent;
