import React, { useState, useContext, useEffect, useRef } from "react";
import { FlatList, View, Platform, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import CouponManager from "@presto-services/features/coupons/CouponManager";
import CouponComponent from "@presto-components/Coupons/Coupon";
import utils from "../../utils";
import I18n from "i18n-js";
import TextInputWithButtonComponent from "@presto-components/TextInputs/TextInputWithButtonComponent";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import OffersCardModel from "@presto-component-models/OffersCardModel";
import config from "@presto-common/config";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import moment from "moment";

export default function Coupons({ route }) {
  const { inSideNavigation } = route.params || {};
  const { theme } = useContext(ThemeContext);
  const { cart, applyCouponToCart, removeCouponFromCart } = useContext(
    CartContext
  );
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [textCouponCode, setTextCouponCode] = useState("");
  const { Navigator } = useContext(NavigatorContext);
  const userTypeEmployee = config?.user_type === "employee";

  const modalRef = useRef();
  const goBack = () => {
    modalRef.current?.close();
    setTimeout(() => {
      Navigator.emit({
        event: "goBack",
        params: {
          inSideNavigation: inSideNavigation,
        },
      });
    }, 100);
  };
  useEffect(() => {
    if (userTypeEmployee) {
      method = EmployeeManager.getCoupons(
        {},
        (response) => {
          setCoupons(response.data);
        },
        (error) => {
          console.log("Coupons response : ", JSON.stringify(error, null, 2));
        }
      );
    } else {
      CouponManager.getMyCoupons(
        (response) => {
          setCoupons(response.data);
        },
        (error) => {
          console.log("Coupons response : ", JSON.stringify(error, null, 2));
        }
      );
    }
  }, []);

  const applyCoupon = async (couponCode) => {
    setLoading(true);
    const [error, response] = await applyCouponToCart(couponCode);
    console.log("Apply coupon response : ", JSON.stringify(response, null, 2));
    setLoading(false);
    if (error) {
      setErrorMessage(utils.errorMessage(error));
      removeCouponFromCart(couponCode);
    } else {
      if (response.data && !response.data.discount_response) {
        setErrorMessage(I18n.t("screen_messages.coupons.coupon_not_applied"));
        removeCouponFromCart(couponCode);
      } else {
        setErrorMessage("");
        alert(I18n.t("screen_messages.coupons.coupon_applied"));
        Navigator.emit({
          event: "goBack",
          params: {
            inSideNavigation: inSideNavigation,
          },
        });
      }
    }
  };

  const onTextCouponApply = () => {
    if (!_.isEmpty(textCouponCode)) {
      applyCoupon(textCouponCode);
    }
  };

  const onCouponChange = ({ nativeEvent: { text } }) => {
    setTextCouponCode(text);
  };

  const onApplyCoupon = async (coupon) => {
    applyCoupon(coupon.code);
  };

  const onRemoveCoupon = async (coupon) => {
    setLoading(true);
    const [error, response] = await removeCouponFromCart(coupon.title);
    setLoading(false);
    if (error) {
      setErrorMessage(utils.errorMessage(error));
    } else {
      setErrorMessage("");
    }
  };

  const filterCoupons = () => {
    let items = _.cloneDeep(coupons);
    const now = moment();
    items = _.filter(items, (item) => {
      const endTime = moment(item.end_time);
      return endTime >= now;
    });
    return items;
  };

  const renderCoupon = ({ item }) => {
    return (
      <CouponComponent
        coupon={
          new OffersCardModel({
            coupon: item,
            cart: cart,
          })
        }
        onApplyRemoveCoupon={() => {
          cart.coupon_code == item.code
            ? onRemoveCoupon(item)
            : onApplyCoupon(item);
        }}
      />
    );
  };

  const renderFooter = () => {
    return (
      <View
        style={{
          width: "100%",
          paddingLeft: 5,
          paddingRight: 10,
        }}
      >
        <PrestoSolidButton
          size="extra-large"
          buttonStyle="secondary"
          title={I18n.t("screen_messages.common.back")}
          onPress={() => {
            goBack();
          }}
        />
      </View>
    );
  };
  return (
    <LoadingContainer bgColor={theme.appBackgroundColor} loading={loading}>
      <ScrollView>
        <View
          style={{
            marginHorizontal: theme.primaryPadding,
            backgroundColor: theme.appBackgroundColor,
            marginTop: Platform.OS == "web" ? 20 : 0,
          }}
        >
          <TextInputWithButtonComponent
            labelText=""
            theme={theme}
            value={textCouponCode}
            onChange={onCouponChange}
            placeholder={I18n.t("screen_messages.coupons.enter_coupon_code")}
            autoCapitalize="characters"
            buttonText={I18n.t("screen_messages.common.apply")}
            onPressButton={() => onTextCouponApply()}
            autoCorrect={false}
          />

          {errorMessage ? (
            <View style={{ paddingTop: 10 }}>
              <PrestoText color={theme.primary} fontStyle="400.normal">
                {errorMessage}
              </PrestoText>
            </View>
          ) : null}
        </View>
        <View
          style={{ marginHorizontal: theme.primaryPadding - 10, marginTop: 20 }}
        >
          <FlatList data={filterCoupons()} renderItem={renderCoupon} />
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}
