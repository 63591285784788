import React, { useContext } from "react";
import { Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import I18n from "i18n-js";
import DefaultModal from "@presto-components/Modals/DefaultModal";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import SectionLinkTextComponent from "@presto-components/SectionLinks/SectionLinkTextComponent";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import ModalContext from "@presto-contexts/ModalContext";
import utils from "../../utils/index";

export default function RegisterScreen({ navigation, route }) {
  const { inSideNavigation } = route?.params || {};
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { popRightModal } = useContext(ModalContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const isMobileWeb = utils.isMobileWeb();
  const isNextJsApp = utils.isNextJsApp();

  const goToContactUs = () => {
    Navigator.emit({
      event: "modal",
      params: {
        inSideNavigation: inSideNavigation,
        nextUrl: "/contact",
        tabName: "CONTACT",
        screenName: "CONTACT_SCREEN",
      },
    });
  };

  const goBack = () => {
    if (isMobileWeb && isNextJsApp) {
      popRightModal();
    } else {
      if (isDesktop) {
        setPaneState(false);
      } else {
        Navigator.emit({
          event: "goBack",
          params: {
            inSideNavigation: inSideNavigation,
          },
        });
      }
    }
  };
  const renderFooter = () => {
    return (
      <PrestoSolidButton
        size="extra-large"
        title="Close"
        titleColor={theme.white}
        buttonStyle="primary"
        onPress={goBack}
      />
    );
  };

  return (
    <DefaultModal
      keyboardAvoidView={false}
      goBack={goBack}
      heading={I18n.t("screen_messages.register.onregister_title")}
      footer={renderFooter()}
      style={theme.lightShadow}
    >
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        style={{
          backgroundColor: theme.white,
        }}
      >
        <FullWidthHorizontalPaddedBox>
          <PrestoText
            fontStyle="400.normal"
            color={theme.subHeader}
            extraStyle={{ marginBottom: 10 }}
          >
            {I18n.t("screen_messages.register.onregister_description")}
          </PrestoText>
          <SectionLinkTextComponent
            style={{ alignItems: "flex-start" }}
            leftText1={I18n.t("screen_messages.contact.need_assistance")}
            leftText2={I18n.t("screen_messages.contact.contact_support")}
            onPressButton={() => goToContactUs()}
            theme={theme}
            linkTextColor={theme.secondary}
          />
        </FullWidthHorizontalPaddedBox>
      </KeyboardAwareScrollView>
    </DefaultModal>
  );
}
