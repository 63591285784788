import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M26.645 14.877a.846.846 0 010-.75l1.093-2.214a2.511 2.511 0 00-1.093-3.343l-2.188-1.155a.85.85 0 01-.444-.607L23.58 4.38a2.53 2.53 0 00-2.86-2.066l-2.452.344a.858.858 0 01-.718-.232L15.76.715a2.542 2.542 0 00-3.535 0l-1.78 1.713a.858.858 0 01-.719.232l-2.451-.344a2.53 2.53 0 00-2.86 2.066l-.429 2.426a.85.85 0 01-.444.607L1.355 8.57a2.511 2.511 0 00-1.093 3.343l1.093 2.213a.846.846 0 010 .75L.262 17.09a2.511 2.511 0 001.093 3.343l2.188 1.155a.85.85 0 01.444.607l.429 2.426a2.526 2.526 0 002.86 2.066l2.451-.344a.858.858 0 01.719.232l1.78 1.713c.985.951 2.553.95 3.535-.006l1.78-1.713a.858.858 0 01.719-.232l2.452.345a2.53 2.53 0 002.86-2.067L24 22.19a.85.85 0 01.445-.608l2.187-1.155a2.511 2.511 0 001.094-3.342l-1.081-2.208zM10.639 6.975a3.084 3.084 0 012.852 1.89 3.055 3.055 0 01-.667 3.345 3.097 3.097 0 01-3.362.665 3.067 3.067 0 01-1.904-2.835 3.08 3.08 0 013.08-3.065zm-1.592 13.64a.844.844 0 01-1.406-.377.833.833 0 01.218-.808l11.1-11.04a.845.845 0 011.191 0 .834.834 0 010 1.184L9.047 20.614zm8.32 1.415a3.076 3.076 0 01-3.084-3.067 3.076 3.076 0 013.084-3.068 3.076 3.076 0 013.084 3.068 3.08 3.08 0 01-3.084 3.067z"
        transform="translate(6 5)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

