import * as React from "react";
import Svg, { Path } from "react-native-svg";

function RadioButtonNotSelected(props) {
  return (
    <Svg width="32px" height="32px" viewBox="0 0 32 32" {...props}>
      <Path
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 28.8C8.96 28.8 3.2 23.04 3.2 16 3.2 8.96 8.96 3.2 16 3.2c7.04 0 12.8 5.76 12.8 12.8 0 7.04-5.76 12.8-12.8 12.8z"
        fill={props.color || "#000"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default RadioButtonNotSelected;
