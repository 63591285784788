import * as React from "react"
import Svg, { Defs, Circle, G, Mask, Use, Path } from "react-native-svg"

function AppleRoundIcon(props) {
  return (
    <Svg
      width="39px"
      height="39px"
      viewBox="0 0 39 39"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Circle id="a" cx={19.5} cy={19.5} r={19.5} />
      </Defs>
      <G
        transform="translate(-1 -1) translate(1 1)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Circle
          stroke="#979797"
          strokeWidth={0.96}
          cx={19.5}
          cy={19.5}
          r={19.02}
        />
        <G mask="url(#b)">
          <Path
            d="M9.23 0h30.54c3.21 0 4.373.334 5.547.962a6.542 6.542 0 012.721 2.721C48.666 4.857 49 6.021 49 9.23v30.54c0 3.21-.334 4.373-.962 5.547a6.542 6.542 0 01-2.721 2.721c-1.174.628-2.338.962-5.547.962H9.23c-3.21 0-4.373-.334-5.547-.962a6.542 6.542 0 01-2.721-2.721C.334 44.143 0 42.979 0 39.77V9.23c0-3.21.334-4.373.962-5.547A6.542 6.542 0 013.683.962C4.857.334 6.021 0 9.23 0z"
            transform="translate(-5 -5)"
            fill="#000"
          />
          <Path
            d="M24.767 15.577c.999 0 2.25-.695 2.995-1.623.675-.84 1.168-2.015 1.168-3.189 0-.16-.014-.319-.043-.45-1.11.044-2.446.77-3.248 1.74-.633.74-1.21 1.9-1.21 3.088 0 .174.029.347.043.405.07.015.183.03.295.03zm-3.515 17.539c1.364 0 1.968-.942 3.67-.942 1.73 0 2.11.913 3.628.913 1.49 0 2.49-1.42 3.431-2.812 1.055-1.594 1.49-3.16 1.519-3.232-.098-.03-2.953-1.232-2.953-4.61 0-2.928 2.25-4.247 2.376-4.348-1.49-2.203-3.754-2.261-4.373-2.261-1.673 0-3.038 1.043-3.895 1.043-.928 0-2.152-.985-3.6-.985-2.757 0-5.555 2.348-5.555 6.783 0 2.754 1.04 5.668 2.32 7.552 1.097 1.594 2.053 2.899 3.432 2.899z"
            fill="#FFF"
            fillRule="nonzero"
            transform="translate(-5 -5)"
          />
        </G>
      </G>
    </Svg>
  )
}

export default AppleRoundIcon
