export default {
  "Google_play_symbol_2x" : require("./images/Google_play_symbol_2x.png"),
  "Icon" : require("./images/Icon.png"),
  "Logo" : require("./images/Logo.png"),
  "SplashBackground" : require("./images/SplashBackground.png"),
  "ZipPlusTruck" : require("./images/ZipPlusTruck.png"),
  "bannerTwo" : require("./images/bannerTwo.png"),
  "headerLogo" : require("./images/headerLogo.png"),
  "logo_transparent" : require("./images/logo_transparent.png"),
  "textLogo" : require("./images/textLogo.png"),
  "track_truck" : require("./images/track_truck.png")
};
