import * as React from "react"
import Svg, { Defs, Path, G, Circle, Mask, Use } from "react-native-svg"

const GoToTop =(props)=> {
  return (
    <Svg
      width="50px"
      height="50px"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="00-a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill="#0D1D30" cx={25} cy={25} r={25} />
        <G transform="translate(10 11) matrix(1 0 0 -1 0 29)">
          <Mask id="00-b" fill="#fff">
            <Use xlinkHref="#00-a" />
          </Mask>
          <G mask="url(#00-b)" fill="#FFF" fillRule="nonzero">
            <Path
              transform="rotate(90 14.5 14.5)"
              d="M14.5 -3.52756019e-15L11.8628125 2.6371875 21.8424375 12.6168125 -4.08568263e-14 12.6168125 -4.08568263e-14 16.3831875 21.84425 16.3831875 11.8628125 26.3628125 14.5 29 29 14.5z"
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default GoToTop
