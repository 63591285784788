import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function CartIcon(props) {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="none" fillRule="evenodd">
        <Path
          d="M2.836 11.304l1.203-.126a2 2 0 012.137 1.46l3.73 13.607a2 2 0 002.138 1.46l18.595-1.954a2 2 0 001.766-1.672l1.81-11.294a2 2 0 011.767-1.673l1.07-.112h0"
          stroke={props.color || "#000"}
          strokeLinecap="round"
        />
        <Path
          d="M14 28.186a3.625 3.625 0 100 7.25 3.625 3.625 0 000-7.25zm0 1.25a2.375 2.375 0 110 4.75 2.375 2.375 0 010-4.75zM29 28.186a3.625 3.625 0 100 7.25 3.625 3.625 0 000-7.25zm0 1.25a2.375 2.375 0 110 4.75 2.375 2.375 0 010-4.75zM31.5 15.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"
          fill={props.color || "#000"}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
}

export default CartIcon;
