import * as React from "react";
import Svg, { Path } from "react-native-svg";

function EmailFilledIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M30.625 4.333H4.375L17.5 15.167 30.625 4.333zM0 4.333C.007 1.943 1.962.007 4.375 0h26.25C33.038.007 34.993 1.943 35 4.333v17.334c-.007 2.39-1.962 4.326-4.375 4.333H4.375C1.962 25.993.007 24.057 0 21.667V4.333z"
        transform="translate(2.5 7)"
        fill={props.color || "#707070"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default EmailFilledIcon;
