import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function EditIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(5 8.24)" fill={props.color || "#707070"}>
          <Path
            d="M25.367 25.76H3.623A3.634 3.634 0 010 22.125V5.215c.005-2 1.624-3.62 3.623-3.625h8.349c.669 0 1.47.454 1.47 1.123 0 .67-.801 1.3-1.47 1.3h-8.35c-.668 0-1.21.542-1.21 1.211v16.901a1.212 1.212 0 001.21 1.212h21.745c.669 0 1.21-.543 1.21-1.212V8.292c0-.67.69-1.596 1.359-1.596S29 7.622 29 8.292v13.833a3.635 3.635 0 01-3.633 3.635z"
            fillRule="nonzero"
          />
          <Path d="M11 15.1052374L16.0192032 15.1052374 19.6795932 11.5735754 26.0809479 5.39734268 21.076 0 11 9.68771415z" />
        </G>
        <Path
          fill="#FFF"
          d="M18 18.7541844L26.022 10.8853641 28.3587914 13.4330006 20.0997181 21.3875667 18 21.3875667z"
        />
      </G>
    </Svg>
  );
}

export default EditIcon;
