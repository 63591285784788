import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function TapAction(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fillOpacity={0.5} fill="#CCC" cx={20} cy={20} r={20} />
        <G fill={props.color || "#7E5C5B"} fillRule="nonzero">
          <Path
            d="M.667 9.333a.667.667 0 00.666-.666c0-4.044 2.991-7.334 6.667-7.334 3.676 0 6.667 3.29 6.667 7.334a.667.667 0 001.333 0C16 3.888 12.412 0 8 0S0 3.888 0 8.667c0 .368.299.666.667.666z"
            transform="translate(9 4)"
          />
          <Path
            d="M4 8.667C4 6.093 5.795 4 8 4s4 2.093 4 4.667a.667.667 0 001.333 0c0-3.308-2.392-6-5.333-6-2.941 0-5.333 2.692-5.333 6a.667.667 0 001.333 0z"
            transform="translate(9 4)"
          />
          <Path
            d="M14.667 14.996v-.356A2.67 2.67 0 0012 11.973c-.485 0-.941.131-1.333.359V7.987A2.663 2.663 0 008 5.333a2.663 2.663 0 00-2.667 2.654v8.304a2.608 2.608 0 00-1.28-.331c-.833 0-1.53.299-2.018.863-.583.674-.82 1.698-.655 2.809.71 4.659 2.404 8.584 5.035 11.667.38.446.94.701 1.536.701h10.44c.809 0 1.532-.473 1.841-1.207 1.51-3.585 2.397-7.468 2.433-10.648.016-1.329-.805-2.468-1.996-2.769a2.72 2.72 0 00-2.002.273v-.356A2.662 2.662 0 0016 14.64c-.485 0-.941.13-1.333.356zm2.666 2.297v2.654a.667.667 0 001.334 0c0-.828.841-1.488 1.676-1.278.581.147.997.762.99 1.462-.034 2.97-.905 6.762-2.33 10.144a.655.655 0 01-.614.392H7.95a.685.685 0 01-.52-.231c-2.468-2.89-4.06-6.593-4.732-11-.106-.715.023-1.365.346-1.741.232-.27.562-.4 1.008-.4.717 0 1.28.585 1.28 1.333a.667.667 0 101.336-.001V7.987c0-.715.61-1.32 1.333-1.32s1.333.605 1.333 1.32v6.653a.667.667 0 001.334 0c0-.735.598-1.333 1.333-1.333s1.333.598 1.333 1.333v2.653a.667.667 0 001.334 0c0-.716.61-1.32 1.333-1.32s1.333.606 1.333 1.32z"
            transform="translate(9 4)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default TapAction;
