import * as React from "react"
import Svg, { Defs, Pattern, Use, Image, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Pattern
          id="b"
          patternUnits="objectBoundingBox"
          x="0%"
          width="100%"
          height="100%"
        >
          <Use xlinkHref="#a" />
        </Pattern>
        <Image
          id="a"
          width={40}
          height={40}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAAB65masAAAEWUlEQVRYCe2WW4hXVRSHp9KZLE2iNKKsMbR5MKOrVEhvXSxRUQwEpQQTNKxI8KE3sUi6EEQlZQ/RhRIxIkF9UfH+EDhSok6kDmWjaXY1y6z0+4bZsedw9px9YBx7mB98/3PO3muf/9prrb33aWjoV38EeiUCQ3nLDHgBruyVN/biS6bxrnY41cUWrlfA/0IL8ULH3oXRcDMcgE1w3p2cjRNnQAenwwUwEF4C289rJO/Egb+6nHmU6zewD76GvbAADsE26PNIDuFP98BmaAQ1HCbBBLgM1FjogD6P5Cv86QkYBVUag4G2fRbJe/gz62se5OhBjIzi97AVzmm6m/iDXeAKvQiqdCEG22ERtMC3YFl0c3IADbFMiwV8I7jqcnUaQ2vLcePgH6jSxRhcAzrVBi6m9bAWpsIhaIgdbOZ5A/wLO8FU5UhnnNit8Czshhz9idFRcEJG8Hn4AdwnjWKng1z/0xLuDkPdo8hU7eiiiWsdGTX3SSf5OjRDUu/R05rsLe/QOccZiWaoqzcY8DtMSw2MU+xMboGlYC1UpdganQmz4FVoBlNdJm3duN2sXbFqLsyHR2AVVOodLH4C06yzOfyNnRFwL6uydyEdAzfsEXASXoYe5cyCTNV1MBks0riPx05ZKy4i+0yv5Mr6XAQPgcfdVXAHOLks6eDmCku3kpFgBBorbMu6HWuqLZ+HywyKbbkRmMjAz6ANnL21tAeWwU2QKzOgPoU1nXc1fsoieAnjl4MzTvEzfRZ8jq7FyAg+kGOsTU8R9Lh6C+ZoGOlp7uNVN5Rn7WZHNsXb4TR4aoj1Owy6HWk8V6oYwVmMKIvavbT7xVLs+5E2ayzW/TzsAE8F6QAXhVuNZ++T0KNSEfTL10iVyT6jW9TlNDweNZrGlWCtTQAnNh6s2bthMTwDgyCplIOjGTE2MUrnUivYiPnOAeCG/yIsgS/hCLgPHoeD0ApmQdukUp3XM8JIuQm7mbqBB7mKT0FbaOB6O1gS1tilMBiuho9ATQEPgvB/n3P/HLin9qgwoGhkIStf8Cv84kOXPD3+gLjNiSjHiStV6aiy3jaBk7bf6PmeWooXiek9DcWF4LNpfDPR5x+H+lzN/QrQoTL5edYOQ8o6Q1sqgl9hYCrL6tDNNkQovCdc13Njv3oK1sFGcJP/DZTlYSTvAtt8TirloINeg+UlI01NcCLu9s/ejhoOcO/KXQAToQn8ir4NDMB+mAepydLVXXGK7XGWn0AxzXNps/iL7U/QViUXkZO3TGrrfUZYyLH8OPgYYmesTRdPaDvJ/ULI0QiMdNAvmtqy8HeXjLLIZ8BGcLvROZ38Dj6EcZCrMRg6MVNfW9aJg72m5GnRAjeA9VRX1vRR8D1ZiheJK+4DWAUW+xfgYgjbhM77HNrcTjw1Qj+3pXKcX0VO/D6YCWYiS8WXNzLKenoM/OLoDfkfrny3raWwBrJVdDAMNLIeWan+YJd7NepuQ/3q8wicBYgGHg7CchH0AAAAAElFTkSuQmCC"
        />
      </Defs>
      <Path
        fill="url(#b)"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        d="M0 0H40V40H0z"
      />
    </Svg>
  )
}

export default SvgComponent
