import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import PrestoText from "@presto-components/PrestoText";
import I18n from "i18n-js";
import OrderManager from "@presto-services/features/orders/OrderManager";
import _, { isEmpty } from "lodash";

export default function TransactionCardComponent(props) {
  const { transaction, theme, rightTextColor } = props || {};
  const [orderItem, setOrderItem] = useState([]);

  const styles = getStyles(theme);

  const getOrderDetails = (orderId) => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        const arr = filterOrderItemByLineId(response.data?.items);
        setOrderItem(arr);
      },
      (error) => {
        console.log("error", error.message);
      }
    );
  };

  const filterOrderItemByLineId = (data) => {
    return _.filter(data, (item) => item?.id === transaction.getLineItemId);
  };

  useEffect(() => {
    if (transaction.type === "DEBIT") {
      if (transaction.getLineItemId) {
        getOrderDetails(transaction.getOrderId);
      }
    }
  }, [transaction.id]);

  const getExcessText = () => {
    let str = `${transaction.transactionIdText}`;
    let first = _.first(orderItem);
    if (first?.excess_quantity_amount) {
      str += `  ${first?.excess_quantity_amount}`;
    }
    if (first?.name) {
      str += ` for item ${first.name}`;
    }
    return str;
  };

  return (
    <View style={styles.cardContainer}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color="#333333"
            extraStyle={styles.titleStyle}
          >
            {transaction.transactionTypeTitle}
          </PrestoText>
        </View>
        <View>
          <PrestoText
            fontStyle="500.medium"
            color={
              rightTextColor ||
              (transaction.type === "DEBIT" ? theme.primary : theme.tertiary)
            }
            size={16}
            extraStyle={styles.titleStyle}
          >
            {transaction.amount}
          </PrestoText>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginTop: 5 }}>
        {transaction.transactionIdText ? (
          <PrestoText color="#666666" size={12}>
            {getExcessText()}
          </PrestoText>
        ) : null}
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: theme.primaryPadding,
        }}
      >
        <View style={{}}>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color="#666666"
            extraStyle={styles.titleStyle}
          >
            {I18n.t("screen_messages.datetime.date_and_time")}
          </PrestoText>
        </View>
        <View>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color="#666666"
            extraStyle={styles.titleStyle}
          >
            {transaction.formattedDate}
          </PrestoText>
        </View>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    cardContainer: {
      paddingHorizontal: theme.primaryPadding,
      paddingVertical: theme.primaryPadding,
      backgroundColor: "#EDEDED",
      borderRadius: 8,
      marginBottom: 20,
      flexDirection: "column",
      justifyContent: "space-between",
      ...theme.lightShadow,
    },
    titleStyle: {
      letterSpacing: 0.5,
      lineHeight: 16,
    },
  };
}
