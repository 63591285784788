import * as React from "react";
import Svg, { Defs, Circle, G, Mask, Use, Path, Rect } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Circle id="noShow" cx={16} cy={16} r={16} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(4 4)">
          <Mask id="mainContainer" fill={props.color2 || "#FFFFFF"}>
            <Use xlinkHref="#noShow" />
          </Mask>
          <Use
            stroke={props.color || "#FAA85D"}
            strokeWidth={0.8}
            fill={props.color || "#FAA85D"}
            xlinkHref="#noShow"
          />
          <G
            mask="url(#mainContainer)"
            fill={props.color2 || "#FFFFFF"}
            fillRule="nonzero"
          >
            <Path
              d="M0 27.6v-4.533C0 18.08 5.04 14 11.2 14c6.16 0 11.2 4.08 11.2 9.067V27.6H0zm5.6-22a5.6 5.6 0 1111.2 0 5.6 5.6 0 01-11.2 0z"
              transform="translate(4.8 4.8)"
            />
          </G>
        </G>
        <G
          transform="rotate(45 8.55 25.45)"
          fill={props.color1 || "#E21111"}
          stroke={props.color1 || "#E21111"}
          strokeWidth={1.568}
        >
          <Rect x={13.0928235} y={0.784} width={1} height={25.432} rx={0.5} />
          <Path
            d="M13.156.847c.387-.063.576-.035.7.054.034.025.051.06.051.099h0v25a.215.215 0 01-.216.216c-.285 0-.446.026-.535-.063a.215.215 0 01-.063-.153h0V1c0-.06.024-.114.063-.153z"
            transform="rotate(90 13.5 13.5)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
