import * as React from "react";
import Svg, { Defs, Circle, G, Mask, Use, Path } from "react-native-svg";

function BookIcon(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      {...props}
    >
      <Defs>
        <Circle id="circle" cx={20} cy={20} r={15} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#circle" />
        </Mask>
        <Use
          stroke={props.strokeColor || "#707070"}
          strokeWidth={1}
          xlinkHref="#circle"
        />
        <G
          mask="url(#b)"
          fill={props.color || "#707070"}
          stroke={props.strokeColor || "#707070"}
        >
          <G transform="translate(11.25 11)">
            <Path d="M8.25 0.5H9.25V16.5H8.25z" />
            <Path
              transform="rotate(90 8.5 8.5)"
              d="M8.25 0.5H9.25V16.5H8.25z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default BookIcon;
