import * as React from "react"
import Svg, { G, Path, Rect } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(4 4)"
        stroke={"#EAE9ED"}
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M0 6.52V30a2 2 0 002 2h28a2 2 0 002-2V6.52a2 2 0 00-2-2H2a2 2 0 00-2 2z"
          fill={props.color || "#F2749B"}
        />
        <Path d="M22.79 24.98v2a1 1 0 001 1h2.08a1 1 0 001-1v-2a1 1 0 00-1-1h-2.08a1 1 0 00-1 1zM22.79 16.98v2a1 1 0 001 1h2.08a1 1 0 001-1v-2a1 1 0 00-1-1h-2.08a1 1 0 00-1 1zM13.84 24.98v2a1 1 0 001 1h2.079a1 1 0 001-1v-2a1 1 0 00-1-1h-2.08a1 1 0 00-1 1zM13.84 16.98v2a1 1 0 001 1h2.079a1 1 0 001-1v-2a1 1 0 00-1-1h-2.08a1 1 0 00-1 1zM4.89 24.98v2a1 1 0 001 1h2.079a1 1 0 001-1v-2a1 1 0 00-1-1h-2.08a1 1 0 00-1 1zM4.89 16.98v2a1 1 0 001 1h2.079a1 1 0 001-1v-2a1 1 0 00-1-1h-2.08a1 1 0 00-1 1z" />
        <G transform="translate(5.517)" fill={props.color || "#F2749B"}>
          <Rect
            x={0.5}
            y={0.5}
            width={2.31034483}
            height={8.03841637}
            rx={1.15517241}
          />
          <Rect
            x={17.0517241}
            y={0.5}
            width={2.31034483}
            height={8.03841637}
            rx={1.15517241}
          />
        </G>
        <Path d="M0 11.0296572L31.4991126 11.0296572" />
      </G>
    </Svg>
  )
}

export default SvgComponent
