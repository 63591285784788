import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke={props.color || "#000"} strokeWidth={1.32} fill={props.color1 || "none"} fillRule="evenodd">
        <Path
          d="M4.113 4.246c5.693-5.661 14.924-5.661 20.617 0a14.441 14.441 0 010 20.504L14.422 35 4.112 24.75a14.833 14.833 0 010-20.504z"
          transform="translate(6 3)"
        />
        <Path
          d="M14.422 20.822a6.147 6.147 0 004.346-1.785 6.078 6.078 0 001.795-4.321c.024-3.419-2.704-6.23-6.14-6.326a6.147 6.147 0 00-4.347 1.785 6.079 6.079 0 00-1.796 4.322c.054 3.387 2.74 6.153 6.142 6.325z"
          transform="translate(6 3)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
