import * as React from "react"
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width ||"40px"}
      height={props.width ||"40px"}
      viewBox="0 0 40.0 40.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <ClipPath id="sad1">
          <Path d="M22.425 16.469c1.293 0 2.533.51 3.447 1.418a4.821 4.821 0 01.606 6.116 4.87 4.87 0 01-2.187 1.785 4.906 4.906 0 01-2.817.275 4.885 4.885 0 01-2.496-1.325 4.814 4.814 0 01-1.057-5.279 4.85 4.85 0 011.796-2.174 4.898 4.898 0 012.708-.816zm-17.55 0c1.293 0 2.533.51 3.447 1.418a4.822 4.822 0 01.606 6.116 4.87 4.87 0 01-2.187 1.785 4.906 4.906 0 01-2.817.275 4.885 4.885 0 01-2.496-1.325A4.814 4.814 0 01.37 19.459a4.852 4.852 0 011.796-2.174 4.898 4.898 0 012.708-.816zM7.313 0A.49.49 0 017.8.484v3.391h1.463a.49.49 0 01.487.484v.97a.483.483 0 01-.487.483H6.338a.49.49 0 01-.488-.484V.484A.483.483 0 016.338 0z" />
        </ClipPath>
        <ClipPath id="sad2">
          <Path d="M22.425 0c.776 0 1.52.306 2.068.851.549.545.857 1.284.857 2.055v2.906h2.687a2.935 2.935 0 012.066.854l6.088 6.049c.549.544.858 1.282.859 2.052v6.545h.975a.98.98 0 01.69.284.966.966 0 01.285.685v1.938a.966.966 0 01-.285.685.978.978 0 01-.69.283h-3.056c-.478-3.273-3.264-5.812-6.694-5.812a6.8 6.8 0 00-6.726 5.812H17.45c-.477-3.278-3.3-5.812-6.726-5.812s-6.216 2.539-6.693 5.812H2.925c-.776 0-1.52-.306-2.068-.85A2.897 2.897 0 010 22.28V2.906c0-.77.308-1.51.857-2.055A2.935 2.935 0 012.925 0zm-7.138 3.422a6.866 6.866 0 00-3.943-.385 6.839 6.839 0 00-3.495 1.855 6.768 6.768 0 00-1.868 3.473 6.74 6.74 0 00.389 3.918 6.791 6.791 0 002.513 3.043 6.856 6.856 0 006.404.627c.829-.34 1.581-.84 2.215-1.47a6.74 6.74 0 00.848-8.564 6.816 6.816 0 00-3.063-2.497zm12.75 5.297H25.35V15.5h8.775v-.733L28.037 8.72z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#sad1)" transform="translate(1 5) translate(5.85 4.844)">
        <Path
          fill="#707070"
          d="M5.55111512e-17 0L27.3001284 0 27.3001284 26.1562571 5.55111512e-17 26.1562571 5.55111512e-17 0z"
        />
      </G>
      <G clipPath="url(#sad2)" transform="translate(1 5)">
        <Path fill="#707070" d="M0 0L39 0 39 25.1874888 0 25.1874888 0 0z" />
      </G>
    </Svg>
  )
}

export default SvgComponent
