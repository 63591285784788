import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function AddressIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H30V30H0z" />
      </Defs>
      <G
        transform="translate(5 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path d="M0 0H30V30H0z" />
          <Path
            d="M14.166 17.85a5.224 5.224 0 005.233-5.233 5.385 5.385 0 00-5.233-5.42 5.224 5.224 0 00-5.235 5.232 5.516 5.516 0 005.235 5.421zM5.379 3.645c4.852-4.852 12.719-4.852 17.571 0 4.852 4.852 4.852 12.719 0 17.57L14.166 30l-8.787-8.784C.706 16.29.706 8.57 5.38 3.645z"
            fill={props.color || "#707070"}
          />
        </G>
      </G>
    </Svg>
  );
}

export default AddressIcon;
