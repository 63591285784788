import I18n from "i18n-js";
import utils from "../utils/index";
import _ from "lodash";
import config from "@presto-common/config";
export default class TrackOrderModel {
  constructor({ order, count = null }) {
    this.order = order;
    this.count = count;
  }

  get orderFriendlyIdWithTitle() {
    return I18n.t("screen_messages.orders.order_with_id", {
      query: this.order.friendly_id,
    });
  }

  get deliveryMessage() {
    if (this.order?.status === "REJECTED") {
      return I18n.t("screen_messages.orders.order_status_choices.rejected");
    } else if (this.order?.status === "DELIVERED") {
      return I18n.t("screen_messages.orders.order_status_choices.delivered");
    } else if (this.order?.status === "CLOSED") {
      return I18n.t("screen_messages.orders.order_status_choices.delivered");
    } else if (this.order?.status === "DISPATCHED") {
      return I18n.t("screen_messages.orders.order_status_choices.dispatched");
    } else if (this.order?.status === "ACCEPTED") {
      return I18n.t("screen_messages.orders.order_status_choices.accepted");
    } else {
      return I18n.t("screen_messages.orders.order_status_choices.processing");
    }
  }

  get orderTitle() {
    return I18n.t("screen_messages.title_singular");
  }

  get countDetails() {
    if (this.count > 1) {
      return I18n.t("screen_messages.orders.count_items", {
        count: this.count,
      });
    } else
      return I18n.t("screen_messages.orders.count_item", {
        count: this.count,
      });
  }

  get stringWith() {
    return I18n.t("screen_messages.common.with_and_space_at_end");
  }

  get orderIdWithTitle() {
    return I18n.t("screen_messages.orders.no_followed_by_id", {
      orderId: this.order.friendly_id,
    });
  }

  get reOrderTitle() {
    return I18n.t("screen_messages.orders.reorder");
  }

  get repeatOrderTitleWithAmount() {
    const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
    return I18n.t("screen_messages.orders.repeat_last_order_of_amount", {
      amount: utils.formattedPriceRoundOff(this.order.total_amount, noRoundOff),
    });
  }

  get repeatOrderMessageWithDateAndCount() {
    return I18n.t("screen_messages.orders.repeat_order_message", {
      date: utils.formattedDate(this.order.created_at),
      count: this.count,
    });
  }

  get itemCountTitle() {
    return I18n.t("screen_messages.orders.items_count", {
      count: this.order.no_of_items,
    });
  }

  get orderAmountWithTitle() {
    const currency_method = _.get(config, "constants.currency_method", null);
    if (currency_method) {
      return utils[currency_method]({
        cents: this.totalAmount * 100,
        undefined,
        showDecimal: true,
      });
    }
    return I18n.t("screen_messages.total_amount", {
      amount: this.totalDeliveredAmount,
    });
  }

  get totalAmount() {
    return this.order.total_amount;
  }

  get totalDeliveredAmount() {
    const undeliveredAmountArray = _.map(this.items, (item) => {
      let returned_amount = item.returned_amount;
      returned_amount = returned_amount / 100;
      return item.undelivered_amount + returned_amount;
    });
    const totalUndeliveredAmount = _.sum(undeliveredAmountArray);
    const totalDeliveredAmount = _.subtract(
      this.totalAmount,
      totalUndeliveredAmount
    );
    return utils.formattedPrice(totalDeliveredAmount);
  }

  get totalUndeliveredAmount() {
    const undeliveredAmountArray = _.map(this.items, "undelivered_amount");
    const totalUndeliveredAmount = _.sum(undeliveredAmountArray);
    return totalUndeliveredAmount;
  }

  get items() {
    return this.order.items;
  }

  get orderCreatedOnTitle() {
    return I18n.t("screen_messages.orders.ordered_on", {
      date: utils.formattedDate(this.order.created_at),
    });
  }

  get orderSubStatus() {
    const subStatus = this.order?.sub_status;
    return subStatus === "PARTIAL_DELIVERY"
      ? I18n.t("screen_messages.partial_delivery")
      : null;
  }

  get date() {
    let dateObj = new Date(this.order.delivered_at);
    let date =
      dateObj.getMonth() +
      " " +
      dateObj.getDate() +
      ", " +
      dateObj.getFullYear();
    return date;
  }

  get orderFriendlyId() {
    return this.order.friendly_id;
  }

  get itemCount() {
    return this.order.no_of_items;
  }

  get orderAmount() {
    return this.order.total_amount;
  }

  get orderStatus() {
    return this.order.status;
  }

  get orderStatusLabel() {
    return _.capitalize(this.order.status);
  }

  get trackOrderButton() {
    return this.order?.status === "REJECTED"
      ? I18n.t("screen_messages.common.details")
      : I18n.t("screen_messages.orders.track");
  }

  get paymentSatus() {
    return this.order?.payment_status;
  }

  get reOrderButton() {
    return I18n.t("screen_messages.orders.reorder");
  }

  get viewOrderButton() {
    return I18n.t("screen_messages.view_order_str");
  }

  get payOrderButton() {
    return I18n.t("screen_messages.pay_text");
  }

  get isGiftItem() {
    let isGift = _.some(
      this.order?.items,
      (ele) => ele.other_data.is_gift == "true"
    );

    if (isGift) {
      return true;
    } else {
      return false;
    }
  }

  get isPartialDelivery() {
    let is = this.order.sub_status === "PARTIAL_DELIVERY";
    if (!is) {
      is = _.some(this.order?.items, (e) => {
        let quantity = Number(_.get(e, "quantity", 0));
        let deliveredQuantity = Number(_.get(e, "delivered_quantity", 0));
        return deliveredQuantity < quantity;
      });
    }
    return is;
  }

  static fake() {
    return new TrackOrderModel({
      orderIdWithTitle: this.orderIdWithTitle(),
      itemCountTitle: this.itemCountTitle(),
      orderAmountWithTitle: this.orderAmountWithTitle(),
    });
  }
}
