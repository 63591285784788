import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function UserRole(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M0 29v-3.625c0-3.988 6.525-7.25 14.5-7.25S29 21.387 29 25.375V29H0zM7.25 7.25a7.25 7.25 0 1114.5 0 7.25 7.25 0 01-14.5 0z"
          transform="translate(6 5)"
          fill={props.color || "#979797"}
          fillRule="nonzero"
        />
        <G transform="translate(25 22)">
          <Circle
            stroke={props.color1 || "#00AF62"}
            strokeWidth={0.65}
            fill={props.color1 || "#00AF62"}
            cx={6.5}
            cy={6.5}
            r={6.175}
          />
          <Path
            stroke="#FFF"
            strokeWidth={1.2636}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.88888889 6.68055556L5.41666667 9.02777778 10.4722222 4.33333333"
          />
        </G>
      </G>
    </Svg>
  );
}

export default UserRole;
