import _ from "lodash";
import moment from "moment";
import create from "zustand";
import { devtools } from "zustand/middleware";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import LoginHelper from "@presto-helpers/LoginHelper";
import AdminManager from "@presto-services/features/admin/AdminManager";
import Utils from "@presto-services/common/Utils";

let createStoreTimings = (set, get) => ({
  ready: false,
  storeTimings: null,
  selectedOperator: null,
  holidays: [],
  operatorLeavesHash: {},
  user: null,
  loading: false,
  lastTryTime: moment().subtract(1, "day"),
  init: (user) => {
    set({
      user: user,
      ready: true,
      lastTryTime: moment().subtract(1, "day"),
      selectedOperator: LoginHelper.isUserRoleOperator() ? user : null,
    });
  },
  fetch: async (force = false) => {
    const { loading: isLoading, lastTryTime } = get();
    if (!force && moment() - moment(lastTryTime) < 60000) {
      return;
    }
    if (!isLoading || force) {
      set({ loading: true, lastTryTime: new Date() });
      if (!_.isEmpty(get().user)) {
        await getTimings(null, set, get);
        await getStoreHolidays(set, get);
      }
    }
  },
  reset: () => {
    set({
      storeTimings: null,
      user: null,
      lastTryTime: moment().subtract(1, "day"),
      loading: false,
      ready: false,
      selectedOperator: null,
      holidays: [],
      operatorLeavesHash: {},
    });
  },
  refresh: () => {
    get().fetch(true);
  },
  refreshStoreHolidays: () => {
    getStoreHolidays(set, get);
  },
  resetOperatorLeavesHash: () => {
    set({
      operatorLeavesHash: {},
      selectedOperator: null,
    });
  },
  get: () => {
    return get();
  },
  getDayTimings: (dateObject) => {
    const dayName = _.toLower(moment(dateObject).format("dddd"));
    const keyName = `${dayName}_operational_timings`;
    const dayTimings = _.get(get().storeTimings, keyName, []);
    return dayTimings;
  },
  getStartEndTimeByDay: (dateObject) => {
    const dayTimings = get().getDayTimings(dateObject);
    return {
      startTime: _.first(dayTimings)?.startTime,
      endTime: _.last(dayTimings)?.endTime,
    };
  },
  isStoreOpen: (dateObject) => {
    const dayTimings = get().getDayTimings(dateObject);
    return !_.isEmpty(dayTimings);
  },
  isHoliday: (dateObject) => {
    const isHoliday = _.some(get().holidays, (holidayItem) =>
      Utils.isSameDate(holidayItem.startTime, dateObject)
    );
    return isHoliday;
  },
  getHolidayOnDate: (dateObject) => {
    const holidayItem = _.find(get().holidays, (holidayItem) =>
      Utils.isSameDate(holidayItem.startTime, dateObject)
    );
    return holidayItem;
  },
  isStoreOpenBetweenTime: (timings, startTime, endTime) => {
    const isOpen = get().isStoreOpen(startTime);
    return isOpen
      ? get().isNonWorkingStoreTime(timings, startTime, endTime)
      : false;
  },
  isStoreWorkingTime: (startTime, endTime) => {
    let timingsList = _.cloneDeep(get().getDayTimings(startTime));

    startTime = Utils.resetDateExcludingTime(startTime);
    endTime = Utils.resetDateExcludingTime(endTime);

    let filterWorkingHours = _.filter(timingsList, (timeItem) => {
      timeItem.startTime = Utils.resetDateExcludingTime(timeItem.startTime);
      timeItem.endTime = Utils.resetDateExcludingTime(timeItem.endTime);

      return (
        startTime.getTime() >= timeItem.startTime.getTime() &&
        endTime.getTime() <= timeItem.endTime.getTime()
      );
    });

    return !_.isEmpty(filterWorkingHours);
  },
  onOperatorSelect: async (operator) => {
    const hash = get().operatorLeavesHash;
    const storeHolidaysOnly = _.filter(
      get().holidays,
      (holidayItem) => holidayItem.non_working_slotable_type === "Merchant"
    );

    if (_.isEmpty(hash, operator.id)) {
      set({
        loading: true,
        holidays: storeHolidaysOnly,
      });
      fetchStylistLeaves(operator, set, get);
    } else {
      set({
        operatorLeavesHash: hash,
        holidays: _.compact(_.concat(storeHolidaysOnly, hash[operator.id])),
      });
    }
    set({
      selectedOperator: operator,
    });
    await getTimings(operator, set, get);
  },
  onUnsetOperator: async () => {
    if (get().selectedOperator) {
      await getTimings(null, set, get);
    }
    set({
      selectedOperator: null,
      holidays: _.filter(
        get().holidays,
        (holidayItem) => holidayItem.non_working_slotable_type === "Merchant"
      ),
    });
  },
});

const getEntityId = (user) => {
  if (LoginHelper.isUserRoleEmployee()) {
    return user.merchant_id;
  } else if (
    LoginHelper.isUserRoleAdmin() ||
    LoginHelper.isUserRoleOperator()
  ) {
    return user.id;
  }
};

const constructTimingsList = (storeTimingsObject) => {
  const allWeekList = Utils.getWeekdaysList();
  let newStoreTimings = _.cloneDeep(storeTimingsObject);
  _.forEach(allWeekList, (dayItem) => {
    const keyName = `${_.toLower(dayItem.fullName)}_operational_timings`;

    if (_.has(newStoreTimings, keyName)) {
      const timeObjects = onEachDayTimings(dayItem, newStoreTimings[keyName]);

      newStoreTimings[keyName] = timeObjects;
    }
  });

  return newStoreTimings;
};

const onEachDayTimings = (dayItem, dayTimingList) => {
  return _.map(dayTimingList, (timeItem) => {
    const startTime = new Date();
    startTime.setHours(timeItem.start_hour, timeItem.start_minute, 0);

    const endTime = new Date();
    endTime.setHours(timeItem.end_hour, timeItem.end_minute, 0);

    let timeObject = {
      ...timeItem,
      startTime: startTime,
      endTime: endTime,
    };

    return timeObject;
  });
};

const operationalTimingsOnSuccess = (response, get, set) => {
  const newStoreTimings = constructTimingsList(response.data);
  set({
    storeTimings: newStoreTimings,
    loading: false,
  });
};

const operationalTimingsOnError = (error, set) => {
  console.log("getTimings Error :>> ", error.response);
  set({
    storeTimings: null,
    loading: false,
  });
};

const getTimings = async (operator, set, get) => {
  const params = {
    entity_id: operator?.id || getEntityId(get().user),
    entity_name: operator?.id ? "Presto::Operator" : getEntityName(),
  };

  const isEmployee =
    LoginHelper.isUserRoleOperator() || LoginHelper.isUserRoleEmployee();

  const isAdmin = LoginHelper.isUserRoleAdmin();
  const Manager = isEmployee ? EmployeeManager : isAdmin ? AdminManager : null;

  if (Manager) {
    Manager.showOperationalTiming(
      params,
      (response) => operationalTimingsOnSuccess(response, get, set),
      (error) => operationalTimingsOnError(error, set)
    );
  }
};

const getEntityName = () => {
  return LoginHelper.isUserRoleAdmin() || LoginHelper.isUserRoleEmployee()
    ? "Merchant"
    : LoginHelper.isUserRoleOperator()
    ? "Presto::Operator"
    : "";
};

const getStoreHolidays = async (set, get) => {
  const onSuccess = (response) => {
    const holidays = _.map(response.data, (holidayItem) => {
      holidayItem.startTime = new Date(holidayItem.start_time);
      return holidayItem;
    });
    set({
      holidays: holidays,
      loading: false,
    });
  };

  const onError = (error) => {
    console.log("getStoreHolidays Error :>> ", error);
    set({
      holidays: [],
      loading: false,
    });
  };
  if (!get().user) {
    return;
  }

  let params = {
    entity_id: get().user?.id,
    entity_name: getEntityName(),
  };

  const isEmployee = LoginHelper.isUserRoleEmployee();
  const isAdmin = LoginHelper.isUserRoleAdmin();

  if (isEmployee) {
    const start_time = new Date();
    let params = {
      start_time: start_time.toISOString(),
    };
    EmployeeManager.showNonWorkingSlots(params, onSuccess, onError);
  } else if (isAdmin) {
    params.entity_id = get().user?.id;
    AdminManager.showNonWorkingSlots(params, onSuccess, onError);
  }
};

const fetchStylistLeaves = async (operator, set, get) => {
  const onSuccess = (response) => {
    let hash = _.cloneDeep(get().operatorLeavesHash);
    const leaves = _.map(response.data, (leaveItem) => {
      return {
        ...leaveItem,
        startTime: new Date(leaveItem.start_time),
      };
    });

    hash[operator.id] = leaves;

    set({
      operatorLeavesHash: hash,
      holidays: _.concat(get().holidays, leaves),
      loading: false,
    });
  };

  const onError = (error) => {
    console.log("fetchStylistLeaves Error :>> ", error);
    set({
      operatorLeavesHash: {},
      loading: false,
    });
  };

  const isEmployee = LoginHelper.isUserRoleEmployee();
  const isOperator = LoginHelper.isUserRoleOperator();
  const isAdmin = LoginHelper.isUserRoleAdmin();

  if (isEmployee || isOperator) {
    const start_time = new Date();
    let params = {
      start_time: start_time.toISOString(),
      entity_id: operator.id,
    };

    EmployeeManager.operatorLeaves(params, onSuccess, onError);
  } else if (isAdmin) {
    let params = {
      entity_id: operator.id,
      entity_name: "Presto::Operator",
    };
    AdminManager.showNonWorkingSlots(params, onSuccess, onError);
  }
};

export const useStoreTimings = create(devtools(createStoreTimings));
