import _ from "lodash";
import { Platform } from "react-native";

export default function styles(theme, images) {
  const currentStyles = {};

  return {
    ...currentStyles,
  };
}
