import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";

export function FullWidthPaddedBox({
  children,
  paddingHorizontal,
  paddingVertical,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  padding,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={[
        { padding: theme.primaryPadding },
        {
          paddingHorizontal,
          paddingVertical,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          padding,
        },
      ]}
    >
      {children}
    </View>
  );
}

export function FullWidthHorizontalPaddedBox({
  children,
  paddingHorizontal,
  paddingVertical,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  padding,
  containerstyles,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        width: "100%",
        paddingHorizontal: theme.containerPadding,
        ...containerstyles,
      }}
    >
      {children}
    </View>
  );
}

export function FullWidthBox({ children }) {
  return <View style={{ width: "100%" }}>{children}</View>;
}
