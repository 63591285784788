import * as React from "react";
import Svg, { G, Path, Circle, Ellipse } from "react-native-svg";

function SvgComponent({
  width,
  height,
  fillColor = "#CCCCCC",
  color = "#7E5C5B",
}) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/services-with-circle">
          <Circle
            id="Oval"
            fill-opacity="0.5"
            fill={fillColor}
            cx="20"
            cy="20"
            r="20"
          ></Circle>
          <G
            id="Group-2"
            transform="translate(20.500000, 20.000000) scale(-1, 1) translate(-20.500000, -20.000000) translate(10.000000, 11.000000)"
          >
            <G id="Group" transform="translate(0.000000, 0.000000)">
              <Path
                d="M4.3974286,3.39081155 C4.06502843,3.20954419 3.65435507,3.14541159 3.27081641,3.09611959 C2.06593103,2.94188334 1.03689943,3.28904742 0.161805105,4.26322695 C2.61279975,5.70594548 5.94515058,7.61879309 8.32987392,9.00002913 L11.3392174,7.25201821 C9.17365897,6.03614888 6.44870813,4.51127701 4.3974286,3.39081155"
                id="Fill-195"
                stroke={color}
                stroke-width="0.64"
              ></Path>
              <Path
                d="M8.32987392,9.00002913 C5.94515058,7.61879309 2.61279975,5.70594548 0.161805105,4.26322695 C0.904878637,3.4363934 1.75857828,3.06113817 2.73699322,3.06113817 C2.91128154,3.06113817 3.0892226,3.07279865 3.27081641,3.09611959 C3.65435507,3.14541159 4.06502843,3.20954419 4.3974286,3.39081155 C6.44870813,4.51127701 9.17365897,6.03614888 11.3392174,7.25201821 L8.32987392,9.00002913"
                id="Fill-196"
              ></Path>
              <Path
                d="M17.0709025,15.9671618 C18.0816704,15.9851825 18.9478937,15.1413883 18.9739848,14.1126166 C19.0016413,13.0271325 18.1876001,12.1610774 17.1283029,12.1472968 C16.1060549,12.1345763 15.2476589,12.9592898 15.2106096,13.9896516 C15.1730385,15.0269037 16.0403055,15.9491411 17.0709025,15.9671618 Z M17.0922972,5.85437141 C18.1521163,5.85912169 18.9844212,5.00631709 18.9745066,3.9245432 C18.9656357,2.89206131 18.1218506,2.03872669 17.1069082,2.03447161 C16.0763111,2.03024635 15.1959986,2.93128291 15.2100878,3.97542527 C15.2241769,5.00207692 16.073702,5.84958131 17.0922972,5.85437141 Z M13.6159237,12.2225599 C13.539216,12.1578973 13.5240831,12.1414666 13.5052976,12.1303361 C12.7559622,11.6904183 11.9617502,10.8503342 11.2025002,10.8662348 C10.9134112,10.8720651 10.6024057,11.1248853 9.8556794,11.5526127 C8.61061378,12.2654916 6.52124129,13.5094521 5.27043564,14.2106705 C4.20278926,14.8090648 2.90084511,15.257463 1.68500148,14.8689572 C1.09377795,14.6797396 0.622573314,14.3426459 4.05021966e-05,13.8343553 C4.661992,11.0893739 9.11260587,8.46841756 13.6138364,5.81778002 C13.5428687,5.61319172 13.4713792,5.4154937 13.4056297,5.21620561 C12.8540646,3.55458819 13.4447663,1.72707405 14.858902,0.717383082 C16.2860833,-0.301848271 18.2089948,-0.228705303 19.5751229,0.89653035 C20.9047236,1.99102476 21.3613173,3.87790131 20.6871241,5.48969671 C20.007191,7.11580268 18.3832831,8.0650712 16.6623164,7.88804401 C16.3669656,7.85730277 16.0272599,7.94210621 15.7559128,8.07408156 C15.2372225,8.32531175 14.7529723,8.64862487 14.1346141,9.01221962 C15.1824312,9.49082904 15.8941955,10.2508799 17.1173446,10.1353352 C18.8064802,9.97579872 20.3082819,11.2197592 20.821754,12.8781965 C21.3242679,14.5011223 20.7403499,16.267154 19.3716126,17.2635944 C17.9950481,18.265335 16.1133604,18.2430741 14.7561032,17.2090022 C13.416588,16.1881808 12.8697192,14.4014783 13.4045861,12.7886228 C13.466161,12.6047054 13.5402596,12.4250281 13.6159237,12.2225599 L13.6159237,12.2225599 Z"
                id="Fill-197"
                stroke="#7E5C5B"
                stroke-width="0.64"
              ></Path>
              <Path
                d="M13.0952173,6.14427326 C13.0972333,6.14745339 13.0534003,6.17819464 12.9683435,6.23331688 C12.8556301,6.30433976 12.7330021,6.38225292 12.5941976,6.46970647 C12.2169208,6.70185589 11.7535435,6.98647743 11.2071966,7.32251107 C10.0346641,8.03963016 8.41284351,9.02600018 6.59377446,10.0690825 C4.80497104,11.1010344 3.18784683,12.0333422 1.94225938,12.7515213 C1.38443241,13.0674142 0.911662314,13.335075 0.526558194,13.5534439 C0.383057337,13.6324171 0.255732939,13.7023799 0.138844968,13.7665125 C0.0496135257,13.8142145 0.0021277874,13.8375354 0,13.8343553 C-0.0015249617,13.8311751 0.0423080275,13.8009639 0.128408542,13.7469017 C0.241643764,13.6774689 0.365837234,13.6016758 0.506207164,13.5158123 C0.886614892,13.2894932 1.35364496,13.0117619 1.90416643,12.6836786 C3.1455793,11.9580791 4.75696348,11.0157009 6.53898322,9.97367863 C8.35700863,8.93112633 9.98561291,7.95535674 11.16806,7.25519834 C11.7211906,6.9313552 12.1903079,6.65627404 12.5728029,6.43313498 C12.7152601,6.35151167 12.8415409,6.27995877 12.9573852,6.2142361 C13.045573,6.1649441 13.0930588,6.14109313 13.0952173,6.14427326"
                id="Fill-200"
                fill="#7E5C5B"
              ></Path>
            </G>
            <Ellipse
              id="Oval"
              stroke="#7E5C5B"
              stroke-width="0.512"
              cx="11.15625"
              cy="9"
              rx="1"
              ry="1"
            ></Ellipse>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
