import React, { useContext } from "react";
import { View, Text } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function UnSelectedCheckBox(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        width: props?.size ?? 26,
        height: props?.size ?? 26,
        backgroundColor: theme.appBackgroundColor,
        borderRadius: props.radius != undefined ? props.radius : 16,
        borderColor: props.borderColor || theme.secondary,
        borderWidth: props.borderWidth || 2,
        ...props.style,
      }}
    >
      <Text></Text>
    </View>
  );
}
