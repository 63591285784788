import * as React from "react";
import Svg, { G, Path, Ellipse } from "react-native-svg";

 export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <G
        transform="translate(5.558 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Ellipse
          fill="#979797"
          cx={13.9423077}
          cy={14}
          rx={6.69230769}
          ry={6.72}
        />
        <Ellipse
          stroke={props.color || "#000"}
          strokeWidth={1.5}
          cx={13.9423077}
          cy={14}
          rx={9.28846154}
          ry={9.33}
        />
        <Path
          stroke={props.color || "#000"}
          strokeWidth={1.1}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.9423077 0L13.9423077 4.48"
        />
        <Path
          stroke={props.color || "#000"}
          strokeWidth={1.1}
          strokeLinecap="round"
          d="M13.9423077 23.52L13.9423077 28"
        />
        <Path
          stroke={props.color || "#000"}
          strokeWidth={1.1}
          strokeLinecap="round"
          transform="rotate(90 2.23 14.56)"
          d="M2.23076923 12.3292308L2.23076923 16.7907692"
        />
        <Path
          stroke={props.color || "#000"}
          strokeWidth={1.1}
          strokeLinecap="round"
          transform="rotate(90 25.654 14.56)"
          d="M25.6538462 12.3292308L25.6538462 16.7907692"
        />
      </G>
    </Svg>
  );
}

