import _ from "lodash";
import Config from "@presto-common/config";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import SearchManager from "@presto-services/features/search/SearchManager";
import CategoryItemManager from "@presto-services/features/category_item/CategoryItemManager";
import asyncify from "../../common/Asyncify";

const AsyncCatalogManager = asyncify(CatalogManager);
const AsyncSearchManager = asyncify(SearchManager);

const EMPTY_ARRAY = [];

export default class DynamicPriceManager {
  constructor() {
    this.filter = Config.constants.master_catalog_name;
    this.merchantId = Config.server_config.merchant_id;
    this.masterItems = null;
    this.dynamicPrices = null;
    this.mainItems = null;
  }

  async getMasterCatalogId() {
    const [error, response] = await AsyncCatalogManager.getFilterBasedCatalog({
      filter: this.filter,
      merchant_id: this.merchantId,
    });
    if (response.data) {
      console.log("master catalog id passed :>>", response.data.id);
      return response.data.id;
    }
    if (error) {
      return null;
    }
  }

  async getMasterCatalogItems(items, callback, inCart) {
    console.log("mainItems length :>>", items.length);
    this.mainItems = items;
    let masterCatalogId = await this.getMasterCatalogId();
    let refIds = _.map(items, (i) => {
      return inCart ? i.reference_id : i._source.reference_id;
    });
    if (!masterCatalogId) {
      this.getDynamicPrices([], callback);
      return;
    }
    let params = {
      catalog_id: masterCatalogId,
      ref_ids: refIds,
    };
    console.log("refIds length :>>", refIds.length, refIds[0]);

    SearchManager.searchCategoryItem(
      params,
      (response) => {
        if (response.hits.hits) {
          console.log(
            "response.hits.hits length :>>",
            response.hits.hits.length
          );
          this.masterItems = response.hits.hits;
          this.getDynamicPrices(response.hits.hits, callback, inCart);
        }
      },
      (error) => {
        this.getDynamicPrices([], callback);
        console.log("Error fetching items:>>", error);
      }
    );
  }

  getDynamicPrices(items, callback, inCart) {
    if (items.length == EMPTY_ARRAY) {
      this.createItemsWithDynamicPrices([], callback);
      return;
    }
    let ids = _.map(items, (i) => {
      return i._source.id;
    });
    let params = { item_ids: ids };
    CategoryItemManager.getDynamicPriceForItems(
      params,
      (res) => {
        console.log("Res fetching dynamic prices :>>", res);
        this.dynamicPrices = res;
        this.createItemsWithDynamicPrices(res.data, callback, inCart);
      },
      (err) => {
        this.createItemsWithDynamicPrices([], callback);
        console.log("Error fetching dynamic prices :>>", err, ids);
      }
    );
  }

  createItemsWithDynamicPrices(items, callback, inCart) {
    if (items.length == EMPTY_ARRAY) {
      callback(this.mainItems);
      return;
    }
    let finalItems = _.map(this.masterItems, (i) => {
      let item = i._source;
      let itemHasDynamicPrice = items[item.id] !== undefined;
      if (itemHasDynamicPrice) {
        let dynamicPrice = items[item.id].price;
        dynamicPrice.base_price = dynamicPrice.base_price / 100;
        dynamicPrice.total_price = dynamicPrice.total_price / 100;
        return {
          ...i,
          _source: {
            ...i._source,
            price: {
              ...dynamicPrice,
            },
          },
        };
      } else {
        return i;
      }
    });
    let sources = _.map(finalItems, (i) => i._source);
    let object = _.keyBy(sources, "id");
    console.log("final Items log :>>", finalItems[0]);
    callback(inCart ? object : finalItems);
  }

  async getDynamicPricesForCartItems(items, callback) {
    this.getMasterCatalogItems(items, callback, true);
  }
}
