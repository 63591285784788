import React, { useContext } from "react";
import { View } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import utils from "../../utils";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import I18n from "i18n-js";

function LeftRightRow(props) {
  if (props.children.length !== 2) {
    throw new Error("Number of children for LeftRightRow should be 2");
  }
  if (props.leftWidth && props.rightWidth) {
    throw new Error("Don't specify both leftWidth and rightWidth props");
  }

  const leftStyle = props.rightWidth
    ? { width: props.leftWidth, flex: 1 }
    : { width: props.leftWidth };
  const rightStyle = props.leftWidth
    ? { width: props.rightWidth, flex: 1 }
    : { width: props.rightWidth };

  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        alignItems: "center",
        height: props.height || 40,
      }}
    >
      <View style={leftStyle}>{props.children[0]}</View>
      <View style={rightStyle}>{props.children[1]}</View>
    </View>
  );
}

export default function OrderReview({ order, mainColor, savingsColor }) {
  let savings = 0;
  _.forEach(order.items, (product) => {
    const parsedOldPrice = parseFloat(product.other_data?.old_price);
    const oldPrice = isNaN(parsedOldPrice) ? 0 : parsedOldPrice;
    let oldTotal = 0;
    if (oldPrice) {
      oldTotal = oldPrice * product.quantity;
      savings +=
        oldTotal - product.rate * product.quantity ||
        product.price?.base_price * product.quantity;
    }
  });

  const undeliveredAmountArray = _.map(order.items, (item) => {
    let returned_amount = item.returned_amount;
    returned_amount = returned_amount / 100;
    return item.undelivered_amount + returned_amount;
  });

  const totalUndeliveredAmount = _.sum(undeliveredAmountArray);

  const totalDeliveredAmount = _.subtract(
    order?.total_amount,
    totalUndeliveredAmount
  );

  const { theme } = useContext(ThemeContext);
  return (
    <View>
      <View style={{ marginBottom: 15 }}>
        <PrestoText fontStyle="bold" size={16} color={theme.darkText}>
          {`${I18n.t(
            "screen_messages.fufilled_order_total"
          )} ${utils.formattedPrice(totalDeliveredAmount || 0)}`}
        </PrestoText>
      </View>

      <LeftRightRow>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.primaryTextColor}
          extraStyle={{ marginTop: 2 }}
        >
          {I18n.t("screen_messages.order_total")}
        </PrestoText>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={mainColor || theme.secondary}
          extraStyle={{ marginTop: 2 }}
        >
          {utils.formattedPrice(order?.bill_amount - order?.tax_amount || 0)}
        </PrestoText>
      </LeftRightRow>
      <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />

      {totalUndeliveredAmount ? (
        <>
          <LeftRightRow>
            <PrestoText
              fontStyle="400.normal"
              size={14}
              color={theme.primaryTextColor}
              extraStyle={{ marginTop: 2 }}
            >
              {I18n.t("screen_messages.undelivered_amount")}
            </PrestoText>
            <PrestoText
              fontStyle="400.normal"
              size={14}
              color={mainColor || theme.secondary}
              extraStyle={{ marginTop: 2 }}
            >
              {utils.formattedPrice(totalUndeliveredAmount)}
            </PrestoText>
          </LeftRightRow>
          <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
        </>
      ) : null}
      {/* Hide this section for now */}
      {/*  <LeftRightRow>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.primaryTextColor}
          extraStyle={{ marginTop: 2 }}
        >
          {I18n.t("screen_messages.billing.gst_cess_with_colon")}
        </PrestoText>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.secondary}
          extraStyle={{ marginTop: 2 }}
        >
          {utils.formattedPrice(order?.tax_amount || 0)}
        </PrestoText>
      </LeftRightRow> */}
      <LeftRightRow>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.primaryTextColor}
          extraStyle={{ marginTop: 2 }}
        >
          {I18n.t("screen_messages.billing.discount_with_colon_2")}
        </PrestoText>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={mainColor || theme.secondary}
          extraStyle={{ marginTop: 2 }}
        >
          {utils.formattedPrice(order?.discount || 0)}
        </PrestoText>
      </LeftRightRow>
      <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
      <LeftRightRow>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.primaryTextColor}
          extraStyle={{ marginTop: 2 }}
        >
          {I18n.t("screen_messages.billing.gst_cess_with_colon")}
        </PrestoText>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={mainColor || theme.secondary}
          extraStyle={{ marginTop: 2 }}
        >
          {utils.formattedPrice(order?.total_tax || order?.tax_amount || 0)}
        </PrestoText>
      </LeftRightRow>
      <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
      <LeftRightRow>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.primaryTextColor}
          extraStyle={{ marginTop: 2 }}
        >
          {I18n.t("screen_messages.billing.savings_with_colon")}
        </PrestoText>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={savingsColor || theme.secondary}
          extraStyle={{ marginTop: 2 }}
        >
          {utils.formattedPrice(savings + order?.discount || 0)}
        </PrestoText>
      </LeftRightRow>
      <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
      <LeftRightRow>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={theme.primaryTextColor}
          extraStyle={{ marginTop: 2 }}
        >
          {I18n.t("screen_messages.billing.delivery_charges_colon")}
        </PrestoText>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color={mainColor || theme.secondary}
          extraStyle={{ marginTop: 2 }}
        >
          {utils.formattedPrice(order?.delivery_charge || 0)}
        </PrestoText>
      </LeftRightRow>
      <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
    </View>
  );
}
