import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function ExportIcon(props) {
  let _props = props;
  const maskFillColor = _props?.maskFillColor || "#fff";
  const fillColor = _props?.color || "#707070";
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H20V20H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(10 7)">
          <Mask id="b" fill={maskFillColor}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)" fill={fillColor} fillRule="nonzero">
            <Path
              transform="rotate(90 10 10)"
              d="M10 -3.46944695e-17L8.18125 1.81875 15.06375 8.70125 -9.98520428e-15 8.70125 -9.98520428e-15 11.29875 15.065 11.29875 8.18125 18.18125 10 20 20 10z"
            />
          </G>
        </G>
        <Path
          stroke={fillColor}
          strokeWidth={2}
          d="M6 23.0842182L6 32 33.1895752 32 33.1895752 23.0842182"
        />
      </G>
    </Svg>
  );
}

export default ExportIcon;
