import React, { useContext } from "react";
import { View, ScrollView, StatusBar } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import OnOfferItems from "@presto-screen-components/OnOfferItems/OnOfferItems";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import TopHeaderWithLogoAndBack from "@presto-screen-components/Headers/TopHeaderWithLogoAndBack";
// showCart: true,

export default function OnOfferScreen({ navigation }) {
  const { theme } = useContext(ThemeContext);
  const insets = useSafeAreaInsets();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.appBackgroundColor,
        paddingBottom: 0,
      }}
    >
      <View style={{ top: insets.top, backgroundColor: "transparent" }}>
        <StatusBar barStyle="dark-content" />
      </View>
      <ScrollView
        scrollEnabled={true}
        style={{ width: "100%", height: "100%" }}
        contentContainerStyle={{}}
      >
        <OnOfferItems showAll={true} hideSeeAll={true} />
      </ScrollView>
      <FloatingCartComponent />
    </View>
  );
}
