import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={14} height={22} viewBox="0 0 14 22" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M8.257 11l2.485 2.444-6.751 6.618a1.06 1.06 0 01-1.485 0l-.993-.973a1.02 1.02 0 010-1.454L8.257 11z"
        fill={props.primaryColor || "#979797"}
      />
      <Path
        d="M3.993 1.936l8.501 8.336a1.019 1.019 0 010 1.454l-1.75 1.719-9.23-9.081a1.027 1.027 0 01-.308-.727 1.012 1.012 0 01.307-.727l.993-.973a1.051 1.051 0 01.743-.303 1.067 1.067 0 01.744.302z"
        fill={props.primaryColor || "#979797"}
      />
    </Svg>
  );
}

export default SvgComponent;
