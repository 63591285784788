import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function GlutenFreeIcon(props) {
  return (
    <Svg
      width={props.width || "20px"}
      height={props.height || "20px"}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#618C03" stroke="none" strokeWidth={1} fillRule="evenodd">
        <Path d="M10 1.326a8.618 8.618 0 00-6.134 2.54A8.618 8.618 0 001.326 10c0 2.317.902 4.495 2.54 6.134A8.618 8.618 0 0010 18.674a8.618 8.618 0 006.134-2.54A8.618 8.618 0 0018.674 10a8.618 8.618 0 00-2.54-6.134A8.618 8.618 0 0010 1.326zM10 20a9.968 9.968 0 01-7.071-2.929A9.967 9.967 0 010 10a9.966 9.966 0 012.929-7.071A9.966 9.966 0 0110 0a9.965 9.965 0 017.071 2.929A9.966 9.966 0 0120 10a9.967 9.967 0 01-2.929 7.071A9.967 9.967 0 0110 20z" />
        <Path d="M5.987 14.82a5.41 5.41 0 01-.738-.754l-2.115 2.24.964.91L6.2 14.989a4.905 4.905 0 01-.214-.17M13.299 6.171c.26-.023.429-.024.447-.024h.002c.21 0 .408.102.53.274l.005.009 2.583-2.736-.964-.91-3.287 3.481c.251-.047.485-.076.684-.094M8.535 12.583c.608.517 1.03 1.236 1.027 2.233 0 0-1.589.498-3.16-.494 1.703 1.423 3.612.824 3.612.824.003-1.23-.638-2.035-1.479-2.563M8.786 9.709a2.3 2.3 0 01.809 1.805s-1.28.402-2.558-.38c1.41 1.213 3.01.71 3.01.71.002-1.032-.548-1.702-1.26-2.135M9.63 8.738s-.955.299-1.927-.263c1.103.993 2.378.593 2.378.593.003-.827-.453-1.353-1.033-1.688.348.324.583.763.581 1.358" />
        <Path d="M9.562 14.816c.002-.997-.42-1.716-1.027-2.233-1.594-1.002-3.906-1.005-3.906-1.005.476 1.326 1.109 2.19 1.773 2.744 1.571.992 3.16.494 3.16.494M9.595 11.514a2.3 2.3 0 00-.809-1.805c-1.31-.797-3.17-.8-3.17-.8.383 1.066.888 1.768 1.42 2.225 1.28.782 2.559.38 2.559.38M7.703 8.475c.972.562 1.926.263 1.926.263a1.776 1.776 0 00-.581-1.358c-1.01-.582-2.396-.585-2.396-.585.284.793.656 1.325 1.05 1.68M15.383 11.594c-1.422 3.676-4.092 3.569-4.995 3.415a3.56 3.56 0 00-.002.137s3.69 1.158 5.384-3.568c0 0-.145 0-.387.016M10.356 11.705a2.7 2.7 0 00-.003.14s3.035.953 4.43-2.936c0 0-.148 0-.389.018-1.146 2.912-3.25 2.897-4.038 2.778M10.323 8.923a2.158 2.158 0 00-.005.145s2.35.737 3.43-2.273c0 0-.152 0-.392.022-.859 2.118-2.381 2.186-3.033 2.106" />
        <Path d="M15.383 11.594c-1.216.08-4.888.56-4.995 3.415.903.154 3.573.261 4.995-3.415M10.356 11.705c.787.12 2.892.134 4.038-2.778-1.06.08-3.93.506-4.038 2.778M10.323 8.923c.652.08 2.174.012 3.033-2.106-.875.078-2.924.44-3.033 2.106M10.207 3.143l-.04.066c1.633 2.394-.14 3.862-.14 3.862a2.288 2.288 0 01-.09-.062c.09.088.194.17.313.247 0 0 1.897-1.57-.043-4.113" />
        <Path d="M10.166 3.209c-.244.406-1.467 2.598-.23 3.8.03.021.06.042.092.062 0 0 1.772-1.468.138-3.862" />
      </G>
    </Svg>
  );
}

export default GlutenFreeIcon;
