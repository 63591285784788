import PaymentResource from "./PaymentResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import $ from "../../global/util";

const PaymentManager = function (PaymentResource, Utils, ErrorConstants) {
  function initiateRazorPayPayment(params, succcessCallback, errorCallback) {
    if (Utils.isEmpty(params.id)) {
      var error = "";
      if (params.id_type == "appointment_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
          false
        );
      } else if (params.id_type == "order_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.ORDER_ID_EMPTY,
          false
        );
      } else {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.SUBSCRIPTION_ID_EMPTY,
          false
        );
      }
      errorCallback(error);
    } else {
      PaymentResource.initiateRazorPayPayment(params).then(
        function (initiatePaymentResponse) {
          succcessCallback(initiatePaymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }
  function initiateAppyPayPayment(params, succcessCallback, errorCallback) {
    if (Utils.isEmpty(params.id)) {
      var error = "";
      if (params.id_type == "appointment_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
          false
        );
      } else if (params.id_type == "order_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.ORDER_ID_EMPTY,
          false
        );
      } else {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.SUBSCRIPTION_ID_EMPTY,
          false
        );
      }
      errorCallback(error);
    } else {
      PaymentResource.initiateAppyPayPayment(params).then(
        function (initiatePaymentResponse) {
          succcessCallback(initiatePaymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function initiateRazorPayPaymentV1(params, succcessCallback, errorCallback) {
    if (Utils.isEmpty(params.id)) {
      var error = "";
      if (params.id_type == "appointment_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
          false
        );
      } else if (params.id_type == "order_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.ORDER_ID_EMPTY,
          false
        );
      } else {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.SUBSCRIPTION_ID_EMPTY,
          false
        );
      }
      errorCallback(error);
    } else {
      PaymentResource.initiateRazorPayPaymentV1(params).then(
        function (initiatePaymentResponse) {
          succcessCallback(initiatePaymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function captureRazorPayPayment(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.razorPayUrl)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.RAZOR_PAY_URL_EMPTY,
        false
      );
      errorCallback(error);
    } else if (Utils.isEmpty(params.razorPayPaymentId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.RAZOR_PAY_PAYMENT_ID_EMPTY,
        false
      );
      errorCallback(error);
    } else {
      PaymentResource.captureRazorPayPayment(params).then(
        function (capturePaymentResponse) {
          capturePaymentResponse.message = Utils.getSuccessMessage(
            "payment_successful"
          );
          successCallback(capturePaymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function captureRazorPayPaymentV1(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.razorPayUrl)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.RAZOR_PAY_URL_EMPTY,
        false
      );
      errorCallback(error);
    } else {
      PaymentResource.captureRazorPayPaymentV1(params).then(
        function (capturePaymentResponse) {
          capturePaymentResponse.message = Utils.getSuccessMessage(
            "payment_successful"
          );
          successCallback(capturePaymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getPaymentDetails(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.url)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.PAYMENT_URL_EMPTY,
        false
      );
      errorCallback(error);
    } else {
      PaymentResource.getPaymentDetails(params).then(
        function (paymentResponse) {
          paymentResponse.message = Utils.getSuccessMessage(
            "payment_successful"
          );
          successCallback(paymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function captureWorldPayPayment(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.worldPayUrl)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.WORLD_PAY_URL_EMPTY,
        false
      );
      errorCallback(error);
    } else if (Utils.isEmpty(params.worldpay.token)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.WORLD_PAY_TOKEN_EMPTY,
        false
      );
      errorCallback(error);
    } else {
      PaymentResource.captureWorldPayPayment(params).then(
        function (capturePaymentResponse) {
          capturePaymentResponse.message = Utils.getSuccessMessage(
            "payment_successful"
          );
          successCallback(capturePaymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function createAgentCompositePayment(params, successCallback, errorCallback) {
    PaymentResource.createAgentCompositePayment(params).then(
      function (capturePaymentResponse) {
        successCallback(capturePaymentResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getSavedCards(params, succcessCallback, errorCallback) {
    PaymentResource.getSavedCards(params).then(
      function (savedCardsResponse) {
        succcessCallback(savedCardsResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function deleteSavedCard(params, succcessCallback, errorCallback) {
    PaymentResource.deleteSavedCard(params).then(
      function (deleteCardResponse) {
        succcessCallback(deleteCardResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function payViaPaygateSavedCard(params, succcessCallback, errorCallback) {
    PaymentResource.payViaPaygateSavedCard(params).then(
      function (paymentResponse) {
        succcessCallback(paymentResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getNewPaymentV1(params, succcessCallback, errorCallback) {
    PaymentResource.getNewPaymentV1(params).then(
      function (paymentResponse) {
        succcessCallback(paymentResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  return {
    initiateRazorPayPayment: initiateRazorPayPayment,
    captureRazorPayPayment: captureRazorPayPayment,
    captureRazorPayPaymentV1: captureRazorPayPaymentV1,
    getPaymentDetails: getPaymentDetails,
    captureWorldPayPayment: captureWorldPayPayment,
    createAgentCompositePayment: createAgentCompositePayment,
    initiateRazorPayPaymentV1: initiateRazorPayPaymentV1,
    getSavedCards: getSavedCards,
    deleteSavedCard: deleteSavedCard,
    payViaPaygateSavedCard: payViaPaygateSavedCard,
    getNewPaymentV1: getNewPaymentV1,
    initiateAppyPayPayment: initiateAppyPayPayment,
  };
};

export default PaymentManager(PaymentResource, Utils, ErrorConstants);
