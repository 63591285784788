const PosCartHelper = function () {
  function isEmpty(stringObj) {
    //console.log("isEmpty(),stringObj=" + stringObj);
    if (!stringObj || null == stringObj || "" == stringObj) {
      return true;
    } else {
      return false;
    }
  }

  function isSameDate(date1, date2) {
    if (
      date1.getDate() == date2.getDate() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getYear() == date2.getYear()
    ) {
      return true;
    } else {
      return false;
    }
  }
  function convertRupeeToPaisa(rupee) {
    return Math.round(rupee * 100);
  }

  function getDisplayPrice(
    basePrice,
    priceMultipler,
    displayQuantity,
    displayName
  ) {
    return (
      basePrice * priceMultipler + " / " + displayQuantity + " " + displayName
    );
  }

  function getDisplayCount(
    count,
    displayQuantity,
    priceMultipler,
    displayName
  ) {
    return (count * displayQuantity) / priceMultipler + " " + displayName;
  }

  function numberInCart(item, cart) {
    const variationId = item.variation ? item.variation.id : null;
    let itemId = cart.getCartItemId(item.id, variationId);
    const cartItem = cart.getItem(itemId);
    return cartItem ? cartItem.count : 0;
  }
  function addToCart(quantity, item, variation, cart, setCartItemCount) {
    let itemId = cart.getCartItemId(item.id, variation && variation.id);
    const cartItem = cart.getItem(itemId);
    if (cartItem) {
      if (quantity === 0) {
        cart.updateItem(itemId, -cartItem.count, true);
        cart.removeItem(itemId);
      } else {
        cart.updateItem(itemId, quantity - cartItem.count, true);
      }
    } else {
      let newCartItem = cart.createNewItem(
        itemId,
        item,
        variation,
        item.variations
      );
      cart.addNewItem(itemId, newCartItem);
      cart.updateItem(itemId, 1);
    }
    setCartItemCount();
    console.log("cart get items : ", cart.getItems());
    return cart;
  }

  function createCartItem(
    cartItemId,
    product,
    innermostVariation,
    CartService
  ) {
    if (innermostVariation) {
      cartItemId = CartService.getCartItemId(product.id, innermostVariation.id);
    } else {
      cartItemId = CartService.getCartItemId(product.id, undefined);
    }
    let cartItem = CartService.getItem(cartItemId);
    if (!cartItem) {
      cartItem = CartService.createNewItem(
        cartItemId,
        product,
        innermostVariation
      );
    }
    return cartItem;
  }

  function addItemToCartThroughItems(
    items,
    CartService,
    successCallBack,
    errorCallBack
  ) {
    CartService.clearCart();
    var cartItemId;
    var cartItem;
    if (items) {
      for (var itemId in items) {
        if (
          items.hasOwnProperty(itemId) &&
          items[itemId].reference_id != "4417"
        ) {
          if (
            items[itemId].other_data.user_data &&
            items[itemId].other_data.user_data.added_toppings
          ) {
            items[itemId].other_data.added_toppings =
              items[itemId].other_data.user_data.added_toppings;
          }

          if (!items[itemId].variation) {
            items[itemId].price = {};
            items[itemId].price.total_price = items[itemId].total_price;
            items[itemId].price.base_price = items[itemId].total_price;
            items[itemId].price.tax_percentage = items[itemId].tax_percentage;
            items[itemId].price.shipping_charge = 0;
            items[itemId].price.packing_charge = 0;
          } else {
            items[itemId].price = {};
            items[itemId].price.total_price = items[itemId].total_price;
            items[itemId].price.base_price = items[itemId].total_price;
            items[itemId].price.tax_percentage = items[itemId].tax_percentage;
            items[itemId].price.shipping_charge = 0;
            items[itemId].price.packing_charge = 0;
            items[itemId].variation.price = {};
            if (!items[itemId].variation.total_price) {
              items[itemId].variation.price.total_price =
                items[itemId].total_price;
              items[itemId].variation.price.base_price =
                items[itemId].total_price;
              items[itemId].variation.price.tax_percentage =
                items[itemId].tax_percentage;
            }
            items[itemId].variation.price.shipping_charge = 0;
            items[itemId].variation.price.packing_charge = 0;
          }
          items[itemId].item_id = items[itemId].item_id;
          items[itemId].id = items[itemId].item_id;
          if (!cartItem && items[itemId].variation) {
            cartItem = createCartItem(
              cartItemId,
              items[itemId],
              items[itemId].variation,
              CartService
            );
          } else if (!cartItem && !items[itemId].variation) {
            cartItemId = CartService.getCartItemId(
              items[itemId].item_id,
              undefined
            );
            cartItem = CartService.getItem(cartItemId);
            cartItem = CartService.createNewItem(cartItemId, items[itemId]);
          } else if (cartItem && items[itemId].variation) {
            cartItem = createCartItem(
              cartItemId,
              items[itemId],
              items[itemId].variation,
              CartService
            );
          }
          if (!items[itemId].other_data.user_data.default_item_id) {
            cartItem.other_data = {};
            cartItem.other_data["count"] =
              items[itemId].other_data.user_data.count;
            cartItem.other_data["added_toppings"] =
              items[itemId].other_data.user_data.added_toppings;
            cartItem.other_data["total_price"] =
              items[itemId].other_data.user_data.total_price;
          } else if (items[itemId].other_data.user_data.default_item_id) {
            cartItem.other_data = {};
            cartItem.other_data["default_item_id"] =
              items[itemId].other_data.user_data.default_item_id;
          }
          if (!CartService.getItem(cartItem.itemId)) {
            CartService.addNewItem(cartItem.itemId, cartItem);
          }
          CartService.updateItem(cartItem.itemId, 1);
        }
      }
      successCallBack();
    }
  }

  return {
    isEmpty: isEmpty,
    isSameDate: isSameDate,
    convertRupeeToPaisa: convertRupeeToPaisa,
    getDisplayPrice: getDisplayPrice,
    getDisplayCount: getDisplayCount,
    numberInCart: numberInCart,
    addToCart: addToCart,
    addItemToCartThroughItems: addItemToCartThroughItems,
  };
};

export default PosCartHelper();
