import * as React from "react";
import Svg, { Defs, G, Circle, Text, TSpan } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs></Defs>
      <G
        filter="url(#a)"
        transform="translate(2 2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          stroke="#FAA85D"
          strokeWidth={0.9}
          fill="#FAA85D"
          cx={18}
          cy={18}
          r={17.55}
        />
        <Text
          fontFamily="Montserrat-Medium, Montserrat"
          fontSize={20}
          fontWeight={400}
          fill="#FFF"
        >
          <TSpan x={10} y={25}>
            {"\u20B9"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default SvgComponent;
