import * as React from "react";
import Svg, {
  Defs,
  G,
  Path,
  Mask,
  Use,
} from "react-native-svg";

export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill={props.color || "#fff"}>
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)" fill={props.color2 || "#707070"} fillRule="nonzero">
          <Path
            transform="rotate(180 14.5 14.5)"
            d="M14.5 0L11.8628125 2.6371875 21.8424375 12.6168125 0 12.6168125 0 16.3831875 21.84425 16.3831875 11.8628125 26.3628125 14.5 29 29 14.5z"
          />
        </G>
      </G>
    </Svg>
  );
}

