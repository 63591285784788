import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

const SvgComponent = (props) => (
  <Svg
    width={40}
    height={40}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <Defs>
      <Path id="a" d="M0 0h29v29H0z" />
    </Defs>
    <G transform="translate(5 6)" fill="none" fillRule="evenodd">
      <Mask id="b" fill="#fff">
        <Use xlinkHref="#a" />
      </Mask>
      <G mask="url(#b)" fill="#707070" fillRule="nonzero">
        <Path d="M12.688 16.314H0v-3.626h29v3.626H16.312z" />
      </G>
    </G>
  </Svg>
);

export default SvgComponent;
