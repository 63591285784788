import React, { useState, useContext, useEffect } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import SearchInput from "@presto-components/TextInputs/SearchInput";
import I18n from "i18n-js";

export default function SearchComponent({
  onSearch,
  onSearchFocus,
  onEndSearchFocusEnd,
  placeholder,
  query,
  inputref,
  showSoftInputOnFocus,
  onSearchChange,
  autoFocus,
  styles,
  iconStyle,
  containerStyle,
  icon,
  textInputStyle,
  closeIcon,
}) {
  const { theme } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState(query);

  const onSearchQueryChange = (text) => {
    setSearchQuery(text);
    onSearchChange && onSearchChange(text);
  };

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  return (
    <SearchInput
      theme={theme}
      placeholder={placeholder || I18n.t("screen_messages.common.search")}
      value={searchQuery}
      onChange={onSearchQueryChange}
      onSearch={onSearch}
      onSearchFocus={onSearchFocus}
      onEndSearchFocusEnd={onEndSearchFocusEnd}
      showSoftInputOnFocus={showSoftInputOnFocus}
      ref={inputref}
      inputref={inputref}
      autoFocus={autoFocus || false}
      iconStyle={iconStyle}
      containerStyle={containerStyle}
      icon={icon}
      textInputStyle={textInputStyle}
      closeIcon={closeIcon}
    ></SearchInput>
  );
}
