import * as React from "react";
import Svg, {
  Defs,
  Path,
  LinearGradient,
  Stop,
  G,
  Rect,
  Use,
  Circle,
} from "react-native-svg";
function SvgComponent({ width = "314px", height = "66px" }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 314 66"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Defs>
        <Path
          d="M20.272.877l10.456 6.037A6.543 6.543 0 0134 12.58v12.075a6.543 6.543 0 01-3.272 5.667l-10.456 6.037a6.543 6.543 0 01-6.544 0L3.272 30.322A6.543 6.543 0 010 24.655V12.58a6.543 6.543 0 013.272-5.666L13.728.877a6.543 6.543 0 016.544 0z"
          id="b"
        />
        <LinearGradient
          x1="69.8700676%"
          y1="13.9259268%"
          x2="12.2467898%"
          y2="113.18054%"
          id="c"
        >
          <Stop stopColor="#F9CF6F" offset="0%" />
          <Stop stopColor="#BF8F2D" offset="100%" />
        </LinearGradient>
        <Path
          d="M13.117.561l6.766 3.864A4.18 4.18 0 0122 8.052v7.729a4.18 4.18 0 01-2.117 3.627l-6.766 3.864a4.274 4.274 0 01-4.234 0l-6.766-3.864A4.18 4.18 0 010 15.781V8.052a4.18 4.18 0 012.117-3.627L8.883.561a4.274 4.274 0 014.234 0z"
          id="e"
        />
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="11.4182552%"
          y2="113.18054%"
          id="f"
        >
          <Stop stopColor="#844307" offset="0%" />
          <Stop stopColor="#BA8758" offset="100%" />
        </LinearGradient>
        <Path
          d="M13.117.561l6.766 3.864A4.18 4.18 0 0122 8.052v7.729a4.18 4.18 0 01-2.117 3.627l-6.766 3.864a4.274 4.274 0 01-4.234 0l-6.766-3.864A4.18 4.18 0 010 15.781V8.052a4.18 4.18 0 012.117-3.627L8.883.561a4.274 4.274 0 014.234 0z"
          id="h"
        />
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="11.4182552%"
          y2="113.18054%"
          id="i"
        >
          <Stop stopColor="#E1E1E1" offset="0%" />
          <Stop stopColor="#9DA1A2" offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-27 -314) translate(27 326) translate(0 16)">
          <Rect fill="#D5D3D3" x={23} y={0} width={267} height={5} rx={2.5} />
          <Rect fill="#53C175" x={0} y={0} width={157} height={5} rx={2.5} />
        </G>
        <G transform="translate(-27 -314) translate(27 326) translate(266)">
          <Use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <Path
            stroke="#53C175"
            strokeWidth={2}
            d="M17-1c1.302 0 2.605.337 3.772 1.01l10.456 6.038A7.543 7.543 0 0135 12.58v12.075a7.543 7.543 0 01-3.772 6.533l-10.456 6.037a7.538 7.538 0 01-7.544 0L2.772 31.188A7.543 7.543 0 01-1 24.655V12.58a7.543 7.543 0 013.772-6.532L13.228.01A7.538 7.538 0 0117-1z"
            fill="#FFF"
          />
        </G>
        <Path
          d="M19.38 5.715l7.604 4.39a4.759 4.759 0 012.38 4.122v8.781c0 1.7-.907 3.271-2.38 4.122l-7.605 4.39a4.759 4.759 0 01-4.758 0l-7.605-4.39a4.759 4.759 0 01-2.38-4.122v-8.781c0-1.7.907-3.271 2.38-4.121l7.605-4.39a4.759 4.759 0 014.758 0z"
          fill="url(#c)"
          transform="translate(-27 -314) translate(27 326) translate(266)"
        />
        <G
          transform="translate(-27 -314) translate(27 326) translate(266) translate(11 11)"
          fill="#FFF"
          stroke="#FFF"
        >
          <G transform="translate(0 2)">
            <Path strokeLinecap="round" d="M0 10.4907407L12 10.4907407" />
            <Path
              strokeWidth={0.5}
              strokeLinejoin="round"
              d="M0 7.94444444L0 4.40552436 2.41949026 6.1749844 6 2.11856003 9.54072571 6.1749844 12 4.40552436 12 7.94444444z"
            />
            <Path
              strokeWidth={0.5}
              strokeLinejoin="round"
              d="M1.8 5.82588441L1.8 2.28696432 3.49364318 4.05642437 6 0 8.478508 4.05642437 10.2 2.28696432 10.2 5.82588441z"
            />
          </G>
          <Circle cx={5.95833333} cy={1.625} r={1.125} />
        </G>
        <G>
          <G transform="translate(-27 -314) translate(27 326) translate(41 7)">
            <Use fill="#000" filter="url(#d)" xlinkHref="#e" />
            <Path
              stroke="#53C175"
              strokeWidth={2}
              d="M8.387-.307a5.275 5.275 0 015.226 0l6.766 3.864a5.208 5.208 0 011.917 1.897A5.15 5.15 0 0123 8.052v7.729a5.15 5.15 0 01-.704 2.598 5.208 5.208 0 01-1.917 1.897l-6.766 3.865a5.275 5.275 0 01-5.226 0l-6.766-3.865A5.208 5.208 0 01-.296 18.38 5.15 5.15 0 01-1 15.781V8.052c0-.928.25-1.82.704-2.598A5.208 5.208 0 011.62 3.557z"
              fill="#FFF"
            />
          </G>
          <Path
            d="M12.54 3.658l4.92 2.81A3.04 3.04 0 0119 9.106v5.621a3.04 3.04 0 01-1.54 2.638l-4.92 2.81a3.108 3.108 0 01-3.08 0l-4.92-2.81A3.04 3.04 0 013 14.727v-5.62a3.04 3.04 0 011.54-2.639l4.92-2.81a3.108 3.108 0 013.08 0z"
            fill="url(#f)"
            transform="translate(-27 -314) translate(27 326) translate(41 7)"
          />
        </G>
        <G>
          <G transform="translate(-27 -314) translate(27 326) translate(154 7)">
            <Use fill="#000" filter="url(#g)" xlinkHref="#h" />
            <Path
              stroke="#53C175"
              strokeWidth={2}
              d="M8.387-.307a5.275 5.275 0 015.226 0l6.766 3.864a5.208 5.208 0 011.917 1.897A5.15 5.15 0 0123 8.052v7.729a5.15 5.15 0 01-.704 2.598 5.208 5.208 0 01-1.917 1.897l-6.766 3.865a5.275 5.275 0 01-5.226 0l-6.766-3.865A5.208 5.208 0 01-.296 18.38 5.15 5.15 0 01-1 15.781V8.052c0-.928.25-1.82.704-2.598A5.208 5.208 0 011.62 3.557z"
              fill="#FFF"
            />
          </G>
          <Path
            d="M12.54 3.658l4.92 2.81A3.04 3.04 0 0119 9.106v5.621a3.04 3.04 0 01-1.54 2.638l-4.92 2.81a3.108 3.108 0 01-3.08 0l-4.92-2.81A3.04 3.04 0 013 14.727v-5.62a3.04 3.04 0 011.54-2.639l4.92-2.81a3.108 3.108 0 013.08 0z"
            fill="url(#i)"
            transform="translate(-27 -314) translate(27 326) translate(154 7)"
          />
        </G>
      </G>
    </Svg>
  );
}
export default SvgComponent;