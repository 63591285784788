import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={13} height={22} viewBox="0 0 13 22" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M11.602 17.634c.182.193.285.455.285.727 0 .273-.103.535-.285.727l-.922.974a.949.949 0 01-.69.302.949.949 0 01-.689-.302l-6.269-6.618L5.34 11l6.263 6.634z"
        fill={props.primaryColor || "#fff"}
      />
      <Path
        d="M10.678 1.936l.922.973c.183.193.285.454.285.727 0 .273-.102.534-.284.727L3.03 13.445l-1.625-1.72A1.06 1.06 0 011.12 11c0-.273.103-.534.285-.727l7.894-8.336a.949.949 0 01.69-.302c.258 0 .506.108.69.302z"
        fill={props.primaryColor || "#fff"}
      />
    </Svg>
  );
}

export default SvgComponent;
