import * as React from "react";
import Svg, { Path } from "react-native-svg";

function DownArrowWithoutPadding(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 24 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M15.883-5.705A.9.9 0 0117.29-4.59l-.077.095L7.826 5.833a.1.1 0 00-.02.103l.02.031 9.388 10.328a.9.9 0 01-1.243 1.295l-.088-.085L6.494 7.178a1.9 1.9 0 01-.11-2.424l.11-.132 9.389-10.327z"
        transform="scale(1 -1) rotate(90 17.624 0)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default DownArrowWithoutPadding;
