import * as React from "react";
import Svg, { G, Rect } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function MenuIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={props.color || "#000"} fillRule="evenodd">
        <Rect x={15} y={7} width={22} height={2} rx={1} />
        <Rect x={4} y={7} width={6} height={2} rx={1} />
        <Rect x={15} y={15} width={22} height={2} rx={1} />
        <Rect x={4} y={15} width={6} height={2} rx={1} />
        <Rect x={15} y={23} width={22} height={2} rx={1} />
        <Rect x={4} y={23} width={6} height={2} rx={1} />
        <Rect x={15} y={31} width={22} height={2} rx={1} />
        <Rect x={4} y={31} width={6} height={2} rx={1} />
      </G>
    </Svg>
  );
}

export default MenuIcon;
