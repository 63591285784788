import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#707070" fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path
          d="M4.663 6.316c.895 0 1.747-.383 2.345-1.051.597-.668.883-1.56.786-2.452l1.055-.12c.39-.058.719-.319.867-.685l.362-.967a.62.62 0 00-.054-.575.59.59 0 00-.497-.243h-.074L6.4.515a3.14 3.14 0 00-3.654.149 3.163 3.163 0 00-1.048 3.515 3.149 3.149 0 002.973 2.137h-.008zM14.233 14.386a1.051 1.051 0 00-1.01-.95l-3.416-.053a.472.472 0 01-.43-.375L8.625 8.21a1.011 1.011 0 00-1.072-.883l-4.247.181a.95.95 0 00-1.014 1.063s-.018 10.666-.04 11.057l-.148 2.64a4.372 4.372 0 01-.298 1.245l-1.72 3.895a.909.909 0 00.618 1.27l.573.143c.086.021.176.032.265.032a1.24 1.24 0 001.1-.669l1.627-3.34a8.13 8.13 0 00.501-1.395l.922-3.852 2.232 3.423c.21.356.351.75.415 1.158l.432 3.832a1.14 1.14 0 001.104.99h.653a.953.953 0 00.956-1.064l-.45-4.555a6.651 6.651 0 00-.343-1.45l-2-5.24-.012-1.103 4.647-.182a.933.933 0 00.903-1.015l.004-.005z"
          transform="translate(11 5)"
        />
        <Path
          d="M17.997 11.259l-.303-4.378a1.028 1.028 0 00-1.01-.943l-5.834.011a.915.915 0 00-.918.99l.303 4.378c.045.527.48.935 1.009.942l5.835-.01a.915.915 0 00.918-.99z"
          transform="translate(11 5)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
