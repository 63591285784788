import React, { createContext, useState } from "react";
import Themes from "@presto-app/themes";
import AppThemes from "@presto-app/appThemes";
import { useAssets } from "presto-react-components";
import config from "@presto-common/config";

function mix(...sources) {
  const result = {};
  for (const source of sources) {
    const props = Object.keys(source);
    for (const prop of props) {
      const descriptor = Object.getOwnPropertyDescriptor(source, prop);
      Object.defineProperty(result, prop, descriptor);
    }
  }
  return result;
}

const generateTheme = (themeName) => {
  const { images, svgs } = useAssets();
  return mix(
    {},
    Themes[themeName](svgs, images, config.theme),
    AppThemes(svgs, images, config.theme)
  );
};

const ThemeContext = createContext();

export function ThemeContextProvider(props) {
  const { images, svgs } = useAssets();
  const appTheme = AppThemes(svgs, images);
  const [themeName, setThemeName] = useState(appTheme.name);
  const theme = generateTheme(themeName);
  return (
    <ThemeContext.Provider value={{ theme, setThemeName }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
