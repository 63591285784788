import React, { useContext } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";

function SectionHeader(props) {
  const { leftText, leftTextStyle, fontStyle } = props;
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 941 ? true : false;

  return (
    <View>
      {leftText ? (
        <PrestoText
          fontStyle={
            fontStyle ? fontStyle : isDesktop ? "900.bold" : "700.bold"
          }
          color={theme.dark}
          size={props.fontSize ? props.fontSize : isDesktop ? 22 : 16}
          extraStyle={[styles.leftTextStyle, leftTextStyle]}
        >
          {leftText}
        </PrestoText>
      ) : null}
    </View>
  );
}

export function SectionSubHeader(props) {
  const { leftText, theme } = props;
  return (
    <View>
      {leftText ? (
        <PrestoText
          fontStyle="medium"
          color={theme.dark}
          extraStyle={styles.leftTextStyle}
        >
          {leftText}
        </PrestoText>
      ) : null}
    </View>
  );
}

export default SectionHeader;

const styles = {
  leftTextStyle: {
    letterSpacing: 0.4,
  },
};
