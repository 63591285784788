import React from "react";
import { View } from "react-native";

export function VerticalDivider(props) {
  return (
    <View
      style={{
        height: "100%",
        width: props.width ?? 1,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: props.paddingHorizontal
          ? props.paddingHorizontal
          : 0,
        paddingVertical: props.paddingVertical ? props.paddingVertical : 0,
      }}
    >
      <View
        style={{
          width: props.width ?? 1,
          height: "100%",
          backgroundColor: props.dividerColor,
          ...props.style,
        }}
      ></View>
    </View>
  );
}

export function LineDivider(props) {
  return (
    <View
      style={{
        height: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: props.padding ? props.padding : 0,
        paddingVertical: props.paddingVertical ? props.paddingVertical : 0,
      }}
    >
      <View
        style={{
          width: props.width,
          height: props.height ? props.height : 1,
          backgroundColor: props.dividerColor,
          ...props.style,
        }}
      ></View>
    </View>
  );
}

export function HorizontalSpace(props) {
  return (
    <View
      style={{
        width: props.width,
        height: 1,
        backgroundColor: "transparent",
      }}
    ></View>
  );
}
