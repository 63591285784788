import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import utils from "../../utils";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import moment from "moment";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";

export default function TopupSuccessScreen(props) {
  const { images } = useAssets();
  const [loading, setLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const insets = useSafeArea();
  const { Navigator } = useContext(NavigatorContext);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  const styles = getStyles(theme);
  const [wallet, setWallet] = useState();
  useEffect(() => {
    getWalletInfo();
  }, [user?.id]);
  const getWalletInfo = () => {
    if (!user?.wallet_id) return;
    WalletManager.getWalletInfo(
      { walletId: user.wallet_id },
      (response) => {
        setWallet(response.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(JSON.stringify(error));
      }
    );
  };
  const renderFooter = () => {
    return (
      <FlexRowSpaceAligned>
        <View
          style={{
            width: "100%",
            height: utils.isDesktop() ? undefined : 100,
          }}
        >
          <PrestoSolidButton
            size="extra-large"
            buttonStyle="primary"
            titleColor={theme.primaryButtonText}
            title={I18n.t("screen_messages.common.close")}
            onPress={() => {
              goBack();
            }}
          />
        </View>
      </FlexRowSpaceAligned>
    );
  };
  return (
    <LoadingContainer
      style={{ backgroundColor: theme.appBackgroundColor }}
      bgColor="transaparent"
      loading={loading}
    >
      <ScrollView
        style={{
          paddingTop: theme.containerPadding,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 100,
        }}
      >
        <View>
          <View
            style={{
              paddingBottom: theme.primaryPadding,
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <SectionHeader
              fontSize={18}
              leftText={`${I18n.t("screen_messages.topups.title")}`}
            />
            <VerticalSpacing size={20} />

            <PrestoText
              fontStyle="400.normal"
              color={theme.secondary}
              extraStyle={styles.summaryStyle}
            >
              {I18n.t("screen_messages.topups.description", {
                amount: utils.formattedCurrencyCents(
                  props.route.params?.amount
                ),
              })}
            </PrestoText>
            <VerticalSpacing size={30} />
            <View style={{ paddingVertical: 10 }}>
              <FlexRowSpaceAligned>
                <PrestoText
                  fontStyle="700.medium"
                  size={16}
                  color={theme.darkText}
                >
                  {I18n.t("screen_messages.wallet.wallet_balance")}
                </PrestoText>
                <PrestoText
                  size={16}
                  fontStyle="400.normal"
                  color={theme.primary}
                >
                  {wallet
                    ? utils.formattedCurrencyCents(
                        wallet.current_balance - wallet.expiry_balance
                      )
                    : 0}
                </PrestoText>
              </FlexRowSpaceAligned>
            </View>
            <LineDivider dividerColor="#999999" width="100%" theme={theme} />
            <VerticalSpacing size={10} />
            <PrestoText
              fontStyle="400.normal"
              size={12}
              color="#999999"
              extraStyle={styles.timeStyle}
            >
              {I18n.t("screen_messages.datetime.time")}{" "}
              {`${moment(new Date()).format("hh:mm")} ${moment(
                new Date()
              ).format("DD-MM-yyyy")}`}
            </PrestoText>
            <VerticalSpacing size={30} />
            <View style={{ alignItems: "center", paddingTop: 30 }}>
              <Image
                source={images.thank_you}
                style={{ width: 225, height: 225 }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          bottom: 30,
          marginHorizontal: theme.primaryPadding,
        }}
      >
        {renderFooter()}
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {
      padding: theme.primaryPadding,
      paddingBottom: 5,
    },
    timeStyle: {
      letterSpacing: 0.4,
    },
    summaryStyle: {
      letterSpacing: 0.4,
    },
  };
}
