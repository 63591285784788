import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={19}
      height={22}
      viewBox="0 0 19 22"
      fill="none"
      {...props}
      pointerEvents="none"
    >
      <Path
        opacity={0.4}
        d="M14.928 5.5a5.552 5.552 0 01-.914 3.056 5.444 5.444 0 01-2.437 2.025 5.363 5.363 0 01-3.136.313A5.407 5.407 0 015.66 9.39a5.523 5.523 0 01-1.485-2.816 5.567 5.567 0 01.309-3.178A5.485 5.485 0 016.484.927 5.379 5.379 0 019.5 0c1.44 0 2.82.58 3.838 1.61a5.537 5.537 0 011.59 3.89z"
        fill={props.primaryColor || "#1C164A"}
      />
      <Path
        d="M13.3 12.375h-.708a7.303 7.303 0 01-6.184 0H5.7a5.664 5.664 0 00-4.03 1.692A5.815 5.815 0 000 18.15v1.788c0 .547.214 1.071.596 1.458s.9.604 1.44.604h14.928c.54 0 1.058-.217 1.44-.604.381-.387.596-.912.596-1.459V18.15c0-1.531-.601-3-1.67-4.083a5.664 5.664 0 00-4.03-1.692z"
        fill={props.primaryColor || "#1C164A"}
      />
    </Svg>
  );
}

export default SvgComponent;
