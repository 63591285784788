import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="60px"
      height="57px"
      viewBox="0 0 60 57"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-239 -282) translate(206 250) translate(33 32)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M30 25.5a9.47 9.47 0 016.718 2.782A9.47 9.47 0 0139.5 35v21.5h-19V35a9.47 9.47 0 012.782-6.718A9.47 9.47 0 0130 25.5z"
          stroke="#505050"
        />
        <Path stroke="#505050" d="M7.5 15.5H52.5V56.5H7.5z" />
        <Path stroke="#505050" d="M11.5 10.5H48.5V15.5H11.5z" />
        <Path stroke="#505050" d="M15.5 5.5H44.5V10.5H15.5z" />
        <Path stroke="#505050" d="M26.5 0.5H33.5V5.5H26.5z" />
        <Path stroke="#505050" fill="#FFF" d="M0.5 56.5H59.5V57.5H0.5z" />
        <Path
          stroke="#505050"
          fill="#FFF"
          d="M5.5 14.6333333H54.5V15.6333333H5.5z"
        />
        <Path
          stroke="#505050"
          fill="#FFF"
          d="M5.5 19.6333333H54.5V20.6333333H5.5z"
        />
        <Path
          stroke="#505050"
          fill="#FFF"
          d="M11.5 9.63333333H48.5V10.63333333H11.5z"
        />
        <Path
          stroke="#505050"
          fill="#FFF"
          d="M15.5 4.63333333H44.5V5.63333333H15.5z"
        />
        <Path
          stroke="#505050"
          fill="#FFF"
          d="M26.5 0.633333333H33.5V1.633333333H26.5z"
        />
        <Path
          d="M38.244 36.133H55v2H38.227l.017-2zm-16.355 2H5v-2h16.933l-.044 2zM38.244 46.133H55v2H38.227l.017-2zm-16.355 2H5v-2h16.933l-.044 2z"
          fill="#505050"
        />
        <Path
          d="M15 26a3 3 0 110 6 3 3 0 010-6zM45 26a3 3 0 110 6 3 3 0 010-6z"
          fill="#505050"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
