import React, { useContext } from "react";
import { View, Dimensions, Platform } from "react-native";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import HorizontalButtonList from "@presto-components/HorizontalButtonList";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import Category from "@presto-cards/Category/Category";
import LoadingContainer from "@presto-components/LoadingContainer";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import SectionHeaderWithRightLink from "@presto-components/SectionHeaders/SectionHeaderWithRightLink";

import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import CategoryModel from "@presto-component-models/CategoryModel";
import SeeAllText from "@presto-screen-components/SeeAllText/SeeAllText";
import utils from "../../utils";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import { useMediaQuery } from "react-responsive";

export default function CategoriesSection(props) {
  const { theme } = props;
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const catalog = useCatalog((state) => state.catalog);

  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 941 ? true : false;

  const isWeb = Platform.OS == "web";
  const isMobile = useMediaQuery({ maxWidth: 940 });

  const goToCategory = (categoryId, subCategoryId) => {
    if (isWeb && !isMobile) {
      WebNavigator.emit({
        event: "push",
        params: {
          screenName: "BROWSE_CATEGORIES",
          screenParams: {
            categoryId,
            subCategoryId,
          },
        },
      });
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "BROWSE_CATEGORIES",
          nextUrl: `categories/${categoryId}/${subCategoryId}`,
          screenParams: {
            categoryId,
            subCategoryId,
          },
        },
      });
    }
  };

  const categories = _.values(catalog?.categories) || [];

  const renderSubCategoryButtons = (category, item) => {
    return (
      <Category
        category={new CategoryModel({ category: item })}
        theme={theme}
        onPressItem={() => {
          props?.onPressItem
            ? props?.onPressItem(category, item)
            : goToCategory(category.id, item.id);
        }}
      />
    );
  };

  const renderCategoryButtons = (item) => {
    return (
      <Category
        category={new CategoryModel({ category: item })}
        theme={theme}
        onPressItem={() => {
          props?.onPressItem ? props?.onPressItem(item) : goToCategory(item.id);
        }}
      />
    );
  };

  const RenderCategories = () => {
    return (
      <>
        <VerticalSpacing size={10} />
        {_.map(categories, (toplevel, index) => {
          if ((toplevel.categories?.length || 0) > 0) {
            return (
              <>
                <View style={{ paddingHorizontal: theme.containerPadding }}>
                  <SeeAllText
                    rightText={
                      index === 0 && !isDesktop
                        ? I18n.t("screen_messages.common.see_all")
                        : ""
                    }
                    leftText={toplevel.name}
                    rightTextStyle={props?.rightTextStyle}
                    fontSize={isDesktop ? 18 : 14}
                    leftTextFontStyle={isDesktop ? "700.bold" : "500.medium"}
                    onPressRightText={() => {
                      if (props?.onPressSeeAll) {
                        props.onPressSeeAll(toplevel);
                      } else {
                        Navigator.emit({
                          event: "modal",
                          params: {
                            screenName: "CATEGORIES",
                            screenParams: {
                              categoryId: toplevel?.id,
                            },
                          },
                        });
                      }
                    }}
                  />
                </View>
                <HorizontalButtonList
                  key={toplevel.id}
                  style={{ paddingLeft: 10 }}
                  renderButton={(item) =>
                    renderSubCategoryButtons(toplevel, item)
                  }
                  items={toplevel.categories}
                  spacing={isDesktop ? 30 : 5}
                />
                <VerticalSpacing size={30} />
              </>
            );
          } else {
            return (
              <>
                <View
                  style={{
                    paddingLeft: theme.containerPadding,
                    paddingBottom: isDesktop ? 35 : 0,
                  }}
                >
                  <PrestoText
                    color={theme.darkText}
                    size={isDesktop ? 18 : 14}
                    fontStyle={isDesktop ? "700.bold" : "500.medium"}
                  >
                    {toplevel.name}
                  </PrestoText>
                </View>
                <HorizontalButtonList
                  key={toplevel.id}
                  style={{ paddingLeft: 10 }}
                  renderButton={renderCategoryButtons}
                  items={[toplevel]}
                  spacing={isDesktop ? 30 : 5}
                />
                <VerticalSpacing size={isDesktop ? 30 : 30} />
              </>
            );
          }
        })}
      </>
    );
  };

  return categories ? (
    <LoadingContainer loading={false}>
      <View>
        {categories && categories.length > 0 ? (
          <>
            <VerticalSpacing size={30} />
            <View
              style={{
                paddingLeft: theme.containerPadding,
                paddingBottom: isDesktop ? 25 : 0,
              }}
            >
              <SectionHeader
                leftText={I18n.t("screen_messages.category.shop_by_category")}
                fontSize={isDesktop ? 22 : 18}
              />
            </View>
            <RenderCategories />
          </>
        ) : null}
      </View>
    </LoadingContainer>
  ) : null;
}
