import PriceObject from "./PriceObject";
import Utils from "../common/Utils";
import VariationObject from "./VariationObject";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const SubscriptionItemObject = function (
  PriceObject,
  Utils,
  VariationObject,
  ErrorConstants
) {
  function SubscriptionItemObject() {
    this.available_from = "";
    this.category_id = "";
    this.chef_name = null;
    this.chef_pic = null;
    this.description = "";
    this.dislikes = 0;
    this.id = "";
    this.is_available = true;
    this.keywords = "";
    this.likes = 0;
    this.name = "";
    this.other_data = {};
    this.pic_url = "";
    this.pre_orderable = false;
    this.price = {};
    this.reference_id = "";
    this.seo_description = "";
    this.seo_handle = "";
    this.seo_keywords = "";
    this.seo_title = "";
    this.sold_out = false;
    this.stock_count = 0;
    this.summary = null;
    this.variations = [];
    this.pictures = [];
    this.reference_id = "";
    this.subscription_plan = {};
  }

  SubscriptionItemObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawSubscriptionItem) {
      this.reference_id = rawSubscriptionItem.reference_id;
      this.available_from = rawSubscriptionItem.available_from;
      this.category_id = rawSubscriptionItem.category_id;
      this.chef_name = rawSubscriptionItem.chef_name;
      this.chef_pic = rawSubscriptionItem.chef_pic;
      this.dislikes = rawSubscriptionItem.dislikes;
      this.id = rawSubscriptionItem.id;
      this.is_available = rawSubscriptionItem.is_available;
      this.keywords = rawSubscriptionItem.keywords;
      this.likes = rawSubscriptionItem.likes;
      this.name = rawSubscriptionItem.name;
      this.other_data = rawSubscriptionItem.other_data;
      this.pic_url = rawSubscriptionItem.pic_url;
      this.pre_orderable = rawSubscriptionItem.pre_orderable;
      this.price = new PriceObject();
      this.price.buildObject(rawSubscriptionItem.price);
      this.reference_id = rawSubscriptionItem.reference_id;
      this.seo_description = rawSubscriptionItem.seo_description;
      this.seo_handle = rawSubscriptionItem.seo_handle;
      this.seo_keywords = rawSubscriptionItem.seo_keywords;
      this.seo_title = rawSubscriptionItem.seo_title;
      this.sold_out = rawSubscriptionItem.sold_out;
      this.stock_count = rawSubscriptionItem.stock_count;
      this.summary = rawSubscriptionItem.summary;
      this.pictures = rawSubscriptionItem.pictures;
      this.variations = [];
      var rawVariations = rawSubscriptionItem.variations;
      if (rawVariations) {
        var parsedVariations = [];
        rawVariations.forEach(function (rawVariation, index, rawVariations) {
          var variation = new VariationObject();
          variation.buildObject(rawVariation);
          parsedVariations.push(variation);
        });
        this.variations = parsedVariations;
      }
      this.subscription_plan = rawSubscriptionItem.subscription_plan;
      this.description = rawSubscriptionItem.description;
    },
  };
  return SubscriptionItemObject;
};

export default SubscriptionItemObject(
  PriceObject,
  Utils,
  VariationObject,
  ErrorConstants
);
