import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={31} height={25} viewBox="0 0 31 25" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M0 21.094c0 .621.272 1.217.757 1.657.484.44 1.141.686 1.826.686h25.834c.685 0 1.342-.246 1.826-.686A2.24 2.24 0 0031 21.094V12.5H0v8.594zm10.333-3.32a.56.56 0 01.19-.415.68.68 0 01.456-.172h7.32a.68.68 0 01.456.172.56.56 0 01.19.414v1.954a.56.56 0 01-.19.414.68.68 0 01-.456.172h-7.32a.68.68 0 01-.456-.172.56.56 0 01-.19-.414v-1.954zm-6.889 0a.56.56 0 01.19-.415.68.68 0 01.456-.172h3.875a.68.68 0 01.457.172.56.56 0 01.19.414v1.954a.56.56 0 01-.19.414.68.68 0 01-.457.172H4.09a.68.68 0 01-.456-.172.56.56 0 01-.19-.414v-1.954zM28.417 1.561H2.583c-.685 0-1.342.247-1.826.687A2.24 2.24 0 000 3.906V6.25h31V3.906a2.24 2.24 0 00-.757-1.657 2.723 2.723 0 00-1.826-.687z"
        />
        <Path d="M31 12.5H0V6.25h31v6.25z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h31v25H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
