import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const NotificationObject = function (Utils, ErrorConstants) {
  function NotificationObject() {
    this.msg = "";
    this.title = "";
    this.created_at = "";
    this.other_data;
    this.picture = "";
    this.deep_link = null;
  }

  NotificationObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawNotification) {
      this.msg = rawNotification.msg;
      this.title = rawNotification.title;
      this.created_at = rawNotification.created_at;
      this.other_data = rawNotification.other_data;
      this.picture = rawNotification.picture;
      this.deep_link = rawNotification.deep_link;
    },
  };

  return NotificationObject;
};

export default NotificationObject(Utils, ErrorConstants);
