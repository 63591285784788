import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function UserIconWhite(props) {
  const borderColor = props?.borderColor || "none";
  const strokeWidth = props?.strokeWidth || 1;
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(6 5)"
        stroke={props.color || "#707070"}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          stroke={borderColor}
          strokeWidth={strokeWidth}
          cx={14.5}
          cy={7.25}
          r={7.25}
        />
        <Path
          stroke={borderColor}
          strokeWidth={strokeWidth}
          d="M14.5 18.125c-7.975 0-14.5 3.262-14.5 7.25V29h29v-3.625c0-3.988-6.525-7.25-14.5-7.25z"
        />
      </G>
    </Svg>
  );
}

export default UserIconWhite;
