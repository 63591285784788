import * as React from "react";
import Svg, { Defs, Path, G, Circle, Mask, Use } from "react-native-svg";

function StartDate(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="startdate" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G
          transform="translate(12 15)"
          fill="#FFF"
          stroke="#FFF"
          strokeWidth={0.9}
        >
          <Circle cx={8.5} cy={4.25} r={4.25} />
          <Path d="M8.5 10.625c-4.675 0-8.5 1.912-8.5 4.25V17h17v-2.125c0-2.338-3.825-4.25-8.5-4.25z" />
        </G>
        <G transform="translate(7 5)">
          <Mask id="startdateb" fill="#fff">
            <Use xlinkHref="#startdate" />
          </Mask>
          <G mask="url(#startdateb)">
            <G transform="translate(-.003 -.003)">
              <Path
                stroke="none"
                fill="none"
                d="M0.00308998349 0.00308998349H29.00308998349V29.00308998349H0.00308998349z"
              />
              <Path
                d="M23.566 3.628h3.625a1.713 1.713 0 011.812 1.813v21.75a1.713 1.713 0 01-1.812 1.812H1.816a1.713 1.713 0 01-1.813-1.812V5.44a1.713 1.713 0 011.813-1.813H5.44V1.816A1.713 1.713 0 017.253.003a1.713 1.713 0 011.813 1.813v1.812H19.94V1.816a1.813 1.813 0 013.625 0v1.812z"
                stroke="none"
                fill={props.color || "#979797"}
                fillRule="evenodd"
              />
              <Path
                stroke="#FFF"
                strokeWidth={1}
                fill="#FFF"
                fillRule="evenodd"
                d="M13.00309 12.00309H16.00309V15.00309H13.00309z"
              />
              <Path
                stroke="#FFF"
                strokeWidth={1}
                fill="#FFF"
                fillRule="evenodd"
                d="M13.00309 19.00309H16.00309V22.00309H13.00309z"
              />
              <Path
                stroke="#FFF"
                strokeWidth={1}
                fill="#FFF"
                fillRule="evenodd"
                d="M8.00308998 19.00309H11.00308998V22.00309H8.00308998z"
              />
              <Path
                stroke="#FFF"
                strokeWidth={1}
                fill="#FFF"
                fillRule="evenodd"
                d="M18.00309 19.00309H21.00309V22.00309H18.00309z"
              />
              <Path
                stroke="#FFF"
                strokeWidth={1}
                fill="#FFF"
                fillRule="evenodd"
                d="M18.00309 12.00309H21.00309V15.00309H18.00309z"
              />
            </G>
          </G>
        </G>
        <G transform="translate(25 24)">
          <Circle
            stroke="#FFF"
            strokeWidth={0.65}
            fill={props.color1 || "#00AF62"}
            cx={6.5}
            cy={6.5}
            r={6.175}
          />
          <Path
            transform="translate(2.734 2.376)"
            fill="#FFF"
            fillRule="nonzero"
            d="M4.12413793 0L3.37406034 0.750077586 6.21249828 3.58851552 0 3.58851552 0 4.65976034 6.21301379 4.65976034 3.37406034 7.49819828 4.12413793 8.24827586 8.24827586 4.12413793z"
          />
        </G>
      </G>
    </Svg>
  );
}

export default StartDate;
