import React, { useEffect, useContext } from "react";
import { useCategories } from "../stores/CategoryStore";
import { useGalleries } from "../stores/GalleryStore";
import { useCatalog } from "../stores/CatalogStore";
import { useFavourites } from "../stores/FavouritesStore";
import { useStylists } from "../stores/StylistStore";
import { useStoreTimings } from "../stores/StoreTimingsStore";
import config from "@presto-common/config";
import UserContext from "@presto-contexts/UserContext";
import _ from "lodash";

export default function UserDataLoader({ children }) {
  const { user } = useContext(UserContext);
  const categoriesStore = useCategories();
  const galleriesStore = useGalleries();
  const catalogStore = useCatalog();
  const favouritesStore = useFavourites();
  const stylistStore = useStylists();
  const storeTimingsStore = useStoreTimings();

  const loadMerchantCategories = () => {
    categoriesStore.fetch();
  };

  const loadGalleries = (args) => {
    galleriesStore.fetch(...args);
  };

  const loadCatalog = () => {
    catalogStore.fetch(true);
  };

  const loadFavourites = () => {
    favouritesStore.fetch(true);
  };

  const loadStylists = () => {
    stylistStore.fetch(true);
  };

  const loadStoreTimings = () => {
    storeTimingsStore.fetch(true);
  };

  useEffect(() => {
    if (user) {
      let merchantId =
        config.user_type === "admin"
          ? user.id
          : user.merchant_id || config.server_config.merchant_id;

      categoriesStore.init(merchantId);
      galleriesStore.init(user.merchant_id || config.server_config.merchant_id);
    }
    if (user && user.catalog_type) {
      catalogStore.init(user.catalog_type);
    } else {
      catalogStore.init(config?.constants?.default_catalog_name);
    }

    if (user) {
      favouritesStore.init();
    } else {
      favouritesStore.resetFavourites();
    }
    if (user) {
      storeTimingsStore.init(user);
      stylistStore.init();
    } else {
      storeTimingsStore.reset();
      stylistStore.reset();
    }
  }, [user?.id]);

  useEffect(() => {
    if (!galleriesStore.ready) return;
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "galleries") {
        loadGalleries(args);
      }
    });
  }, [galleriesStore.ready, galleriesStore.merchant_id]);

  useEffect(() => {
    if (!categoriesStore.ready) return;
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "categories") {
        loadMerchantCategories(...args);
      }
    });
  }, [categoriesStore.ready]);

  useEffect(() => {
    if (!categoriesStore.ready && !config.features.load_catalog) return;
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "categories") {
        loadMerchantCategories(...args);
      }
    });
  }, [categoriesStore.ready]);

  useEffect(() => {
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "catalog") {
        loadCatalog(...args);
      }
    });
  }, [catalogStore.catalogName]);

  useEffect(() => {
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "favourites") {
        loadFavourites(...args);
      }
    });
  }, [favouritesStore.ready]);

  useEffect(() => {
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "stylists") {
        loadStylists(...args);
      }
    });
  }, [stylistStore.ready]);

  useEffect(() => {
    _.forEach(config.user_data || [], ({ key, args = [] }) => {
      if (key === "storeTimings") {
        loadStoreTimings(...args);
      }
    });
  }, [storeTimingsStore.ready]);
  return <>{children}</>;
}
