import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Image } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import SearchManager from "@presto-services/features/search/SearchManager";
import utils from "../../utils/index";
import Spring from "@presto-components/Spring/Spring";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import DefaultModal from "@presto-components/Modals/DefaultModal";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import { DotIndicator } from "react-native-indicators";
import PlusMinusButton from "@presto-components/PlusMinusButtons/PlusMinusButton";
import RightPaneContext from "@presto-contexts/RightPaneContext";

export default function DetailedItemScreen({ route, navigation }) {
  const { itemId, item, variationId = "" } = route.params;
  const { user } = useContext(UserContext);
  const insets = useSafeAreaInsets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [updating, setUpdating] = useState(undefined);
  const { updateItemInCart, getCountOfItemInCart } = useContext(CartContext);
  const [product, setProduct] = useState(item);
  const loading = !product;
  const [state, setState] = useState(false);
  const isSoldOut = product?.sold_out || product?.stock_count === 0;
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);

  const onAdd = async (variationId) => {
    if (updating) {
      return true;
    }
    const count = getCountOfItemInCart(product.id, variationId);
    setUpdating(variationId || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variationId,
      count + 1
    );
    console.log("Error in Add", error, status);
    if (!status) {
      alert(utils.errorMessage(error));
    }
    setUpdating(undefined);
  };

  const onSubtract = async (variationId) => {
    if (updating) {
      return true;
    }
    const count = getCountOfItemInCart(product.id, variationId);
    if (count - 1 < 0) {
      return;
    }
    setUpdating(variationId || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variationId,
      count - 1 > 0 ? count - 1 : 0
    );
    if (!status) {
      alert(I18n.t("error_messages.error_updating_cart"));
    }
    setUpdating(undefined);
  };

  const onUpdate = async (variation, quantity) => {
    if (!user) {
      showLogin();
      return;
    }
    if (updating) {
      return true;
    }
    let qty = 0;
    setUpdating(product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variation?.id,
      qty ? qty : quantity
    );
    console.log("Error in onAmountEdit", error, status);
    if (!status) {
      alert(utils.errorMessage(error));
    }
    setUpdating(undefined);
  };

  useEffect(() => {
    if (itemId) {
      SearchManager.searchCategoryItem(
        { id: itemId },
        (response) => {
          if (response.hits.hits.length > 0) {
            setProduct(response.hits.hits[0]._source);
          } else {
            alert(I18n.t("error_messages.error_loading_product_str"));
          }
        },
        (error) => {
          alert(utils.errorMessage(error));
        }
      );
    }
  }, [itemId]);

  const Updating = () => {
    return (
      <View
        style={{
          alignItems: "center",
          height: 40,
        }}
      >
        <DotIndicator
          color={theme.primary}
          maxScale={1.2}
          minScale={0.2}
          size={8}
        />
      </View>
    );
  };

  const AddButton = () => {
    return (
      <View style={{ width: 100 }}>
        <PrestoFramedButton
          buttonStyle="primary"
          size={"large"}
          bgColor={theme.buttonBgColor}
          titleColor={theme.primary}
          borderWidth={1}
          title={I18n.t("screen_messages.common.add")}
          onPress={() => onUpdate && onUpdate("", 1)}
        />
      </View>
    );
  };

  const NotifyButton = () => {
    return (
      <View style={{ width: 100 }}>
        <PrestoSolidButton
          buttonStyle="primary"
          size={"large"}
          bgColor={theme.primaryLightColor}
          borderWidth={1}
          borderColor={theme.primary}
          titleColor={theme.primary}
          title={I18n.t("screen_messages.stocks.out_of_stock")}
          titleExtraStyle={{
            textTransform: "none",
          }}
          onPress={() => {}}
        />
      </View>
    );
  };

  const PlusMinus = ({ count }) => {
    let variation;
    return (
      <PlusMinusButton
        count={count}
        onPressPlus={onAdd}
        onPressMinus={onSubtract}
        onUpdate={onUpdate}
        variation={variation}
        mainColor={theme.primary}
        // textColor={theme.primary}
      />
    );
  };

  const renderItem = () => {
    const count = getCountOfItemInCart(product.id, variationId);
    const amount = count ? count : 1;
    const price = amount
      ? `${utils.formattedPrice(product.price.total_price * amount)}`
      : "0";

    if (product.other_data?.old_price !== "0") {
      const priceDiff =
        product.other_data?.old_price - product.price.base_price;
      savings = I18n.t("screen_messages.billing.discount_save_message", {
        amount: utils.formattedPrice(priceDiff),
      });
      const percentage = (
        (priceDiff / product.other_data?.old_price) *
        100
      ).toFixed(1);

      offer = `${percentage}%`;
    }
    let quantity = "";
    let displayName = product.other_data?.display_name;
    let displayQuantity = product.other_data?.display_quantity;
    let priceMultiplier = product.other_data?.display_price_multiplier;
    if (count > 0) {
      quantity = `${((count * displayQuantity) / priceMultiplier).toFixed(
        2
      )} ${displayName}`;
    } else {
      quantity = `${(displayQuantity / priceMultiplier).toFixed(
        2
      )} ${displayName}`;
    }

    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingVertical: 10,
        }}
      >
        <View style={{}}>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {quantity ? (
              <>
                <PrestoText
                  size={14}
                  fontStyle="medium"
                  color="#999999"
                  extraStyle={{
                    paddingRight: 5,
                    lineHeight: 16,
                    marginBottom: 8,
                  }}
                >
                  {I18n.t("screen_messages.quantity_text")}
                  <PrestoText fontStyle="medium" color="#333333">
                    {" : "} {quantity}
                  </PrestoText>
                </PrestoText>
              </>
            ) : (
              <PrestoText
                size={12}
                fontStyle="medium"
                color="#999999"
                extraStyle={{
                  paddingRight: 5,
                  lineHeight: 16,
                  marginBottom: 8,
                }}
              />
            )}

            {price !== "0" ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrestoText
                  fontStyle="medium"
                  color="#999999"
                  extraStyle={{ lineHeight: 16 }}
                >
                  {I18n.t("screen_messages.price")}
                  <PrestoText size={14} fontStyle="medium" color="#666666">
                    {" : "}
                    {price}
                  </PrestoText>
                </PrestoText>
              </View>
            ) : null}
          </View>
        </View>
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {count ? (
            <>{updating ? <Updating /> : <PlusMinus count={count} />}</>
          ) : (
            <>{isSoldOut ? <NotifyButton /> : <AddButton />}</>
          )}
        </View>
      </View>
    );
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const renderFooter = () => {
    return (
      <FlexRowSpaceAligned>
        <View
          style={{
            width: "100%",
          }}
        >
          <PrestoSolidButton
            size="large"
            onPress={() => {
              if (utils.isDesktop()) {
                goBack();
                setPaneState(false);
              } else {
                goBack();
              }
            }}
            titleColor={theme.white}
            title={I18n.t("screen_messages.common.continue_shopping")}
            buttonStyle="primary"
          />
        </View>
      </FlexRowSpaceAligned>
    );
  };

  const renderActionSheet = ({ margin }) => {
    return (
      <DefaultModal
        footer={renderFooter()}
        style={{ backgroundColor: "white" }}
      >
        <View
          style={{
            backgroundColor: "white",
            marginBottom: margin,
            flex: 1,
            paddingTop: insets.top,
            ...theme.lightShadow,
          }}
        >
          <LoadingContainer bgColor={theme.white} loading={loading}>
            {product ? (
              <>
                <ScrollView
                  style={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {product.pic_url ? (
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={{ uri: product.pic_url }}
                        style={{
                          width: "90%",
                          aspectRatio: 1,
                          resizeMode: "cover",
                          borderWidth: 0.1,
                          borderRadius: 15,
                          borderColor: theme.tertiary,
                        }}
                      />
                    </View>
                  ) : null}
                  <View
                    style={{
                      paddingHorizontal: theme.containerPadding,
                      marginTop: 20,
                    }}
                  >
                    <PrestoText
                      fontStyle={"medium"}
                      color={theme.darkText}
                      size={theme.h2FontSize}
                    >
                      {product.name}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={20} />
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                  {renderItem()}
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                  {product?.description ? (
                    <View
                      style={{
                        paddingHorizontal: theme.containerPadding,
                        marginVertical: 20,
                      }}
                    >
                      <PrestoText fontStyle={"bold"} color={theme.darkText}>
                        {I18n.t("screen_messages.products.product_details")}
                      </PrestoText>
                      {product.description.split("\u2022").map((i) => {
                        if (!i) return;
                        return (
                          <PrestoText
                            fontStyle={"lite"}
                            color={theme.paragraph}
                            extraStyle={{
                              paddingTop: 5,
                            }}
                          >
                            {"\u2022 " + _.trim(i)}
                          </PrestoText>
                        );
                      })}
                    </View>
                  ) : (
                    <VerticalSpacing size={20} />
                  )}
                  {product.other_data?.how_product ? (
                    <View style={{ paddingHorizontal: 20 }}>
                      <PrestoText fontStyle={"bold"} color={theme.darkText}>
                        {I18n.t(
                          "screen_messages.products.how_product_as_grown"
                        )}
                      </PrestoText>
                      <PrestoText
                        fontStyle={"lite"}
                        color={theme.paragraph}
                        extraStyle={{
                          paddingTop: 10,
                        }}
                      >
                        {product.other_data.how_product}
                      </PrestoText>
                      <VerticalSpacing size={20} />
                    </View>
                  ) : null}
                  {product.other_data?.pricing_strategy ? (
                    <View style={{ paddingHorizontal: 20 }}>
                      <PrestoText fontStyle={"bold"} color={theme.darkText}>
                        {I18n.t("screen_messages.products.pricing_strategy")}
                      </PrestoText>
                      <PrestoText
                        fontStyle={"lite"}
                        color={theme.paragraph}
                        extraStyle={{
                          paddingTop: 10,
                        }}
                      >
                        {product.other_data.pricing_strategy}
                      </PrestoText>
                      <VerticalSpacing size={20} />
                    </View>
                  ) : null}
                  <VerticalSpacing size={100} />
                </ScrollView>
              </>
            ) : null}
          </LoadingContainer>
        </View>
      </DefaultModal>
    );
  };

  const fromSpring = state.close ? { margin: 0 } : { margin: -600 };
  const toSpring = state.close ? { margin: -600 } : { margin: 0 };

  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => renderActionSheet(props)}
    </Spring>
  );
}
