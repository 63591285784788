import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const OperatorsResource = function ($http, PrestoSdk) {
  function getNearByOperators(params) {
    var url;
    if (params.merchant_id) {
      url =
        PrestoSdk.getHostName() +
        "/nearby_operators.json?location=" +
        params.currentLocation.latitude +
        "," +
        params.currentLocation.longitude +
        "&merchant_id=" +
        params.merchant_id;
    } else {
      url =
        PrestoSdk.getHostName() +
        "/nearby_operators.json?location=" +
        params.latitude +
        "," +
        params.longitude +
        "&merchant_id=" +
        PrestoSdk.getMerchantId();
    }
    return $http.get(url);
  }
  function operatorLeads(params) {
    if (params && !params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    var url = PrestoSdk.getHostName() + "/operator_leads.json";
    return $http.post(url, params);
  }

  function getAvailableOperators(params) {
    let str = Object.entries(params.operator)
      .map(([key, val]) => `operator[${key}]=${val}`)
      .join("&");
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/operators/list_available.json?" +
      str;
    return $http.get(url);
  }

  function createBooking(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/bookings.json";
    return $http.post(url, params);
  }

  function destroyBooking(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/" +
      params.booking_id +
      ".json";
    return $http.delete(url);
  }

  function getBookingInfoForServices(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/for_service_items.json?service_item_ids=" +
      params.service_item_ids;
    return $http.get(url, params);
  }

  function getAllBookings(params) {
    let str = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
    var url = PrestoSdk.getHostName() + "/employee/v1/bookings.json?" + str;
    return $http.get(url, params);
  }

  function loginOperatorWithToken(params) {
    var url = PrestoSdk.getHostName() + "/operator/v1/login_with_token.json";
    return $http.post(url, params);
  }

  function fetchOperatorAppointments(params) {
    var url =
      PrestoSdk.getHostName() + "/employee/v1/bookings/fetch_appointments.json";
    return $http.get(url, params);
  }

  function fetchOperatorAppointmentDetails(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/" +
      params.booking_id +
      "/appointment_details.json";
    return $http.get(url);
  }

  function startAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/" +
      params.booking_id +
      "/start_appointment.json";
    return $http.put(url, params);
  }

  function completeAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/" +
      params.booking_id +
      "/complete_appointment.json";
    return $http.put(url, params);
  }

  return {
    getNearByOperators: getNearByOperators,
    getAllBookings: getAllBookings,
    operatorLeads: operatorLeads,
    getAvailableOperators: getAvailableOperators,
    createBooking: createBooking,
    destroyBooking: destroyBooking,
    getBookingInfoForServices: getBookingInfoForServices,
    loginOperatorWithToken: loginOperatorWithToken,
    fetchOperatorAppointments: fetchOperatorAppointments,
    fetchOperatorAppointmentDetails: fetchOperatorAppointmentDetails,
    startAppointment: startAppointment,
    completeAppointment: completeAppointment,
  };
};

export default OperatorsResource($http, PrestoSdk);
