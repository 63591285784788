import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import PrestoIcon from "@presto-components/PrestoIcon";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import { useAssets } from "presto-react-components";

export default function CheckBox(props) {
  const { svgs } = useAssets();
  const { theme, onPress, style } = props;
  const disabled = props.disabled || false;
  const [state, setstate] = useState(props.state);
  const checkBoxHeight = props.checkBoxHeight;
  const color = props.color || theme.primary;
  const borderColorCheckedIcon = props.borderColorCheckedIcon || theme.primary;
  const checkBoxUnSelectedColor =
    props.checkBoxUnSelectedColor || theme.primary;
  const onClick = () => {
    if (onPress) {
      onPress(!state);
    } else {
      setstate(!state);
    }
  };
  useEffect(() => {
    setstate(props.state);
  }, [props.state]);
  const styles = getStyles(theme);
  return (
    <TouchableWithoutFeedback onPress={() => !disabled && onClick()}>
      <View style={{ ...styles.container, ...style }}>
        {state ? (
          <PrestoIcon
            icon={
              <svgs.CheckedIcon
                borderColor={borderColorCheckedIcon}
                color={color}
                width={checkBoxHeight}
                hight={checkBoxHeight}
              />
            }
          />
        ) : (
          <PrestoIcon
            icon={
              <svgs.UnCheckedIcon
                color={checkBoxUnSelectedColor}
                width={checkBoxHeight}
                hight={checkBoxHeight}
              />
            }
          />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}

function getStyles(theme) {
  return {
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: 15,
      paddingBottom: 15,
    },
  };
}
