import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        transform="translate(-300 -3039) translate(109 3002) rotate(90 198.543 44.944)"
        fill="#666"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        d="M198.598878 37.9386632L205.519923 51.9491201 191.565617 51.9491201z"
      />
    </Svg>
  )
}

export default SvgComponent
