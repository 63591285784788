import _ from "lodash";
import MerchantManager from "@presto-services/features/merchant/MerchantManager";
import create from "zustand";
import { devtools } from "zustand/middleware";
import moment from "moment";

let createFavourites = (set, get) => ({
  ready: false,
  favouriteMerchants: null,
  loading: false,
  lastTryTime: moment().subtract(1, "day"),

  init: () => {
    set({ ready: true, lastTryTime: moment().subtract(1, "day") });
  },

  toggleFavouriteMerchant: (obj) => {
    if (obj) {
      const merchants = get().favouriteMerchants || [];
      let flag = merchants.find((ele) => obj.merchant_id == ele.merchant_id);
      if (flag) {
        let filteredMerchants = merchants.filter(function (ele) {
          return ele.merchant_id != obj.merchant_id;
        });
        set({ favouriteMerchants: filteredMerchants });
      } else {
        set({ favouriteMerchants: [...merchants, obj] });
      }
    } else {
      set({ favouriteMerchants: [] });
    }
  },
  isFavouriteMerchant: (merchantId) => {
    const merchants = get().favouriteMerchants || [];
    return merchants.find((ele) => merchantId == ele.merchant_id) !== undefined;
  },
  resetFavourites: () => {
    set({ favouriteMerchants: [], lastTryTime: moment().subtract(1, "day") });
  },
  setFavourites: (favs) => {
    set({ favouriteMerchants: favs || [] });
  },
  fetch: async (force = false) => {
    console.log("Favourites Store :: fetch");
    const { loading: isLoading, lastTryTime } = get();
    console.log("Favourites Store :: fetch1");
    if (!force && moment() - moment(lastTryTime) < 60000) {
      return;
    }
    console.log("Favourites Store :: fetch2");
    if (!isLoading || force) {
      set({ loading: true, lastTryTime: new Date() });
      await fetchFavourites(set, get);
    }
  },
  refresh: () => {
    get().fetch(true);
  },
  getFavourites: () => {
    const items = get().favouriteMerchants;
    if (items) {
      return;
    } else {
      get().fetch();
      return undefined;
    }
  },
});

const fetchFavourites = async (set, get) => {
  console.log("Favourites Store :: Fetching Favourites");
  MerchantManager.getFavouriteMerchants(
    (response) => {
      let favObject = response.data.map((ele) => {
        if (ele) {
          return { merchant_id: ele.merchant_id, fav_merchant_id: ele.id };
        }
      });
      set({ favouriteMerchants: favObject, loading: false });
    },
    (error) => {
      set({ loading: false });
    }
  );
};

export const useFavourites = create(devtools(createFavourites));
