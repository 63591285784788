import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent({
  color="#6D6D6D"
}) {
  return (
    <Svg
      width="9px"
      height="12px"
      viewBox="0 0 9 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        transform="translate(-309 -232) rotate(90 313.5 238)"
        fill={color}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        d="M313.5 233.5L319.5 242.5 307.5 242.5z"
      />
    </Svg>
  )
}

export default SvgComponent
