import I18n from "i18n-js";
import utils from "../utils/index";

export default class OffersCardModel {
  constructor({ coupon, cart }) {
    this.coupon = coupon;
    this.cart = cart;
  }

  get couponCode() {
    return this.coupon.code;
  }

  get title() {
    return this.coupon.title;
  }

  get description() {
    return this.coupon.description;
  }
  get value_type() {
    return this.coupon.value_type;
  }

  get savingsPercentageTitle() {
    return this.coupon.value + "%";
  }

  get savingsFixedDiscountTitle() {
    return (
      I18n.t("screen_messages.common.currency") +
      " " +
      this.coupon.value / 100.0
    );
  }

  get minimumDiscountValueTitle() {
    return I18n.t("screen_messages.minimum_discount_value", {
      value: this.coupon.max_discount,
    });
  }

  get validityText() {
    return (
      I18n.t("screen_messages.coupons.coupon_validity_prefix") +
      utils.formatTime(this.coupon.end_time)
    );
  }

  get validityTextWithDescription() {
    return `\u2022 ${I18n.t(
      "screen_messages.common.valid_till"
    )} ${utils.formattedCouponTime(this.coupon.end_time)}`;
  }

  get validityTextWithDescriptionUmmaira() {
    return `\u2022 ${I18n.t(
      "screen_messages.valid_till"
    )} ${utils.formattedDate(this.coupon.end_time)}`;
  }

  get otherOfferText() {
    return `\u2022 ${I18n.t("screen_messages.offers.cant_combine_with_offer")}`;
  }

  get otherOfferTextUmmaira() {
    return `\u2022 ${I18n.t("screen_messages.cant_combine_with_offer")}`;
  }

  get applyRemoveTitle() {
    if (this.cart.coupon_code == this.coupon.code) {
      return I18n.t("screen_messages.common.remove");
    } else {
      return I18n.t("screen_messages.common.apply");
    }
  }

  get couponAppliedFlag() {
    if (this.cart.coupon_code == this.coupon.code) {
      return false;
    } else {
      return true;
    }
  }

  static fake() {
    return new OffersCardModel({
      coupon: {
        couponCode: "couponCode",
        title: "title",
        description: "description",
        savingsPercentageTitle: "savingsPercentageTitle",
        savingsFixedDiscountTitle: "savingsFixedDiscountTitle",
        validityText: "validityText",
        validityTextWithDescription: "validityTextWithDescription",
        otherOfferText: "otherOfferText",
        applyRemoveTitle: "applyRemoveTitle",
        couponAppliedFlag: "couponAppliedFlag",
      },
    });
  }
}
