import React, { useContext, useEffect } from "react";
import { StatusBar, Platform } from "react-native";
import NavigationManager from "../../common/NavigationManager";
import WebNavigationManager from "../../common/WebNavigationManager";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useMediaQuery } from "react-responsive";
import AnalyticsManager from "../../common/AnalyticsManager";
import Config, { updateConfig } from "@presto-common/config";

function setupApp() {
  AnalyticsManager.initialize();
}

export default function Main() {
  const { authState } = useContext(UserContext);
  console.log(
    "WebNavigationManager Ref authState : ",
    authState,
    WebNavigationManager.ref
  );
  useEffect(() => {
    if (Config.web.interakt_ai_id) {
      (function (w, d, s, c, r, a, m) {
        w["KiwiObject"] = r;
        w[r] =
          w[r] ||
          function () {
            (w[r].q = w[r].q || []).push(arguments);
          };
        w[r].l = 1 * new Date();
        a = d.createElement(s);
        m = d.getElementsByTagName(s)[0];
        a.async = 1;
        a.src = c;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        "script",
        "https://app.interakt.ai/kiwi-sdk/kiwi-sdk-17-prod-min.js?v=" +
          new Date().getTime(),
        "kiwi"
      );
      // window.addEventListener("load", function () {
      const intervalId = setInterval(() => {
        if (kiwi) {
          kiwi.init("", "976hwHmpw94LTejytUGBNLqbg0kTgoqj", {});
          clearInterval(intervalId);
        }
      }, 2000);
      // });
    }
    setupApp();
  }, []);
  const { theme } = useContext(ThemeContext);
  if (theme.statusBarColor && Platform.OS === "android") {
    StatusBar.setBackgroundColor(theme.statusBarColor);
    StatusBar.setBarStyle("dark-content");
  }

  const isDesktop = useMediaQuery({ minWidth: 941 });
  if (isDesktop) {
    return (
      <>
        {WebNavigationManager.renderTree(
          authState === AuthState.userLoggedIn,
          isDesktop
        )}
      </>
    );
  } else {
    return (
      <>
        {NavigationManager.renderTree(
          authState === AuthState.userLoggedIn,
          isDesktop
        )}
      </>
    );
  }
}
