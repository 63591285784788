import React from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function PrestoTouchableOpacity({
  eventName,
  eventAttr,
  onPress,
  ...props
}) {
  return (
    <TouchableOpacity
      {...props}
      onPress={() => {
        if (eventName) {
          AnalyticsManager.send(eventName, eventAttr);
        }
        onPress();
      }}
    />
  );
}
