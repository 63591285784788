import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
   <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(6 5)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      >
        <Path d="M1.69921875 1.69921875L6.796875 1.69921875 6.796875 0 0 0 0 6.796875 1.69921875 6.796875z" />
        <Path d="M22.203125 0L22.203125 1.69921875 27.3007812 1.69921875 27.3007812 6.796875 29 6.796875 29 0z" />
        <Path d="M27.3007812 27.3007812L22.203125 27.3007812 22.203125 29 29 29 29 22.203125 27.3007812 22.203125z" />
        <Path d="M1.69921875 22.203125L0 22.203125 0 29 6.796875 29 6.796875 27.3007812 1.69921875 27.3007812z" />
        <Path d="M3.455 3.398v8.497h8.496V6.797h1.699v-1.7h-1.7V3.399H3.456zm6.796 6.797H5.154V5.098h5.098v5.097zM25.544 25.488v-8.496h-3.398v-1.699h-1.7v1.7H15.35v1.698h1.7v1.7h-1.7v1.699h1.7v3.398h8.495zm-6.796-6.797h5.097v5.098h-5.097v-5.098z" />
        <Path d="M8.55228125 15.2929688L11.9507187 15.2929688 11.9507187 13.59375 3.454625 13.59375 3.454625 15.2929688 6.8530625 15.2929688 6.8530625 16.9921875 5.15384375 16.9921875 5.15384375 18.6914062 6.8530625 18.6914062 6.8530625 22.0898438 5.15384375 22.0898438 5.15384375 23.7890625 6.8530625 23.7890625 6.8530625 25.4882812 15.3491562 25.4882812 15.3491562 23.7890625 13.6499375 23.7890625 13.6499375 22.0898438 11.9507187 22.0898438 11.9507187 23.7890625 8.55228125 23.7890625 8.55228125 22.0898438 10.2515 22.0898438 10.2515 20.390625 8.55228125 20.390625z" />
        <Path d="M6.8530625 6.796875L8.55228125 6.796875 8.55228125 8.49609375 6.8530625 8.49609375z" />
        <Path d="M20.4468125 6.796875L22.1460313 6.796875 22.1460313 8.49609375 20.4468125 8.49609375z" />
        <Path d="M22.146 11.895h3.398V3.398h-8.496v8.497h3.399v1.699h1.699v-1.7zm-3.398-1.7V5.098h5.097v5.097h-5.097z" />
        <Path d="M25.5444688 15.2929688L25.5444688 13.59375 22.1460313 13.59375 22.1460313 15.2929688z" />
        <Path d="M20.4468125 20.390625L22.1460313 20.390625 22.1460313 22.0898438 20.4468125 22.0898438z" />
        <Path d="M13.6499375 18.6914062L15.3491563 18.6914062 15.3491563 20.390625 13.6499375 20.390625z" />
        <Path d="M10.2515 20.390625L11.9507188 20.390625 11.9507188 16.9921875 10.2515 16.9921875z" />
        <Path d="M11.9507188 15.2929688L13.6499375 15.2929688 13.6499375 16.9921875 11.9507188 16.9921875z" />
        <Path d="M5.15384375 18.6914062L3.454625 18.6914062 3.454625 22.0898438 5.15384375 22.0898438z" />
        <Path d="M3.454625 23.7890625L5.15384375 23.7890625 5.15384375 25.4882812 3.454625 25.4882812z" />
        <Path d="M13.6499375 3.3984375L15.3491563 3.3984375 15.3491563 5.09765625 13.6499375 5.09765625z" />
        <Path d="M13.6499375 10.1953125L15.3491563 10.1953125 15.3491563 6.796875 13.6499375 6.796875z" />
        <Path d="M15.3491563 13.59375L15.3491563 11.8945312 13.6499375 11.8945312 13.6499375 15.2929688 20.4468125 15.2929688 20.4468125 13.59375z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
