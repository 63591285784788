import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={34} height={30} viewBox="0 0 34 30" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M26.25 11.25a1.875 1.875 0 00-1.875-1.875H18.75v-3.75a5.625 5.625 0 10-11.25 0v3.75H1.875A1.875 1.875 0 000 11.25v15a1.875 1.875 0 001.875 1.875h7.5V15a1.875 1.875 0 011.875-1.875h15V11.25zM15.937 9.375h-5.624v-3.75a2.812 2.812 0 115.624 0v3.75zm11.72 9.375h-.938a.469.469 0 00-.469.469v1.406a3.754 3.754 0 01-4.124 3.732c-1.948-.19-3.376-1.94-3.376-3.898v-1.24a.469.469 0 00-.469-.469h-.937a.469.469 0 00-.469.469v1.21c0 2.86 2.05 5.41 4.885 5.774a5.63 5.63 0 006.365-5.578v-1.406a.469.469 0 00-.469-.469z"
        />
        <Path d="M31.875 15h-18.75a1.875 1.875 0 00-1.875 1.875v11.25A1.875 1.875 0 0013.125 30h18.75a1.875 1.875 0 001.875-1.875v-11.25A1.875 1.875 0 0031.875 15zm-3.75 5.625a5.632 5.632 0 01-6.365 5.578c-2.834-.363-4.885-2.915-4.885-5.774v-1.21a.469.469 0 01.469-.469h.937a.469.469 0 01.469.469v1.24c0 1.957 1.428 3.709 3.376 3.898a3.754 3.754 0 004.124-3.732v-1.406a.469.469 0 01.469-.469h.937a.469.469 0 01.469.469v1.406z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h33.75v30H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
