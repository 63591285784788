import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      {...props}
    >
      <Defs>
        <Path id="plusA" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="plusB" fill="#fff">
          <Use xlinkHref="#plusA" />
        </Mask>
        <G
          mask="url(#plusB)"
          fill={props.color || "#707070"}
          fillRule="nonzero"
        >
          <Path d="M12.6875 29.0018126L12.6875 16.3135196 0 16.3135196 0 12.688293 12.6875 12.688293 12.6875 0 16.3125 0 16.3125 12.688293 29 12.688293 29 16.3135196 16.3125 16.3135196 16.3125 29.0018126z" />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
