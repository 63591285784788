import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import ServiceItemObject from "./ServiceItemObject";
import SubscriptionItemObject from "./SubscriptionItemObject";
import CategoryItemObject from "./CategoryItemObject";
import $ from "../global/util";

const CategoryObject = function (
  Utils,
  ErrorConstants,
  ServiceItemObject,
  SubscriptionItemObject,
  CategoryItemObject
) {
  function CategoryObject() {
    this.catalog_id = "";
    this.categories = [];
    this.services = [];
    this.combo_items = [];
    this.description = "";
    this.hidden = undefined;
    this.id = "";
    this.items = [];
    this.subscription_items = [];
    this.listing_order = 0;
    this.name = "";
    this.outlet_id = "";
    this.pic_url = "";
    this.pictures = [];
    this.reference_id = "";
    this.subscription_items = [];
    this.seo_handle = "";
    this.seo_keywords = "";
    this.seo_title = "";
    this.seo_description = "";
    this.other_data = {};
    this.category_id = null;
  }

  CategoryObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawCategoryObject) {
      this.catalog_id = rawCategoryObject.catalog_id;
      this.category_id = rawCategoryObject.category_id;
      this.categories = [];
      var rawCategories = rawCategoryObject.categories;
      if (rawCategories) {
        var parsedCategories = [];
        rawCategories.forEach(function (rawCategory, index, rawCategories) {
          var categoryObject = new CategoryObject();
          categoryObject.buildObject(rawCategory);
          parsedCategories.push(categoryObject);
        });
        this.categories = parsedCategories;
      }
      this.services = [];
      var rawServices = rawCategoryObject.services;
      if (rawServices) {
        var parsedServices = [];
        rawServices.forEach(function (rawService, index, rawServices) {
          var serviceItem = new ServiceItemObject();
          serviceItem.buildObject(rawService);
          parsedServices.push(serviceItem);
        });
        this.services = parsedServices;
      }
      this.combo_items = rawCategoryObject.combo_items;
      this.description = rawCategoryObject.description;
      this.hidden = rawCategoryObject.hidden;
      this.id = rawCategoryObject.id;
      this.items = [];
      var rawItems = rawCategoryObject.items;
      if (rawItems) {
        var parsedItems = [];
        rawItems.forEach(function (rawItem, index, rawItems) {
          var item = new CategoryItemObject();
          item.buildObject(rawItem);
          parsedItems.push(item);
        });
        this.items = parsedItems;
      }
      this.subscription_items = [];
      var rawSubcriptionItems = rawCategoryObject.subscription_items;
      if (rawSubcriptionItems) {
        var parsedSubscriptionItems = [];
        rawSubcriptionItems.forEach(function (
          rawSubscriptionItem,
          index,
          rawSubcriptionItems
        ) {
          var item = new SubscriptionItemObject();
          item.buildObject(rawSubscriptionItem);
          parsedSubscriptionItems.push(item);
        });
        this.subscription_items = parsedSubscriptionItems;
      }
      this.listing_order = rawCategoryObject.listing_order;
      this.name = rawCategoryObject.name;
      this.outlet_id = rawCategoryObject.outlet_id;
      this.pic_url = rawCategoryObject.pic_url;
      this.pictures = rawCategoryObject.pictures;
      this.reference_id = rawCategoryObject.reference_id;
      this.subscription_items = rawCategoryObject.subscription_items;
      this.seo_handle = rawCategoryObject.seo_handle;
      this.seo_keywords = rawCategoryObject.seo_keywords;
      this.seo_title = rawCategoryObject.seo_title;
      this.seo_description = rawCategoryObject.seo_description;
      this.other_data = rawCategoryObject.other_data;
    },
  };

  return CategoryObject;
};

export default CategoryObject(
  Utils,
  ErrorConstants,
  ServiceItemObject,
  SubscriptionItemObject,
  CategoryItemObject
);
