import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg"

function SvgComponent({
  width="45px",
  height="44px",
  fillColor1="#FFF",
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Defs>
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="29.2227031%"
          y2="100%"
          id="a"
        >
          <Stop stopColor="#E1E1E1" offset="0%" />
          <Stop stopColor="#A1A6B5" offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill={fillColor1} d="M0 0H45V44H0z" />
        <G fillRule="nonzero">
          <Path
            d="M13.117.561l6.766 3.864A4.18 4.18 0 0122 8.052v7.729a4.18 4.18 0 01-2.117 3.627l-6.766 3.864a4.274 4.274 0 01-4.234 0l-6.766-3.864A4.18 4.18 0 010 15.781V8.052a4.18 4.18 0 012.117-3.627L8.883.561a4.274 4.274 0 014.234 0z"
            transform="translate(10 9) translate(1 1)"
            fill="#000"
          />
          <Path
            d="M9.387.693a5.275 5.275 0 015.226 0l6.766 3.864a5.208 5.208 0 011.917 1.897A5.15 5.15 0 0124 9.052v7.729a5.15 5.15 0 01-.704 2.598 5.208 5.208 0 01-1.917 1.897l-6.766 3.864a5.275 5.275 0 01-5.226 0l-6.766-3.864A5.208 5.208 0 01.704 19.38 5.15 5.15 0 010 16.78V9.052c0-.928.25-1.821.704-2.598A5.208 5.208 0 012.62 4.557L9.387.693z"
            stroke="#D5D3D3"
            strokeWidth={2}
            fill="#FFF"
            transform="translate(10 9)"
          />
          <Path
            d="M13.54 4.658l4.92 2.81A3.04 3.04 0 0120 10.106v5.62a3.04 3.04 0 01-1.54 2.639l-4.92 2.81a3.108 3.108 0 01-3.08 0l-4.92-2.81A3.04 3.04 0 014 15.727v-5.62a3.04 3.04 0 011.54-2.639l4.92-2.81a3.108 3.108 0 013.08 0z"
            fill="url(#a)"
            transform="translate(10 9)"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
