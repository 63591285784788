import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function WhtsappFilledIcon(props) {
  return (
    <Svg
    width={props.width || 40}
    height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M29.596 11.508C27.963 4.72 22.078.105 14.994 0c-.94.035-1.95.117-2.866.304C2.19 2.278-2.932 13.19 1.885 21.988c.164.245.164.467.129.736-.693 2.267-1.34 4.615-1.962 6.917-.094.409-.094.409.282.292a330.753 330.753 0 017.26-1.858c.211-.07.352-.07.528.035 3.113 1.577 6.39 1.998 9.833 1.367 8.21-1.577 13.603-9.896 11.641-17.969z"
          transform="translate(5 5)"
          fill={props.color1 || "#707070"}
        />
        <Path
          d="M24.891 26.268c-.531.048-1.074-.06-1.593-.216-3.511-1.018-6.178-3.16-8.314-6.058-.797-1.077-1.557-2.239-1.786-3.592-.326-1.592.169-2.993 1.376-4.082.41-.371 1.58-.55 2.051-.311.157.143.241.263.35.419.434 1.077.893 2.083 1.34 3.124a.876.876 0 01-.061.647c-.241.467-.603.862-.953 1.245-.29.251-.29.55-.085.838 1.255 2.095 2.957 3.592 5.25 4.466.325.143.579.096.784-.168.398-.443.784-.934 1.146-1.413.23-.31.531-.359.857-.227.76.347 1.496.706 2.22 1.03.193.095.338.18.52.227.76.383.76.443.663 1.245-.097 1.592-1.327 2.395-2.74 2.79a2.716 2.716 0 01-1.025.036"
          fill={props.color2 || "#FFFFFE"}
        />
      </G>
    </Svg>
  )
}

export default WhtsappFilledIcon