export default class CategoryModel {
  constructor({ category, icon, name }) {
    this.category = category;
    this.icon = icon || category.pic_url;
    this.name = name || category.name;
  }

  get footerText() {
    return this.category.footerText || "";
  }
  get description() {
    return this.category.description ? this.category.description : "";
  }
  get topImage() {
    return this.icon;
  }
  get title() {
    return this.name;
  }

  static fake() {
    return new CategoryModel({
      title: "title",
      description: "description",
      footerText: "footerText",
    });
  }
}
