import * as React from "react";
import Svg, {
  Defs,
  G,
  Path,
  Mask,
  Use,
} from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="serch_1" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(6 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="serch_2" fill={props.color2 || "#fff"}>
          <Use xlinkHref="#serch_1" />
        </Mask>
        <G mask="url(#serch_2)">
          <Path
            fill="none"
            d="M0.000479853363 0.000479853363H29.000479853363V29.000479853363H0.000479853363z"
          />
          <Path
            d="M28.82 26.282L22.837 20.3a12.41 12.41 0 002.537-7.612A12.61 12.61 0 0012.688 0 12.61 12.61 0 000 12.688a12.61 12.61 0 0012.688 12.687 12.41 12.41 0 007.612-2.537l5.982 5.981 2.537-2.537zM3.624 12.688a8.974 8.974 0 019.063-9.063 8.974 8.974 0 019.062 9.063 8.974 8.974 0 01-9.062 9.062 8.974 8.974 0 01-9.063-9.062z"
            fill={props.color || "#707070"}
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
