import React, { useContext } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import BestSellers from "@presto-screen-components/BestSellers/BestSellers";
import WebLayout from "@presto-screens/WebLayout/WebLayout";

export default function BestSeller({ navigation }) {
  const { theme } = useContext(ThemeContext);

  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;

  if (isDesktop) {
    return (
      <WebLayout>
        <View
          style={{
            flex: 1,
            backgroundColor: theme.backgroundColor,
            paddingBottom: 0,
            width: "90%",
            alignSelf: "center",
          }}
        >
          <ScrollView
            scrollEnabled={true}
            style={{ width: "100%", height: "100%" }}
            contentContainerStyle={{}}
          >
            <BestSellers showAll={true} />
          </ScrollView>
        </View>
      </WebLayout>
    );
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 0,
        }}
      >
        <ScrollView
          scrollEnabled={true}
          style={{ width: "100%", height: "100%" }}
          contentContainerStyle={{}}
        >
          <BestSellers showAll={true} />
        </ScrollView>
        <FloatingCartComponent />
      </View>
    );
  }
}
