import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={35} height={31} viewBox="0 0 35 31" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M0 12.594V6.78c0-.77.307-1.51.854-2.055a2.922 2.922 0 012.063-.851h29.166c.774 0 1.516.306 2.063.851A2.9 2.9 0 0135 6.781v5.813c-.773 0-1.515.306-2.062.851a2.9 2.9 0 000 4.11 2.922 2.922 0 002.062.851v5.813c0 .77-.307 1.51-.854 2.055a2.922 2.922 0 01-2.063.851H2.917a2.922 2.922 0 01-2.063-.851A2.901 2.901 0 010 24.219v-5.813c.774 0 1.515-.306 2.062-.851a2.9 2.9 0 000-4.11A2.922 2.922 0 000 12.594zm10.694 11.625a.975.975 0 00.899-.598.966.966 0 00-.709-1.321.975.975 0 00-.998.412.966.966 0 00.808 1.507zm0-3.875a.975.975 0 00.899-.598.966.966 0 00-.709-1.321.975.975 0 00-.998.412.966.966 0 00.808 1.507zm0-3.875a.975.975 0 00.899-.598.966.966 0 00-.709-1.321.975.975 0 00-.998.412.966.966 0 00.808 1.507zm0-3.875a.975.975 0 00.899-.598.966.966 0 00-.709-1.321.975.975 0 00-.998.412.966.966 0 00.808 1.507zm0-3.875a.975.975 0 00.899-.598.966.966 0 00-.709-1.321.975.975 0 00-.998.412.966.966 0 00.808 1.507z"
        />
        <Path d="M10.694 18.406a.975.975 0 01.899.598.965.965 0 01-.211 1.056.973.973 0 01-1.66-.685.967.967 0 01.972-.969zm0 3.875a.975.975 0 01.899.598.965.965 0 01-.211 1.056.973.973 0 01-1.66-.685.967.967 0 01.972-.969zm0-13.562a.974.974 0 01-.898-.598.966.966 0 01.709-1.321.976.976 0 01.998.412.966.966 0 01-.809 1.507zm0 5.812a.975.975 0 01.899.598.965.965 0 01-.211 1.056.973.973 0 01-1.66-.685.967.967 0 01.972-.969zm0-3.875a.975.975 0 01.899.598.965.965 0 01-.211 1.056.973.973 0 01-1.66-.685.967.967 0 01.972-.969z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill="#fff"
            transform="matrix(-1 0 0 1 35 0)"
            d="M0 0h35v31H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
