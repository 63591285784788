import React from "react";
import { ScrollView, View, Dimensions } from "react-native";
import Drawer from "@presto-screens/Drawers/Drawer";
import HomeScreen from "@presto-screens/Home/Home";
import LoginScreen from "@presto-screens/Login/Login";
import RegisterScreen from "@presto-screens/Register/Register";
import ReferScreen from "@presto-screens/Refer/Refer";
import NotificationsScreen from "@presto-screens/Notifications/Notifications";
import ProfileScreen from "@presto-screens/Profile/Profile";
import WelcomeScreen from "@presto-screens/Welcome/WelcomeScreen";
import OrdersList from "@presto-screens/OrdersList/OrdersList";
import CouponsScreen from "@presto-screens/Coupons/Coupons";
import CouponApplyScreen from "@presto-screens/CouponApply/CouponApply";
import ContactScreen from "@presto-screens/Contact/Contact";
import WalletScreen from "@presto-screens/Wallet/Wallet";
import TopupScreen from "@presto-screens/Topup/Topup";
import TopupPinScreen from "@presto-screens/TopupPinScreen/TopupPin";
import VerifyPinOtpScreen from "@presto-screens/VerifyPinOtp/VerifyPinOtp";
import SetAndConfirmPinScreen from "@presto-screens/SetAndConfirmPin/SetAndConfirmPin";
import PaymentsScreen from "@presto-screens/Payments/Payments";
import TopupSuccessScreen from "@presto-screens/TopupSuccessScreen/TopupSuccess";
import CategoriesScreen from "@presto-screens/Categories/Categories";
import TransactionScreen from "@presto-screens/Transactions/Transactions";
import BrowseCategoriesScreen from "@presto-screens/BrowseCategories/BrowseCategories";
import WebBrowseCategoriesScreen from "@presto-screens/BrowseCategories/WebBrowseCategories";
import SearchResultsScreen from "@presto-screens/SearchResults/SearchResults";
import WebSearchResultsScreen from "@presto-screens/SearchResults/WebSearchResults";
import DetailedItemScreen from "@presto-screens/DetailedItemScreen/DetailedItemScreen";
import CartScreen from "@presto-screens/Cart/Cart";
import CartPayment from "@presto-screens/CartPayment/CartPayment";
import TrackOrderScreen from "@presto-screens/TrackOrder/TrackOrder";
import OrderSuccessScreen from "@presto-screens/OrderSuccess/OrderSuccess";
import OnOfferScreen from "@presto-screens/OnOfferScreen/OnOfferScreen";
import BestSellerScreen from "@presto-screens/BestSeller/BestSeller";
import TopDrawerHeaderWithLogo from "@presto-screen-components/Headers/TopDrawerHeaderWithLogo";
import TopHeaderWithLogoAndBack from "@presto-screen-components/Headers/TopHeaderWithLogoAndBack";
import TopHeaderWithTitle from "@presto-screen-components/Headers/TopHeaderWithTitle";
import OrderPinScreen from "@presto-screens/OrderPin/OrderPin";
import WebHome from "@presto-screens/Home/WebHome";
import About from "@presto-screens/AboutUs/About";
import WebView from "@presto-screens/WebView/WebView";
import GeneralStatement from "@presto-screens/GeneralStatement/GeneralStatement";
import ListItemsAvailableForDiscount from "@presto-screens/ListItemsAvailableForDiscount/ListItemsAvailableForDiscount";
import _ from "lodash";

const windowWidth = Dimensions.get("window").width;
let isDesktop = windowWidth > 940 ? true : false;

function EmptyScreen() {
  return (
    <ScrollView style={{}}>
      <View
        style={{
          width: "100%",
          height: 1000,
          justifyContent: "center",
          alignItems: "center",
        }}
      ></View>
    </ScrollView>
  );
}

export default {
  mobile: {
    logged_out: "LOGGED_OUT",
    logged_in: "DRAWER",
    main: "DRAWER",
    screens: {
      DRAWER: {
        component: Drawer,
        type: "DRAWER",
        tabScreens: [
          {
            screen: "HOME_SCREEN",
            name: "HOME",
          },
          {
            screen: "OFFERS_SCREEN",
            name: "OFFERS",
          },
          {
            screen: "REFERRAL_SCREEN",
            name: "REFER",
          },
          {
            screen: "NOTIFICATIONS_SCREEN",
            name: "NOTIFICATIONS",
          },
          {
            screen: "PROFILE_SCREEN",
            name: "ACCOUNT",
          },
          {
            screen: "MY_ORDERS",
            name: "MY_ORDERS",
          },
          {
            screen: "WALLET_SCREEN",
            name: "WALLET",
          },
          {
            screen: "CONTACT_SCREEN",
            name: "CONTACT",
          },
        ],
      },
      LOGGED_OUT: {
        component: LoginScreen,
        header: false,
        path: "logout",
      },
      LOGIN: {
        component: LoginScreen,
        header: false,
        path: "login",
      },
      SIGNUP_SCREEN: {
        component: RegisterScreen,
        header: false,
        path: "signup",
      },
      HOME_SCREEN: {
        component: HomeScreen,
        header: TopDrawerHeaderWithLogo,
      },
      OFFERS_SCREEN: {
        component: (props) => {
          const route = _.get(props, "route", {});
          route.params = { ...route?.params, hideHeader: true };
          return <CouponsScreen {...props} route={route} />;
        },
        header: TopHeaderWithTitle,
        headerParams: {
          title: "screen_messages.coupons.apply_coupon",
        },
        path: "offers",
      },
      COUPON_APPLY: {
        component: CouponApplyScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "screen_messages.coupons.apply_coupon",
        },
        path: "apply_coupon",
      },
      REFERRAL_SCREEN: {
        component: ReferScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Refer & Earn",
        },
        path: "refer",
      },
      NOTIFICATIONS_SCREEN: {
        component: NotificationsScreen,
        header: TopHeaderWithLogoAndBack,
        path: "notifications",
        headerParams: {
          showWallet: true,
          showCart: false,
        },
      },
      WELCOME_SCREEN: {
        component: WelcomeScreen,
      },
      MY_ORDERS: {
        component: OrdersList,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "My Orders",
        },
        path: "my_orders",
      },
      PROFILE_SCREEN: {
        component: ProfileScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Profile",
          goBack: isDesktop ? false : true,
        },
        path: "profile",
      },
      ONBOARD_PIN_SETUP: {
        component: SetAndConfirmPinScreen,
        header: false,
      },
      PAYMENTS: {
        component: PaymentsScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
        path: "payment",
      },
      CONTACT_SCREEN: {
        component: ContactScreen,
        path: "contact",
      },
      WALLET_SCREEN: {
        component: WalletScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
          goBack: isDesktop ? false : true,
        },
        path: "wallet",
      },
      TOPUP_PIN: {
        component: TopupPinScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
        path: "topup_pin",
      },
      VERIFY_PIN: {
        component: VerifyPinOtpScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
        path: "verify_pin",
      },
      ADD_MONEY: {
        component: TopupScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
        path: "add_topup",
      },
      ONBOARD_PIN_SETUP: {
        component: SetAndConfirmPinScreen,
        headerParams: {
          title: "Wallet",
        },
      },
      PIN_SETUP: {
        component: SetAndConfirmPinScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
      },
      PAYMENTS: {
        component: PaymentsScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
      },
      TOPUP_SUCCESS: {
        component: TopupSuccessScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Wallet",
        },
        path: "topup_success",
      },
      CATEGORIES: {
        component: CategoriesScreen,
        header: TopHeaderWithLogoAndBack,
        path: "categories",
        headerParams: {
          showWallet: false,
          showCart: true,
        },
      },
      TRANSACTIONS: {
        component: TransactionScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "All Transactions",
        },
        path: "transactions",
      },
      BROWSE_CATEGORIES: {
        component: BrowseCategoriesScreen,
        header: TopHeaderWithLogoAndBack,
        path: "browse",

        headerParams: {
          showWallet: false,
          showCart: true,
        },
      },
      SEARCH: {
        component: SearchResultsScreen,
        header: TopHeaderWithLogoAndBack,
        headerParams: {
          hideSearch: true,
          showWallet: false,
          showCart: true,
        },
        path: "search",
      },
      DETAILED_ITEM: {
        component: DetailedItemScreen,
        path: "item_Details",
        fadeIn: true,
      },
      CART: {
        component: CartScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Shopping Cart",
          goBack: isDesktop ? false : true,
        },
        path: "cart",
      },
      CART_PAYMENT: {
        component: CartPayment,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Payment Options",
        },
        path: "payment_options",
      },
      ORDER_DETAILS_SCREEN: {
        component: TrackOrderScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Order Details",
        },
        path: "order_details",
      },
      TRACK_ORDER_SCREEN: {
        component: TrackOrderScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Order Details",
        },
        path: "track_order",
      },
      ORDER_SUCCESSFUL: {
        component: OrderSuccessScreen,
        path: "order_success",
      },
      ON_OFFER_SCREEN: {
        component: OnOfferScreen,
        header: TopHeaderWithLogoAndBack,
        headerParams: {
          showWallet: false,
          showCart: true,
        },
      },
      ON_BEST_SELLER_SCREEN: {
        component: BestSellerScreen,
        header: TopHeaderWithLogoAndBack,
        path: "bestseller",
        headerParams: {
          showWallet: false,
          showCart: true,
        },
      },
      ORDER_PIN: {
        component: OrderPinScreen,
        header: TopHeaderWithLogoAndBack,
        path: "order_pin",
        headerParams: {
          showWallet: true,
          showCart: false,
        },
      },
      aboutus: {
        component: About,
        header: TopHeaderWithLogoAndBack,
        path: "aboutus",
        headerParams: {
          showWallet: true,
          showCart: false,
        },
      },
      HUB_SPOT_FORM: {
        component: WebView,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Sign up",
        },
      },
      GENERAL_STATEMENT_SCREEN: {
        component: GeneralStatement,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Generate Statement",
        },
        path: "generate_statement",
      },
      LIST_ITEMS_AVAILABLE_FOR_DISCOUNT: {
        path: "list_items_available_for_discount",
        component: ListItemsAvailableForDiscount,
        header: false,
        fadeIn: true,
      },
    },
  },
  desktop: {
    logged_out: "LOGIN",
    logged_in: "HOME_SCREEN",
    main: "HOME_SCREEN",
    screens: {
      DRAWER: {
        component: Drawer,
        type: "DRAWER",
        tabScreens: [
          {
            screen: "HOME_SCREEN",
            name: "HOME",
          },
          {
            screen: "OFFERS_SCREEN",
            name: "OFFERS",
          },
          {
            screen: "REFERRAL_SCREEN",
            name: "REFER",
          },
          {
            screen: "NOTIFICATIONS_SCREEN",
            name: "NOTIFICATIONS",
          },
          {
            screen: "PROFILE_SCREEN",
            name: "ACCOUNT",
          },
          {
            screen: "MY_ORDERS",
            name: "MY_ORDERS",
          },
          {
            screen: "WALLET_SCREEN",
            name: "WALLET",
          },
          {
            screen: "CONTACT_SCREEN",
            name: "CONTACT",
          },
        ],
      },
      BROWSE_CATEGORIES: {
        component: WebBrowseCategoriesScreen,
        header: TopHeaderWithLogoAndBack,
        headerParams: {},
        path: "browse",
      },
      SEARCH: {
        component: WebSearchResultsScreen,
        header: TopHeaderWithLogoAndBack,
        headerParams: {
          hideSearch: true,
        },
        path: "search",
      },
      LOGGED_OUT: {
        component: LoginScreen,
        header: false,
      },
      LOGIN: {
        component: LoginScreen,
        header: false,
      },
      SIGNUP_SCREEN: {
        component: RegisterScreen,
        header: false,
      },
      HOME_SCREEN: {
        component: WebHome,
        header: false,
        path: "home",
      },
      OFFERS_SCREEN: {
        component: CouponsScreen,
        header: false,
        headerParams: {
          title: "screen_messages.coupons.apply_coupon",
        },
      },
      COUPON_APPLY: {
        component: CouponApplyScreen,
        header: false,
        headerParams: {
          title: "screen_messages.coupons.apply_coupon",
        },
      },
      REFERRAL_SCREEN: {
        component: ReferScreen,
        header: false,
        headerParams: {
          title: "Refer & Earn",
        },
      },
      NOTIFICATIONS_SCREEN: {
        component: NotificationsScreen,
        header: false,
      },
      WELCOME_SCREEN: {
        component: WelcomeScreen,
      },
      MY_ORDERS: {
        component: OrdersList,
        header: false,
        headerParams: {
          title: "My Orders",
        },
      },
      PROFILE_SCREEN: {
        component: ProfileScreen,
        header: false,
        headerParams: {
          title: "Profile",
        },
      },
      CONTACT_SCREEN: {
        component: ContactScreen,
      },
      WALLET_SCREEN: {
        component: WalletScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      TOPUP_PIN: {
        component: TopupPinScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      VERIFY_PIN: {
        component: VerifyPinOtpScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      ADD_MONEY: {
        component: TopupScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      ONBOARD_PIN_SETUP: {
        component: SetAndConfirmPinScreen,
        headerParams: {
          title: "Wallet",
        },
      },
      PIN_SETUP: {
        component: SetAndConfirmPinScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      PAYMENTS: {
        component: PaymentsScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      TOPUP_SUCCESS: {
        component: TopupSuccessScreen,
        header: false,
        headerParams: {
          title: "Wallet",
        },
      },
      CATEGORIES: {
        component: CategoriesScreen,
        header: TopHeaderWithLogoAndBack,
      },
      TRANSACTIONS: {
        component: TransactionScreen,
        header: false,
        headerParams: {
          title: "All Transactions",
        },
      },

      DETAILED_ITEM: {
        component: DetailedItemScreen,
        fadeIn: true,
      },
      CART: {
        component: CartScreen,
        header: false,
        headerParams: {
          title: "Shopping Cart",
        },
      },
      CART_PAYMENT: {
        component: CartPayment,
        header: false,
        headerParams: {
          title: "Payment Options",
        },
      },
      ORDER_DETAILS_SCREEN: {
        component: TrackOrderScreen,
        header: false,
        headerParams: {
          title: "Order Details",
        },
      },
      TRACK_ORDER_SCREEN: {
        component: TrackOrderScreen,
        header: false,
        headerParams: {
          title: "Order Details",
        },
      },
      ORDER_SUCCESSFUL: {
        component: OrderSuccessScreen,
      },
      ON_OFFER_SCREEN: {
        component: OnOfferScreen,
        header: false,
      },
      ON_BEST_SELLER_SCREEN: {
        component: BestSellerScreen,
        header: false,
      },
      ORDER_PIN: {
        component: OrderPinScreen,
        header: false,
      },
      HUB_SPOT_FORM: {
        component: WebView,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Sign up",
        },
      },
      aboutus: {
        component: About,
        header: TopHeaderWithLogoAndBack,
        headerParams: {
          hideSearch: true,
        },
        path: "aboutus",
      },
    },
  },
};
