import React, { useReducer } from "react";
import _ from "lodash";

function paginationReducer(state, { payload, type }) {
  switch (type) {
    case "SET_RESULTS":
      let lTransaction = _.last(payload);
      if (lTransaction) {
        return {
          ...state,
          results: [...state.results, ...payload],
          loading: false,
          lastItem: lTransaction,
          done: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          done: true,
        };
      }

    case "SET_FIRST_RESULTS":
      let lastTransaction = _.last(payload);
      return {
        ...state,
        results: payload,
        loading: false,
        lastItem: lastTransaction,
        done: false,
      };

    case "FETCH_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "START_FETCH":
      return {
        ...state,
        loading: true,
      };
  }
  return state;
}

export function usePagination(loadFunction, lastItem) {
  const [state, dispatch] = useReducer(paginationReducer, {
    loading: false,
    results: [],
    pageLoaded: 0,
    done: false,
    lastItem: lastItem,
  });

  const loadNextPage = () => {
    const { pageLoaded, loading, done, lastItem } = state;
    //console.log("Loading next page. ", loading, pageLoaded);
    //console.log("Loading next lastItem. ", lastItem);
    if (done) return;
    if (!loading) {
      dispatch({
        type: "START_FETCH",
        payload: {},
      });
      loadFunction(
        lastItem,
        (response) => {
          dispatch({
            type: "SET_RESULTS",
            payload: response,
          });
        },
        (error) => {
          dispatch({
            type: "FETCH_FAILED",
            payload: {},
          });
        }
      );
    }
  };
  const loadFirstPage = () => {
    const { pageLoaded, loading } = state;
    //console.log("Loading first page", loading, pageLoaded);
    if (!loading) {
      dispatch({
        type: "START_FETCH",
        payload: {},
      });
      loadFunction(
        undefined,
        (response) => {
          //console.log("Loading first page response", response);
          dispatch({
            type: "SET_FIRST_RESULTS",
            payload: response,
          });
        },
        (error) => {
          dispatch({
            type: "FETCH_FAILED",
            payload: {},
          });
        }
      );
    }
  };
  return [
    state.results,
    loadNextPage,
    loadFirstPage,
    state.loading,
    state.pageLoaded,
  ];
}
