import * as React from "react";
import Svg, { G, Circle, Text, TSpan } from "react-native-svg";

function AboutUsIcon(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(2 3)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          stroke={props.color || "#707070"}
          strokeWidth={1.485}
          fill={props.color1 || "#707070"}
          cx={17.5}
          cy={17.5}
          r={16.7575}
        />
        <Text
          fontSize={19.8}
          fontStyle="italic"
          fontWeight="normal"
          fill={props.color2 || "#FFF"}
        >
          <TSpan x={15.0646} y={25.2954545}>
            {"i"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default AboutUsIcon;
