import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        stroke={props.color || "#707070"}
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <G transform="translate(3 7)">
          <Path
            strokeWidth={3.85}
            d="M0 0L0 15.8826583 9.87096774 15.8826583"
          />
          <Path
            strokeWidth={1.815}
            d="M4.93548387 15.8826583L4.93548387 26 0 26 9.87096774 26"
          />
        </G>
        <G transform="translate(3 7) matrix(-1 0 0 1 34 0)">
          <Path
            strokeWidth={3.85}
            d="M0 0L0 15.8826583 9.87096774 15.8826583"
          />
          <Path
            strokeWidth={1.815}
            d="M4.93548387 15.8826583L4.93548387 26 0 26 9.87096774 26"
          />
        </G>
        <G transform="translate(3 7) translate(4.935 5.417)">
          <Path
            strokeWidth={1.815}
            d="M11.9826524 6.75176966L11.9826524 20.5833333 7.90863009 20.5833333 16.0566747 20.5833333"
          />
          <Path
            d="M.014 6.729c-.085-1.71.21-3.131.887-4.262C1.577 1.337 2.623.514 4.039 0h16.526a5.98 5.98 0 012.436 2.467c.557 1.051.933 2.472 1.128 4.262H.015z"
            strokeWidth={1.21}
            fill={props.color || "#707070"}
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
