import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import TextInputComponent from "@presto-components/TextInputs/TextInputComponent";
import SectionLinkComponent from "@presto-components/SectionLinks/SectionLinkComponent";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import LoadingContainer from "@presto-components/LoadingContainer";
import I18n from "i18n-js";
import { prestoAlert } from "../../common/Alert";
import utils from "../../utils";
import DefaultModal from "@presto-components/Modals/DefaultModal";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";

export default function VerifyPinOtpScreen(props) {
  const { svgs } = useAssets();
  const [otp, setOtp] = useState();
  const pin = props.route.params?.pin;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const phone_number = user.phone_number;
  const [loading, setLoading] = useState(true);
  const { Navigator } = useContext(NavigatorContext);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  useEffect(() => {
    initiateOTPRequest();
  }, []);

  const initiateOTPRequest = () => {
    WalletManager.onSetUserPinOtp(
      { wallet_id: user.wallet_id },
      (response) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(utils.errorMessage(error));
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error)
        );
      }
    );
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPressSubmit = () => {
    const params = {
      wallet_id: user.wallet_id,
      wallet: {
        pin: pin,
        pin_confirmation: pin,
        otp: otp,
      },
    };
    setLoading(true);
    WalletManager.onSetUserPin(
      params,
      (response) => {
        Navigator.emit({ event: "goBack" });
        console.log("Wallet response : ", response);
        setLoading(false);
        alert(
          I18n.t("screen_messages.pin.app_pin"),
          I18n.t("screen_messages.pin.change_msg")
        );
        if (props.route.params?.showCart) {
          Navigator.emit({
            event: "modal",
            params: {
              screenName: "CART_SCREEN",
            },
          });
        } else if (props.route.params?.directPay) {
          Navigator.emit({
            event: "modal",
            params: {
              screenName: "CART_SCREEN",
              screenParams: {
                directPay: props.route.params?.directPay,
              },
            },
          });
        }
      },
      (error) => {
        setLoading(false);
        console.log(utils.errorMessage(error));
        alert("Sorry", utils.errorMessage(error));
      }
    );
  };

  const onChangeOtp = ({ nativeEvent: { text } }) => {
    setOtp(text);
  };

  const onPressResend = () => {
    initiateOTPRequest();
  };
  const styles = getStyles(theme);
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  return (
    <DefaultModal
      keyboardAvoidView={false}
      goBack={goBack}
      height={450}
      heading={"Verify PIN OTP"}
    >
      <LoadingContainer bgColor="transaparent" loading={loading}>
        <View style={{ paddingBottom: 15 }}>
          <View
            style={{
              paddingHorizontal: theme.primaryPadding,
              paddingBottom: 10,
              borderBottomColor: "#DADADA",
              borderBottomWidth: 1,
            }}
          >
            <TextInputComponent
              labelText={I18n.t("screen_messages.login.enter_otp")}
              theme={theme}
              value={otp}
              onChange={onChangeOtp}
              placeholder="123456"
              autoCapitalize="words"
              autoCorrect={false}
              keyboardType={"phone-pad"}
            />
          </View>
          <View
            style={{
              paddingHorizontal: theme.primaryPadding,
              paddingBottom: 30,
            }}
          >
            <PrestoText
              fontStyle="medium"
              color={theme.paragraph}
              size={16}
              extraStyle={{
                paddingTop: 20,
              }}
            >
              {I18n.t(
                "screen_messages.login.complete_otp_verification_description"
              )}
            </PrestoText>
          </View>
          <View style={{ paddingHorizontal: theme.primaryPadding }}>
            <PrestoSolidButton
              size="extra-large"
              buttonStyle="primary"
              title="Verify"
            />
          </View>
          <VerticalSpacing size={25} />
          <SectionLinkComponent
            theme={theme}
            leftText1={I18n.t("screen_messages.login.request_otp_link")}
            rightIcon={<svgs.RightIcon2 width="12" height="18" />}
            onPressButton={onPressResend}
            paddingHorizontal={theme.primaryPadding}
          />
        </View>
      </LoadingContainer>
    </DefaultModal>
  );
}

function getStyles(theme) {
  return {
    container: {
      padding: theme.primaryPadding,
      paddingBottom: 5,
    },
    socialHeaderStyle: {
      color: theme.discriptionTextColor,
      fontSize: 16,
    },
  };
}
