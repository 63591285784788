import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M8.698 23.2a2.9 2.9 0 100 5.8 2.9 2.9 0 000-5.8zM0 0v2.9h2.9l5.218 11.02-2.03 3.48a5.152 5.152 0 00-.29 1.45 2.9 2.9 0 002.9 2.9h17.396v-2.9H9.278a.312.312 0 01-.29-.29v-.145l1.305-2.465H21.02a2.644 2.644 0 002.464-1.45l5.22-9.425a.798.798 0 00.29-.725 1.37 1.37 0 00-1.45-1.45H6.088L4.784 0H0zm23.195 23.2a2.9 2.9 0 100 5.8 2.9 2.9 0 000-5.8z"
        transform="translate(5 6)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke={props.color2 || "none"}
        strokeWidth={1}
      />
    </Svg>
  );
}

export default SvgComponent;
