import React from "react";
import _ from "lodash";
import SectionHeaderWithRightLink from "@presto-components/SectionHeaders/SectionHeaderWithRightLink";

export default function SeeAllText(props) {
  const forwardedProps = {
    ...props,
  };
  return <SectionHeaderWithRightLink {...forwardedProps} />;
}
