import React from "react";
import { View, StyleSheet, TextInput, Platform } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import PrestoText from "@presto-components/PrestoText";

export default function TextInputWithButtonComponent(props) {
  const { theme, buttonText } = props;
  const styles = getStyles(theme, {
    textInputStyle: props.textInputStyle,
  });

  return (
    <View style={styles.container}>
      <View style={{ ...styles.labelContainer }}>
        <PrestoText extraStyle={styles.label}>
          {props.labelText || ""}
        </PrestoText>
      </View>

      <View style={styles.textinputContainer}>
        <TextInput
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={
            props.placeholderTextcolor || styles.placeHolder.color
          }
          {...props}
          style={styles.textInputStyle}
        ></TextInput>
        <View style={[styles.buttonContainer, { ...props?.buttonContainer }]}>
          <TouchableWithoutFeedback onPress={props.onPressButton}>
            <View
              style={[styles.primaryContainer, { ...props?.primaryContainer }]}
            >
              <PrestoText color={theme.white}>{buttonText}</PrestoText>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    </View>
  );
}

const getStyleObject = (theme, button) => {
  return _.get(theme, button);
};

function getStyles(theme, buttonStyles) {
  let primaryContainer = {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: theme.secondary,
    height: 36,
  };

  let buttonContainer = {
    position: "absolute",
    right: 6,
    top: 3,
    width: 100,
  };

  if (Platform.OS === "web") {
    primaryContainer["cursor"] = "pointer";
  }

  return {
    container: {
      width: "100%",
    },
    labelContainer: {
      paddingBottom: 5,
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    label: {
      fontSize: 12,
      width: "100%",
      color: theme.textInputLabelColor,
    },
    textInputStyle: {
      width: "100%",
      height: 42,
      borderWidth: 0,
      borderRadius: theme.textInputBorderRadius,
      fontSize: theme.textInputSize,
      paddingHorizontal: 10,
      borderColor: theme.textInputBorderColor,
      backgroundColor: theme.textInputBgColor,
      ...buttonStyles?.textInputStyle,
    },
    placeHolder: {
      color: theme.placeHolderColor,
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: 42,
      borderWidth: 0,
      borderRadius: 4,
      fontFamily: theme.textInputMediumFont,
      backgroundColor: theme.textInputBgColor,
      color: theme.textInputColor,
    },
    primaryContainer: primaryContainer,
    buttonContainer: buttonContainer,
  };
}
