import I18n from "i18n-js";
import utils from "../utils/index";
import _ from "lodash";
import moment from "moment";

export default class TransactionModel {
  constructor({ transaction }) {
    this.transaction = transaction;
  }

  get transactionTypeTitle() {
    return this.transaction.type == "CREDIT" &&
      this.transaction?.other_data?.refund_request_id
      ? I18n.t("screen_messages.refund_order_title")
      : this.transaction.type == "CREDIT"
      ? I18n.t("screen_messages.credit_order_title")
      : this.transaction.type == "DEBIT"
      ? I18n.t("screen_messages.orders.title_singular")
      : "";
  }

  get transactionTypeTitleTelco() {
    return this.transaction.type == "CREDIT" &&
      this.transaction.target?.type === "WalletOrder"
      ? I18n.t("screen_messages.topups.title")
      : this.transaction.type == "CREDIT" &&
        this.transaction.target?.type === "Order"
      ? I18n.t("screen_messages.cashback.wallet_cashback_text")
      : this.transaction.type == "DEBIT"
      ? I18n.t("screen_messages.payments.payment")
      : "";
  }

  get amount() {
    return utils.formattedCurrencyCents(this.transaction.amount);
  }

  get cashbackTitle() {
    return I18n.t("screen_messages.cashback.title", {
      defaultValue: "Cashback",
    });
  }

  get type() {
    return this.transaction.type;
  }

  get cashback() {
    return utils.formattedCurrencyCents(0);
  }

  get formattedDate() {
    return `${moment(this.transaction.created_at).format(
      "MMM D yyy"
    )}, ${moment(this.transaction.created_at).format("hh:mm a")}`;
  }

  get transactionIdText() {
    return this.transaction?.other_data?.refund_request_id &&
      this.transaction?.other_data?.refund_type == "partial_delivery"
      ? I18n.t("screen_messages.refund_title_for_partial_delivery")
      : this.transaction?.other_data?.refund_request_id &&
        this.transaction?.other_data?.refund_type == "return"
      ? I18n.t("screen_messages.refund_title_for_return_item")
      : this.transaction?.target?.type == "WalletComponents::BulkAction" &&
        this.transaction?.comment
      ? this.transaction?.comment
      : `${this.transaction.id}`;
  }

  get formattedTransactionIdText() {
    return I18n.t("screen_messages.transactions.transaction_with_id", {
      transactionId: this.transaction.id,
    });
  }

  get getLineItemId() {
    return this.transaction.other_data?.line_item_id;
  }

  get getOrderId() {
    return this.transaction.other_data?.order_id;
  }

  static fake() {
    return new TransactionModel({
      title: "Title",
      title2: "Title",
      amount: 100,
      cashbackTitle: "",
      type: "",
      cashback: "",
      formattedDate: "",
      description1: "",
      description2: "",
    });
  }
}
