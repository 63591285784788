import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

const SvgComponent = (props) => {
  const { color = "$707070" } = props;
  return (
    <Svg
      width={40}
      height={40}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0h29v29H0z" />
      </Defs>
      <G transform="translate(6 5)" fill="none" fillRule="evenodd">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path d="M0 0h29v29H0z" />
          <Path
            d="M28.819 26.281 22.837 20.3a12.41 12.41 0 0 0 2.538-7.613A12.61 12.61 0 0 0 12.687 0 12.61 12.61 0 0 0 0 12.687a12.61 12.61 0 0 0 12.687 12.688 12.41 12.41 0 0 0 7.613-2.538l5.981 5.982 2.538-2.538ZM3.625 12.687a8.974 8.974 0 0 1 9.062-9.062 8.974 8.974 0 0 1 9.063 9.062 8.974 8.974 0 0 1-9.063 9.063 8.974 8.974 0 0 1-9.062-9.063Z"
            fill={color}
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  );
};

export default SvgComponent;
