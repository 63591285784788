import * as React from "react";
import Svg, { Path } from "react-native-svg";

function RightArrowIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        transform="translate(15 13)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
        d="M1.53886832 0L6.9388939e-17 1.273125 5.82337388 6.090875 6.86034075 7 5.82337388 7.909125 6.9388939e-17 12.726875 1.53886832 14 10 7z"
      />
    </Svg>
  );
}

export default RightArrowIcon;
