import * as React from "react"
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40.0 40.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <ClipPath id="gift1">
          <Path d="M13.496.245c1.213-.39 2.367-.312 3.252.215.643.385 1.07.974 1.198 1.661.34 1.819-.88 3.329-3.186 3.942-1.317.35-5.476 1.098-5.653 1.13a.578.578 0 01-.212 0c-.177-.032-4.336-.78-5.653-1.13C.937 5.45-.284 3.94.056 2.121.186 1.434.611.845 1.255.46c.884-.527 2.038-.604 3.252-.215 2.341.75 3.773 3.122 4.494 4.704.721-1.582 2.153-3.954 4.495-4.704zm1.024 1.373c-.32 0-.662.056-1.012.168-1.892.606-3.057 2.799-3.578 4.036 1.23-.228 3.318-.624 4.116-.836.567-.151 2.399-.766 2.105-2.33-.057-.314-.263-.589-.576-.776-.292-.174-.654-.262-1.055-.262zm-11.038 0c-.4 0-.763.088-1.054.262-.314.187-.519.462-.577.775-.294 1.565 1.538 2.18 2.106 2.331.797.212 2.885.608 4.115.836-.521-1.237-1.685-3.43-3.577-4.036a3.36 3.36 0 00-1.013-.168z" />
        </ClipPath>
        <ClipPath id="gift11">
          <Path d="M11.4 0v15.599H.6a.6.6 0 01-.6-.6V0h11.4z" />
        </ClipPath>
        <ClipPath id="gift111">
          <Path d="M12.6 0v6H0V.6A.6.6 0 01.6 0h12z" />
        </ClipPath>
        <ClipPath id="gift">
          <Path d="M12 0a.6.6 0 01.6.6V6H0V0z" />
        </ClipPath>
        <ClipPath id="gift1111">
          <Path d="M11.4 0v14.999a.6.6 0 01-.6.6H0V0h11.4z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#gift1)" transform="translate(5 5) translate(5.999)">
        <Path
          fill={props.color || "#707070"}
          d="M1.38777878e-17 0L18.0025081 0 18.0025081 7.20167491 1.38777878e-17 7.20167491 1.38777878e-17 0z"
        />
      </G>
      <G clipPath="url(#gift11)" transform="translate(5 5) translate(1.2 14.401)">
        <Path fill={props.color || "#707070"} d="M0 0L11.4 0 11.4 15.598854 0 15.598854 0 0z" />
      </G>
      <G clipPath="url(#gift111)" transform="translate(5 5) translate(0 7.202)">
        <Path
          fill={props.color || "#707070"}
          d="M0 0L12.6 0 12.6 5.99955923 0 5.99955923 0 0z"
        />
      </G>
      <G clipPath="url(#gift)" transform="translate(5 5) translate(17.4 7.202)">
        <Path
          fill={props.color || "#707070"}
          d="M0 0L12.6 0 12.6 5.99955923 0 5.99955923 0 0z"
        />
      </G>
      <G clipPath="url(#gift1111)" transform="translate(5 5) translate(17.4 14.401)">
        <Path fill={props.color || "#707070"} d="M0 0L11.4 0 11.4 15.598854 0 15.598854 0 0z" />
      </G>
    </Svg>
  )
}

export default SvgComponent
