import OperatorsResource from "./OperatorsResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import $ from "../../global/util";

const OperatorsManager = function (OperatorsResource, Utils, ErrorConstants) {
  function getNearByOperators(currentLocation, successCallback, errorCallback) {
    OperatorsResource.getNearByOperators(currentLocation).then(
      function (getNearByOperatorsResponse) {
        successCallback(getNearByOperatorsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(errorCallback);
      }
    );
  }
  function operatorLeads(params, successCallback, errorCallback) {
    OperatorsResource.operatorLeads(params).then(
      function (getNearByOperatorsResponse) {
        successCallback(getNearByOperatorsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAvailableOperators(params, successCallback, errorCallback) {
    OperatorsResource.getAvailableOperators(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createBooking(params, successCallback, errorCallback) {
    OperatorsResource.createBooking(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function destroyBooking(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.booking_id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.BOOKING_ID_EMPTY,
        false
      );
      errorObject = Utils.updateErrorObject(errorObject);
      errorCallback(errorObject);
    } else {
      OperatorsResource.destroyBooking(params).then(
        function (response) {
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getBookingInfoForServices(params, successCallback, errorCallback) {
    OperatorsResource.getBookingInfoForServices(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getBookings(params, successCallback, errorCallback) {
    OperatorsResource.getAllBookings(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function loginOperatorWithToken(params, successCallback, errorCallback) {
    OperatorsResource.loginOperatorWithToken(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchOperatorAppointments(params, successCallback, errorCallback) {
    OperatorsResource.fetchOperatorAppointments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchOperatorAppointmentDetails(
    params,
    successCallback,
    errorCallback
  ) {
    OperatorsResource.fetchOperatorAppointmentDetails(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function startAppointment(params, successCallback, errorCallback) {
    OperatorsResource.startAppointment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function completeAppointment(params, successCallback, errorCallback) {
    OperatorsResource.completeAppointment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getNearByOperators: getNearByOperators,
    getBookings: getBookings,
    operatorLeads: operatorLeads,
    getAvailableOperators: getAvailableOperators,
    createBooking: createBooking,
    destroyBooking: destroyBooking,
    getBookingInfoForServices: getBookingInfoForServices,
    loginOperatorWithToken: loginOperatorWithToken,
    fetchOperatorAppointments: fetchOperatorAppointments,
    fetchOperatorAppointmentDetails: fetchOperatorAppointmentDetails,
    startAppointment: startAppointment,
    completeAppointment: completeAppointment,
  };
};

export default OperatorsManager(OperatorsResource, Utils, ErrorConstants);
