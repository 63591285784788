import SessionManager from "@presto-services/features/session/SessionManager";
import config from "@presto-common/config";

function isLoggedInUser(successCallback, errorCallback) {
  if (config.user_type == "operator") {
    return SessionManager.isOperatorLoggedIn(successCallback, errorCallback);
  } else if (config.user_type == "employee") {
    return SessionManager.getEmployee(successCallback, errorCallback);
  } else if (config.user_type == "admin") {
    return SessionManager.isMerchantLoggedIn(successCallback, errorCallback);
  } else {
    return SessionManager.isLoggedInUser(successCallback, errorCallback);
  }
}

function login(loginObj, successCallback, errorCallback) {
  if (config.user_type == "operator") {
    SessionManager.operatorLogin(loginObj, successCallback, errorCallback);
  } else if (config.user_type == "employee") {
    SessionManager.agentLogin(loginObj, successCallback, errorCallback);
  } else if (config.user_type == "admin") {
    SessionManager.adminLogin(loginObj, successCallback, errorCallback);
  } else {
    SessionManager.login(loginObj, successCallback, errorCallback);
  }
}

function logout(successCallback, errorCallback) {
  if (config.user_type == "operator") {
    SessionManager.operatorLogout(successCallback, errorCallback);
  } else if (config.user_type == "employee") {
    SessionManager.agentLogout(successCallback, errorCallback);
  } else if (config.user_type == "admin") {
    SessionManager.adminLogout(successCallback, errorCallback);
  } else {
    SessionManager.logout(successCallback, errorCallback);
  }
}

export default { isLoggedInUser, login, logout };
