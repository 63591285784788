import React, { createContext, useEffect, useState, useContext } from "react";
import _ from "lodash";
import config from "@presto-common/config";
import ServiceCartManager from "@presto-services/features/service_cart/ServiceCartManager";
import asyncify from "../common/Asyncify";
import UserContext from "./UserContext";
import { AsyncAlert } from "../common/Alert";
import I18n from "i18n-js";
import SearchManager from "@presto-services/features/search/SearchManager";

const AsyncServiceCartManager = asyncify(ServiceCartManager);

const ServiceCartContext = createContext();

export function ServiceCartContextProvider(props) {
  const [serviceCart, setServiceCart] = useState(null);
  const { user } = useContext(UserContext);

  const onSetServiceCart = (data) => {
    const updatedCart = applyCheckoutDetail(data);
    setServiceCart(updatedCart);
  };

  const applyCheckoutDetail = (data) => {
    let checkoutDetail = _.get(data, "checkout_detail");

    if (_.isEmpty(checkoutDetail)) {
      checkoutDetail = _.get(serviceCart, "checkout_detail");
    }
    return { ...data, checkout_detail: checkoutDetail };
  };

  const addServiceItemToCart = async (
    service_id,
    variation_id,
    quantity,
    merchant_id,
    notes,
    outlet_id
  ) => {
    const [error, response] = await AsyncServiceCartManager.addServiceItem({
      service_id,
      variation_id,
      quantity,
      merchant_id,
      notes,
      outlet_id,
    });
    if (!error) {
      onSetServiceCart(response.data);
    }
    return [error, response];
  };

  const applyCouponToCart = async (coupon_code) => {
    const [error, response] = await AsyncServiceCartManager.applyServiceCoupon({
      coupon_code,
    });
    if (!error) {
      onSetServiceCart(response.data);
    }
    return [error, response];
  };

  const removeCouponFromCart = async (coupon_code) => {
    const [error, response] = await AsyncServiceCartManager.removeServiceCoupon(
      {
        coupon_code,
      }
    );
    if (!error) {
      onSetServiceCart(response.data);
    }
    return [error, response];
  };

  const checkoutServiceCart = async (params) => {
    const [error, response] = await AsyncServiceCartManager.checkoutServiceCart(
      params
    );
    if (!error) {
      onSetServiceCart(response.data);
    }
    return [error, response];
  };

  const placeAppointmentOrder = async (params) => {
    const [
      error,
      response,
    ] = await AsyncServiceCartManager.placeAppointmentOrder(params || {});
    if (!error) {
      //setServiceCart(null);
    }
    return [error, response];
  };

  const updateServiceItemFromCart = async (service_item_id, quantity) => {
    const [error, response] = await AsyncServiceCartManager.updateServiceItem({
      service_item_id,
      quantity,
    });
    console.log("updateServiceItemFromCart");
    console.log(response);
    console.log(error);
    if (!error) {
      onSetServiceCart(response.data);
    }
    return [error, response];
  };

  const removeServiceItemFromCart = async (service_item_id) => {
    const [error, response] = await AsyncServiceCartManager.removeServiceItem({
      service_item_id,
    });
    if (!error) {
      onSetServiceCart(response.data);
    }
    return [error, response];
  };

  const clearServiceCart = async () => {
    const [error, response] = await AsyncServiceCartManager.clearServiceCart(
      {}
    );
    if (!error) {
      setServiceCart(response.data);
    }
    return [error, response];
  };

  const fetchServiceCart = async () => {
    const [error, response] = await AsyncServiceCartManager.fetchServiceCart(
      {}
    );
    return [error, response?.data];
  };

  const createServiceCart = async (mid, oid) => {
    const [error, response] = await AsyncServiceCartManager.createServiceCart({
      merchant_id: mid,
      outlet_id: oid,
    });
    return [error, response?.data];
  };

  const refreshServiceCart = async () => {
    if (user) {
      const [error, cart] = await fetchServiceCart();
      if (error) {
        setServiceCart(null);
      } else {
        onSetServiceCart(cart);
      }
    } else {
      setServiceCart(null);
    }
  };

  const getServiceItemCount = () => {
    if (!serviceCart) return 0;
    // console.log("servicecart : ", JSON.stringify(serviceCart, null, 2));
    return serviceCart?.services?.length ?? 0;
  };

  const findServiceItemInCart = (cart, itemId, variationId) => {
    if (variationId) {
      return _.first(
        _.filter(
          cart.services,
          (li) => li.service_id === itemId && li.variation?.id == variationId
        )
      );
    } else {
      return _.first(
        _.filter(cart.services, (li) => {
          return li.service_id === itemId;
        })
      );
    }
  };

  const getCountOfServiceItemInCart = (itemId, variationId) => {
    if (!serviceCart) return 0;
    const itemInCart = findServiceItemInCart(serviceCart, itemId, variationId);
    // console.log("itemInCart = > ", JSON.stringify(itemInCart));
    return itemInCart?.quantity ?? 0;
  };

  const reOrder = async (order, merchantId = null, outletId = null) => {
    let cartCopy = serviceCart;
    const [error, cartResponse] = await fetchServiceCart();
    if (!cartResponse) {
      const [error, cartResponse] = await createServiceCart(
        merchantId,
        outletId
      );
      if (error) {
        return [error, false];
      } else {
        cartCopy = cartResponse;
      }
    } else {
      const [error, _] = await clearServiceCart();
      if (error) {
        return [error, false];
      }
      cartCopy = cartResponse;
    }
    let errorInReorder = false;
    console.log("Order : ", JSON.stringify(order, null, 2));
    for (const lineItem of order.service_items) {
      console.log("Line Item in reorder : ", JSON.stringify(lineItem, null, 2));
      const [err, response] = await updateServiceItemInCart(
        lineItem.service_id,
        lineItem.variation?.id,
        lineItem.quantity,
        merchantId,
        lineItem?.notes,
        outletId
      );
      if (err) {
        errorInReorder = true;
      }
    }
    return [errorInReorder, !errorInReorder];
  };

  const clearAndUpdateCart = async (
    itemId,
    variationId,
    count,
    merchantId = null,
    notes = null,
    outletId = null
  ) => {
    let cartCopy = serviceCart;
    const [error, cartResponse] = await createServiceCart(merchantId, outletId);
    if (error) {
      return [error, false];
    } else {
      cartCopy = cartResponse;
    }

    const itemInCart = findServiceItemInCart(cartCopy, itemId, variationId);
    if (itemInCart) {
      if (count == 0) {
        const [error, response] = await removeServiceItemFromCart(
          itemInCart.id
        );
        console.log("updateServiceItemFromCart response : ", error, response);
        if (error) {
          return [error, false];
        } else {
          return [null, true];
        }
      } else {
        const [error, response] = await updateServiceItemFromCart(
          itemInCart.id,
          count
        );
        console.log("updateServiceItemFromCart response : ", error, response);
        if (error) {
          return [error, false];
        } else {
          return [null, true];
        }
      }
    } else {
      const [error, response] = await addServiceItemToCart(
        itemId,
        variationId,
        count,
        merchantId
      );
      console.log(
        "addServiceItemToCart response : ",
        error,
        JSON.stringify(response)
      );
      if (error) {
        return [error, false];
      } else {
        return [null, true];
      }
    }
  };

  const updateServiceItemInCart = async (
    itemId,
    variationId,
    count,
    merchantId = null,
    notes,
    outletId = null
  ) => {
    console.log(
      "Update Item in serviceCart : ",
      itemId,
      variationId,
      count,
      merchantId,
      null,
      outletId
    );
    let cartCopy = serviceCart;
    const [error, cartResponse] = await fetchServiceCart();
    let isDifferentVendor = config?.features?.temple_association_app
      ? cartResponse && cartResponse.outlet_id != outletId
      : cartResponse && cartResponse.merchant_id != merchantId
      ? true
      : false;

    if (config.features.app_user == "cc_agent" && !cartResponse) {
      const [error, cartResponse] = await createServiceCart(
        merchantId,
        outletId
      );
      if (error) {
        return [error, false];
      } else {
        cartCopy = cartResponse;
      }
    }
    if (merchantId && isDifferentVendor && getServiceItemCount() > 0) {
      const userResp = await AsyncAlert(
        I18n.t("screen_messages.cart.empty_and_add_services"),
        I18n.t("screen_messages.cart.service_cart_clear_message"),
        I18n.t("screen_messages.common.confirm_text"),
        I18n.t("screen_messages.common.cancel_text"),
        true
      );
      console.log("userResps", userResp);
      if (userResp === "yes") {
        return clearAndUpdateCart(
          itemId,
          variationId,
          count,
          merchantId,
          notes,
          outletId
        );
      } else {
        return [null, true];
      }
    } else if (isDifferentVendor && getServiceItemCount() == 0) {
      return clearAndUpdateCart(
        itemId,
        variationId,
        count,
        merchantId,
        notes,
        outletId
      );
    } else {
      console.log("Is different Vender3");
      if (
        !cartResponse ||
        (cartResponse && cartResponse.merchant_id != merchantId)
      ) {
        const [error, cartResponse] = await createServiceCart(
          merchantId,
          outletId
        );
        if (error) {
          return [error, false];
        } else {
          cartCopy = cartResponse;
        }
      } else if (
        !cartResponse ||
        (cartResponse && cartResponse.outlet_id != outletId)
      ) {
        const [error, cartResponse] = await createServiceCart(
          merchantId,
          outletId
        );
        if (error) {
          return [error, false];
        } else {
          cartCopy = cartResponse;
        }
      } else {
        cartCopy = cartResponse;
      }
      const itemInCart = findServiceItemInCart(cartCopy, itemId, variationId);
      if (itemInCart) {
        if (count == 0) {
          const [error, response] = await removeServiceItemFromCart(
            itemInCart.id
          );
          console.log("updateServiceItemFromCart response : ", error, response);
          if (error) {
            return [error, false];
          } else {
            return [null, true];
          }
        } else {
          const [error, response] = await updateServiceItemFromCart(
            itemInCart.id,
            count
          );
          console.log("updateServiceItemFromCart response : ", error, response);
          if (error) {
            return [error, false];
          } else {
            return [null, true];
          }
        }
      } else {
        console.log("Is different Vender4");
        const [error, response] = await addServiceItemToCart(
          itemId,
          variationId,
          count,
          null,
          notes,
          outletId
        );
        console.log(
          "addServiceItemToCart response : ",
          error,
          JSON.stringify(response)
        );
        if (error) {
          return [error, false];
        } else {
          return [null, true];
        }
      }
    }
  };

  const getServiceTotalPrice = () => {
    if (!serviceCart) return 0;
    // console.log("serviceCart : ", serviceCart);
    return serviceCart.total_price;
  };

  const getServiceSubTotalPrice = () => {
    if (!serviceCart) return 0;
    return serviceCart.original_gross_amount || serviceCart.sub_total_price;
  };

  const getServiceTotalTax = () => {
    if (!serviceCart) return 0;
    // console.log("serviceCart : ", serviceCart);
    return serviceCart.total_taxes;
  };

  const getTotalServiceDiscountPrice = () => {
    if (!serviceCart) return 0;
    // console.log("Cart : ", cart);
    return serviceCart.total_discounts;
  };

  const getIngredients = async (ids = null, catalogId) => {
    let ingredientsHash = await getIngredientHash().then((response) => {
      return response.data;
    });

    // getting the reference ids from service cart
    let serviceReferenceIds =
      ids ||
      _.map(serviceCart?.services, (service) => {
        return service.reference_id;
      });

    // filtering reference ids from productsHash
    let productsHash = [];
    _.forEach(serviceReferenceIds, (id) => {
      if (ingredientsHash[id]) {
        productsHash.push(...ingredientsHash[id]);
      }
    });

    let refIds = _.map(productsHash, (product) => {
      return product.item_id;
    });

    let params = {
      ref_ids: refIds,
      size: refIds.length + 20,
    };
    if (catalogId) {
      params.catalog_id = catalogId;
    }
    return new Promise((resolve, reject) => {
      if (refIds.length === 0) {
        resolve([]);
      } else {
        SearchManager.searchCategoryItem(
          params,
          (response) => {
            const items = _.map(response.hits.hits, (item) => {
              let quantity = 0;
              _.forEach(productsHash, (i) => {
                if (i.item_id === item._source.reference_id) {
                  quantity += i.quantity;
                }
              });
              return { ...item._source, quantity: quantity };
            });

            resolve(items);
          },
          (error) => {
            console.log("error", error);
            reject(error);
          }
        );
      }
    });
  };

  const getEquipments = async (ids = null) => {
    let equipmentHash = await getEquipmentHash().then((response) => {
      return response.data;
    });

    // getting the reference ids from service cart
    let serviceReferenceIds =
      ids ||
      _.map(serviceCart?.services, (service) => {
        return service.reference_id;
      });

    // filtering reference ids from productsHash
    let productsHash = [];
    _.forEach(serviceReferenceIds, (id) => {
      if (equipmentHash[id]) {
        productsHash.push(...equipmentHash[id]);
      }
    });

    let refIds = _.map(productsHash, (product) => {
      return product.item_id;
    });

    let params = {
      ref_ids: refIds,
    };

    return new Promise((resolve, reject) => {
      if (refIds.length === 0) {
        resolve([]);
      } else {
        SearchManager.searchCategoryItem(
          params,
          (response) => {
            const items = _.map(response.hits.hits, (item) => {
              let quantity = 0;
              _.forEach(productsHash, (i) => {
                if (i.item_id === item._source.reference_id) {
                  quantity += i.quantity;
                }
              });
              return { ...item._source, quantity: quantity };
            });

            resolve(items);
          },
          (error) => {
            console.log("error", error);
            reject(error);
          }
        );
      }
    });
  };

  const getCombos = async (ids = null) => {
    let comboHash = await getComboItemHash().then((response) => {
      return response.data;
    });

    // getting the reference ids from service cart
    let serviceReferenceIds =
      ids ||
      _.map(serviceCart?.services, (service) => {
        return service.reference_id;
      });

    // filtering reference ids from productsHash
    let productsHash = [];
    _.forEach(serviceReferenceIds, (id) => {
      if (comboHash[id]) {
        productsHash.push(...comboHash[id]);
      }
    });

    let refIds = _.map(productsHash, (product) => {
      return product.items;
    });

    let params = {
      ref_ids: refIds,
    };

    return new Promise((resolve, reject) => {
      if (refIds.length === 0) {
        resolve([]);
      } else {
        SearchManager.searchCategoryItem(
          params,
          (response) => {
            const items = _.map(response.hits.hits, (item) => {
              let quantity = 0;
              _.forEach(productsHash, (i) => {
                if (i.item_id === item._source.reference_id) {
                  quantity += i.quantity;
                }
              });
              return { ...item._source, quantity: quantity };
            });

            resolve(items);
          },
          (error) => {
            console.log("error in combo", error);
            reject(error);
          }
        );
      }
    });
  };

  useEffect(() => {
    if (user) {
      refreshServiceCart();
    }
  }, [user]);

  const getIngredientHash = async () => {
    const [
      error,
      response,
    ] = await AsyncServiceCartManager.fetchServiceCartIngredients({
      appName: config?.service_app_name,
    });
    if (response.data) {
      return response;
    } else {
      return [];
    }
  };
  const getEquipmentHash = async () => {
    const [
      error,
      response,
    ] = await AsyncServiceCartManager.fetchServiceCartEquipments({
      appName: config?.service_app_name,
    });
    if (response.data) {
      return response;
    } else {
      return [];
    }
  };

  const getComboItemHash = async () => {
    const [
      error,
      response,
    ] = await AsyncServiceCartManager.fetchServiceCartComboList({
      appName: config?.combo_app_name || config.app,
    });
    if (response.data) {
      return response;
    } else {
      return [];
    }
  };

  return (
    <ServiceCartContext.Provider
      value={{
        serviceCart,
        clearServiceCart,
        updateServiceItemInCart,
        placeAppointmentOrder,
        checkoutServiceCart,
        removeServiceItemFromCart,
        applyCouponToCart,
        removeCouponFromCart,
        getServiceItemCount,
        getCountOfServiceItemInCart,
        reOrder,
        refreshServiceCart,
        getServiceTotalPrice,
        getServiceTotalTax,
        getServiceSubTotalPrice,
        getTotalServiceDiscountPrice,
        getIngredientHash,
        getIngredients,
        getEquipments,
        getComboItemHash,
        getCombos,
        updateServiceItemFromCart,
      }}
    >
      {props.children}
    </ServiceCartContext.Provider>
  );
}

export default ServiceCartContext;
