import Utils from "../common/Utils";
import PriceObject from "./PriceObject";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const VariationObject = function (Utils, PriceObject, ErrorConstants) {
  function VariationObject() {
    this.description = "";
    this.id = "";
    this.inner_variations = [];
    this.is_available = true;
    this.listing_order = 0;
    this.name = "";
    this.other_data = {};
    this.outer_variation_id = null;
    this.pic_url = "";
    this.pictures = [];
    this.price = {};
    this.reference_id = "";
    this.seo_description = "";
    this.seo_handle = "";
    this.seo_keywords = "";
    this.seo_title = "";
    this.sold_out = false;
    this.stock_count = 0;
    this.type = "";
    this.value = "";
    this.title = "";
  }
  VariationObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawVariationObject) {
      this.description = rawVariationObject.description;
      this.id = rawVariationObject.id;
      this.inner_variations = [];
      var rawInnerVariations = rawVariationObject.inner_variations;
      if (rawInnerVariations) {
        var parsedVariations = [];
        rawInnerVariations.forEach(function (
          rawVariation,
          index,
          rawInnerVariations
        ) {
          var vairation = new VariationObject();
          vairation.buildObject(rawVariation);
          parsedVariations.push(vairation);
        });
        this.inner_variations = parsedVariations;
      }
      var rawVariations = rawVariationObject.variations;
      if (rawVariations.length != 0) {
        var parsedVariations = [];
        rawVariations.forEach(function (rawVariation, index, rawVariations) {
          var vairation = new VariationObject();
          vairation.buildObject(rawVariation);
          parsedVariations.push(vairation);
        });
        this.variations = parsedVariations;
      }
      this.is_available = rawVariationObject.is_available;
      this.listing_order = rawVariationObject.listing_order;
      this.name = rawVariationObject.name;
      this.other_data = rawVariationObject.other_data;
      if (!rawVariationObject.outer_variation_id) {
        rawVariationObject.outer_variation_id = null;
      }
      this.outer_variation_id = rawVariationObject.outer_variation_id;
      this.pic_url = rawVariationObject.pic_url;
      this.pictures = rawVariationObject.pictures;
      this.price = new PriceObject();
      this.price.buildObject(rawVariationObject.price);
      this.reference_id = rawVariationObject.reference_id;
      this.seo_description = rawVariationObject.seo_description;
      this.seo_handle = rawVariationObject.seo_handle;
      this.seo_keywords = rawVariationObject.seo_keywords;
      this.seo_title = rawVariationObject.seo_title;
      this.sold_out = rawVariationObject.sold_out;
      this.stock_count = rawVariationObject.stock_count;
      this.type = rawVariationObject.type;
      this.value = rawVariationObject.value;
      this.title = rawVariationObject.value;
    },
  };

  return VariationObject;
};

export default VariationObject(Utils, PriceObject, ErrorConstants);
