import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  const fill = props.fill || "#000"
  return (
    <Svg
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={fill} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path d="M180.702339 361.414861L271.053509 361.414861 271.053509 512.000144 180.702339 512.000144z" />
        <Path d="M406.582 1.275c-48.415 8.11-82.42 54.188-74.03 104.143 7.157 42.731 44.318 75.284 89.087 75.284 49.899 0 90.34-40.443 90.34-90.34 0-55.777-50.11-98.37-105.397-89.087zM421.278 71.5c-9.261 0-16.8 7.461-16.8 16.622 0 9.167 7.539 16.628 16.8 16.628 9.261 0 16.8-7.461 16.8-16.628 0-9.16-7.539-16.622-16.8-16.622m-2.4 42.75c-13.256 0-24-10.63-24-23.752 0-13.118 10.744-23.748 24-23.748s24 10.63 24 23.748c0 13.122-10.744 23.752-24 23.752m0 14.25c24.286 0 48.093-21.651 52.8-40.377-4.707-18.722-28.514-40.373-52.8-40.373-24.288 0-48.093 21.65-52.8 40.373 4.707 18.727 28.511 40.377 52.8 40.377zm-57.6-38.002c3.333-16.963 26.859-47.498 60-47.498 33.138 0 56.667 30.535 60 47.498-3.333 16.967-26.862 47.502-60 47.502-33.141 0-56.667-30.535-60-47.502z" />
        <Path d="M60.2341131 289.480272L60.2341131 512.000144 150.585283 512.000144 150.585283 331.297805 301.170565 331.297805 301.170565 512.000144 391.521735 512.000144 391.521735 289.480272 225.877924 242.152321z" />
        <Path d="M105.409698 90.3613526L45.1755848 90.3613526 45.1755848 140.182995 105.409698 122.973105z" />
        <Path d="M315.894 148.07l-90.016-28.194L0 184.413v90.955l225.878-64.537 225.878 64.537v-68.35c-52.293 13.51-108.848-9.507-135.862-58.948z" />
      </G>
    </Svg>
  )
}

export default SvgComponent
