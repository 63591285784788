import * as React from "react";
import Svg, { Path, G, Rect, Polyline } from "react-native-svg";

function SvgComponent({
  width = 40,
  height = 40,
  fillColor = "#FAA85D",
  color = "#FFFFFF",
  style
}) {
  return (
    <Svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="tick-icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="tick-icon/white/tick-with-amber-circle">
          <Rect fill="transparent" x="0" y="0" width="36" height="36"></Rect>
          <Path
            d="M18,0 C8.0586,0 0,8.0586 0,18 C0,27.9414 8.0586,36 18,36 C27.9414,36 36,27.9414 36,18 C36,8.0586 27.9414,0 18,0 Z"
            id="tick-Path"
            fill={fillColor}
            fillRule="nonzero"
          ></Path>
          <Polyline
            id="tick-Path-1"
            stroke={color}
            strokeWidth="3.6"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="7 17.5 14 24 28 11"
          ></Polyline>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
