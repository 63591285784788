import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={35} height={35} viewBox="0 0 35 35" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#FFCF31"}>
        <Path
          opacity={0.4}
          d="M34.487 9.872l-16.389 16.48-4.973-4.984L29.539 4.893a1.743 1.743 0 012.465-.01l.01.01 2.473 2.49a1.768 1.768 0 010 2.49z"
        />
        <Path d="M18.093 26.359l-3.73 3.75a1.743 1.743 0 01-2.466.01l-.01-.01L.514 18.67a1.766 1.766 0 010-2.489l2.474-2.489a1.743 1.743 0 012.461-.011l.011.011L18.093 26.36z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h35v35H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
