import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function TimerIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(5 5)"
        stroke={props.color || "#707070"}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <Circle cx={15} cy={15} r={14} />
        <Path d="M15 7.14515997L15 17.9627175 22.00052 21.0300158" />
      </G>
    </Svg>
  );
}

export default TimerIcon;
