import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke={props.color2 || "none"}
        strokeWidth={1}
      >
        <Path
          d="M13.97 11.89L2.901.566A1.64 1.64 0 001.261.05 1.683 1.683 0 00.049 1.294a1.727 1.727 0 00.507 1.678l11.069 11.324c.414.459 1.04.648 1.631.494a1.682 1.682 0 001.201-1.232 1.725 1.725 0 00-.488-1.668z"
          transform="translate(8 5) translate(10.24 .164)"
        />
        <Path
          d="M4.024 0l-.083.426A18.455 18.455 0 010 8.592l6.03 6.17a17.663 17.663 0 017.946-4.072l.419-.086L4.025 0z"
          transform="translate(8 5) translate(5.95 4.692)"
        />
        <Path
          d="M4.846 0L.73 4.21A2.581 2.581 0 000 6.014C0 6.691.263 7.34.73 7.82l2.353 2.406a2.466 2.466 0 001.764.748c.661 0 1.296-.27 1.763-.748l4.115-4.21L4.845 0zm.587 6.616a.818.818 0 01-1.16-.017.865.865 0 01-.016-1.186L5.433 4.21a.818.818 0 011.16.017.865.865 0 01.016 1.186L5.433 6.616z"
          transform="translate(8 5) translate(0 14.573)"
        />
        <Path
          d="M5.236 5.625l1.131-1.158a2.58 2.58 0 00.731-1.803A2.58 2.58 0 006.368.86L5.526 0c-.464.32-.911.667-1.34 1.036l1.004 1.027a.86.86 0 010 1.201L4.042 4.44l-1.69-1.675L0 5.172l3.772 3.74a1.637 1.637 0 002.32-.036 1.73 1.73 0 00.03-2.373l-.886-.878z"
          transform="translate(8 5) translate(8.96 19.625)"
        />
        <Path
          d="M.887 0a.82.82 0 00-.589.249.86.86 0 00-.243.602v1.702c0 .47.372.851.832.851.46 0 .832-.38.832-.85V.85A.842.842 0 00.887 0z"
          transform="translate(8 5) translate(16.669)"
        />
        <Path
          d="M2.494.056H.832A.842.842 0 000 .907c0 .47.372.851.832.851h1.663a.843.843 0 00.794-.85c0-.455-.35-.829-.794-.85L2.494.056z"
          transform="translate(8 5) translate(21.711 6.748)"
        />
        <Path
          d="M3.065.249a.819.819 0 00-1.176 0L.227 1.951a.865.865 0 00.017 1.186.818.818 0 001.16.017l1.663-1.702A.865.865 0 003.065.25z"
          transform="translate(8 5) translate(20.063 1.7)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
