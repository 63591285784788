import * as React from "react"
import Svg, { G, Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Rect
          stroke="#707070"
          strokeWidth={1.5}
          fill="#707070"
          x={8.75}
          y={5.75}
          width={22.5}
          height={28.5}
          rx={4}
        />
        <Path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.1209259 15L26.77771 15"
        />
        <Path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.1209259 20L26.77771 20"
        />
        <Path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.1209259 25L26.77771 25"
        />
        <Path
          stroke="#FFF"
          strokeWidth={0.8}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28.3726425 5.61957804L28.3726425 8.46825218 31.3652725 8.46825218 31.3652725 8.46825218z"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
