import React, { useState, useContext } from "react";
import { View, Platform, Image } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import { Appbar } from "react-native-paper";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import CartContext from "@presto-contexts/CartContext";
import PrestoText from "@presto-components/PrestoText";
import Logo from "@presto-components/Headers/TopDrawerHeaderWithLogo/Logo";
import I18n from "i18n-js";

function TopHeaderCenterTitleIcon(props) {
  const { images: Images, svgs } = useAssets();
  const {
    rightIcon2,
    rightIcon1,
    onPressRightIcon1,
    onPressRightIcon2,
    onHamburgerClick,
    theme,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const insets = useSafeArea();
  const { getItemCount } = useContext(CartContext);

  return (
    <Appbar
      style={{
        height: 50 + insets.top,
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        backgroundColor: theme.white,
        shadowOpacity: 0,
      }}
    >
      <View
        style={{
          height: 40,
          width: "100%",
          flexDirection: "row",
        }}
      >
        <View style={{}}>
          <Pressable onPress={() => onHamburgerClick()}>
            <View
              style={{
                width: 40,
                height: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PrestoIcon icon={<svgs.Hamburger width={35} height={35} />} />
            </View>
          </Pressable>
        </View>
        <View style={{ flexDirection: "column", marginTop: -4 }}>
          <Logo />

          <PrestoText
            color={theme.primary}
            size={12}
            fontStyle="500.medium"
            extraStyle={{
              paddingBottom: 5,
              marginLeft: 24,
              marginTop: -2,
            }}
          >
            {I18n.t("screen_messages.footer_text")}
          </PrestoText>
        </View>
        <View
          style={{
            position: "absolute",
            right: 0,
            flexDirection: "row",
            alignItems: "center",
            height: 44,
          }}
        >
          {rightIcon1 ? (
            <Pressable onPress={() => onPressRightIcon1 && onPressRightIcon1()}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  flex: 1,
                  alignItems: "center",
                  marginRight: 3,
                }}
              >
                <PrestoIcon icon={rightIcon1} />
              </View>
            </Pressable>
          ) : null}
          {rightIcon2 ? (
            <Pressable onPress={() => onPressRightIcon2 && onPressRightIcon2()}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <PrestoIcon icon={rightIcon2} />
                {getItemCount() > 0 ? (
                  <View
                    style={{
                      position: "absolute",
                      top: 1,
                      backgroundColor: theme.primary,
                      borderRadius: 50,
                      padding: 1,
                      width: 20,
                      height: 20,
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                      left: 14,
                    }}
                  >
                    <PrestoText
                      fontStyle={"semibold"}
                      color={theme.white}
                      size={10}
                    >
                      {getItemCount()}
                    </PrestoText>
                  </View>
                ) : null}
              </View>
            </Pressable>
          ) : null}
        </View>
      </View>
    </Appbar>
  );
}

export default TopHeaderCenterTitleIcon;
