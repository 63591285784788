import PriceObject from "./PriceObject";
import Utils from "../common/Utils";
import VariationObject from "./VariationObject";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const CategoryItemObject = function (
  PriceObject,
  Utils,
  VariationObject,
  ErrorConstants
) {
  function CategoryItemObject() {
    this.available_from = "";
    this.category_id = "";
    this.chef_name = null;
    this.chef_pic = null;
    this.description = "";
    this.dislikes = 0;
    this.id = "";
    this.is_available = true;
    this.keywords = "";
    this.likes = 0;
    this.name = "";
    this.other_data = {};
    this.pic_url = "";
    this.pre_orderable = false;
    this.price = {};
    this.reference_id = "";
    this.seo_description = "";
    this.seo_handle = "";
    this.seo_keywords = "";
    this.seo_title = "";
    this.sold_out = false;
    this.stock_count = 0;
    this.summary = null;
    this.variations = [];
    this.pictures = [];
    this.outlet_id = "";
    this.listing_order;
    this.merchant_id = null;
    this.ratings_aggregate = null;
  }

  CategoryItemObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawCategoryItem) {
      this.available_from = rawCategoryItem.available_from;
      this.category_id = rawCategoryItem.category_id;
      this.chef_name = rawCategoryItem.chef_name;
      this.chef_pic = rawCategoryItem.chef_pic;
      this.description = rawCategoryItem.description;
      this.dislikes = rawCategoryItem.dislikes;
      this.id = rawCategoryItem.id;
      this.is_available = rawCategoryItem.is_available;
      this.keywords = rawCategoryItem.keywords;
      this.likes = rawCategoryItem.likes;
      this.name = rawCategoryItem.name;
      this.other_data = rawCategoryItem.other_data;
      this.pic_url = rawCategoryItem.pic_url;
      this.pre_orderable = rawCategoryItem.pre_orderable;
      this.price = new PriceObject();
      this.price.buildObject(rawCategoryItem.price);
      this.reference_id = rawCategoryItem.reference_id;
      this.seo_description = rawCategoryItem.seo_description;
      this.seo_handle = rawCategoryItem.seo_handle;
      this.seo_keywords = rawCategoryItem.seo_keywords;
      this.seo_title = rawCategoryItem.seo_title;
      this.sold_out = rawCategoryItem.sold_out;
      this.stock_count = rawCategoryItem.stock_count;
      this.summary = rawCategoryItem.summary;
      this.pictures = rawCategoryItem.pictures;
      this.variations = [];
      this.outlet_id = rawCategoryItem.outlet_id;
      this.listing_order = rawCategoryItem.listing_order;
      this.merchant_id = rawCategoryItem.merchant_id;
      this.ratings_aggregate = rawCategoryItem.ratings_aggregate;
      var rawVariations = rawCategoryItem.variations;
      if (rawVariations) {
        var parsedVariations = [];
        rawVariations.forEach(function (rawVariation, index, rawVariations) {
          var variation = new VariationObject();
          variation.buildObject(rawVariation);
          parsedVariations.push(variation);
        });
        this.variations = parsedVariations;
      }
    },
  };
  return CategoryItemObject;
};

export default CategoryItemObject(
  PriceObject,
  Utils,
  VariationObject,
  ErrorConstants
);
