import * as React from "react";
import Svg, { Rect, G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      {...props}
    >
      <G
        transform="translate(4 4)"
        strokeWidth={1.5}
        stroke="none"
        fill="none"
        fillRule="evenodd"
      >
        <Rect
          stroke={props.borderColor || "#000"}
          x={0}
          y={0}
          width={24}
          height={24}
          rx={4}
        />
        <Path
          stroke={props.color || "#000"}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 13L9.33333333 18 20 8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
