import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function Divider(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        height: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: props.padding ? props.padding : 0,
        opacity: props.opacity,
      }}
    >
      <View
        style={{
          width: props.width || "100%",
          height: props.height ? props.height : 1,
          backgroundColor: props.dividerColor || theme.sectionDeviderColor,
          ...props.style,
        }}
      ></View>
    </View>
  );
}
