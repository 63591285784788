import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={21} height={28} viewBox="0 0 21 28" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M10.335 1C4.745 1 .21 5.535.21 11.125c0 4.082 1.424 5.22 9.085 16.33a1.265 1.265 0 002.08 0c7.662-11.11 9.085-12.248 9.085-16.33C20.46 5.535 15.925 1 10.335 1zm0 15.187a5.063 5.063 0 110-10.126 5.063 5.063 0 010 10.126z"
        fill={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M10.335 14.5a3.375 3.375 0 110-6.75 3.375 3.375 0 010 6.75z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
