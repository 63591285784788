import { View, Text } from "react-native";
import React from "react";
import { PrestoDottedButton } from "@presto-components/PrestoButton";

export default function CouponButton({ title, onPress }) {
  return (
    <PrestoDottedButton
      buttonStyle="tertiary"
      size={"normal"}
      borderRadius={1}
      borderWidth={1}
      title={title}
      onPress={onPress}
    />
  );
}
