import React, { useState, useContext, useEffect } from "react";
import { FlatList, ScrollView } from "react-native";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import CouponManager from "@presto-services/features/coupons/CouponManager";
import CouponComponent from "@presto-components/Coupons/Coupon";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import OffersCardModel from "@presto-component-models/OffersCardModel";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Header from "@presto-screen-components/Coupons/Header";
import moment from "moment";

export default function Coupons({ route }) {
  const insets = useSafeAreaInsets();
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { hideHeader = false } = _.get(route, "params", {});

  useEffect(() => {
    if (!user) {
      setLoading(false);
      alert(I18n.t("screen_messages.login.please_login_before_continue"));
      return;
    }
    CouponManager.getMyCoupons(
      (response) => {
        setLoading(false);
        setCoupons(response.data);
      },
      (error) => {
        setLoading(false);
        console.log("Coupons response : ", JSON.stringify(error, null, 2));
      }
    );
  }, []);
  const renderCoupon = ({ item: coupon }) => {
    return (
      <CouponComponent
        coupon={
          new OffersCardModel({
            coupon: coupon,
          })
        }
      />
    );
  };

  const filterCoupons = () => {
    let items = _.cloneDeep(coupons);
    const now = moment();
    items = _.filter(items, (item) => {
      const endTime = moment(item.end_time);
      return endTime >= now;
    });
    return items;
  };

  return (
    <LoadingContainer bgColor={theme.appBackgroundColor} loading={loading}>
      {!hideHeader ? <Header /> : null}

      <ScrollView>
        <FlatList data={filterCoupons()} renderItem={renderCoupon} />
      </ScrollView>
    </LoadingContainer>
  );
}
