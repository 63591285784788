import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={21} viewBox="0 0 18 21" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M0 19.286h7.714V11.57H0v7.715zm2.571-5.143h2.572v2.571H2.57v-2.571zm7.715-12.857V9H18V1.286h-7.714zm5.143 5.143h-2.572V3.856h2.572v2.571zM0 8.998h7.714V1.287H0V9zm2.571-5.142h2.572v2.571H2.57v-2.57z"
        fill={props.primaryColor || "#fff"}
      />
      <Path
        d="M16.714 19.286H18V18h-1.286v1.286zm-2.571 0h1.286V18h-1.286v1.286zm2.571-7.715v1.286h-2.571v-1.286h-3.857v7.715h2.571v-3.858h1.286v1.286H18v-5.143h-1.286z"
        fill={props.primaryColor || "#fff"}
      />
    </Svg>
  );
}

export default SvgComponent;
