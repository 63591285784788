import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function PrivacyPolicyIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(6 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path d="M0 0H29V29H0z" />
          <Path
            d="M14.5 14.5a3.625 3.625 0 11-3.625 3.625A3.636 3.636 0 0114.5 14.5zm0-10.875a3.636 3.636 0 00-3.625 3.625h7.25A3.636 3.636 0 0014.5 3.625zM23.563 29H5.438a3.636 3.636 0 01-3.625-3.625v-14.5A3.636 3.636 0 015.438 7.25H7.25a7.25 7.25 0 0114.5 0h1.813a3.636 3.636 0 013.625 3.625v14.5A3.636 3.636 0 0123.563 29z"
            fill={props.color2 || props.color1}
            stroke={props.color}
            strokeWidth={1.2}
          />
        </G>
      </G>
    </Svg>
  );
}

export default PrivacyPolicyIcon;
