import React, { createContext, useReducer } from "react";
import _ from "lodash";
import PosManager from "@presto-services/features/pos/PosManager";
import PosCartItem from "../helpers/PosCartItem";

const PosCartContext = createContext();
function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_CART":
      return { ...state, cart: payload };
    case "SET_USER":
      return { ...state, user: payload };
  }
}

export function PosCartContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, {
    cart: {
      no_of_items: 0,
      total_price: 0,
      total_tax: 0,
      total_service_tax: 0,
      delivery_charge: {},
      total_packaging_cost: 0,
      total_vat_amount: 0,
      total_base_price: 0,
      cartItems: {},
      minimum_bill_amount: 0,
      total_shipping_cost: 0,
      total_base_price_with_tax: 0,
      user: null,
    },
    user: null,
  });
  const { cart, user } = state;

  const getItems = () => {
    return cart.cartItems;
  };

  const getUser = () => {
    return user;
  };

  const setUser = (user) => {
    dispatch({
      type: "SET_USER",
      payload: user,
    });
  };

  const getCartItemId = (productId, variationId) => {
    if (variationId && null != variationId && "" != variationId) {
      return productId + ":" + variationId;
    } else {
      return productId;
    }
  };

  const getItem = (itemId) => {
    return cart.cartItems[itemId];
  };

  const createNewItem = (itemId, product, variation, variationList, type) => {
    var cartItem = new PosCartItem(itemId, product, variation, variationList);
    cartItem.added_at = Date.now();
    cartItem.type = type;
    return cartItem;
  };

  const addNewItem = (newItemId, newItem) => {
    console.log("adding new cart item. itemId=" + newItemId);
    if (!cart.cartItems[newItemId]) {
      console.log("New item successfully added");
      cart.cartItems[newItemId] = newItem;
      //$rootScope.$broadcast('ON_CART_ITEM_UPDATED');
    }
  };

  const getTaxAmount = () => {
    if (cart.total_tax > 0) {
      return cart.total_tax;
    } else {
      return 0;
    }
  };

  const updateItem = (itemId, addCount, variation, type) => {
    console.log("itemId=" + itemId + ",addCount=" + addCount);
    var cartItem = cart.cartItems[itemId];
    if (cartItem) {
      cart.cartItems[itemId].count += addCount;
      if (cartItem.other_data && cartItem.other_data.default_item_id) {
        //ignoring as its addon
      } else {
        cart.no_of_items += parseInt(addCount);
      }
      console.log("cart = = = ", cart);
      if (cart.cartItems[itemId].type === "SERVICES") {
        if (!cartItem.hasVariations()) {
          console.log(
            "item count successfully incremented/decremented to " + addCount
          );
          cart.total_price +=
            Number(cartItem.product.unit_price / 100) * addCount;
          cart.total_base_price +=
            Number(cartItem.product.unit_price / 100) * addCount;
        } else {
          cart.total_price +=
            Number(cartItem.variation.unit_price / 100) * addCount;
          cart.total_base_price +=
            Number(cartItem.variation.unit_price / 100) * addCount;
        }
      } else {
        if (!cartItem.hasVariations()) {
          cart.total_price +=
            Number(cartItem.product.price.total_price) * addCount;
          cart.total_base_price +=
            Number(cartItem.product.price.base_price) * addCount;
          if (cartItem.product.price.packing_charge) {
            cart.total_packaging_cost +=
              Number(cartItem.product.price.packing_charge) * addCount;
          }
          cart.total_shipping_cost +=
            Number(cartItem.product.price.shipping_charge) * addCount;
          var item_tax_price;
          if (
            cartItem.product.price.total_tax_percentage &&
            cartItem.product.price.total_tax_percentage > 0
          ) {
            item_tax_price =
              (Number(cartItem.product.price.total_price) +
                Number(cartItem.product.price.packing_charge) +
                Number(cartItem.product.price.shipping_charge)) *
              (cartItem.product.price.total_tax_percentage / 100);
          } else {
            item_tax_price =
              (Number(cartItem.product.price.total_price) +
                Number(cartItem.product.price.packing_charge) +
                Number(cartItem.product.price.shipping_charge)) *
              (cartItem.product.price.tax_percentage / 100);
          }
          cart.total_tax += item_tax_price * addCount;
        } else {
          cart.total_price +=
            Number(cartItem.variation.price.total_price) * addCount;
          cart.total_base_price +=
            Number(cartItem.variation.price.base_price) * addCount;
          cart.total_packaging_cost +=
            Number(cartItem.variation.price.packing_charge) * addCount;
          cart.total_shipping_cost +=
            Number(cartItem.variation.price.shipping_charge) * addCount;
          var item_tax_price;
          if (
            cartItem.variation.price.total_tax_percentage &&
            cartItem.variation.price.total_tax_percentage > 0
          ) {
            item_tax_price =
              (Number(cartItem.variation.price.total_price) +
                Number(cartItem.variation.price.packing_charge) +
                Number(cartItem.variation.price.shipping_charge)) *
              (cartItem.variation.price.total_tax_percentage / 100);
          } else {
            item_tax_price =
              (Number(cartItem.variation.price.total_price) +
                Number(cartItem.variation.price.packing_charge) +
                Number(cartItem.variation.price.shipping_charge)) *
              (cartItem.variation.price.tax_percentage / 100);
          }
          cart.total_tax += item_tax_price * addCount;
        }
      }
    } else {
      console.log("item not found in cart");
    }
  };

  const removeItem = (itemId) => {
    console.log("removeItem itemId=" + itemId);
    if (cart.cartItems[itemId]) {
      console.log("Item deleted");
      delete cart.cartItems[itemId];
      //$rootScope.$broadcast('ON_CART_ITEM_UPDATED');
    } else {
      console.log("no item found to delete");
    }
  };

  const clearCart = () => {
    cart.no_of_items = 0;
    cart.total_price = 0;
    cart.total_tax = 0;
    cart.total_service_tax = 0;
    cart.total_packaging_cost = 0;
    cart.total_vat_amount = 0;
    cart.total_base_price = 0;
    cart.total_shipping_cost = 0;
    cart.total_base_price_with_tax = 0;
    for (var item in cart.cartItems) {
      delete cart.cartItems[item];
    }
    cart.cartItems = {};
    dispatch({ type: "SET_CART", payload: cart });
  };

  const getBillAmount = () => {
    return (
      cart.total_price +
      cart.total_tax +
      cart.total_packaging_cost +
      cart.total_shipping_cost
    );
  };

  const getCartItemsCount = () => {
    return cart.no_of_items;
  };

  const getTotalVat = () => {
    return cart.total_vat_amount;
  };

  const getCartPrice = () => {
    return cart.total_price;
  };

  const bookAppointment = (params, response, error) => {
    PosManager.bookAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(err);
      }
    );
  };

  const updateAppointment = (params, response, error) => {
    PosManager.updateAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(err);
      }
    );
  };

  const addService = (params, response, error) => {
    PosManager.addService(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(err);
      }
    );
  };

  const updateService = (params, response, error) => {
    PosManager.updateService(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const removeService = (params, response, error) => {
    PosManager.removeService(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(err);
      }
    );
  };

  const applyCoupon = (params, response, error) => {
    PosManager.applyCoupon(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(err);
      }
    );
  };

  const removeCoupon = (params, response, error) => {
    PosManager.removeCoupon(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const confirmAppointment = (params, response, error) => {
    PosManager.confirmAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const startAppointment = (params, response, error) => {
    PosManager.startAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const completeAppointment = (params, response, error) => {
    PosManager.completeAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const cancelAppointment = (params, response, error) => {
    PosManager.cancelAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const rejectAppointment = (params, response, error) => {
    PosManager.rejectAppointment(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const downloadInvoice = (params, response, error) => {
    PosManager.downloadInvoice(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const sendInvoice = (params, response, error) => {
    PosManager.sendInvoice(
      params,
      (res) => {
        response(res);
      },
      (err) => {
        error(error);
      }
    );
  };

  const getLineItems = (str) => {
    let cartItems = getItems();
    let arry = [];
    if (cartItems) {
      for (var itemId in cartItems) {
        if (cartItems.hasOwnProperty(itemId)) {
          var variation = cartItems[itemId].variation;
          var variationId = null;
          if (variation) {
            variationId = variation.id;
          }
          var other_data = cartItems[itemId].product.other_data;
          if (variation && variation.other_data) {
            other_data = variation.other_data;
          }
          if (
            str === "SERVICES" &&
            cartItems[itemId].count > 0 &&
            cartItems[itemId].type === "SERVICES"
          ) {
            arry.push({
              service_id: cartItems[itemId].product.id,
              quantity: cartItems[itemId].count,
              instructions: "",
              variation_id: variationId,
            });
          } else if (
            str === "PRODUCTS" &&
            cartItems[itemId].count > 0 &&
            cartItems[itemId].type === "PRODUCTS"
          ) {
            arry.push({
              item_id: cartItems[itemId].product.id,
              quantity: cartItems[itemId].count,
              total_price: cartItems[itemId].product.price.total_price,
              variation_id: variationId,
              other_data: other_data,
            });
          }
        }
      }
    }
    return arry;
  };

  return (
    <PosCartContext.Provider
      value={{
        cart,
        getItems,
        getItem,
        getCartItemId,
        getCartItemsCount,
        clearCart,
        createNewItem,
        addNewItem,
        getTaxAmount,
        updateItem,
        removeItem,
        getCartPrice,
        getTotalVat,
        getBillAmount,
        bookAppointment,
        updateAppointment,
        addService,
        updateService,
        removeService,
        applyCoupon,
        removeCoupon,
        confirmAppointment,
        startAppointment,
        completeAppointment,
        cancelAppointment,
        rejectAppointment,
        downloadInvoice,
        sendInvoice,
        getLineItems: getLineItems,
        setUser,
        getUser,
      }}
    >
      {props.children}
    </PosCartContext.Provider>
  );
}

export default PosCartContext;
