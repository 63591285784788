import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import UserContext from "@presto-contexts/UserContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import SectionLinkTextComponent from "@presto-components/SectionLinks/SectionLinkTextComponent";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import { ScrollView } from "react-native-gesture-handler";
import { VerticalSpacing } from "@presto-components/Spacing";
import config from "@presto-common/config";
import PrestoText from "@presto-components/PrestoText";
import ProfileManager from "@presto-services/features/profile/ProfileManager";

export default function Profile({ navigation, route }) {
  const routeParam = route?.params || {};
  const { color } = routeParam;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyles(theme);
  const [virtualAccount, setVirtualAccount] = useState(null);

  useEffect(() => {
    ProfileManager.virtualAccountDetail(
      {},
      (response) => {
        setVirtualAccount(response.data);
      },
      (error) => {
        console.log(`error`, error);
      }
    );
  }, []);

  const onContactSupport = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "CONTACT_SCREEN",
      },
    });
  };

  return (
    <LoadingContainer style={{ paddingTop: 0 }} bgColor="transparent">
      <ScrollView
        style={{
          paddingTop: 20,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 100,
        }}
      >
        <View
          style={{
            paddingHorizontal: theme.containerPadding,
            justifyContent: "center",
            overflow: "hidden",
            paddingTop: 5,
          }}
        >
          <View
            style={[
              styles.mainContainer,
              {
                ...theme.darkShadow,
                marginBottom: 20,
              },
            ]}
          >
            {user?.brand_name ? (
              <>
                <View
                  style={{
                    minHeight: 55,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottomWidth: 0.05,
                    borderBottomColor: "#CCCCCC",
                    paddingVertical: theme.containerPadding,
                    paddingHorizontal: theme.primaryPadding,
                  }}
                >
                  <View style={{}}>
                    <PrestoText
                      fontStyle="400.normal"
                      color={color || theme.secondary}
                    >
                      {I18n.t("screen_messages.Brand_Name")}
                    </PrestoText>
                  </View>

                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <PrestoText fontStyle="400.normal" color={theme.darkText}>
                      {user.brand_name}
                    </PrestoText>
                  </View>
                </View>
                <LineDivider
                  dividerColor="#CCCCCC"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            {user?.company_name ? (
              <>
                <View
                  style={{
                    minHeight: 55,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottomWidth: 0.05,
                    borderBottomColor: "#CCCCCC",
                    paddingVertical: theme.containerPadding,
                    paddingHorizontal: theme.primaryPadding,
                  }}
                >
                  <View style={{}}>
                    <PrestoText
                      fontStyle="400.normal"
                      color={color || theme.secondary}
                    >
                      {I18n.t("screen_messages.common.company_name")}
                    </PrestoText>
                  </View>

                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <PrestoText fontStyle="400.normal" color={theme.darkText}>
                      {user.company_name}
                    </PrestoText>
                  </View>
                </View>
                <LineDivider
                  dividerColor="#CCCCCC"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            <View
              style={{
                minHeight: 55,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomWidth: 0.05,
                borderBottomColor: theme.lightText,
                paddingVertical: theme.containerPadding,
                paddingHorizontal: theme.primaryPadding,
              }}
            >
              <View style={{}}>
                <PrestoText
                  fontStyle="400.normal"
                  color={color || theme.secondary}
                >
                  {I18n.t("screen_messages.billing.billing_name")}
                </PrestoText>
              </View>
              {user.name ? (
                <View
                  style={{
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    width: "54%",
                  }}
                >
                  <PrestoText
                    fontStyle="400.normal"
                    color={theme.darkText}
                    extraStyle={{ textAlign: "right" }}
                  >
                    {user.name}
                  </PrestoText>
                </View>
              ) : null}
            </View>
            <LineDivider dividerColor="#CCCCCC" width="100%" theme={theme} />
            <View
              style={{
                minHeight: 55,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomWidth: 0.05,
                borderBottomColor: theme.lightText,
                paddingVertical: theme.containerPadding,
                paddingHorizontal: theme.primaryPadding,
              }}
            >
              <View style={{}}>
                <PrestoText
                  fontStyle="400.normal"
                  color={color || theme.secondary}
                >
                  {I18n.t("screen_messages.common.phone_number")}
                </PrestoText>
              </View>
              {user.phone_number ? (
                <View
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <PrestoText fontStyle="400.normal" color={theme.darkText}>
                    {user.phone_number}
                  </PrestoText>
                </View>
              ) : null}
            </View>
            <LineDivider dividerColor="#CCCCCC" width="100%" theme={theme} />
            <View
              style={{
                minHeight: 55,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomWidth: 0.05,
                borderBottomColor: theme.lightText,
                paddingVertical: theme.containerPadding,
                paddingHorizontal: theme.primaryPadding,
              }}
            >
              <View style={{}}>
                <PrestoText
                  fontStyle="400.normal"
                  color={color || theme.secondary}
                >
                  {I18n.t("screen_messages.common.email")}
                </PrestoText>
              </View>
              {user.email ? (
                <View
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <PrestoText fontStyle="400.normal" color={theme.darkText}>
                    {user.email}
                  </PrestoText>
                </View>
              ) : null}
            </View>
            <LineDivider dividerColor="#CCCCCC" width="100%" theme={theme} />
            <View
              style={{
                minHeight: 55,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomWidth: 0.05,
                borderBottomColor: theme.lightText,
                paddingVertical: theme.containerPadding,
                paddingHorizontal: theme.primaryPadding,
              }}
            >
              <View style={{}}>
                <PrestoText
                  fontStyle="400.normal"
                  color={color || theme.secondary}
                >
                  {I18n.t("screen_messages.Address")}
                </PrestoText>
              </View>
              {user.addresses && user.addresses.length > 0 ? (
                <View
                  style={{
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    width: "65%",
                  }}
                >
                  <PrestoText
                    color={theme.darkText}
                    extraStyle={{
                      textAlign: "right",
                    }}
                  >
                    {user.addresses[0].line1}
                  </PrestoText>
                  <PrestoText
                    color={theme.darkText}
                    extraStyle={{
                      textAlign: "right",
                    }}
                  >
                    {user.addresses[0].city}
                  </PrestoText>
                  <PrestoText
                    color={theme.darkText}
                    extraStyle={{
                      textAlign: "right",
                    }}
                  >
                    {user.addresses[0].pincode}
                  </PrestoText>
                </View>
              ) : user?.address ? (
                <PrestoText
                  color={theme.darkText}
                  extraStyle={{
                    textAlign: "right",
                    width: "65%",
                  }}
                  numberOfLines={2}
                >
                  {user.address}
                </PrestoText>
              ) : null}
            </View>
          </View>

          {virtualAccount ? (
            <>
              <View
                style={{
                  paddingTop: theme.containerPadding,
                  paddingBottom: theme.primaryPadding,
                }}
              >
                <PrestoText
                  fontStyle="400.normal"
                  size={16}
                  color={theme.black}
                >
                  {I18n.t("screen_messages.bank.title")}
                </PrestoText>
              </View>

              <View
                style={[
                  styles.mainContainer,
                  {
                    ...theme.darkShadow,
                    marginBottom: 20,
                  },
                ]}
              >
                <>
                  <View
                    style={{
                      minHeight: 55,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottomWidth: 0.05,
                      borderBottomColor: "#CCCCCC",
                      paddingVertical: theme.containerPadding,
                      paddingHorizontal: theme.primaryPadding,
                    }}
                  >
                    <View style={{}}>
                      <PrestoText
                        fontStyle="400.normal"
                        color={theme.secondary}
                      >
                        {I18n.t("screen_messages.bank.ac_no")}
                      </PrestoText>
                    </View>

                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <PrestoText fontStyle="400.normal" color={theme.darkText}>
                        {virtualAccount.ecollect_account_number}
                      </PrestoText>
                    </View>
                  </View>
                  <LineDivider
                    dividerColor="#CCCCCC"
                    width="100%"
                    theme={theme}
                  />
                </>

                <>
                  <View
                    style={{
                      minHeight: 55,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottomWidth: 0.05,
                      borderBottomColor: "#CCCCCC",
                      paddingVertical: theme.containerPadding,
                      paddingHorizontal: theme.primaryPadding,
                    }}
                  >
                    <View style={{}}>
                      <PrestoText
                        fontStyle="400.normal"
                        color={theme.secondary}
                      >
                        {I18n.t("screen_messages.bank.ifsc_no")}
                      </PrestoText>
                    </View>

                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <PrestoText fontStyle="400.normal" color={theme.darkText}>
                        {virtualAccount.ecollect_ifsc_code}
                      </PrestoText>
                    </View>
                  </View>
                  <LineDivider
                    dividerColor="#CCCCCC"
                    width="100%"
                    theme={theme}
                  />
                </>
              </View>
            </>
          ) : null}
          <VerticalSpacing size={10} />
          <SectionLinkTextComponent
            leftText1={I18n.t(
              "screen_messages.common.do_you_wish_to_change_your_details"
            )}
            leftText2={I18n.t("screen_messages.contact.contact_support")}
            onPressButton={() => onContactSupport()}
            theme={theme}
            linkTextColor={theme.primary}
          />
          <View
            style={{
              paddingVertical: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText color="#999999">
              {config.appVersion} ({config.expoRunTimeVersion})
            </PrestoText>
          </View>
        </View>
        <VerticalSpacing size={100} />
      </ScrollView>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    mainContainer: {
      backgroundColor: "#EEEEEE",
      position: "relative",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      borderRadius: 8,
      ...theme.darkShadow,
    },
  };
}
