import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SearchIconV2(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <G
        stroke="none"
        strokeWidth={1}
        fill={props.color || "#000"}
        fillRule="nonzero"
      >
        <Path d="M16.8 5c6.517 0 11.8 5.283 11.8 11.8 0 2.348-.686 4.536-1.868 6.375.27.095.523.252.74.468l7.07 7.071a2 2 0 11-2.828 2.829l-7.07-7.071a1.998 1.998 0 01-.381-.531A11.75 11.75 0 0116.8 28.6C10.283 28.6 5 23.317 5 16.8S10.283 5 16.8 5zm0 1.6c-5.633 0-10.2 4.567-10.2 10.2 0 5.633 4.567 10.2 10.2 10.2C22.433 27 27 22.433 27 16.8c0-5.633-4.567-10.2-10.2-10.2z" />
        <Path
          d="M20.751 10c.69 0 1.249.535 1.249 1.194 0 6.519-8.526 9.274-13.655 4.126a1.158 1.158 0 01.042-1.69 1.29 1.29 0 011.766.042c3.632 3.645 9.35 1.798 9.35-2.478 0-.66.559-1.194 1.248-1.194z"
          transform="rotate(150 15 14)"
        />
      </G>
    </Svg>
  );
}

export default SearchIconV2;
