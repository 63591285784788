import React, { useState, useEffect } from "react";
import asyncify from "../../common/Asyncify";
import WebLayout from "@presto-screens/WebLayout/WebLayout";
import BrowseCategories from "@presto-screens/BrowseCategories/BrowseCategories";
import OffersManager from "@presto-services/features/offers/OffersManager";
const AsyncOfferManager = asyncify(OffersManager);

export default function WebBrowseCategories({ navigation, route }) {
  const [rollingText, setRollingText] = useState("");

  useEffect(() => {
    getRollingText();
  }, []);

  const getRollingText = async () => {
    const [error, response] = await AsyncOfferManager.getHomePageOffers();
    if (response) {
      setRollingText(response.data.offer_text);
    }
  };

  return (
    <WebLayout showCart={true}>
      <BrowseCategories route={route} navigation={navigation} />
    </WebLayout>
  );
}
