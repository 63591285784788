import NotificationResource from "./NotificationResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import NotificationObject from "../../data_models/NotificationObject";
import $ from "../../global/util";

const NotificationManager = function (
  NotificationResource,
  Utils,
  ErrorConstants,
  NotificationObject
) {
  function getNotificationArray(rawNotifications) {
    var notifications = [];
    if (rawNotifications) {
      rawNotifications.forEach(function (
        rawNotification,
        index,
        rawNotifications
      ) {
        var notificationObj = new NotificationObject();
        notificationObj.buildObject(rawNotification);
        notifications.push(notificationObj);
      });
    }
    return notifications;
  }

  function getNotifications(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.deviceId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.DEVICE_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      if (errorCallback) {
        errorCallback(error);
      }
    } else {
      NotificationResource.getNotifications(params).then(
        function (notificationsResponse) {
          if (successCallback) {
            notificationsResponse.data = getNotificationArray(
              notificationsResponse.data
            );
            successCallback(notificationsResponse);
          }
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          if (errorCallback) {
            errorCallback(error);
          }
        }
      );
    }
  }

  return {
    getNotifications: getNotifications,
  };
};

export default NotificationManager(
  NotificationResource,
  Utils,
  ErrorConstants,
  NotificationObject
);
