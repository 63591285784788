import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 25}
      height={props.height || 25}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M30 4.3A3.7 3.7 0 0133.7 8v4.269l3.22 4.062v2.474a2.7 2.7 0 01-2.7 2.7l-.521-.001L33.7 33a3.7 3.7 0 01-3.503 3.695L30 36.7H9A3.7 3.7 0 015.3 33l-.001-11.496h-.492a2.7 2.7 0 01-2.694-2.529l-.006-.17v-2.473L5.3 12.27V8a3.7 3.7 0 013.503-3.695L9 4.3zm5.52 12.858H3.505l.001 1.647a1.3 1.3 0 001.038 1.273l.129.02.133.007 1.892-.001L6.7 33A2.3 2.3 0 009 35.3h11.52a1.51 1.51 0 01-.02-.245V25a1.5 1.5 0 011.5-1.5h7a1.5 1.5 0 011.5 1.5v10.055c0 .066-.004.13-.012.194A2.302 2.302 0 0032.3 33V20.104h1.92a1.3 1.3 0 001.3-1.3v-1.646zM29 24.5h-7a.5.5 0 00-.5.5v10.055c0 .089.023.172.064.245h7.872a.498.498 0 00.064-.245V25a.5.5 0 00-.5-.5zm-11.5-1A1.5 1.5 0 0119 25v5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 019 30v-5a1.5 1.5 0 011.5-1.5zm0 1h-7a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm14.992-11.501H6.506l-2.483 3.159h30.973l-2.504-3.159zM12.5 13.6a.5.5 0 01.492.41l.008.09v.8a.5.5 0 01-.992.09L12 14.9v-.8a.5.5 0 01.5-.5zm14.5 0a.5.5 0 01.492.41l.008.09v.8a.5.5 0 01-.992.09l-.008-.09v-.8a.5.5 0 01.5-.5zm-7 0a.5.5 0 01.492.41l.008.09v.8a.5.5 0 01-.992.09l-.008-.09v-.8a.5.5 0 01.5-.5zm10-7.9H9A2.3 2.3 0 006.7 8v3.999h25.6V8a2.3 2.3 0 00-2.143-2.295L30 5.7z"
        fill={props.primaryColor || "#fff"}
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default SvgComponent;
