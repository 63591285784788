import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function ActionNeededInProgressIcon({
  width=40,
  height=40,
  fill="#D9B673"
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <G id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <G id="icon/colour/action-needed-In-progress">
                <Circle id="Oval" stroke={fill} stroke-width="0.9" fill={fill} cx="20" cy="20" r="17.55"></Circle>
                <Circle id="Oval" stroke="#FFFFFF" fill="#FFFFFF" cx="13.5" cy="20.5" r="3"></Circle>
                <Circle id="Oval-Copy" stroke="#FFFFFF" fill="#FFFFFF" cx="21.5" cy="21.5" r="2"></Circle>
                <Circle id="Oval-Copy-2" stroke="#FFFFFF" fill="#FFFFFF" cx="27.5" cy="22.5" r="1"></Circle>
            </G>
        </G>
    </Svg>
  )

}

export default ActionNeededInProgressIcon;
