import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function ReturningCustomer(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke={props.color || "#7E5C5B"}
          strokeWidth={0.9}
          fill={props.color || "#7E5C5B"}
          cx={20}
          cy={20}
          r={17.55}
        />
        <Path
          transform="translate(9 9) matrix(-1 0 0 1 23 0)"
          fill="#FFF"
          fillRule="nonzero"
          d="M11.5 0L9.4084375 2.0915625 17.3233125 10.0064375 0 10.0064375 0 12.9935625 17.32475 12.9935625 9.4084375 20.9084375 11.5 23 23 11.5z"
        />
      </G>
    </Svg>
  );
}

export default ReturningCustomer;
