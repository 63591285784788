import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import ThemeContext from "@presto-contexts/ThemeContext";
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Dimensions,
  Keyboard,
  StyleSheet,
  TouchableWithoutFeedback,
  Pressable,
  View,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useMediaQuery } from "react-responsive";
import Spring from "@presto-components/Spring/Spring";
import { useAssets } from "presto-react-components";

export default function DefaultModal({
  children,
  goBack,
  heading,
  footer,
  headerFontSize,
  height,
  modalTopLeftRadius = 30,
  modalTopRightRadius = 30,
}) {
  const { theme } = useContext(ThemeContext);
  const [close, setClose] = useState(close);
  const isDesktop = useMediaQuery({ minWidth: 941 });
  const fromSpring = { margin: 0 };
  const toSpring = { margin: isDesktop ? 1000 : 600 };
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const modalHeaderFontSize = headerFontSize ? headerFontSize : 17;

  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => {
        return (
          <View
            style={{
              // flex: 1,
              height: isDesktop ? "100%" : windowHeight,
              width: isDesktop ? "100%" : windowWidth,
              alignItems: "flex-start",
              justifyContent: "flex-end",
              backgroundColor: "#000000",
            }}
          >
            <View
              style={{
                backgroundColor: theme.appBackgroundColor,
                borderTopLeftRadius: modalTopLeftRadius,
                borderTopRightRadius: modalTopRightRadius,
                minHeight: 400,
                flex: 1,
                width: isDesktop ? "100%" : windowWidth,
              }}
            >
              <ScrollView style={{ height: "100%" }}>{children}</ScrollView>
            </View>
          </View>
        );
      }}
    </Spring>
  );
}

export function ModalWithIcon({
  children,
  goBack,
  heading,
  footer,
  headerFontSize,
  height,
  subTitle,
  modalRef,
  centerHeader,
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const [close, setClose] = useState(close);

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const [margin, setMargin] = useState(0);
  console.log("Window Height : ", windowHeight, windowWidth);
  const modalHeaderFontSize = headerFontSize ? headerFontSize : 17;
  const [padding, setPadding] = useState(-windowHeight);

  useImperativeHandle(modalRef, () => {
    return {
      open: () => {
        setMargin(0);
      },
      close: () => {
        setMargin(-windowHeight);
      },
    };
  });
  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      console.log("in showSubscription");
      setPadding(100);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      console.log("in hidesubscription");
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const fromSpring = { margin: margin == 0 ? -windowHeight : 0 };
  const toSpring = { margin: margin == 0 ? 0 : -windowHeight };
  const isDesktop = useMediaQuery({ minWidth: 941 });
  if (isDesktop) {
    return <DefaultModal>{children}</DefaultModal>;
  }
  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => {
        return (
          <View
            style={{
              flex: 1,
              backgroundColor: "#00000021",
            }}
          >
            <View
              style={{
                ...styles.modalContainer,
                marginBottom: props.margin,
                height: windowHeight,
                width: windowWidth,
                flexDirection: "column",
                justifyContent: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <View
                style={{
                  position: "relative",
                  backgroundColor: "transparent",
                }}
              >
                <View>
                  <View
                    style={{
                      borderTopLeftRadius: 30,
                      borderTopRightRadius: 30,
                      position: "relative",
                      overflow: "hidden",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      marginBottom: -5,
                    }}
                  >
                    <View style={{ marginHorizontal: -13 }}>
                      <svgs.ModalHeaderBg
                        width={windowWidth + 26}
                        height={56}
                        color={theme.primary}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: -26,
                      alignItems: "center",
                      width: windowWidth,
                    }}
                  >
                    <Pressable onPress={() => goBack()}>
                      <svgs.ModalCloseIcon
                        color={theme.primary}
                        color2={theme.secondary}
                        color4={theme.primary}
                        width={56}
                        height={56}
                      />
                    </Pressable>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: theme.modalBackgroundColor || "#FFF",
                    maxHeight: windowHeight - 100,
                    width: windowWidth,
                    marginTop: -10,
                    position: "relative",
                  }}
                >
                  {heading && !subTitle ? (
                    <View
                      style={{
                        paddingHorizontal: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <PrestoText
                        fontStyle="semibold"
                        size={modalHeaderFontSize}
                        color={theme.darkText}
                        extraStyle={{
                          textAlign: centerHeader && "center",
                        }}
                      >
                        {heading}
                      </PrestoText>
                    </View>
                  ) : null}
                  {heading && subTitle ? (
                    <View
                      style={{
                        paddingHorizontal: 20,
                        paddingBottom: 10,
                        marginBottom: 20,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottomWidth: 1,
                        borderBottomColor: "#999999",
                      }}
                    >
                      <PrestoText
                        fontStyle="semibold"
                        size={modalHeaderFontSize}
                        color={theme.darkText}
                      >
                        {heading}
                      </PrestoText>
                      <PrestoText
                        fontStyle="semibold"
                        size={modalHeaderFontSize}
                        color={theme.secondary}
                      >
                        {subTitle}
                      </PrestoText>
                    </View>
                  ) : null}
                  <ScrollView style={{ height: height || windowHeight - 250 }}>
                    {children}
                    <VerticalSpacing size={padding} />
                  </ScrollView>
                  <View
                    style={{
                      paddingHorizontal: 20,
                      paddingBottom: 15,
                      paddingTop: 15,
                      minHeight: footer ? 100 : 0,
                    }}
                  >
                    {footer}
                  </View>
                </View>
              </View>
            </View>
          </View>
        );
      }}
    </Spring>
  );
}
const styles = {
  modalContainer: {
    shadowColor: "rgba(95, 95, 95, 1)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 30,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
  },
};
