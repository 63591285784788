import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(6 5) translate(0 1)">
          <Mask id="b" fill={props.color || "#fff"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <Path d="M0 0H29V29H0z" />
            <Path
              d="M12.941 18.468a4.714 4.714 0 003.297-1.326 4.469 4.469 0 001.361-3.21c.019-2.54-2.051-4.628-4.658-4.7a4.715 4.715 0 00-3.297 1.326A4.469 4.469 0 008.28 13.77c.042 2.516 2.079 4.57 4.66 4.699zM5.12 6.154c4.319-4.205 11.322-4.205 15.64 0a10.571 10.571 0 010 15.231L12.942 29 5.12 21.385a10.854 10.854 0 010-15.23z"
              fill={props.color2 || "#707070"}
            />
          </G>
        </G>
        <G transform="translate(6 5) translate(13)">
          <Circle fill={props.color3 || "#007AFF"} cx={8} cy={8} r={8} />
          <G
            transform="translate(3.5 3.5)"
            stroke={props.color || "#fff"}
            strokeLinecap="square"
          >
            <Path d="M4.5 -6.52886836e-17L4.5 9" />
            <Path
              transform="matrix(0 -1 -1 0 9 9)"
              d="M4.5 1.0658141e-14L4.5 9"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
