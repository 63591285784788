import CategoryResource from "./CategoryResource";
import CategoryObject from "../../data_models/CategoryObject";
import ErrorConstants from "../../common/ErrorConstants";
import Utils from "../../common/Utils";

const CategoryManager = function (
  CategoryResource,
  CategoryObject,
  ErrorConstants,
  Utils
) {
  function getCategoryDetails(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.categoryId)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.CATEGORY_ID_EMPTY,
        false
      );
      errorObject = Utils.updateErrorObject(errorObject);
      errorCallback(errorObject);
    } else {
      CategoryResource.getCategoryDetails(params).then(
        function (categoryDetails) {
          var categoryObj = new CategoryObject();
          categoryObj.buildObject(categoryDetails.data);
          categoryDetails.data = categoryDetails.data;
          if (successCallback) {
            successCallback(categoryDetails);
          }
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getUBUCategories(params, successCallback, errorCallback) {
    CategoryResource.getUBUCategories(params).then(
      function (categoryDetails) {
        if (successCallback) {
          successCallback(categoryDetails);
        }
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getScreenMetaData(screenName, successCallback, errorCallback) {
    CategoryResource.getScreenMetaData(screenName).then(
      function (details) {
        if (successCallback) {
          successCallback(details);
        }
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeCategory(params, successCallback, errorCallback) {
    CategoryResource.removeCategory(params).then(
      function (details) {
        if (successCallback) {
          successCallback(details);
        }
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getCategoryDetails: getCategoryDetails,
    getUBUCategories: getUBUCategories,
    getScreenMetaData: getScreenMetaData,
    removeCategory: removeCategory,
  };
};

export default CategoryManager(
  CategoryResource,
  CategoryObject,
  ErrorConstants,
  Utils
);
