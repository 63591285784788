import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function Services(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G fill={props.color || "#979797"}>
          <Path
            d="M6.7 5.086c-.506-.272-1.131-.368-1.716-.442-1.836-.231-3.404.29-4.737 1.75C3.98 8.56 9.059 11.429 12.693 13.5l4.586-2.622C13.979 9.054 9.827 6.767 6.7 5.086"
            transform="translate(4 7)"
          />
          <Path
            d="M12.693 13.5C9.06 11.428 3.981 8.559.247 6.395 1.379 5.155 2.68 4.592 4.17 4.592c.265 0 .536.017.813.052.585.074 1.21.17 1.717.442 3.126 1.68 7.278 3.968 10.578 5.792L12.693 13.5"
            transform="translate(4 7)"
          />
          <Path
            d="M26.013 23.95c1.54.028 2.86-1.238 2.9-2.781.042-1.628-1.199-2.927-2.813-2.948-1.557-.02-2.865 1.218-2.922 2.763-.057 1.556 1.264 2.94 2.835 2.967zm.032-15.168c1.615.007 2.884-1.273 2.869-2.895-.014-1.549-1.3-2.829-2.846-2.835-1.57-.007-2.912 1.345-2.89 2.911.02 1.54 1.315 2.811 2.867 2.819zm-5.297 9.552c-.117-.097-.14-.122-.168-.138-1.142-.66-2.353-1.92-3.51-1.897-.44.01-.914.388-2.052 1.03-1.897 1.07-5.08 2.935-6.987 3.987-1.627.898-3.61 1.57-5.463.987-.901-.283-1.62-.789-2.568-1.551L20.745 8.727c-.108-.307-.217-.604-.317-.903a5.927 5.927 0 012.214-6.748c2.175-1.529 5.105-1.42 7.187.269 2.026 1.642 2.722 4.472 1.694 6.89-1.036 2.439-3.51 3.863-6.133 3.597-.45-.046-.968.081-1.381.28-.79.376-1.528.86-2.47 1.406 1.596.718 2.68 1.858 4.545 1.685 2.573-.24 4.862 1.627 5.644 4.114a5.91 5.91 0 01-2.21 6.578 5.973 5.973 0 01-7.033-.081 5.933 5.933 0 01-2.059-6.631c.094-.276.207-.545.322-.85z"
            transform="translate(4 7)"
          />
          <Path
            d="M23.403 12.396c.006.01-.145.11-.421.281l-1.179.715c-1.138.669-2.462 1.444-3.922 2.303l-3.947 2.26-1.205.669c-.286.155-.447.235-.453.226-.005-.01.145-.11.422-.282l1.18-.715 3.92-2.303 3.946-2.259 1.205-.67c.287-.154.448-.235.454-.225M19.955 9.216a1.24 1.24 0 01-.194.134l-.57.355-2.113 1.279a287.16 287.16 0 01-7.03 4.12L2.96 19.127.802 20.33l-.59.32a1.248 1.248 0 01-.212.102c-.002-.005.064-.05.196-.132l.575-.346 2.13-1.248 7.063-4.065c2.77-1.564 5.252-3.028 7.054-4.078.843-.486 1.558-.899 2.14-1.233l.587-.329a1.19 1.19 0 01.21-.105"
            transform="translate(4 7)"
          />
        </G>
        <Circle stroke="#FFF" strokeWidth={0.8} cx={21} cy={20.5} r={1.1} />
      </G>
    </Svg>
  );
}

export default Services;
