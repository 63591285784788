import PosResource from "./PosResource";
import ErrorConstants from "../../common/ErrorConstants";
import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import UserObject from "../../data_models/UserObject";
import $ from "../../global/util";

const PosManager = function (PosResource, Utils) {
  function searchUser(params, successCallback, errorCallback) {
    PosResource.searchUser(params).then(
      function (searchUserResponse) {
        successCallback(searchUserResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getUser(params, successCallback, errorCallback) {
    PosResource.getUser(params).then(
      function (getUserResponse) {
        successCallback(getUserResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function bookAppointment(params, successCallback, errorCallback) {
    PosResource.bookAppointment(params).then(
      function (bookPosAppointmentResponse) {
        successCallback(bookPosAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAppointment(params, successCallback, errorCallback) {
    PosResource.getAppointment(params).then(
      function (getAppointmentResponse) {
        successCallback(getAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function rescheduleAppointment(params, successCallback, errorCallback) {
    PosResource.rescheduleAppointment(params).then(
      function (rescheduleAppointmentResponse) {
        successCallback(rescheduleAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateAppointment(params, successCallback, errorCallback) {
    PosResource.updateAppointment(params).then(
      function (updateAppointmentResponse) {
        successCallback(updateAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getBooking(params, successCallback, errorCallback) {
    PosResource.getBooking(params).then(
      function (getBookingResponse) {
        successCallback(getBookingResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addService(params, successCallback, errorCallback) {
    PosResource.addService(params).then(
      function (addServiceResponse) {
        successCallback(addServiceResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateService(params, successCallback, errorCallback) {
    PosResource.updateService(params).then(
      function (updateServiceResponse) {
        successCallback(updateServiceResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeService(params, successCallback, errorCallback) {
    PosResource.removeService(params).then(
      function (removeServiceResponse) {
        successCallback(removeServiceResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function applyCoupon(params, successCallback, errorCallback) {
    PosResource.applyCoupon(params).then(
      function (applyCouponResponse) {
        successCallback(applyCouponResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeCoupon(params, successCallback, errorCallback) {
    PosResource.removeCoupon(params).then(
      function (removeCouponResponse) {
        successCallback(removeCouponResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function confirmAppointment(params, successCallback, errorCallback) {
    PosResource.confirmAppointment(params).then(
      function (confirmAppointmentResponse) {
        successCallback(confirmAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function startAppointment(params, successCallback, errorCallback) {
    PosResource.startAppointment(params).then(
      function (cstartAppointmentResponse) {
        successCallback(cstartAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function completeAppointment(params, successCallback, errorCallback) {
    PosResource.completeAppointment(params).then(
      function (completeAppointmentResponse) {
        successCallback(completeAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelAppointment(params, successCallback, errorCallback) {
    PosResource.cancelAppointment(params).then(
      function (cancelAppointmentResponse) {
        successCallback(cancelAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function rejectAppointment(params, successCallback, errorCallback) {
    PosResource.rejectAppointment(params).then(
      function (rejectAppointmentResponse) {
        successCallback(rejectAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function downloadInvoice(params, successCallback, errorCallback) {
    PosResource.downloadInvoice(params).then(
      function (downloadInvoiceResponse) {
        successCallback(downloadInvoiceResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function sendInvoice(params, successCallback, errorCallback) {
    PosResource.sendInvoice(params).then(
      function (sendInvoiceResponse) {
        successCallback(sendInvoiceResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllAppointments(params, successCallback, errorCallback) {
    PosResource.getAllAppointments(params).then(
      function (getAppointmentResponse) {
        successCallback(getAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function startBookingService(params, successCallback, errorCallback) {
    PosResource.startBookingService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function completeBookingService(params, successCallback, errorCallback) {
    PosResource.completeBookingService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function searchAppointment(params, successCallback, errorCallback) {
    PosResource.searchAppointment(params).then(
      function (searchUserResponse) {
        successCallback(searchUserResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createPayment(params, successCallback, errorCallback) {
    PosResource.createPayment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelPayment(params, successCallback, errorCallback) {
    PosResource.cancelPayment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function successPayment(params, successCallback, errorCallback) {
    PosResource.successPayment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getUserAppointments(params, successCallback, errorCallback) {
    PosResource.getUserAppointments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createService(params, successCallback, errorCallback) {
    PosResource.createService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function updateServiceByAdmin(params, successCallback, errorCallback) {
    PosResource.updateServiceByAdmin(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function deleteServiceByAdmin(params, successCallback, errorCallback) {
    PosResource.deleteServiceByAdmin(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    searchUser: searchUser,
    bookAppointment: bookAppointment,
    getAppointment: getAppointment,
    getBooking: getBooking,
    addService: addService,
    updateService: updateService,
    removeService: removeService,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    confirmAppointment: confirmAppointment,
    startAppointment: startAppointment,
    completeAppointment: completeAppointment,
    cancelAppointment: cancelAppointment,
    rejectAppointment: rejectAppointment,
    downloadInvoice: downloadInvoice,
    sendInvoice: sendInvoice,
    getAllAppointments: getAllAppointments,
    getUser: getUser,
    startBookingService: startBookingService,
    completeBookingService: completeBookingService,
    searchAppointment: searchAppointment,
    createPayment: createPayment,
    successPayment: successPayment,
    cancelPayment: cancelPayment,
    getUserAppointments: getUserAppointments,
    rescheduleAppointment: rescheduleAppointment,
    updateAppointment: updateAppointment,
    createService: createService,
    updateServiceByAdmin: updateServiceByAdmin,
    deleteServiceByAdmin: deleteServiceByAdmin,
  };
};

export default PosManager(PosResource, Utils);
