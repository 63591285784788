import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G fill="#707070" fillRule="nonzero">
          <Path
            d="M25 .938A.934.934 0 0024.07 0H.93C.416 0 0 .42 0 .938v30.125c0 .517.416.937.93.937h23.14c.514 0 .93-.42.93-.938V.938zM5.578 30.125V1.875h11.26l6.303 6.357v15.536l-6.304 6.357H5.577zM23.14 5.58l-3.674-3.705h3.674V5.58zM1.859 1.875h1.86v28.25h-1.86V1.875zm17.608 28.25l3.674-3.705v3.705h-3.674z"
            transform="translate(8 4)"
          />
          <Path
            d="M15.265 22.625H8.367c-.514 0-.93.42-.93.938v3.75c0 .517.416.937.93.937h6.898c.514 0 .93-.42.93-.938v-3.75a.934.934 0 00-.93-.937zm-.93 3.75H9.297V24.5h5.04v1.875z"
            transform="translate(8 4)"
          />
        </G>
        <G transform="translate(17 17)" stroke="#707070" strokeWidth={1.3}>
          <Path
            d="M11 4C9.22 1.333 7.441 0 5.661 0 3.881 0 1.995 1.333 0 4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path d="M0 0.918910967L0 4 2.24353995 4" />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
