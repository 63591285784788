import React, { useContext, useState } from "react";
import { View, Image } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import Pressable from "@presto-components/Pressable/Pressable";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import svgs from "@presto-common-assets/svgs";
import images from "@presto-assets/images";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import I18n from "i18n-js";
import SearchInput from "@presto-components/TextInputs/SearchInput";
import PrestoIcon from "@presto-components/PrestoIcon";
import { toUpper } from "lodash";
import _ from "lodash";
import SearchResultsScreen from "@presto-screens/SearchResults/SearchResults";
import CartContext from "@presto-contexts/CartContext";
import AsyncStorage from "@react-native-community/async-storage";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoTextButton,
} from "@presto-components/PrestoButton";
import utils from "../../utils/index";

export default function WebHeader(props) {
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [searchQuery, setSearchQuery] = useState("");
  const { getItemCount } = useContext(CartContext);
  const [isFocus, setIsfocus] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { user, authState, currentAddress } = useContext(UserContext);
  const isDesktop = utils.isDesktop();

  const onSearchentery = async (text) => {
    if (text === "") {
      return;
    }

    const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
    const recentSearches = recentSearchesJson
      ? JSON.parse(recentSearchesJson)
      : [];
    const filteredSearches = _.filter(recentSearches, (s) => s !== text);
    const newRecentSearches = [text, ...filteredSearches];
    const splicedSearches = _.slice(newRecentSearches, 0, 5);
    await AsyncStorage.setItem(
      "RECENT_SEARCHES",
      JSON.stringify(splicedSearches)
    );
  };
  const onSearch = (searchProps) => {
    setIsfocus(false);
    onSearchentery(searchQuery);
    WebNavigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH",
        screenParams: { query: searchProps || searchQuery },
      },
    });
  };

  function _onSearchQueryChange({ nativeEvent: { text } }) {
    setSearchQuery(text);
  }

  const RenderItem = ({
    icon,
    title,
    onPress,
    activeStyles,
    ShowCount,
    isActive,
  }) => {
    return (
      <Pressable onPress={onPress}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginHorizontal: 18,
          }}
        >
          {ShowCount && getItemCount() > 0 && (
            <View
              style={{
                backgroundColor: theme.primary,
                padding: 10,
                height: 22,
                width: 22,
                borderRadius: 30,
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: 15,
                top: -10,
                zIndex: 99,
              }}
            >
              <PrestoText size={12} fontStyle="400.normal" color={theme.white}>
                {getItemCount()}
              </PrestoText>
            </View>
          )}
          <PrestoIcon
            icon={icon}
            style={{
              alignItems: "center",
            }}
          />
          <PrestoText
            fontStyle={isActive ? "500.medium" : "400.normal"}
            size={16}
            color={theme.darkText}
            extraStyle={{
              paddingLeft: 10,
            }}
          >
            {title}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const onPressItem = (option) => {
    setSelectedMenu(option);
    props.onNavigate(options[toUpper(option)]);
  };

  const searchResultProps = {
    route: {
      params: {
        searchQuery: searchQuery,
        hideFloatingCart: true,
        hideSearch: true,
        onSuggestionsPress: onSearch,
      },
    },
  };

  const renderSearchModal = () => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          height: 400,
          top: 50,
          left: 0,
          width: "100%",
          minWidth: 400,
          flexDirection: "column",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            position: "absolute",
            height: "100%",
            width: "95%",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "lightgray",
            overflow: "hidden",
            padding: 10,
          }}
        >
          <SearchResultsScreen {...searchResultProps} />
        </View>
        <View style={{ position: "absolute", right: -40, zIndex: 11 }}>
          <Pressable
            style={{
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundColor: theme.secondary,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => {
              setIsfocus(false);
              console.log("isfocus", isFocus);
            }}
          >
            <View
              style={{
                padding: 2,
                borderRadius: 50,
                backgroundColor: theme.secondary,
              }}
            >
              <PrestoIcon
                icon={
                  <svgs.CloseIcon color={theme.white} width={30} height={30} />
                }
              />
            </View>
          </Pressable>
        </View>
      </View>
    );
  };
  const options = {
    MENU: "MORE",
    CART: "CART",
    HOME: "HOME",
    PROFILE_SCREEN: "ACCOUNT",
    WALLET_SCREEN: "WALLET_SCREEN",
    LOGIN: "LOGIN",
    SIGNUP_SCREEN: "SIGNUP_SCREEN",
  };

  const onPress = (screenName) => {
    props.onNavigate(options[toUpper(screenName)]);
  };

  return (
    <View
      style={{
        backgroundColor: theme.white,
        shadowColor: "rgb(32, 32, 32)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 1,
        elevation: 3,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          minHeight: 85,
          alignItems: "center",
          justifyContent: "space-between",
          width: "90%",
          alignSelf: "center",
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={() => {
              WebNavigator.emit({
                event: "replace",
                params: {
                  screenName: "HOME_SCREEN",
                },
              });
            }}
          >
            <Image
              source={images.headerLogo}
              style={{
                width: "100%",
                height: 60,
                minWidth: 200,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
          {props?.showFooterText ? (
            <PrestoText
              color={theme.primary}
              size={12}
              fontStyle="500.medium"
              extraStyle={{
                paddingBottom: isDesktop ? undefined : 5,
              }}
            >
              {I18n.t("screen_messages.footer_text")}
            </PrestoText>
          ) : null}
        </View>
        <View
          style={{
            width: "80%",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              alignItems: "center",
              marginLeft: theme.containerPadding,
              flexDirection: "row",

              flex: 1,
            }}
          >
            {!user ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flex: 1,
                }}
              >
                <View style={{ marginRight: theme.primaryPadding }}>
                  <PrestoTextButton
                    size="normal"
                    title="Login"
                    titleFontStyle="400.normal"
                    leftIcon={<svgs.UserOutline primaryColor={theme.white} />}
                    onPress={() => onPress("LOGIN")}
                  />
                </View>
                <View style={{ marginLeft: theme.primaryPadding }}>
                  <PrestoSolidButton
                    size="large"
                    title="Register"
                    titleColor={theme.white}
                    buttonStyle="secondary"
                    bgColor={theme.secondary}
                    onPress={() => onPress("SIGNUP_SCREEN")}
                    extraStyle={{
                      paddingVertical: 12,
                      paddingHorizontal: 24,
                    }}
                  />
                </View>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <RenderItem
                  icon={
                    <svgs.Hamburger
                      color={
                        selectedMenu == "MENU" ? theme.black : theme.darkText
                      }
                      width={30}
                      height={30}
                    />
                  }
                  title={I18n.t("screen_messages.more_str")}
                  onPress={() => onPressItem("MENU")}
                  isActive={selectedMenu == "MENU"}
                />
                <RenderItem
                  icon={
                    <svgs.LineWalletIcon
                      color={
                        selectedMenu == "WALLET_SCREEN"
                          ? theme.black
                          : theme.darkText
                      }
                      width={30}
                      height={30}
                    />
                  }
                  title={I18n.t("screen_messages.wallet.wallet_text")}
                  onPress={() => onPressItem("WALLET_SCREEN")}
                  isActive={selectedMenu == "WALLET_SCREEN"}
                />
                <RenderItem
                  icon={
                    <svgs.UserOutline
                      color={
                        selectedMenu == "PROFILE_SCREEN"
                          ? theme.black
                          : theme.darkText
                      }
                      width={30}
                      height={30}
                    />
                  }
                  title={_.truncate(user.name || user.email, {
                    length: 20,
                    separator: "...",
                  })}
                  onPress={() => onPressItem("PROFILE_SCREEN")}
                  isActive={selectedMenu == "PROFILE_SCREEN"}
                />
                <RenderItem
                  ShowCount
                  icon={
                    <svgs.CartIcon
                      color={
                        selectedMenu == "CART" ? theme.black : theme.darkText
                      }
                      width={30}
                      height={30}
                    />
                  }
                  title={I18n.t("screen_messages.cart_tab")}
                  onPress={() => onPressItem("CART")}
                  isActive={selectedMenu == "CART"}
                />
              </View>
            )}
          </View>
          <View style={{ width: "32%", maxWidth: 500 }}>
            <SearchInput
              onSearch={() => onSearch(searchQuery)}
              placeholder={I18n.t("screen_messages.search_default_message")}
              value={searchQuery}
              onChange={_onSearchQueryChange}
              containerStyle={{
                backgroundColor: theme.white,
              }}
              onSearchFocus={(value) => {
                setIsfocus(true);
              }}
              icon={searchQuery.length > 0 ? <></> : null}
            />
            {isFocus ? renderSearchModal() : null}
          </View>
        </View>
      </View>
    </View>
  );
}
