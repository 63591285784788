import * as React from "react";
import Svg, {
  Defs,
  LinearGradient,
  Stop,
  G,
  Circle,
  Path,
} from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="92px"
      height="92px"
      viewBox="0 0 92 92"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="98.6126666%" id="abc">
          <Stop stopColor={props.color || "#007BFF"} offset="0%" />
          <Stop stopColor={props.color2 || "#00C54F"} offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill={props.color3 || "#FFF"} cx={46} cy={46} r={46} />
        <Path
          d="M67.163 36.004a3.413 3.413 0 014.835 0 3.435 3.435 0 01.13 4.712l-.13.137-23.08 23.143a3.413 3.413 0 01-4.7.13l-.136-.13-23.08-23.143a3.435 3.435 0 010-4.849 3.413 3.413 0 014.699-.13l.136.13L46.5 56.723l20.663-20.719z"
          fill="url(#abc)"
          fillRule="nonzero"
        />
        <Path
          d="M46 0c25.405 0 46 20.595 46 46S71.405 92 46 92 0 71.405 0 46 20.595 0 46 0zm0 8.214C25.132 8.214 8.214 25.132 8.214 46c0 20.868 16.918 37.786 37.786 37.786 20.868 0 37.786-16.918 37.786-37.786C83.786 25.132 66.868 8.214 46 8.214z"
          fill={props.color4 || "#007AFF"}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
