import * as React from "react";
import Svg, { Path, G, Circle } from "react-native-svg";

export function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill={props.color || "#C4421A"} cx={20} cy={20} r={20} />
        <G
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <Path
            d="M0 2.548a11 11 0 0114.08 0M3.53 6.108a6 6 0 016.95 0"
            transform="translate(7 6.855) rotate(45 8.287 20.007)"
          />
        </G>
        <Path
          d="M3.824 2.145c-1.033 0-1.968.983-2.794 1.852-.167.175-.329.345-.487.504-.793.792-.712 2.876.206 5.308 1.133 3.006 3.386 6.29 6.342 9.246 2.957 2.956 6.239 5.208 9.244 6.342 2.432.918 4.516.997 5.309.204.158-.158.327-.319.5-.483.89-.846 1.898-1.803 1.855-2.866-.03-.73-.562-1.514-1.58-2.332-3.047-2.447-3.897-1.582-5.31-.152l-.277.28c-.672.675-1.769.527-3.259-.442-1.03-.67-2.305-1.763-3.79-3.246-3.661-3.662-4.833-5.902-3.686-7.05l.276-.273c1.435-1.412 2.3-2.264-.148-5.31-.819-1.018-1.604-1.55-2.333-1.58l-.068-.002z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(7 6.855)"
        />
      </G>
    </Svg>
  );
}
export default SvgComponent;
