import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={24} viewBox="0 0 20 24" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M19.5 1.125v21.75A1.124 1.124 0 0118.375 24H15.75a1.125 1.125 0 01-1.12-1.219l.703-8.676C7.997 8.367 14.508 0 18.375 0A1.125 1.125 0 0119.5 1.125z"
        />
        <Path d="M9.745.713c.038.22.755 4.43.755 6.037 0 2.452-1.303 4.2-3.23 4.903l.605 11.161A1.128 1.128 0 016.75 24h-3a1.13 1.13 0 01-1.125-1.186l.605-11.16C1.298 10.95 0 9.196 0 6.75 0 5.138.717.933.755.713.905-.24 2.878-.253 3 .764v6.619c.06.16.708.15.75 0 .066-1.186.37-6.525.375-6.647.155-.975 2.095-.975 2.245 0 .01.127.31 5.46.375 6.647.042.15.694.16.75 0V.764c.122-1.012 2.1-1.003 2.25-.051z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h19.5v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
