import React, { useContext } from "react";
import Svg, { G, Rect, Polyline, Path, Line } from "react-native-svg";

function SvgComponent({ width, height, fillColor = "transparent" }) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="trash-icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="trash-icon/black/trash-light">
          <Rect fill={fillColor} x="0" y="0" width="40" height="40"></Rect>
          <G
            id="trash-2"
            transform="translate(7.000000, 6.000000)"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <Polyline
              id="trash-Path"
              stroke="#FFFFFF"
              stroke-width="2.016"
              points="0 5.8 2.88888889 5.8 26 5.8"
            ></Polyline>
            <Path
              d="M23.1111111,5.8 L23.1111111,26.1 C23.1111111,27.7016258 21.8177115,29 20.2222222,29 L5.77777778,29 C4.1822885,29 2.88888889,27.7016258 2.88888889,26.1 L2.88888889,5.8 M7.22222222,5.8 L7.22222222,2.9 C7.22222222,1.29837423 8.51562183,0 10.1111111,0 L15.8888889,0 C17.4843782,0 18.7777778,1.29837423 18.7777778,2.9 L18.7777778,5.8"
              id="trash-Shape"
              stroke="#FFFFFF"
              stroke-width="2.016"
              fill="#FFFFFF"
            ></Path>
            <Line
              x1="10.0454545"
              y1="11.4791667"
              x2="10.0454545"
              y2="22.3541667"
              id="trash-Line-4"
              stroke="#7E5C5B"
              stroke-width="1.2"
            ></Line>
            <Line
              x1="15.9545455"
              y1="11.4791667"
              x2="15.9545455"
              y2="22.3541667"
              id="trash-Line-4-Copy"
              stroke="#7E5C5B"
              stroke-width="1.2"
            ></Line>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
