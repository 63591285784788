import React from "react";
import {
  View,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
  Dimensions,
  Pressable,
} from "react-native";
import { useAssets } from "presto-react-components";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import I18n from "i18n-js";
import utils from "../../utils/index";
import _ from "lodash";

export default function BaseItemCard({
  product,
  right,
  hideBottomSection,
  color = "#FD5B53",
  containerStyle = {},
  itemPromotionText,
  modal,
  openSelectionModal,
  success,
  discount,
  oldPriceTextStyle,
  excessQuantityAmount,
  onPress,
}) {
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const { svgs } = useAssets();
  const styles = getStyles();
  const itemPrice = product.price;
  const price = product.totalPrice;
  const lineItem = product.lineItem;
  let refundAmount = 0;
  if (lineItem) {
    let undeliveredAmount = lineItem.undelivered_amount
      ? lineItem.undelivered_amount
      : 0;
    let returnedAmount = lineItem.returned_amount
      ? lineItem.returned_amount / 100
      : 0;
    refundAmount = utils.formattedPrice(undeliveredAmount + returnedAmount);
  }

  let itemDeliveryStatus = null;
  const isPartialDelivery = product.isPartialDelivery;
  const isRefund = lineItem?.refund_request?.status === "completed";
  const isPartialDeliveryAndReturn = isPartialDelivery && isRefund;

  if (
    product.refundStatus === "COMPLETED" ||
    product.returnStatus === "COMPLETED"
  ) {
    let bothCompleted =
      isPartialDeliveryAndReturn &&
      product.refundStatus === "COMPLETED" &&
      product.returnStatus === "COMPLETED";

    if (isPartialDelivery && isRefund) {
      bothCompleted = I18n.t("screen_messages.completed");
    }

    itemDeliveryStatus = bothCompleted
      ? I18n.t("screen_messages.completed")
      : I18n.t("screen_messages.common.initiated");
  } else if (product.refundStatus === null && product.returnStatus === null) {
    itemDeliveryStatus = null;
  } else if (
    product.refundStatus === "INITIATED" ||
    product.returnStatus === "INITIATED"
  ) {
    itemDeliveryStatus = I18n.t("screen_messages.common.initiated");
  } else if (
    product.refundStatus === "REJECTED" ||
    product.returnStatus === "REJECTED"
  ) {
    itemDeliveryStatus = I18n.t("screen_messages.common.rejected");
  }

  let webCardStyle =
    Platform.OS == "web"
      ? {
          borderWidth: 1,
          borderRadius: 5,
        }
      : {};

  return (
    <TouchableWithoutFeedback onPress={() => onPress && onPress()}>
      <View
        style={[
          {
            ...styles.mainContainer,
            flexDirection: "column",
            justifyContent: "flex-start",
            borderBottomWidth: 1,
            borderColor: "#CCCCCC",
            marginVertical: 5,
            ...webCardStyle,
          },
          !isDesktop && {
            borderTopWidth: 0,
            borderRightWidth: 0,
            borderLeftWidth: 0,
          },
          containerStyle,
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomWidth: 1,
            borderBottomColor: "#FAF7F7",
            paddingVertical: 15,
            paddingHorizontal: 20,
          }}
        >
          <View style={{ height: "100%", width: "70%" }}>
            <View style={{ flex: 1 }}>
              <PrestoText
                fontStyle="medium"
                color="#333333"
                extraStyle={{ width: "100%" }}
              >
                {product.name}
              </PrestoText>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  lineHeight: 16,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <PrestoText fontStyle="medium" color="#333333">
                  {itemPrice}
                </PrestoText>

                <PrestoText
                  fontStyle="medium"
                  color="#666666"
                  extraStyle={{
                    paddingLeft: 15,
                    textDecorationLine: "line-through",
                    ...oldPriceTextStyle,
                  }}
                >
                  {product.originalPrice}
                </PrestoText>
              </View>
              {product?.issues ? (
                <PrestoText fontStyle="medium" color={color} numberOfLines={2}>
                  {product.issues?.message}{" "}
                  {product.oldCartPrice ? "from " + product.oldCartPrice : ""}
                </PrestoText>
              ) : null}
              {product?.comment ? (
                <PrestoText
                  fontStyle="medium"
                  color={"#999999"}
                  numberOfLines={2}
                >
                  Reason {" : "} {product?.comment}
                </PrestoText>
              ) : null}
              {itemDeliveryStatus ? (
                <PrestoText
                  fontStyle="medium"
                  color={"#999999"}
                  numberOfLines={1}
                  extraStyle={{ paddingTop: 5 }}
                >
                  {I18n.t("screen_messages.refunds.status_with_colon")}
                  <PrestoText
                    fontStyle="medium"
                    color={
                      itemDeliveryStatus == I18n.t("screen_messages.completed")
                        ? "green"
                        : "#999999"
                    }
                  >
                    {itemDeliveryStatus}
                  </PrestoText>
                </PrestoText>
              ) : null}
            </View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            ></View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              {product.offer ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PrestoIcon icon={<svgs.OfferIcon />} />
                  <PrestoText fontStyle="medium" color="#303AB6">
                    {I18n.t("screen_messages.off_text", {
                      offer: product.offer,
                    })}
                  </PrestoText>
                </View>
              ) : null}

              {product.savings ? (
                <View style={{ lineHeight: 14, marginTop: 10 }}>
                  <PrestoText size={12} fontStyle="medium" color="#00AF62">
                    {product.savings}
                  </PrestoText>
                </View>
              ) : null}
            </View>
            {modal ? (
              <Pressable
                onPress={() => openSelectionModal()}
                style={{
                  alignItems: "flex-start",
                  justifyContent: "center",
                  height: 35,
                  width: "80%",
                  color: "blue",
                }}
              >
                <PrestoText
                  size={14}
                  fontStyle="medium"
                  color={"blue"}
                  extraStyle={{
                    textDecorationLine: "underline",
                  }}
                >
                  {I18n.t("screen_messages.cart.choose_free_item")}
                </PrestoText>
              </Pressable>
            ) : null}
            {discount ? (
              <PrestoText
                size={13}
                fontStyle="medium"
                color={"#32a664"}
                extraStyle={{ paddingBottom: 8 }}
              >
                {"You save " + discount}
              </PrestoText>
            ) : null}
            {itemPromotionText ? (
              <>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: 35,
                    width: "85%",
                    borderWidth: 1,
                    backgroundColor: success ? "#D0FFC9" : "lightyellow",
                    borderColor: success ? "#32a664" : "#cf8a45",
                    borderRadius: 8,
                  }}
                >
                  <PrestoText
                    size={12}
                    fontStyle="medium"
                    color={success ? "#06ba4e" : "#cf8a45"}
                  >
                    {itemPromotionText}
                  </PrestoText>
                </View>
              </>
            ) : null}
          </View>

          <View
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              width: 100,
            }}
          >
            <View style={{ ...styles.imgContainer }}>
              <Image
                source={{ uri: product.picURL }}
                style={{
                  ...styles.imgStyle,
                  width: 100,
                  height: 100,
                  borderRadius: 8,
                }}
                resizeMode="cover"
              />
            </View>
          </View>
        </View>

        {hideBottomSection ? (
          <View style={{ width: "100%", height: 30 }} />
        ) : (
          <View
            style={{
              height: 70,
              flexDirection: "row",
              justifyContent: "space-between",
              paddingVertical: 15,
              paddingHorizontal: 20,
            }}
          >
            <View style={{}}>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {product.quantity ? (
                  <>
                    <PrestoText
                      size={14}
                      fontStyle="medium"
                      color="#999999"
                      extraStyle={{
                        paddingRight: 5,
                        lineHeight: 16,
                        marginBottom: 8,
                      }}
                    >
                      {I18n.t("screen_messages.quantity_text")}
                      <PrestoText fontStyle="medium" color="#333333">
                        {" : "} {product.quantity}
                      </PrestoText>
                    </PrestoText>
                  </>
                ) : (
                  <PrestoText
                    size={12}
                    fontStyle="medium"
                    color="#999999"
                    extraStyle={{
                      paddingRight: 5,
                      lineHeight: 16,
                      marginBottom: 8,
                    }}
                  />
                )}

                {price !== "0" ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PrestoText
                      fontStyle="medium"
                      color="#999999"
                      extraStyle={{ lineHeight: 16 }}
                    >
                      {I18n.t("screen_messages.price")}
                      <PrestoText size={14} fontStyle="medium" color="#666666">
                        {" : "}
                        {price}
                      </PrestoText>
                    </PrestoText>
                  </View>
                ) : null}
              </View>
            </View>
            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              {product?.orderSubStatus === "PARTIAL_DELIVERY" ||
              itemDeliveryStatus !== null ||
              excessQuantityAmount ? (
                <>
                  <PrestoText
                    size={14}
                    fontStyle="medium"
                    color="#999999"
                    extraStyle={{
                      paddingRight: 5,
                      lineHeight: 16,
                      marginBottom: 8,
                    }}
                  >
                    Delivered {" : "}
                    <PrestoText fontStyle="medium" color="#333333">
                      {product.deliveredQuantity}
                    </PrestoText>
                  </PrestoText>

                  {product.refundRequestAvailable ? (
                    <PrestoText
                      size={14}
                      fontStyle="medium"
                      color="#999999"
                      extraStyle={{
                        paddingRight: 5,
                        lineHeight: 16,
                        marginBottom: 8,
                      }}
                    >
                      Refund {" : "}
                      <PrestoText fontStyle="medium" color="#333333">
                        {refundAmount}
                      </PrestoText>
                    </PrestoText>
                  ) : null}
                </>
              ) : (
                <View
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  {right}
                </View>
              )}
            </View>
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}

function getStyles() {
  return StyleSheet.create({
    mainContainer: {
      width: "100%",
      minHeight: 160,
      position: "relative",
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#CCCCCC",
    },

    imgContainer: {
      alignItems: "center",
    },

    imgStyle: {
      width: 100,
      height: 100,
      borderRadius: 8,
    },

    offerImgStyle: {
      width: 16,
      height: 16,
      marginRight: 5,
    },
  });
}
