import ErrorConstants from "./ErrorConstants";
import PrestoSdk from "../global/PrestoSdk";
import _ from "lodash";
import moment from "moment";
import * as geolib from "geolib";
import { Platform } from "react-native";
import * as Location from "expo-location";
import * as Permissions from "expo-permissions";
import Geolocation from "react-native-geolocation-service";
import I18n from "i18n-js";

const Utils = function (ErrorConstants, PrestoSdk) {
  var appConfig = PrestoSdk.getAppConfig();
  /**
   * @ngdoc
   * @name Common.Utils#ErrorObject
   * @methodOf Common.Utils
   * @description Method to get error object.
   * @param {string} errorCode - code defined in ErrorConstants
   * @param {boolean} success - true/false
   * @returns {ErrorObject} - error object containing success/fail status, error code and error message
   */
  function ErrorObject(errorCode, success) {
    var errorObject = {};
    errorObject.success = success;
    errorObject.code = errorCode;
    errorObject.message = ErrorConstants.ErrorMessages[errorCode];
    return errorObject;
  }
  /**
   * @ngdoc
   * @name Common.Utils#isValidEmail
   * @methodOf Common.Utils
   * @description Method to check validity of email via regex
   * @param {string} Email to be validated
   * @returns {boolean} true if email is valid, otherwise false
   */
  function isValidEmail(email) {
    console.log("isValidEmail(), email=" + email);
    var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email) {
      return emailPattern.test(email);
    } else {
      return false;
    }
  }
  /**
   * @ngdoc
   * @name Common.Utils#isValidPhoneNumber
   * @methodOf Common.Utils
   * @description Method to check validity of phone number. Phone number is considered
   * to be valid if contains only digits
   * @param {string} Phone number to be validated
   * @returns {boolean} true if phone number is valid, otherwise false
   */
  function isValidPhoneNumber(phoneNumber) {
    console.log("isValidPhoneNumber(), phoneNumber=" + phoneNumber);
    var digitsPattern = /^\d+$/;

    if (phoneNumber) {
      return digitsPattern.test(phoneNumber); // returns a boolean
    } else {
      return false;
    }
  }
  /**
   * @ngdoc
   * @name Common.Utils#isValidPassword
   * @methodOf Common.Utils
   * @description Method to check validity of password. Password is valid
   * if it's length is between 6 to 20 chars
   * @param {string} Password to be validated
   * @returns {boolean} true if password is valid, otherwise false
   */
  function isValidPassword(password) {
    console.log("isValidPassword(), password=" + password);
    if (
      appConfig &&
      appConfig.merchant_config.features.do_not_validate_password
    ) {
      return true;
    } else {
      if (password && password.length >= 6 && password.length <= 20) {
        return true;
      } else {
        return false;
      }
    }
  }
  /**
   * @ngdoc
   * @name Common.Utils#instanceOf
   * @methodOf Common.Utils
   * @description Method to check whether a object is an instance of particular data type
   * @param {object} Object whose type to be validated
   * @param {constructor} Constructor to match it's type with that of object's type
   * @returns {boolean} true if object is of constructor's type, otherwise false
   */
  function instanceOf(object, constructor) {
    return object instanceof constructor;
  }
  /**
   * @ngdoc
   * @name Common.Utils#isEmpty
   * @methodOf Common.Utils
   * @description Method to check whether a string object is empty or not
   * @param {string} string to be validated
   * @returns {boolean} true if string object is empty otherwise false
   */
  function isEmpty(stringObj) {
    //console.log("isEmpty(),stringObj=" + stringObj);
    if (!stringObj || null == stringObj || "" == stringObj) {
      return true;
    } else {
      return false;
    }
  }

  function isSameDate(date1, date2) {
    if (
      date1.getDate() == date2.getDate() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getYear() == date2.getYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  function sortOutletsByDistance(outlets, coords, configuredDistance = 100000) {
    if (coords && outlets) {
      console.log(outlets);
      console.log(coords);
      var sortedOutlets = geolib.orderByDistance(
        {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
        outlets
      );
      for (var i = 0; i < sortedOutlets.length; i++) {
        sortedOutlets[i].distance =
          geolib.getDistance(sortedOutlets[i], coords) / 1000;
        console.log(
          "Delivery radius : ",
          sortedOutlets[i].delivery_radius,
          sortedOutlets[i].distance
        );
      }
      // let sorted = sortedOutlets.filter(
      //   (outlet) => outlet.distance < configuredDistance
      // );
      // console.log(sortedOutlets);
      return sortedOutlets;
    } else {
      return outlets;
    }
  }

  function convertRupeeToPaisa(rupee) {
    return Math.round(rupee * 100);
  }

  function isOfTypeString(obj) {
    if (obj && (typeof obj === "string" || obj instanceof String)) {
      return true;
    } else {
      return false;
    }
  }

  function updateErrorObject(errorObject) {
    if (errorObject) {
      if (errorObject.data) {
        errorObject = errorObject.data;
      }
      var customMsg = "";
      if (appConfig && appConfig.merchant_config.error_messages) {
        customMsg = appConfig.merchant_config.error_messages[errorObject.code];
      }
      if (isEmpty(customMsg)) {
        if (isEmpty(errorObject.message)) {
          customMsg = errorObject.error;
        } else {
          customMsg = errorObject.message;
        }
      }
      errorObject.message = customMsg;
    } else {
      errorObject = ErrorObject(ErrorConstants.ErrorCodes.UNKNOWN_ERROR, false);
    }
    errorObject.success = false;
    return errorObject;
  }

  function getSuccessMessage(event) {
    var eventMsg = "";
    if (appConfig && appConfig.merchant_config.success_messages) {
      var customSuccessMessages = appConfig.merchant_config.success_messages;
      if (customSuccessMessages) {
        eventMsg = customSuccessMessages[event];
      }
    }
    if (isEmpty(eventMsg)) {
      eventMsg = ErrorConstants.SuccessMessages[event];
    }
    return eventMsg;
  }

  function getDisplayPrice(
    basePrice,
    priceMultipler,
    displayQuantity,
    displayName
  ) {
    return (
      basePrice * priceMultipler + " / " + displayQuantity + " " + displayName
    );
  }

  function getDisplayCount(
    count,
    displayQuantity,
    priceMultipler,
    displayName
  ) {
    return (count * displayQuantity) / priceMultipler + " " + displayName;
  }
  function getDistance(outlet, coords) {
    if (coords && outlet) {
      var distance = geolib.getDistance(
        {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
        {
          latitude: outlet.latitude,
          longitude: outlet.longitude,
        }
      );
      return distance;
    } else {
      return -1;
    }
  }
  function receivedDrivingDistance(
    dResponse,
    outlet,
    outletsWithDistance,
    outlets,
    callback
  ) {
    if (dResponse.rows[0].elements[0].status == "ZERO_RESULTS") {
      callback(outletsWithDistance);
    } else {
      var distance = dResponse.rows[0].elements[0].distance.value;
      var distanceText = dResponse.rows[0].elements[0].distance.text;
      outlet.distance = distance;
      outlet.distanceText = distanceText;
      outletsWithDistance.push(outlet);
      if (outlets.length == outletsWithDistance.length) {
        outletsWithDistance.sort(function (a, b) {
          if (a.distance < b.distance) {
            return -1;
          } else if (a.distance == b.distance) {
            return 0;
          } else {
            return 1;
          }
        });
        callback(outletsWithDistance);
      }
    }
    // return outlet
  }

  function getDrivingDistance(outlet, coords, callback) {
    if (coords && outlet) {
      var origin = new google.maps.LatLng(
        outlet.address.location[0],
        outlet.address.location[1]
      );
      var destination = new google.maps.LatLng(
        coords.latitude,
        coords.longitude
      );

      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: "DRIVING",
        },
        dCallback
      );

      function dCallback(response) {
        console.log(JSON.stringify(response));
        return callback(response.rows[0].elements[0]);
      }
    } else {
      return -1;
    }
  }

  function sortOutletsByDrivingDistance(outlets, coords, callback) {
    // console.log("coordinates=" + JSON.stringify(coords));
    // console.log(outlets);
    if (Platform.OS == "web") {
    } else {
    }
    var outletsWithDistance = [];
    if (coords && outlets) {
      outlets.map((outlet) => {
        var origin = new google.maps.LatLng(
          outlet.address.location[0],
          outlet.address.location[1]
        );
        var destination = new google.maps.LatLng(
          coords.latitude,
          coords.longitude
        );

        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
          },
          dCallback
        );

        function dCallback(response) {
          // console.log(response);
          // console.log("status",status);
          if (response != null && response.rows.length > 0)
            receivedDrivingDistance(
              response,
              outlet,
              outletsWithDistance,
              outlets,
              callback,
              coords
            );
        }
      });
    } else {
      return outlets;
    }
  }

  function setDrivingDistance(
    dResponse,
    originItem,
    originItemWithDistance,
    originArry,
    callback
  ) {
    if (dResponse.rows[0].elements[0].status == "ZERO_RESULTS") {
      callback(originItemWithDistance);
    } else {
      var distance = dResponse.rows[0].elements[0].distance.value;
      var distanceText = dResponse.rows[0].elements[0].distance.text;
      originItem.distance = distance;
      originItem.distanceText = distanceText;
      originItemWithDistance.push(originItem);
      if (originArry.length == originItemWithDistance.length) {
        originItemWithDistance.sort(function (a, b) {
          if (a.distance < b.distance) {
            return -1;
          } else if (a.distance == b.distance) {
            return 0;
          } else {
            return 1;
          }
        });
        callback(originItemWithDistance);
      }
    }
    // return outlet
  }
  function getDistanceOfItems(originArry, coords, callback) {
    var originItemWithDistance = [];
    if (coords && originArry.length > 0) {
      originArry.map((originItem) => {
        var origin = new google.maps.LatLng(
          originItem.location[0],
          originItem.location[1]
        );

        var destination = new google.maps.LatLng(
          coords.latitude,
          coords.longitude
        );
        console.log("destination", destination);

        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
          },
          dCallback
        );

        function dCallback(response) {
          // console.log(response);
          if (response != null && response.rows.length > 0)
            setDrivingDistance(
              response,
              originItem,
              originItemWithDistance,
              originArry,
              callback,
              coords
            );
        }
      });
    } else {
      return originArry;
    }
  }

  function getDeliverableAddresses(addresses, outlets, callback) {
    let dAddresses = [];
    let promises = _.map(addresses, (address) => {
      return new Promise((resolve) => {
        var coords = {};
        coords.latitude = parseFloat(address.location[0]);
        coords.longitude = parseFloat(address.location[1]);
        var sortedOutletsByArialDistance = sortOutletsByDistance(
          outlets,
          coords
        );
        sortedOutletsByArialDistance = sortedOutletsByArialDistance.filter(
          function (outlet) {
            return outlet.delivery_radius >= outlet.distance / 1000;
          }
        );
        sortOutletsByDrivingDistance(
          sortedOutletsByArialDistance,
          coords,
          function (sortedOutlets) {
            sortedOutlets = sortedOutlets.filter(function (outlet) {
              return outlet.delivery_radius >= outlet.distance / 1000;
            });
            // console.log("sortedOutlets",sortedOutlets);
            if (sortedOutlets.length > 0) {
              dAddresses.push(address);
              callback(address);
              resolve(dAddresses);
            } else {
              resolve();
            }
          }
        );
      });
    });

    Promise.all(promises)
      .then(() => {
        // console.log("dAddresses",dAddresses);
        callback(null, true);
      })
      .catch(() => {});
  }

  function calculateDistanceWithOutlet(address, outlets) {
    return new Promise((resolve) => {
      var coords = {};
      coords.latitude = address.location[0];
      coords.longitude = address.location[1];
      // console.log("outlets----",outlets);
      sortOutletsByDrivingDistance(outlets, coords, async function (
        sortedOutlets
      ) {
        sortedOutlets = sortedOutlets.filter(function (outlet) {
          return outlet.delivery_radius >= outlet.distance / 1000;
        });
        console.log("sortedOutlets", sortedOutlets);
        if (sortedOutlets.length > 0) {
          // callback(address)
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  function numberInCart(item, cart) {
    const variationId = item.variation ? item.variation.id : null;
    // console.log("variationId",variationId );
    let itemId = cart.getCartItemId(item.id, variationId);
    const cartItem = cart.getItem(itemId);
    return cartItem ? cartItem.count : 0;
  }
  function addToCart(quantity, item, variation, cart, setCartItemCount) {
    let itemId = cart.getCartItemId(item.id, variation && variation.id);
    const cartItem = cart.getItem(itemId);
    if (cartItem) {
      if (quantity === 0) {
        cart.updateItem(itemId, -cartItem.count, true);
        cart.removeItem(itemId);
      } else {
        cart.updateItem(itemId, quantity - cartItem.count, true);
      }
    } else {
      let newCartItem = cart.createNewItem(
        itemId,
        item,
        variation,
        item.variations
      );
      cart.addNewItem(itemId, newCartItem);
      cart.updateItem(itemId, 1);
    }
    setCartItemCount();
    return cart;
  }

  function createCartItem(
    cartItemId,
    product,
    innermostVariation,
    CartService
  ) {
    if (innermostVariation) {
      cartItemId = CartService.getCartItemId(product.id, innermostVariation.id);
    } else {
      cartItemId = CartService.getCartItemId(product.id, undefined);
    }
    let cartItem = CartService.getItem(cartItemId);
    if (!cartItem) {
      cartItem = CartService.createNewItem(
        cartItemId,
        product,
        innermostVariation
      );
    }
    return cartItem;
  }

  function addItemToCartThroughItems(items, CartService, successCallBack) {
    CartService.clearCart();
    var cartItemId;
    var cartItem;
    if (items) {
      for (var itemId in items) {
        if (
          items.hasOwnProperty(itemId) &&
          items[itemId].reference_id != "4417"
        ) {
          console.log("Cart item");
          console.log("--------");
          // console.log(items[itemId]);
          if (
            items[itemId].other_data.user_data &&
            items[itemId].other_data.user_data.added_toppings
          ) {
            items[itemId].other_data.added_toppings =
              items[itemId].other_data.user_data.added_toppings;
          }
          console.log(items[itemId]);

          if (!items[itemId].variation) {
            items[itemId].price = {};
            items[itemId].price.total_price = items[itemId].total_price;
            items[itemId].price.base_price = items[itemId].total_price;
            items[itemId].price.tax_percentage = items[itemId].tax_percentage;
            items[itemId].price.shipping_charge = 0;
            items[itemId].price.packing_charge = 0;
          } else {
            items[itemId].price = {};
            items[itemId].price.total_price = items[itemId].total_price;
            items[itemId].price.base_price = items[itemId].total_price;
            items[itemId].price.tax_percentage = items[itemId].tax_percentage;
            items[itemId].price.shipping_charge = 0;
            items[itemId].price.packing_charge = 0;
            items[itemId].variation.price = {};
            if (!items[itemId].variation.total_price) {
              items[itemId].variation.price.total_price =
                items[itemId].total_price;
              items[itemId].variation.price.base_price =
                items[itemId].total_price;
              items[itemId].variation.price.tax_percentage =
                items[itemId].tax_percentage;
            }
            items[itemId].variation.price.shipping_charge = 0;
            items[itemId].variation.price.packing_charge = 0;
            // console.log("Variation");
            // console.log("-------")
            // console.log(items[itemId].variation);
          }
          items[itemId].item_id = items[itemId].item_id;
          items[itemId].id = items[itemId].item_id;
          if (!cartItem && items[itemId].variation) {
            cartItem = createCartItem(
              cartItemId,
              items[itemId],
              items[itemId].variation,
              CartService
            );
            //cartItem = CartService.createNewItem(cartItemId, items[itemId]);
          } else if (!cartItem && !items[itemId].variation) {
            cartItemId = CartService.getCartItemId(
              items[itemId].item_id,
              undefined
            );
            cartItem = CartService.getItem(cartItemId);
            cartItem = CartService.createNewItem(cartItemId, items[itemId]);
          } else if (cartItem && items[itemId].variation) {
            cartItem = createCartItem(
              cartItemId,
              items[itemId],
              items[itemId].variation,
              CartService
            );
          }
          if (!items[itemId].other_data.user_data.default_item_id) {
            console.log("Cart item");
            console.log("--------");
            console.log(cartItem);
            cartItem.other_data = {};
            cartItem.other_data["count"] =
              items[itemId].other_data.user_data.count;
            cartItem.other_data["added_toppings"] =
              items[itemId].other_data.user_data.added_toppings;
            cartItem.other_data["total_price"] =
              items[itemId].other_data.user_data.total_price;
          } else if (items[itemId].other_data.user_data.default_item_id) {
            cartItem.other_data = {};
            cartItem.other_data["default_item_id"] =
              items[itemId].other_data.user_data.default_item_id;
          }
          if (!CartService.getItem(cartItem.itemId)) {
            CartService.addNewItem(cartItem.itemId, cartItem);
          }
          CartService.updateItem(cartItem.itemId, 1);
        }
      }
      //console.log(CartService.getItems());
      successCallBack();
    }
  }

  /**
   * @ngdoc
   * @name Common.Utils#getDateTimeDifference
   * @methodOf Common.Utils
   * @description returns time in milliseconds
   * @param {string} date1 and date2 to be date object
   * @returns {int} milliseconds
   */
  function getDateTimeDifference(date1, date2) {
    let diff = 0;
    try {
      diff = Math.abs(date1.getTime() - date2.getTime());
    } catch (error) {
      console.log("getDateTimeDifference error :>> ", error);
    }

    return diff;
  }

  /**
   * @ngdoc
   * @name Common.Utils#getDateTimeDifferenceInHours
   * @methodOf Common.Utils
   * @description returns time in hours
   * @param {string} date1 and date2 to be date object
   * @returns {int} hours
   */
  function getDateTimeDifferenceInHours(date1, date2) {
    return getDateTimeDifference(date1, date2) / 1000 / 3600;
  }

  /**
   * @ngdoc
   * @name Common.Utils#getDateTimeDifferenceInMinutes
   * @methodOf Common.Utils
   * @description returns time in minutes
   * @param {string} date1 and date2 to be date object
   * @returns {int} minutes
   */
  function getDateTimeDifferenceInMinutes(date1, date2) {
    return Math.floor(getDateTimeDifference(date1, date2) / 1000 / 60);
  }

  /**
   * @ngdoc
   * @name Common.Utils#getDateTimeDifferenceInHoursAndMinutes
   * @methodOf Common.Utils
   * @description returns time in hours and minutes
   * @param {string} date1 and date2 to be date object
   * @returns {string} hours and minutes
   */
  function getDateTimeDifferenceInHoursAndMinutes(date1, date2) {
    let mins = getDateTimeDifferenceInMinutes(date1, date2);
    mins = mins.toString().replace(".", ":");
    return mins;
  }

  /**
   * @ngdoc
   * @name Common.Utils#formateMinutesToHours
   * @methodOf Common.Utils
   * @description returns time in hours and minutes
   * @param {string} int minutes
   * @returns {string} hours and minutes
   */
  function formateMinutesToHours(minutes) {
    let d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMinutes(minutes);

    let date_string = `${d.getHours() > 0 ? `${d.getHours()}h` : ``} ${
      d.getMinutes() > 0 ? `${d.getMinutes()} mins` : ``
    }`;

    return date_string;
  }

  /**
   * @ngdoc
   * @name Common.Utils#isInteger
   * @methodOf Common.Utils
   * @description returns boolean wheather a number is int or not
   * @param {string} int num
   * @returns {string} boolean value
   */
  function isInteger(num) {
    let isInt = false;

    if (!containsAlphabetsAndSpecialCharacters(num)) {
      let toInt = parseInt(num);
      isInt = Number.isInteger(toInt);
    }

    return isInt;
  }

  /**
   * @ngdoc
   * @name Common.Utils#containsAlphabetsAndSpecialCharacters
   * @methodOf Common.Utils
   * @description returns boolean wheather value has special characters or Alphabets
   * @param {string} string str
   * @returns {string} boolean value
   */
  function containsAlphabetsAndSpecialCharacters(str) {
    const format = /[ A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let contains = format.test(str);
    return contains;
  }

  /**
   * @ngdoc
   * @name Common.Utils#getSearchType
   * @methodOf Common.Utils
   * @description returns type for the column to be searched
   * @param {string} string text
   * @returns {string} string type
   */
  function getSearchType(text) {
    let type = "name";
    if (isValidEmail(text)) {
      type = "email";
    } else if (isValidPhoneNumber(text)) {
      type = "phone_number";
    }
    return type;
  }

  /**
   * @ngdoc
   * @name Common.Utils#sortItemsByDate
   * @methodOf Common.Utils
   * @description returns sorted array
   * @param {array} array list,
   * @param {sortKey} string sortKey,
   * @param {sortOrder} string sortOrder,
   * @returns {array} array sortedArray
   */
  function sortItemsByDate(list, sortKey, sortOrder = "ASC") {
    return list?.length
      ? list.sort((a, b) => {
          let date1 = new Date(a[sortKey]);
          let date2 = new Date(b[sortKey]);
          let compare = sortOrder == "ASC" ? date1 <= date2 : date1 >= date2;
          return compare ? -1 : 0;
        })
      : [];
  }

  /**
   * @ngdoc
   * @name Common.Utils#isBirthDayToday
   * @methodOf Common.Utils
   * @description returns true if today is birthday
   * @param {string} string date_string
   * @returns {boolean} boolean sortedArray
   */
  function isBirthDayToday(date_string) {
    var given_date = moment(date_string);
    var today = moment();

    return (
      given_date.date() === today.date() && given_date.month() === today.month()
    );
  }

  /**
   * @ngdoc
   * @name Common.Utils#weekStartAndEndDate
   * @methodOf Common.Utils
   * @description returns startDate and endDate of give date object
   * @param {string} Date Object date
   * @returns {boolean} boolean startDate, endDate
   */
  function weekStartAndEndDate(dateObject = new Date()) {
    const newDate = moment(dateObject.toISOString());
    const startDate = newDate.clone().day(0);
    const endDate = newDate.clone().day(6);
    return {
      startDate,
      endDate,
    };
  }

  /**
   * @ngdoc
   * @name Common.Utils#getWeekdaysList
   * @methodOf Common.Utils
   * @description returns array of weekday object
   * @returns {boolean} array  weekdays list
   */
  function getWeekdaysList() {
    return _.map(moment.weekdays(), (weekDay, index) => {
      return {
        fullName: weekDay,
        dayIndex: index,
        shortName: moment.weekdaysShort(index),
      };
    });
  }

  /**
   * @ngdoc
   * @name Common.Utils#getWeekdaysList
   * @methodOf Common.Utils
   * @description returns array of weekday object
   * @returns {boolean} array  weekdays list
   */
  function getWeekdaysList() {
    return _.map(moment.weekdays(), (weekDay, index) => {
      return {
        fullName: weekDay,
        dayIndex: index,
        shortName: moment.weekdaysShort(index),
      };
    });
  }

  /**
   * @ngdoc
   * @name Common.Utils#resetDateExcludingTime
   * @methodOf Common.Utils
   * @param {date} dateObject
   * @description returns date object resetting the date, month, year and millisecond
   * @returns {date} dateObject
   */
  function resetDateExcludingTime(dateObject) {
    let newDateObject = _.cloneDeep(dateObject);
    const now = new Date();
    newDateObject.setFullYear(now.getFullYear(), 1, 1);
    newDateObject.setMilliseconds(0);

    return newDateObject;
  }

  /**
   * @ngdoc
   * @name Common.Utils#generateReducerMethod
   * @methodOf Common.Utils
   * @param {string} reducerName
   * @description returns functions for reducer
   * @returns {function} function
   */
  function generateReducerMethod(dispatch) {
    return (reducerName) => {
      return function (val) {
        dispatch({
          type: reducerName,
          payload: val,
        });
      };
    };
  }

  /**
   * @ngdoc
   * @name Common.Utils#getTimeFormatted
   * @methodOf Common.Utils
   * @param {string} date_string
   * @description returns time in HH:mm:ss AM/PM format
   * @returns {string} time
   */
  function getTimeFormatted(date_string, format = "hh:mm A") {
    let momentDate = moment(date_string);

    return momentDate.format(format);
  }

  /**
   * @ngdoc
   * @name Common.Utils#toTitleCase
   * @methodOf Common.Utils
   * @param {string} text
   * @description returns text in title case
   * @returns {string} text
   */
  function toTitleCase(text = "") {
    return _.startCase(_.toLower(text));
  }

  /**
   * @ngdoc
   * @name Common.Utils#decodeUrl
   * @methodOf Common.Utils
   * @param {string} input
   * @description returns uri decoded input
   * @returns {string} uri decoded input
   */
  function decodeUrl(input) {
    try {
      return decodeURIComponent(input);
    } catch (e) {
      return null;
    }
  }

  /**
   * @ngdoc
   * @name Common.Utils#querystringParser
   * @methodOf Common.Utils
   * @param {string} query
   * @description returns object result
   * @returns {string} uri result
   */
  function queryStringParser(query) {
    var parser = /([^=?#&]+)=?([^&]*)/g,
      result = {},
      part;

    while ((part = parser.exec(query))) {
      var key = decodeUrl(part[1]),
        value = decodeUrl(part[2]);

      if (key === null || value === null || key in result) continue;
      result[key] = value;
    }

    return result;
  }

  /**
   * @ngdoc
   * @name Common.Utils#getPercentage
   * @methodOf Common.Utils
   * @param {number} total, percentage, decimalPoint=0
   * @description returns number val for the percentage
   * @returns {float} val
   */
  function getPercentage(total, percantage, decimalPoint = 0) {
    let val = total > 0 ? (parseFloat(total) * percantage) / 100 : 0;

    return Number(parseFloat(val).toFixed(decimalPoint));
  }

  /**
   * @ngdoc
   * @name Common.Utils#convertSqMtrToGuntha
   * @methodOf Common.Utils
   * @param {number} sqMtr
   * @description returns guntha the calculation is 1 guntha == 101.17 mtrs
   * @returns {float} guntha
   */
  function convertSqMtrToGuntha(sqMtr) {
    let num = Number(sqMtr);
    let perGunthaForSqMtrValue = 101.17;
    return num > 0 ? num / perGunthaForSqMtrValue : 0;
  }

  /**
   * @ngdoc
   * @name Common.Utils#convertGunthaToSqMtr
   * @methodOf Common.Utils
   * @param {number} guntha
   * @description returns sqmtr
   * @returns {float} sqmtr
   */
  function convertGunthaToSqMtr(guntha) {
    let num = Number(guntha);
    let perGunthaForSqMtrValue = 101.17;
    return num > 0 ? num * perGunthaForSqMtrValue : 0;
  }

  /**
   * @ngdoc
   * @name Common.Utils#getLocationInfo
   * @methodOf Common.Utils
   * @description checks if location permission provided in background and then returns latitude and longitude
   * @returns {string, string} latitude, longitude
   */
  async function getLocationInfo({ onSuccess, onError }) {
    const isEnabled = await Location.hasServicesEnabledAsync();
    if (isEnabled) {
      let permissionInfo = await Permissions.getAsync(Permissions.LOCATION);
      if (permissionInfo.status === "undetermined") {
        permissionInfo = await Location.requestPermissionsAsync();
      }

      if (permissionInfo && permissionInfo.status === "granted") {
        Geolocation.getCurrentPosition(
          (position) => {
            if (onSuccess) {
              onSuccess(position);
            }
          },
          (error) => {
            console.log("getLocationInfo error", error);
            if (onError) {
              onError(error);
            }
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
      }
    } else {
      const message = I18n.t("error_messages.location.no_permission");
      const error = new Error(message);
      onError(error);
    }
  }

  /**
   * @ngdoc
   * @name Common.Utils#validateManyFields
   * @methodOf Common.Utils
   * @param {object} validateFields
   * @description validates the object and returns the error message, used for many fields to validate
   * @returns {object, number} errorBag, errorCount
   */
  function validateManyFields(validateFields) {
    let errorBag = {};
    let errorCount = 0;
    for (let fieldName in validateFields) {
      errorBag[fieldName] = [];
      let field = validateFields[fieldName];
      let value = field.value;

      if (
        field.required &&
        (value === null || value === undefined || value === "")
      ) {
        errorBag[fieldName].push(field.requiredMsg);
        errorCount++;
      }
    }

    errorBag = _.filter(errorBag, (errorObject) => !_.isEmpty(errorObject));

    return { errorBag, errorCount };
  }

  return {
    ErrorObject: ErrorObject,
    isValidPassword: isValidPassword,
    isValidEmail: isValidEmail,
    isValidPhoneNumber: isValidPhoneNumber,
    isEmpty: isEmpty,
    instanceOf: instanceOf,
    isSameDate: isSameDate,
    sortOutletsByDistance: sortOutletsByDistance,
    convertRupeeToPaisa: convertRupeeToPaisa,
    isOfTypeString: isOfTypeString,
    updateErrorObject: updateErrorObject,
    getSuccessMessage: getSuccessMessage,
    getDisplayPrice: getDisplayPrice,
    getDisplayCount: getDisplayCount,
    numberInCart: numberInCart,
    addToCart: addToCart,
    sortOutletsByDrivingDistance: sortOutletsByDrivingDistance,
    getDrivingDistance: getDrivingDistance,
    getDistance: getDistance,
    getDistanceOfItems: getDistanceOfItems,
    addItemToCartThroughItems: addItemToCartThroughItems,
    getDeliverableAddresses: getDeliverableAddresses,
    getDateTimeDifference: getDateTimeDifference,
    getDateTimeDifferenceInHours: getDateTimeDifferenceInHours,
    getDateTimeDifferenceInMinutes: getDateTimeDifferenceInMinutes,
    getDateTimeDifferenceInHoursAndMinutes: getDateTimeDifferenceInHoursAndMinutes,
    formateMinutesToHours: formateMinutesToHours,
    isInteger: isInteger,
    containsAlphabetsAndSpecialCharacters: containsAlphabetsAndSpecialCharacters,
    getSearchType: getSearchType,
    sortItemsByDate: sortItemsByDate,
    isBirthDayToday: isBirthDayToday,
    weekStartAndEndDate: weekStartAndEndDate,
    getWeekdaysList: getWeekdaysList,
    resetDateExcludingTime: resetDateExcludingTime,
    generateReducerMethod: generateReducerMethod,
    getTimeFormatted: getTimeFormatted,
    toTitleCase: toTitleCase,
    decodeUrl: decodeUrl,
    queryStringParser: queryStringParser,
    getPercentage: getPercentage,
    convertSqMtrToGuntha: convertSqMtrToGuntha,
    convertGunthaToSqMtr: convertGunthaToSqMtr,
    getLocationInfo: getLocationInfo,
    validateManyFields: validateManyFields,
  };
};

export default Utils(ErrorConstants, PrestoSdk);
