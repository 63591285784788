import * as React from "react";

import Svg, { Path } from "react-native-svg";

function HamburgerIcon(props) {
  const color = props?.color || "#333";
  return (
    <Svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M23.227 22c.427 0 .773.336.773.75s-.346.75-.773.75H7.773A.762.762 0 017 22.75c0-.414.346-.75.773-.75zm0-6.75c.427 0 .773.336.773.75s-.346.75-.773.75H7.773A.762.762 0 017 16c0-.414.346-.75.773-.75zm0-6.75c.427 0 .773.336.773.75s-.346.75-.773.75H7.773A.762.762 0 017 9.25c0-.414.346-.75.773-.75z"
        fill={color}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default HamburgerIcon;
