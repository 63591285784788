import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function RightIcon(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 35 35"
      {...props}
    >
      <Path
        stroke={props.color || "#000"}
        transform="rotate(-90 16 16)"
        strokeWidth={props.strokeWidth || 2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 12L15.9153844 20 25 12"
      />
    </Svg>
  );
}

export default RightIcon;
