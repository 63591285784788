import * as React from "react";
import Svg, { Path } from "react-native-svg";

function UserIconFilled(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M0 29v-3.625c0-3.988 6.525-7.25 14.5-7.25S29 21.387 29 25.375V29H0zM7.25 7.25a7.25 7.25 0 1114.5 0 7.25 7.25 0 01-14.5 0z"
        transform="translate(6 5)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default UserIconFilled;
