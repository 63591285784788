import * as React from "react";
import Svg, { G, Path, Rect, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(5 5)">
          <Path
            d="M6.5 0h8.047a.5.5 0 01.5.5V2a.5.5 0 01-.5.5H6.5A.5.5 0 016 2V.5a.5.5 0 01.5-.5z"
            fill="#000"
            fillRule="nonzero"
          />
          <Rect
            stroke="#333"
            fillRule="nonzero"
            x={0.5}
            y={0.5}
            width={20}
            height={29}
            rx={3}
          />
          <Circle fill="#333" cx={10.5} cy={27} r={1.5} />
          <Path stroke="#FFF" strokeLinecap="square" d="M20.5 7.5L20.5 20.5" />
          <G transform="translate(6 7)">
            <Rect
              stroke="#000"
              strokeWidth={0.98}
              x={0.482035928}
              y={0}
              width={22.0359281}
              height={14}
              rx={1.4}
            />
            <Path
              d="M10.811 9.8c.38 0 .689.313.689.7a.696.696 0 01-.608.695l-.08.005H3.236a.694.694 0 01-.69-.7c0-.359.267-.655.61-.695l.08-.005h7.574zm11.707-7.49c.266 0 .482.22.482.49a.488.488 0 01-.417.486l-.065.004-3.988-.001-1.584 3.221h5.572c.266 0 .482.22.482.49a.488.488 0 01-.417.486l-.065.004H.482A.486.486 0 010 7c0-.248.181-.453.417-.486l.065-.004H14.11l1.584-3.221L.482 3.29A.486.486 0 010 2.8c0-.248.181-.453.417-.486l.065-.004h22.036zm-6.342 4.2l1.584-3.221h-1.296L14.88 6.51h1.296z"
              fill="#000"
              fillRule="nonzero"
            />
          </G>
        </G>
        <Path stroke="#333" strokeLinecap="square" d="M5.5 29.5L25.5 29.5" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
