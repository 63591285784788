import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import ReactNativePinView from "react-native-pin-view";
import Container from "@presto-components/Containers/Container";
import { SectionSubHeader } from "@presto-components/SectionHeaders/SectionHeader";
import PrestoIcon from "@presto-components/PrestoIcon";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import { prestoAlert } from "../../common/Alert";
import I18n from "i18n-js";
import utils from "../../utils";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useSafeArea } from "react-native-safe-area-context";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { useAssets } from "presto-react-components";

export default function OrderPinScreen(props) {
  const { svgs } = useAssets();
  const wallet = props.route.params?.wallet;
  const orderId = props.route.params?.order.id;
  const [pin, setPin] = useState();
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeArea();
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  let pinViewRef = React.createRef();
  const styles = createFormStyle(theme);
  const setEnteredPin = (value) => {
    setPin(value);
  };
  useEffect(() => {
    if (pin?.length === 4) {
      makePayment();
    }
  }, [pin]);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const makePayment = () => {
    const params = {
      order_id: props.route.params?.order.id,
      pin: pin,
    };
    console.log("params : ", params);
    setLoading(true);
    WalletManager.makePaymentWithPin(
      params,
      (response) => {
        console.log("payment response : ", response);
        setLoading(false);
        Navigator.emit({ event: "goBack" });
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "ORDER_SUCCESSFUL",
            screenParams: {
              order: props.route.params?.order,
              cart: props.route.params?.cart,
            },
          },
        });
      },
      (error) => {
        setLoading(false);
        console.log("Cart pin error : ", error, utils.errorMessage(error));
        pinViewRef.current?.clearAll();
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error)
        );
        if (error && error.code == "1013024") {
          // Navigator.emit({ event: "goBack" });
          Navigator.emit({
            event: "replace",
            params: {
              screenName: "PIN_SETUP",
            },
          });
        }
      }
    );
  };
  return (
    <LoadingContainer bgColor="transaparent" loading={loading}>
      <ScrollView
        style={{
          paddingTop: insets.top,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 100,
        }}
      >
        <View>
          <View
            style={{
              paddingBottom: 15,
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <VerticalSpacing size={20} />
            <SectionHeader
              leftText={`${I18n.t("screen_messages.pin.confirm_app_pin")}`}
            />
            <VerticalSpacing size={20} />
            <Container>
              <SectionSubHeader
                theme={theme}
                leftText={I18n.t("screen_messages.pin.confirm_transaction_msg")}
              />
              <VerticalSpacing size={50}></VerticalSpacing>
              <ReactNativePinView
                style={{ backgroundColor: "transparent" }}
                inputSize={11}
                ref={pinViewRef}
                pinLength={4}
                buttonSize={50}
                onValueChange={(value) => setEnteredPin(value)}
                buttonAreaStyle={styles.buttonAreaStyle}
                inputAreaStyle={styles.inputAreaStyle}
                inputViewEmptyStyle={styles.inputViewEmptyStyle}
                inputViewFilledStyle={styles.inputViewFilledStyle}
                buttonViewStyle={[styles.buttonViewStyle]}
                buttonTextStyle={styles.buttonTextStyle}
                onButtonPress={(key) => {
                  if (key === "custom_right") {
                    pinViewRef.current.clear();
                  }
                }}
                customRightButton={
                  <View
                    style={{
                      alignSelf: "center",
                      height: "100%",
                      paddingTop: 10,
                    }}
                  >
                    <PrestoIcon icon={<svgs.BackSpaceIcon />}></PrestoIcon>
                  </View>
                }
              />
            </Container>
          </View>
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}

function createFormStyle(theme) {
  return {
    buttonAreaStyle: {
      marginTop: 24,
    },
    inputAreaStyle: {
      marginBottom: 24,
    },
    inputViewEmptyStyle: {
      backgroundColor: "#D8D8D8",
      marginHorizontal: 20,
    },
    inputViewFilledStyle: {
      marginHorizontal: 20,
      backgroundColor: theme.primary,
    },
    buttonViewStyle: {
      marginBottom: 15,
      backgroundColor: "#D8D8D8",
      borderRadius: 20,
    },
    buttonTextStyle: {
      color: theme.darkText,
      fontFamily: theme.primarySemiBoldFont,
      fontSize: 33,
    },
  };
}
