import React, {
  useContext,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from "react";
import { View, ScrollView, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import CartReview from "@presto-components/CartReview/CartReview";
import AddressCard from "@presto-cards/AddressCard/AddressCard";
import LineItemCard from "@presto-screen-components/LineItemCard/LineItemCard";
import useFocusEffect from "@presto-common/useFocusEffect";
import SearchManager from "@presto-services/features/search/SearchManager";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import AddressCardModel from "@presto-component-models/AddressCardModel";
import OrderManager from "@presto-services/features/orders/OrderManager";
import config from "@presto-common/config";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

export default function OrderSucccessScreen({ route }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { orderId, cart } = route.params;
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 941 ? true : false;
  const userTypeEmployee = config?.user_type === "employee";
  const insets = useSafeAreaInsets();
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("");

  useEffect(() => {
    getOrder(orderId);
  }, []);

  const getOrder = (orderId) => {
    setLoading(true);

    let method = OrderManager.getOrder,
      params = { id: orderId };

    if (userTypeEmployee) {
      method = OrderManager.getOrdersByOrderId;
      params = { order_id: orderId };
    }

    method(
      params,
      (response) => {
        setOrder(response.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const onClose = () => {
    isDesktop && setPaneState(false);
    Navigator.emit({ event: "popToTop" });
    Navigator.emit({ event: "popToTop" });
    Navigator.emit({ event: "popToTop" });
  };
  const onTrack = () => {
    if (userTypeEmployee) {
      Navigator.emit({ event: "popToTop" });
      Navigator.emit({ event: "popToTop" });
      Navigator.emit({ event: "popToTop" });
    } else {
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "TRACK_ORDER_SCREEN",
          screenParams: { orderId: order.id },
        },
      });
    }
  };
  const { items } = state;

  const getCategoryItem = (id) => {
    SearchManager.searchCategoryItem(
      { id },
      (response) => {
        console.log(
          "Category Item for id : ",
          id,
          JSON.stringify(response, null, 2)
        );
        dispatch({
          type: "SET_CATEGORY_ITEM",
          payload: response.hits.hits[0]._source,
        });
      },
      () => {}
    );
  };

  useFocusEffect(
    useCallback(() => {
      _.forEach(order?.items, (lineItem) => {
        getCategoryItem(lineItem.item_id);
      });
    }, [order])
  );

  const renderCartItem = (lineItem) => {
    const item = items[lineItem.item_id];
    const variation = lineItem.variation;

    if (item) {
      return (
        <View style={{ paddingHorizontal: isDesktop ? 20 : 0 }}>
          <LineItemCard
            key={lineItem.id}
            item={item}
            lineItem={lineItem}
            variation={variation}
            showWhite={true}
          ></LineItemCard>
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <LoadingContainer loading={false}>
      <ScrollView
        style={{
          flex: 1,
          paddingTop: insets.top + 20,
          backgroundColor: theme.appBackgroundColor,
        }}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <VerticalSpacing size={15} />
        <FullWidthHorizontalPaddedBox>
          <PrestoText fontStyle="700.bold" color={theme.darkText} size={18}>
            {I18n.t("screen_messages.orders.order_success_title")}
          </PrestoText>
          <VerticalSpacing size={15} />
          <PrestoText
            fontStyle="400.normal"
            color={theme.secondary}
            size={14}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.cart.thank_you_for_shoping", {
              query: order?.friendly_id,
            })}
          </PrestoText>
          <VerticalSpacing size={20} />
        </FullWidthHorizontalPaddedBox>
        {order?.address ? (
          <View>
            <PrestoText
              fontStyle="700.bold"
              size={16}
              color={theme.darkText}
              extraStyle={{ paddingLeft: theme.containerPadding }}
            >
              {I18n.t("screen_messages.delivery_address_title")}
            </PrestoText>
            <AddressCard
              fontColor={theme.paragraph}
              boldLine1
              address={new AddressCardModel({ address: order.address })}
            />
          </View>
        ) : null}
        <FullWidthHorizontalPaddedBox>
          <CartReview order={cart} />
        </FullWidthHorizontalPaddedBox>
        <VerticalSpacing size={10} />
        <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
        <VerticalSpacing size={25} />
        <PrestoText
          fontStyle="500.medium"
          color="#333333"
          size={16}
          extraStyle={{ paddingLeft: theme.containerPadding }}
        >
          {I18n.t("screen_messages.orders.item_details")}
        </PrestoText>
        {_.map(order?.items, (item) => {
          return renderCartItem(item);
        })}

        <VerticalSpacing size={100} />
      </ScrollView>

      <View
        style={{
          width: "100%",
          height: 80,
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: theme.white,
          paddingVertical: 10,
        }}
      >
        <FullWidthHorizontalPaddedBox>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: theme.white,
            }}
          >
            <View style={{ width: "49%" }}>
              <PrestoSolidButton
                size="extra-large"
                title={I18n.t("screen_messages.common.close")}
                onPress={onClose}
                buttonStyle="tertiary"
                bgColor="#CCCCCC"
                titleColor={theme.primaryButtonText}
              />
            </View>
            <View style={{ width: "49%" }}>
              <PrestoSolidButton
                size="extra-large"
                title={
                  userTypeEmployee
                    ? I18n.t("screen_messages.common.start_new")
                    : I18n.t("screen_messages.orders.track")
                }
                buttonStyle="primary"
                titleColor={theme.primaryButtonText}
                onPress={() => onTrack()}
              />
            </View>
          </View>
        </FullWidthHorizontalPaddedBox>
      </View>
    </LoadingContainer>
  );
}
