import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="20px"
      height="23px"
      viewBox="0 0 20 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M150.394 22.166c-.426-.33-.958.083-.958.745v.573h-5.827l9.575-9.642a3.375 3.375 0 00.34-4.394c-.677-.94-1.83-1.448-3.01-1.448h-12.665C136.275 8 135 9.24 135 10.768c0 1.529 1.275 2.768 2.849 2.768h7.723l-9.575 9.641a3.375 3.375 0 00-.34 4.395c.678.94 1.831 1.448 3.013 1.448h10.766v.551c0 .662.532 1.076.958.745l4.286-3.33c.427-.33.427-1.158 0-1.489l-4.286-3.33z"
        transform="translate(-155 -110) translate(20 102)"
        fill="#53C175"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default SvgComponent
