const colorsArray = [
  {
    color_name: "Absolute Zero",
    hexcode: "#0048BA",
  },
  {
    color_name: "Acid Green",
    hexcode: "#B0BF1A",
  },
  {
    color_name: "Aero",
    hexcode: "#7CB9E8",
  },
  {
    color_name: "Aero Blue",
    hexcode: "#C9FFE5",
  },
  {
    color_name: "African Violet",
    hexcode: "#B284BE",
  },
  {
    color_name: "Air Force Blue (RAF)",
    hexcode: "#5D8AA8",
  },
  {
    color_name: "Air Force Blue (USAF)",
    hexcode: "#00308F",
  },
  {
    color_name: "Air Superiority Blue",
    hexcode: "#72A0C1",
  },
  {
    color_name: "Alabama Crimson",
    hexcode: "#AF002A",
  },
  {
    color_name: "Alabaster",
    hexcode: "#F2F0E6",
  },
  {
    color_name: "Alice Blue",
    hexcode: "#F0F8FF",
  },
  {
    color_name: "Alien Armpit",
    hexcode: "#84DE02",
  },
  {
    color_name: "Alizarin Crimson",
    hexcode: "#E32636",
  },
  {
    color_name: "Alloy Orange",
    hexcode: "#C46210",
  },
  {
    color_name: "Almond",
    hexcode: "#EFDECD",
  },
  {
    color_name: "Amaranth",
    hexcode: "#E52B50",
  },
  {
    color_name: "Amaranth Deep Purple",
    hexcode: "#9F2B68",
  },
  {
    color_name: "Amaranth Pink",
    hexcode: "#F19CBB",
  },
  {
    color_name: "Amaranth Purple",
    hexcode: "#AB274F",
  },
  {
    color_name: "Amaranth Red",
    hexcode: "#D3212D",
  },
  {
    color_name: "Amazon Store",
    hexcode: "#3B7A57",
  },
  {
    color_name: "Amazonite",
    hexcode: "#00C4B0",
  },
  {
    color_name: "Amber",
    hexcode: "#FFBF00",
  },
  {
    color_name: "Amber (SAE/ECE)",
    hexcode: "#FF7E00",
  },
  {
    color_name: "American Rose",
    hexcode: "#FF033E",
  },
  {
    color_name: "Amethyst",
    hexcode: "#9966CC",
  },
  {
    color_name: "Android Green",
    hexcode: "#A4C639",
  },
  {
    color_name: "Anti-Flash White",
    hexcode: "#F2F3F4",
  },
  {
    color_name: "Antique Brass",
    hexcode: "#CD9575",
  },
  {
    color_name: "Antique Bronze",
    hexcode: "#665D1E",
  },
  {
    color_name: "Antique Fuchsia",
    hexcode: "#915C83",
  },
  {
    color_name: "Antique Ruby",
    hexcode: "#841B2D",
  },
  {
    color_name: "Antique White",
    hexcode: "#FAEBD7",
  },
  {
    color_name: "Ao (English)",
    hexcode: "#008000",
  },
  {
    color_name: "Apple Green",
    hexcode: "#8DB600",
  },
  {
    color_name: "Apricot",
    hexcode: "#FBCEB1",
  },
  {
    color_name: "Aqua",
    hexcode: "#00FFFF",
  },
  {
    color_name: "Aquamarine",
    hexcode: "#7FFFD4",
  },
  {
    color_name: "Arctic Lime",
    hexcode: "#D0FF14",
  },
  {
    color_name: "Army Green",
    hexcode: "#4B5320",
  },
  {
    color_name: "Arsenic",
    hexcode: "#3B444B",
  },
  {
    color_name: "Artichoke",
    hexcode: "#8F9779",
  },
  {
    color_name: "Arylide Yellow",
    hexcode: "#E9D66B",
  },
  {
    color_name: "Ash Gray",
    hexcode: "#B2BEB5",
  },
  {
    color_name: "Aurora",
    hexcode: "#507297",
  },
  {
    color_name: "Asparagus",
    hexcode: "#87A96B",
  },
  {
    color_name: "Atomic Tangerine",
    hexcode: "#FF9966",
  },
  {
    color_name: "Auburn",
    hexcode: "#A52A2A",
  },
  {
    color_name: "Aureolin",
    hexcode: "#FDEE00",
  },
  {
    color_name: "AuroMetalSaurus",
    hexcode: "#6E7F80",
  },
  {
    color_name: "Avocado",
    hexcode: "#568203",
  },
  {
    color_name: "Awesome",
    hexcode: "#FF2052",
  },
  {
    color_name: "Gold",
    hexcode: "#C39953",
  },
  {
    color_name: "Aztec Gold",
    hexcode: "#C39953",
  },
  {
    color_name: "Azure",
    hexcode: "#007FFF",
  },
  {
    color_name: "Azure (Web Color)",
    hexcode: "#F0FFFF",
  },
  {
    color_name: "Azure Mist",
    hexcode: "#F0FFFF",
  },
  {
    color_name: "Azureish White",
    hexcode: "#DBE9F4",
  },
  {
    color_name: "Baby Blue",
    hexcode: "#89CFF0",
  },
  {
    color_name: "Baby Blue Eyes",
    hexcode: "#A1CAF1",
  },
  {
    color_name: "Baby Pink",
    hexcode: "#F4C2C2",
  },
  {
    color_name: "Baby Powder",
    hexcode: "#FEFEFA",
  },
  {
    color_name: "Baker-Miller Pink",
    hexcode: "#FF91AF",
  },
  {
    color_name: "Ball Blue",
    hexcode: "#21ABCD",
  },
  {
    color_name: "Banana Mania",
    hexcode: "#FAE7B5",
  },
  {
    color_name: "Banana Yellow",
    hexcode: "#FFE135",
  },
  {
    color_name: "Bangladesh Green",
    hexcode: "#006A4E",
  },
  {
    color_name: "Barbie Pink",
    hexcode: "#E0218A",
  },
  {
    color_name: "Barn Red",
    hexcode: "#7C0A02",
  },
  {
    color_name: "Battery Charged Blue",
    hexcode: "#1DACD6",
  },
  {
    color_name: "Battleship Grey",
    hexcode: "#848482",
  },
  {
    color_name: "Bazaar",
    hexcode: "#98777B",
  },
  {
    color_name: "Beau Blue",
    hexcode: "#BCD4E6",
  },
  {
    color_name: "Beaver",
    hexcode: "#9F8170",
  },
  {
    color_name: "Begonia",
    hexcode: "#FA6E79",
  },
  {
    color_name: "Beige",
    hexcode: "#F5F5DC",
  },
  {
    color_name: "B'dazzled Blue",
    hexcode: "#2E5894",
  },
  {
    color_name: "Big Dip O'ruby",
    hexcode: "#9C2542",
  },
  {
    color_name: "Big Foot Feet",
    hexcode: "#E88E5A",
  },
  {
    color_name: "Bisque",
    hexcode: "#FFE4C4",
  },
  {
    color_name: "Bistre",
    hexcode: "#3D2B1F",
  },
  {
    color_name: "Bistre Brown",
    hexcode: "#967117",
  },
  {
    color_name: "Bitter Lemon",
    hexcode: "#CAE00D",
  },
  {
    color_name: "Bitter Lime",
    hexcode: "#BFFF00",
  },
  {
    color_name: "Bittersweet",
    hexcode: "#FE6F5E",
  },
  {
    color_name: "Bittersweet Shimmer",
    hexcode: "#BF4F51",
  },
  {
    color_name: "Black",
    hexcode: "#000000",
  },
  {
    color_name: "Black Bean",
    hexcode: "#3D0C02",
  },
  {
    color_name: "Black Coral",
    hexcode: "#54626F",
  },
  {
    color_name: "Black Leather Jacket",
    hexcode: "#253529",
  },
  {
    color_name: "Black Olive",
    hexcode: "#3B3C36",
  },
  {
    color_name: "Black Shadows",
    hexcode: "#BFAFB2",
  },
  {
    color_name: "Blanched Almond",
    hexcode: "#FFEBCD",
  },
  {
    color_name: "Blast-Off Bronze",
    hexcode: "#A57164",
  },
  {
    color_name: "Bleu De France",
    hexcode: "#318CE7",
  },
  {
    color_name: "Blizzard Blue",
    hexcode: "#ACE5EE",
  },
  {
    color_name: "Blond",
    hexcode: "#FAF0BE",
  },
  {
    color_name: "Blue",
    hexcode: "#0000FF",
  },
  {
    color_name: "Blue (Crayola)",
    hexcode: "#1F75FE",
  },
  {
    color_name: "Blue (Munsell)",
    hexcode: "#0093AF",
  },
  {
    color_name: "Blue (NCS)",
    hexcode: "#0087BD",
  },
  {
    color_name: "Blue (Pantone)",
    hexcode: "#0018A8",
  },
  {
    color_name: "Blue (Pigment)",
    hexcode: "#333399",
  },
  {
    color_name: "Blue (RYB)",
    hexcode: "#0247FE",
  },
  {
    color_name: "Blue Bell",
    hexcode: "#A2A2D0",
  },
  {
    color_name: "Blue Bolt",
    hexcode: "#00B9FB",
  },
  {
    color_name: "Blue-Gray",
    hexcode: "#6699CC",
  },
  {
    color_name: "Blue-Green",
    hexcode: "#0D98BA",
  },
  {
    color_name: "Blue Jeans",
    hexcode: "#5DADEC",
  },
  {
    color_name: "Blue Lagoon",
    hexcode: "#ACE5EE",
  },
  {
    color_name: "Blue-Magenta Violet",
    hexcode: "#553592",
  },
  {
    color_name: "Blue Sapphire",
    hexcode: "#126180",
  },
  {
    color_name: "Blue-Violet",
    hexcode: "#8A2BE2",
  },
  {
    color_name: "Blue Yonder",
    hexcode: "#5072A7",
  },
  {
    color_name: "Blueberry",
    hexcode: "#4F86F7",
  },
  {
    color_name: "Bluebonnet",
    hexcode: "#1C1CF0",
  },
  {
    color_name: "Blush",
    hexcode: "#DE5D83",
  },
  {
    color_name: "Bole",
    hexcode: "#79443B",
  },
  {
    color_name: "Bondi Blue",
    hexcode: "#0095B6",
  },
  {
    color_name: "Bone",
    hexcode: "#E3DAC9",
  },
  {
    color_name: "Booger Buster",
    hexcode: "#DDE26A",
  },
  {
    color_name: "Boston University Red",
    hexcode: "#CC0000",
  },
  {
    color_name: "Bottle Green",
    hexcode: "#006A4E",
  },
  {
    color_name: "Boysenberry",
    hexcode: "#873260",
  },
  {
    color_name: "Brandeis Blue",
    hexcode: "#0070FF",
  },
  {
    color_name: "Brass",
    hexcode: "#B5A642",
  },
  {
    color_name: "Brick Red",
    hexcode: "#CB4154",
  },
  {
    color_name: "Bright Cerulean",
    hexcode: "#1DACD6",
  },
  {
    color_name: "Bright Green",
    hexcode: "#66FF00",
  },
  {
    color_name: "Bright Lavender",
    hexcode: "#BF94E4",
  },
  {
    color_name: "Bright Lilac",
    hexcode: "#D891EF",
  },
  {
    color_name: "Bright Maroon",
    hexcode: "#C32148",
  },
  {
    color_name: "Bright Navy Blue",
    hexcode: "#1974D2",
  },
  {
    color_name: "Bright Pink",
    hexcode: "#FF007F",
  },
  {
    color_name: "Bright Turquoise",
    hexcode: "#08E8DE",
  },
  {
    color_name: "Bright Ube",
    hexcode: "#D19FE8",
  },
  {
    color_name: "Bright Yellow (Crayola)",
    hexcode: "#FFAA1D",
  },
  {
    color_name: "Brilliant Azure",
    hexcode: "#3399FF",
  },
  {
    color_name: "Brilliant Lavender",
    hexcode: "#F4BBFF",
  },
  {
    color_name: "Brilliant Rose",
    hexcode: "#FF55A3",
  },
  {
    color_name: "Brink Pink",
    hexcode: "#FB607F",
  },
  {
    color_name: "British Racing Green",
    hexcode: "#004225",
  },
  {
    color_name: "Bronze",
    hexcode: "#CD7F32",
  },
  {
    color_name: "Bronze Yellow",
    hexcode: "#737000",
  },
  {
    color_name: "Brown (Traditional)",
    hexcode: "#964B00",
  },
  {
    color_name: "Brown (Web)",
    hexcode: "#A52A2A",
  },
  {
    color_name: "Brown-Nose",
    hexcode: "#6B4423",
  },
  {
    color_name: "Brown Sugar",
    hexcode: "#AF6E4D",
  },
  {
    color_name: "Brown Yellow",
    hexcode: "#cc9966",
  },
  {
    color_name: "Brunswick Green",
    hexcode: "#1B4D3E",
  },
  {
    color_name: "Bubble Gum",
    hexcode: "#FFC1CC",
  },
  {
    color_name: "Bubbles",
    hexcode: "#E7FEFF",
  },
  {
    color_name: "Bud Green",
    hexcode: "#7BB661",
  },
  {
    color_name: "Buff",
    hexcode: "#F0DC82",
  },
  {
    color_name: "Bulgarian Rose",
    hexcode: "#480607",
  },
  {
    color_name: "Burgundy",
    hexcode: "#800020",
  },
  {
    color_name: "Burlywood",
    hexcode: "#DEB887",
  },
  {
    color_name: "Burnished Brown",
    hexcode: "#A17A74",
  },
  {
    color_name: "Burnt Orange",
    hexcode: "#CC5500",
  },
  {
    color_name: "Burnt Sienna",
    hexcode: "#E97451",
  },
  {
    color_name: "Burnt Umber",
    hexcode: "#8A3324",
  },
  {
    color_name: "Button Blue",
    hexcode: "#24A0ED",
  },
  {
    color_name: "Byzantine",
    hexcode: "#BD33A4",
  },
  {
    color_name: "Byzantium",
    hexcode: "#702963",
  },
  {
    color_name: "Cadet",
    hexcode: "#536872",
  },
  {
    color_name: "Cadet Blue",
    hexcode: "#5F9EA0",
  },
  {
    color_name: "Cadet Grey",
    hexcode: "#91A3B0",
  },
  {
    color_name: "Cadmium Green",
    hexcode: "#006B3C",
  },
  {
    color_name: "Cadmium Orange",
    hexcode: "#ED872D",
  },
  {
    color_name: "Cadmium Red",
    hexcode: "#E30022",
  },
  {
    color_name: "Cadmium Yellow",
    hexcode: "#FFF600",
  },
  {
    color_name: "Cafe Au Lait",
    hexcode: "#A67B5B",
  },
  {
    color_name: "Cafe Noir",
    hexcode: "#4B3621",
  },
  {
    color_name: "Cal Poly Pomona Green",
    hexcode: "#1E4D2B",
  },
  {
    color_name: "Cambridge Blue",
    hexcode: "#A3C1AD",
  },
  {
    color_name: "Camel",
    hexcode: "#C19A6B",
  },
  {
    color_name: "Cameo Pink",
    hexcode: "#EFBBCC",
  },
  {
    color_name: "Camouflage Green",
    hexcode: "#78866B",
  },
  {
    color_name: "Canary",
    hexcode: "#FFFF99",
  },
  {
    color_name: "Canary Yellow",
    hexcode: "#FFEF00",
  },
  {
    color_name: "Candy Apple Red",
    hexcode: "#FF0800",
  },
  {
    color_name: "Candy Pink",
    hexcode: "#E4717A",
  },
  {
    color_name: "Capri",
    hexcode: "#00BFFF",
  },
  {
    color_name: "Caput Mortuum",
    hexcode: "#592720",
  },
  {
    color_name: "Cardinal",
    hexcode: "#C41E3A",
  },
  {
    color_name: "Caribbean Green",
    hexcode: "#00CC99",
  },
  {
    color_name: "Carmine",
    hexcode: "#960018",
  },
  {
    color_name: "Carmine (M&P)",
    hexcode: "#D70040",
  },
  {
    color_name: "Carmine Pink",
    hexcode: "#EB4C42",
  },
  {
    color_name: "Carmine Red",
    hexcode: "#FF0038",
  },
  {
    color_name: "Carnation Pink",
    hexcode: "#FFA6C9",
  },
  {
    color_name: "Carnelian",
    hexcode: "#B31B1B",
  },
  {
    color_name: "Carolina Blue",
    hexcode: "#56A0D3",
  },
  {
    color_name: "Carrot Orange",
    hexcode: "#ED9121",
  },
  {
    color_name: "Castleton Green",
    hexcode: "#00563F",
  },
  {
    color_name: "Catalina Blue",
    hexcode: "#062A78",
  },
  {
    color_name: "Catawba",
    hexcode: "#703642",
  },
  {
    color_name: "Cedar Chest",
    hexcode: "#C95A49",
  },
  {
    color_name: "Ceil",
    hexcode: "#92A1CF",
  },
  {
    color_name: "Celadon",
    hexcode: "#ACE1AF",
  },
  {
    color_name: "Celadon Blue",
    hexcode: "#007BA7",
  },
  {
    color_name: "Celadon Green",
    hexcode: "#2F847C",
  },
  {
    color_name: "Celeste",
    hexcode: "#B2FFFF",
  },
  {
    color_name: "Celestial Blue",
    hexcode: "#4997D0",
  },
  {
    color_name: "Cerise",
    hexcode: "#DE3163",
  },
  {
    color_name: "Cerise Pink",
    hexcode: "#EC3B83",
  },
  {
    color_name: "Cerulean",
    hexcode: "#007BA7",
  },
  {
    color_name: "Cerulean Blue",
    hexcode: "#2A52BE",
  },
  {
    color_name: "Cerulean Frost",
    hexcode: "#6D9BC3",
  },
  {
    color_name: "CG Blue",
    hexcode: "#007AA5",
  },
  {
    color_name: "CG Red",
    hexcode: "#E03C31",
  },
  {
    color_name: "Chamoisee",
    hexcode: "#A0785A",
  },
  {
    color_name: "Champagne",
    hexcode: "#F7E7CE",
  },
  {
    color_name: "Champagne Pink",
    hexcode: "#F1DDCF",
  },
  {
    color_name: "Charcoal",
    hexcode: "#36454F",
  },
  {
    color_name: "Charleston Green",
    hexcode: "#232B2B",
  },
  {
    color_name: "Charm Pink",
    hexcode: "#E68FAC",
  },
  {
    color_name: "Chartreuse (Traditional)",
    hexcode: "#DFFF00",
  },
  {
    color_name: "Chartreuse (Web)",
    hexcode: "#7FFF00",
  },
  {
    color_name: "Cherry",
    hexcode: "#DE3163",
  },
  {
    color_name: "Cherry Blossom Pink",
    hexcode: "#FFB7C5",
  },
  {
    color_name: "Chestnut",
    hexcode: "#954535",
  },
  {
    color_name: "China Pink",
    hexcode: "#DE6FA1",
  },
  {
    color_name: "China Rose",
    hexcode: "#A8516E",
  },
  {
    color_name: "Chinese Red",
    hexcode: "#AA381E",
  },
  {
    color_name: "Chinese Violet",
    hexcode: "#856088",
  },
  {
    color_name: "Chlorophyll Green",
    hexcode: "#4AFF00",
  },
  {
    color_name: "Chocolate (Traditional)",
    hexcode: "#7B3F00",
  },
  {
    color_name: "Chocolate (Web)",
    hexcode: "#D2691E",
  },
  {
    color_name: "Chrome Yellow",
    hexcode: "#FFA700",
  },
  {
    color_name: "Cinereous",
    hexcode: "#98817B",
  },
  {
    color_name: "Cinnabar",
    hexcode: "#E34234",
  },
  {
    color_name: "Cinnamon",
    hexcode: "#D2691E",
  },
  {
    color_name: "Cinnamon Satin",
    hexcode: "#CD607E",
  },
  {
    color_name: "Citrine",
    hexcode: "#E4D00A",
  },
  {
    color_name: "Citron",
    hexcode: "#9FA91F",
  },
  {
    color_name: "Claret",
    hexcode: "#7F1734",
  },
  {
    color_name: "Classic Rose",
    hexcode: "#FBCCE7",
  },
  {
    color_name: "Cobalt Blue",
    hexcode: "#0047AB",
  },
  {
    color_name: "Cocoa Brown",
    hexcode: "#D2691E",
  },
  {
    color_name: "Coconut",
    hexcode: "#965A3E",
  },
  {
    color_name: "Coffee",
    hexcode: "#6F4E37",
  },
  {
    color_name: "Columbia Blue",
    hexcode: "#C4D8E2",
  },
  {
    color_name: "Congo Pink",
    hexcode: "#F88379",
  },
  {
    color_name: "Cool Black",
    hexcode: "#002E63",
  },
  {
    color_name: "Cool Grey",
    hexcode: "#8C92AC",
  },
  {
    color_name: "Copper",
    hexcode: "#B87333",
  },
  {
    color_name: "Copper (Crayola)",
    hexcode: "#DA8A67",
  },
  {
    color_name: "Copper Penny",
    hexcode: "#AD6F69",
  },
  {
    color_name: "Copper Red",
    hexcode: "#CB6D51",
  },
  {
    color_name: "Copper Rose",
    hexcode: "#996666",
  },
  {
    color_name: "Coquelicot",
    hexcode: "#FF3800",
  },
  {
    color_name: "Coral",
    hexcode: "#FF7F50",
  },
  {
    color_name: "Coral Pink",
    hexcode: "#F88379",
  },
  {
    color_name: "Coral Red",
    hexcode: "#FF4040",
  },
  {
    color_name: "Coral Reef",
    hexcode: "#FD7C6E",
  },
  {
    color_name: "Cordovan",
    hexcode: "#893F45",
  },
  {
    color_name: "Corn",
    hexcode: "#FBEC5D",
  },
  {
    color_name: "Cornell Red",
    hexcode: "#B31B1B",
  },
  {
    color_name: "Cornflower Blue",
    hexcode: "#6495ED",
  },
  {
    color_name: "Cornsilk",
    hexcode: "#FFF8DC",
  },
  {
    color_name: "Cosmic Cobalt",
    hexcode: "#2E2D88",
  },
  {
    color_name: "Cosmic Latte",
    hexcode: "#FFF8E7",
  },
  {
    color_name: "Coyote Brown",
    hexcode: "#81613C",
  },
  {
    color_name: "Cotton Candy",
    hexcode: "#FFBCD9",
  },
  {
    color_name: "Cream",
    hexcode: "#FFFDD0",
  },
  {
    color_name: "Crimson",
    hexcode: "#DC143C",
  },
  {
    color_name: "Crimson Glory",
    hexcode: "#BE0032",
  },
  {
    color_name: "Crimson Red",
    hexcode: "#990000",
  },
  {
    color_name: "Cultured",
    hexcode: "#F5F5F5",
  },
  {
    color_name: "Cyan",
    hexcode: "#00FFFF",
  },
  {
    color_name: "Cyan Azure",
    hexcode: "#4E82B4",
  },
  {
    color_name: "Cyan-Blue Azure",
    hexcode: "#4682BF",
  },
  {
    color_name: "Cyan Cobalt Blue",
    hexcode: "#28589C",
  },
  {
    color_name: "Cyan Cornflower Blue",
    hexcode: "#188BC2",
  },
  {
    color_name: "Cyan (Process)",
    hexcode: "#00B7EB",
  },
  {
    color_name: "Cyber Grape",
    hexcode: "#58427C",
  },
  {
    color_name: "Cyber Yellow",
    hexcode: "#FFD300",
  },
  {
    color_name: "Cyclamen",
    hexcode: "#F56FA1",
  },
  {
    color_name: "Daffodil",
    hexcode: "#FFFF31",
  },
  {
    color_name: "Dandelion",
    hexcode: "#F0E130",
  },
  {
    color_name: "Dark Blue",
    hexcode: "#00008B",
  },
  {
    color_name: "Dark Blue-Gray",
    hexcode: "#666699",
  },
  {
    color_name: "Dark Brown",
    hexcode: "#654321",
  },
  {
    color_name: "Dark Brown-Tangelo",
    hexcode: "#88654E",
  },
  {
    color_name: "Dark Byzantium",
    hexcode: "#5D3954",
  },
  {
    color_name: "Dark Candy Apple Red",
    hexcode: "#A40000",
  },
  {
    color_name: "Dark Cerulean",
    hexcode: "#08457E",
  },
  {
    color_name: "Dark Chestnut",
    hexcode: "#986960",
  },
  {
    color_name: "Dark Coral",
    hexcode: "#CD5B45",
  },
  {
    color_name: "Dark Cyan",
    hexcode: "#008B8B",
  },
  {
    color_name: "Dark Electric Blue",
    hexcode: "#536878",
  },
  {
    color_name: "Dark Goldenrod",
    hexcode: "#B8860B",
  },
  {
    color_name: "Dark Gray (X11)",
    hexcode: "#A9A9A9",
  },
  {
    color_name: "Dark Green",
    hexcode: "#013220",
  },
  {
    color_name: "Dark Green (X11)",
    hexcode: "#006400",
  },
  {
    color_name: "Dark Gunmetal",
    hexcode: "#1F262A",
  },
  {
    color_name: "Dark Imperial Blue",
    hexcode: "#00416A",
  },
  {
    color_name: "Dark Imperial Blue",
    hexcode: "#00147E",
  },
  {
    color_name: "Dark Jungle Green",
    hexcode: "#1A2421",
  },
  {
    color_name: "Dark Khaki",
    hexcode: "#BDB76B",
  },
  {
    color_name: "Dark Lava",
    hexcode: "#483C32",
  },
  {
    color_name: "Dark Lavender",
    hexcode: "#734F96",
  },
  {
    color_name: "Dark Liver",
    hexcode: "#534B4F",
  },
  {
    color_name: "Dark Liver (Horses)",
    hexcode: "#543D37",
  },
  {
    color_name: "Dark Magenta",
    hexcode: "#8B008B",
  },
  {
    color_name: "Dark Medium Gray",
    hexcode: "#A9A9A9",
  },
  {
    color_name: "Dark Midnight Blue",
    hexcode: "#003366",
  },
  {
    color_name: "Dark Moss Green",
    hexcode: "#4A5D23",
  },
  {
    color_name: "Dark Olive Green",
    hexcode: "#556B2F",
  },
  {
    color_name: "Dark Orange",
    hexcode: "#FF8C00",
  },
  {
    color_name: "Dark Orchid",
    hexcode: "#9932CC",
  },
  {
    color_name: "Dark Pastel Blue",
    hexcode: "#779ECB",
  },
  {
    color_name: "Dark Pastel Green",
    hexcode: "#03C03C",
  },
  {
    color_name: "Dark Pastel Purple",
    hexcode: "#966FD6",
  },
  {
    color_name: "Dark Pastel Red",
    hexcode: "#C23B22",
  },
  {
    color_name: "Dark Pink",
    hexcode: "#E75480",
  },
  {
    color_name: "Dark Powder Blue",
    hexcode: "#003399",
  },
  {
    color_name: "Dark Puce",
    hexcode: "#4F3A3C",
  },
  {
    color_name: "Dark Purple",
    hexcode: "#301934",
  },
  {
    color_name: "Dark Raspberry",
    hexcode: "#872657",
  },
  {
    color_name: "Dark Red",
    hexcode: "#8B0000",
  },
  {
    color_name: "Dark Salmon",
    hexcode: "#E9967A",
  },
  {
    color_name: "Dark Scarlet",
    hexcode: "#560319",
  },
  {
    color_name: "Dark Sea Green",
    hexcode: "#8FBC8F",
  },
  {
    color_name: "Dark Sienna",
    hexcode: "#3C1414",
  },
  {
    color_name: "Dark Sky Blue",
    hexcode: "#8CBED6",
  },
  {
    color_name: "Dark Slate Blue",
    hexcode: "#483D8B",
  },
  {
    color_name: "Dark Slate Gray",
    hexcode: "#2F4F4F",
  },
  {
    color_name: "Dark Spring Green",
    hexcode: "#177245",
  },
  {
    color_name: "Dark Tan",
    hexcode: "#918151",
  },
  {
    color_name: "Dark Tangerine",
    hexcode: "#FFA812",
  },
  {
    color_name: "Dark Taupe",
    hexcode: "#483C32",
  },
  {
    color_name: "Dark Terra Cotta",
    hexcode: "#CC4E5C",
  },
  {
    color_name: "Dark Turquoise",
    hexcode: "#00CED1",
  },
  {
    color_name: "Dark Vanilla",
    hexcode: "#D1BEA8",
  },
  {
    color_name: "Dark Violet",
    hexcode: "#9400D3",
  },
  {
    color_name: "Dark Yellow",
    hexcode: "#9B870C",
  },
  {
    color_name: "Dartmouth Green",
    hexcode: "#00703C",
  },
  {
    color_name: "Davy's Grey",
    hexcode: "#555555",
  },
  {
    color_name: "Debian Red",
    hexcode: "#D70A53",
  },
  {
    color_name: "Deep Aquamarine",
    hexcode: "#40826D",
  },
  {
    color_name: "Deep Carmine",
    hexcode: "#A9203E",
  },
  {
    color_name: "Deep Carmine Pink",
    hexcode: "#EF3038",
  },
  {
    color_name: "Deep Carrot Orange",
    hexcode: "#E9692C",
  },
  {
    color_name: "Deep Cerise",
    hexcode: "#DA3287",
  },
  {
    color_name: "Deep Champagne",
    hexcode: "#FAD6A5",
  },
  {
    color_name: "Deep Chestnut",
    hexcode: "#B94E48",
  },
  {
    color_name: "Deep Coffee",
    hexcode: "#704241",
  },
  {
    color_name: "Deep Fuchsia",
    hexcode: "#C154C1",
  },
  {
    color_name: "Deep Green",
    hexcode: "#056608",
  },
  {
    color_name: "Deep Green-Cyan Turquoise",
    hexcode: "#0E7C61",
  },
  {
    color_name: "Deep Jungle Green",
    hexcode: "#004B49",
  },
  {
    color_name: "Deep Koamaru",
    hexcode: "#333366",
  },
  {
    color_name: "Deep Lemon",
    hexcode: "#F5C71A",
  },
  {
    color_name: "Deep Lilac",
    hexcode: "#9955BB",
  },
  {
    color_name: "Deep Magenta",
    hexcode: "#CC00CC",
  },
  {
    color_name: "Deep Maroon",
    hexcode: "#820000",
  },
  {
    color_name: "Deep Mauve",
    hexcode: "#D473D4",
  },
  {
    color_name: "Deep Moss Green",
    hexcode: "#355E3B",
  },
  {
    color_name: "Deep Peach",
    hexcode: "#FFCBA4",
  },
  {
    color_name: "Deep Pink",
    hexcode: "#FF1493",
  },
  {
    color_name: "Deep Puce",
    hexcode: "#A95C68",
  },
  {
    color_name: "Deep Red",
    hexcode: "#850101",
  },
  {
    color_name: "Deep Ruby",
    hexcode: "#843F5B",
  },
  {
    color_name: "Deep Saffron",
    hexcode: "#FF9933",
  },
  {
    color_name: "Deep Sky Blue",
    hexcode: "#00BFFF",
  },
  {
    color_name: "Deep Space Sparkle",
    hexcode: "#4A646C",
  },
  {
    color_name: "Deep Spring Bud",
    hexcode: "#556B2F",
  },
  {
    color_name: "Deep Taupe",
    hexcode: "#7E5E60",
  },
  {
    color_name: "Deep Tuscan Red",
    hexcode: "#66424D",
  },
  {
    color_name: "Deep Violet",
    hexcode: "#330066",
  },
  {
    color_name: "Deer",
    hexcode: "#BA8759",
  },
  {
    color_name: "Denim",
    hexcode: "#1560BD",
  },
  {
    color_name: "Denim Blue",
    hexcode: "#2243B6",
  },
  {
    color_name: "Desaturated Cyan",
    hexcode: "#669999",
  },
  {
    color_name: "Desert",
    hexcode: "#C19A6B",
  },
  {
    color_name: "Desert Sand",
    hexcode: "#EDC9AF",
  },
  {
    color_name: "Desire",
    hexcode: "#EA3C53",
  },
  {
    color_name: "Diamond",
    hexcode: "#B9F2FF",
  },
  {
    color_name: "Dim Gray",
    hexcode: "#696969",
  },
  {
    color_name: "Dingy Dungeon",
    hexcode: "#C53151",
  },
  {
    color_name: "Dirt",
    hexcode: "#9B7653",
  },
  {
    color_name: "Dodger Blue",
    hexcode: "#1E90FF",
  },
  {
    color_name: "Dodie Yellow",
    hexcode: "#FEF65B",
  },
  {
    color_name: "Dogwood Rose",
    hexcode: "#D71868",
  },
  {
    color_name: "Dollar Bill",
    hexcode: "#85BB65",
  },
  {
    color_name: "Dolphin Gray",
    hexcode: "#828E84",
  },
  {
    color_name: "Donkey Brown",
    hexcode: "#664C28",
  },
  {
    color_name: "Drab",
    hexcode: "#967117",
  },
  {
    color_name: "Duke Blue",
    hexcode: "#00009C",
  },
  {
    color_name: "Dust Storm",
    hexcode: "#E5CCC9",
  },
  {
    color_name: "Dutch White",
    hexcode: "#EFDFBB",
  },
  {
    color_name: "Earth Yellow",
    hexcode: "#E1A95F",
  },
  {
    color_name: "Ebony",
    hexcode: "#555D50",
  },
  {
    color_name: "Ecru",
    hexcode: "#C2B280",
  },
  {
    color_name: "Eerie Black",
    hexcode: "#1B1B1B",
  },
  {
    color_name: "Eggplant",
    hexcode: "#614051",
  },
  {
    color_name: "Eggshell",
    hexcode: "#F0EAD6",
  },
  {
    color_name: "Egyptian Blue",
    hexcode: "#1034A6",
  },
  {
    color_name: "Electric Blue",
    hexcode: "#7DF9FF",
  },
  {
    color_name: "Electric Crimson",
    hexcode: "#FF003F",
  },
  {
    color_name: "Electric Cyan",
    hexcode: "#00FFFF",
  },
  {
    color_name: "Electric Green",
    hexcode: "#00FF00",
  },
  {
    color_name: "Electric Indigo",
    hexcode: "#6F00FF",
  },
  {
    color_name: "Electric Lavender",
    hexcode: "#F4BBFF",
  },
  {
    color_name: "Electric Lime",
    hexcode: "#CCFF00",
  },
  {
    color_name: "Electric Purple",
    hexcode: "#BF00FF",
  },
  {
    color_name: "Electric Ultramarine",
    hexcode: "#3F00FF",
  },
  {
    color_name: "Electric Violet",
    hexcode: "#8F00FF",
  },
  {
    color_name: "Electric Yellow",
    hexcode: "#FFFF33",
  },
  {
    color_name: "Emerald",
    hexcode: "#50C878",
  },
  {
    color_name: "Eminence",
    hexcode: "#6C3082",
  },
  {
    color_name: "English Green",
    hexcode: "#1B4D3E",
  },
  {
    color_name: "English Lavender",
    hexcode: "#B48395",
  },
  {
    color_name: "English Red",
    hexcode: "#AB4B52",
  },
  {
    color_name: "English Vermillion",
    hexcode: "#CC474B",
  },
  {
    color_name: "English Violet",
    hexcode: "#563C5C",
  },
  {
    color_name: "Eton Blue",
    hexcode: "#96C8A2",
  },
  {
    color_name: "Eucalyptus",
    hexcode: "#44D7A8",
  },
  {
    color_name: "Fallow",
    hexcode: "#C19A6B",
  },
  {
    color_name: "Falu Red",
    hexcode: "#801818",
  },
  {
    color_name: "Fandango",
    hexcode: "#B53389",
  },
  {
    color_name: "Fandango Pink",
    hexcode: "#DE5285",
  },
  {
    color_name: "Fashion Fuchsia",
    hexcode: "#F400A1",
  },
  {
    color_name: "Fawn",
    hexcode: "#E5AA70",
  },
  {
    color_name: "Feldgrau",
    hexcode: "#4D5D53",
  },
  {
    color_name: "Feldspar",
    hexcode: "#FDD5B1",
  },
  {
    color_name: "Fern Green",
    hexcode: "#4F7942",
  },
  {
    color_name: "Ferrari Red",
    hexcode: "#FF2800",
  },
  {
    color_name: "Field Drab",
    hexcode: "#6C541E",
  },
  {
    color_name: "Fiery Rose",
    hexcode: "#FF5470",
  },
  {
    color_name: "Firebrick",
    hexcode: "#B22222",
  },
  {
    color_name: "Fire Engine Red",
    hexcode: "#CE2029",
  },
  {
    color_name: "Flame",
    hexcode: "#E25822",
  },
  {
    color_name: "Flamingo Pink",
    hexcode: "#FC8EAC",
  },
  {
    color_name: "Flattery",
    hexcode: "#6B4423",
  },
  {
    color_name: "Flavescent",
    hexcode: "#F7E98E",
  },
  {
    color_name: "Flax",
    hexcode: "#EEDC82",
  },
  {
    color_name: "Flirt",
    hexcode: "#A2006D",
  },
  {
    color_name: "Floral White",
    hexcode: "#FFFAF0",
  },
  {
    color_name: "Fluorescent Orange",
    hexcode: "#FFBF00",
  },
  {
    color_name: "Fluorescent Pink",
    hexcode: "#FF1493",
  },
  {
    color_name: "Fluorescent Yellow",
    hexcode: "#CCFF00",
  },
  {
    color_name: "Folly",
    hexcode: "#FF004F",
  },
  {
    color_name: "Forest Green (Traditional)",
    hexcode: "#014421",
  },
  {
    color_name: "Forest Green (Web)",
    hexcode: "#228B22",
  },
  {
    color_name: "French Beige",
    hexcode: "#A67B5B",
  },
  {
    color_name: "French Bistre",
    hexcode: "#856D4D",
  },
  {
    color_name: "French Blue",
    hexcode: "#0072BB",
  },
  {
    color_name: "French Fuchsia",
    hexcode: "#FD3F92",
  },
  {
    color_name: "French Lilac",
    hexcode: "#86608E",
  },
  {
    color_name: "French Lime",
    hexcode: "#9EFD38",
  },
  {
    color_name: "French Mauve",
    hexcode: "#D473D4",
  },
  {
    color_name: "French Pink",
    hexcode: "#FD6C9E",
  },
  {
    color_name: "French Plum",
    hexcode: "#811453",
  },
  {
    color_name: "French Puce",
    hexcode: "#4E1609",
  },
  {
    color_name: "French Raspberry",
    hexcode: "#C72C48",
  },
  {
    color_name: "French Rose",
    hexcode: "#F64A8A",
  },
  {
    color_name: "French Sky Blue",
    hexcode: "#77B5FE",
  },
  {
    color_name: "French Violet",
    hexcode: "#8806CE",
  },
  {
    color_name: "French Wine",
    hexcode: "#AC1E44",
  },
  {
    color_name: "Fresh Air",
    hexcode: "#A6E7FF",
  },
  {
    color_name: "Frogert",
    hexcode: "#E936A7",
  },
  {
    color_name: "Fuchsia",
    hexcode: "#FF00FF",
  },
  {
    color_name: "Fuchsia (Crayola)",
    hexcode: "#C154C1",
  },
  {
    color_name: "Fuchsia Pink",
    hexcode: "#FF77FF",
  },
  {
    color_name: "Fuchsia Purple",
    hexcode: "#CC397B",
  },
  {
    color_name: "Fuchsia Rose",
    hexcode: "#C74375",
  },
  {
    color_name: "Fulvous",
    hexcode: "#E48400",
  },
  {
    color_name: "Fuzzy Wuzzy",
    hexcode: "#CC6666",
  },
  {
    color_name: "Gainsboro",
    hexcode: "#DCDCDC",
  },
  {
    color_name: "Gamboge",
    hexcode: "#E49B0F",
  },
  {
    color_name: "Gamboge Orange (Brown)",
    hexcode: "#996600",
  },
  {
    color_name: "Gargoyle Gas",
    hexcode: "#FFDF46",
  },
  {
    color_name: "Generic Viridian",
    hexcode: "#007F66",
  },
  {
    color_name: "Ghost White",
    hexcode: "#F8F8FF",
  },
  {
    color_name: "Giant's Club",
    hexcode: "#B05C52",
  },
  {
    color_name: "Giants Orange",
    hexcode: "#FE5A1D",
  },
  {
    color_name: "Ginger",
    hexcode: "#B06500",
  },
  {
    color_name: "Glaucous",
    hexcode: "#6082B6",
  },
  {
    color_name: "Glitter",
    hexcode: "#E6E8FA",
  },
  {
    color_name: "Glossy Grape",
    hexcode: "#AB92B3",
  },
  {
    color_name: "GO Green",
    hexcode: "#00AB66",
  },
  {
    color_name: "Gold (Metallic)",
    hexcode: "#D4AF37",
  },
  {
    color_name: "Gold (Web) (Golden)",
    hexcode: "#FFD700",
  },
  {
    color_name: "Gold Fusion",
    hexcode: "#85754E",
  },
  {
    color_name: "Golden Brown",
    hexcode: "#996515",
  },
  {
    color_name: "Golden Poppy",
    hexcode: "#FCC200",
  },
  {
    color_name: "Golden Yellow",
    hexcode: "#FFDF00",
  },
  {
    color_name: "Goldenrod",
    hexcode: "#DAA520",
  },
  {
    color_name: "Granite Gray",
    hexcode: "#676767",
  },
  {
    color_name: "Granny Smith Apple",
    hexcode: "#A8E4A0",
  },
  {
    color_name: "Grape",
    hexcode: "#6F2DA8",
  },
  {
    color_name: "Gray",
    hexcode: "#808080",
  },
  {
    color_name: "Gray (HTML/CSS Gray)",
    hexcode: "#808080",
  },
  {
    color_name: "Gray (X11 Gray)",
    hexcode: "#BEBEBE",
  },
  {
    color_name: "Gray-Asparagus",
    hexcode: "#465945",
  },
  {
    color_name: "Gray-Blue",
    hexcode: "#8C92AC",
  },
  {
    color_name: "Green",
    hexcode: "#00FF00",
  },
  {
    color_name: "Green (Color Wheel) (X11 Green)",
    hexcode: "#00FF00",
  },
  {
    color_name: "Green (Crayola)",
    hexcode: "#1CAC78",
  },
  {
    color_name: "Green (HTML/CSS Color)",
    hexcode: "#008000",
  },
  {
    color_name: "Green (Munsell)",
    hexcode: "#00A877",
  },
  {
    color_name: "Green (NCS)",
    hexcode: "#009F6B",
  },
  {
    color_name: "Green (Pantone)",
    hexcode: "#00AD43",
  },
  {
    color_name: "Green (Pigment)",
    hexcode: "#00A550",
  },
  {
    color_name: "Green (RYB)",
    hexcode: "#66B032",
  },
  {
    color_name: "Green-Blue",
    hexcode: "#1164B4",
  },
  {
    color_name: "Green-Cyan",
    hexcode: "#009966",
  },
  {
    color_name: "Green Lizard",
    hexcode: "#A7F432",
  },
  {
    color_name: "Green Sheen",
    hexcode: "#6EAEA1",
  },
  {
    color_name: "Green-Yellow",
    hexcode: "#ADFF2F",
  },
  {
    color_name: "Grizzly",
    hexcode: "#885818",
  },
  {
    color_name: "Grullo",
    hexcode: "#A99A86",
  },
  {
    color_name: "Guppie Green",
    hexcode: "#00FF7F",
  },
  {
    color_name: "Gunmetal",
    hexcode: "#2a3439",
  },
  {
    color_name: "Halaya Ube",
    hexcode: "#663854",
  },
  {
    color_name: "Han Blue",
    hexcode: "#446CCF",
  },
  {
    color_name: "Han Purple",
    hexcode: "#5218FA",
  },
  {
    color_name: "Hansa Yellow",
    hexcode: "#E9D66B",
  },
  {
    color_name: "Harlequin",
    hexcode: "#3FFF00",
  },
  {
    color_name: "Harlequin Green",
    hexcode: "#46CB18",
  },
  {
    color_name: "Harvard Crimson",
    hexcode: "#C90016",
  },
  {
    color_name: "Harvest Gold",
    hexcode: "#DA9100",
  },
  {
    color_name: "Heart Gold",
    hexcode: "#808000",
  },
  {
    color_name: "Heat Wave",
    hexcode: "#FF7A00",
  },
  {
    color_name: "Heidelberg Red",
    hexcode: "#960018",
  },
  {
    color_name: "Heliotrope",
    hexcode: "#DF73FF",
  },
  {
    color_name: "Heliotrope Gray",
    hexcode: "#AA98A9",
  },
  {
    color_name: "Heliotrope Magenta",
    hexcode: "#AA00BB",
  },
  {
    color_name: "Hollywood Cerise",
    hexcode: "#F400A1",
  },
  {
    color_name: "Honeydew",
    hexcode: "#F0FFF0",
  },
  {
    color_name: "Honolulu Blue",
    hexcode: "#006DB0",
  },
  {
    color_name: "Hooker's Green",
    hexcode: "#49796B",
  },
  {
    color_name: "Hot Magenta",
    hexcode: "#FF1DCE",
  },
  {
    color_name: "Hot Pink",
    hexcode: "#FF69B4",
  },
  {
    color_name: "Hunter Green",
    hexcode: "#355E3B",
  },
  {
    color_name: "Iceberg",
    hexcode: "#71A6D2",
  },
  {
    color_name: "Icterine",
    hexcode: "#FCF75E",
  },
  {
    color_name: "Iguana Green",
    hexcode: "#71BC78",
  },
  {
    color_name: "Illuminating Emerald",
    hexcode: "#319177",
  },
  {
    color_name: "Imperial",
    hexcode: "#602F6B",
  },
  {
    color_name: "Imperial Blue",
    hexcode: "#002395",
  },
  {
    color_name: "Imperial Purple",
    hexcode: "#66023C",
  },
  {
    color_name: "Imperial Red",
    hexcode: "#ED2939",
  },
  {
    color_name: "Inchworm",
    hexcode: "#B2EC5D",
  },
  {
    color_name: "Independence",
    hexcode: "#4C516D",
  },
  {
    color_name: "India Green",
    hexcode: "#138808",
  },
  {
    color_name: "Indian Red",
    hexcode: "#CD5C5C",
  },
  {
    color_name: "Indian Yellow",
    hexcode: "#E3A857",
  },
  {
    color_name: "Indigo",
    hexcode: "#4B0082",
  },
  {
    color_name: "Indigo Dye",
    hexcode: "#091F92",
  },
  {
    color_name: "Indigo (Web)",
    hexcode: "#4B0082",
  },
  {
    color_name: "Infra Red",
    hexcode: "#FF496C",
  },
  {
    color_name: "Interdimensional Blue",
    hexcode: "#360CCC",
  },
  {
    color_name: "International Klein Blue",
    hexcode: "#002FA7",
  },
  {
    color_name: "International Orange (Aerospace)",
    hexcode: "#FF4F00",
  },
  {
    color_name: "International Orange (Engineering)",
    hexcode: "#BA160C",
  },
  {
    color_name: "International Orange (Golden Gate Bridge)",
    hexcode: "#C0362C",
  },
  {
    color_name: "Iris",
    hexcode: "#5A4FCF",
  },
  {
    color_name: "Irresistible",
    hexcode: "#B3446C",
  },
  {
    color_name: "Isabelline",
    hexcode: "#F4F0EC",
  },
  {
    color_name: "Islamic Green",
    hexcode: "#009000",
  },
  {
    color_name: "Italian Sky Blue",
    hexcode: "#B2FFFF",
  },
  {
    color_name: "Ivory",
    hexcode: "#FFFFF0",
  },
  {
    color_name: "Jade",
    hexcode: "#00A86B",
  },
  {
    color_name: "Japanese Carmine",
    hexcode: "#9D2933",
  },
  {
    color_name: "Japanese Indigo",
    hexcode: "#264348",
  },
  {
    color_name: "Japanese Violet",
    hexcode: "#5B3256",
  },
  {
    color_name: "Jasmine",
    hexcode: "#F8DE7E",
  },
  {
    color_name: "Jasper",
    hexcode: "#D73B3E",
  },
  {
    color_name: "Jazzberry Jam",
    hexcode: "#A50B5E",
  },
  {
    color_name: "Jelly Bean",
    hexcode: "#DA614E",
  },
  {
    color_name: "Jet",
    hexcode: "#343434",
  },
  {
    color_name: "Jonquil",
    hexcode: "#F4CA16",
  },
  {
    color_name: "Jordy Blue",
    hexcode: "#8AB9F1",
  },
  {
    color_name: "June Bud",
    hexcode: "#BDDA57",
  },
  {
    color_name: "Jungle Green",
    hexcode: "#29AB87",
  },
  {
    color_name: "Kelly Green",
    hexcode: "#4CBB17",
  },
  {
    color_name: "Kenyan Copper",
    hexcode: "#7C1C05",
  },
  {
    color_name: "Keppel",
    hexcode: "#3AB09E",
  },
  {
    color_name: "Key Lime",
    hexcode: "#E8F48C",
  },
  {
    color_name: "Khaki (HTML/CSS) (Khaki)",
    hexcode: "#C3B091",
  },
  {
    color_name: "Khaki (X11) (Light Khaki)",
    hexcode: "#F0E68C",
  },
  {
    color_name: "Kiwi",
    hexcode: "#8EE53F",
  },
  {
    color_name: "Kobe",
    hexcode: "#882D17",
  },
  {
    color_name: "Kobi",
    hexcode: "#E79FC4",
  },
  {
    color_name: "Kobicha",
    hexcode: "#6B4423",
  },
  {
    color_name: "Kombu Green",
    hexcode: "#354230",
  },
  {
    color_name: "KSU Purple",
    hexcode: "#512888",
  },
  {
    color_name: "KU Crimson",
    hexcode: "#E8000D",
  },
  {
    color_name: "La Salle Green",
    hexcode: "#087830",
  },
  {
    color_name: "Languid Lavender",
    hexcode: "#D6CADD",
  },
  {
    color_name: "Lapis Lazuli",
    hexcode: "#26619C",
  },
  {
    color_name: "Laser Lemon",
    hexcode: "#FFFF66",
  },
  {
    color_name: "Laurel Green",
    hexcode: "#A9BA9D",
  },
  {
    color_name: "Lava",
    hexcode: "#CF1020",
  },
  {
    color_name: "Lavender (Floral)",
    hexcode: "#B57EDC",
  },
  {
    color_name: "Lavender (Web)",
    hexcode: "#E6E6FA",
  },
  {
    color_name: "Lavender Blue",
    hexcode: "#CCCCFF",
  },
  {
    color_name: "Lavender Blush",
    hexcode: "#FFF0F5",
  },
  {
    color_name: "Lavender Gray",
    hexcode: "#C4C3D0",
  },
  {
    color_name: "Lavender Indigo",
    hexcode: "#9457EB",
  },
  {
    color_name: "Lavender Magenta",
    hexcode: "#EE82EE",
  },
  {
    color_name: "Lavender Mist",
    hexcode: "#E6E6FA",
  },
  {
    color_name: "Lavender Pink",
    hexcode: "#FBAED2",
  },
  {
    color_name: "Lavender Purple",
    hexcode: "#967BB6",
  },
  {
    color_name: "Lavender Rose",
    hexcode: "#FBA0E3",
  },
  {
    color_name: "Lawn Green",
    hexcode: "#7CFC00",
  },
  {
    color_name: "Lemon",
    hexcode: "#FFF700",
  },
  {
    color_name: "Lemon Chiffon",
    hexcode: "#FFFACD",
  },
  {
    color_name: "Lemon Curry",
    hexcode: "#CCA01D",
  },
  {
    color_name: "Lemon Glacier",
    hexcode: "#FDFF00",
  },
  {
    color_name: "Lemon Lime",
    hexcode: "#E3FF00",
  },
  {
    color_name: "Lemon Meringue",
    hexcode: "#F6EABE",
  },
  {
    color_name: "Lemon Yellow",
    hexcode: "#FFF44F",
  },
  {
    color_name: "Licorice",
    hexcode: "#1A1110",
  },
  {
    color_name: "Liberty",
    hexcode: "#545AA7",
  },
  {
    color_name: "Light Apricot",
    hexcode: "#FDD5B1",
  },
  {
    color_name: "Light Blue",
    hexcode: "#ADD8E6",
  },
  {
    color_name: "Light Brown",
    hexcode: "#B5651D",
  },
  {
    color_name: "Light Carmine Pink",
    hexcode: "#E66771",
  },
  {
    color_name: "Light Cobalt Blue",
    hexcode: "#88ACE0",
  },
  {
    color_name: "Light Coral",
    hexcode: "#F08080",
  },
  {
    color_name: "Light Cornflower Blue",
    hexcode: "#93CCEA",
  },
  {
    color_name: "Light Crimson",
    hexcode: "#F56991",
  },
  {
    color_name: "Light Cyan",
    hexcode: "#E0FFFF",
  },
  {
    color_name: "Light Deep Pink",
    hexcode: "#FF5CCD",
  },
  {
    color_name: "Light French Beige",
    hexcode: "#C8AD7F",
  },
  {
    color_name: "Light Fuchsia Pink",
    hexcode: "#F984EF",
  },
  {
    color_name: "Light Goldenrod Yellow",
    hexcode: "#FAFAD2",
  },
  {
    color_name: "Light Gray",
    hexcode: "#D3D3D3",
  },
  {
    color_name: "Light Grayish Magenta",
    hexcode: "#CC99CC",
  },
  {
    color_name: "Light Green",
    hexcode: "#90EE90",
  },
  {
    color_name: "Light Hot Pink",
    hexcode: "#FFB3DE",
  },
  {
    color_name: "Light Khaki",
    hexcode: "#F0E68C",
  },
  {
    color_name: "Light Medium Orchid",
    hexcode: "#D39BCB",
  },
  {
    color_name: "Light Moss Green",
    hexcode: "#ADDFAD",
  },
  {
    color_name: "Light Orange",
    hexcode: "#FED8B1",
  },
  {
    color_name: "Light Orchid",
    hexcode: "#E6A8D7",
  },
  {
    color_name: "Light Pastel Purple",
    hexcode: "#B19CD9",
  },
  {
    color_name: "Light Pink",
    hexcode: "#FFB6C1",
  },
  {
    color_name: "Light Red Ochre",
    hexcode: "#E97451",
  },
  {
    color_name: "Light Salmon",
    hexcode: "#FFA07A",
  },
  {
    color_name: "Light Salmon Pink",
    hexcode: "#FF9999",
  },
  {
    color_name: "Light Sea Green",
    hexcode: "#20B2AA",
  },
  {
    color_name: "Light Sky Blue",
    hexcode: "#87CEFA",
  },
  {
    color_name: "Light Slate Gray",
    hexcode: "#778899",
  },
  {
    color_name: "Light Steel Blue",
    hexcode: "#B0C4DE",
  },
  {
    color_name: "Light Taupe",
    hexcode: "#B38B6D",
  },
  {
    color_name: "Light Thulian Pink",
    hexcode: "#E68FAC",
  },
  {
    color_name: "Light Yellow",
    hexcode: "#FFFFE0",
  },
  {
    color_name: "Lilac",
    hexcode: "#C8A2C8",
  },
  {
    color_name: "Lilac Luster",
    hexcode: "#AE98AA",
  },
  {
    color_name: "Lime (Color Wheel)",
    hexcode: "#BFFF00",
  },
  {
    color_name: "Lime (Web) (X11 Green)",
    hexcode: "#00FF00",
  },
  {
    color_name: "Lime Green",
    hexcode: "#32CD32",
  },
  {
    color_name: "Limerick",
    hexcode: "#9DC209",
  },
  {
    color_name: "Lincoln Green",
    hexcode: "#195905",
  },
  {
    color_name: "Linen",
    hexcode: "#FAF0E6",
  },
  {
    color_name: "Loeen (Lopen) Look",
    hexcode: "#15F2FD",
  },
  {
    color_name: "Liseran Purple",
    hexcode: "#DE6FA1",
  },
  {
    color_name: "Little Boy Blue",
    hexcode: "#6CA0DC",
  },
  {
    color_name: "Liver",
    hexcode: "#674C47",
  },
  {
    color_name: "Liver (Dogs)",
    hexcode: "#B86D29",
  },
  {
    color_name: "Liver (Organ)",
    hexcode: "#6C2E1F",
  },
  {
    color_name: "Liver Chestnut",
    hexcode: "#987456",
  },
  {
    color_name: "Livid",
    hexcode: "#6699CC",
  },
  {
    color_name: "Lumber",
    hexcode: "#FFE4CD",
  },
  {
    color_name: "Lust",
    hexcode: "#E62020",
  },
  {
    color_name: "Maastricht Blue",
    hexcode: "#001C3D",
  },
  {
    color_name: "Macaroni And Cheese",
    hexcode: "#FFBD88",
  },
  {
    color_name: "Madder Lake",
    hexcode: "#CC3336",
  },
  {
    color_name: "Magenta",
    hexcode: "#FF00FF",
  },
  {
    color_name: "Magenta (Crayola)",
    hexcode: "#FF55A3",
  },
  {
    color_name: "Magenta (Dye)",
    hexcode: "#CA1F7B",
  },
  {
    color_name: "Magenta (Pantone)",
    hexcode: "#D0417E",
  },
  {
    color_name: "Magenta (Process)",
    hexcode: "#FF0090",
  },
  {
    color_name: "Magenta Haze",
    hexcode: "#9F4576",
  },
  {
    color_name: "Magenta-Pink",
    hexcode: "#CC338B",
  },
  {
    color_name: "Magic Mint",
    hexcode: "#AAF0D1",
  },
  {
    color_name: "Magic Potion",
    hexcode: "#FF4466",
  },
  {
    color_name: "Magnolia",
    hexcode: "#F8F4FF",
  },
  {
    color_name: "Mahogany",
    hexcode: "#C04000",
  },
  {
    color_name: "Maize",
    hexcode: "#FBEC5D",
  },
  {
    color_name: "Majorelle Blue",
    hexcode: "#6050DC",
  },
  {
    color_name: "Malachite",
    hexcode: "#0BDA51",
  },
  {
    color_name: "Manatee",
    hexcode: "#979AAA",
  },
  {
    color_name: "Mandarin",
    hexcode: "#F37A48",
  },
  {
    color_name: "Mango Tango",
    hexcode: "#FF8243",
  },
  {
    color_name: "Mantis",
    hexcode: "#74C365",
  },
  {
    color_name: "Mardi Gras",
    hexcode: "#880085",
  },
  {
    color_name: "Marigold",
    hexcode: "#EAA221",
  },
  {
    color_name: "Maroon (Crayola)",
    hexcode: "#C32148",
  },
  {
    color_name: "Maroon (HTML/CSS)",
    hexcode: "#800000",
  },
  {
    color_name: "Maroon (X11)",
    hexcode: "#B03060",
  },
  {
    color_name: "Mauve",
    hexcode: "#E0B0FF",
  },
  {
    color_name: "Mauve Taupe",
    hexcode: "#915F6D",
  },
  {
    color_name: "Mauvelous",
    hexcode: "#EF98AA",
  },
  {
    color_name: "Maximum Blue",
    hexcode: "#47ABCC",
  },
  {
    color_name: "Maximum Blue Green",
    hexcode: "#30BFBF",
  },
  {
    color_name: "Maximum Blue Purple",
    hexcode: "#ACACE6",
  },
  {
    color_name: "Maximum Green",
    hexcode: "#5E8C31",
  },
  {
    color_name: "Maximum Green Yellow",
    hexcode: "#D9E650",
  },
  {
    color_name: "Maximum Purple",
    hexcode: "#733380",
  },
  {
    color_name: "Maximum Red",
    hexcode: "#D92121",
  },
  {
    color_name: "Maximum Red Purple",
    hexcode: "#A63A79",
  },
  {
    color_name: "Maximum Yellow",
    hexcode: "#FAFA37",
  },
  {
    color_name: "Maximum Yellow Red",
    hexcode: "#F2BA49",
  },
  {
    color_name: "May Green",
    hexcode: "#4C9141",
  },
  {
    color_name: "Maya Blue",
    hexcode: "#73C2FB",
  },
  {
    color_name: "Meat Brown",
    hexcode: "#E5B73B",
  },
  {
    color_name: "Medium Aquamarine",
    hexcode: "#66DDAA",
  },
  {
    color_name: "Medium Blue",
    hexcode: "#0000CD",
  },
  {
    color_name: "Medium Candy Apple Red",
    hexcode: "#E2062C",
  },
  {
    color_name: "Medium Carmine",
    hexcode: "#AF4035",
  },
  {
    color_name: "Medium Champagne",
    hexcode: "#F3E5AB",
  },
  {
    color_name: "Medium Electric Blue",
    hexcode: "#035096",
  },
  {
    color_name: "Medium Jungle Green",
    hexcode: "#1C352D",
  },
  {
    color_name: "Medium Lavender Magenta",
    hexcode: "#DDA0DD",
  },
  {
    color_name: "Medium Orchid",
    hexcode: "#BA55D3",
  },
  {
    color_name: "Medium Persian Blue",
    hexcode: "#0067A5",
  },
  {
    color_name: "Medium Purple",
    hexcode: "#9370DB",
  },
  {
    color_name: "Medium Red-Violet",
    hexcode: "#BB3385",
  },
  {
    color_name: "Medium Ruby",
    hexcode: "#AA4069",
  },
  {
    color_name: "Medium Sea Green",
    hexcode: "#3CB371",
  },
  {
    color_name: "Medium Sky Blue",
    hexcode: "#80DAEB",
  },
  {
    color_name: "Medium Slate Blue",
    hexcode: "#7B68EE",
  },
  {
    color_name: "Medium Spring Bud",
    hexcode: "#C9DC87",
  },
  {
    color_name: "Medium Spring Green",
    hexcode: "#00FA9A",
  },
  {
    color_name: "Medium Taupe",
    hexcode: "#674C47",
  },
  {
    color_name: "Medium Turquoise",
    hexcode: "#48D1CC",
  },
  {
    color_name: "Medium Tuscan Red",
    hexcode: "#79443B",
  },
  {
    color_name: "Medium Vermilion",
    hexcode: "#D9603B",
  },
  {
    color_name: "Medium Violet-Red",
    hexcode: "#C71585",
  },
  {
    color_name: "Mellow Apricot",
    hexcode: "#F8B878",
  },
  {
    color_name: "Mellow Yellow",
    hexcode: "#F8DE7E",
  },
  {
    color_name: "Melon",
    hexcode: "#FDBCB4",
  },
  {
    color_name: "Metallic Seaweed",
    hexcode: "#0A7E8C",
  },
  {
    color_name: "Metallic Sunburst",
    hexcode: "#9C7C38",
  },
  {
    color_name: "Mexican Pink",
    hexcode: "#E4007C",
  },
  {
    color_name: "Middle Blue",
    hexcode: "#7ED4E6",
  },
  {
    color_name: "Middle Blue Green",
    hexcode: "#8DD9CC",
  },
  {
    color_name: "Middle Blue Purple",
    hexcode: "#8B72BE",
  },
  {
    color_name: "Middle Red Purple",
    hexcode: "#210837",
  },
  {
    color_name: "Middle Green",
    hexcode: "#4D8C57",
  },
  {
    color_name: "Middle Green Yellow",
    hexcode: "#ACBF60",
  },
  {
    color_name: "Middle Purple",
    hexcode: "#D982B5",
  },
  {
    color_name: "Middle Red",
    hexcode: "#E58E73",
  },
  {
    color_name: "Middle Red Purple",
    hexcode: "#A55353",
  },
  {
    color_name: "Middle Yellow",
    hexcode: "#FFEB00",
  },
  {
    color_name: "Middle Yellow Red",
    hexcode: "#ECB176",
  },
  {
    color_name: "Midnight",
    hexcode: "#702670",
  },
  {
    color_name: "Midnight Blue",
    hexcode: "#191970",
  },
  {
    color_name: "Midnight Green (Eagle Green)",
    hexcode: "#004953",
  },
  {
    color_name: "Mikado Yellow",
    hexcode: "#FFC40C",
  },
  {
    color_name: "Milk",
    hexcode: "#FDFFF5",
  },
  {
    color_name: "Mimi Pink",
    hexcode: "#FFDAE9",
  },
  {
    color_name: "Mindaro",
    hexcode: "#E3F988",
  },
  {
    color_name: "Ming",
    hexcode: "#36747D",
  },
  {
    color_name: "Minion Yellow",
    hexcode: "#F5E050",
  },
  {
    color_name: "Mint",
    hexcode: "#3EB489",
  },
  {
    color_name: "Mint Cream",
    hexcode: "#F5FFFA",
  },
  {
    color_name: "Mint Green",
    hexcode: "#98FF98",
  },
  {
    color_name: "Misty Moss",
    hexcode: "#BBB477",
  },
  {
    color_name: "Misty Rose",
    hexcode: "#FFE4E1",
  },
  {
    color_name: "Moccasin",
    hexcode: "#FAEBD7",
  },
  {
    color_name: "Mode Beige",
    hexcode: "#967117",
  },
  {
    color_name: "Moonstone Blue",
    hexcode: "#73A9C2",
  },
  {
    color_name: "Mordant Red 19",
    hexcode: "#AE0C00",
  },
  {
    color_name: "Morning Blue",
    hexcode: "#8DA399",
  },
  {
    color_name: "Moss Green",
    hexcode: "#8A9A5B",
  },
  {
    color_name: "Mountain Meadow",
    hexcode: "#30BA8F",
  },
  {
    color_name: "Mountbatten Pink",
    hexcode: "#997A8D",
  },
  {
    color_name: "MSU Green",
    hexcode: "#18453B",
  },
  {
    color_name: "Mughal Green",
    hexcode: "#306030",
  },
  {
    color_name: "Mulberry",
    hexcode: "#C54B8C",
  },
  {
    color_name: "Mummy's Tomb",
    hexcode: "#828E84",
  },
  {
    color_name: "Mustard",
    hexcode: "#FFDB58",
  },
  {
    color_name: "Myrtle Green",
    hexcode: "#317873",
  },
  {
    color_name: "Mystic",
    hexcode: "#D65282",
  },
  {
    color_name: "Mystic Maroon",
    hexcode: "#AD4379",
  },
  {
    color_name: "Nadeshiko Pink",
    hexcode: "#F6ADC6",
  },
  {
    color_name: "Napier Green",
    hexcode: "#2A8000",
  },
  {
    color_name: "Naples Yellow",
    hexcode: "#FADA5E",
  },
  {
    color_name: "Navajo White",
    hexcode: "#FFDEAD",
  },
  {
    color_name: "Navy",
    hexcode: "#000080",
  },
  {
    color_name: "Navy Purple",
    hexcode: "#9457EB",
  },
  {
    color_name: "Neon Carrot",
    hexcode: "#FFA343",
  },
  {
    color_name: "Neon Fuchsia",
    hexcode: "#FE4164",
  },
  {
    color_name: "Neon Green",
    hexcode: "#39FF14",
  },
  {
    color_name: "New Car",
    hexcode: "#214FC6",
  },
  {
    color_name: "New York Pink",
    hexcode: "#D7837F",
  },
  {
    color_name: "Nickel",
    hexcode: "#727472",
  },
  {
    color_name: "Non-Photo Blue",
    hexcode: "#A4DDED",
  },
  {
    color_name: "North Texas Green",
    hexcode: "#059033",
  },
  {
    color_name: "Nyanza",
    hexcode: "#E9FFDB",
  },
  {
    color_name: "Ocean Blue",
    hexcode: "#4F42B5",
  },
  {
    color_name: "Ocean Boat Blue",
    hexcode: "#0077BE",
  },
  {
    color_name: "Ocean Green",
    hexcode: "#48BF91",
  },
  {
    color_name: "Ochre",
    hexcode: "#CC7722",
  },
  {
    color_name: "Office Green",
    hexcode: "#008000",
  },
  {
    color_name: "Ogre Odor",
    hexcode: "#FD5240",
  },
  {
    color_name: "Old Burgundy",
    hexcode: "#43302E",
  },
  {
    color_name: "Old Gold",
    hexcode: "#CFB53B",
  },
  {
    color_name: "Old Heliotrope",
    hexcode: "#563C5C",
  },
  {
    color_name: "Old Lace",
    hexcode: "#FDF5E6",
  },
  {
    color_name: "Old Lavender",
    hexcode: "#796878",
  },
  {
    color_name: "Old Mauve",
    hexcode: "#673147",
  },
  {
    color_name: "Old Moss Green",
    hexcode: "#867E36",
  },
  {
    color_name: "Old Rose",
    hexcode: "#C08081",
  },
  {
    color_name: "Old Silver",
    hexcode: "#848482",
  },
  {
    color_name: "Olive",
    hexcode: "#808000",
  },
  {
    color_name: "Olive Drab (#3)",
    hexcode: "#6B8E23",
  },
  {
    color_name: "Olive Drab #7",
    hexcode: "#3C341F",
  },
  {
    color_name: "Olivine",
    hexcode: "#9AB973",
  },
  {
    color_name: "Onyx",
    hexcode: "#353839",
  },
  {
    color_name: "Opera Mauve",
    hexcode: "#B784A7",
  },
  {
    color_name: "Orange (Color Wheel)",
    hexcode: "#FF7F00",
  },
  {
    color_name: "Orange (Crayola)",
    hexcode: "#FF7538",
  },
  {
    color_name: "Orange (Pantone)",
    hexcode: "#FF5800",
  },
  {
    color_name: "Orange (RYB)",
    hexcode: "#FB9902",
  },
  {
    color_name: "Orange (Web)",
    hexcode: "#FFA500",
  },
  {
    color_name: "Orange Peel",
    hexcode: "#FF9F00",
  },
  {
    color_name: "Orange-Red",
    hexcode: "#FF4500",
  },
  {
    color_name: "Orange Soda",
    hexcode: "#FA5B3D",
  },
  {
    color_name: "Orange-Yellow",
    hexcode: "#F8D568",
  },
  {
    color_name: "Orchid",
    hexcode: "#DA70D6",
  },
  {
    color_name: "Orchid Pink",
    hexcode: "#F2BDCD",
  },
  {
    color_name: "Orioles Orange",
    hexcode: "#FB4F14",
  },
  {
    color_name: "Otter Brown",
    hexcode: "#654321",
  },
  {
    color_name: "Outer Space",
    hexcode: "#414A4C",
  },
  {
    color_name: "Outrageous Orange",
    hexcode: "#FF6E4A",
  },
  {
    color_name: "Oxford Blue",
    hexcode: "#002147",
  },
  {
    color_name: "OU Crimson Red",
    hexcode: "#990000",
  },
  {
    color_name: "Pacific Blue",
    hexcode: "#1CA9C9",
  },
  {
    color_name: "Pakistan Green",
    hexcode: "#006600",
  },
  {
    color_name: "Palatinate Blue",
    hexcode: "#273BE2",
  },
  {
    color_name: "Palatinate Purple",
    hexcode: "#682860",
  },
  {
    color_name: "Pale Aqua",
    hexcode: "#BCD4E6",
  },
  {
    color_name: "Pale Blue",
    hexcode: "#AFEEEE",
  },
  {
    color_name: "Pale Brown",
    hexcode: "#987654",
  },
  {
    color_name: "Pale Carmine",
    hexcode: "#AF4035",
  },
  {
    color_name: "Pale Cerulean",
    hexcode: "#9BC4E2",
  },
  {
    color_name: "Pale Chestnut",
    hexcode: "#DDADAF",
  },
  {
    color_name: "Pale Copper",
    hexcode: "#DA8A67",
  },
  {
    color_name: "Pale Cornflower Blue",
    hexcode: "#ABCDEF",
  },
  {
    color_name: "Pale Cyan",
    hexcode: "#87D3F8",
  },
  {
    color_name: "Pale Gold",
    hexcode: "#E6BE8A",
  },
  {
    color_name: "Pale Goldenrod",
    hexcode: "#EEE8AA",
  },
  {
    color_name: "Pale Green",
    hexcode: "#98FB98",
  },
  {
    color_name: "Pale Lavender",
    hexcode: "#DCD0FF",
  },
  {
    color_name: "Pale Magenta",
    hexcode: "#F984E5",
  },
  {
    color_name: "Pale Magenta-Pink",
    hexcode: "#FF99CC",
  },
  {
    color_name: "Pale Pink",
    hexcode: "#FADADD",
  },
  {
    color_name: "Pale Plum",
    hexcode: "#DDA0DD",
  },
  {
    color_name: "Pale Red-Violet",
    hexcode: "#DB7093",
  },
  {
    color_name: "Pale Robin Egg Blue",
    hexcode: "#96DED1",
  },
  {
    color_name: "Pale Silver",
    hexcode: "#C9C0BB",
  },
  {
    color_name: "Pale Spring Bud",
    hexcode: "#ECEBBD",
  },
  {
    color_name: "Pale Taupe",
    hexcode: "#BC987E",
  },
  {
    color_name: "Pale Turquoise",
    hexcode: "#AFEEEE",
  },
  {
    color_name: "Pale Violet",
    hexcode: "#CC99FF",
  },
  {
    color_name: "Pale Violet-Red",
    hexcode: "#DB7093",
  },
  {
    color_name: "Palm Leaf",
    hexcode: "#6F9940",
  },
  {
    color_name: "Pansy Purple",
    hexcode: "#78184A",
  },
  {
    color_name: "Paolo Veronese Green",
    hexcode: "#009B7D",
  },
  {
    color_name: "Papaya Whip",
    hexcode: "#FFEFD5",
  },
  {
    color_name: "Paradise Pink",
    hexcode: "#E63E62",
  },
  {
    color_name: "Paris Green",
    hexcode: "#50C878",
  },
  {
    color_name: "Parrot Pink",
    hexcode: "#D998A0",
  },
  {
    color_name: "Pastel Blue",
    hexcode: "#AEC6CF",
  },
  {
    color_name: "Pastel Brown",
    hexcode: "#836953",
  },
  {
    color_name: "Pastel Gray",
    hexcode: "#CFCFC4",
  },
  {
    color_name: "Pastel Green",
    hexcode: "#77DD77",
  },
  {
    color_name: "Pastel Magenta",
    hexcode: "#F49AC2",
  },
  {
    color_name: "Pastel Orange",
    hexcode: "#FFB347",
  },
  {
    color_name: "Pastel Pink",
    hexcode: "#DEA5A4",
  },
  {
    color_name: "Pastel Purple",
    hexcode: "#B39EB5",
  },
  {
    color_name: "Pastel Red",
    hexcode: "#FF6961",
  },
  {
    color_name: "Pastel Violet",
    hexcode: "#CB99C9",
  },
  {
    color_name: "Pastel Yellow",
    hexcode: "#FDFD96",
  },
  {
    color_name: "Patriarch",
    hexcode: "#800080",
  },
  {
    color_name: "Payne's Grey",
    hexcode: "#536878",
  },
  {
    color_name: "Peach",
    hexcode: "#FFE5B4",
  },
  {
    color_name: "Peach",
    hexcode: "#FFCBA4",
  },
  {
    color_name: "Peach-Orange",
    hexcode: "#FFCC99",
  },
  {
    color_name: "Peach Puff",
    hexcode: "#FFDAB9",
  },
  {
    color_name: "Peach-Yellow",
    hexcode: "#FADFAD",
  },
  {
    color_name: "Pear",
    hexcode: "#D1E231",
  },
  {
    color_name: "Pearl",
    hexcode: "#EAE0C8",
  },
  {
    color_name: "Pearl Aqua",
    hexcode: "#88D8C0",
  },
  {
    color_name: "Pearly Purple",
    hexcode: "#B768A2",
  },
  {
    color_name: "Peridot",
    hexcode: "#E6E200",
  },
  {
    color_name: "Periwinkle",
    hexcode: "#CCCCFF",
  },
  {
    color_name: "Permanent Geranium Lake",
    hexcode: "#E12C2C",
  },
  {
    color_name: "Persian Blue",
    hexcode: "#1C39BB",
  },
  {
    color_name: "Persian Green",
    hexcode: "#00A693",
  },
  {
    color_name: "Persian Indigo",
    hexcode: "#32127A",
  },
  {
    color_name: "Persian Orange",
    hexcode: "#D99058",
  },
  {
    color_name: "Persian Pink",
    hexcode: "#F77FBE",
  },
  {
    color_name: "Persian Plum",
    hexcode: "#701C1C",
  },
  {
    color_name: "Persian Red",
    hexcode: "#CC3333",
  },
  {
    color_name: "Persian Rose",
    hexcode: "#FE28A2",
  },
  {
    color_name: "Persimmon",
    hexcode: "#EC5800",
  },
  {
    color_name: "Peru",
    hexcode: "#CD853F",
  },
  {
    color_name: "Pewter Blue",
    hexcode: "#8BA8B7",
  },
  {
    color_name: "Phlox",
    hexcode: "#DF00FF",
  },
  {
    color_name: "Phthalo Blue",
    hexcode: "#000F89",
  },
  {
    color_name: "Phthalo Green",
    hexcode: "#123524",
  },
  {
    color_name: "Picton Blue",
    hexcode: "#45B1E8",
  },
  {
    color_name: "Pictorial Carmine",
    hexcode: "#C30B4E",
  },
  {
    color_name: "Piggy Pink",
    hexcode: "#FDDDE6",
  },
  {
    color_name: "Pine Green",
    hexcode: "#01796F",
  },
  {
    color_name: "Pineapple",
    hexcode: "#563C0D",
  },
  {
    color_name: "Pink",
    hexcode: "#FFC0CB",
  },
  {
    color_name: "Pink (Pantone)",
    hexcode: "#D74894",
  },
  {
    color_name: "Pink Flamingo",
    hexcode: "#FC74FD",
  },
  {
    color_name: "Pink Lace",
    hexcode: "#FFDDF4",
  },
  {
    color_name: "Pink Lavender",
    hexcode: "#D8B2D1",
  },
  {
    color_name: "Pink-Orange",
    hexcode: "#FF9966",
  },
  {
    color_name: "Pink Pearl",
    hexcode: "#E7ACCF",
  },
  {
    color_name: "Pink Raspberry",
    hexcode: "#980036",
  },
  {
    color_name: "Pink Sherbet",
    hexcode: "#F78FA7",
  },
  {
    color_name: "Pistachio",
    hexcode: "#93C572",
  },
  {
    color_name: "Pixie Powder",
    hexcode: "#391285",
  },
  {
    color_name: "Platinum",
    hexcode: "#E5E4E2",
  },
  {
    color_name: "Plum",
    hexcode: "#8E4585",
  },
  {
    color_name: "Plum (Web)",
    hexcode: "#DDA0DD",
  },
  {
    color_name: "Plump Purple",
    hexcode: "#5946B2",
  },
  {
    color_name: "Polished Pine",
    hexcode: "#5DA493",
  },
  {
    color_name: "Pomp And Power",
    hexcode: "#86608E",
  },
  {
    color_name: "Popstar",
    hexcode: "#BE4F62",
  },
  {
    color_name: "Portland Orange",
    hexcode: "#FF5A36",
  },
  {
    color_name: "Powder Blue",
    hexcode: "#B0E0E6",
  },
  {
    color_name: "Princess Perfume",
    hexcode: "#FF85CF",
  },
  {
    color_name: "Princeton Orange",
    hexcode: "#F58025",
  },
  {
    color_name: "Prune",
    hexcode: "#701C1C",
  },
  {
    color_name: "Prussian Blue",
    hexcode: "#003153",
  },
  {
    color_name: "Psychedelic Purple",
    hexcode: "#DF00FF",
  },
  {
    color_name: "Puce",
    hexcode: "#CC8899",
  },
  {
    color_name: "Puce Red",
    hexcode: "#722F37",
  },
  {
    color_name: "Pullman Brown (UPS Brown)",
    hexcode: "#644117",
  },
  {
    color_name: "Pullman Green",
    hexcode: "#3B331C",
  },
  {
    color_name: "Pumpkin",
    hexcode: "#FF7518",
  },
  {
    color_name: "Purple (HTML)",
    hexcode: "#800080",
  },
  {
    color_name: "Purple (Munsell)",
    hexcode: "#9F00C5",
  },
  {
    color_name: "Purple (X11)",
    hexcode: "#A020F0",
  },
  {
    color_name: "Purple Heart",
    hexcode: "#69359C",
  },
  {
    color_name: "Purple Mist",
    hexcode: "#8560a2",
  },
  {
    color_name: "Purple Mountain Majesty",
    hexcode: "#9678B6",
  },
  {
    color_name: "Purple Navy",
    hexcode: "#4E5180",
  },
  {
    color_name: "Purple Pizzazz",
    hexcode: "#FE4EDA",
  },
  {
    color_name: "Purple Plum",
    hexcode: "#9C51B6",
  },
  {
    color_name: "Purple Taupe",
    hexcode: "#50404D",
  },
  {
    color_name: "Purpureus",
    hexcode: "#9A4EAE",
  },
  {
    color_name: "Quartz",
    hexcode: "#51484F",
  },
  {
    color_name: "Queen Blue",
    hexcode: "#436B95",
  },
  {
    color_name: "Queen Pink",
    hexcode: "#E8CCD7",
  },
  {
    color_name: "Quick Silver",
    hexcode: "#A6A6A6",
  },
  {
    color_name: "Quinacridone Magenta",
    hexcode: "#8E3A59",
  },
  {
    color_name: "Rackley",
    hexcode: "#5D8AA8",
  },
  {
    color_name: "Radical Red",
    hexcode: "#FF355E",
  },
  {
    color_name: "Raisin Black",
    hexcode: "#242124",
  },
  {
    color_name: "Rajah",
    hexcode: "#FBAB60",
  },
  {
    color_name: "Raspberry",
    hexcode: "#E30B5D",
  },
  {
    color_name: "Raspberry Glace",
    hexcode: "#915F6D",
  },
  {
    color_name: "Raspberry Pink",
    hexcode: "#E25098",
  },
  {
    color_name: "Raspberry Rose",
    hexcode: "#B3446C",
  },
  {
    color_name: "Raw Sienna",
    hexcode: "#D68A59",
  },
  {
    color_name: "Raw Umber",
    hexcode: "#826644",
  },
  {
    color_name: "Razzle Dazzle Rose",
    hexcode: "#FF33CC",
  },
  {
    color_name: "Razzmatazz",
    hexcode: "#E3256B",
  },
  {
    color_name: "Razzmic Berry",
    hexcode: "#8D4E85",
  },
  {
    color_name: "Rebecca Purple",
    hexcode: "#663399",
  },
  {
    color_name: "Red",
    hexcode: "#FF0000",
  },
  {
    color_name: "Red (Crayola)",
    hexcode: "#EE204D",
  },
  {
    color_name: "Red (Munsell)",
    hexcode: "#F2003C",
  },
  {
    color_name: "Red (NCS)",
    hexcode: "#C40233",
  },
  {
    color_name: "Red (Pantone)",
    hexcode: "#ED2939",
  },
  {
    color_name: "Red (Pigment)",
    hexcode: "#ED1C24",
  },
  {
    color_name: "Red (RYB)",
    hexcode: "#FE2712",
  },
  {
    color_name: "Red-Brown",
    hexcode: "#A52A2A",
  },
  {
    color_name: "Red Devil",
    hexcode: "#860111",
  },
  {
    color_name: "Red-Orange",
    hexcode: "#FF5349",
  },
  {
    color_name: "Red-Purple",
    hexcode: "#E40078",
  },
  {
    color_name: "Red Salsa",
    hexcode: "#FD3A4A",
  },
  {
    color_name: "Red-Violet",
    hexcode: "#C71585",
  },
  {
    color_name: "Redwood",
    hexcode: "#A45A52",
  },
  {
    color_name: "Regalia",
    hexcode: "#522D80",
  },
  {
    color_name: "Registration Black",
    hexcode: "#000000",
  },
  {
    color_name: "Resolution Blue",
    hexcode: "#002387",
  },
  {
    color_name: "Rhythm",
    hexcode: "#777696",
  },
  {
    color_name: "Rich Black",
    hexcode: "#004040",
  },
  {
    color_name: "Rich Black (FOGRA29)",
    hexcode: "#010B13",
  },
  {
    color_name: "Rich Black (FOGRA39)",
    hexcode: "#010203",
  },
  {
    color_name: "Rich Brilliant Lavender",
    hexcode: "#F1A7FE",
  },
  {
    color_name: "Rich Carmine",
    hexcode: "#D70040",
  },
  {
    color_name: "Rich Electric Blue",
    hexcode: "#0892D0",
  },
  {
    color_name: "Rich Lavender",
    hexcode: "#A76BCF",
  },
  {
    color_name: "Rich Lilac",
    hexcode: "#B666D2",
  },
  {
    color_name: "Rich Maroon",
    hexcode: "#B03060",
  },
  {
    color_name: "Rifle Green",
    hexcode: "#444C38",
  },
  {
    color_name: "Roast Coffee",
    hexcode: "#704241",
  },
  {
    color_name: "Robin Egg Blue",
    hexcode: "#00CCCC",
  },
  {
    color_name: "Rocket Metallic",
    hexcode: "#8A7F80",
  },
  {
    color_name: "Roman Silver",
    hexcode: "#838996",
  },
  {
    color_name: "Rose",
    hexcode: "#FF007F",
  },
  {
    color_name: "Rose Bonbon",
    hexcode: "#F9429E",
  },
  {
    color_name: "Rose Dust",
    hexcode: "#9E5E6F",
  },
  {
    color_name: "Rose Ebony",
    hexcode: "#674846",
  },
  {
    color_name: "Rose Gold",
    hexcode: "#B76E79",
  },
  {
    color_name: "Rose Madder",
    hexcode: "#E32636",
  },
  {
    color_name: "Rose Pink",
    hexcode: "#FF66CC",
  },
  {
    color_name: "Rose Quartz",
    hexcode: "#AA98A9",
  },
  {
    color_name: "Rose Red",
    hexcode: "#C21E56",
  },
  {
    color_name: "Rose Taupe",
    hexcode: "#905D5D",
  },
  {
    color_name: "Rose Vale",
    hexcode: "#AB4E52",
  },
  {
    color_name: "Rosewood",
    hexcode: "#65000B",
  },
  {
    color_name: "Rosso Corsa",
    hexcode: "#D40000",
  },
  {
    color_name: "Rosy Brown",
    hexcode: "#BC8F8F",
  },
  {
    color_name: "Royal Azure",
    hexcode: "#0038A8",
  },
  {
    color_name: "Royal Blue",
    hexcode: "#002366",
  },
  {
    color_name: "Royal Blue",
    hexcode: "#4169E1",
  },
  {
    color_name: "Royal Fuchsia",
    hexcode: "#CA2C92",
  },
  {
    color_name: "Royal Purple",
    hexcode: "#7851A9",
  },
  {
    color_name: "Royal Yellow",
    hexcode: "#FADA5E",
  },
  {
    color_name: "Ruber",
    hexcode: "#CE4676",
  },
  {
    color_name: "Rubine Red",
    hexcode: "#D10056",
  },
  {
    color_name: "Ruby",
    hexcode: "#E0115F",
  },
  {
    color_name: "Ruby Red",
    hexcode: "#9B111E",
  },
  {
    color_name: "Ruddy",
    hexcode: "#FF0028",
  },
  {
    color_name: "Ruddy Brown",
    hexcode: "#BB6528",
  },
  {
    color_name: "Ruddy Pink",
    hexcode: "#E18E96",
  },
  {
    color_name: "Rufous",
    hexcode: "#A81C07",
  },
  {
    color_name: "Russet",
    hexcode: "#80461B",
  },
  {
    color_name: "Russian Green",
    hexcode: "#679267",
  },
  {
    color_name: "Russian Violet",
    hexcode: "#32174D",
  },
  {
    color_name: "Rust",
    hexcode: "#B7410E",
  },
  {
    color_name: "Rusty Red",
    hexcode: "#DA2C43",
  },
  {
    color_name: "Sacramento State Green",
    hexcode: "#00563F",
  },
  {
    color_name: "Saddle Brown",
    hexcode: "#8B4513",
  },
  {
    color_name: "Safety Orange",
    hexcode: "#FF7800",
  },
  {
    color_name: "Safety Orange (Blaze Orange)",
    hexcode: "#FF6700",
  },
  {
    color_name: "Safety Yellow",
    hexcode: "#EED202",
  },
  {
    color_name: "Saffron",
    hexcode: "#F4C430",
  },
  {
    color_name: "Sage",
    hexcode: "#BCB88A",
  },
  {
    color_name: "St. Patrick's Blue",
    hexcode: "#23297A",
  },
  {
    color_name: "Salmon",
    hexcode: "#FA8072",
  },
  {
    color_name: "Salmon Pink",
    hexcode: "#FF91A4",
  },
  {
    color_name: "Sand",
    hexcode: "#C2B280",
  },
  {
    color_name: "Sand Dune",
    hexcode: "#967117",
  },
  {
    color_name: "Sandstorm",
    hexcode: "#ECD540",
  },
  {
    color_name: "Sandy Brown",
    hexcode: "#F4A460",
  },
  {
    color_name: "Sandy Tan",
    hexcode: "#FDD9B5",
  },
  {
    color_name: "Sandy Taupe",
    hexcode: "#967117",
  },
  {
    color_name: "Sangria",
    hexcode: "#92000A",
  },
  {
    color_name: "Sap Green",
    hexcode: "#507D2A",
  },
  {
    color_name: "Sapphire",
    hexcode: "#0F52BA",
  },
  {
    color_name: "Sapphire Blue",
    hexcode: "#0067A5",
  },
  {
    color_name: "Sasquatch Socks",
    hexcode: "#FF4681",
  },
  {
    color_name: "Satin Sheen Gold",
    hexcode: "#CBA135",
  },
  {
    color_name: "Scarlet",
    hexcode: "#FF2400",
  },
  {
    color_name: "Scarlet",
    hexcode: "#FD0E35",
  },
  {
    color_name: "Schauss Pink",
    hexcode: "#FF91AF",
  },
  {
    color_name: "School Bus Yellow",
    hexcode: "#FFD800",
  },
  {
    color_name: "Screamin' Green",
    hexcode: "#66FF66",
  },
  {
    color_name: "Sea Blue",
    hexcode: "#006994",
  },
  {
    color_name: "Sea Foam Green",
    hexcode: "#9FE2BF",
  },
  {
    color_name: "Sea Green",
    hexcode: "#2E8B57",
  },
  {
    color_name: "Sea Serpent",
    hexcode: "#4BC7CF",
  },
  {
    color_name: "Seal Brown",
    hexcode: "#59260B",
  },
  {
    color_name: "Seashell",
    hexcode: "#FFF5EE",
  },
  {
    color_name: "Selective Yellow",
    hexcode: "#FFBA00",
  },
  {
    color_name: "Sepia",
    hexcode: "#704214",
  },
  {
    color_name: "Shadow",
    hexcode: "#8A795D",
  },
  {
    color_name: "Shadow Blue",
    hexcode: "#778BA5",
  },
  {
    color_name: "Shampoo",
    hexcode: "#FFCFF1",
  },
  {
    color_name: "Shamrock Green",
    hexcode: "#009E60",
  },
  {
    color_name: "Sheen Green",
    hexcode: "#8FD400",
  },
  {
    color_name: "Shimmering Blush",
    hexcode: "#D98695",
  },
  {
    color_name: "Shiny Shamrock",
    hexcode: "#5FA778",
  },
  {
    color_name: "Shocking Pink",
    hexcode: "#FC0FC0",
  },
  {
    color_name: "Shocking Pink (Crayola)",
    hexcode: "#FF6FFF",
  },
  {
    color_name: "Sienna",
    hexcode: "#882D17",
  },
  {
    color_name: "Silver",
    hexcode: "#C0C0C0",
  },
  {
    color_name: "Silver Chalice",
    hexcode: "#ACACAC",
  },
  {
    color_name: "Silver Lake Blue",
    hexcode: "#5D89BA",
  },
  {
    color_name: "Silver Pink",
    hexcode: "#C4AEAD",
  },
  {
    color_name: "Silver Sand",
    hexcode: "#BFC1C2",
  },
  {
    color_name: "Sinopia",
    hexcode: "#CB410B",
  },
  {
    color_name: "Sizzling Red",
    hexcode: "#FF3855",
  },
  {
    color_name: "Sizzling Sunrise",
    hexcode: "#FFDB00",
  },
  {
    color_name: "Skobeloff",
    hexcode: "#007474",
  },
  {
    color_name: "Sky Blue",
    hexcode: "#87CEEB",
  },
  {
    color_name: "Sky Magenta",
    hexcode: "#CF71AF",
  },
  {
    color_name: "Slate Blue",
    hexcode: "#6A5ACD",
  },
  {
    color_name: "Slate Gray",
    hexcode: "#708090",
  },
  {
    color_name: "Smalt (Dark Powder Blue)",
    hexcode: "#003399",
  },
  {
    color_name: "Slimy Green",
    hexcode: "#299617",
  },
  {
    color_name: "Smashed Pumpkin",
    hexcode: "#FF6D3A",
  },
  {
    color_name: "Smitten",
    hexcode: "#C84186",
  },
  {
    color_name: "Smoke",
    hexcode: "#738276",
  },
  {
    color_name: "Smokey Topaz",
    hexcode: "#832A0D",
  },
  {
    color_name: "Smoky Black",
    hexcode: "#100C08",
  },
  {
    color_name: "Smoky Topaz",
    hexcode: "#933D41",
  },
  {
    color_name: "Snow",
    hexcode: "#FFFAFA",
  },
  {
    color_name: "Soap",
    hexcode: "#CEC8EF",
  },
  {
    color_name: "Solid Pink",
    hexcode: "#893843",
  },
  {
    color_name: "Sonic Silver",
    hexcode: "#757575",
  },
  {
    color_name: "Spartan Crimson",
    hexcode: "#9E1316",
  },
  {
    color_name: "Space Cadet",
    hexcode: "#1D2951",
  },
  {
    color_name: "Spanish Bistre",
    hexcode: "#807532",
  },
  {
    color_name: "Spanish Blue",
    hexcode: "#0070B8",
  },
  {
    color_name: "Spanish Carmine",
    hexcode: "#D10047",
  },
  {
    color_name: "Spanish Crimson",
    hexcode: "#E51A4C",
  },
  {
    color_name: "Spanish Gray",
    hexcode: "#989898",
  },
  {
    color_name: "Spanish Green",
    hexcode: "#009150",
  },
  {
    color_name: "Spanish Orange",
    hexcode: "#E86100",
  },
  {
    color_name: "Spanish Pink",
    hexcode: "#F7BFBE",
  },
  {
    color_name: "Spanish Red",
    hexcode: "#E60026",
  },
  {
    color_name: "Spanish Sky Blue",
    hexcode: "#00FFFF",
  },
  {
    color_name: "Spanish Violet",
    hexcode: "#4C2882",
  },
  {
    color_name: "Spanish Viridian",
    hexcode: "#007F5C",
  },
  {
    color_name: "Spicy Mix",
    hexcode: "#8B5f4D",
  },
  {
    color_name: "Spiro Disco Ball",
    hexcode: "#0FC0FC",
  },
  {
    color_name: "Spring Bud",
    hexcode: "#A7FC00",
  },
  {
    color_name: "Spring Frost",
    hexcode: "#87FF2A",
  },
  {
    color_name: "Spring Green",
    hexcode: "#00FF7F",
  },
  {
    color_name: "Star Command Blue",
    hexcode: "#007BB8",
  },
  {
    color_name: "Steel Blue",
    hexcode: "#4682B4",
  },
  {
    color_name: "Steel Pink",
    hexcode: "#CC33CC",
  },
  {
    color_name: "Steel Teal",
    hexcode: "#5F8A8B",
  },
  {
    color_name: "Stil De Grain Yellow",
    hexcode: "#FADA5E",
  },
  {
    color_name: "Stizza",
    hexcode: "#990000",
  },
  {
    color_name: "Stormcloud",
    hexcode: "#4F666A",
  },
  {
    color_name: "Straw",
    hexcode: "#E4D96F",
  },
  {
    color_name: "Strawberry",
    hexcode: "#FC5A8D",
  },
  {
    color_name: "Sugar Plum",
    hexcode: "#914E75",
  },
  {
    color_name: "Sunburnt Cyclops",
    hexcode: "#FF404C",
  },
  {
    color_name: "Sunglow",
    hexcode: "#FFCC33",
  },
  {
    color_name: "Sunny",
    hexcode: "#F2F27A",
  },
  {
    color_name: "Sunray",
    hexcode: "#E3AB57",
  },
  {
    color_name: "Sunset",
    hexcode: "#FAD6A5",
  },
  {
    color_name: "Sunset Orange",
    hexcode: "#FD5E53",
  },
  {
    color_name: "Super Pink",
    hexcode: "#CF6BA9",
  },
  {
    color_name: "Sweet Brown",
    hexcode: "#A83731",
  },
  {
    color_name: "Tan",
    hexcode: "#D2B48C",
  },
  {
    color_name: "Tangelo",
    hexcode: "#F94D00",
  },
  {
    color_name: "Tangerine",
    hexcode: "#F28500",
  },
  {
    color_name: "Tangerine Yellow",
    hexcode: "#FFCC00",
  },
  {
    color_name: "Tango Pink",
    hexcode: "#E4717A",
  },
  {
    color_name: "Tart Orange",
    hexcode: "#FB4D46",
  },
  {
    color_name: "Taupe",
    hexcode: "#483C32",
  },
  {
    color_name: "Taupe Gray",
    hexcode: "#8B8589",
  },
  {
    color_name: "Tea Green",
    hexcode: "#D0F0C0",
  },
  {
    color_name: "Tea Rose",
    hexcode: "#F88379",
  },
  {
    color_name: "Tea Rose",
    hexcode: "#F4C2C2",
  },
  {
    color_name: "Teal",
    hexcode: "#008080",
  },
  {
    color_name: "Teal Blue",
    hexcode: "#367588",
  },
  {
    color_name: "Teal Deer",
    hexcode: "#99E6B3",
  },
  {
    color_name: "Teal Green",
    hexcode: "#00827F",
  },
  {
    color_name: "Telemagenta",
    hexcode: "#CF3476",
  },
  {
    color_name: "Tenne (Tawny)",
    hexcode: "#CD5700",
  },
  {
    color_name: "Terra Cotta",
    hexcode: "#E2725B",
  },
  {
    color_name: "Thistle",
    hexcode: "#D8BFD8",
  },
  {
    color_name: "Thulian Pink",
    hexcode: "#DE6FA1",
  },
  {
    color_name: "Tickle Me Pink",
    hexcode: "#FC89AC",
  },
  {
    color_name: "Tiffany Blue",
    hexcode: "#0ABAB5",
  },
  {
    color_name: "Tiger's Eye",
    hexcode: "#E08D3C",
  },
  {
    color_name: "Timberwolf",
    hexcode: "#DBD7D2",
  },
  {
    color_name: "Titanium Yellow",
    hexcode: "#EEE600",
  },
  {
    color_name: "Tomato",
    hexcode: "#FF6347",
  },
  {
    color_name: "Toolbox",
    hexcode: "#746CC0",
  },
  {
    color_name: "Topaz",
    hexcode: "#FFC87C",
  },
  {
    color_name: "Tractor Red",
    hexcode: "#FD0E35",
  },
  {
    color_name: "Trolley Grey",
    hexcode: "#808080",
  },
  {
    color_name: "Tropical Rain Forest",
    hexcode: "#00755E",
  },
  {
    color_name: "Tropical Violet",
    hexcode: "#CDA4DE",
  },
  {
    color_name: "True Blue",
    hexcode: "#0073CF",
  },
  {
    color_name: "Tufts Blue",
    hexcode: "#3E8EDE",
  },
  {
    color_name: "Tulip",
    hexcode: "#FF878D",
  },
  {
    color_name: "Tumbleweed",
    hexcode: "#DEAA88",
  },
  {
    color_name: "Turkish Rose",
    hexcode: "#B57281",
  },
  {
    color_name: "Turquoise",
    hexcode: "#40E0D0",
  },
  {
    color_name: "Turquoise Blue",
    hexcode: "#00FFEF",
  },
  {
    color_name: "Turquoise Green",
    hexcode: "#A0D6B4",
  },
  {
    color_name: "Turquoise Surf",
    hexcode: "#00C5CD",
  },
  {
    color_name: "Turtle Green",
    hexcode: "#8A9A5B",
  },
  {
    color_name: "Tuscan",
    hexcode: "#FAD6A5",
  },
  {
    color_name: "Tuscan Brown",
    hexcode: "#6F4E37",
  },
  {
    color_name: "Tuscan Red",
    hexcode: "#7C4848",
  },
  {
    color_name: "Tuscan Tan",
    hexcode: "#A67B5B",
  },
  {
    color_name: "Tuscany",
    hexcode: "#C09999",
  },
  {
    color_name: "Twilight Lavender",
    hexcode: "#8A496B",
  },
  {
    color_name: "Tyrian Purple",
    hexcode: "#66023C",
  },
  {
    color_name: "UA Blue",
    hexcode: "#0033AA",
  },
  {
    color_name: "UA Red",
    hexcode: "#D9004C",
  },
  {
    color_name: "Ube",
    hexcode: "#8878C3",
  },
  {
    color_name: "UCLA Blue",
    hexcode: "#536895",
  },
  {
    color_name: "UCLA Gold",
    hexcode: "#FFB300",
  },
  {
    color_name: "UFO Green",
    hexcode: "#3CD070",
  },
  {
    color_name: "Ultramarine",
    hexcode: "#3F00FF",
  },
  {
    color_name: "Ultramarine Blue",
    hexcode: "#4166F5",
  },
  {
    color_name: "Ultra Pink",
    hexcode: "#FF6FFF",
  },
  {
    color_name: "Ultra Red",
    hexcode: "#FC6C85",
  },
  {
    color_name: "Umber",
    hexcode: "#635147",
  },
  {
    color_name: "Unbleached Silk",
    hexcode: "#FFDDCA",
  },
  {
    color_name: "United Nations Blue",
    hexcode: "#5B92E5",
  },
  {
    color_name: "University Of California Gold",
    hexcode: "#B78727",
  },
  {
    color_name: "Unmellow Yellow",
    hexcode: "#FFFF66",
  },
  {
    color_name: "UP Forest Green",
    hexcode: "#014421",
  },
  {
    color_name: "UP Maroon",
    hexcode: "#7B1113",
  },
  {
    color_name: "Upsdell Red",
    hexcode: "#AE2029",
  },
  {
    color_name: "Urobilin",
    hexcode: "#E1AD21",
  },
  {
    color_name: "USAFA Blue",
    hexcode: "#004F98",
  },
  {
    color_name: "USC Cardinal",
    hexcode: "#990000",
  },
  {
    color_name: "USC Gold",
    hexcode: "#FFCC00",
  },
  {
    color_name: "University Of Tennessee Orange",
    hexcode: "#F77F00",
  },
  {
    color_name: "Utah Crimson",
    hexcode: "#D3003F",
  },
  {
    color_name: "Van Dyke Brown",
    hexcode: "#664228",
  },
  {
    color_name: "Vanilla",
    hexcode: "#F3E5AB",
  },
  {
    color_name: "Vanilla Ice",
    hexcode: "#F38FA9",
  },
  {
    color_name: "Vegas Gold",
    hexcode: "#C5B358",
  },
  {
    color_name: "Venetian Red",
    hexcode: "#C80815",
  },
  {
    color_name: "Verdigris",
    hexcode: "#43B3AE",
  },
  {
    color_name: "Vermilion",
    hexcode: "#E34234",
  },
  {
    color_name: "Vermilion",
    hexcode: "#D9381E",
  },
  {
    color_name: "Veronica",
    hexcode: "#A020F0",
  },
  {
    color_name: "Very Light Azure",
    hexcode: "#74BBFB",
  },
  {
    color_name: "Very Light Blue",
    hexcode: "#6666FF",
  },
  {
    color_name: "Very Light Malachite Green",
    hexcode: "#64E986",
  },
  {
    color_name: "Very Light Tangelo",
    hexcode: "#FFB077",
  },
  {
    color_name: "Very Pale Orange",
    hexcode: "#FFDFBF",
  },
  {
    color_name: "Very Pale Yellow",
    hexcode: "#FFFFBF",
  },
  {
    color_name: "Violet",
    hexcode: "#8F00FF",
  },
  {
    color_name: "Violet (Color Wheel)",
    hexcode: "#7F00FF",
  },
  {
    color_name: "Violet (RYB)",
    hexcode: "#8601AF",
  },
  {
    color_name: "Violet (Web)",
    hexcode: "#EE82EE",
  },
  {
    color_name: "Violet-Blue",
    hexcode: "#324AB2",
  },
  {
    color_name: "Violet-Red",
    hexcode: "#F75394",
  },
  {
    color_name: "Viridian",
    hexcode: "#40826D",
  },
  {
    color_name: "Viridian Green",
    hexcode: "#009698",
  },
  {
    color_name: "Vista Blue",
    hexcode: "#7C9ED9",
  },
  {
    color_name: "Vivid Amber",
    hexcode: "#CC9900",
  },
  {
    color_name: "Vivid Auburn",
    hexcode: "#922724",
  },
  {
    color_name: "Vivid Burgundy",
    hexcode: "#9F1D35",
  },
  {
    color_name: "Vivid Cerise",
    hexcode: "#DA1D81",
  },
  {
    color_name: "Vivid Cerulean",
    hexcode: "#00AAEE",
  },
  {
    color_name: "Vivid Crimson",
    hexcode: "#CC0033",
  },
  {
    color_name: "Vivid Gamboge",
    hexcode: "#FF9900",
  },
  {
    color_name: "Vivid Lime Green",
    hexcode: "#A6D608",
  },
  {
    color_name: "Vivid Malachite",
    hexcode: "#00CC33",
  },
  {
    color_name: "Vivid Mulberry",
    hexcode: "#B80CE3",
  },
  {
    color_name: "Vivid Orange",
    hexcode: "#FF5F00",
  },
  {
    color_name: "Vivid Orange Peel",
    hexcode: "#FFA000",
  },
  {
    color_name: "Vivid Orchid",
    hexcode: "#CC00FF",
  },
  {
    color_name: "Vivid Raspberry",
    hexcode: "#FF006C",
  },
  {
    color_name: "Vivid Red",
    hexcode: "#F70D1A",
  },
  {
    color_name: "Vivid Red-Tangelo",
    hexcode: "#DF6124",
  },
  {
    color_name: "Vivid Sky Blue",
    hexcode: "#00CCFF",
  },
  {
    color_name: "Vivid Tangelo",
    hexcode: "#F07427",
  },
  {
    color_name: "Vivid Tangerine",
    hexcode: "#FFA089",
  },
  {
    color_name: "Vivid Vermilion",
    hexcode: "#E56024",
  },
  {
    color_name: "Vivid Violet",
    hexcode: "#9F00FF",
  },
  {
    color_name: "Vivid Yellow",
    hexcode: "#FFE302",
  },
  {
    color_name: "Volt",
    hexcode: "#CEFF00",
  },
  {
    color_name: "Wageningen Green",
    hexcode: "#34B233",
  },
  {
    color_name: "Warm Black",
    hexcode: "#004242",
  },
  {
    color_name: "Waterspout",
    hexcode: "#A4F4F9",
  },
  {
    color_name: "Weldon Blue",
    hexcode: "#7C98AB",
  },
  {
    color_name: "Wenge",
    hexcode: "#645452",
  },
  {
    color_name: "Wheat",
    hexcode: "#F5DEB3",
  },
  {
    color_name: "White",
    hexcode: "#FFFFFF",
  },
  {
    color_name: "White Smoke",
    hexcode: "#F5F5F5",
  },
  {
    color_name: "Wild Blue Yonder",
    hexcode: "#A2ADD0",
  },
  {
    color_name: "Wild Orchid",
    hexcode: "#D470A2",
  },
  {
    color_name: "Wild Strawberry",
    hexcode: "#FF43A4",
  },
  {
    color_name: "Wild Watermelon",
    hexcode: "#FC6C85",
  },
  {
    color_name: "Willpower Orange",
    hexcode: "#FD5800",
  },
  {
    color_name: "Windsor Tan",
    hexcode: "#A75502",
  },
  {
    color_name: "Wine",
    hexcode: "#722F37",
  },
  {
    color_name: "Wine Dregs",
    hexcode: "#673147",
  },
  {
    color_name: "Winter Sky",
    hexcode: "#FF007C",
  },
  {
    color_name: "Winter Wizard",
    hexcode: "#A0E6FF",
  },
  {
    color_name: "Wintergreen Dream",
    hexcode: "#56887D",
  },
  {
    color_name: "Wisteria",
    hexcode: "#C9A0DC",
  },
  {
    color_name: "Wood Brown",
    hexcode: "#C19A6B",
  },
  {
    color_name: "Xanadu",
    hexcode: "#738678",
  },
  {
    color_name: "Yale Blue",
    hexcode: "#0F4D92",
  },
  {
    color_name: "Yankees Blue",
    hexcode: "#1C2841",
  },
  {
    color_name: "Yellow",
    hexcode: "#FFFF00",
  },
  {
    color_name: "Yellow (Crayola)",
    hexcode: "#FCE883",
  },
  {
    color_name: "Yellow (Munsell)",
    hexcode: "#EFCC00",
  },
  {
    color_name: "Yellow (NCS)",
    hexcode: "#FFD300",
  },
  {
    color_name: "Yellow (Pantone)",
    hexcode: "#FEDF00",
  },
  {
    color_name: "Yellow (Process)",
    hexcode: "#FFEF00",
  },
  {
    color_name: "Yellow (RYB)",
    hexcode: "#FEFE33",
  },
  {
    color_name: "Yellow-Green",
    hexcode: "#9ACD32",
  },
  {
    color_name: "Yellow Orange",
    hexcode: "#FFAE42",
  },
  {
    color_name: "Yellow Rose",
    hexcode: "#FFF000",
  },
  {
    color_name: "Yellow Sunshine",
    hexcode: "#FFF700",
  },
  {
    color_name: "Zaffre",
    hexcode: "#0014A8",
  },
  {
    color_name: "Zinnwaldite Brown",
    hexcode: "#2C1608",
  },
  {
    color_name: "Zomp",
    hexcode: "#39A78E",
  },
  {
    color_name: "Dream Glow",
    hexcode: "#B1B7FF",
  },
];

const sorted_colors = colorsArray.sort(
  (colorObj1, colorObj2) =>
    colorObj2.color_name.length - colorObj1.color_name.length
);

export const colorConstants = {
  colors: sorted_colors,
};
