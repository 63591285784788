import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="71px"
      height="76px"
      viewBox="0 0 71 76"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-70 -263) translate(43 250) translate(27 13)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M66 20v56H54V20h12zm-1.2 1.191h-9.6V74.81h9.6V21.19z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path
          d="M63 15v6h-7v-6h7zm-1.167 1.2h-4.666v3.6h4.666v-3.6z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path
          d="M59.988 5c1.169 1.93 2.863 3.763 5.083 5.5s2.524 3.57.91 5.5H53.919c-1.422-2.117-1.192-3.95.69-5.5 1.883-1.55 3.676-3.383 5.38-5.5zm-.102 1.988l-.015.02a37.621 37.621 0 01-4.488 4.4c-1.228 1.011-1.493 2.026-.809 3.351l.03.055h10.769l.026-.037c.721-1.126.464-2.076-.888-3.196l-.191-.154c-1.664-1.301-3.053-2.663-4.166-4.085l-.268-.354z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path
          d="M9.988 5c1.169 1.93 2.863 3.763 5.083 5.5s2.524 3.57.91 5.5H3.919c-1.422-2.117-1.192-3.95.69-5.5C6.492 8.95 8.285 7.117 9.989 5z"
          fill="#FFF"
        />
        <Path
          d="M9.988 5c1.169 1.93 2.863 3.763 5.083 5.5s2.524 3.57.91 5.5H3.919c-1.422-2.117-1.192-3.95.69-5.5C6.492 8.95 8.285 7.117 9.989 5zm-.102 1.988l-.015.02a37.621 37.621 0 01-4.488 4.4c-1.228 1.011-1.493 2.026-.809 3.351l.03.055h10.769l.026-.037c.721-1.126.464-2.076-.888-3.196l-.191-.154c-1.664-1.301-3.053-2.663-4.166-4.085l-.268-.354z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path fill="#FFF" d="M6 15H13V21H6z" />
        <Path
          d="M13 15v6H6v-6h7zm-1.167 1.2H7.167v3.6h4.666v-3.6z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path
          d="M16 20v54.808h55V76l-55-.001L4 76v-.001L0 76v-1.191l4-.001V20h12zm-1 1H5v53.808h10V21z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path fill="#FFF" d="M15 43H55V76H15z" />
        <Path
          d="M55 43v33H15V43h40zm-1.176 1.179H16.176V74.82h37.648V44.18z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path
          d="M52 33v11H15V33h37zm-1.194 1.222H16.194v8.556h34.612v-8.556z"
          fill="#505050"
          fillRule="nonzero"
        />
        <G transform="translate(50 31)">
          <Path fill="#FFF" d="M0 0H19V13H0z" />
          <Path
            d="M19 0v13H0V0h19zm-1.188 1.182H1.188v10.636h16.625V1.182zM9.5 2.364c.656 0 1.188.529 1.188 1.181v5.91c0 .652-.532 1.181-1.188 1.181-.656 0-1.188-.529-1.188-1.181v-5.91c0-.652.532-1.181 1.188-1.181zm4.75 0c.656 0 1.188.529 1.188 1.181v5.91c0 .652-.532 1.181-1.188 1.181-.656 0-1.188-.529-1.188-1.181v-5.91c0-.652.532-1.181 1.188-1.181zm-9.5 0c.656 0 1.188.529 1.188 1.181v5.91c0 .652-.532 1.181-1.188 1.181-.656 0-1.188-.529-1.188-1.181v-5.91c0-.652.532-1.181 1.188-1.181z"
            fill="#505050"
            fillRule="nonzero"
          />
        </G>
        <G transform="translate(0 31)">
          <Path fill="#FFF" d="M0 0H19V13H0z" />
          <Path
            d="M19 0v13H0V0h19zm-1.188 1.182H1.188v10.636h16.625V1.182zM9.5 2.364c.656 0 1.188.529 1.188 1.181v5.91c0 .652-.532 1.181-1.188 1.181-.656 0-1.188-.529-1.188-1.181v-5.91c0-.652.532-1.181 1.188-1.181zm4.75 0c.656 0 1.188.529 1.188 1.181v5.91c0 .652-.532 1.181-1.188 1.181-.656 0-1.188-.529-1.188-1.181v-5.91c0-.652.532-1.181 1.188-1.181zm-9.5 0c.656 0 1.188.529 1.188 1.181v5.91c0 .652-.532 1.181-1.188 1.181-.656 0-1.188-.529-1.188-1.181v-5.91c0-.652.532-1.181 1.188-1.181z"
            fill="#505050"
            fillRule="nonzero"
          />
        </G>
        <Path fill="#FFF" d="M59.5 7L59.5 0" />
        <Path
          fill="#505050"
          fillRule="nonzero"
          d="M60.25 6.98847908L59.875 3.41060513e-13 59.5 6.98847908z"
        />
        <Path
          fill="#505050"
          fillRule="nonzero"
          d="M10.25 6.98847908L9.875 3.41060513e-13 9.5 6.98847908z"
        />
        <Path
          d="M10 17a1 1 0 110 2 1 1 0 010-2zM60 17a1 1 0 110 2 1 1 0 010-2zM22 43v33h-7V43h7zm-1.167 1.179h-4.666V74.82h4.666V44.18zM18.5 65.393c.644 0 1.167.528 1.167 1.178v2.358c0 .65-.523 1.178-1.167 1.178a1.173 1.173 0 01-1.167-1.178V66.57c0-.65.523-1.178 1.167-1.178zm0-8.25c.644 0 1.167.528 1.167 1.178v2.358c0 .65-.523 1.178-1.167 1.178a1.173 1.173 0 01-1.167-1.178V58.32c0-.65.523-1.178 1.167-1.178zm0-8.25c.644 0 1.167.528 1.167 1.178v2.358c0 .65-.523 1.178-1.167 1.178a1.173 1.173 0 01-1.167-1.178V50.07c0-.65.523-1.178 1.167-1.178zM55 43v33h-7V43h7zm-1.167 1.179h-4.666V74.82h4.666V44.18zM51.5 65.393c.644 0 1.167.528 1.167 1.178v2.358c0 .65-.523 1.178-1.167 1.178a1.173 1.173 0 01-1.167-1.178V66.57c0-.65.523-1.178 1.167-1.178zm0-8.25c.644 0 1.167.528 1.167 1.178v2.358c0 .65-.523 1.178-1.167 1.178a1.173 1.173 0 01-1.167-1.178V58.32c0-.65.523-1.178 1.167-1.178zm0-8.25c.644 0 1.167.528 1.167 1.178v2.358c0 .65-.523 1.178-1.167 1.178a1.173 1.173 0 01-1.167-1.178V50.07c0-.65.523-1.178 1.167-1.178z"
          fill="#505050"
          fillRule="nonzero"
        />
        <Path
          d="M33.5 51h2a5.5 5.5 0 015.5 5.5V76H28V56.5a5.5 5.5 0 015.5-5.5z"
          fill="#FFF"
        />
        <Path
          d="M34.5 51c3.59 0 6.5 2.931 6.5 6.548V76H28V57.548C28 53.93 30.91 51 34.5 51zm0 1.19c-2.937 0-5.318 2.399-5.318 5.358V74.81h10.636V57.548c0-2.875-2.247-5.22-5.068-5.352l-.25-.006z"
          fill="#505050"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
