import I18n from "i18n-js";

export default class AddressCardModel {
  constructor({ address, title, description, footerMessage }) {
    this.addressObj = address;
    this.titleStr = title;
    this.descriptionText = description;
    this.footerText = footerMessage;
  }

  get address() {
    return this.addressObj;
  }

  get title() {
    return (
      this.titleStr ||
      this.address.type ||
      I18n.t("HOME", { defaultValue: "HOME" })
    );
  }

  get description() {
    let description = "";
    if (this.addressObj && this.addressObj.line1) {
      description = this.addressObj.line1;
    }
    if (this.addressObj && this.addressObj.area) {
      description += ", " + this.addressObj.area;
    }
    if (this.addressObj && this.addressObj.city) {
      description += ", " + this.addressObj.city;
    }
    return description || this.descriptionText;
  }

  get fullAddress() {
    let address = "";
    if (this.addressObj && this.addressObj.line1) {
      address = this.addressObj.line1;
    }
    if (this.addressObj && this.addressObj.line2) {
      address += ", " + this.addressObj.line2;
    }
    if (this.addressObj && this.addressObj.area) {
      address += ", " + this.addressObj.area;
    }
    if (this.addressObj && this.addressObj.landmark) {
      address += ", " + this.addressObj.landmark;
    }
    if (this.addressObj && this.addressObj.city) {
      address += ", " + this.addressObj.city;
    }
    if (this.addressObj && this.addressObj.pincode) {
      address += ", " + this.addressObj.pincode;
    }
    return address || this.descriptionText;
  }

  //TODO: used in BookMyChef:
  // src/screens/BookingDetails/BookingDetails.js
  //src/screens/AddNewService/AddNewService.js
  //src/screens/BookingDetails/PreviewBooking.js
  get fullAddress2() {
    let address = "";
    if (this.addressObj && this.addressObj.line1) {
      address = this.addressObj.line1;
    }
    if (this.addressObj && this.addressObj.line2) {
      address += ", " + this.addressObj.line2;
    }
    if (this.addressObj && this.addressObj.landmark) {
      address += ", " + this.addressObj.landmark;
    }
    if (this.addressObj && this.addressObj.area) {
      address += ", " + this.addressObj.area;
    }
    if (this.addressObj && this.addressObj.city) {
      address += ", " + this.addressObj.city;
    }
    if (this.addressObj && this.addressObj.state) {
      address += ", " + this.addressObj.state;
    }
    if (this.addressObj && this.addressObj.pincode) {
      address += ", " + this.addressObj.pincode;
    }
    if (this.addressObj && this.addressObj.country) {
      address += ", " + this.addressObj.country;
    }
    return address;
  }

  get fullAddress3() {
    let address = "";
    if (this.addressObj && this.addressObj.line1) {
      address = this.addressObj.line1;
    }
    if (this.addressObj && this.addressObj.line2) {
      address += ", " + this.addressObj.line2;
    }
    if (this.addressObj && this.addressObj.landmark) {
      address += ", " + this.addressObj.landmark;
    }
    if (this.addressObj && this.addressObj.city) {
      address += ", " + this.addressObj.city;
    }
    if (this.addressObj && this.addressObj.pincode) {
      address += ", " + this.addressObj.pincode;
    }
    return address || this.descriptionText;
  }

  get fullAddress4() {
    let address = "";
    if (this.addressObj && this.addressObj.line1) {
      address = this.addressObj.line1;
    }
    if (this.addressObj && this.addressObj.line2) {
      address += ", " + this.addressObj.line2;
    }
    if (this.addressObj && this.addressObj.landmark) {
      address += ", " + this.addressObj.landmark;
    }
    if (this.addressObj && this.addressObj.city) {
      address += ", " + this.addressObj.city;
    }
    if (this.addressObj && this.addressObj.pincode) {
      address += ", " + this.addressObj.pincode;
    }
    if (this.addressObj && this.addressObj.state) {
      address += ", " + this.addressObj.state;
    }

    if (this.addressObj && this.addressObj.country) {
      address += ", " + this.addressObj.country;
    }
    return address || this.descriptionText;
  }

  get line1() {
    return this.address.line1;
  }

  get line2() {
    return this.address.line2;
  }

  get area() {
    return this.address.area;
  }

  get landmark() {
    return this.address.landmark;
  }

  get city() {
    return this.address.city;
  }

  get state() {
    return this.address.state;
  }

  get pincode() {
    return this.address.pincode;
  }

  get alternate_number() {
    return this.address?.other_data?.alternate_number;
  }

  get footer() {
    return this.footerText;
  }

  get type() {
    return this.addressObj?.type;
  }

  static fake() {
    return new AddressCardModel({
      address: {
        line1: "line1",
        line2: "line2",
        area: "Area",
        landmark: "Landmark",
        city: "City",
        pincode: "Pincode",
      },
      title: "Title",
      description: "Description",
      footerMessage: "Footer",
      fullAddress: "",
    });
  }
}
