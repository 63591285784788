import _ from "lodash";
import staticConfig from "@presto-app/config";

const config = _.cloneDeep(staticConfig);

export function updateConfig(options) {
  if (options.server_config) {
    config.server_config = options.server_config;
  }
  config.theme = options.theme;
  config.messages = options.messages;
  config.merchant = _.merge(config.merchant, options.merchant);
}

export default config;
