import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M3.47 1.515c.125 1.18 1.351 14.763 1.566 16.788C5.198 19.834 6.312 21 7.865 21h15.71c1.417 0 2.66-.998 2.958-2.372l2.422-11.209a1.977 1.977 0 00-.404-1.676A2.016 2.016 0 0026.98 5H5.472L5.036.896A1.006 1.006 0 004.033 0H1.008C.452 0 0 .344 0 .896s.452.619 1.008.619H3.47z"
          fill={props.color || "#707070"}
          transform="translate(6 6)"
        />
        <Path
          d="M9.074 23.75a2.28 2.28 0 011.609.662 2.23 2.23 0 010 3.176 2.28 2.28 0 01-1.61.662 2.28 2.28 0 01-1.609-.662 2.23 2.23 0 010-3.176 2.28 2.28 0 011.61-.662zM21.172 23.75a2.28 2.28 0 011.61.662 2.23 2.23 0 010 3.176 2.28 2.28 0 01-1.61.662 2.28 2.28 0 01-1.61-.662 2.23 2.23 0 010-3.176 2.28 2.28 0 011.61-.662z"
          stroke={props.color || "#707070"}
          strokeWidth={1.5}
          transform="translate(6 6)"
          fill={props.color3 || "none"}
        />
        <Path
          d="M33.48 12l-2.5 13.077c-.103.534-.531.923-1.02.923H13.734c-.536 0-.98-.45-1.037-1.044L11.48 12h22z"
          fill={props.color2 || "#FFF"}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
