import React, { useContext, useEffect, useReducer } from "react";
import { View } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import WebLayout from "@presto-screens/WebLayout/WebLayout";
import { usePagination } from "../../hooks/pagination";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import I18n from "i18n-js";
import CategoriesSection from "@presto-screen-components/CategoriesSection/CategoriesSection";
import { useCatalog } from "@presto-stores/CatalogStore";
import PrestoText from "@presto-components/PrestoText";

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_QUERY_DONE":
      return {
        ...state,
        ...{
          query: payload,
          auto_complete_results: [],
          user_pressed_enter: true,
          results: [],
        },
      };
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{
          query: payload,
          user_pressed_enter: false,
          results: [],
        },
      };
  }
  return state;
}

export default function WebSearchResults({ route }) {
  const searchQuery = route.params.query;
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const [state, dispatch] = useReducer(reducer, {
    query: route.params.query || "",
  });

  useEffect(() => {
    dispatch({ type: "SET_SEARCH_QUERY", payload: route.params.query });
  }, [route.params.query]);

  const searchPage = (pageNumber, success, failure) => {
    const params = {
      search_string: state.query,
      catalog_id: catalog.id,
      fuzziness: 5,
      page: pageNumber,
    };
    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        success(response.hits.hits);
      },
      (error) => {
        failure(error);
      }
    );
  };
  const [
    results,
    loadNextPage,
    loadFirstPage,
    tempLoading,
    pageLoaded,
  ] = usePagination(searchPage);

  const onSearchItemPress = (query) => {
    console.log("onSearchItemPress : ", query);
    onSearch(_.toLower(query));
  };

  useEffect(() => {
    if (!catalog) return;
    if (state.query !== "") {
      loadFirstPage();
    }
  }, [state.query, catalog]);

  const renderItem = ({ item, key }) => {
    console.log("renderItem : ", item);
    if (item) {
      return (
        <View
          key={key}
          style={{
            width: 400,
            marginRight: 15,
            marginBottom: 15,
          }}
        >
          <ItemCard key={item._id} item={item}></ItemCard>
        </View>
      );
    } else {
      return null;
    }
  };

  const onSearch = async (text) => {
    dispatch({ type: "SET_SEARCH_QUERY", payload: text });
    const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
    const recentSearches = recentSearchesJson
      ? JSON.parse(recentSearchesJson)
      : [];
    const filteredSearches = _.filter(recentSearches, (s) => s !== text);
    const newRecentSearches = [text, ...filteredSearches];
    const splicedSearches = _.slice(newRecentSearches, 0, 6);
    await AsyncStorage.setItem(
      "RECENT_SEARCHES",
      JSON.stringify(splicedSearches)
    );
  };

  return (
    <WebLayout>
      <View
        style={{
          backgroundColor: "#EFEDED",
          height: 50,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View style={{ width: "90%", alignSelf: "center" }}>
          <CategoriesSection theme={theme} />
        </View>
      </View>
      <View style={{ paddingTop: 30, paddingHorizontal: 40 }}>
        {results.length > 0 ? (
          <PrestoText size={theme.h3FontSize} fontStyle="900.normal">
            {I18n.t("screen_messages.common.search_results_found_for")}
            {searchQuery}
          </PrestoText>
        ) : null}
        {results.length == 0 && pageLoaded ? (
          <PrestoText
            size={theme.h3FontSize}
            fontStyle="900.normal"
            color={theme.primary}
          >
            {I18n.t("screen_messages.products.no_search_results")}
          </PrestoText>
        ) : null}
      </View>
      <View
        style={{
          padding: 40,
          width: "100%",
          height: "100%",
          flexWrap: "wrap",
          flexDirection: "row",
          alignItems: "flex-start",
          alignContent: "flex-start",
        }}
      >
        {_.map(results, (item, index) => {
          return renderItem({
            item: item._source,
            index,
            key: item._source.id,
          });
        })}
      </View>
    </WebLayout>
  );
}
