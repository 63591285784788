import React, { useState } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PrestoText from "@presto-components/PrestoText";
export default function TextInputComponent(props) {
  const { theme } = props;
  const styles = getTheme(theme, {
    textInputWrapperBgColor: props?.textInputWrapperBgColor,
    textInputHeight: props?.textInputHeight,
  });

  const labelStyles = props?.labelStyles ? props.labelStyles : {};
  const labelFontStyle = props?.labelFontStyle ? props.labelFontStyle : {};

  const showSoftInputOnFocus = props.showSoftInputOnFocus ?? true;
  const _textInputStyles = props?.textInputStyles ? props.textInputStyles : {};

  const textContainerStylesOnFocus = props?.textContainerStylesOnFocus
    ? props.textContainerStylesOnFocus
    : {};

  const [textInputStyles, setTextInputStyles] = useState({
    paddingRight: props.icon && 40,
    ...styles.textInputStyle,
    flex: 1,
    borderWidth: props.noBorder && 0,
    ..._textInputStyles,
    padding: 10,
  });

  const [textContainerStyles, setTextContainerStyles] = useState([
    styles.textContainer,
    props?.textContainerStyles,
  ]);

  const onFocus = () => {
    if (props?.onFocus) {
      props.onFocus();
    }

    if (textContainerStylesOnFocus) {
      let _style = [styles.textContainer, props?.textContainerStyles];
      _style.push(textContainerStylesOnFocus);
      setTextContainerStyles([..._style]);
    }
  };

  const onBlur = () => {
    if (props?.onBlur) {
      props.onBlur();
    }

    if (textContainerStylesOnFocus) {
      setTextContainerStyles([
        styles.textContainer,
        props?.textContainerStyles,
      ]);
    }
  };

  return (
    <View style={[styles.container, props?.style]}>
      {props.labelText ? (
        <View style={styles.labelContainer}>
          <PrestoText
            color={props.labelColor || theme.darkText}
            weight={props.labelFontWeight || "normal"}
            size={props.labelFontSize || 12}
            extraStyle={[styles.label, labelStyles, labelFontStyle]}
          >
            {props.labelText}
          </PrestoText>
        </View>
      ) : null}
      <View style={[textContainerStyles, props.bodyStyle]}>
        <TextInput
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={
            styles?.placeHolder?.color || props.placeHolderColor
          }
          secureTextEntry={props.secureTextEntry}
          {...props}
          style={textInputStyles}
          autoFocus={props.autoFocus || false}
          showSoftInputOnFocus={showSoftInputOnFocus}
          onBlur={onBlur}
          onFocus={onFocus}
        ></TextInput>
        {props.icon && (
          <TouchableOpacity onPress={props.onEyePress}>
            {props.icon}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

function getTheme(theme, options) {
  return {
    container: {
      width: "100%",
    },
    textContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.textInputBgColor,
      height: options?.textInputHeight || 42,
      borderRadius: theme.textInputBorderRadius,
      // paddingHorizontal: 10,
    },
    labelContainer: {
      paddingBottom: 5,
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    label: {
      width: "100%",
    },
    textInputStyle: {
      width: "100%",
      height: options?.textInputHeight || 42,
      borderWidth: 0.1,
      borderRadius: theme.textInputBorderRadius,
      fontSize: theme.textInputSize,
      borderColor: theme.textInputBorderColor,
      backgroundColor: theme.textInputBgColor,
      color: theme.textInputColor,
      textAlignVertical: "top",
    },
    placeHolder: {
      color: "#999999",
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: 42,
      borderWidth: 0,
      borderRadius: 4,
      fontFamily: theme.primaryMediumFont,
      backgroundColor: theme.textInputBgColor,
      color: theme.textInputColor,
    },
    eyebutton: {
      flex: 1,
      position: "absolute",
      right: 0,
    },
  };
}
