import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(5 5)"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke={props.color1 || "none"}
        strokeWidth={1}
      >
        <Path d="M29 9H0v1.334C0 11.806 1.082 13 2.416 13h3.625c.64 0 1.255-.28 1.708-.78.454-.5.708-1.179.708-1.886 0 .707.255 1.386.708 1.886.454.5 1.069.78 1.71.78h7.25c1.334 0 2.416-1.194 2.416-2.666 0 1.472 1.082 2.666 2.416 2.666h3.625c.641 0 1.256-.28 1.71-.78.453-.5.708-1.179.708-1.886V9zM29 8L26.792.83C26.642.34 26.165.003 25.62 0H20l1.717 8H29zM9 0H3.385c-.547 0-1.028.338-1.179.828L0 8h7.274L9 0z" />
        <Path d="M17.3339286 0L11.6660714 0 10 8 19 8z" />
        <Path d="M23.089 14.899A3.76 3.76 0 0120.635 14a3.76 3.76 0 01-2.454.899h-7.364A3.76 3.76 0 018.363 14a3.76 3.76 0 01-2.453.899H2.228A3.804 3.804 0 011 14.693v13.131C1 28.474 1.55 29 2.228 29h2.454V18.424c0-.65.55-1.175 1.228-1.175h6.135c.678 0 1.228.526 1.228 1.175V29h13.5C27.45 29 28 28.474 28 27.824V14.693c-.394.135-.81.205-1.228.206H23.09zm1.227 7.05c0 .65-.55 1.176-1.227 1.176h-6.136c-.678 0-1.228-.526-1.228-1.176v-3.525c0-.65.55-1.175 1.228-1.175h6.136c.678 0 1.227.526 1.227 1.175v3.525z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
