import * as React from "react";
import Svg, { Path } from "react-native-svg";

function EyeOpen(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.5 6C10.57 6 9 7.57 9 9.5s1.57 3.5 3.5 3.5S16 11.43 16 9.5 14.43 6 12.5 6m-.5 9a5 5 0 110-10 5 5 0 110 10m0 3c5.06 0 10.02-4.558 11-8.5C22.02 5.558 17.06 1 12 1S1.98 5.558 1 9.5c.98 3.942 5.94 8.5 11 8.5zM0 10C.694 6.428 5.596 0 12.5 0S24.306 6.428 25 10c-.694 3.572-5.596 10-12.5 10S.694 13.572 0 10z"
        transform="translate(7.514 10)"
        fill={props.color||"#999"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default EyeOpen;
