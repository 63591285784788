import React, { Component } from "react";
import Slider from "react-slick";
import { View, Image, Platform } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";

export default class BannerSlide extends Component {
  _renderItem = (item) => {
    const imageWrapperStyle = {
      borderRadius: this.props.borderRadius || 0,
      height: this.props.height || 200,
      overflow: "hidden",
      width: this.props.width || "100%",
    };

    if (Platform.OS === "web") {
      imageWrapperStyle.cursor = "pointer";
    }

    return (
      <TouchableWithoutFeedback onPress={() => this.props.onClickBanner(item)}>
        <View style={imageWrapperStyle}>
          <Image
            source={{ uri: item.file_url }}
            resizeMode="contain"
            style={{
              borderRadius: this.props.borderRadius || 0,
              height: this.props.height || 200,
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  render() {
    const settings = {
      dots: this.props.dots || true,
      infinite: false,
      speed: 500,
      slidesToShow: this.props.slidesToShow || 1,
      slidesToScroll: this.props.slidesToShow || 1,
      arrows: this.props.arrows || false,
      autoplay: true,
      centerPadding: "20px",
    };
    const renderItems =
      this.props.banners &&
      this.props.banners.map((item) => {
        return this._renderItem(item);
      });
    return (
      <View style={{ marginBottom: 20, height: this.props.height || 200 }}>
        <Slider {...settings}>{renderItems}</Slider>
      </View>
    );
  }
}
