import React, { useState, useContext, useCallback } from "react";
import {
  View,
  FlatList,
  TouchableWithoutFeedback,
  ImageBackground,
  Dimensions,
} from "react-native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import useFocusEffect from "@presto-common/useFocusEffect";
import { VerticalSpacing } from "@presto-components/Spacing";
import I18n from "i18n-js";
import _ from "lodash";

export default function DealsComponent({
  navigation,
  title,
  fontStyle,
  fontSize,
  hideTitle,
  renderHeader,
  setItemCount,
}) {
  const { theme } = useContext(ThemeContext);
  const [gallery, setGallery] = useState([]);
  const [galleryTitle, setGalleryTitle] = useState();
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    if (response?.data && response?.data?.alternate_name != "") {
      setGalleryTitle(response?.data?.alternate_name);
    }
    setGallery(response?.data?.media_items);

    if (_.isFunction(setItemCount)) {
      let len = response?.data?.media_items?.length || 0;
      setItemCount(len);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getGallery("BRANDS");
    }, [])
  );

  const renderItem = ({ item, index }) => {
    return (
      <TouchableWithoutFeedback>
        <ImageBackground
          style={{
            width: 285,
            height: 265,
            borderRadius: 15,
            overflow: "hidden",
            marginLeft: theme.containerPadding,
          }}
          source={{ uri: item.file_url }}
          resizeMode="contain"
        />
      </TouchableWithoutFeedback>
    );
  };
  if (_.isEmpty(gallery)) {
    return null;
  }
  return (
    <View
      style={{
        paddingVertical: theme.primaryPadding,
        marginBottom: 50,
      }}
    >
      {renderHeader ? renderHeader(galleryTitle) : null}
      {!hideTitle ? (
        <>
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <SectionHeader
              fontStyle={fontStyle}
              fontSize={fontSize}
              leftText={
                title ||
                galleryTitle ||
                I18n.t("screen_messages.testimonials.title")
              }
            />
          </View>
          <VerticalSpacing size={10} />
        </>
      ) : null}
      <View style={{ height: 300 }}>
        <FlatList
          horizontal
          keyExtractor={(_, index) => "index-" + index}
          data={gallery}
          renderItem={renderItem}
          swipeThreshold={10}
          layout={"default"}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            paddingRight: theme.containerPadding,
          }}
        />
      </View>
    </View>
  );
}
