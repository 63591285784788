import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
      <Path
        d="M14.766 7.5A7.264 7.264 0 017.5 14.766 7.264 7.264 0 01.234 7.5 7.264 7.264 0 017.5.234 7.264 7.264 0 0114.766 7.5zm-6.768 3.337l3.97-3.97a.7.7 0 000-.993l-.498-.498a.7.7 0 00-.993 0L7.5 8.353 4.523 5.376a.7.7 0 00-.993 0l-.498.498a.7.7 0 000 .993l3.97 3.97c.275.275.72.275.996 0z"
        fill={props.primaryColor || "#FFCF31"}
      />
    </Svg>
  );
}

export default SvgComponent;
