import * as React from "react";
import Svg, { G, Path, Defs, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill={props.color || "#FFF"}>
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <G transform="translate(-.018)">
            <Path
              fill="none"
              d="M0.0176530206 0H29.0176530206V29H0.0176530206z"
            />
            <Path
              d="M27.091 25.375h-9.063a3.625 3.625 0 11-7.25 0H1.716a1.742 1.742 0 01-1.631-1.269 1.936 1.936 0 01.543-1.994 7.297 7.297 0 002.9-5.8v-5.437C3.528 4.869 8.398 0 14.403 0c6.007 0 10.875 4.869 10.875 10.875v5.438a7.297 7.297 0 002.9 5.8c.606.468.828 1.282.544 1.993a1.742 1.742 0 01-1.631 1.269z"
              fill={props.color2 || "#707070"}
              fillRule="evenodd"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
