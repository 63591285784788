const fonts = {
  primaryLiteFont: "Roboto-Light",
  primaryFont: "Roboto-Regular",
  primaryMediumFont: "Roboto-Medium",
  primarySemiBoldFont: "Roboto-Bold",
  primaryBoldFont: "Roboto-Bold",
};

const fontSizes = {
  h1FontSize: 24,
  h2FontSize: 20,
  h3FontSize: 18,
  h4FontSize: 16,
  h5FontSize: 14,
  h6FontSize: 12,
};

const buttonVariations = {
  "extra-large": {
    height: 50,
    fontSize: 18,
    borderRadius: 6,
    borderWidth: 1,
  },
  large: {
    height: 40,
    fontSize: 16,
    borderRadius: 6,
    borderWidth: 1,
  },
  normal: {
    height: 30,
    fontSize: 14,
    borderRadius: 6,
    borderWidth: 1,
  },
  small: {
    height: 25,
    fontSize: 12,
    borderRadius: 6,
    borderWidth: 1,
  },
  "extra-small": {
    height: 20,
    fontSize: 10,
    borderRadius: 6,
    borderWidth: 1,
  },
};

const fontVariations = {
  lite: {
    fontFamily: fonts.primaryLiteFont,
    fontWeight: "normal",
  },
  normal: {
    fontFamily: fonts.primaryFont,
    fontWeight: "normal",
  },
  medium: {
    fontFamily: fonts.primaryMediumFont,
    fontWeight: "normal",
  },
  semibold: {
    fontFamily: fonts.primarySemiBoldFont,
    fontWeight: "normal",
  },
  bold: {
    fontFamily: fonts.primaryBoldFont,
    fontWeight: "normal",
  },
  "100": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "100",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "100",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "100",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "100",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "100",
    },
  },
  "400": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "400",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "400",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "400",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "400",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "400",
    },
  },
  "500": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "500",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "500",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "500",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "500",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "500",
    },
  },
  "600": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "600",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "600",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "600",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "600",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "600",
    },
  },
  "700": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "700",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "700",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "700",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "700",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "700",
    },
  },
  "900": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "900",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "900",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "900",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "900",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "900",
    },
  },
};

const constantStyles = {
  //border for button, modal and card radius, border width
  cardRadius: 6,
  modalBorderRadius: 4,
  borderWidth: 1,
  containerPadding: 20,
  primaryPadding: 15,
};

const shadowStyles = {
  //shadow for button, icon, modal and card
  lightShadow: {
    shadowColor: "rgb(32, 32, 32)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: 5,
  },
  darkShadow: {
    shadowColor: "#333333",
    shadowOffset: {
      width: 3,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 10,
  },
};

const colors = {
  //theme specific text, button, bg, border colors
  primary: "#FD5B53",
  secondary: "#303AB6",
  tertiary: "#00AF62",
  //common text or background colors
  black: "#000",
  white: "#fff",
  //both background and border colors
  light: "#fefefe",
  //both background and border colors
  dark: "#333333",
  //success popup or inline success msgs
  success: "green",
  //error popup or inline errors msgs
  error: "red",
  //only for specific components
  marketing: "blue",
  defaultTickedColor: "#CCCCCC",
  itemPromotionColor: "lightyellow",
  buttonBgColor: "rgba(253,91,83,0.1)",
};

const textColor = {
  title: "#000000",
  header: "#333333",
  subHeader: "#666666",
  paragraph: "#999999",
  lightText: "#CCCCCC",
  darkText: "#333333",
  // buttons Color
  primaryButtonText: "#FFFFFF",
  secondaryButtonText: "#FFFFFF",
  tertiaryButtonText: "#FFFFFF",
};

const backgroundColor = {
  //only for model background
  modalBackgroundColor: "#FFFFFF",
  //only for APP background
  appBackgroundColor: "#FFFFFF",
  //only for card background
  cardBackgroundColor: "#FFFFFF",
};

const textInputStyles = {
  placeHolderColor: "#999999",
  textInputBorderColor: "transparent",
  textInputBorderWidth: 0,
  textInputBorderRadius: 6,
  textInputBgColor: "#E3E3E3",
  textInputSize: 14,
  textInputHeight: 42,
  textInputColor: "#666666",
  textInputLabelColor: "#333333",
};

const buttonStyles = {
  "solid-button-styles": {
    primary: {
      color: colors.primaryButtonText,
      backgroundColor: colors.primary,
      fontStyle: "500.medium",
    },
    secondary: {
      color: colors.secondaryButtonText,
      backgroundColor: colors.secondary,
      fontStyle: "500.medium",
    },
    tertiary: {
      color: colors.tertiaryButtonText,
      backgroundColor: colors.tertiary,
      fontStyle: "500.medium",
    },
    "white-primary": {
      color: colors.primary,
      backgroundColor: "#fff",
      fontStyle: "500.medium",
    },
    white: {
      color: "#000",
      backgroundColor: "#fff",
      fontStyle: "500.medium",
    },
    black: {
      color: "#fff",
      backgroundColor: "#000",
      fontStyle: "500.medium",
    },
    "black-primary": {
      color: colors.primary,
      backgroundColor: "#000",
      fontStyle: "500.medium",
    },
  },
  "framed-button-styles": {
    primary: {
      color: colors.primary,
      borderColor: colors.primary,
      fontStyle: "400.normal",
    },
    secondary: {
      color: colors.secondaryButtonTextColor,
      borderColor: colors.secondary,
      fontStyle: "400.normal",
    },
    tertiary: {
      color: colors.tertiaryButtonTextColor,
      borderColor: colors.tertiary,
      fontStyle: "400.normal",
    },
  },
  "dotted-button-styles": {
    tertiary: {
      color: colors.tertiary,
      borderColor: colors.tertiary,
      fontStyle: "400.normal",
    },
  },
};

const allThemes = {
  light: (svgs, images) => {
    return {
      ...fonts,
      ...fontSizes,
      ...fontVariations,
      ...buttonVariations,
      ...colors,
      ...buttonStyles,
      ...constantStyles,
      ...shadowStyles,
      ...textInputStyles,
      ...backgroundColor,
      ...textColor,

      get primaryLightColor() {
        return "rgba(253,91,83,0.1)";
      },

      get largeButtonBgColor() {
        return "rgba(48,58,182,0.19)";
      },
      get pinButtonBgColor() {
        return "#D8D8D8";
      },
      get pinButtonColor() {
        return "#333333";
      },

      get notFoundTextColor() {
        return "#E05757";
      },
    };
  },
  dark: (svgs, images) => {
    return {
      ...fonts,
      ...fontSizes,
    };
  },
};

export default allThemes;
