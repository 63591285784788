import React, { useContext } from "react";
import {
  View,
  Dimensions,
  TouchableOpacity,
  Platform,
  Pressable,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useSafeArea } from "react-native-safe-area-context";
import _ from "lodash";
import utils from "../../utils/index";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import Draggable from "react-native-draggable";

const windowWidth = Dimensions.get("window").width;

export default function FloatingCart({
  route,
  containerBottom,
  bottom,
  right,
}) {
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { theme } = useContext(ThemeContext);
  const { cart, getItemCount, getTotalPrice, clearCart } = useContext(
    CartContext
  );
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const isWeb = utils.isWeb();
  const isNextJsApp = utils.isNextJsApp();
  const isDesktop = utils.isDesktop();

  const onOrder = () => {
    if (!user) {
      showLogin();
      return;
    } else {
      let inSideNavigation;
      if (isNextJsApp && isDesktop) {
        inSideNavigation = true;
      }
      setPaneState(true);
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/cart",
          screenName: "CART",
          inSideNavigation: inSideNavigation,
        },
      });
    }
  };

  const showLogin = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: { screenName: "LOGIN" },
    });
  };
  if (cart != null && !_.isEmpty(cart.items)) {
    return (
      <View
        style={{
          backgroundColor: theme.secondary,
          borderRadius: 15,
          width: 190,
          position: utils.isMobile()
            ? "absolute"
            : isWeb
            ? "fixed"
            : "absolute",
          right: right || 20,
          bottom: bottom || 10,
          flexDirection: "column",
          padding: 10,
          zIndex: 10000000,
        }}
      >
        <PrestoText color={theme.white} size={14} fontStyle="500.normal">
          {I18n.t("screen_messages.cart.floating_cart_count", {
            count: getItemCount(),
          })}
        </PrestoText>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PrestoText fontStyle="400.normal" color={theme.white} size={14}>
            {utils.formattedCurrencyToRoundOff(
              getTotalPrice(),
              undefined,
              true
            )}
            {/* {utils.formattedCurrencyCents(getTotalPrice())} */}
          </PrestoText>
          <Pressable onPress={() => onOrder()}>
            <View
              style={{
                borderWidth: 1,
                borderColor: theme.white,
                borderRadius: 10,
                height: 32,
                minWidth: 30,
                paddingHorizontal: 15,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PrestoText
                fontStyle="400.normal"
                color={theme.white}
                size={14}
                extraStyle={{ textTransform: "uppercase" }}
              >
                {I18n.t("screen_messages.orders.title_singular_space")}
              </PrestoText>
            </View>
          </Pressable>
        </View>
      </View>
    );
  } else {
    return null;
  }
}
