import * as React from "react";
import Svg, { Path, G, Circle } from "react-native-svg";

export function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill={props.color || "#C4421A"} cx={20} cy={20} r={20} />
        <G
          transform="translate(11 7)"
          fill={"#ffffff"}
          stroke={"#ffffff"}
          strokeWidth={1.2}
        >
          <Path d="M2.42698544 3.1651296L2.42698544 18.2053223 5.72075938 18.2053223 5.72075938 14.6148737 5.72075938 3.1651296 8.65407823 3.1651296 4.47649613 -5.9043679e-16 0 3.1651296z" />
          <Path
            transform="matrix(1 0 0 -1 0 34.782)"
            d="M11.7729072 11.771405L11.7729072 26 15.0666812 26 15.0666812 22.5850522 15.0666812 11.771405 18 11.771405 13.8224179 8.78211594 9.34592177 11.771405z"
          />
        </G>
      </G>
    </Svg>
  );
}
export default SvgComponent;
