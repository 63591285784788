import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke={props.color || "#707070"} 
      strokeWidth={props.strokeWidth || 1.5} 
      fill={props.fill || "none"} 
      fillRule="evenodd"
      >
        <Path
          d="M9.955.75a3.055 3.055 0 013.04 3.053v6.19a3.055 3.055 0 01-.893 2.157 3.027 3.027 0 01-2.146.894H3.79A3.055 3.055 0 01.75 9.991V3.804a3.055 3.055 0 01.892-2.16A3.027 3.027 0 013.79.75zM25.21.75a3.055 3.055 0 013.04 3.053v6.19a3.055 3.055 0 01-.893 2.157 3.027 3.027 0 01-2.146.894h-6.166a3.055 3.055 0 01-3.04-3.053V3.804a3.055 3.055 0 01.892-2.16A3.027 3.027 0 0119.044.75zM9.955 15.95a3.055 3.055 0 013.04 3.053v6.19c0 .844-.34 1.608-.89 2.161-.55.553-1.31.895-2.149.896H3.79a3.055 3.055 0 01-3.04-3.053v-6.193a3.055 3.055 0 01.892-2.16 3.027 3.027 0 012.147-.894zM25.21 15.95a3.055 3.055 0 013.04 3.053v6.19c0 .844-.34 1.608-.891 2.161-.55.553-1.31.895-2.148.896h-6.166a3.055 3.055 0 01-3.04-3.053v-6.193a3.055 3.055 0 01.892-2.16 3.027 3.027 0 012.147-.894z"
          transform="translate(6 5)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
