import React from "react";
import { AddressCards } from "presto-react-components";

export default function AddressCard(props) {
  return <AddressCards.AddressCard2 {...props} />;
}

// TODO(refactor): Move this to presto react components ?
// export default function AddressCard({
//   address,
//   width,
//   boldLine1,
//   selected,
//   radioButton,
// }) {
//   const { theme } = useContext(ThemeContext);
//   let lastline = "";
//   if (address.city) {
//     lastline = address.city;
//     if (address.state) {
//       lastline + " , ";
//     }
//   }
//   if (address.state) {
//     lastline += address.state;
//   }
//   return (
//     <TransparentCard padding={20}>
//       <View
//         style={{
//           width: width || "100%",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <View style={{ width: "80%" }}>
//           {address.line1 ? (
//             <PrestoText
//               size={theme.primaryTextSize}
//               color={theme.darkText}
//               extraStyle={boldLine1 && { marginBottom: 5 }}
//               weight={boldLine1 && "700"}
//             >
//               {address.line1}
//             </PrestoText>
//           ) : null}
//           {address.line2 ? (
//             <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
//               {address.line2}
//             </PrestoText>
//           ) : null}
//           {address.area ? (
//             <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
//               {address.area}
//             </PrestoText>
//           ) : null}
//           {address.landmark ? (
//             <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
//               {address.landmark}
//             </PrestoText>
//           ) : null}
//           {lastline ? (
//             <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
//               {lastline}
//             </PrestoText>
//           ) : null}
//           <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
//             {I18n.t("screen_messages.pin_text")}
//             {address.pincode}
//           </PrestoText>
//         </View>
//         <View>
//           {radioButton ? (
//             selected ? (
//               <svgs.RadioButtonSelected
//                 color={theme.secondary}
//                 width={25}
//                 height={25}
//               />
//             ) : (
//               <svgs.RadioButtonNotSelected
//                 color={theme.primary}
//                 width={25}
//                 height={25}
//               />
//             )
//           ) : null}
//         </View>
//       </View>
//       <VerticalSpacing size={15} />
//       <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
//     </TransparentCard>
//   );
// }
