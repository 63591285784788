import * as React from "react";
import Svg, { Circle, G } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      {...props}
    >
      <G
        transform="translate(2 2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          stroke={props.color || "#000"}
          strokeWidth={1.725}
          cx={14}
          cy={14}
          r={13.1375}
        />
        <Circle fill="#42B549" cx={14} cy={14} r={10.5} />
      </G>
    </Svg>
  );
}

export default SvgComponent;
