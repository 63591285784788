import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9.43 0c-4.977 0-9 4.023-9 9s4.023 9 9 9 9-4.023 9-9-4.023-9-9-9zm5.246 6.532l-6.511 6.533a.819.819 0 01-.58.248.784.784 0 01-.582-.248L3.768 9.829a.826.826 0 010-1.182.826.826 0 011.183 0l2.612 2.655 5.931-5.931a.826.826 0 011.182 0c.332.31.332.83 0 1.161z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
