import $ from "../../global/util";

export default {
  GET_EMPLOYEE: {
    PATH: "/status.json",
  },
  GET_ALL_EMPLOYEES: {
    PATH: "/employee/v0/employees.json",
  },
};
