import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={17} height={15} viewBox="0 0 17 15" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M10.313 3.75V0h-3.75v3.75l1.875-.938 1.874.938zm6.248 5.862c-.345-.313-.884-.293-1.248 0l-2.707 2.165a1.865 1.865 0 01-1.171.41H7.969a.469.469 0 010-.937h2.294c.466 0 .9-.32.975-.78a.939.939 0 00-.925-1.095H5.625c-.79 0-1.557.272-2.17.77L2.091 11.25H.469a.469.469 0 00-.469.469v2.812A.469.469 0 00.469 15h10.453c.426 0 .839-.145 1.172-.41l4.43-3.545a.937.937 0 00.037-1.433z"
        />
        <Path d="M14.063.469V7.03a.469.469 0 01-.47.469H3.282a.469.469 0 01-.468-.469V.47A.469.469 0 013.28 0h3.282v3.75l1.875-.938 1.875.938V0h3.28a.469.469 0 01.47.469z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill={props.secondaryColor || "#FFF"} d="M0 0h16.875v15H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
