import AboutUsIcon from "./svgs/AboutUsIcon";
import AccountingIcon from "./svgs/AccountingIcon";
import ActionCompletedIcon from "./svgs/ActionCompletedIcon";
import ActionCompletedIcon2 from "./svgs/ActionCompletedIcon2";
import ActionNeededIcon from "./svgs/ActionNeededIcon";
import ActionNeededInProgressIcon from "./svgs/ActionNeededInProgressIcon";
import ActionNeededOrangeIcon from "./svgs/ActionNeededOrangeIcon";
import ActionNeededRedIcon from "./svgs/ActionNeededRedIcon";
import AddIcon from "./svgs/AddIcon";
import AddLocationIcon from "./svgs/AddLocationIcon";
import AddMoneyIcon from "./svgs/AddMoneyIcon";
import AddressHistoryIcon from "./svgs/AddressHistoryIcon";
import AddressHomeIcon from "./svgs/AddressHomeIcon";
import AddressIcon from "./svgs/AddressIcon";
import AddressLocationIcon from "./svgs/AddressLocationIcon";
import AfterSelectionIcon from "./svgs/AfterSelectionIcon";
import AppleIcon from "./svgs/AppleIcon";
import AppleRoundIcon from "./svgs/AppleRoundIcon";
import Apts from "./svgs/Apts";
import AptsOutline from "./svgs/AptsOutline";
import ArrowDown from "./svgs/ArrowDown";
import ArrowUp from "./svgs/ArrowUp";
import BMCLogo from "./svgs/BMCLogo";
import BMCLogoutIcon from "./svgs/BMCLogoutIcon";
import BackIcon from "./svgs/BackIcon";
import BackLeftArrow from "./svgs/BackLeftArrow";
import BackSpaceIcon from "./svgs/BackSpaceIcon";
import BagIcon from "./svgs/BagIcon";
import BeforeSelectionIcon from "./svgs/BeforeSelectionIcon";
import BillCircularOutline from "./svgs/BillCircularOutline";
import Birthday from "./svgs/Birthday";
import BookIcon from "./svgs/BookIcon";
import BookingsIcon from "./svgs/BookingsIcon";
import Briefcase from "./svgs/Briefcase";
import BronzeCardIcon from "./svgs/BronzeCardIcon";
import BronzeCardIconOpaque from "./svgs/BronzeCardIconOpaque";
import BuPosOperatorOfferOutlineWithCircle from "./svgs/BuPosOperatorOfferOutlineWithCircle";
import BuposClose from "./svgs/BuposClose";
import BuposLeftArrow from "./svgs/BuposLeftArrow";
import BuposPaid from "./svgs/BuposPaid";
import BuposSearch from "./svgs/BuposSearch";
import COD from "./svgs/COD";
import CagetoryFoodIcon from "./svgs/CagetoryFoodIcon";
import Calendar from "./svgs/Calendar";
import Calendar2 from "./svgs/Calendar2";
import CalendarFilledIcon from "./svgs/CalendarFilledIcon";
import CalendarIcon from "./svgs/CalendarIcon";
import CalendarIconWhiteFilled from "./svgs/CalendarIconWhiteFilled";
import CalendarTableIcon from "./svgs/CalendarTableIcon";
import Call from "./svgs/Call";
import CallIcon from "./svgs/CallIcon";
import CallOutline from "./svgs/CallOutline";
import CallOutlineIcon from "./svgs/CallOutlineIcon";
import CameraFilled from "./svgs/CameraFilled";
import CameraIcon from "./svgs/CameraIcon";
import CameraWithCircleFilled from "./svgs/CameraWithCircleFilled";
import CameraWithOutlineCircle from "./svgs/CameraWithOutlineCircle";
import Cancelled from "./svgs/Cancelled";
import CardIcon from "./svgs/CardIcon";
import CardIcon2 from "./svgs/CardIcon2";
import CardIconV2 from "./svgs/CardIconV2";
import CartIcon from "./svgs/CartIcon";
import CartIcon1 from "./svgs/CartIcon1";
import CartIconFilled from "./svgs/CartIconFilled";
import CartIconOutlined from "./svgs/CartIconOutlined";
import CartIconV2 from "./svgs/CartIconV2";
import CashIcon from "./svgs/CashIcon";
import CatalogOutlineWithCircle from "./svgs/CatalogOutlineWithCircle";
import CategoriesIcon from "./svgs/CategoriesIcon";
import CategoriesList from "./svgs/CategoriesList";
import CategoryBeautyIcon from "./svgs/CategoryBeautyIcon";
import CategoryFashionIcon from "./svgs/CategoryFashionIcon";
import CategoryIcon from "./svgs/CategoryIcon";
import CategoryLeisureIcon from "./svgs/CategoryLeisureIcon";
import CategoryListIcon from "./svgs/CategoryListIcon";
import CategoryTravelIcon from "./svgs/CategoryTravelIcon";
import CategoryV2 from "./svgs/CategoryV2";
import CategoryWineIcon from "./svgs/CategoryWineIcon";
import ChangePinIcon from "./svgs/ChangePinIcon";
import ChatIcon from "./svgs/ChatIcon";
import CheckBoxSelected from "./svgs/CheckBoxSelected";
import CheckIcon from "./svgs/CheckIcon";
import CheckIconDeselected from "./svgs/CheckIconDeselected";
import CheckSuccessIcon from "./svgs/CheckSuccessIcon";
import CheckSuccessLargeIcon from "./svgs/CheckSuccessLargeIcon";
import CheckedIcon from "./svgs/CheckedIcon";
import ChefIcon from "./svgs/ChefIcon";
import CircularUserIcon from "./svgs/CircularUserIcon";
import CloseIcon from "./svgs/CloseIcon";
import CloseIcon2 from "./svgs/CloseIcon2";
import CloseIcon3 from "./svgs/CloseIcon3";
import ContactUs from "./svgs/ContactUs";
import CopyIcon from "./svgs/CopyIcon";
import CouponIcon from "./svgs/CouponIcon";
import CouponIconFill from "./svgs/CouponIconFill";
import CuisineIcon from "./svgs/CuisineIcon";
import CustomDataIcon from "./svgs/CustomDataIcon";
import CustomIcon from "./svgs/CustomIcon";
import CustomInternatoinalIcon from "./svgs/CustomInternatoinalIcon";
import CustomSMSIcon from "./svgs/CustomSMSIcon";
import CustomVoiceIcon from "./svgs/CustomVoiceIcon";
import CustomerOutlineCircle from "./svgs/CustomerOutlineCircle";
import DefaultStar from "./svgs/DefaultStar";
import DeleteIcon from "./svgs/DeleteIcon";
import Delivery from "./svgs/Delivery";
import DeliveryIcon from "./svgs/DeliveryIcon";
import DineIn from "./svgs/DineIn";
import Direction from "./svgs/Direction";
import DiscoverIcon from "./svgs/DiscoverIcon";
import DoorStepDeliveryIcon from "./svgs/DoorStepDeliveryIcon";
import DownArrow from "./svgs/DownArrow";
import DownArrow2 from "./svgs/DownArrow2";
import DownArrowWithoutPadding from "./svgs/DownArrowWithoutPadding";
import DownIcon from "./svgs/DownIcon";
import DownloadIcon from "./svgs/DownloadIcon";
import DropDownIcon from "./svgs/DropDownIcon";
import EditIcon from "./svgs/EditIcon";
import EmailFilledIcon from "./svgs/EmailFilledIcon";
import EmailIcon from "./svgs/EmailIcon";
import EmployeeId from "./svgs/EmployeeId";
import EndServiceIcon from "./svgs/EndServiceIcon";
import EnterCodeIcon from "./svgs/EnterCodeIcon";
import EnvelopeIcon from "./svgs/EnvelopeIcon";
import ExportIcon from "./svgs/ExportIcon";
import EyeClose from "./svgs/EyeClose";
import EyeOpen from "./svgs/EyeOpen";
import FacebookIcon from "./svgs/FacebookIcon";
import FacebookIconV2 from "./svgs/FacebookIconV2";
import FacebookRoundIcon from "./svgs/FacebookRoundIcon";
import FacebookRoundedSmall from "./svgs/FacebookRoundedSmall";
import FavoriteIcon from "./svgs/FavoriteIcon";
import FavoriteSelectedIcon from "./svgs/FavoriteSelectedIcon";
import FavouriteIcon from "./svgs/FavouriteIcon";
import FbIcon from "./svgs/FbIcon";
import FilterIcon from "./svgs/FilterIcon";
import FilterIcon2 from "./svgs/FilterIcon2";
import FilterIcon3 from "./svgs/FilterIcon3";
import FilterSelectedIcon from "./svgs/FilterSelectedIcon";
import FreeShippingIcon from "./svgs/FreeShippingIcon";
import Gift from "./svgs/Gift";
import GiftIcon from "./svgs/GiftIcon";
import GlutenFreeIcon from "./svgs/GlutenFreeIcon";
import GoFindIcon from "./svgs/GoFindIcon";
import GoToTop from "./svgs/GoToTop";
import GoldCardIcon from "./svgs/GoldCardIcon";
import GoogleIcon from "./svgs/GoogleIcon";
import GoogleIconV2 from "./svgs/GoogleIconV2";
import GreetIcon from "./svgs/GreetIcon";
import GroceryIcon from "./svgs/GroceryIcon";
import GuestIcon from "./svgs/GuestIcon";
import Hamburger from "./svgs/Hamburger";
import HamburgerIcon from "./svgs/HamburgerIcon";
import HappyFaceIcon from "./svgs/HappyFaceIcon";
import HeartIcon from "./svgs/HeartIcon";
import HighSpenderIcon from "./svgs/HighSpenderIcon";
import HistoryIcon from "./svgs/HistoryIcon";
import HomeFilledIcon from "./svgs/HomeFilledIcon";
import HomeIcon from "./svgs/HomeIcon";
import HomeIconV2 from "./svgs/HomeIconV2";
import HomeIconWithContainer from "./svgs/HomeIconWithContainer";
import HomeOutline from "./svgs/HomeOutline";
import HomeWithPlusIcon from "./svgs/HomeWithPlusIcon";
import HouseWithEyeIcon from "./svgs/HouseWithEyeIcon";
import HyderabadIcon from "./svgs/HyderabadIcon";
import InfoIcon from "./svgs/InfoIcon";
import InformationalIcon from "./svgs/InformationalIcon";
import Instagram from "./svgs/Instagram";
import InstagramRoundedSmall from "./svgs/InstagramRoundedSmall";
import InventoryIcon from "./svgs/InventoryIcon";
import InventoryIconFilled from "./svgs/InventoryIconFilled";
import ItemsAddedBagIcon from "./svgs/ItemsAddedBagIcon";
import LactoseIcon from "./svgs/LactoseIcon";
import LeftArrow from "./svgs/LeftArrow";
import LeftArrowIcon from "./svgs/LeftArrowIcon";
import LeftChevron from "./svgs/LeftChevron";
import LeftIcon from "./svgs/LeftIcon";
import LeftIcon2 from "./svgs/LeftIcon2";
import LineWalletIcon from "./svgs/LineWalletIcon";
import LineWalletIcon2 from "./svgs/LineWalletIcon2";
import LocationDualTone from "./svgs/LocationDualTone";
import LocationIcon from "./svgs/LocationIcon";
import LocationIcon2 from "./svgs/LocationIcon2";
import LocationIcon3 from "./svgs/LocationIcon3";
import LocationIconOutline from "./svgs/LocationIconOutline";
import LocationOutlineIcon from "./svgs/LocationOutlineIcon";
import LockIcon from "./svgs/LockIcon";
import LogoIcon from "./svgs/LogoIcon";
import LogoPrimaryLargeIcon from "./svgs/LogoPrimaryLargeIcon";
import LogoPrimarySmallIcon from "./svgs/LogoPrimarySmallIcon";
import LogoSecondaryLargeIcon from "./svgs/LogoSecondaryLargeIcon";
import LogoSecondarySmallIcon from "./svgs/LogoSecondarySmallIcon";
import LogoTextIconWithCircle from "./svgs/LogoTextIconWithCircle";
import Logout from "./svgs/Logout";
import LogoutIcon from "./svgs/LogoutIcon";
import LogoutIconFilled from "./svgs/LogoutIconFilled";
import LoyaltyIndicatorBronzeIcon from "./svgs/LoyaltyIndicatorBronzeIcon";
import LoyaltyIndicatorGoldIcon from "./svgs/LoyaltyIndicatorGoldIcon";
import LoyaltyIndicatorNoTierIcon from "./svgs/LoyaltyIndicatorNoTierIcon";
import LoyaltyIndicatorSilverIcon from "./svgs/LoyaltyIndicatorSilverIcon";
import LoyaltyTierActiveBronzeIcon from "./svgs/LoyaltyTierActiveBronzeIcon";
import LoyaltyTierActiveGoldIcon from "./svgs/LoyaltyTierActiveGoldIcon";
import LoyaltyTierActiveSilverIcon from "./svgs/LoyaltyTierActiveSilverIcon";
import LoyaltyTierByPassedBronzeIcon from "./svgs/LoyaltyTierByPassedBronzeIcon";
import LoyaltyTierByPassedSilverIcon from "./svgs/LoyaltyTierByPassedSilverIcon";
import LoyaltyTierInActiveBronzeIcon from "./svgs/LoyaltyTierInActiveBronzeIcon";
import LoyaltyTierInActiveGoldIcon from "./svgs/LoyaltyTierInActiveGoldIcon";
import LoyaltyTierInActiveSilverIcon from "./svgs/LoyaltyTierInActiveSilverIcon";
import MailIcon from "./svgs/MailIcon";
import MapIcon from "./svgs/MapIcon";
import MapIconSolid from "./svgs/MapIconSolid";
import MealIcon from "./svgs/MealIcon";
import Menu from "./svgs/Menu";
import MenuIcon from "./svgs/MenuIcon";
import Minus from "./svgs/Minus";
import MinusIcon from "./svgs/MinusIcon";
import MinusIcon2 from "./svgs/MinusIcon2";
import MinusIconBuposOperator from "./svgs/MinusIconBuposOperator";
import MobileIcon from "./svgs/MobileIcon";
import ModalCloseIcon from "./svgs/ModalCloseIcon";
import ModalHeaderBg from "./svgs/ModalHeaderBg";
import MumbaiIcon from "./svgs/MumbaiIcon";
import NavigateIcon from "./svgs/NavigateIcon";
import NewCustomerIcon from "./svgs/NewCustomerIcon";
import NextIcon from "./svgs/NextIcon";
import NoShow from "./svgs/NoShow";
import NonVegIcon from "./svgs/NonVegIcon";
import NotFoundIcon from "./svgs/NotFoundIcon";
import NotificationIcon from "./svgs/NotificationIcon";
import NotificationIconWithDot from "./svgs/NotificationIconWithDot";
import Notifications from "./svgs/Notifications";
import NotoficationsThemeIcon from "./svgs/NotoficationsThemeIcon";
import OfferIcon from "./svgs/OfferIcon";
import OfferIcon2 from "./svgs/OfferIcon2";
import Offers from "./svgs/Offers";
import OffersIcon from "./svgs/OffersIcon";
import Offline from "./svgs/Offline";
import Online from "./svgs/Online";
import Online2 from "./svgs/Online2";
import OnlineV2 from "./svgs/OnlineV2";
import OnlineV2WithoutPadding from "./svgs/OnlineV2WithoutPadding";
import OperatorWithCircle from "./svgs/OperatorWithCircle";
import OrdersIcon from "./svgs/OrdersIcon";
import PasswordHide from "./svgs/PasswordHide";
import PasswordShow from "./svgs/PasswordShow";
import PauseButtonIcon from "./svgs/PauseButtonIcon";
import PayCircle from "./svgs/PayCircle";
import PayCircleIcon from "./svgs/PayCircleIcon";
import PayInStoreIcon from "./svgs/PayInStoreIcon";
import PickupIcon from "./svgs/PickupIcon";
import Pin from "./svgs/Pin";
import PlainLocation from "./svgs/PlainLocation";
import PlainLocationColored from "./svgs/PlainLocationColored";
import PlayButtonIcon from "./svgs/PlayButtonIcon";
import PlayIcon from "./svgs/PlayIcon";
import Plus from "./svgs/Plus";
import PlusBoldIcon from "./svgs/PlusBoldIcon";
import PlusIcon from "./svgs/PlusIcon";
import PlusIcon2 from "./svgs/PlusIcon2";
import PlusIconBuposOperator from "./svgs/PlusIconBuposOperator";
import PlusIconWithCircle from "./svgs/PlusIconWithCircle";
import PostPaidIcon from "./svgs/PostPaidIcon";
import PrePaidIcon from "./svgs/PrePaidIcon";
import PrivacyPolicyIcon from "./svgs/PrivacyPolicyIcon";
import ProductOutlineWithCircle from "./svgs/ProductOutlineWithCircle";
import ProfileDuleTone from "./svgs/ProfileDuleTone";
import ProfileIcon from "./svgs/ProfileIcon";
import ProfileIconOutlined from "./svgs/ProfileIconOutlined";
import QualityCheckIcon from "./svgs/QualityCheckIcon";
import RadioButtonNotSelected from "./svgs/RadioButtonNotSelected";
import RadioButtonSelected from "./svgs/RadioButtonSelected";
import RadioSelectedIcon from "./svgs/RadioSelectedIcon";
import RadioUnSelectedIcon from "./svgs/RadioUnSelectedIcon";
import RecordCollectionIcon from "./svgs/RecordCollectionIcon";
import RecordPreviousCollectionIcon from "./svgs/RecordPreviousCollectionIcon";
import Refer from "./svgs/Refer";
import ReferAndEarnIcon from "./svgs/ReferAndEarnIcon";
import ReferFriendsIcon from "./svgs/ReferFriendsIcon";
import RefreshIcon from "./svgs/RefreshIcon";
import RefreshIcon2 from "./svgs/RefreshIcon2";
import RefundIcon from "./svgs/RefundIcon";
import ReportsIcon from "./svgs/ReportsIcon";
import ReportsIconFilled from "./svgs/ReportsIconFilled";
import ReturnIcon from "./svgs/ReturnIcon";
import ReturnIcon2 from "./svgs/ReturnIcon2";
import ReturningCustomer from "./svgs/ReturningCustomer";
import ReturnsAndRefunds from "./svgs/ReturnsAndRefunds";
import RightArrow from "./svgs/RightArrow";
import RightArrow2 from "./svgs/RightArrow2";
import RightArrow3 from "./svgs/RightArrow3";
import RightArrowIcon from "./svgs/RightArrowIcon";
import RightArrowWithTail from "./svgs/RightArrowWithTail";
import RightChevron from "./svgs/RightChevron";
import RightIcon from "./svgs/RightIcon";
import RightIcon2 from "./svgs/RightIcon2";
import RightIconWithoutPadding from "./svgs/RightIconWithoutPadding";
import RoundedMinusIcon from "./svgs/RoundedMinusIcon";
import RoundedPlusIcon from "./svgs/RoundedPlusIcon";
import SavedCardIcon from "./svgs/SavedCardIcon";
import SavedCardsIcon from "./svgs/SavedCardsIcon";
import ScanIcon from "./svgs/ScanIcon";
import ScanIcon2 from "./svgs/ScanIcon2";
import ScrollToTopIcon from "./svgs/ScrollToTopIcon";
import SearchIcon from "./svgs/SearchIcon";
import SearchIcon2 from "./svgs/SearchIcon2";
import SearchIconOutlined from "./svgs/SearchIconOutlined";
import SearchIconV2 from "./svgs/SearchIconV2";
import SearchInputBoxIcon from "./svgs/SearchInputBoxIcon";
import SelectedCheckbox from "./svgs/SelectedCheckbox";
import SendIcon from "./svgs/SendIcon";
import ServiceOutlineWithCircle from "./svgs/ServiceOutlineWithCircle";
import Services from "./svgs/Services";
import ServicesIcon from "./svgs/ServicesIcon";
import SettingIcon1 from "./svgs/SettingIcon1";
import ShareIcon from "./svgs/ShareIcon";
import ShippingAndDelivery from "./svgs/ShippingAndDelivery";
import ShopIcon from "./svgs/ShopIcon";
import ShopIconFilled from "./svgs/ShopIconFilled";
import SilverCardIcon from "./svgs/SilverCardIcon";
import SilverCardIconOpaque from "./svgs/SilverCardIconOpaque";
import SortFilterIcon from "./svgs/SortFilterIcon";
import SortIcon from "./svgs/SortIcon";
import SortIcon2 from "./svgs/SortIcon2";
import SortIcon3 from "./svgs/SortIcon3";
import SortSelected from "./svgs/SortSelected";
import SortSelectedFilterIcon from "./svgs/SortSelectedFilterIcon";
import StaffIcon from "./svgs/StaffIcon";
import StaffIconFilled from "./svgs/StaffIconFilled";
import StarIcon from "./svgs/StarIcon";
import StartDate from "./svgs/StartDate";
import StartServiceIcon from "./svgs/StartServiceIcon";
import Store from "./svgs/Store";
import StoreIcon from "./svgs/StoreIcon";
import StoreIcon2 from "./svgs/StoreIcon2";
import StorePickupIcon from "./svgs/StorePickupIcon";
import StreamingIcon from "./svgs/StreamingIcon";
import SubtractIcon from "./svgs/SubtractIcon";
import SwipeLeft from "./svgs/SwipeLeft";
import TabbarBg from "./svgs/TabbarBg";
import TapAction from "./svgs/TapAction";
import TelcoIcon from "./svgs/TelcoIcon";
import TermsAndConditionsIcon from "./svgs/TermsAndConditionsIcon";
import TermsAndConditionsIcon2 from "./svgs/TermsAndConditionsIcon2";
import ThankYouIcon from "./svgs/ThankYouIcon";
import ThemeStarIcon from "./svgs/ThemeStarIcon";
import TickIcon from "./svgs/TickIcon";
import TickIcon1 from "./svgs/TickIcon1";
import TickIcon2 from "./svgs/TickIcon2";
import TickIconFilled from "./svgs/TickIconFilled";
import TickMarkCircularFilledIcon from "./svgs/TickMarkCircularFilledIcon";
import TicketIcon from "./svgs/TicketIcon";
import TimeWithCircle from "./svgs/TimeWithCircle";
import Timer from "./svgs/Timer";
import TimerIcon from "./svgs/TimerIcon";
import TopUpIcon from "./svgs/TopUpIcon";
import TransactionIcon from "./svgs/TransactionIcon";
import TransactionIcon2 from "./svgs/TransactionIcon2";
import TrashIcon from "./svgs/TrashIcon";
import TrashIcon2 from "./svgs/TrashIcon2";
import TrashIconWithCircle from "./svgs/TrashIconWithCircle";
import TruckIcon from "./svgs/TruckIcon";
import UnCheckedIcon from "./svgs/UnCheckedIcon";
import UnSelectedCheckbox from "./svgs/UnSelectedCheckbox";
import UpArrowIcon from "./svgs/UpArrowIcon";
import UpArrowIcon2 from "./svgs/UpArrowIcon2";
import UpComingAppointment from "./svgs/UpComingAppointment";
import UpIcon from "./svgs/UpIcon";
import UserIcon from "./svgs/UserIcon";
import UserIconCircularOutline from "./svgs/UserIconCircularOutline";
import UserIconFilled from "./svgs/UserIconFilled";
import UserIconWhite from "./svgs/UserIconWhite";
import UserOutline from "./svgs/UserOutline";
import UserPinIcon from "./svgs/UserPinIcon";
import UserRole from "./svgs/UserRole";
import VegIcon from "./svgs/VegIcon";
import VeganIcon from "./svgs/VeganIcon";
import VerticalDashedLine from "./svgs/VerticalDashedLine";
import ViewMoreIcon from "./svgs/ViewMoreIcon";
import WalletFilled from "./svgs/WalletFilled";
import WalletFilledIcon from "./svgs/WalletFilledIcon";
import WalletIcon from "./svgs/WalletIcon";
import WaringIcon from "./svgs/WaringIcon";
import WarrantyIcon from "./svgs/WarrantyIcon";
import WarrantyIcon2 from "./svgs/WarrantyIcon2";
import WebsiteIcon from "./svgs/WebsiteIcon";
import WhatsappIcon from "./svgs/WhatsappIcon";
import WhtsappFilledIcon from "./svgs/WhtsappFilledIcon";
import WhtsappIcon from "./svgs/WhtsappIcon";
import ZipIcon from "./svgs/ZipIcon";

export default {
  AboutUsIcon,
  AccountingIcon,
  ActionCompletedIcon,
  ActionCompletedIcon2,
  ActionNeededIcon,
  ActionNeededInProgressIcon,
  ActionNeededOrangeIcon,
  ActionNeededRedIcon,
  AddIcon,
  AddLocationIcon,
  AddMoneyIcon,
  AddressHistoryIcon,
  AddressHomeIcon,
  AddressIcon,
  AddressLocationIcon,
  AfterSelectionIcon,
  AppleIcon,
  AppleRoundIcon,
  Apts,
  AptsOutline,
  ArrowDown,
  ArrowUp,
  BMCLogo,
  BMCLogoutIcon,
  BackIcon,
  BackLeftArrow,
  BackSpaceIcon,
  BagIcon,
  BeforeSelectionIcon,
  BillCircularOutline,
  Birthday,
  BookIcon,
  BookingsIcon,
  Briefcase,
  BronzeCardIcon,
  BronzeCardIconOpaque,
  BuPosOperatorOfferOutlineWithCircle,
  BuposClose,
  BuposLeftArrow,
  BuposPaid,
  BuposSearch,
  COD,
  CagetoryFoodIcon,
  Calendar,
  Calendar2,
  CalendarFilledIcon,
  CalendarIcon,
  CalendarIconWhiteFilled,
  CalendarTableIcon,
  Call,
  CallIcon,
  CallOutline,
  CallOutlineIcon,
  CameraFilled,
  CameraIcon,
  CameraWithCircleFilled,
  CameraWithOutlineCircle,
  Cancelled,
  CardIcon,
  CardIcon2,
  CardIconV2,
  CartIcon,
  CartIcon1,
  CartIconFilled,
  CartIconOutlined,
  CartIconV2,
  CashIcon,
  CatalogOutlineWithCircle,
  CategoriesIcon,
  CategoriesList,
  CategoryBeautyIcon,
  CategoryFashionIcon,
  CategoryIcon,
  CategoryLeisureIcon,
  CategoryListIcon,
  CategoryTravelIcon,
  CategoryV2,
  CategoryWineIcon,
  ChangePinIcon,
  ChatIcon,
  CheckBoxSelected,
  CheckIcon,
  CheckIconDeselected,
  CheckSuccessIcon,
  CheckSuccessLargeIcon,
  CheckedIcon,
  ChefIcon,
  CircularUserIcon,
  CloseIcon,
  CloseIcon2,
  CloseIcon3,
  ContactUs,
  CopyIcon,
  CouponIcon,
  CouponIconFill,
  CuisineIcon,
  CustomDataIcon,
  CustomIcon,
  CustomInternatoinalIcon,
  CustomSMSIcon,
  CustomVoiceIcon,
  CustomerOutlineCircle,
  DefaultStar,
  DeleteIcon,
  Delivery,
  DeliveryIcon,
  DineIn,
  Direction,
  DiscoverIcon,
  DoorStepDeliveryIcon,
  DownArrow,
  DownArrow2,
  DownArrowWithoutPadding,
  DownIcon,
  DownloadIcon,
  DropDownIcon,
  EditIcon,
  EmailFilledIcon,
  EmailIcon,
  EmployeeId,
  EndServiceIcon,
  EnterCodeIcon,
  EnvelopeIcon,
  ExportIcon,
  EyeClose,
  EyeOpen,
  FacebookIcon,
  FacebookIconV2,
  FacebookRoundIcon,
  FacebookRoundedSmall,
  FavoriteIcon,
  FavoriteSelectedIcon,
  FavouriteIcon,
  FbIcon,
  FilterIcon,
  FilterIcon2,
  FilterIcon3,
  FilterSelectedIcon,
  FreeShippingIcon,
  Gift,
  GiftIcon,
  GlutenFreeIcon,
  GoFindIcon,
  GoToTop,
  GoldCardIcon,
  GoogleIcon,
  GoogleIconV2,
  GreetIcon,
  GroceryIcon,
  GuestIcon,
  Hamburger,
  HamburgerIcon,
  HappyFaceIcon,
  HeartIcon,
  HighSpenderIcon,
  HistoryIcon,
  HomeFilledIcon,
  HomeIcon,
  HomeIconV2,
  HomeIconWithContainer,
  HomeOutline,
  HomeWithPlusIcon,
  HouseWithEyeIcon,
  HyderabadIcon,
  InfoIcon,
  InformationalIcon,
  Instagram,
  InstagramRoundedSmall,
  InventoryIcon,
  InventoryIconFilled,
  ItemsAddedBagIcon,
  LactoseIcon,
  LeftArrow,
  LeftArrowIcon,
  LeftChevron,
  LeftIcon,
  LeftIcon2,
  LineWalletIcon,
  LineWalletIcon2,
  LocationDualTone,
  LocationIcon,
  LocationIcon2,
  LocationIcon3,
  LocationIconOutline,
  LocationOutlineIcon,
  LockIcon,
  LogoIcon,
  LogoPrimaryLargeIcon,
  LogoPrimarySmallIcon,
  LogoSecondaryLargeIcon,
  LogoSecondarySmallIcon,
  LogoTextIconWithCircle,
  Logout,
  LogoutIcon,
  LogoutIconFilled,
  LoyaltyIndicatorBronzeIcon,
  LoyaltyIndicatorGoldIcon,
  LoyaltyIndicatorNoTierIcon,
  LoyaltyIndicatorSilverIcon,
  LoyaltyTierActiveBronzeIcon,
  LoyaltyTierActiveGoldIcon,
  LoyaltyTierActiveSilverIcon,
  LoyaltyTierByPassedBronzeIcon,
  LoyaltyTierByPassedSilverIcon,
  LoyaltyTierInActiveBronzeIcon,
  LoyaltyTierInActiveGoldIcon,
  LoyaltyTierInActiveSilverIcon,
  MailIcon,
  MapIcon,
  MapIconSolid,
  MealIcon,
  Menu,
  MenuIcon,
  Minus,
  MinusIcon,
  MinusIcon2,
  MinusIconBuposOperator,
  MobileIcon,
  ModalCloseIcon,
  ModalHeaderBg,
  MumbaiIcon,
  NavigateIcon,
  NewCustomerIcon,
  NextIcon,
  NoShow,
  NonVegIcon,
  NotFoundIcon,
  NotificationIcon,
  NotificationIconWithDot,
  Notifications,
  NotoficationsThemeIcon,
  OfferIcon,
  OfferIcon2,
  Offers,
  OffersIcon,
  Offline,
  Online,
  Online2,
  OnlineV2,
  OnlineV2WithoutPadding,
  OperatorWithCircle,
  OrdersIcon,
  PasswordHide,
  PasswordShow,
  PauseButtonIcon,
  PayCircle,
  PayCircleIcon,
  PayInStoreIcon,
  PickupIcon,
  Pin,
  PlainLocation,
  PlainLocationColored,
  PlayButtonIcon,
  PlayIcon,
  Plus,
  PlusBoldIcon,
  PlusIcon,
  PlusIcon2,
  PlusIconBuposOperator,
  PlusIconWithCircle,
  PostPaidIcon,
  PrePaidIcon,
  PrivacyPolicyIcon,
  ProductOutlineWithCircle,
  ProfileDuleTone,
  ProfileIcon,
  ProfileIconOutlined,
  QualityCheckIcon,
  RadioButtonNotSelected,
  RadioButtonSelected,
  RadioSelectedIcon,
  RadioUnSelectedIcon,
  RecordCollectionIcon,
  RecordPreviousCollectionIcon,
  Refer,
  ReferAndEarnIcon,
  ReferFriendsIcon,
  RefreshIcon,
  RefreshIcon2,
  RefundIcon,
  ReportsIcon,
  ReportsIconFilled,
  ReturnIcon,
  ReturnIcon2,
  ReturningCustomer,
  ReturnsAndRefunds,
  RightArrow,
  RightArrow2,
  RightArrow3,
  RightArrowIcon,
  RightArrowWithTail,
  RightChevron,
  RightIcon,
  RightIcon2,
  RightIconWithoutPadding,
  RoundedMinusIcon,
  RoundedPlusIcon,
  SavedCardIcon,
  SavedCardsIcon,
  ScanIcon,
  ScanIcon2,
  ScrollToTopIcon,
  SearchIcon,
  SearchIcon2,
  SearchIconOutlined,
  SearchIconV2,
  SearchInputBoxIcon,
  SelectedCheckbox,
  SendIcon,
  ServiceOutlineWithCircle,
  Services,
  ServicesIcon,
  SettingIcon1,
  ShareIcon,
  ShippingAndDelivery,
  ShopIcon,
  ShopIconFilled,
  SilverCardIcon,
  SilverCardIconOpaque,
  SortFilterIcon,
  SortIcon,
  SortIcon2,
  SortIcon3,
  SortSelected,
  SortSelectedFilterIcon,
  StaffIcon,
  StaffIconFilled,
  StarIcon,
  StartDate,
  StartServiceIcon,
  Store,
  StoreIcon,
  StoreIcon2,
  StorePickupIcon,
  StreamingIcon,
  SubtractIcon,
  SwipeLeft,
  TabbarBg,
  TapAction,
  TelcoIcon,
  TermsAndConditionsIcon,
  TermsAndConditionsIcon2,
  ThankYouIcon,
  ThemeStarIcon,
  TickIcon,
  TickIcon1,
  TickIcon2,
  TickIconFilled,
  TickMarkCircularFilledIcon,
  TicketIcon,
  TimeWithCircle,
  Timer,
  TimerIcon,
  TopUpIcon,
  TransactionIcon,
  TransactionIcon2,
  TrashIcon,
  TrashIcon2,
  TrashIconWithCircle,
  TruckIcon,
  UnCheckedIcon,
  UnSelectedCheckbox,
  UpArrowIcon,
  UpArrowIcon2,
  UpComingAppointment,
  UpIcon,
  UserIcon,
  UserIconCircularOutline,
  UserIconFilled,
  UserIconWhite,
  UserOutline,
  UserPinIcon,
  UserRole,
  VegIcon,
  VeganIcon,
  VerticalDashedLine,
  ViewMoreIcon,
  WalletFilled,
  WalletFilledIcon,
  WalletIcon,
  WaringIcon,
  WarrantyIcon,
  WarrantyIcon2,
  WebsiteIcon,
  WhatsappIcon,
  WhtsappFilledIcon,
  WhtsappIcon,
  ZipIcon
};
