import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#fff"}>
        <Path
          opacity={0.4}
          d="M19.375 2.5H11.25V5h8.125A.625.625 0 0020 4.375v-1.25a.625.625 0 00-.625-.625zM.625 5H7.5V2.5H.625A.625.625 0 000 3.125v1.25A.625.625 0 00.625 5zm0 6.25H12.5v-2.5H.625A.625.625 0 000 9.375v1.25a.625.625 0 00.625.625zm18.75-2.5H16.25v2.5h3.125a.624.624 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm0 6.25H6.25v2.5h13.125a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zM0 15.625v1.25a.625.625 0 00.625.625H2.5V15H.625a.625.625 0 00-.625.625z"
        />
        <Path d="M10.625 1.25h-1.25a.625.625 0 00-.625.625v3.75a.625.625 0 00.625.625h1.25a.625.625 0 00.625-.625v-3.75a.625.625 0 00-.625-.625zm5 6.25h-1.25a.625.625 0 00-.625.625v3.75a.624.624 0 00.625.625h1.25a.624.624 0 00.625-.625v-3.75a.625.625 0 00-.625-.625zm-10 6.25h-1.25a.625.625 0 00-.625.625v3.75a.625.625 0 00.625.625h1.25a.625.625 0 00.625-.625v-3.75a.625.625 0 00-.625-.625z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h20v20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
