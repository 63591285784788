import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

 export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(7 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path
          stroke={props.color || "#707070"}
          strokeWidth={2.016}
          d="M0 5.8L2.88888889 5.8 26 5.8"
        />
        <Path
          d="M23.111 5.8v20.3c0 1.602-1.293 2.9-2.889 2.9H5.778a2.894 2.894 0 01-2.89-2.9V5.8m4.334 0V2.9c0-1.602 1.294-2.9 2.89-2.9h5.777a2.894 2.894 0 012.889 2.9v2.9"
          stroke={props.color || "#707070"}
          strokeWidth={2.016}
          fill={props.color || "#707070"}
        />
        <Path
          stroke={props.color2 || "#fff"}
          strokeWidth={1.2}
          d="M10.0454545 11.4791667L10.0454545 22.3541667"
        />
        <Path
          stroke={props.color2 || "#fff"}
          strokeWidth={1.2}
          d="M15.9545455 11.4791667L15.9545455 22.3541667"
        />
      </G>
    </Svg>
  );
}

