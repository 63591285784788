import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg"

function SvgComponent({
  width="45px",
  height="44px",
  fillColor1="#FFF",
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Defs>
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="29.2227031%"
          y2="100%"
          id="a"
        >
          <Stop stopColor="#E1E1E1" offset="0%" />
          <Stop stopColor="#A1A6B5" offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill={fillColor1} d="M0 0H45V44H0z" />
        <G fillRule="nonzero">
          <Path
            d="M18.582.795l9.586 5.474a5.922 5.922 0 012.999 5.139v10.948a5.922 5.922 0 01-3 5.139l-9.585 5.474a6.055 6.055 0 01-5.998 0L3 27.495A5.922 5.922 0 010 22.356V11.408a5.922 5.922 0 012.999-5.139L12.584.795a6.055 6.055 0 015.998 0z"
            transform="translate(6 4) translate(1.417 1.416)"
            fill="#000"
          />
          <Path
            d="M13.298.981a7.473 7.473 0 017.404 0l9.585 5.474a7.378 7.378 0 012.716 2.688c.643 1.1.997 2.366.997 3.681v10.949c0 1.315-.354 2.58-.997 3.68a7.378 7.378 0 01-2.716 2.688l-9.585 5.475a7.473 7.473 0 01-7.404 0L3.713 30.14a7.378 7.378 0 01-2.716-2.688A7.296 7.296 0 010 23.773V12.824c0-1.315.354-2.58.997-3.68a7.378 7.378 0 012.716-2.689L13.298.981z"
            stroke="#53C175"
            strokeWidth={2}
            fill="#FFF"
            transform="translate(6 4)"
          />
          <Path
            d="M19.411 5.651l7.606 4.344a4.699 4.699 0 012.38 4.077v8.688a4.699 4.699 0 01-2.38 4.077l-7.606 4.344a4.804 4.804 0 01-4.759 0l-7.606-4.344a4.699 4.699 0 01-2.38-4.077v-8.688c0-1.682.908-3.236 2.38-4.077l7.606-4.344a4.804 4.804 0 014.76 0z"
            fill="url(#a)"
            transform="translate(6 4)"
          />
        </G>
        <G transform="translate(6 4) translate(11 13)">
          <Path stroke="#FFF" strokeLinecap="round" d="M0.5 9.55L11 9.63" />
          <Path
            d="M5.98.109L8 2.887a.25.25 0 00.367.05l1.027-.854a.25.25 0 01.368.05c.036.05.055.11.055.173v2.25c0 .155.118.281.263.281a.252.252 0 00.172-.07l.863-.81a.25.25 0 01.37.027.294.294 0 01.065.185v3.55c0 .155-.118.281-.263.281H.262C.118 8 0 7.874 0 7.718V4.176c0-.155.118-.282.263-.282.064 0 .126.026.174.072l.858.82a.25.25 0 00.37-.023.294.294 0 00.067-.188V2.312c0-.156.118-.282.263-.282.059 0 .116.021.162.06l1 .845a.25.25 0 00.368-.047L5.568.108a.25.25 0 01.413 0z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
