import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={30} height={25} viewBox="0 0 30 25" {...props}>
      <G fill={props.primaryColor || "#000"} fillRule="evenodd">
        <Path d="M24.31 6.904c-.243-1.32-1.152-2.116-2.615-2.298C21.659 4.599 18.094 4 12.5 4s-9.16.6-9.183.604C1.842 4.788.933 5.584.693 6.89.686 6.916 0 9.483 0 12.61c0 3.128.686 5.696.69 5.707.243 1.321 1.152 2.117 2.615 2.299.029.005 2.341.393 6.152.545l2.27 3.448c.16.242.454.391.773.391s.614-.15.773-.391l2.27-3.448c3.81-.152 6.12-.54 6.14-.543 1.475-.184 2.384-.98 2.625-2.287.006-.025.692-2.593.692-5.72 0-3.128-.686-5.695-.69-5.707z" />
        <Path
          d="M29.283 2.833C29.03 1.545 28.085.768 26.563.591 26.526.585 22.818 0 17.002 0c-5.817 0-9.525.585-9.55.59-1.19.138-2.02.64-2.452 1.453a81.312 81.312 0 017.508-.333c5.639 0 9.39.533 9.941.616 2.483.307 4.209 1.724 4.627 3.8.18.676.751 3.054.751 5.893 0 1.52-.166 2.916-.345 3.981.985-.342 1.603-1.027 1.798-2.019.007-.024.72-2.529.72-5.58 0-3.052-.713-5.557-.717-5.568z"
          opacity={0.4}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
