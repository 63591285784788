import React, { useContext, useState, useEffect, useRef } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import asyncify from "../../common/Asyncify";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import TestimonialsComponent from "@presto-screen-components/Testimonials/Testimonials";
import CarouselGallery from "@presto-screen-components/CarouselGallery/CarouselGallery";
import CategoriesSection from "@presto-screen-components/CategoriesSection/CategoriesSection";
import ShopCategoriesSection from "@presto-screen-components/ShopCategoriesSection/ShopCategoriesSection";
import OnOfferItems from "@presto-screen-components/OnOfferItems/OnOfferItems";
import BestSellers from "@presto-screen-components/BestSellers/BestSellers";
import LastOrderComponent from "@presto-screen-components/LastOrderComponent/LastOrderComponent";
import TrackOrderComponent from "@presto-screen-components/TrackOrderComponent/TrackOrderComponent";
import WebLayout from "@presto-screens/WebLayout/WebLayout";
import OffersManager from "@presto-services/features/offers/OffersManager";
const AsyncOfferManager = asyncify(OffersManager);
import MarqeeText from "@presto-components/Marquee/Marquee";
import { VerticalSpacing } from "@presto-components/Spacing";
import Footer from "@presto-screen-components/Footer/Footer";
import AnalyticsManager from "../../common/AnalyticsManager";
import * as Linking from "expo-linking";
import I18n from "i18n-js";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";

export default function Home({ navigation }) {
  const { theme } = useContext(ThemeContext);
  const { user, authState, isUserLoggedIn } = useContext(UserContext);
  const [rollingText, setRollingText] = useState("");
  const windoWidth = Dimensions.get("window").width;
  const scroll = useRef();
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);

  const scrollToTop = () => {
    if (scroll.current !== null) {
      window.scrollTo({
        behavior: "smooth",
        top: scroll.current.scrollTo(0),
      });
    } else {
      console.log("Scroll", scroll.current);
    }
  };

  useEffect(() => {
    getRollingText();
  }, []);

  const getRollingText = async () => {
    const [error, response] = await AsyncOfferManager.getHomePageOffers();
    if (response) {
      setRollingText(response.data.offer_text);
    }
  };

  const onOfferClick = (item) => {
    AnalyticsManager.send("click_on_banner_card", { banner: "Offers" });

    if (item.target?.type) {
      switch (item.target?.type) {
        case "category":
          Navigator.emit({
            event: "push",
            params: {
              screenName: "BROWSE_CATEGORIES",
              screenParams: {
                categoryId: item.other_data.category_id,
                subCategoryId: item.target.id_value,
              },
            },
          });
          return;
        case "url":
          windows.open(item.target.id_value);
          return;
      }
    } else {
      setPaneState(true);
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "REFERRAL_SCREEN",
          screenParams: {
            searchParams: {
              searchString: item?.other_data?.company_name,
            },
          },
        },
      });
    }
  };

  return (
    <WebLayout>
      <ScrollView
        ref={scroll}
        scrollEnabled={true}
        style={{ width: "100%", height: "100%", flex: 1 }}
        contentContainerStyle={{}}
      >
        <View style={{}}>
          <View
            style={{
              backgroundColor: "#EFEDED",
              height: 50,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View style={{ width: "90%", alignSelf: "center" }}>
              <CategoriesSection theme={theme} />
            </View>
          </View>
          {rollingText ? (
            <View
              style={{
                backgroundColor: theme.primary,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View style={{ width: "91%", alignSelf: "center" }}>
                <MarqeeText paddingHorizontal={0} text={rollingText} />
              </View>
            </View>
          ) : null}
          <CarouselGallery
            title=""
            name="OFFERS_WEB"
            height={windoWidth / 3.6}
            width={windoWidth}
          />
          <VerticalSpacing size={20} />
          <View
            style={{
              width: "90%",
              alignSelf: "center",
            }}
          >
            {user ? <TrackOrderComponent /> : null}
            {user ? (
              <LastOrderComponent
                HeadingText={I18n.t("screen_messages.past_order_title")}
              />
            ) : null}
            <VerticalSpacing size={20} />
            <OnOfferItems />
            <View style={{ marginHorizontal: -theme.primaryPadding }}>
              <ShopCategoriesSection theme={theme} />
            </View>
            <BestSellers />
            <VerticalSpacing size={30} />
            <CarouselGallery
              slidesToShow={2}
              width="98%"
              height={450}
              borderRadius={7}
              title=""
              name="MISCELLANEOUS"
              fontStyle="700.bold"
              fontSize={18}
              onItemClicked={onOfferClick}
            />
            <View style={{ marginHorizontal: -theme.primaryPadding }}>
              <TestimonialsComponent fontStyle="700.bold" fontSize={18} />
            </View>
          </View>
        </View>
        <Footer
          ScrollToTop={() => scrollToTop()}
          showFooterText={true}
          showWhatsappIcon={false}
        />
      </ScrollView>
    </WebLayout>
  );
}
