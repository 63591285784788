import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function TransactionIcon({
  width="40px",
  height="40px",
  strokeColor="#333",
  color="#333",
  style,
}) {
  return (
    <Svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill={color} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path
          d="M23.865 8.455H2.137L.015 28.125c-.01.093-.015.187-.015.28C0 29.84 1.15 31 2.57 31h20.86c.093 0 .185-.005.278-.015 1.41-.155 2.43-1.435 2.277-2.86l-2.12-19.67zM3.332 9.799H22.67l1.99 18.472a1.246 1.246 0 01-1.23 1.384H2.57c-.683 0-1.237-.56-1.237-1.25l.007-.134L3.332 9.799z"
          transform="translate(7 5)"
        />
        <Path
          d="M22.041 22.098c.288 0 .52.215.52.48 0 .244-.195.445-.45.476l-.07.005H4.045c-.287 0-.52-.215-.52-.48 0-.244.196-.445.45-.477l.07-.004h17.996zM13.043 0c4.946 0 7.987 3.025 9.033 8.86.063.348-.184.68-.55.739-.367.059-.715-.175-.778-.524-.945-5.268-3.484-7.795-7.705-7.795s-6.76 2.527-7.705 7.795c-.063.349-.41.583-.777.524-.367-.06-.613-.39-.55-.74C5.056 3.026 8.096 0 13.042 0z"
          transform="translate(7 5)"
        />
      </G>
    </Svg>
  )
}

export default TransactionIcon