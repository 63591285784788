import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  const fill = props.fill || "#000"
  return (
    <Svg
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={fill} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path d="M102.821 9.43L9.141 103 102.821 103z" />
        <Path d="M392.46 0h-259.6v118c0 8.28-6.73 15-15.02 15H0v364c0 8.28 6.73 15 15.02 15h224.56V227.08c0-24.81 20.21-45 45.05-45h122.85V15c0-8.28-6.74-15-15.02-15zM176.78 380.8c-2 11.14-8.46 21.37-18.21 28.8-6.47 4.94-14.15 8.38-22.36 10.13v12.23c0 8.08-6.23 15.03-14.31 15.4h-.15c-8.55.32-15.58-6.51-15.58-14.98v-13.31c-14.97-3.43-28.71-11.12-38.59-22.01-5.57-6.14-5.11-15.63 1.04-21.19 6.15-5.56 15.65-5.1 21.22 1.04 7.65 8.44 19.48 13.65 31.91 14.12 12.93 1 24.31-7.39 25.45-15.5 1.86-10.38-11.68-16.56-17.59-18.74-11.78-4.33-29.95-11.76-35.22-14.13-16.21-6.92-25.76-24.31-23.07-42 2.43-17.66 16.46-32.08 34.85-36.41v-12.87c0-8.47 7.03-15.3 15.58-14.98h.15c8.08.37 14.31 7.32 14.31 15.4v12.21c18.28 4.11 30.81 14.42 31.59 15.08 6.47 5.02 7.54 14.39 2.33 20.72-5.08 6.96-15.12 7.97-21.58 2.31-.14-.12-12.01-9.63-26.8-9.75-3.12-.03-6.37.36-9.68 1.35-13.53 4.67-14.43 16.62-5.05 21.68 8.66 3.76 22.31 9.33 32.98 13.24 31.89 11.75 39.9 34.65 36.78 52.16zM238.57 140h-41.64c-8.3 0-15.02-6.72-15.02-15 0-8.28 6.72-15 15.02-15h41.64c8.3 0 15.02 6.72 15.02 15 0 8.28-6.72 15-15.02 15zm101.73 0h-41.65c-8.3 0-15.02-6.72-15.02-15 0-8.28 6.72-15 15.02-15h41.65c8.3 0 15.02 6.72 15.02 15 0 8.28-6.72 15-15.02 15zm0-60H196.92c-8.3 0-15.02-6.72-15.02-15 0-8.28 6.72-15 15.02-15H340.3c8.3 0 15.02 6.72 15.02 15 0 8.28-6.72 15-15.02 15z" />
        <Path d="M269.616 512h227.366c8.3 0 15.018-6.72 15.018-15V322.08H269.616V512zm181.262-145.01c8.29 0 15.018 6.72 15.018 15 0 8.29-6.728 15-15.018 15-8.29 0-15.018-6.71-15.018-15 .001-8.28 6.729-15 15.018-15zm0 60c8.29 0 15.018 6.72 15.018 15 0 8.29-6.728 15-15.018 15-8.29 0-15.018-6.71-15.018-15 .001-8.28 6.729-15 15.018-15zm-60.06-60c8.29 0 15.018 6.72 15.018 15 0 8.29-6.728 15-15.018 15-8.3 0-15.018-6.71-15.018-15 0-8.28 6.718-15 15.018-15zm0 60c8.29 0 15.018 6.72 15.018 15 0 8.29-6.728 15-15.018 15-8.3 0-15.018-6.71-15.018-15 0-8.28 6.718-15 15.018-15zm-60.08-60c8.29 0 15.018 6.72 15.018 15 0 8.29-6.728 15-15.018 15-8.29 0-15.018-6.71-15.018-15 0-8.28 6.728-15 15.018-15zm0 60c8.29 0 15.018 6.72 15.018 15 0 8.29-6.728 15-15.018 15-8.29 0-15.018-6.71-15.018-15 0-8.28 6.728-15 15.018-15zM496.982 212.08H284.634c-8.3 0-15.018 6.71-15.018 15v65H512v-65c0-8.29-6.718-15-15.018-15z" />
      </G>
    </Svg>
  )
}

export default SvgComponent
