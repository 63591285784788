import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={27} height={24} viewBox="0 0 27 24" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M0 19.5h27v2.25A2.25 2.25 0 0124.75 24H2.25A2.25 2.25 0 010 21.75V19.5zM24.75 6h-2.703a4.143 4.143 0 01-3.685 2.25H16.09l2.925 2.424a.75.75 0 010 1.06l-.53.53a.75.75 0 01-1.06 0L13.5 8.817l-3.925 3.45a.75.75 0 01-1.06 0l-.53-.53a.751.751 0 010-1.062L10.91 8.25H8.638A4.143 4.143 0 014.953 6H2.25A2.25 2.25 0 000 8.25V15h27V8.25A2.25 2.25 0 0024.75 6z"
        fill={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M0 15v4.5h27V15H0zm8.638-6.75h2.272l-2.925 2.424a.75.75 0 000 1.061l.53.53a.75.75 0 001.06 0l3.925-3.45 3.924 3.45a.75.75 0 001.06 0l.53-.53a.75.75 0 000-1.061L16.09 8.25h2.272c2.282 0 4.138-1.85 4.138-4.125C22.5 1.851 20.644 0 18.362 0c-.815 0-1.8.124-2.915 1.046-.571.471-1.184 1.157-1.947 2.183-.765-1.031-1.383-1.716-1.963-2.191C10.424.123 9.447 0 8.637 0 6.358 0 4.5 1.85 4.5 4.125c0 2.274 1.856 4.125 4.138 4.125zm9.724-6a1.875 1.875 0 010 3.75H14.3c2.425-3.586 3.1-3.75 4.062-3.75zm-9.724 0c.937 0 1.632.155 4.062 3.75H8.638a1.875 1.875 0 110-3.75z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
