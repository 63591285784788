import React from "react";
import { View, StyleSheet } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";

export function SectionLinkComponent(props) {
  const {
    rightIcon,
    leftText1,
    leftText2,
    rightText,
    onPressButton,
    theme,
    paddingHorizontal,
  } = props;
  const styles = getStyles(theme);
  return (
    <TouchableWithoutFeedback onPress={onPressButton}>
      <View
        style={{
          ...styles.default,
          paddingHorizontal: props.paddingHorizontal || 0,
        }}
      >
        <FlexRowSpaceAligned>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            {leftText1 ? (
              <PrestoText fontStyle="medium" extraStyle={styles.linkText}>
                {leftText1}
              </PrestoText>
            ) : null}
            {leftText2 ? (
              <PrestoText fontStyle="semibold" extraStyle={styles.linkText}>
                {leftText2}
              </PrestoText>
            ) : null}
          </View>
          <View style={{ flexDirection: "row" }}>
            {rightText ? (
              <PrestoText fontStyle="semibold" extraStyle={styles.linkText}>
                {rightText}
              </PrestoText>
            ) : null}
            {rightIcon ? (
              <PrestoIcon
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 5,
                }}
                icon={rightIcon}
              />
            ) : null}
          </View>
        </FlexRowSpaceAligned>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default SectionLinkComponent;

function getStyles(theme) {
  return {
    default: {
      paddingVertical: 15,
      borderBottomWidth: 1,
      borderColor: theme.linkDividerColor,
    },
    linkText: {
      fontSize: 14,
      color: theme.linkTextColor,
      letterSpacing: 0,
      paddingTop: 2,
    },
  };
}
