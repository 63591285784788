import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
   <Svg
      width={props.width || 40}
      height={props.width || 40}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        transform="translate(8 12)"
        stroke={props.color || "#42B549"}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0 5L5.33333333 10 16 0"
      />
    </Svg>
  )
}

export default SvgComponent
