import React, { createContext, useReducer, useContext } from "react";
import NavigatorContext from "./NavigatorContext";

const RightPaneContext = createContext();

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_PANE_STATE":
      return { ...state, ...payload };
  }
  return state;
}

export function RightPaneContextProvider(props) {
  const { Navigator } = useContext(NavigatorContext);
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    link: null,
  });

  const setPaneState = (open, link) => {
    if (open) {
      dispatch({
        type: "SET_PANE_STATE",
        payload: { open: open, link: link },
      });
    } else {
      Navigator.emit({ event: "popToTop", params: { inSideNavigation: true } });
      dispatch({
        type: "SET_PANE_STATE",
        payload: { open: false, link: null },
      });
    }
  };

  return (
    <RightPaneContext.Provider
      value={{
        rightPaneOpen: state.open,
        rightPaneLink: state.link,
        setPaneState,
      }}
    >
      {props.children}
    </RightPaneContext.Provider>
  );
}

export default RightPaneContext;
