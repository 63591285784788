import * as React from "react";

import Svg, { G, Path } from "react-native-svg";

function LogoSecondarySmallIcon(props) {
  return (
    <Svg
      width="98px"
      height="113px"
      viewBox="0 0 98 113"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path d="M0 0H98V113H0z" />

        <G fill="#303AB6" fillRule="nonzero">
          <Path
            d="M33.38 0h-5.53C12.496 0 0 13.179 0 29.384v65.478c0 .568.227 1.112.632 1.513.404.401.953.626 1.524.625H13.35a2.158 2.158 0 001.524-.625 2.13 2.13 0 00.632-1.513V39.226c0-.774 0-1.063 1.649-1.063H33.38a2.163 2.163 0 001.523-.631c.404-.403.63-.948.628-1.517V24.92a2.15 2.15 0 00-2.151-2.142H16.793c2.272-4.495 6.534-7.391 11.058-7.391h5.529a2.15 2.15 0 002.151-2.142V2.137C35.531.957 34.568 0 33.38 0zM81.844 22.742H67.212c-.251 0-.503 0-.724.03V2.137a2.13 2.13 0 00-.632-1.513A2.158 2.158 0 0064.332 0H53.153a2.144 2.144 0 00-2.15 2.137v65.48c0 7.93 2.829 15.245 7.936 20.559A27.043 27.043 0 0079.079 97h2.765c.571 0 1.12-.224 1.524-.625A2.13 2.13 0 0084 94.862V83.756a2.15 2.15 0 00-2.156-2.142h-2.765a11.803 11.803 0 01-8.384-3.61 14.43 14.43 0 01-4.091-10.488v-28.78c0-.174.608-.608 1.508-.608h13.712A2.15 2.15 0 0084 36.015v-11.13a2.15 2.15 0 00-2.156-2.143z"
            transform="translate(7 8)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default LogoSecondarySmallIcon;
