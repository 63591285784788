import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const CouponResource = function ($http, PrestoSdk) {
  function isValidCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      "/appointments" +
      "/check_coupon.json?merchant_id=" +
      PrestoSdk.getMerchantId() +
      "&" +
      "coupon_code=" +
      params.coupon_code;
    return $http.get(url);
  }

  function applyCoupon(params) {
    var url = PrestoSdk.getHostName() + "/appointments/apply_coupon.json";
    return $http.post(url, params);
  }

  function applyCouponOnOrder(params) {
    var url = PrestoSdk.getHostName() + "/api/1/orders/apply_coupon.json";
    if (PrestoSdk.getAppConfig().merchant_config.features.market_place) {
      url =
        PrestoSdk.getHostName() +
        "/user/v0/marketplace_orders/validate_coupon.json";
    }
    return $http.post(url, params);
  }

  function applyCouponOnSubscription(params) {
    var url =
      PrestoSdk.getHostName() + "/api/1/subscriptions/apply_coupon.json";
    return $http.post(url, params);
  }

  function getMyCoupons(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/coupons.json";
    if (params?.merchant_id) {
      url += "?merchant_id=" + params.merchant_id;
    }
    return $http.get(url);
  }

  return {
    isValidCoupon: isValidCoupon,
    applyCoupon: applyCoupon,
    applyCouponOnOrder: applyCouponOnOrder,
    applyCouponOnSubscription: applyCouponOnSubscription,
    getMyCoupons: getMyCoupons,
  };
};

export default CouponResource($http, PrestoSdk);
