import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Dimensions,
  FlatList,
  Pressable,
  ScrollView,
  View,
} from "react-native";
import HorizontalButtonList from "@presto-components/HorizontalMultiRowButtonList/HorizontalMultiRowButtonList";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import ThemeContext from "@presto-contexts/ThemeContext";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import SearchComponent from "@presto-screen-components/Home/SearchComponent";
import SearchManager from "@presto-services/features/search/SearchManager";
import { useCatalog } from "@presto-stores/CatalogStore";
import AsyncStorage from "@react-native-community/async-storage";
import I18n from "i18n-js";
import _ from "lodash";
import { usePagination } from "../../hooks/pagination";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import config from "@presto-common/config";
import SeeAllText from "@presto-screen-components/SeeAllText/SeeAllText";
import DynamicPriceManager from "@presto-services/common/DynamicPriceManager";

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_QUERY_DONE":
      return {
        ...state,
        ...{
          query: payload,
          auto_complete_results: [],
          user_pressed_enter: true,
          results: [],
        },
      };
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{
          query: payload,
          user_pressed_enter: false,
          results: [],
        },
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
        ...payload,
      };
    case "SET_SORT_TYPE":
      return {
        ...state,
        sort_type: payload,
      };
    case "SET_SEARCH_RESULTS":
      return { ...state, search_results: payload, auto_complete_results: [] };
    case "SET_RECENT_SEARCHES":
      return { ...state, recent_searches: payload };

    case "SET_AUTO_COMPLETE_RESULTS":
      return { ...state, auto_complete_results: _.slice(payload, 0, 7) };
  }
  return state;
}

export default function SearchScreen({
  navigation,
  route,
  searchQuery,
  hideFloatingCart = false,
  hideSearch = false,
  onSuggestionsPress,
}) {
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const [itemPromotions, setItemPromotions] = useState(null);
  const [state, dispatch] = useReducer(reducer, {
    query: "",
  });
  const isDesktop = Dimensions.get("window").width > 940 ? true : false;
  const { searchString } = route.params?.searchParams || {};
  const isDynamicPricingEnabled =
    config.merchant_config.features.dynamic_pricing;
  const searchPage = (pageNumber, success, failure) => {
    const params = {
      search_string: state.query,
      catalog_id: catalog.id,
      fuzziness: 5,
      page: pageNumber,
    };

    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        if (isDynamicPricingEnabled) {
          getItems(response, success);
        } else {
          success(response.hits.hits);
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  const [
    results,
    loadNextPage,
    loadFirstPage,
    tempLoading,
    pageLoaded,
  ] = usePagination(searchPage);

  const loadAutoComplete = () => {
    const params = {
      search_string: state.query,
      catalog_id: catalog.id,
    };

    SearchManager.searchCategoryItem(
      params,
      (response) => {
        if (isDynamicPricingEnabled) {
          getItems(response, setAutoCompleteResults);
        } else {
          setAutoCompleteResults(response.hits.hits);
        }
      },
      (error) => {}
    );
  };

  const getItems = async (response, success) => {
    const dynamicPriceManager = new DynamicPriceManager();
    await dynamicPriceManager.getMasterCatalogItems(
      response.hits.hits,
      success
    );
  };

  const setAutoCompleteResults = (array) => {
    dispatch({
      type: "SET_AUTO_COMPLETE_RESULTS",
      payload: array,
    });
  };

  useEffect(() => {
    if (state.query && state.query !== "" && state.query.length >= 3) {
      loadAutoComplete();
    } else {
      setAutoCompleteResults([]);
    }
  }, [state.query]);

  useEffect(() => {
    if (searchString) {
      dispatch({ type: "SET_SEARCH_QUERY", payload: searchString });
    }
  }, []);

  useEffect(() => {
    if (catalog?.id) {
      if (state.user_pressed_enter && !_.isEmpty(state.query)) {
        loadFirstPage();
      } else if (state.query && state.query !== "" && state.query.length >= 3) {
        loadAutoComplete();
      }
    }
  }, [catalog?.id]);

  useEffect(() => {
    if (state.query !== "") {
      loadFirstPage();
    }
  }, [state.user_pressed_enter]);

  function getItemPromos() {
    ServerCartManager.getProbableItemPromotions(
      {},
      (resp) => {
        console.log("resp :>> ", resp);
        setItemPromotions(resp);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const renderItem = ({ item, index }) => {
    return (
      <ItemCard
        key={item._source.id}
        item={item._source}
        getItemPromotions={getItemPromos}
        promotions={itemPromotions}
      ></ItemCard>
    );
  };

  const searchInputRef = useRef();
  useEffect(() => {
    if (route?.params.focus) {
      searchInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (searchQuery) {
      onSearchChange(searchQuery);
    }
  }, [searchQuery]);

  const onSearch = async (text) => {
    if (_.isObject(text)) {
      text = _.get(text, "nativeEvent.text", "");
    }

    if (text === "" || !_.isString(text)) {
      return;
    }

    dispatch({ type: "SET_SEARCH_QUERY_DONE", payload: text });
    const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
    const recentSearches = recentSearchesJson
      ? JSON.parse(recentSearchesJson)
      : [];
    const filteredSearches = _.filter(
      recentSearches,
      (s) => s !== text && _.isString(s)
    );
    const newRecentSearches = [text, ...filteredSearches];
    const splicedSearches = _.slice(newRecentSearches, 0, 5);
    await AsyncStorage.setItem(
      "RECENT_SEARCHES",
      JSON.stringify(splicedSearches)
    );
  };

  useEffect(() => {
    async function fet() {
      const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
      let tempJSON = recentSearchesJson ? JSON.parse(recentSearchesJson) : [];
      tempJSON = _.filter(tempJSON, (item) => _.isString(item));
      dispatch({
        type: "SET_RECENT_SEARCHES",
        payload: tempJSON,
      });
    }
    fet();
  }, []);

  const clearRecentSearch = async () => {
    dispatch({
      type: "SET_RECENT_SEARCHES",
      payload: [],
    });
    await AsyncStorage.setItem("RECENT_SEARCHES", JSON.stringify([]));
  };

  const handleSuggestionPress = (item) => {
    console.log("handle suggestion");
    if (onSuggestionsPress) {
      onSuggestionsPress(item);
    } else {
      dispatch({ type: "SET_SEARCH_QUERY", payload: item });
    }
  };

  const renderSearchButton = (item) => {
    return (
      <Pressable
        style={{ marginRight: 10 }}
        onPress={() => handleSuggestionPress(item)}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: "#999999",
            borderRadius: 6,
            height: 40,
            minWidth: 30,
            paddingHorizontal: 15,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText fontStyle="400.normal" size={14} color="#999999">
            {item}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const renderEmptySearchPage = () => {
    return (
      <ScrollView style={{ flex: 1, paddingTop: 15 }}>
        {pageLoaded == 1 && state.user_pressed_enter ? (
          <View
            style={{
              paddingVertical: theme.primaryPadding,
              marginBottom: 10,
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <PrestoText color={theme.primary}>
              {I18n.t("screen_messages.products.no_search_results")}
            </PrestoText>
          </View>
        ) : null}
        {state.recent_searches && _.isEmpty(state.recent_searches) ? null : (
          <>
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                marginVertical: 10,
              }}
            >
              <SeeAllText
                rightText={"Clear"}
                leftText={I18n.t("screen_messages.common.recent_searches")}
                onPressRightText={() => {
                  clearRecentSearch();
                }}
                rightTextStyle={{
                  paddingRight: 10,
                }}
              />
            </View>

            <HorizontalButtonList
              style={{ paddingLeft: 10 }}
              renderButton={(item) => renderSearchButton(item)}
              items={state.recent_searches}
              spacing={5}
            />

            <VerticalSpacing size={40} />
          </>
        )}
        <View
          style={{
            paddingHorizontal: theme.primaryPadding,
            marginVertical: 10,
          }}
        >
          <PrestoText
            fontStyle="700.bold"
            size={isDesktop ? theme.h1FontSize : theme.h3FontSize}
            color={theme.darkText}
          >
            {I18n.t("screen_messages.common.popular_searches")}
          </PrestoText>
        </View>
        <HorizontalButtonList
          style={{ paddingLeft: 10 }}
          renderButton={(item) => renderSearchButton(item)}
          items={config.constants.default_search_items}
          spacing={5}
        />
      </ScrollView>
    );
  };

  const onSearchChange = (text) => {
    if (_.isObject(text)) {
      text = _.get(text, "nativeEvent.text", "");
    }

    dispatch({ type: "SET_SEARCH_QUERY", payload: text });
  };

  const renderResults = state.user_pressed_enter
    ? results
    : state.auto_complete_results;

  const renderSearchResults = () => {
    return (
      <FlatList
        contentContainerStyle={{ paddingBottom: 130 }}
        data={renderResults}
        renderItem={renderItem}
        keyExtractor={(item) => item._source.id}
        style={{ paddingTop: 5 }}
        onEndReached={
          results.length > 0
            ? () => {
                console.log("onEndREached");
                loadNextPage();
              }
            : undefined
        }
        onEndReachedThreshold={0.25}
      />
    );
  };

  return (
    <View
      style={{
        flex: 1,
        marginTop: 0,
        backgroundColor: theme.appBackgroundColor,
        zIndex: 100,
      }}
    >
      {!hideSearch ? (
        <View
          style={{
            paddingTop: 20,
            paddingBottom: 10,
            paddingHorizontal: theme.primaryPadding,
          }}
        >
          <SearchComponent
            onSearchChange={onSearchChange}
            query={state.query}
            onSearch={onSearch}
            placeholder={I18n.t("screen_messages.products.search_for_item")}
            autoFocus={true}
            onSearchFocus={() => {}}
            textInputStyle={{ paddingHorizontal: 8 }}
          />
        </View>
      ) : null}
      {_.isEmpty(renderResults)
        ? renderEmptySearchPage()
        : renderSearchResults()}
      {!hideFloatingCart ? <FloatingCartComponent /> : null}
    </View>
  );
}
