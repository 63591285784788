import * as React from "react";
import Svg, { Path } from "react-native-svg";

function TermsAndConditionsIcon(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M37.6 0H23.2c-1.36 0-2.4 1.024-2.4 2.364V29c.4-.158.72-.315.96-.63H37.6c1.36 0 2.4-1.025 2.4-2.365V2.365C40 1.024 38.96 0 37.6 0zM26.634 6.399a.786.786 0 010-1.12.815.815 0 011.136 0l2.632 2.601 2.632-2.6a.815.815 0 011.136 0 .786.786 0 010 1.119l-2.64 2.593 2.64 2.592a.786.786 0 01-.568 1.348.826.826 0 01-.568-.229l-2.632-2.6-2.632 2.6a.826.826 0 01-.568.229.786.786 0 01-.568-1.348l2.64-2.592-2.64-2.593zM36 22.853H24.8c-.48 0-.8-.315-.8-.788 0-.473.32-.788.8-.788H36c.48 0 .8.315.8.788 0 .473-.32.788-.8.788zm0-3.94H24.8c-.48 0-.8-.315-.8-.788 0-.473.32-.788.8-.788H36c.48 0 .8.315.8.788 0 .473-.32.788-.8.788zM16.8 0H2.4C1.04 0 0 1.024 0 2.364v23.641c0 1.34 1.04 2.365 2.4 2.365h15.84c.24.315.64.472.96.63V2.364C19.2 1.024 18.16 0 16.8 0zm-1.6 22.853H4c-.48 0-.8-.315-.8-.788 0-.473.32-.788.8-.788h11.2c.48 0 .8.315.8.788 0 .473-.32.788-.8.788zm0-3.94H4c-.48 0-.8-.315-.8-.788 0-.473.32-.788.8-.788h11.2c.48 0 .8.315.8.788 0 .473-.32.788-.8.788zM16 6.43l-7.2 6.305a.785.785 0 01-.528.197.826.826 0 01-.568-.229l-3.2-3.152a.786.786 0 010-1.119.815.815 0 011.136 0l2.664 2.632 6.64-5.816a.807.807 0 011.128.071A.779.779 0 0116 6.43z"
        transform="translate(0 6)"
        fill={props.color1 ? props.color1 : "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default TermsAndConditionsIcon;
