import * as React from "react";
import Svg, {
  Defs,
  Path,
  LinearGradient,
  Stop,
  G,
  Rect,
  Use,
  Circle,
} from "react-native-svg";
function SvgComponent({ width = "314px", height = "66px" }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 311 59"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Defs>
        <Path
          d="M13.298.981a7.473 7.473 0 017.404 0l9.585 5.474a7.378 7.378 0 012.716 2.688c.643 1.1.997 2.366.997 3.681v10.949c0 1.315-.354 2.58-.997 3.68a7.378 7.378 0 01-2.716 2.688l-9.585 5.475a7.473 7.473 0 01-7.404 0L3.713 30.14a7.378 7.378 0 01-2.716-2.688A7.296 7.296 0 010 23.773V12.824c0-1.315.354-2.58.997-3.68a7.378 7.378 0 012.716-2.689L13.298.981z"
          id="b"
        />
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="29.2227031%"
          y2="100%"
          id="c"
        >
          <Stop stopColor="#F9CF6F" offset="0%" />
          <Stop stopColor="#CEA03E" offset="100%" />
        </LinearGradient>
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="31.5615953%"
          y2="87.1352849%"
          id="d"
        >
          <Stop stopColor="#EEB16F" offset="0%" />
          <Stop stopColor="#9C7143" offset="100%" />
        </LinearGradient>
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="29.2227031%"
          y2="100%"
          id="e"
        >
          <Stop stopColor="#E1E1E1" offset="0%" />
          <Stop stopColor="#A1A6B5" offset="100%" />
        </LinearGradient>
      </Defs>
      <G
        transform="translate(-20 -17) translate(20 28)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Rect
          fill="#D5D3D3"
          fillRule="nonzero"
          x={0}
          y={16}
          width={282}
          height={5}
          rx={2.5}
        />
        <Rect
          fill="#53C175"
          fillRule="nonzero"
          x={0}
          y={16}
          width={283}
          height={5}
          rx={2.5}
        />
        <Path
          d="M18.582.795l9.586 5.474a5.922 5.922 0 012.999 5.139v10.948a5.922 5.922 0 01-3 5.139l-9.585 5.474a6.055 6.055 0 01-5.998 0L3 27.495A5.922 5.922 0 010 22.356V11.408a5.922 5.922 0 012.999-5.139L12.584.795a6.055 6.055 0 015.998 0z"
          transform="translate(266) translate(1.417 1.416)"
          fill="#000"
          fillRule="nonzero"
        />
        <G transform="translate(266)">
          <Use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <Use stroke="#53C175" strokeWidth={2} fill="#FFF" xlinkHref="#b" />
        </G>
        <Path
          d="M19.411 5.651l7.606 4.344a4.699 4.699 0 012.38 4.077v8.688a4.699 4.699 0 01-2.38 4.077l-7.606 4.344a4.804 4.804 0 01-4.759 0l-7.606-4.344a4.699 4.699 0 01-2.38-4.077v-8.688c0-1.682.908-3.236 2.38-4.077l7.606-4.344a4.804 4.804 0 014.76 0z"
          fill="url(#c)"
          fillRule="nonzero"
          transform="translate(266)"
        />
        <Path
          d="M13.117.561l6.766 3.864A4.18 4.18 0 0122 8.052v7.729a4.18 4.18 0 01-2.117 3.627l-6.766 3.864a4.274 4.274 0 01-4.234 0l-6.766-3.864A4.18 4.18 0 010 15.781V8.052a4.18 4.18 0 012.117-3.627L8.883.561a4.274 4.274 0 014.234 0z"
          transform="translate(40 6) translate(1 1)"
          fill="#000"
          fillRule="nonzero"
        />
        <Path
          d="M9.387.693a5.275 5.275 0 015.226 0h0l6.766 3.864a5.208 5.208 0 011.917 1.897A5.15 5.15 0 0124 9.052h0v7.729a5.15 5.15 0 01-.704 2.598 5.208 5.208 0 01-1.917 1.897h0l-6.766 3.864a5.275 5.275 0 01-5.226 0h0l-6.766-3.864A5.208 5.208 0 01.704 19.38 5.15 5.15 0 010 16.78h0V9.052c0-.928.25-1.821.704-2.598A5.208 5.208 0 012.62 4.557h0L9.387.693z"
          stroke="#53C175"
          strokeWidth={2}
          fill="#FFF"
          transform="translate(40 6)"
        />
        <Path
          d="M13.54 4.658l4.92 2.81A3.04 3.04 0 0120 10.106v5.62a3.04 3.04 0 01-1.54 2.639l-4.92 2.81a3.108 3.108 0 01-3.08 0l-4.92-2.81A3.04 3.04 0 014 15.727v-5.62a3.04 3.04 0 011.54-2.639l4.92-2.81a3.108 3.108 0 013.08 0z"
          fill="url(#d)"
          fillRule="nonzero"
          transform="translate(40 6)"
        />
        <G>
          <Path
            d="M13.117.561l6.766 3.864A4.18 4.18 0 0122 8.052v7.729a4.18 4.18 0 01-2.117 3.627l-6.766 3.864a4.274 4.274 0 01-4.234 0l-6.766-3.864A4.18 4.18 0 010 15.781V8.052a4.18 4.18 0 012.117-3.627L8.883.561a4.274 4.274 0 014.234 0z"
            transform="translate(153 6) translate(1 1)"
            fill="#000"
            fillRule="nonzero"
          />
          <Path
            d="M9.387.693a5.275 5.275 0 015.226 0l6.766 3.864a5.208 5.208 0 011.917 1.897A5.15 5.15 0 0124 9.052v7.729a5.15 5.15 0 01-.704 2.598 5.208 5.208 0 01-1.917 1.897l-6.766 3.864a5.275 5.275 0 01-5.226 0l-6.766-3.864A5.208 5.208 0 01.704 19.38 5.15 5.15 0 010 16.78V9.052c0-.928.25-1.821.704-2.598A5.208 5.208 0 012.62 4.557L9.387.693z"
            stroke="#53C175"
            strokeWidth={2}
            fill="#FFF"
            transform="translate(153 6)"
          />
          <Path
            d="M13.54 4.658l4.92 2.81A3.04 3.04 0 0120 10.106v5.62a3.04 3.04 0 01-1.54 2.639l-4.92 2.81a3.108 3.108 0 01-3.08 0l-4.92-2.81A3.04 3.04 0 014 15.727v-5.62a3.04 3.04 0 011.54-2.639l4.92-2.81a3.108 3.108 0 013.08 0z"
            fill="url(#e)"
            fillRule="nonzero"
            transform="translate(153 6)"
          />
        </G>
        <G transform="translate(277 11)" fill="#FFF" stroke="#FFF">
          <G transform="translate(0 2)">
            <Path strokeLinecap="round" d="M0 10.4907407L12 10.4907407" />
            <Path
              strokeWidth={0.5}
              strokeLinejoin="round"
              d="M0 7.94444444L0 4.40552436 2.41949026 6.1749844 6 2.11856003 9.54072571 6.1749844 12 4.40552436 12 7.94444444z"
            />
            <Path
              strokeWidth={0.5}
              strokeLinejoin="round"
              d="M1.8 5.82588441L1.8 2.28696432 3.49364318 4.05642437 6 0 8.478508 4.05642437 10.2 2.28696432 10.2 5.82588441z"
            />
          </G>
          <Circle cx={5.95833333} cy={1.625} r={1.125} />
        </G>
      </G>
    </Svg>
  );
}
export default SvgComponent;