import { LineDivider } from "@presto-components/Dividers/Dividers";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import utils from "../../utils";
import SavingsText from "@presto-screen-components/CartCheckout/SavingsText";
import ToggleBillDetailsText from "@presto-screen-components/CartReview/ToggleBillDetailsText";
import config from "@presto-common/config";

const defaultCurrencyMethod =
  config.constants?.currency_method || "configFormattedCurrencyCents";

function LeftRightRow(props) {
  if (props.children.length !== 2) {
    throw new Error(I18n.t("screen_messages.common.total_number_of_children"));
  }
  if (props.leftWidth && props.rightWidth) {
    throw new Error(I18n.t("screen_messages.common.dont_specify_left_right"));
    {
    }
  }

  const leftStyle = props.rightWidth
    ? { width: props.leftWidth, flex: 1 }
    : { width: props.leftWidth };
  const rightStyle = props.leftWidth
    ? { width: props.rightWidth, flex: 1 }
    : { width: props.rightWidth };

  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        alignItems: "center",
        height: props.height || 40,
      }}
    >
      <View style={leftStyle}>{props.children[0]}</View>
      <View style={rightStyle}>{props.children[1]}</View>
    </View>
  );
}

export default function CartReview({
  order,
  mainColor,
  savingsColor,
  dividerColor,
}) {
  let savings = 0;
  _.forEach(order.items, (product) => {
    let tax = product?.total_tax ? product?.total_tax / 100 : 0;
    const parsedOldPrice = parseFloat(product.other_data?.old_price);
    const oldPrice = isNaN(parsedOldPrice) ? 0 : parsedOldPrice;
    let oldTotal = 0;
    if (oldPrice) {
      oldTotal = (oldPrice * product.quantity + tax) * 100;
      savings += oldTotal - product.total_amount;
    }
  });
  const [showBill, setShowBill] = useState(true);
  const { theme } = useContext(ThemeContext);
  let currencyMethod;
  if (typeof utils[defaultCurrencyMethod] === "function") {
    currencyMethod = utils[defaultCurrencyMethod];
  } else {
    currencyMethod = utils.formattedCurrencyCents;
  }
  return (
    <View>
      <View
        style={{
          marginBottom: 15,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <PrestoText fontStyle="600.medium" size={13} color={theme.darkText}>
            {`${I18n.t("screen_messages.billing.total_text")} ${currencyMethod({
              cents: order?.total_price || 0,
              showDecimal: true,
            })}`}
          </PrestoText>
          <PrestoText
            fontStyle="400.normal"
            size={13}
            color="#666666"
            extraStyle={{ marginLeft: 5 }}
          >
            {`(${order?.item_count} ${
              order?.item_count > 1
                ? I18n.t("screen_messages.orders.items")
                : I18n.t("screen_messages.orders.item")
            })`}
          </PrestoText>
        </View>
        <ToggleBillDetailsText
          fontStyle="400.normal"
          onPress={() => setShowBill(!showBill)}
          size={14}
          color={mainColor || theme.secondary}
        >
          {showBill
            ? I18n.t("screen_messages.billing.hide_bill")
            : I18n.t("screen_messages.billing.show_bill")}
        </ToggleBillDetailsText>
      </View>
      <View style={{ display: showBill ? "flex" : "none" }}>
        <LeftRightRow>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color={theme.darkText}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.billing.sub_total_with_colon")}
          </PrestoText>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color={mainColor || theme.secondary}
            extraStyle={{ marginTop: 2 }}
          >
            {currencyMethod({
              cents: order?.sub_total_price || 0,
              showDecimal: true,
            })}
          </PrestoText>
        </LeftRightRow>
        <LineDivider
          lineHeight={1}
          width="100%"
          dividerColor={dividerColor || "#999999"}
        />
        <LeftRightRow>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color={theme.darkText}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.billing.discount_with_colon_2")}
          </PrestoText>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color={mainColor || theme.secondary}
            extraStyle={{ marginTop: 2 }}
          >
            {currencyMethod({
              cents: order?.aggregate_discounts || 0,
              showDecimal: true,
            })}
          </PrestoText>
        </LeftRightRow>
        <LineDivider
          lineHeight={1}
          width="100%"
          dividerColor={dividerColor || "#999999"}
        />

        <LeftRightRow>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color={theme.darkText}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.billing.gst_cess_with_colon")}
          </PrestoText>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color={mainColor || theme.secondary}
            extraStyle={{ marginTop: 2 }}
          >
            {currencyMethod({
              cents: order?.total_tax || order?.total_taxes || 0,
              showDecimal: true,
            })}
          </PrestoText>
        </LeftRightRow>
        <LineDivider
          lineHeight={1}
          width="100%"
          dividerColor={dividerColor || "#999999"}
        />
        <LeftRightRow>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color={theme.darkText}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.billing.total_savings_with_colon")}
          </PrestoText>
          <SavingsText savingsColor={savingsColor}>
            {currencyMethod({
              cents: savings || 0,
              showDecimal: true,
            })}
          </SavingsText>
        </LeftRightRow>
        <LineDivider
          lineHeight={1}
          width="100%"
          dividerColor={dividerColor || "#999999"}
        />
        <LeftRightRow>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color={theme.darkText}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.billing.delivery_charges_colon")}
          </PrestoText>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color={mainColor || theme.secondary}
            extraStyle={{ marginTop: 2 }}
          >
            {currencyMethod({
              cents: order?.charges[0]?.value || 0,
              showDecimal: true,
            })}
          </PrestoText>
        </LeftRightRow>
        <LineDivider
          lineHeight={1}
          width="100%"
          dividerColor={dividerColor || "#999999"}
        />
        <LeftRightRow>
          <PrestoText
            fontStyle="500.medium"
            size={14}
            color={theme.darkText}
            extraStyle={{ marginTop: 2 }}
          >
            {I18n.t("screen_messages.total_amount_text")}
          </PrestoText>
          <PrestoText
            fontStyle="400.normal"
            size={14}
            color={mainColor || theme.secondary}
            extraStyle={{ marginTop: 2 }}
          >
            {currencyMethod({
              cents: order?.total_price || 0,
              showDecimal: true,
            })}
          </PrestoText>
        </LeftRightRow>
      </View>
    </View>
  );
}
