import * as React from "react";
import Svg, {
  Defs,
  Path,
  LinearGradient,
  Stop,
  G,
  Mask,
  Use,
} from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
        <LinearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="c">
          <Stop stopColor={props.color2 || "#00C54F"} offset="0%" />
          <Stop stopColor={props.color || "#007BFF"} offset="96.6%" />
        </LinearGradient>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(5 6)">
          <Mask id="b" fill={props.color3 || "#fff"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <Path d="M0 0H29V29H0z" />
          </G>
        </G>
        <Path
          d="M18.712 7.335C17.555 2.972 13.895.131 9.636.011V0c-.046 0-.09.005-.136.006C9.454.006 9.409 0 9.363 0v.011C5.105.131 1.444 2.971.288 7.335c-.576 2.175-.25 4.273.643 6.308.903 2.061 2.178 3.887 3.454 5.714 2.066 2.957 3.702 6.125 4.62 9.646.137.52.225 1.052.344 1.576.003.015.01.03.015.045V31l.136-.006.137.006v-.376l.014-.045c.12-.524.208-1.056.344-1.576.918-3.521 2.554-6.689 4.62-9.646 1.276-1.826 2.55-3.653 3.454-5.714.894-2.035 1.219-4.132.643-6.308zM9.5 11.386c-1.335 0-2.417-1.07-2.417-2.39s1.082-2.39 2.417-2.39c1.335 0 2.417 1.07 2.417 2.39s-1.082 2.39-2.417 2.39z"
          transform="translate(10 5)"
          fill="url(#c)"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
