import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={40} height={32} viewBox="0 0 40 32" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M39.788 9.975a.75.75 0 01-.007 1.05l-8.831 8.762a.742.742 0 01-1.05-.006l-5.106-5.144a.742.742 0 01.006-1.05l1.756-1.743a.741.741 0 011.05.006l2.844 2.862 6.55-6.5a.742.742 0 011.05.007l1.738 1.756z"
        />
        <Path d="M14 16a8 8 0 100-16 8 8 0 000 16zm5.6 2h-1.044a10.88 10.88 0 01-9.112 0H8.4A8.402 8.402 0 000 26.4V29a3 3 0 003 3h22a3 3 0 003-3v-2.6a8.402 8.402 0 00-8.4-8.4z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h40v32H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
