import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const ServiceItemObject = function (Utils, ErrorConstants) {
  function ServiceItemObject() {
    this.category_id = "";
    this.description = "";
    this.duration = 0;
    this.id = "";
    this.is_available = true;
    this.name = "";
    this.other_data = {};
    this.pic_url = "";
    this.price = {};
    this.reference_id = "";
    this.summary = "";
    this.seo_handle = "";
    this.seo_keywords = "";
    this.seo_title = "";
    this.seo_description = "";
    this.likes = "";
    this.dislikes = "";
    this.service_id = "";
    this.outlet_id = "";
    this.documents = "";
  }

  ServiceItemObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawServiceItem) {
      this.category_id = rawServiceItem.category_id;
      this.description = rawServiceItem.description;
      this.duration = rawServiceItem.duration;
      this.id = rawServiceItem.id;
      this.service_id = rawServiceItem.id;
      this.is_available = rawServiceItem.is_available;
      this.name = rawServiceItem.name;
      this.other_data = rawServiceItem.other_data;
      this.pic_url = rawServiceItem.pic_url;
      this.price = rawServiceItem.price;
      if (!this.price) {
        this.price = {
          base_price: 0.0,
          shipping_charge: 0.0,
          packing_charge: 0.0,
          tax: 0.0,
          total_price: rawServiceItem.unit_price / 100,
          service_tax: 0.0,
          vat_amount: 0.0,
          total_tax_percentage: "0.0",
          service_tax_percentage: "0.0",
          vat_percentage: "0.0",
          currency: "MVR",
          tax_percentage: "0.0",
        };
      }
      this.reference_id = rawServiceItem.reference_id;
      this.summary = rawServiceItem.summary;
      this.seo_handle = rawServiceItem.seo_handle;
      this.seo_keywords = rawServiceItem.seo_keywords;
      this.seo_title = rawServiceItem.seo_title;
      this.seo_description = rawServiceItem.seo_description;
      this.likes = rawServiceItem.likes;
      this.dislikes = rawServiceItem.dislikes;
      this.outlet_id = rawServiceItem.outlet_id;
      this.documents = rawServiceItem.documents;
    },
  };

  return ServiceItemObject;
};

export default ServiceItemObject(Utils, ErrorConstants);
