import React from "react";
import { View } from "react-native";
import PlusMinusButton from "@presto-components/PlusMinusButtons/PlusMinusButton";
import { DotIndicator } from "react-native-indicators";
import BaseItemCard from "@presto-cards/ItemCards/BaseItemCard";

export default function ItemCard(props) {
  const {
    onAdd,
    onSubtract,
    updating,
    count,
    onUpdate,
    variation,
    textColor,
    color,
    promotionText,
    success,
  } = props;

  const Updating = () => {
    return (
      <View
        style={{
          alignItems: "center",
          height: 40,
        }}
      >
        <DotIndicator
          color={color || "#FD5B53"}
          maxScale={1.2}
          minScale={0.2}
          size={8}
        />
      </View>
    );
  };
  const PlusMinus = () => {
    return (
      <PlusMinusButton
        count={count}
        onPressPlus={onAdd}
        onPressMinus={onSubtract}
        onUpdate={onUpdate}
        variation={variation}
        mainColor={color}
        textColor={textColor}
      />
    );
  };
  console.log("promotionText", promotionText);
  return (
    <BaseItemCard
      {...props}
      right={updating ? <Updating /> : <PlusMinus />}
      itemPromotionText={promotionText}
      success={success}
    />
  );
}
