import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.width || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle stroke="#000" strokeWidth={2} cx={20.5} cy={20.5} r={15.5} />
        <Path
          d="M28.036 16.757c0 .383-1.252 2.11-2.018 2.11-.766 0-2.018-1.692-2.018-2.11 0-1.074.673-1.209 2.018-.406 1.346-.79 2.018-.655 2.018.406z"
          fill="#000"
          fillRule="nonzero"
        />
        <Path
          d="M12 24.473c2.691 2.596 5.524 3.894 8.5 3.894s5.601-1.298 7.876-3.894"
          stroke="#000"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
        <Path
          d="M16.036 16.757c0 .383-1.252 2.11-2.018 2.11-.766 0-2.018-1.692-2.018-2.11 0-1.074.673-1.209 2.018-.406 1.346-.79 2.018-.655 2.018.406z"
          fill="#000"
          fillRule="nonzero"
        />
        <Path
          d="M18.22 5.164l6.63 2.435a1 1 0 001.345-.895c.104-2.393-.971-3.883-3.226-4.47-2.152-.56-3.87-.117-5.153 1.327a1 1 0 00.403 1.603z"
          stroke="#000"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
