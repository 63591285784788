import * as React from "react"
import Svg, { G, Path, Ellipse } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="31px"
      height="23px"
      viewBox="0 0 31 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-316 -566) translate(109 210) translate(181 341) translate(27 16)"
        stroke="#686868"
        strokeWidth={1.2}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M0 0h16.417c.954 0 1.728.77 1.728 1.722v16.354h-6.83m-7.033 0H1.728c-.954 0-1.728-.77-1.728-1.722V11.33"
          strokeLinecap="round"
        />
        <Ellipse
          cx={7.77631579}
          cy={18.0759494}
          rx={2.85614035}
          ry={2.84303797}
        />
        <Ellipse
          cx={23.3289474}
          cy={18.0759494}
          rx={2.85614035}
          ry={2.84303797}
        />
        <Path
          d="M17.71 4.175h6.366c2.137 1.981 3.7 4.047 4.69 6.197v5.426c0 .951-.774 1.722-1.728 1.722h-.556m-6.177 0h-2.42"
          strokeLinejoin="round"
        />
        <Path d="M21.65 4.175v4.981a2 2 0 002 2h5.167" />
        <Path strokeLinecap="round" d="M0 2.40679655L10.5418856 2.40679655" />
        <Path strokeLinecap="round" d="M0 4.55869528L8.64035088 4.55869528" />
        <Path strokeLinecap="round" d="M0 6.71059402L6.9122807 6.71059402" />
        <Path strokeLinecap="round" d="M0 8.86249275L5.18421053 8.86249275" />
      </G>
    </Svg>
  )
}

export default SvgComponent
