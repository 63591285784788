import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  ScrollView,
  Platform,
} from "react-native";
import PrestoAmountInput from "@presto-components/AmountInputs/PrestoAmountInput";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import HorizontalButtonList from "@presto-components/HorizontalButtonList";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import UserContext from "@presto-contexts/UserContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import useFocusEffect from "@presto-common/useFocusEffect";
import I18n from "i18n-js";
import utils from "../../utils";
import { prestoAlert } from "../../common/Alert";

export default function TopupScreen(props) {
  const [loading, setLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyles(theme);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  let defaultOptions = [
    {
      name: `${I18n.t("screen_messages.common.currency")} 100`,
      value: 100,
      id: 1,
    },
    {
      name: `${I18n.t("screen_messages.common.currency")} 200`,
      value: 200,
      id: 2,
    },
    {
      name: `${I18n.t("screen_messages.common.currency")} 500`,
      value: 500,
      id: 3,
    },
    {
      name: `${I18n.t("screen_messages.common.currency")} 1000`,
      value: 1000,
      id: 4,
    },
  ];
  const [selectedOption, setOptions] = useState(defaultOptions[0]);
  const [wallet, setWallet] = useState();
  const [amount, setAmount] = useState(defaultOptions[0].value);

  useFocusEffect(
    useCallback(() => {
      getWalletInfo();
    }, [user])
  );
  const getWalletInfo = () => {
    WalletManager.getWalletInfo(
      { walletId: user.wallet_id },
      (response) => {
        setWallet(response.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(
          "WalletManager.getWalletInfo ERROR: ",
          JSON.stringify(error)
        );
      }
    );
  };
  const renderButtons = (item) => {
    let selected = false;
    if (selectedOption.id == item.id) {
      selected = true;
    }

    return (
      <PrestoSolidButton
        size={"large"}
        buttonStyle="secondary"
        width={90}
        title={item.name}
        titleColor={theme.white}
        bgColor={selected ? theme.secondary : theme.paragraph}
        onPress={() => {
          onSelectOption(item);
        }}
      />
    );
  };
  const onSelectOption = (item) => {
    setOptions(item);
    setAmount(item.value);
  };
  const rechargeAmountChange = (text) => {
    if (text === "" || !text) {
      setAmount("");
      setOptions({});
    } else {
      setAmount(text);
      setOptions({});
    }
  };
  useEffect(() => {
    setOptions(defaultOptions[0]);
    setAmount(defaultOptions[0].value);
  }, []);

  const createTopupOrder = () => {
    const params = {
      wallet_id: wallet?.wallet_id,
      amount: Math.round(amount * 100),
      source: Platform.OS,
    };
    setLoading(true);
    WalletManager.onWalletRechargeWithoutPin(
      params,
      (response) => {
        console.log("Response==>", response);
        setLoading(false);
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "PAYMENTS",
            screenParams: {
              orderId: response.order_id,
              rechargeAmount: amount * 100,
              wallet: props.route.params?.wallet,
              fromCheckout: props.route.params?.fromCheckout,
              showCart: props.route.params?.showCart,
            },
          },
        });
      },
      (error) => {
        setLoading(false);
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error) || "Please try again later"
        );
        console.log("error =  ", JSON.stringify(error));
      }
    );
  };

  const rechargerFor = () => {
    if (amount <= 0) {
      return;
    } else {
      createTopupOrder();
    }
  };
  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  return (
    <LoadingContainer bgColor="transparent" loading={loading}>
      <View style={[styles.container, { padding: 0 }]}>
        <ScrollView style={styles.container}>
          <View>
            <View
              style={{
                paddingBottom: 15,
                paddingHorizontal: theme.primaryPadding,
              }}
            >
              <VerticalSpacing size={20} />
              <SectionHeader
                fontSize={18}
                leftText={`${I18n.t("screen_messages.topups.title")}`}
              />
              <VerticalSpacing size={20} />
              <PrestoText fontStyle="500.medium" color={theme.darkText}>
                {I18n.t("screen_messages.topups.subtitle")}
              </PrestoText>
              <VerticalSpacing size={20} />
              <PrestoText
                fontStyle="400.normal"
                color="#999999"
                extraStyle={styles.summaryStyle}
              >
                {I18n.t("screen_messages.topups.description")}
              </PrestoText>
              <VerticalSpacing size={30} />
              <SectionHeader
                fontSize={16}
                leftText={`${I18n.t("screen_messages.wallet.wallet_balance")} ${
                  wallet
                    ? utils.formattedCurrencyCents(
                        wallet.current_balance - wallet.expiry_balance
                      )
                    : 0
                }`}
              />
              <VerticalSpacing size={20} />
              <HorizontalButtonList
                renderButton={renderButtons}
                items={defaultOptions}
                spacing={10}
              />
              <VerticalSpacing size={20} />
              <View
                style={{
                  paddingBottom: 5,
                }}
              >
                <PrestoAmountInput
                  value={amount ? `${amount}` : ""}
                  clearTextOnFocus={false}
                  onAmountChange={rechargeAmountChange}
                  placeholder="₹ 100"
                  keyboardType="numeric"
                  width="100%"
                  labelText={I18n.t(
                    "screen_messages.topups.enter_custom_amount"
                  )}
                  defaultValue=""
                ></PrestoAmountInput>
              </View>
            </View>
          </View>
          <View style={{ padding: 15, backgroundColor: "transparent" }}>
            <FlexRowSpaceAligned>
              <View
                style={{
                  width: "50%",
                  paddingRight: 10,
                }}
              >
                <PrestoSolidButton
                  size="extra-large"
                  buttonStyle="secondary"
                  title={I18n.t("screen_messages.common.cancel_text")}
                  bgColor="#CCCCCC"
                  titleColor={theme.white}
                  onPress={() => {
                    goBack();
                  }}
                />
              </View>
              <View
                style={{
                  width: "50%",
                }}
              >
                <PrestoSolidButton
                  size="extra-large"
                  buttonStyle="primary"
                  title={
                    isDesktop
                      ? I18n.t("screen_messages.topups.topup")
                      : I18n.t("screen_messages.topups.add_topup")
                  }
                  titleColor={theme.white}
                  onPress={() => {
                    rechargerFor();
                  }}
                />
              </View>
            </FlexRowSpaceAligned>
          </View>
        </ScrollView>
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {
      backgroundColor: theme.appBackgroundColor,
      paddingBottom: 100,
      height: "100%",
      flex: 1,
    },
    summaryStyle: {
      letterSpacing: 0.4,
    },
  };
}
