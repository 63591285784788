import React, { useState, useContext, useCallback } from "react";
import {
  View,
  FlatList,
  TouchableWithoutFeedback,
  ImageBackground,
} from "react-native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { Dimensions } from "react-native";
import BannersSlide from "@presto-components/BannersSlide";
import _ from "lodash";

export default function CarouselGallery({
  navigation,
  title,
  name,
  slidesToShow,
  borderRadius,
  width,
  height,
  onItemClicked,
  fontStyle,
  fontSize,
  bgColor,
  carouselHeight,
  setItemCount,
}) {
  const { theme } = useContext(ThemeContext);
  const [gallery, setGallery] = useState([]);
  const windowWidth = Dimensions.get("window").width;
  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response?.data?.media_items);
    if (_.isFunction(setItemCount)) {
      let len = response?.data?.media_items?.length || 0;
      setItemCount(len);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getGallery(name);
    }, [])
  );

  let isDesktop = windowWidth > 940 ? true : false;
  const renderItem = ({ item, index }) => {
    return (
      <TouchableWithoutFeedback
        onPress={() => onItemClicked && onItemClicked(item)}
      >
        <ImageBackground
          style={{
            width: 300,
            height: 150,
            borderRadius: 8,
            overflow: "hidden",
            marginLeft: theme.containerPadding,
            ...theme.darkShadow,
          }}
          source={{ uri: item.file_url }}
          resizeMode="cover"
        />
      </TouchableWithoutFeedback>
    );
  };

  if (_.isEmpty(gallery)) {
    return null;
  }

  if (isDesktop) {
    return (
      <BannersSlide
        banners={gallery}
        height={height || 350}
        width={width || 400}
        slidesToShow={slidesToShow}
        borderRadius={borderRadius || 0}
        onClickBanner={(item) => onItemClicked && onItemClicked(item)}
      />
    );
  }
  return (
    <View
      style={{
        paddingVertical: theme.containerPadding,
        flexGrow: 0,
        backgroundColor: bgColor || undefined,
      }}
    >
      <View
        style={{
          paddingHorizontal: theme.containerPadding,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <SectionHeader fontStyle={fontStyle} fontSize={fontSize}>
          {title}
        </SectionHeader>
      </View>
      <FlatList
        horizontal
        keyExtractor={(_, index) => "index-" + index}
        data={gallery}
        renderItem={renderItem}
        swipeThreshold={10}
        layout={"default"}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          paddingRight: theme.containerPadding,
          height: carouselHeight || 165,
        }}
      />
    </View>
  );
}
