import $http from "../../global/http";
import NotificationConstants from "./NotificationConstants";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const NotificationResource = function (
  $http,
  NotificationConstants,
  PrestoSdk
) {
  function getNotifications(params) {
    var url =
      PrestoSdk.getHostName() +
      NotificationConstants.GET_NOTIFICATIONS.PATH +
      "/" +
      params.deviceId +
      "/notifications.json";
    return $http.get(url);
  }

  return {
    getNotifications: getNotifications,
  };
};

export default NotificationResource($http, NotificationConstants, PrestoSdk);
