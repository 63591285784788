import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="24px"
      height="29px"
      viewBox="0 0 24 29"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        transform="translate(-19 -128)"
        stroke="#FFF"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
        d="M20.0956938 129L20.0956938 156 41.1961722 142.5z"
      />
    </Svg>
  )
}

export default SvgComponent
