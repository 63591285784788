import React, { useContext } from "react";
import { View, StyleSheet, Dimensions, Platform } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import SectionContainer from "@presto-components/Containers/SectionContainer";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";
import ThemeContext from "@presto-contexts/ThemeContext";

function SectionHeaderWithRightLink(props) {
  const {
    defaultColor,
    rightIcon,
    rightText,
    leftText,
    onPressRightText,
    fontSize,
    fontWeight,
    rightTextStyle,
  } = props;
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 941 ? true : false;
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme, defaultColor, Platform.OS);
  return (
    <SectionContainer>
      <FlexRowSpaceAligned styleSet={{ marginBottom: 10 }}>
        <PrestoText
          fontStyle={
            props.leftTextFontStyle
              ? props.leftTextFontStyle
              : isDesktop
              ? "900.bold"
              : "700.bold"
          }
          color={theme.darkText}
          size={fontSize ? fontSize : 16}
        >
          {leftText}
        </PrestoText>
        {rightText || rightIcon ? (
          <TouchableWithoutFeedback onPress={onPressRightText}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {rightText ? (
                <PrestoText
                  fontStyle={props.rightTextFontStyle || "400.normal"}
                  color={theme.secondary}
                  size={isDesktop ? 16 : 14}
                  extraStyle={[styles.rightTextStyle, rightTextStyle]}
                >
                  {rightText}
                </PrestoText>
              ) : null}

              {rightIcon ? (
                <PrestoIcon
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlignVertical: "center",
                    paddingTop: 2,
                  }}
                  icon={rightIcon}
                />
              ) : null}
            </View>
          </TouchableWithoutFeedback>
        ) : null}
      </FlexRowSpaceAligned>
    </SectionContainer>
  );
}

export default SectionHeaderWithRightLink;

function getStyles(theme, defaultColor, os) {
  let rightTextStyle = {
    fontWeight: "400",
    fontSize: 14,
    color: defaultColor ? theme.darkText : theme.secondary,
    textAlignVertical: "center",
    lineHeight: 19,
    paddingRight: 5,
    letterSpacing: 0.4,
  };
  if (os == "web") {
    rightTextStyle.cursor = "pointer";
  }
  return {
    rightTextStyle: rightTextStyle,
  };
}
