import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  const fill = props.fill || "#000"
  return (
    <Svg
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={fill} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path d="M187.744 67.822c-11.906.386-19.36-14.596-11.904-23.884 3.584-4.775 5.48-10.475 5.48-16.473 0-15.822-13.434-28.57-29.482-27.39-14.38 1.059-25.618 13.479-25.347 27.923.109 5.803 1.997 11.307 5.475 15.94 7.442 9.285.005 24.272-11.904 23.885H82.584c-8.225 0-14.892 6.68-14.892 14.92v37.553c.395 11.91-14.556 19.392-23.838 11.927a27.097 27.097 0 00-15.91-5.486c-14.416-.272-26.812 10.989-27.868 25.396-1.178 16.078 11.545 29.539 27.336 29.539 5.987 0 11.676-1.9 16.44-5.491 9.296-7.482 24.229.025 23.839 11.927v53.468h173.418v-36.16c0-8.564-7.2-15.557-15.727-14.89-19.496 1.645-36.953-14.038-37.43-33.643-.693-20.631 16.963-37.366 37.35-35.731 8.559.656 15.807-6.287 15.807-14.881V82.744c0-8.241-6.668-14.921-14.892-14.921h-38.473v-.001zM184.796 358.798c-.144-30.26 26.237-54.586 56.313-51.956v-35.423H67.692v53.468c.39 11.904-14.544 19.408-23.838 11.927a27.095 27.095 0 00-15.872-5.485C13.568 331.038 1.16 342.285.08 356.688c-1.207 16.088 11.526 29.575 27.333 29.575 5.987 0 11.676-1.9 16.44-5.491 9.283-7.473 24.232.02 23.839 11.927v36.557c0 8.24 6.668 14.92 14.892 14.92h158.525v-33.423c-30.08 2.599-56.455-21.693-56.313-51.955z" />
        <Path d="M484.019 331.328a27.104 27.104 0 00-15.872 5.485c-9.297 7.475-24.23-.024-23.838-11.927V286.34c0-8.241-6.668-14.921-14.892-14.921h-22.082c-8.578 0-15.458 7.252-14.853 15.826 1.468 20.013-14.592 37.329-34.64 37.244-19.878.157-36.248-17.528-34.62-37.373.625-8.525-6.354-15.697-14.892-15.697h-37.438v55.497c.352 11.252-13.17 18.884-22.597 12.773-3.435-2.09-7.406-3.183-11.477-3.183-12.695 0-22.942 10.717-22.201 23.605.578 10.055 7.992 18.655 17.864 20.555 5.532 1.064 11.164.06 15.814-2.768 9.43-6.108 22.945 1.522 22.597 12.772v53.508h53.364c11.905-.386 19.36 14.596 11.904 23.884-3.584 4.775-5.48 10.475-5.48 16.473 0 15.822 13.434 28.57 29.482 27.389 14.38-1.058 25.618-13.478 25.347-27.922-.11-5.803-1.997-11.307-5.475-15.941-7.442-9.284-.005-24.271 11.904-23.884h37.479c8.225 0 14.892-6.681 14.892-14.921v-36.557c-.391-11.91 14.55-19.4 23.838-11.927 4.766 3.59 10.454 5.49 16.44 5.49 15.808 0 28.54-13.486 27.334-29.574-1.08-14.404-13.488-25.651-27.902-25.36zM497 88.55h-15.06C480 78.99 476.21 70.09 471 62.22l10.67-10.68c5.86-5.86 5.86-15.35 0-21.21-5.86-5.86-15.35-5.86-21.21 0L449.78 41c-7.87-5.21-16.77-9-26.33-10.95V15c0-8.28-6.71-15-15-15-8.28 0-15 6.72-15 15v15.05C383.89 32 374.99 35.79 367.12 41l-10.67-10.67c-5.86-5.86-15.36-5.86-21.22 0-5.86 5.86-5.86 15.35 0 21.21l10.68 10.68c-5.22 7.87-9 16.77-10.95 26.33H319.9c-8.28 0-15 6.71-15 15 0 8.28 6.72 15 15 15h15.06c1.95 9.56 5.73 18.45 10.95 26.32l-10.68 10.67c-5.86 5.86-5.86 15.36 0 21.22 2.93 2.93 6.77 4.39 10.61 4.39s7.68-1.46 10.61-4.39l10.67-10.68c7.87 5.22 16.77 9.01 26.33 10.96v15.04c0 8.29 6.72 15 15 15 8.29 0 15-6.71 15-15v-15.04c9.57-1.95 18.47-5.74 26.34-10.96l10.67 10.68c2.93 2.93 6.77 4.39 10.61 4.39 3.83 0 7.67-1.46 10.6-4.39 5.86-5.86 5.86-15.36 0-21.22L471 144.87c5.21-7.87 8.99-16.76 10.94-26.32H497c8.28 0 15-6.72 15-15 0-8.29-6.72-15-15-15zm-88.55-.01c8.28 0 15 6.72 15 15 0 8.29-6.72 15-15 15-8.28 0-15-6.71-15-15 0-8.28 6.72-15 15-15z" />
      </G>
    </Svg>
  )
}

export default SvgComponent
