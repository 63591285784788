import * as React from "react";
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg";
function SvgComponent({
  width = "40px",
  height = "41px",
  stopOpacity1 = 0.238724288,
  stopOpacity2 = 0.709619482,
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Defs>
        <LinearGradient
          x1="16.1962392%"
          y1="34.4749467%"
          x2="77.0368355%"
          y2="77.2702536%"
          id="a"
        >
          <Stop stopColor="#FFF" stopOpacity={stopOpacity1} offset="0%" />
          <Stop stopColor="#FFF" stopOpacity={stopOpacity2} offset="100%" />
        </LinearGradient>
      </Defs>
      <G
        transform="translate(1)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          strokeOpacity={0.7}
          stroke="#FFF"
          strokeWidth={2}
          fillOpacity={0.7}
          fill="#FFF"
          strokeLinecap="round"
          d="M0 39.4259259L38 39.4259259"
        />
        <Path
          d="M18.792 0a5.125 5.125 0 011.992 9.848l5.397 8.623a1 1 0 001.556.176l2.855-2.864a1 1 0 011.708.706v6.207a1 1 0 001.575.818l2.55-1.791A1 1 0 0138 22.54v8.015a1 1 0 01-1 1H1a1 1 0 01-1-1V22.56a1 1 0 011.581-.814l2.538 1.811a1 1 0 001.581-.813v-6.227a1 1 0 011.714-.7l2.766 2.82a1 1 0 001.558-.165l5.383-8.5A5.127 5.127 0 0118.79 0z"
          fill="url(#a)"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
}
export default SvgComponent;