import _ from "lodash";
import StylistManager from "@presto-services/features/pos_stylist/StylistManager";
import AdminManager from "@presto-services/features/admin/AdminManager";
import LoginHelper from "@presto-helpers/LoginHelper";

function stylistsBasedOnUserType(
  params = {},
  onSuccessCallback,
  onErrorCallback
) {
  const isAgent = LoginHelper.isUserRoleEmployee();
  const isAdmin = LoginHelper.isUserRoleAdmin();

  if (isAgent) {
    StylistManager.getAllStylists(
      (params = {}),
      onSuccessCallback,
      onErrorCallback
    );
  }

  if (isAdmin) {
    AdminManager.getAllStylists(
      (params = {}),
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default {
  stylistsBasedOnUserType,
};
