import React, { useContext } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import HorizontalButtonList from "@presto-components/HorizontalMultiRowButtonList/HorizontalMultiRowButtonList";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import Category from "@presto-cards/Category/Category";
import LoadingContainer from "@presto-components/LoadingContainer";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import CategoryModel from "@presto-component-models/CategoryModel";
import { Components } from "presto-react-components";

const MultiRowGrid1 = Components.MultiRowGrids.MultiRowGrid1;

export default function Browse({ navigation }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const catalog = useCatalog((state) => state.catalog);
  const windowWidth = Dimensions.get("screen").width;
  const categories = _.values(catalog?.categories) || [];

  const goToCategory = (categoryId, subCategoryId) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "BROWSE_CATEGORIES",
        screenParams: {
          categoryId,
          subCategoryId,
        },
      },
    });
  };

  const renderSubCategoryButtons = (category, item) => {
    return (
      <Category
        category={new CategoryModel({ category: item })}
        theme={theme}
        onPressItem={() => {
          goToCategory(category.id, item.id);
        }}
      />
    );
  };

  const renderCategoryButtons = (category, item) => {
    let itemObj = _.isObject(item) ? item : category;
    return (
      <Category
        category={new CategoryModel({ category: itemObj })}
        theme={theme}
        onPressItem={() => {
          goToCategory(category.id || itemObj.id, itemObj.id);
        }}
      />
    );
  };

  const RenderCategories = () => {
    return (
      <>
        <VerticalSpacing size={10} />
        {_.map(categories, (toplevel) => {
          if ((toplevel.categories?.length || 0) > 0) {
            return (
              <>
                <View style={{ paddingLeft: theme.primaryPadding }}>
                  <PrestoText
                    fontStyle={"medium"}
                    color={theme.darkText}
                    size={16}
                  >
                    {toplevel.name}
                  </PrestoText>
                </View>

                <View>
                  <MultiRowGrid1
                    items={toplevel.categories}
                    columns={3}
                    renderItem={({ item }) => {
                      return (
                        <View
                          key={item.id}
                          style={{
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {renderCategoryButtons(toplevel, item)}
                        </View>
                      );
                    }}
                  />
                </View>
                <VerticalSpacing size={30} />
              </>
            );
          } else {
            return (
              <>
                <View style={{ paddingLeft: theme.primaryPadding }}>
                  <PrestoText
                    fontStyle={"medium"}
                    color={theme.darkText}
                    size={16}
                  >
                    {toplevel.name}
                  </PrestoText>
                </View>
                <HorizontalButtonList
                  key={toplevel.id}
                  style={{
                    paddingLeft: 10,
                  }}
                  renderButton={renderCategoryButtons}
                  items={[toplevel]}
                  spacing={5}
                />
                <VerticalSpacing size={30} />
              </>
            );
          }
        })}
      </>
    );
  };

  return categories ? (
    <LoadingContainer loading={false}>
      <View style={{ flex: 1, paddingTop: 30 }}>
        <ScrollView style={{ width: "100%", height: "100%" }}>
          <View style={{ paddingLeft: theme.primaryPadding }}>
            <SectionHeader
              leftText={I18n.t("screen_messages.category.shop_by_category")}
            />
          </View>
          {categories && categories.length > 0 ? (
            <>
              <VerticalSpacing size={30} />
              <RenderCategories />
            </>
          ) : null}
        </ScrollView>
      </View>
    </LoadingContainer>
  ) : null;
}
