import * as React from "react";
import Svg, { Path } from "react-native-svg";

function StaffIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M0 19.804V1.784C.004 1.288.207.814.564.47A1.783 1.783 0 011.784 0H12.2c.277-.007.546.094.75.281.26.146.459.378.564.656l.939 2.629h12.67c.495.004.968.207 1.313.563.37.333.576.811.564 1.309V19.89c-.004.496-.207.969-.564 1.314a1.704 1.704 0 01-1.314.564H1.784a1.852 1.852 0 01-1.315-.562A2.538 2.538 0 010 19.804z"
        transform="translate(5 8.625)"
        fillRule="nonzero"
        stroke={props.color || "#707070"}
        strokeWidth={1}
        fill="none"
      />
    </Svg>
  );
}

export default StaffIcon;
