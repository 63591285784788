import * as React from "react"
import Svg, {
  Defs,
  Path,
  Mask,
  Use,
  G,
  Circle,
  Text,
  TSpan
} from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path
          d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
          id="a"
        />
        <Mask
          id="b"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x={0}
          y={0}
          width={20}
          height={20}
          fill="#fff"
        >
          <Use xlinkHref="#a" />
        </Mask>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle stroke="#707070" fill="#707070" cx={20} cy={19.5} r={14} />
        <G transform="translate(10 10)">
          <Use
            stroke="#FFF"
            mask="url(#b)"
            strokeWidth={2.176}
            strokeLinejoin="round"
            strokeDasharray="56,67.2"
            transform="matrix(1 0 0 -1 0 20)"
            xlinkHref="#a"
          />
          <Circle cx={9.86666667} cy={10} r={8} />
        </G>
        <Text
          fontFamily="Helvetica"
          fontSize={12}
          fontWeight="normal"
          fill="#FFF"
        >
          <TSpan x={16} y={23.5}>
            {"\u20B9"}
          </TSpan>
        </Text>
        <Path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.7146683 13.4952736L14.7271347 12 13.4773064 15.8399315"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
