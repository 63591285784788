import * as React from "react";
import Svg, { G, Circle, Text, TSpan } from "react-native-svg";

function ActionNeededIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke="#FAA85D"
          strokeWidth={0.9}
          fill="#FAA85D"
          cx={20}
          cy={20}
          r={17.55}
        />
        <Text fontSize={35} fontWeight="normal" fill="#FFF">
          <TSpan x={15} y={32}>
            {"!"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default ActionNeededIcon;
