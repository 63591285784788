import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function VegIcon(props) {
  return (
    <Svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      style={{
        background: "#fff",
      }}
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M0 0H10V10H0z" />
        <G stroke="#05AD00">
          <Path fill="#FFF" d="M0.5 0.5H9.5V9.5H0.5z" />
          <Circle fill="#05AD00" cx={5} cy={5} r={2.27272727} />
        </G>
      </G>
    </Svg>
  );
}

export default VegIcon;
