import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent({width=40, height=40, color="#E21111"}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        transform="translate(6 8)"
        stroke={color}
        strokeWidth={2.592}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M3.0831758 0.403225806L27.3427851 24.5967742" />
        <Path d="M20.246 11.663a14.48 14.48 0 013.017 1.966M4.737 13.629a14.494 14.494 0 016.841-3.154M12.293 3.732A21.209 21.209 0 0128 8.944M0 8.944a21.063 21.063 0 016.22-3.8M9.408 18.327a7.957 7.957 0 019.197 0" />
        <Path d="M14 23.4604106L14.0132325 23.4604106" />
      </G>
    </Svg>
  )
}

export default SvgComponent

