import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent({ width = 40, height = 40, color = "#333333" }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="6 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <G
        id="icon/black"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="icon/black/user">
          <Path
            d="M25.181804,17.5899242 C25.5225736,18.2626663 25.8811899,18.8952579 26.2575,19.4289062 L26.2575,19.4289062 L26.2575,20.442 C26.2575,23.895 23.4505,26.704 19.9995,26.704 C16.7722665,26.704 14.1075731,24.2511854 13.7729195,21.1106067 C14.1143857,21.3434649 14.5793357,21.3463533 14.9296552,21.0856851 L14.8599189,21.1308173 C15.1974253,23.6667698 17.3745631,25.629 20.0005,25.629 C22.8585,25.629 25.1825,23.302 25.1825,20.443 L25.1825,20.443 Z"
            id="Combined-Shape"
            fill={color}
          ></Path>
          <Path
            d="M26.847826,25.7373268 C27.227292,25.7082743 27.6195671,25.8227529 27.9368577,26.0912638 L27.9368577,26.0912638 L29.5608945,27.4656241 C29.8712917,27.7283014 30.058777,28.1012136 30.1028755,28.4889555 C30.14611,28.8691002 30.0519392,29.2603902 29.8135073,29.5763757 C29.8089968,29.5835355 29.8035392,29.5921167 29.7971272,29.6020631 L29.7471406,29.6776743 C29.2341343,30.4367966 26.2973496,34.328 19.9995589,34.328 C13.2832242,34.328 10.3809544,29.8925127 10.1728357,29.5601299 C9.94261932,29.2411098 9.85199435,28.8517276 9.89749425,28.4749938 C9.94329205,28.0957933 10.1268507,27.7310098 10.4379115,27.4672951 L10.4379115,27.4672951 L12.0601281,26.0919934 C12.3737992,25.8260657 12.7623021,25.7132024 13.1412684,25.7421048 C13.5206396,25.7710381 13.8905353,25.9428993 14.1634336,26.2465455 C14.1983935,26.2901123 16.303768,29.2321971 19.9995589,29.2321971 C23.6951528,29.2321971 25.7984579,26.2902949 25.7984579,26.2902949 C26.0947088,25.9421814 26.464969,25.7666389 26.847826,25.7373268 Z"
            id="Path"
            stroke={color}
          ></Path>
          <Path
            d="M22.5035,16.3 C22.7489599,16.3 22.9531084,16.4768752 22.9954443,16.7101244 L23.0035,16.8 L23.0035,17.6 C23.0035,17.8761424 22.7796424,18.1 22.5035,18.1 C22.2580401,18.1 22.0538916,17.9231248 22.0115557,17.6898756 L22.0035,17.6 L22.0035,16.8 C22.0035,16.5238576 22.2273576,16.3 22.5035,16.3 Z"
            id="Line-Copy-3"
            fill={color}
            fillRule="nonzero"
            transform="translate(22.503500, 17.200000) rotate(-270.000000) translate(-22.503500, -17.200000) "
          ></Path>
          <Path
            d="M22.6090207,20.8 C22.8851631,20.8 23.1090207,21.0238576 23.1090207,21.3 C23.1090207,24.0287664 19.6952771,25.1821319 17.6415285,23.026916 C17.451029,22.8270047 17.4586588,22.5105142 17.6585701,22.3200146 C17.8584814,22.1295151 18.1749719,22.1371449 18.3654715,22.3370562 C19.8197771,23.8632131 22.1090207,23.0897705 22.1090207,21.3 C22.1090207,21.0238576 22.3328783,20.8 22.6090207,20.8 Z"
            id="Oval"
            fill={color}
            fillRule="nonzero"
          ></Path>
          <Path
            d="M13.3635348,20.5294298 C10.9386797,10.8431433 13.1334199,6 19.9477555,6 C30.6513929,6 27.8124844,16.7 26.2575,19.4289062 C23.7800626,15.9156323 22.0695156,8.11391209 21.1695156,12.8 C20.6265713,15.6269832 18.546355,18.388525 14.9288667,21.0846254 L14.9296552,21.0856851 C14.486573,21.4153768 13.860116,21.3234556 13.5304243,20.8803734 C13.4523493,20.775446 13.3956154,20.6562222 13.3634362,20.5294548 Z"
            id="Path-5"
            stroke={color}
          ></Path>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
