import * as React from "react";
import Svg, { Rect, G } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      {...props}
    >
      <G
        transform="translate(4 4)"
        strokeWidth={1.5}
        stroke="none"
        fill="none"
        fillRule="evenodd"
      >
        <Rect
          stroke={props.color || "#000"}
          x={0}
          y={0}
          width={24}
          height={24}
          rx={4}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
