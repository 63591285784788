import React, { useRef } from "react";
import { Platform, View } from "react-native";
import { WebView } from "react-native-webview";
import AppConfig from "@presto-common/config";

export default function WebViewComponent(props) {
  const {
    styles = {},
    uri = AppConfig.hubspotUrl,
    injectedJavaScript = "",
    id = "",
    onMessage = () => null,
    onLoad = () => null,
  } = props
  const defaultStyles = {
    flex: 1,
    minHeight: "100%",
    minWidth: "100%",
    backgroundColor: "#fefefe",
    paddingBottom: 40,
  }

  return (
    <View
      style={{
        ...defaultStyles,
        ...styles,
      }}
    >
      {Platform.OS === "web" ? (
        <iframe src={AppConfig.hubspotUrl} width="100%" height="100%"></iframe>
      ) : (
        <WebView
          // containerStyle={containerStyle}
          onMessage={onMessage}
          id={id}
          source={{ uri }}
          onLoad={onLoad}
          javaScriptEnabled={true}
          injectedJavaScript={injectedJavaScript}
        />
      )}
    </View>
  );
}