import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function SwipeLeft(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fillOpacity={0.5} fill="#CCC" cx={20} cy={20} r={20} />
        <G fill={props.color || "#7E5C5B"} fillRule="nonzero">
          <Path
            d="M3.806 6.863l-1.53-1.528H8c0 .362.036.724.11 1.074a5.283 5.283 0 00.801 1.908.667.667 0 001.105-.745 4.075 4.075 0 01-.369-.679 4.083 4.083 0 01-.313-1.558 3.995 3.995 0 014.807-3.919 4.026 4.026 0 011.429.6 4.015 4.015 0 011.682 2.51 4.091 4.091 0 010 1.615 3.893 3.893 0 01-.601 1.43.667.667 0 001.105.745 5.263 5.263 0 00.803-1.907A5.352 5.352 0 0016.316.911 5.263 5.263 0 0014.41.108a5.4 5.4 0 00-2.15 0 5.283 5.283 0 00-2.698 1.456c-.24.241-.459.505-.651.788-.19.284-.356.59-.492.907-.101.24-.165.492-.23.742H2.277l1.528-1.528a.667.667 0 00-.942-.942L.196 4.196a.661.661 0 000 .944l2.666 2.665a.665.665 0 00.944.002.669.669 0 000-.944z"
            transform="translate(6 5)"
          />
          <Path
            d="M26.003 14.712a2.72 2.72 0 00-2.003.273v-.357a2.662 2.662 0 00-2.666-2.653c-.486 0-.942.129-1.334.356v-.356a2.67 2.67 0 00-2.666-2.667c-.486 0-.942.13-1.334.359V5.32a2.663 2.663 0 00-2.666-2.653 2.663 2.663 0 00-2.667 2.653v8.304a2.608 2.608 0 00-1.28-.33c-.833 0-1.53.298-2.019.862-.582.675-.82 1.699-.654 2.811.71 4.659 2.405 8.584 5.034 11.667.38.445.94.7 1.536.7h10.44c.808 0 1.532-.472 1.842-1.206 1.51-3.586 2.397-7.469 2.433-10.648.016-1.33-.805-2.469-1.996-2.769zm-1.667 12.899c-.1.237-.34.39-.613.39h-10.44a.685.685 0 01-.52-.23c-2.468-2.892-4.06-6.594-4.732-11.002-.107-.714.023-1.365.345-1.741.232-.27.563-.4 1.008-.4.718 0 1.28.585 1.28 1.333a.667.667 0 001.334 0V5.321c.002-.714.613-1.32 1.336-1.32.722 0 1.333.606 1.333 1.32v6.654a.667.667 0 001.333 0c0-.735.599-1.334 1.334-1.334.734 0 1.333.599 1.333 1.334v2.653a.667.667 0 001.333 0c0-.716.611-1.32 1.334-1.32.722 0 1.333.605 1.333 1.32v2.653a.667.667 0 001.333 0c0-.828.843-1.48 1.676-1.277.582.147.998.761.99 1.461-.034 2.97-.904 6.763-2.33 10.146z"
            transform="translate(6 5)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SwipeLeft;
