import $ from "../global/util";

const MediaItemObject = function () {
  function MediaItemObject() {
    this.alt_text = "";
    this.description = "";
    this.file_url = "";
    this.name = "";
    this.target = "";
    this.type = "";
    this.other_data = {};
  }

  MediaItemObject.prototype = {
    validateObject: function () {},
    buildObject: function (rawMediaItem) {
      this.alt_text = rawMediaItem.alt_text;
      this.description = rawMediaItem.description;
      this.file_url = rawMediaItem.file_url;
      this.name = rawMediaItem.name;
      this.target = rawMediaItem.target;
      this.type = rawMediaItem.type;
      this.other_data = rawMediaItem.other_data;
    },
  };

  return MediaItemObject;
};

export default MediaItemObject();
