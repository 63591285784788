import * as React from "react";
import Svg, { G, Rect, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
  return (
    <Svg width={122} height={149} viewBox="0 0 122 149" fill="none" {...props}>
      <G filter="url(#prefix__filter0_i)">
        <G filter="url(#prefix__filter1_d)">
          <Rect x={64} y={57.469} width={20} height={20} rx={10} fill="#fff" />
          <Rect
            x={64.5}
            y={57.969}
            width={19}
            height={19}
            rx={9.5}
            stroke="#E4E4E4"
          />
        </G>
      </G>
      <Defs></Defs>
    </Svg>
  );
}

export default SvgComponent;
