import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import AddressObject from "./AddressObject";
import UserObject from "./UserObject";
import $ from "../global/util";

const OrderObject = function (
  Utils,
  ErrorConstants,
  AddressObject,
  UserObject
) {
  function OrderObject() {
    this.accept_instructions = "";
    this.bill_amount = 0;
    this.coupon_code = "";
    this.created_at = "";
    this.delivery_charge = 0;
    this.delivery_charge_tax = 0;
    this.discount = 0;
    this.external_id = "";
    this.friendly_id = "";
    this.merchant_id = "";
    this.full_address = "";
    this.gross_amount = 0;
    this.guest = false;
    this.id = "";
    this.instructions = "";
    this.items = [];
    this.line_items = [];
    this.landmark = "";
    this.line2 = "";
    this.no_of_items = 0;
    this.order_text = "";
    this.outlet = undefined;
    this.outlet_id = "";
    this.outlet_name = undefined;
    this.payment_id = undefined;
    this.payment_status = undefined;
    this.payment_type = undefined;
    this.pg_url = "";
    this.pos_id = "";
    this.pos_message = "";
    this.quantity = 0;
    this.reject_reason = undefined;
    this.source = undefined;
    this.status = undefined;
    this.sub_status = undefined;
    this.tax_amount = 0;
    this.time_slot = undefined;
    this.time_slot_value = undefined;
    this.total_amount = 0;
    this.type = undefined;
    this.updated_at = undefined;
    this.address = new AddressObject();
    this.user = new UserObject();
    this.other_data = [];
    this.seller_id = undefined;
    this.composite_payment_id = undefined;
    this.order_ids = [];
    this.merchant_hash = undefined;
    this.accepted_at = undefined;
    this.dispatched_at = undefined;
    this.delivered_at = undefined;
    this.original_gross_amount = 0;
    this.delivery = {};
    this.balance_amount = undefined;
    this.payments_list = undefined;
  }

  OrderObject.prototype = {
    validateObject: function () {},
    buildObject: function (rawOrder, priceInCents = false) {
      this.accept_instructions = rawOrder.accept_instructions;
      this.bill_amount = priceInCents
        ? rawOrder.bill_amount / 100
        : rawOrder.bill_amount;
      this.coupon_code = rawOrder.coupon_code;
      this.created_at = rawOrder.created_at;
      this.delivery_charge = rawOrder.delivery_charge;
      this.delivery_charge_tax = rawOrder.delivery_charge_tax;
      this.discount = rawOrder.discount;
      this.external_id = rawOrder.external_id;
      this.friendly_id = rawOrder.friendly_id;
      this.address = new AddressObject();
      this.address = rawOrder.address;
      this.full_address = rawOrder.full_address;
      this.gross_amount = rawOrder.gross_amount;
      this.guest = rawOrder.guest;
      this.id = rawOrder.id;
      this.instructions = rawOrder.instructions;
      this.items = rawOrder.items;
      $.each(rawOrder.line_items, function (lineItemId, line_item) {
        rawOrder.line_items[lineItemId].id = lineItemId;
      });
      if (!this.items) {
        this.items = $.values($.clone(rawOrder.line_items));
        console.log("Order object items length ", this.items.length);
        console.log("Order object : ", JSON.stringify(this.items, null, 2));
        $.each(this.items, (index, item) => {
          item.item_id =
            item.item_id && item.item_id["$oid"]
              ? item.item_id["$oid"]
              : undefined;
        });
      }

      this.line_items = rawOrder.line_items;
      this.landmark = rawOrder.landmark;
      this.line2 = rawOrder.line2;
      this.no_of_items = rawOrder.no_of_items;
      this.order_text = rawOrder.order_text;
      this.outlet = rawOrder.outlet;
      this.outlet_id = rawOrder.outlet_id;
      this.outlet_name = rawOrder.outlet_name;
      this.payment_id = rawOrder.payment_id;
      this.payment_status = rawOrder.payment_status;
      this.payment_type = rawOrder.payment_type;
      this.pg_url = rawOrder.pg_url;
      this.pos_id = rawOrder.pos_id;
      this.pos_message = rawOrder.pos_message;
      this.quantity = rawOrder.quantity;
      this.reject_reason = rawOrder.reject_reason;
      this.source = rawOrder.source;
      this.status = rawOrder.status;
      this.sub_status = rawOrder.sub_status;
      this.tax_amount = rawOrder.tax_amount;
      this.time_slot = rawOrder.time_slot;
      this.time_slot_value = rawOrder.time_slot_value;
      this.total_amount = priceInCents
        ? rawOrder.total_amount / 100
        : rawOrder.total_amount;
      this.type = rawOrder.type;
      this.updated_at = rawOrder.updated_at;
      this.user = new UserObject();
      if (rawOrder.user_hash) {
        this.user.buildObject(rawOrder.user_hash);
      } else if (rawOrder.user) {
        this.user.buildObject(rawOrder.user);
      }
      this.other_data = rawOrder.other_data;
      this.seller_id = rawOrder.seller_id;
      this.composite_payment_id = rawOrder.composite_payment_id;
      this.order_ids = rawOrder.order_ids;
      this.merchant_id = rawOrder.merchant_id;
      this.merchant_hash = rawOrder.merchant_hash;
      this.accepted_at = rawOrder.accepted_at;
      this.dispatched_at = rawOrder.dispatched_at;
      this.delivered_at = rawOrder.delivered_at;
      this.original_gross_amount = rawOrder.original_gross_amount;
      this.delivery = rawOrder.delivery;
      this.balance_amount = rawOrder.balance_amount;
      this.payments_list = rawOrder.payments_list;
    },
  };

  return OrderObject;
};

export default OrderObject(Utils, ErrorConstants, AddressObject, UserObject);
