import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent({
  width,
  height,
  fillColor = "#CCCCCC",
  color = "#7E5C5B",
}) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/customer-with-circle">
          <Circle
            id="Oval"
            fill-opacity="0.5"
            fill={fillColor}
            cx="20"
            cy="20"
            r="20"
          ></Circle>
          <G
            id="icons8-customer-(1)-copy"
            transform="translate(10.000000, 9.000000)"
            fill={color}
            fill-rule="nonzero"
          >
            <Path
              d="M10,0 C7.232422,0 5,2.28433879 5,5.11627907 L5,8.18604651 C5,9.84284144 5.792969,11.32976 7,12.2630814 L7,14.5813953 C7,14.6353562 6.9902345,14.6813229 6.859375,14.8052326 C6.7285155,14.9291427 6.496094,15.0950217 6.1875,15.252907 C5.5722655,15.5686776 4.683594,15.8924419 3.78125,16.3401163 C2.8789065,16.7877907 1.9433595,17.3573763 1.21875,18.1787791 C0.4941405,19.0001818 0,20.1013811 0,21.4883721 L0,22 L20,22 L20,21.4883721 C20,20.0893895 19.5058595,18.9841934 18.78125,18.1627907 C18.0566405,17.341388 17.121094,16.7817949 16.21875,16.3401163 C15.3164065,15.8984376 14.4257815,15.5646802 13.8125,15.252907 C13.5058595,15.0970206 13.2695315,14.9451311 13.140625,14.8212209 C13.011719,14.6973113 13,14.6453488 13,14.5813953 L13,12.2630814 C14.2070315,11.32976 15,9.84284144 15,8.18604651 L15,5.11627907 C15,2.28433879 12.767578,0 10,0 Z M10,1.02325581 C12.232422,1.02325581 14,2.83194028 14,5.11627907 L14,8.18604651 C14,9.5970206 13.279297,10.8680962 12.21875,11.5915698 L12,11.7514535 L12,14.5813953 C12,14.9771078 12.199219,15.3268531 12.453125,15.5726744 C12.7070315,15.8184958 13.0253905,16.002362 13.375,16.1802326 C14.074219,16.5359741 14.933594,16.8517442 15.78125,17.2674419 C16.6289065,17.6831395 17.4433595,18.1847748 18.03125,18.8502907 C18.5195315,19.4018895 18.8027345,20.1093753 18.90625,20.9767442 L1.09375,20.9767442 C1.1972655,20.1193679 1.482422,19.4198763 1.96875,18.8662791 C2.5566405,18.1987648 3.371094,17.6891353 4.21875,17.2674419 C5.0664065,16.8457484 5.9277345,16.5199857 6.625,16.1642442 C6.9726565,15.9863737 7.294922,15.79851 7.546875,15.556686 C7.798828,15.314862 8,14.9751089 8,14.5813953 L8,11.7514535 L7.78125,11.5915698 C6.720703,10.8680962 6,9.5970206 6,8.18604651 L6,5.11627907 C6,2.83194028 7.767578,1.02325581 10,1.02325581 Z"
              id="Shape"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
