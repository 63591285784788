import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function LogoutIconFilled(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path d="M0 0H29V29H0z" />
          <Path
            d="M5.138 1.573v25.754h15.82V29H3.625V0h17.333v1.573H5.138zm14.82 22.426l-1.275-1.227 7.214-7.431H10.634v-1.515h15.263L18.683 6.79l1.274-1.456 9.001 9.001-9 9.664z"
            fill={props.color || "#707070"}
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  );
}

export default LogoutIconFilled;
