import $ from "../global/util";

export default {
  ErrorCodes: {
    SUCCESS: "000",
    USER_NAME_EMPTY: "001",
    EMAIL_EMPTY: "002",
    PHONE_NUMBER_EMPTY: "003",
    APP_ID_EMPTY: "004",
    MERCHANT_ID_EMPTY: "005",
    PASSWORD_EMPTY: "006",
    ADDRESS_EMPTY: "007",
    PROFILE_PIC_EMPTY: "008",
    INVALID_PASSWORD: "009",
    INVALID_EMAIL: "010",
    INVALID_PHONE_NUMBER: "011",
    INVALID_EMAIL_OR_PHONE_NUMBER: "012",
    TOKEN_EMPTY: "013",
    ACCESS_TOKEN_EMPTY: "014",
    PROVIDER_NAME_EMPTY: "015",
    REFERENCE_ID_EMPTY: "016",
    OUTLET_NAME_EMPTY: "017",
    FEEDBACK_RATING_UNAVAILABLE: "018",
    USER_ID_EMPTY: "019",
    DEVICE_ID_EMPTY: "020",
    APPOINTMENT_ID_EMPTY: "021",
    OUTLET_ID_EMPTY: "022",
    FILTER_TEXT_EMPTY: "023",
    CATEGORY_ID_EMPTY: "024",
    COUPON_CODE_EMPTY: "025",
    NO_SERVICE_ITEMS: "026",
    RAZOR_PAY_URL_EMPTY: "027",
    RAZOR_PAY_PAYMENT_ID_EMPTY: "028",
    WALLET_ID_EMPTY: "029",
    OTP_EMPTY: "030",
    UNKNOWN_ERROR: "031",
    SEARCH_STRING_EMPTY: "032",
    ITEM_ID_EMPTY: "033",
    NO_CATEGORY_ITEMS: "034",
    ADDRESS_ID_EMPTY: "035",
    SERVICE_ID_EMPTY: "036",
    SUBSCRIPTION_ID_EMPTY: "037",
    NO_SUBSCRIPTION_ITEM: "038",
    PAYMENT_URL_EMPTY: "039",
    REPEAT_PASSWORD_EMPTY: "040",
    REPEAT_PASSWORD_MISMATCH: "041",
    BOOKING_ID_EMPTY: "042",
    BOOKING_START_TIME_EMPTY: "043",
    BOOKING_END_TIME_EMPTY: "044",
  },
  ErrorMessages: {
    "000": "Success",
    "001": "User name can't be empty",
    "002": "Enter valid email id",
    "003": "Phone number can't be empty",
    "004": "App Id can't be empty",
    "005": "Merchant Id can't be empty",
    "006": "Password can't be empty",
    "007": "Address can't be empty",
    "008": "Profile pic can't be empty",
    "009": "Password length should be between 6 to 20 characters",
    "010": "Invalid Email Id",
    "011": "Invalid Phone Number",
    "012": "Invalid email or phone number",
    "013": "Token can't be empty",
    "014": "Access token can't be empty",
    "015": "Provider name can't be empty",
    "016": "Reference Id can't be empty",
    "017": "Outlet name can't be empty",
    "018": "Rating not available",
    "019": "User id can't be empty",
    "020": "Device id can't be empty",
    "021": "Appointment Id can't be empty",
    "022": "Outlet Id can't be empty",
    "023": "Filter text can't be empty",
    "024": "Category Id can't be empty",
    "025": "Coupon code can't be empty",
    "026": "No services are added",
    "027": "Razor Pay Url is empty",
    "028": "Razor Pay payment Id is empty",
    "029": "Wallet Id can't be empty",
    "030": "OTP can't be empty",
    "031": "Unknown error occured",
    "032": "Search string can't be empty",
    "033": "Item Id can't be empty",
    "034": "No items are added",
    "035": "Address Id can't be empty",
    "036": "Service Id can't be empty",
    "037": "Subscription Id can't be empty",
    "038": "Subscription items can't be empty",
    "039": "Payment URL can't be empty",
    "040": "Please repeat your password",
    "041": "Password missmatch",
    "042": "Booking Id can't be empty",
    "043": "Booking start time can't be empty",
    "044": "Booking end time can't be empty",
  },
  SuccessMessages: {
    on_login: "Login successful",
    on_signup: "Signup successful",
    on_logout: "Logout successful",
    add_service: "Service added to cart",
    remove_service: "Service removed from the cart",
    clear_cart: "Cart has been cleared",
    appointment_booked: "Appointment has been booked",
    order_placed: "Order has been successfully placed",
    payment_successful: "Payment successful",
    feedback_sent: "Feedback shared",
    add_to_calendar: "Appointment added to calendar",
    appointment_cancelled: "Appointment has been cancelled",
    refer_successful: "Referral code has been shared successfully",
    profile_updated: "Profile updated",
    appointment_updated: "Appointment has been updated",
    on_otp_requested: "OTP has been requested successfully",
    on_otp_verified: "OTP verification successful",
    on_token_sent: "Token has been sent via email/sms",
    on_password_reset:
      "Password reset successful. Please login using new password",
    on_upload_document: "Document successfully uploaded",
    employee_created: "Employee successfully created",
    employee_updated: "Employee successfully updated",
    employee_address_updated: "Employee address successfully updated",
  },
};
