import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function FavoriteSelectedIcon(props) {
  return (
    <Svg
    width="41px"
    height="41px"
    viewBox="0 0 41 41"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G
      transform="translate(-29 -19) translate(25 15) translate(4.9 4.9)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <Circle fill="#D8D8D8" cx={19.6} cy={19.6} r={19.6} />
      <Path
        d="M19.91 1.34c-2.36-2.043-5.87-1.676-8.037.595l-.848.888-.848-.888C8.015-.335 4.5-.703 2.14 1.34c-2.703 2.345-2.846 6.554-.425 9.096l8.333 8.74a1.347 1.347 0 00.975.424 1.329 1.329 0 00.975-.424l8.333-8.74c2.426-2.542 2.283-6.751-.42-9.096z"
        transform="translate(8.575 9.8)"
        fill={props.color || "#FFF"}
        fillRule="nonzero"
      />
    </G>
  </Svg>
  )
}

export default FavoriteSelectedIcon
