import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={17} height={30} viewBox="0 0 17 30" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M12.208 14.86a4.745 4.745 0 01-2.744 1.808 4.538 4.538 0 01-2.05 0 4.746 4.746 0 01-2.744-1.807 4.673 4.673 0 01-.896-3.238l.704-7.873H12.4l.703 7.873a4.666 4.666 0 01-.895 3.238z"
        />
        <Path d="M12.4 3.75l.703 7.873a4.665 4.665 0 01-.895 3.238 4.746 4.746 0 01-2.744 1.807 4.54 4.54 0 01-2.05 0 4.746 4.746 0 01-2.744-1.807 4.673 4.673 0 01-.896-3.238l.704-7.873H12.4zM14.985 0H1.893a.929.929 0 00-.92.853L.036 11.289a8.477 8.477 0 006.527 9.033v6.866H4.219a2.344 2.344 0 00-2.344 2.343.469.469 0 00.469.469H14.53a.469.469 0 00.469-.469 2.344 2.344 0 00-2.344-2.343h-2.344V20.32a8.477 8.477 0 006.527-9.033L15.902.853A.929.929 0 0014.985 0z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h16.875v30H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
