import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function NonVegIcon(props) {
  return (
    <Svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="#9D3B14" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path d="M0 0H10V10H0z" />
        <Path d="M5 3.118L7.191 7.5H2.809L5 3.118z" fill="#9D3B14" />
      </G>
    </Svg>
  )
}

export default NonVegIcon
