import * as React from "react";
import Svg, { Defs, Path, G, Circle, Image, Use } from "react-native-svg";

export function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill={props.color || "#C4421A"} cx={20} cy={20} r={20} />
        <G stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
          <Path
            d="M24 11.333a11.174 11.174 0 01-1.2 5.067 11.333 11.333 0 01-10.133 6.267 11.173 11.173 0 01-5.067-1.2L0 24l2.533-7.6a11.173 11.173 0 01-1.2-5.067A11.333 11.333 0 017.6 1.2 11.174 11.174 0 0112.667 0h.666A11.307 11.307 0 0124 10.667v.666h0z"
            transform="translate(8 8)"
            strokeWidth={2}
          />
          <G transform="translate(8 8) translate(6 9)" strokeWidth={1.5}>
            <Path d="M0 0.5L12 0.5" />
            <Path d="M1 5.5L11 5.5" />
          </G>
        </G>
      </G>
    </Svg>
  );
}
export default SvgComponent;
