import * as React from "react";
import Svg, { G, Defs, Rect, Polygon, Mask, Use } from "react-native-svg";

function BackIcon(props) {
  const fillColor = props?.fillColor || "#707070";
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
      </Defs>
      <G
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="Backward-arrow" transform="translate(5.000000, 6.000000)">
          <Mask id="mask-2" fill="white">
            <Use xlinkHref="#path-1"></Use>
          </Mask>
          <G id="Rectangle"></G>
          <G
            id="Backward_arrow"
            mask="url(#mask-2)"
            fill={fillColor}
            fillRule="nonzero"
          >
            <G transform="translate(0.000000, -0.000000)" id="Path_10">
              <Polygon
                transform="translate(14.500000, 14.500000) rotate(180.000000) translate(-14.500000, -14.500000) "
                points="14.5 0 11.8628125 2.6371875 21.8424375 12.6168125 0 12.6168125 0 16.3831875 21.84425 16.3831875 11.8628125 26.3628125 14.5 29 29 14.5"
              ></Polygon>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default BackIcon;
