import _ from "lodash";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import config from "@presto-common/config";
import create from "zustand";
import { devtools } from "zustand/middleware";
import moment from "moment";

let createGallery = (set, get) => ({
  ready: false,
  items: {},
  loading: {},
  loadingTimes: {},
  merchantId: null,
  init: (merchantId) => {
    set({ merchantId: merchantId, ready: true });
  },
  fetch: async (galleryName, force = false) => {
    const isLoading = get().loading[galleryName];
    const lastLoadTime =
      get().loadingTimes[galleryName] || moment().subtract("1", "days");
    if (!force && moment() - moment(lastLoadTime) < 60000) {
      return;
    }
    if (!isLoading || force) {
      set({
        loading: _.merge(get().loading, { [galleryName]: true }),
        loadingTimes: _.merge(get().loading, { [galleryName]: new Date() }),
      });
      await getGalleryItems(set, get, galleryName);
    }
  },

  refresh: (galleryName, force = false) => {
    fetch(galleryName, force);
  },
  get: (galleryName) => {
    const items = get().items;
    if (items[galleryName]) {
      return items[galleryName];
    } else {
      // TODO(amal:important) : Can we load a gallery when a user tries to load it.
      get().fetch(galleryName);
      return undefined;
    }
  },
});

const getGalleryItems = async (set, get, galleryName) => {
  let params = {
    name: galleryName,
    merchant_id: get().merchantId,
  };
  GalleryManager.getGallery(
    params,
    (response) => {
      set({
        items: _.merge(get().items, {
          [galleryName]: response.data.media_items,
        }),
        loading: _.merge(get().loading, { [galleryName]: false }),
      });
    },
    (error) => {
      set({
        loading: _.merge(get().loading, { [galleryName]: false }),
      });
    }
  );
};

export const useGalleries = create(devtools(createGallery));
