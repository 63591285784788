import * as React from 'react';
import Svg, { G, Rect, Path } from 'react-native-svg';

function EmailIcon(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        stroke={props.color || '#333'}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <Rect
          x={0.75}
          y={0.75}
          width={30.3289057}
          height={20.5}
          rx={1.98}
          transform="translate(4 9)"
        />
        <Path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.44988251 10.3254166L20 20 34.4888763 10.3254166"
        />
      </G>
    </Svg>
  );
}

export default EmailIcon;
