import _ from "lodash";

const globalKeys = {};

export const setGlobalKeys = (key, value) => {
  _.set(globalKeys, key, value);
};

export const getGlobalKeys = (key) => {
  return _.get(globalKeys, key, null);
};
