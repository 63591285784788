import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ThemeStarIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20 1.13l5.546 11.237 12.4 1.802-8.973 8.747 2.119 12.35L20 29.436l-11.092 5.83 2.119-12.35-8.974-8.747 12.401-1.802L20 1.13z"
        fill={props.color || "#F2749B"}
        stroke="#F2749B"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default ThemeStarIcon;
