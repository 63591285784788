import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function PostPaidIcon(props) {
  return (
    <Svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <G
        fill={props.color || "#333333"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Path
          d="M2.901 28C1.301 28 0 26.722 0 25.151V2.85C0 1.278 1.302 0 2.901 0h12.005c1.6 0 2.902 1.278 2.902 2.85V6.45a.496.496 0 01-.5.491c-.277 0-.5-.22-.5-.49V2.848c0-1.03-.854-1.866-1.902-1.866H2.901c-1.047 0-1.9.837-1.9 1.866v22.302c0 1.03.853 1.867 1.9 1.867h12.005c1.048 0 1.901-.838 1.901-1.867V22.93c0-.272.224-.218.5-.218.277 0 .5-.054.5.218v2.222c0 1.571-1.301 2.849-2.9 2.849H2.9z"
          transform="translate(8 6)"
        />
        <Path
          d="M4.477.983V2.21c0 .162.134.294.3.294h8.253c.167 0 .3-.132.3-.294V.983H4.478zm8.553 2.505H4.777a1.29 1.29 0 01-1.3-1.277V.49c0-.271.224-.491.5-.491h9.855c.275 0 .499.22.499.491v1.72a1.29 1.29 0 01-1.3 1.277zM13.568 26.036H3.564c-.276 0-.5-.22-.5-.492s.224-.491.5-.491h10.004c.277 0 .5.22.5.491a.496.496 0 01-.5.492"
          transform="translate(8 6)"
        />
        <G>
          <Path
            d="M15.75 8.648h-2.602V.352a.352.352 0 00-.6-.249l-.996.996-.996-.996a.352.352 0 00-.497 0l-.996.996-.996-.996a.352.352 0 00-.497 0l-.996.996-.996-.996a.352.352 0 00-.497 0l-.996.996-.996-.996a.352.352 0 00-.497 0l-.996.996L.6.103a.351.351 0 00-.6.258v15.81A1.83 1.83 0 001.828 18h12.445a1.83 1.83 0 001.828-1.828V9a.352.352 0 00-.351-.352zM1.828 17.297c-.62 0-1.125-.505-1.125-1.125V1.2l.644.644c.138.137.36.137.497 0L2.84.849l.996.996c.138.137.36.137.497 0L5.33.849l.996.996c.138.137.36.137.497 0l.996-.996.996.996c.138.137.36.137.498 0l.995-.996.996.996c.138.137.36.137.498 0l.644-.645v14.972c0 .424.146.814.39 1.125H1.827zm13.57-1.125c0 .62-.504 1.125-1.125 1.125-.62 0-1.125-.505-1.125-1.125v-6.82h2.25v6.82z"
            fillRule="nonzero"
            transform="translate(8 6) translate(8 5)"
          />
          <Path
            d="M6.574 5.585c-.349 0-.633-.243-.633-.542 0-.299.284-.542.633-.542.349 0 .633.243.633.542a.352.352 0 10.703 0c0-.573-.418-1.057-.984-1.2v-.239a.352.352 0 10-.704 0v.238c-.566.144-.984.628-.984 1.201 0 .686.6 1.245 1.336 1.245.349 0 .633.243.633.542 0 .298-.284.541-.633.541-.349 0-.633-.243-.633-.541a.352.352 0 10-.703 0c0 .573.418 1.056.984 1.2v.287a.352.352 0 10.704 0V8.03c.566-.144.984-.627.984-1.2 0-.687-.6-1.245-1.336-1.245zM10.106 5.585h-.685a.352.352 0 100 .703h.685a.352.352 0 100-.703zM3.727 5.585h-.685a.352.352 0 100 .703h.685a.352.352 0 100-.703zM10.284 9.492h-5.57a.352.352 0 100 .704h5.57a.352.352 0 100-.704zM3.113 9.595a.354.354 0 00-.497 0 .354.354 0 000 .498.354.354 0 00.497 0 .354.354 0 000-.498zM10.284 11.813h-5.57a.352.352 0 100 .703h5.57a.352.352 0 100-.703zM3.113 11.916a.354.354 0 00-.497 0 .354.354 0 000 .497.354.354 0 00.497 0 .354.354 0 000-.497zM10.284 14.133h-5.57a.352.352 0 100 .703h5.57a.352.352 0 100-.703zM3.113 14.236a.354.354 0 00-.497 0 .354.354 0 000 .497.354.354 0 00.497 0 .354.354 0 000-.497z"
            transform="translate(8 6) translate(8 5)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default PostPaidIcon;
