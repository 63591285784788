import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import ReactNativePinView from "react-native-pin-view";
import Container from "@presto-components/Containers/Container";
import { SectionSubHeader } from "@presto-components/SectionHeaders/SectionHeader";
import PrestoIcon from "@presto-components/PrestoIcon";
import I18n from "i18n-js";
import { prestoAlert } from "../../common/Alert";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import DefaultModal from "@presto-components/Modals/DefaultModal";
import LoadingContainer from "@presto-components/LoadingContainer";
import { PrestoTextButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";

export default function SetAndConfirmPinScreen(props) {
  const { svgs } = useAssets();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [pinFilled, setPinFilled] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  let pinViewRef = React.createRef();
  const styles = createFormStyle(theme);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  useEffect(() => {
    if (confirmPin.length == 4) {
      if (pin === confirmPin) {
        onConfirmPin();
      } else {
        setPin("");
        setConfirmPin("");
        setPinFilled(false);
        pinViewRef.current.clearAll();
        alert("Enter Pin Again", "Pins doesn't match");
      }
    }
  }, [confirmPin]);
  const setEnteredPin = (value) => {
    if (pin.length === 4) {
      console.log("value = ", value);
      setConfirmPin(value);
    }

    if (pin.length === 3 && value.length === 4) {
      pinViewRef.current.clearAll();
      setPinFilled(true);
      alert("Enter Pin Again", "Please re-enter pin to confirm");
      setPin(value);
      return;
    }
    if (!pinFilled) {
      setPin(value);
    }
  };
  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };
  const onConfirmPin = () => {
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "VERIFY_PIN",
        screenParams: {
          pin: pin,
          showCart: props.route.params?.showCart,
          directPay: props.route.params?.directPay,
        },
      },
    });
  };
  const onPressSkip = () => {
    Navigator.emit({
      event: "goBack",
    });
  };
  return (
    <LoadingContainer bgColor="transparent" loading={loading}>
      <DefaultModal
        keyboardAvoidView={false}
        goBack={goBack}
        height={400}
        heading={
          props.route.params?.title || I18n.t("screen_messages.pin.title")
        }
      >
        <View
          style={{
            paddingBottom: 15,
            paddingHorizontal: theme.primaryPadding,
          }}
        >
          <Container>
            <SectionSubHeader
              theme={theme}
              leftText={
                props.route.params?.description ||
                I18n.t("screen_messages.pin.confirm_transaction_msg")
              }
            />
            <VerticalSpacing size={50}></VerticalSpacing>
            <ReactNativePinView
              style={{ backgroundColor: "transparent" }}
              inputSize={11}
              ref={pinViewRef}
              pinLength={4}
              buttonSize={50}
              onValueChange={(value) => setEnteredPin(value)}
              buttonAreaStyle={styles.buttonAreaStyle}
              inputAreaStyle={styles.inputAreaStyle}
              inputViewEmptyStyle={styles.inputViewEmptyStyle}
              inputViewFilledStyle={styles.inputViewFilledStyle}
              buttonViewStyle={[styles.buttonViewStyle]}
              buttonTextStyle={styles.buttonTextStyle}
              onButtonPress={(key) => {
                if (key === "custom_right") {
                  pinViewRef.current.clear();
                }
              }}
              customRightButton={
                <View
                  style={{
                    alignSelf: "center",
                    height: "100%",
                    paddingTop: 10,
                  }}
                >
                  <PrestoIcon icon={<svgs.BackSpaceIcon />}></PrestoIcon>
                </View>
              }
            />
          </Container>
        </View>
        {props.route.params?.showSkip && (
          <View
            style={{
              flex: 1,
              width: "100%",
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <PrestoTextButton
              buttonStyle="primary"
              size={"normal"}
              title={I18n.t("screen_messages.common.skip_text")}
              onPress={onPressSkip}
            />
          </View>
        )}
      </DefaultModal>
    </LoadingContainer>
  );
}

function createFormStyle(theme) {
  return {
    buttonAreaStyle: {
      marginTop: 24,
    },
    inputAreaStyle: {
      marginBottom: 24,
    },
    inputViewEmptyStyle: {
      backgroundColor: theme.pinButtonBgColor,
      marginHorizontal: 20,
    },
    inputViewFilledStyle: {
      marginHorizontal: 20,
      backgroundColor: theme.primary,
    },
    buttonViewStyle: {
      marginBottom: 15,
      backgroundColor: theme.pinButtonBgColor,
      borderRadius: 20,
    },
    buttonTextStyle: {
      color: theme.secondary,
      fontFamily: theme.primaryFont,
      fontSize: 28,
    },
  };
}
