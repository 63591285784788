import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="24px"
      height="28px"
      viewBox="0 0 24 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-960 -563) translate(109 210) translate(181 341) translate(670.624 13.568)"
        stroke="#686868"
        strokeWidth={1.25}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M10.914 0L.036 5.334c-.44 9.535 3.186 16.361 10.878 20.477M11 0l10.879 5.334C22.318 14.869 18.692 21.695 11 25.81" />
        <Path d="M7.07339783 12.9054683L9.70468949 15.5447372 15.752408 9.38460031" />
      </G>
    </Svg>
  )
}

export default SvgComponent
