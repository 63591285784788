import React from "react";
import Svg, { G, Polyline, Path, Line, Circle } from "react-native-svg";

function SvgComponent({
  width,
  height,
  fillColor = "#E21111",
  strokeColor = "#E21111",
  lineStrokeColor = "#7E5C5B",
}) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/colour/delete-service-with-circle">
          <Circle
            id="Oval"
            stroke={strokeColor}
            stroke-width="0.9"
            fill={fillColor}
            cx="20"
            cy="20"
            r="17.55"
          ></Circle>
          <G
            id="trash-2"
            transform="translate(10.000000, 9.000000)"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <Polyline
              id="Path"
              stroke="#FFFFFF"
              stroke-width="1.6128"
              points="0 4.6 2.33333333 4.6 21 4.6"
            ></Polyline>
            <Path
              d="M18.6666667,4.6 L18.6666667,20.7 C18.6666667,21.9702549 17.6219977,23 16.3333333,23 L4.66666667,23 C3.37800225,23 2.33333333,21.9702549 2.33333333,20.7 L2.33333333,4.6 M5.83333333,4.6 L5.83333333,2.3 C5.83333333,1.02974508 6.87800225,0 8.16666667,0 L12.8333333,0 C14.1219977,0 15.1666667,1.02974508 15.1666667,2.3 L15.1666667,4.6"
              id="Shape"
              stroke="#FFFFFF"
              stroke-width="1.6128"
              fill="#FFFFFF"
            ></Path>
            <Line
              x1="8.11363636"
              y1="9.10416667"
              x2="8.11363636"
              y2="17.7291667"
              id="Line-4"
              stroke={lineStrokeColor}
              stroke-width="0.96"
            ></Line>
            <Line
              x1="12.8863636"
              y1="9.10416667"
              x2="12.8863636"
              y2="17.7291667"
              id="Line-4-Copy"
              stroke={lineStrokeColor}
              stroke-width="0.96"
            ></Line>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
