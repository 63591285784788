import * as React from "react"
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40.0 40.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <ClipPath id="a">
          <Path d="M15.26.088a.627.627 0 01.642 0l14.944 8.708a.65.65 0 01.316.563v17.426c0 .232-.12.445-.316.563L15.902 36.06a.643.643 0 01-.641 0L.32 27.348A.647.647 0 010 26.785V9.36c0-.23.12-.445.32-.563z" />
        </ClipPath>
        <ClipPath id="b">
          <Path d="M2.436 0v4.763L1.22 4.756a.645.645 0 00-.486.222L0 5.84V1.422L2.436 0z" />
        </ClipPath>
        <ClipPath id="c">
          <Path d="M4.32 0l13.66 7.958-.644.373-3.682 2.15L0 2.515 4.32 0z" />
        </ClipPath>
        <ClipPath id="d">
          <Path d="M4.316 0l13.659 7.965-4.32 2.517L0 2.519 4.316 0z" />
        </ClipPath>
        <ClipPath id="e">
          <Path d="M0 0l13.652 7.965v15.928L0 15.928V0z" />
        </ClipPath>
        <ClipPath id="f">
          <Path d="M13.658 0v15.928L0 23.893V7.965l4.325-2.52v5.407a.644.644 0 00.644.647c.018 0 .026-.02.051-.026.163-.014.32-.066.433-.196l1.669-1.951 1.569.007a.638.638 0 00.454-.194.627.627 0 00.187-.456V2.525L13.658 0z" />
        </ClipPath>
        <ClipPath id="g">
          <Path d="M2.436 0l13.659 7.965-2.436 1.424L0 1.424 2.436 0z" />
        </ClipPath>
        <ClipPath id="h">
          <Path d="M11.388.062a.795.795 0 01.878.176L19.16 7.43a.812.812 0 01-.012 1.12l-7.041 7.337a.813.813 0 01-.88.193.79.79 0 01-.497-.739l.035-3.497-1.273.013c-3.353.023-6.44 1.957-7.958 4.988a.852.852 0 01-.74.467.713.713 0 01-.177-.02.776.776 0 01-.618-.765l.016-1.618C.081 9.118 4.804 4.377 10.574 4.312l.268-.002.036-3.496a.83.83 0 01.51-.752z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#a)" transform="translate(1)">
        <Path
          fill="#FFF"
          d="M0 0L31.1624317 0 31.1624317 36.1458707 0 36.1458707 0 0z"
        />
      </G>
      <G clipPath="url(#b)" transform="translate(1) translate(21.835 13.757)">
        <Path
          fill="#FFF"
          d="M0 0L2.4359912 0 2.4359912 5.8400342 0 5.8400342 0 0z"
        />
      </G>
      <G clipPath="url(#c)" transform="translate(1) translate(11.26 1.4)">
        <Path
          fill="#707070"
          d="M0 0L17.9795547 0 17.9795547 10.4804876 0 10.4804876 0 0z"
        />
      </G>
      <G clipPath="url(#d)" transform="translate(1) translate(1.928 6.84)">
        <Path
          fill="#707070"
          d="M0 0L17.9749908 0 17.9749908 10.4816399 0 10.4816399 0 0z"
        />
      </G>
      <G clipPath="url(#e)" transform="translate(1) translate(1.286 10.482)">
        <Path
          fill="#707070"
          d="M0 0L13.6518195 0 13.6518195 23.8925156 0 23.8925156 0 0z"
        />
      </G>
      <G clipPath="url(#f)" transform="translate(1) translate(16.222 10.482)">
        <Path
          fill="#707070"
          d="M0 0L13.6586654 0 13.6586654 23.8925156 0 23.8925156 0 0z"
        />
      </G>
      <G clipPath="url(#g)" transform="translate(1) translate(7.534 4.666)">
        <Path
          fill="#FFF"
          d="M0 0L16.0946566 0 16.0946566 9.38922626 0 9.38922626 0 0z"
        />
      </G>
      <G clipPath="url(#h)" transform="translate(1) rotate(140 16.695 22.768)">
        <Path
          fill="#707070"
          d="M-1.38177552e-7 -2.77555756e-17L19.3795121 -2.77555756e-17 19.3795121 17.3131277 -1.38177552e-7 17.3131277 -1.38177552e-7 -2.77555756e-17z"
        />
      </G>
      <Path
        d="M19.16 7.43L12.267.239a.795.795 0 00-.878-.176.83.83 0 00-.51.752l-.036 3.496-.268.002C4.804 4.377.08 9.118.016 14.91L0 16.528c0 .372.256.69.618.765.058.014.118.02.177.02a.852.852 0 00.74-.467c1.519-3.031 4.605-4.965 7.958-4.988l1.273-.013-.035 3.497c0 .328.196.62.497.74.302.119.65.043.88-.194l7.041-7.337a.812.812 0 00.012-1.12h0z"
        stroke="#FFF"
        strokeWidth={1.44}
        fill="none"
        strokeMiterlimit={10}
        transform="translate(1) rotate(140 16.695 22.768)"
      />
    </Svg>
  )
}

export default SvgComponent
