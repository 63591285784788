import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
function SvgComponent({ width = "22px", height = "22px" }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        transform="translate(-26 -130) translate(27 130.5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          stroke="#FFF"
          strokeWidth={2}
          strokeLinecap="round"
          d="M0 18.6666667L22 18.6666667"
        />
        <Path
          d="M11.392.502l3.846 4.93a.5.5 0 00.7.087l1.956-1.514a.5.5 0 01.806.396v3.99a.5.5 0 00.829.376l1.642-1.438a.5.5 0 01.829.376V14a.5.5 0 01-.5.5H.5A.5.5 0 010 14V7.718a.5.5 0 01.832-.374L2.467 8.8a.5.5 0 00.832-.374V4.411a.5.5 0 01.81-.393l1.904 1.497a.5.5 0 00.701-.083L10.605.5a.5.5 0 01.787.002z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
}
export default SvgComponent;