import React, { useContext, useState, useEffect } from "react";
import { Dimensions, View, StyleSheet, Keyboard } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Spring from "@presto-components/Spring/Spring";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";

export default function DefaultModal({
  style,
  children,
  goBack,
  heading,
  footer,
  headerFontSize,
  height,
}) {
  const { theme } = useContext(ThemeContext);
  const [close, setClose] = useState(close);
  const fromSpring = { margin: 0 };
  const toSpring = { margin: 600 };
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const modalHeaderFontSize = headerFontSize ? headerFontSize : 18;
  const [padding, setPadding] = useState(0);
  let isDesktop = windowWidth > 940 ? true : false;

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      console.log("in showSubscription");
      setPadding(100);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      console.log("in hidesubscription");
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => {
        return (
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={[
                styles.modalContainer,
                {
                  height: isDesktop ? "100%" : windowHeight - 200,
                  width: isDesktop ? "100%" : windowWidth,
                },
              ]}
            >
              <View
                style={{
                  backgroundColor: theme.modalBackgroundColor,
                  maxHeight: isDesktop ? "100%" : windowHeight - 100,
                  minHeight: isDesktop ? "100%" : windowHeight,
                  zIndex: 2,
                  width: isDesktop ? "100%" : windowWidth,
                  position: "relative",
                  borderTopEndRadius: isDesktop ? 0 : 15,
                  borderTopLeftRadius: isDesktop ? 0 : 15,
                  overflow: "hidden",
                  ...style,
                }}
              >
                <ScrollView
                  contentContainerStyle={{
                    height: isDesktop ? "100%" : windowHeight - 100,
                  }}
                  stickyHeaderIndices={heading ? 0 : null}
                >
                  {heading ? (
                    <View
                      style={{
                        paddingHorizontal: theme.primaryPadding,
                        paddingVertical: 20,
                      }}
                    >
                      <PrestoText
                        fontStyle="bold"
                        size={modalHeaderFontSize}
                        color={theme.darkText}
                      >
                        {heading}
                      </PrestoText>
                    </View>
                  ) : null}
                  {children}
                </ScrollView>
                {footer ? (
                  <View
                    style={{
                      paddingHorizontal: theme.primaryPadding,
                      paddingBottom: 15,
                      paddingTop: 15,
                      position: "absolute",
                      bottom: !isDesktop ? 100 : 0,
                      minHeight: footer ? 100 : 0,
                      width: "100%",
                      backgroundColor: theme.modalBackgroundColor,
                    }}
                  >
                    {footer}
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        );
      }}
    </Spring>
  );
}
const styles = {
  modalContainer: {
    shadowColor: "rgba(95, 95, 95, 1)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 30,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
  },
};
