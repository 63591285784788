import * as React from "react"
import Svg, { Defs, Path, G, Mask, Use, Circle } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="sor1" d="M0 0H18.0869565V19H0z" />
        <Path id="sort11" d="M0 0H18.0869565V19H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(3 6) matrix(1 0 0 -1 0 19)">
          <Mask id="sor11" fill="#fff">
            <Use xlinkHref="#sor1" />
          </Mask>
          <G mask="url(#sor11)" fill="#000" fillRule="nonzero">
            <Path
              transform="translate(3.818) rotate(90 5.506 9.5)"
              d="M8.76915741 3.9940033L7.19197047 5.09348463 10.3165592 8.60647848 -3.9940033 8.60647848 -3.9940033 10.955522 10.3177467 10.955522 7.72164881 13.3689914 8.76915741 15.0059967 15.0059967 9.78100022z"
            />
          </G>
        </G>
        <G transform="translate(3 6) translate(7.913)">
          <Mask id="sort111" fill="#fff">
            <Use xlinkHref="#sort11" />
          </Mask>
          <G mask="url(#sort111)" fill="#000" fillRule="nonzero">
            <Path
              transform="translate(3.818) rotate(90 5.506 9.5)"
              d="M8.76915741 3.9940033L7.19197047 5.09348463 10.3165592 8.60647848 -3.9940033 8.60647848 -3.9940033 10.955522 10.3177467 10.955522 7.72164881 13.3689914 8.76915741 15.0059967 15.0059967 9.78100022z"
            />
          </G>
        </G>
        <Circle fill="#CBA066" cx={20.5} cy={7.5} r={3.5} />
      </G>
    </Svg>
  )
}

export default SvgComponent
