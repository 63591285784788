import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(5 6)">
          <Mask id="b" fill={props.color2 || "#FFF"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <G transform="translate(-.003 -.003)">
              <Path
                stroke="none"
                fill="none"
                d="M0.00308998349 0.00308998349H29.00308998349V29.00308998349H0.00308998349z"
              />
              <Path
                d="M23.566 3.628h3.625a1.713 1.713 0 011.812 1.813v21.75a1.713 1.713 0 01-1.812 1.812H1.816a1.713 1.713 0 01-1.813-1.812V5.44a1.713 1.713 0 011.813-1.813H5.44V1.816A1.713 1.713 0 017.253.003a1.713 1.713 0 011.813 1.813v1.812H19.94V1.816a1.813 1.813 0 013.625 0v1.812z"
                stroke="none"
                fill={props.color || "#FAA85D"}
                fillRule="evenodd"
              />
              <Path
                stroke={props.color2 || "#FFF"}
                strokeWidth={1}
                fill={props.color2 || "#FFF"}
                fillRule="evenodd"
                d="M13.00309 12.00309H16.00309V15.00309H13.00309z"
              />
              <Path
                stroke={props.color2 || "#FFF"}
                strokeWidth={1}
                fill={props.color2 || "#FFF"}
                fillRule="evenodd"
                d="M13.00309 19.00309H16.00309V22.00309H13.00309z"
              />
              <Path
                stroke={props.color2 || "#FFF"}
                strokeWidth={1}
                fill={props.color2 || "#FFF"}
                fillRule="evenodd"
                d="M8.00308998 19.00309H11.00308998V22.00309H8.00308998z"
              />
              <Path
                stroke={props.color2 || "#FFF"}
                strokeWidth={1}
                fill={props.color2 || "#FFF"}
                fillRule="evenodd"
                d="M18.00309 19.00309H21.00309V22.00309H18.00309z"
              />
              <Path
                stroke={props.color2 || "#FFF"}
                strokeWidth={1}
                fill={props.color2 || "#FFF"}
                fillRule="evenodd"
                d="M18.00309 12.00309H21.00309V15.00309H18.00309z"
              />
            </G>
          </G>
        </G>
        <G
          transform="translate(18 3)"
          stroke={props.color1 || "#7E5C5B"}
          strokeWidth={1.152}
        >
          <Circle fill={props.color2 || "#FFF"} cx={8.5} cy={8.5} r={7.924} />
          <Path d="M8.5 4.04892398L8.5 10.1788733 12.4669613 11.917009" />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
