import React, { useState, useContext } from "react";
import { Image, Platform, TouchableWithoutFeedback, View } from "react-native";
import PrestoIcon from "@presto-components/PrestoIcon";
import { Appbar } from "react-native-paper";
import { useSafeArea } from "react-native-safe-area-context";
import { useAssets } from "presto-react-components";
import CartContext from "@presto-contexts/CartContext";
import PrestoText from "@presto-components/PrestoText";

function TopHeaderCenterTitleIcon(props) {
  const { images: Images, svgs } = useAssets();
  const {
    rightIcon2,
    rightIcon1,
    onPressRightIcon1,
    onPressRightIcon2,
    onLogoClick,
    onBack,
    theme,
    showCartIcon,
    showWallet,
    showCart,
    onPressCartIcon,
    hideSearch,
  } = props;
  const insets = useSafeArea();

  const { getItemCount } = useContext(CartContext);

  return (
    <Appbar
      style={{
        height: 50 + insets.top,
        width: "100%",
        alignItems: "flex-end",
        backgroundColor: theme.appBackgroundColor,
        borderBottomColor: "lightgray",
        borderBottomWidth: 1,
        shadowOpacity: 0,
      }}
    >
      <View
        style={{
          height: 40,
          bottom: 0,
          width: "100%",
          flexDirection: "row",
        }}
      >
        <View style={{}}>
          <TouchableWithoutFeedback onPress={() => onBack()}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <PrestoIcon icon={<svgs.LeftArrow width={30} height={30} />} />
            </View>
          </TouchableWithoutFeedback>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            marginLeft: 10,
          }}
        >
          <TouchableWithoutFeedback onPress={onLogoClick}>
            <Image
              source={Images.textLogo}
              resizeMode="contain"
              style={{
                height: 36,
                marginTop: 5,
                marginLeft: -5,
                minWidth: Platform.OS === "web" ? 130 : 0,
              }}
            />
          </TouchableWithoutFeedback>
        </View>
        <View
          style={{
            position: "absolute",
            right: 0,
            flexDirection: "row",
            alignItems: "center",
            height: 44,
          }}
        >
          {rightIcon1 && !hideSearch ? (
            <TouchableWithoutFeedback
              onPress={() => onPressRightIcon1 && onPressRightIcon1()}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  flex: 1,
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <PrestoIcon icon={rightIcon1} />
              </View>
            </TouchableWithoutFeedback>
          ) : null}
          {rightIcon2 && showWallet ? (
            <TouchableWithoutFeedback
              onPress={() => onPressRightIcon2 && onPressRightIcon2()}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  marginTop: -10,
                }}
              >
                <PrestoIcon icon={rightIcon2} />
              </View>
            </TouchableWithoutFeedback>
          ) : null}

          {showCartIcon && showCart ? (
            <TouchableWithoutFeedback
              onPress={() => onPressCartIcon && onPressCartIcon()}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  marginTop: -10,
                }}
              >
                <PrestoIcon icon={showCartIcon} />
                {getItemCount() > 0 ? (
                  <View
                    style={{
                      position: "absolute",
                      top: 1,
                      backgroundColor: theme.primary,
                      borderRadius: 50,
                      padding: 1,
                      width: 20,
                      height: 20,
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                      left: 14,
                    }}
                  >
                    <PrestoText
                      fontStyle={"semibold"}
                      color={theme.white}
                      size={10}
                    >
                      {getItemCount()}
                    </PrestoText>
                  </View>
                ) : null}
              </View>
            </TouchableWithoutFeedback>
          ) : null}
        </View>
      </View>
    </Appbar>
  );
}

export default TopHeaderCenterTitleIcon;
