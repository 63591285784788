import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

const SvgComponent = (props) => {
  const { color = "$707070" } = props;
  return (
    <Svg
      width={40}
      height={40}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0h29v29H0z" />
      </Defs>
      <G transform="translate(5 6)" fill="none" fillRule="evenodd">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)" fill={color} fillRule="nonzero">
          <Path d="m14.5 29 2.637-2.637-9.98-9.98H29v-3.766H7.156l9.981-9.98L14.5 0 0 14.5z" />
        </G>
      </G>
    </Svg>
  );
};

export default SvgComponent;
