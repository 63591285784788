import * as React from "react"
import Svg, { G, Rect } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(10 10)"
        fill="#618C03"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Rect x={9} y={0} width={2} height={20} rx={1} />
        <Rect
          transform="rotate(90 10 10)"
          x={9}
          y={0}
          width={2}
          height={20}
          rx={1}
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
