import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width||"40px"}
      height={props.height||"40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M3.12 3.154c4.319-4.205 11.322-4.205 15.64 0a10.571 10.571 0 010 15.231L10.942 26 3.12 18.385a10.854 10.854 0 010-15.23z"
          transform="translate(9 7)"
          stroke={props.stroke||"#707070"}
        />
        <Path
          d="M10.941 15.468a4.714 4.714 0 003.297-1.326 4.469 4.469 0 001.361-3.21c.019-2.54-2.051-4.628-4.658-4.7a4.715 4.715 0 00-3.297 1.326A4.469 4.469 0 006.28 10.77c.042 2.516 2.079 4.57 4.66 4.699z"
          stroke={props.color||"#D97904"}
          strokeWidth={1.5}
          transform="translate(9 7)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
