import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <Path
        d="M39.375 20C39.375 9.297 30.703.625 20 .625S.625 9.297.625 20c0 9.67 7.085 17.686 16.348 19.14V25.6H12.05V20h4.922v-4.269c0-4.855 2.89-7.537 7.318-7.537 2.12 0 4.337.378 4.337.378v4.766h-2.444c-2.406 0-3.157 1.493-3.157 3.025V20h5.374l-.86 5.6h-4.514v13.54C32.29 37.687 39.375 29.67 39.375 20z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
