import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use, Rect, Circle } from "react-native-svg";

function NotificationIconWithDot(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      version="1.1"
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
      </Defs>
      <G
        id="icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <G id="icon/black/notification-new">
          <G id="Alert" transform="translate(5.000000, 5.000000)">
            <Mask id="mask-2" fill="white">
              <Use xlinkHref="#path-1"></Use>
            </Mask>
            <G id="Rectangle"></G>
            <G mask="url(#mask-2)">
              <G transform="translate(-0.017653, 0.000000)">
                <Rect
                  id="Rectangle_479"
                  fill="none"
                  x="0.0176530206"
                  y="0"
                  width="29"
                  height="29"
                ></Rect>
                <Path
                  d="M27.0909655,25.375 L18.0284655,25.375 C18.0284655,27.3770322 16.4054977,29.0000002 14.4034655,29.0000002 C12.4014333,29.0000002 10.7784655,27.3770322 10.7784655,25.375 L1.71596552,25.375 C0.952963894,25.3551681 0.291753023,24.8408929 0.0847155206,24.10625 C-0.133617783,23.3924156 0.0779547627,22.6166496 0.628465521,22.1125 C2.44824923,20.7384659 3.52110847,18.5927474 3.52846552,16.3125 L3.52846552,10.875 C3.52846565,4.86890344 8.39736896,2.29173599e-07 14.4034655,2.29173599e-07 C20.4095621,2.29173599e-07 25.2784654,4.86890344 25.2784655,10.875 L25.2784655,16.3125 C25.2858226,18.5927474 26.3586818,20.7384659 28.1784655,22.1125 C28.7843068,22.5812895 29.0061702,23.3947889 28.7222155,24.10625 C28.515178,24.8408929 27.8539671,25.3551681 27.0909655,25.375 Z"
                  id="Path_1"
                  fill={props.color || "#707070"}
                  fillRule="evenodd"
                ></Path>
              </G>
            </G>
          </G>
          <Circle
            id="Oval"
            fill={props.ovalFillColor || "#FAA85D"}
            cx="25.5"
            cy="6.5"
            r="3.5"
          ></Circle>
        </G>
      </G>
    </Svg>
  );
}

export default NotificationIconWithDot;
