import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M33.84 0c-1.19 0-2.16.993-2.16 2.21v23.58c0 1.217.97 2.21 2.16 2.21 1.19 0 2.16-.993 2.16-2.21V2.21C36 .994 35.03 0 33.84 0zm-3.6 2.095L5.76 9.625v7.414l4.072 1.451-.562 1.52v.023c-.557 1.71.357 3.575 2.025 4.145l2.992 1.036c1.671.57 3.494-.363 4.05-2.073l.473-1.427 11.43 4.075V2.095zM2.16 8.842c-1.19 0-2.16.993-2.16 2.21v4.422c0 1.217.97 2.21 2.16 2.21 1.19 0 2.16-.993 2.16-2.21v-4.421c0-1.218-.97-2.21-2.16-2.21zm9.022 10.132l6.255 2.233-.472 1.45c-.31.956-1.294 1.472-2.227 1.152l-2.993-1.013c-.922-.317-1.398-1.33-1.102-2.28.002-.008-.003-.014 0-.023l.54-1.52z"
        transform="translate(2 6)"
        fill="#707070"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default SvgComponent;
