import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function GuestIcon(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M9.938 0C7.599.052 6.12.986 5.483 2.429c-.606 1.374-.48 3.105-.047 4.904-.232.27-.422.648-.374 1.215v.03c.084.6.267 1.023.499 1.322.113.146.28.15.42.231.078.519.207 1.044.405 1.476.113.246.23.46.359.646.06.086.151.142.218.215v1.768c-.16.378-.54.684-1.137.984-.624.31-1.437.597-2.259.953-.822.355-1.653.795-2.32 1.476-.669.68-1.148 1.604-1.216 2.828L0 21h20l-.031-.523c-.068-1.224-.547-2.148-1.215-2.828-.668-.68-1.511-1.12-2.337-1.476-.825-.356-1.643-.64-2.274-.953-.605-.3-.983-.602-1.152-.984v-1.784c.06-.069.146-.117.202-.2.129-.186.261-.4.374-.645.197-.429.327-.948.405-1.46.16-.081.343-.087.467-.247.24-.309.38-.749.436-1.352.045-.521-.107-.9-.342-1.184.259-.788.566-2.049.467-3.336-.055-.707-.243-1.413-.67-1.999-.385-.53-1.008-.893-1.791-1.06C12.01.273 11.053 0 9.953 0h-.015zm.015.984h.016c.995.004 1.631.28 1.838.63l.124.215.25.031c.685.094 1.06.356 1.34.738.278.383.437.917.482 1.507.092 1.182-.255 2.567-.483 3.197l-.156.415.39.2c-.004.014.16.177.14.538v.016c-.047.463-.167.724-.25.83-.083.107-.126.092-.108.092h-.452l-.047.446c-.042.442-.204.947-.389 1.353-.093.203-.18.384-.265.507-.085.123-.187.194-.14.17l-.25.138V14.358c0-.005-.089.212-.45.4-.363.189-.922.339-1.543.339-.627 0-1.182-.14-1.542-.323-.36-.183-.452-.373-.452-.415a.482.482 0 00-.046-.216v-2.136l-.25-.139c.047.025-.054-.046-.14-.169a3.45 3.45 0 01-.28-.507c-.185-.406-.347-.911-.39-1.353l-.03-.446h-.421a.289.289 0 01-.11-.092c-.087-.114-.212-.379-.28-.83v-.016c-.002-.007.002-.007 0-.015-.008-.373.226-.58.187-.553l.28-.2-.077-.339c-.46-1.792-.532-3.416-.047-4.52C6.887 1.73 7.868 1.035 9.953.985zM7.461 15.28c.16.146.343.267.545.37.538.272 1.225.43 1.994.43.775 0 1.476-.168 2.01-.446.186-.098.363-.208.513-.339.327.339.746.598 1.184.815.723.358 1.546.634 2.321.969.775.334 1.486.713 2.01 1.245.416.425.673.99.81 1.691H1.152c.136-.701.393-1.266.81-1.691.521-.532 1.236-.91 2.009-1.245.773-.335 1.589-.61 2.305-.969.442-.22.863-.484 1.184-.83z"
          transform="translate(2 7) translate(15)"
          fill="#D97904"
        />
        <Path
          d="M10.5 5c-2.059 0-3.533.705-4.51 1.793-.976 1.086-1.46 2.504-1.748 3.926-.289 1.424-.376 2.869-.503 4.029-.063.58-.137 1.09-.239 1.473-.101.383-.236.62-.33.705a.5.5 0 00-.17.373c0 .41.274.738.57.933.3.194.653.32 1.063.432.674.184 1.54.291 2.4.398A.513.513 0 007 19.25v.008c-.178.33-.535.617-1.094.892-.636.31-1.464.588-2.302.946-.838.36-1.692.799-2.373 1.488-.68.69-1.16 1.643-1.23 2.889A.504.504 0 00.5 26H19.5a.504.504 0 00.5-.527c-.07-1.246-.547-2.198-1.223-2.887-.676-.691-1.525-1.135-2.361-1.494-.834-.362-1.66-.643-2.297-.961-.566-.281-.934-.58-1.121-.928a.512.512 0 00-.037-.152c.904-.112 1.808-.254 2.498-.475.416-.133.77-.28 1.056-.46.285-.182.584-.43.584-.866a.5.5 0 00-.164-.37c-.072-.066-.183-.267-.256-.61-.07-.344-.109-.805-.132-1.332-.043-1.055-.028-2.37-.188-3.668-.162-1.3-.5-2.602-1.328-3.61-.773-.947-2.006-1.556-3.709-1.63l-.375-.753A.5.5 0 0010.5 5zm-.283 1.05l.336.673A.5.5 0 0011 7c1.666 0 2.619.512 3.258 1.293.64.781.957 1.895 1.109 3.1.15 1.207.135 2.49.182 3.586.023.548.06 1.052.154 1.496.058.285.213.51.36.742l-.087.056a3.918 3.918 0 01-.822.352c-.73.232-1.758.43-2.8.527a.5.5 0 00-.444.596c.04.2.041.352.041.502 0 .021 0 .043.004.063-.043.068-.137.2-.414.345-.363.192-.918.342-1.541.342-.629 0-1.185-.14-1.547-.326-.236-.121-.35-.242-.406-.328.049-.35.053-.55.053-.696a.499.499 0 00-.442-.496c-1.045-.125-2.058-.265-2.765-.457-.354-.093-.63-.207-.774-.3-.119-.079-.101-.08-.097-.08.174-.258.357-.508.445-.84.125-.475.2-1.022.266-1.62.13-1.199.22-2.607.488-3.939.27-1.332.719-2.574 1.514-3.457.744-.83 1.837-1.344 3.482-1.41zM12.5 20.218c.324.334.736.592 1.172.808.73.364 1.564.645 2.35.983.782.34 1.51.736 2.04 1.275.422.432.684 1.004.82 1.717H1.118c.138-.713.402-1.283.826-1.713.533-.54 1.265-.935 2.052-1.271.787-.336 1.621-.612 2.348-.97.44-.212.853-.466 1.176-.796.142.12.304.225.476.313C8.536 20.84 9.23 21 10 21c.777 0 1.473-.176 2.008-.459.177-.092.345-.201.492-.324z"
          fill="#707070"
          transform="translate(2 7)"
        />
      </G>
    </Svg>
  );
}

export default GuestIcon;
