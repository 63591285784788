import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="35px"
      height="35px"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="rotate(-90 17.5 17.5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          cx={17.5}
          cy={17.5}
          r={17}
          transform="matrix(-1 0 0 1 35 0)"
          fill="#FFF"
          opacity={0.514756702}
          stroke="#E2E2E2"
        />
        <Path
          fill="#8C8C8C"
          transform="rotate(90 20 17)"
          d="M20 10L27 24 13 24z"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
