import React, { useContext } from "react";
import { FlatList, Dimensions, View } from "react-native";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function OnOfferItemsList(props) {
  const { theme } = useContext(ThemeContext);
  const renderItem = (item) => {
    if (!item) {
      return null;
    }

    return (
      <View key={item._id}>
        <ItemCard
          theme={theme}
          item={item}
          getItemPromotions={props.getItemPromotions}
          promotions={props.promotions}
        />
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <FlatList
        data={props.data}
        keyExtractor={(_, index) => "index-" + index}
        renderItem={({ item }) => renderItem(item)}
      />
    </View>
  );
}
