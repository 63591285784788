import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M15.156.005c2.892.035 5.312 2.81 4.767 5.774-.544 2.963-3.003 7.112-9.915 12.103v.013a46.93 46.93 0 01-.008-.007l-.009.007v-.013C3.08 12.891.622 8.742.078 5.78-.468 2.815 1.952.04 4.844.005c1.872-.078 3.594.722 5.165 2.402L10 2.398l.217-.225c1.51-1.52 3.156-2.242 4.94-2.168z"
        transform="translate(-200 -11) translate(200 11)"
        fill={props.color || "#FF6F6F"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default SvgComponent
