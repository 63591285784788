import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={16} height={19} viewBox="0 0 16 19" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M12.571 3.429V0H5.43a.857.857 0 00-.858.857V14a.857.857 0 00.858.857h9.714A.857.857 0 0016 14V3.429h-3.429z"
        />
        <Path d="M3.429 14V3.429H.857A.857.857 0 000 4.286v13.143a.857.857 0 00.857.857h9.714a.857.857 0 00.858-.857V16h-6a2.002 2.002 0 01-2-2zM15.75 2.607L13.393.25a.857.857 0 00-.607-.25h-.215v3.429H16v-.217a.857.857 0 00-.25-.605z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h16v18.286H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
