import React, { useState, useEffect, useContext } from "react";
import { View, FlatList, ScrollView, Platform } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import RadioButtonGroup from "@presto-components/RadioButtons/RadioButtonGroup";
import RadioButtonWithText from "@presto-components/RadioButtons/RadioButtonWithText";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import utils from "../../utils";
import AppConfig from "@presto-common/config";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import RazorpayCheckout from "react-native-razorpay";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { useSafeArea } from "react-native-safe-area-context";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { useAssets } from "presto-react-components";

export default function PaymentsScreen(props) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const insets = useSafeArea();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [paymentOptions, setPaymentOptions] = useState(null);
  const { Navigator } = useContext(NavigatorContext);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  const renderOption = ({ item }) => {
    if (item.card_type) {
      return (
        <View>
          <RadioButtonWithText
            value={item.id}
            text={`${item.card_provider} Card - XXXX XXXX XXXX ${item.masked_card_number}`}
            theme={theme}
          />
          <VerticalSpacing size={10} />
        </View>
      );
    } else {
      return (
        <View>
          <RadioButtonWithText
            value={item.sub_type}
            text={item.display_name}
            icon={<svgs.CardIcon2 width={32} hight={32} />}
            description={item.description}
            theme={theme}
            status={item.sub_type == selectedOption?.sub_type}
          />
          <VerticalSpacing size={10} />
        </View>
      );
    }
  };
  useEffect(() => {
    getSavedCards();
  }, []);

  const getSavedCards = () => {
    setLoading(true);
    let config = AppConfig.credits_payment_config.filter(
      (item) => item.display_name != "EFT"
    );
    console.log("config = ", JSON.stringify(config));
    let payments = {
      title: "Payment options",
      data: config,
    };
    PaymentManager.getSavedCards(
      {},
      (cards) => {
        setLoading(false);
        cards.data.forEach((card) => {
          config.push(card);
        });
        setPaymentOptions(payments);
        setSelectedOption(config[0]);
      },
      (error) => {
        setLoading(false);
        setPaymentOptions(payments);
        setSelectedOption(config[0]);
        console.log("Sorry", error.message);
      }
    );
  };
  const onSelectOption = (value) => {
    let temp = paymentOptions.data.find((ele) => {
      if (ele.sub_type === value || ele.id === value) {
        return ele;
      }
    });
    setSelectedOption(temp);
  };
  const renderFooter = () => {
    return (
      <FlexRowSpaceAligned>
        <View
          style={{
            width: "50%",
            paddingRight: 10,
          }}
        >
          <PrestoSolidButton
            size="extra-large"
            buttonStyle="secondary"
            bgColor="#CCCCCC"
            titleColor={theme.white}
            title={I18n.t("screen_messages.common.cancel_text")}
            onPress={() => {
              goBack();
            }}
          />
        </View>
        <View
          style={{
            width: "50%",
            paddingLeft: 10,
          }}
        >
          <PrestoSolidButton
            size="extra-large"
            buttonStyle="primary"
            titleColor={theme.primaryButtonText}
            onPress={() => {
              handlePaymentOption();
            }}
            title={I18n.t("screen_messages.common.pay")}
          />
        </View>
      </FlexRowSpaceAligned>
    );
  };
  const handleSavedCardPayment = (selectedCard) => {
    setLoading(true);
    const params = {
      saved_card_id: selectedCard.id,
      order_id: props.route.params?.orderId,
    };
    console.log("Payment via Params : ", params);
    PaymentManager.payViaPaygateSavedCard(
      params,
      (response) => {
        setLoading(false);
        console.log("payment via pay gate saved card response : ", response);
        Navigator.emit({ event: "goBack" });
        if (props.route.params?.showCart) {
          Navigator.emit({
            event: "modal",
            params: { screenName: "CART_SCREEN" },
          });
        } else {
          Navigator.emit({
            event: "modal",
            params: {
              screenName: "TOPUP_SUCCESS",
              screenParams: {
                amount: props.route.params?.rechargeAmount,
              },
            },
          });
        }
      },
      (error) => {
        setLoading(false);
        utils.alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error.data)
        );
      }
    );
  };
  const handlePaymentOption = () => {
    console.log(selectedOption);
    if (selectedOption?.provider) {
      handleSavedCardPayment(selectedOption);
    } else {
      handleOnlinePayment();
    }
  };
  const paymentSuccess = (response, payment_data) => {
    console.log("this is success payment", response, payment_data);
    var params = {};
    params.paymentResponse = response;
    params.razorPayUrl = payment_data.razor_post_url;
    PaymentManager.captureRazorPayPaymentV1(
      params,
      (resp) => {
        console.log("resp : ", resp);
        setLoading(false);
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "TOPUP_SUCCESS",
            screenParams: {
              amount: props.route.params?.rechargeAmount,
            },
          },
        });
      },
      (error) => {
        setLoading(false);
        console.log("capture error -", error);
        paymentFail(error);
      }
    );
  };
  const paymentFail = (response) => {
    setLoading(false);
    alert(
      I18n.t("screen_messages.payments.failed_title"),
      I18n.t("screen_messages.payments.failed_description")
    );
    console.log("this is failed", response);
  };
  const handleOnlinePayment = () => {
    var params = {};
    setLoading(true);
    params.id_type = "order_id";
    params.id = props.route.params?.orderId;
    params.user = user;
    params.total_amount = props.route.params?.rechargeAmount;
    PaymentManager.initiateRazorPayPaymentV1(
      params,
      (response) => {
        console.log("Success - ", JSON.stringify(response));
        const data = response.data.payment_response.payment_data;
        var paymentConfig = AppConfig.payment_config.razor_pay;
        const payment_data = response.data.payment_response;
        var options = {
          description: "Payment",
          image: paymentConfig.merchant_logo_path,
          currency: paymentConfig.currency,
          key: paymentConfig.razor_pay_api_key,
          amount: data.total_amount,
          name: paymentConfig.app_name,
          prefill: {
            email: data.user_email,
            contact: data.user_phone_number,
            name: data.user_name,
          },
          theme: { color: paymentConfig.razor_pay_theme_color },
          order_id: data.order_id,
        };
        console.log("options -", options);
        if (Platform.OS === "web") {
          options.handler = (resp) => {
            paymentSuccess(resp, payment_data);
          };
          options.modal = {
            ondismiss: function () {
              setLoading(false);
            },
          };
          var rzp1 = new Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (error) {
            console.log("failed ", error);
            paymentFail(error);
          });
        } else {
          console.log("options -", options);
          RazorpayCheckout.open(options)
            .then((response) => {
              paymentSuccess(response, payment_data);
            })
            .catch((error) => {
              paymentFail(error);
            });
        }
      },
      (error) => {
        console.log("error -", error);
      }
    );
  };

  return (
    <LoadingContainer
      bgColor="transaparent"
      style={{ backgroundColor: theme.appBackgroundColor }}
      loading={loading}
    >
      <ScrollView
        style={{
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 30,
        }}
      >
        <View>
          <View
            style={{
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <VerticalSpacing size={20} />
            <SectionHeader
              fontSize={16}
              leftText={`${I18n.t(
                "screen_messages.payments.select_payment_option"
              )}`}
            />
            <VerticalSpacing size={20} />

            {paymentOptions ? (
              <RadioButtonGroup
                theme={theme}
                value={selectedOption?.sub_type}
                onPress={(value) => {
                  onSelectOption(value);
                }}
              >
                <FlatList
                  data={paymentOptions.data}
                  renderItem={renderOption}
                  keyExtractor={(item, index) => item + index}
                />
              </RadioButtonGroup>
            ) : null}
          </View>
          <LineDivider lineHeight={1} width="100%" dividerColor="#CCCCCC" />
        </View>
      </ScrollView>
      <View
        style={{
          bottom: 30,
          marginHorizontal: theme.primaryPadding,
          height: utils.isDesktop() ? undefined : 120,
        }}
      >
        {renderFooter()}
      </View>
    </LoadingContainer>
  );
}
