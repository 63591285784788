import React, { useContext, useState, useEffect, createRef } from "react";
import { View } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import asyncify from "../../common/Asyncify";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import OffersManager from "@presto-services/features/offers/OffersManager";
const AsyncOfferManager = asyncify(OffersManager);
import WebHeader from "@presto-screen-components/Headers/WebHeader";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import NavigationManager from "../../common/NavigationManager";
import PrestoIcon from "@presto-components/PrestoIcon";
import svgs from "@presto-common-assets/svgs";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";

export default function WebLayout({ children, showCart = true }) {
  const { theme } = useContext(ThemeContext);
  const { user, authState, isUserLoggedIn } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [rollingText, setRollingText] = useState("");
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);

  useEffect(() => {
    getRollingText();
  }, []);

  const getRollingText = async () => {
    const [error, response] = await AsyncOfferManager.getHomePageOffers();
    if (response) {
      setRollingText(response.data.offer_text);
    }
  };

  const onNavigate = (path) => {
    console.log("Path : ", path);
    switch (path) {
      case "ACCOUNT":
        setPaneState(true);
        Navigator.emit({
          event: "modal",
          params: { tabName: "ACCOUNT", screenName: "PROFILE_SCREEN" },
        });
        break;
      case "WALLET_SCREEN":
        setPaneState(true);
        Navigator.emit({
          event: "modal",
          params: { screenName: "WALLET_SCREEN" },
        });
        break;
      case "CART":
      case "LOGIN":
        setPaneState(true);
        Navigator.emit({
          event: "modal",
          params: {
            screenName: path,
          },
        });
        break;
      case "SIGNUP_SCREEN":
        setPaneState(true);
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "SIGNUP_SCREEN",
          },
        });
        break;
      case "MORE":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        break;
    }
  };
  return (
    <>
      <WebHeader onNavigate={onNavigate} showFooterText={true} />
      <View
        style={{
          flex: 1,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 0,
          zIndex: -1,
        }}
      >
        {children}
        <TouchableWithoutFeedback
          onPress={() => {
            setPaneState(false);
          }}
        >
          <View
            style={{
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              backgroundColor: "#00000050",
              position: "absolute",
              display: rightPaneOpen ? "flex" : "none",
            }}
          >
            <TouchableWithoutFeedback onPress={() => {}}>
              <View
                style={{
                  width: 400,
                  alignSelf: "flex-start",
                  position: "absolute",
                  borderRadius: 8,
                  overflow: "hidden",
                  right: 20,
                  top: 10,
                  height: "90%",
                  zIndex: 300,
                  display: rightPaneOpen ? "flex" : "none",
                }}
              >
                {NavigationManager.renderTree(
                  authState === AuthState.userLoggedIn,
                  true
                )}
              </View>
            </TouchableWithoutFeedback>
            <View
              style={{ position: "absolute", right: 430, top: 10, zIndex: 11 }}
            >
              <Pressable
                onPress={() => {
                  setPaneState(false);
                }}
              >
                <View
                  style={{
                    padding: 2,
                    borderRadius: 50,
                    backgroundColor: theme.secondary,
                  }}
                >
                  <PrestoIcon
                    icon={
                      <svgs.CloseIcon
                        color={theme.white}
                        width={35}
                        height={35}
                      />
                    }
                  />
                </View>
              </Pressable>
            </View>
          </View>
        </TouchableWithoutFeedback>
        {isUserLoggedIn() && showCart && <FloatingCartComponent right={250} />}
      </View>
    </>
  );
}
