import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20.136 20.5c1.01 0 1.869.626 2.187 1.5h.904c.427 0 .773.336.773.75s-.346.75-.773.75h-.904a2.316 2.316 0 01-2.187 1.5 2.316 2.316 0 01-2.186-1.5H7.773A.762.762 0 017 22.75c0-.414.346-.75.773-.75H17.95a2.316 2.316 0 012.186-1.5zm-9.272-6.75c1.01 0 1.868.626 2.186 1.5h10.177c.427 0 .773.336.773.75s-.346.75-.773.75H13.05a2.316 2.316 0 01-2.186 1.5 2.316 2.316 0 01-2.187-1.5h-.904A.762.762 0 017 16c0-.414.346-.75.773-.75h.904a2.316 2.316 0 012.187-1.5zM20.136 7c1.01 0 1.869.626 2.187 1.5h.904c.427 0 .773.336.773.75s-.346.75-.773.75h-.904a2.316 2.316 0 01-2.187 1.5A2.316 2.316 0 0117.95 10H7.773A.762.762 0 017 9.25c0-.414.346-.75.773-.75H17.95A2.316 2.316 0 0120.136 7z"
        fill="#333"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  )
}

export default SvgComponent
