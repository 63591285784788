import React from "react";
import { View } from "react-native";

export function HorizontalSpacing(props) {
  return (
    <View
      style={{
        height: props.size,
        backgroundColor: "rgba(0,0,0,0)",
      }}
    ></View>
  );
}

export function RowSpacing(props) {
  return (
    <View
      style={{
        height: "100%",
        width: props.size,
        backgroundColor: "rgba(0,0,0,0)",
      }}
    ></View>
  );
}

export function VerticalSpacing(props) {
  return (
    <View
      style={{
        width: "100%",
        height: props.size,
        backgroundColor: "rgba(0,0,0,0)",
      }}
    ></View>
  );
}
