import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill={props.color || "#fff"}>
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path
            fill={props.color2 || "#707070"}
            fillRule="nonzero"
            d="M14.5 0L19.03125 9.0625 29 10.5125 21.75 17.58125 23.38125 27.55 14.5 22.8375 5.61875 27.55 7.25 17.58125 0 10.5125 9.96875 9.0625z"
          />
          <Path d="M0 0H29V29H0z" />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
