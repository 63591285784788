import * as React from "react"
import Svg, { G, Path, Rect } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(4 4)"
        stroke={props.color || "#707070"}
        strokeWidth={1.25}
        fill="none"
        fillRule="evenodd"
      >
        <Path d="M0 6.52V30a2 2 0 002 2h28a2 2 0 002-2V6.52a2 2 0 00-2-2H2a2 2 0 00-2 2z" />
        <Path d="M17.655 21.702v4.779a1 1 0 001 1h5.724a1 1 0 001-1v-4.779a1 1 0 00-1-1h-5.724a1 1 0 00-1 1z" />
        <G transform="translate(5.517)">
          <Rect
            x={0.625}
            y={0.625}
            width={2.06034483}
            height={7.78841637}
            rx={1.03017241}
          />
          <Rect
            x={17.1767241}
            y={0.625}
            width={2.06034483}
            height={7.78841637}
            rx={1.03017241}
          />
        </G>
        <Path d="M0 11.0296572L31.4991126 11.0296572" />
      </G>
    </Svg>
  )
}

export default SvgComponent