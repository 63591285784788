import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(6 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          stroke={props.color || "#FFF"}
          strokeWidth={1.1475}
          fill={props.color || "#FFF"}
          strokeLinejoin="round"
          d="M14.5 0L29 14.5 14.5 29 0 14.5z"
        />
        <Path
          d="M12.594 19.04H9.63v-6.545c.022-.21.09-.365.202-.467.112-.103.27-.152.473-.147h6.89V8.74l4.76 4.637-4.76 4.517v-2.947h-4.6v4.092z"
          stroke={props.color2 || "#007AFF"}
          strokeWidth={0.5}
          fill={props.color2 || "#007AFF"}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
