import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
function SvgComponent({ width = "22px", height = "22px" }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        transform="translate(-197 -129) translate(198 130)"
        fill="#FFF"
        stroke="#FFF"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Path
          strokeWidth={2}
          strokeLinecap="round"
          d="M0 19.1666667L20 19.1666667"
        />
        <Path
          strokeLinejoin="round"
          d="M0 15L0 3.62408451 4.03248376 9.31204226 10 0 15.9012095 9.31204226 20 3.62408451 20 15z"
        />
      </G>
    </Svg>
  );
}
export default SvgComponent;