import React, { useContext } from "react";
import {
  View,
  ImageBackground,
  StyleSheet,
  Share,
  ScrollView,
  Platform,
  Clipboard,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import TextInputWithButtonComponent from "@presto-components/TextInputs/TextInputWithButtonComponent";
import UserContext from "@presto-contexts/UserContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";

export default function Home({ navigation }) {
  const { images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { user } = useContext(UserContext);
  const referralCode = user.referral_code;
  const shareableLink = I18n.t("screen_messages.refer_url") + referralCode;

  const onShareReferralLink = async () => {
    const referral_link = shareableLink;
    if (Platform.OS != "web") {
      const result = await Share.share({
        message:
          I18n.t("screen_messages.refer_and_earn.description") +
          " " +
          shareableLink,
      });
    } else {
      Clipboard.setString(referral_link);
      alert(I18n.t("screen_messages.common.copied_to_clipboard"));
    }
  };

  return (
    <View style={{ flex: 1, zIndex: 100 }}>
      <ScrollView
        scrollEnabled={true}
        contentContainerStyle={{
          paddingTop: 15,
        }}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: theme.appBackgroundColor,
        }}
      >
        <View style={{ marginHorizontal: theme.containerPadding }}>
          <ImageBackground
            source={images.bannerTwo}
            resizeMode="stretch"
            style={{
              height: 150,
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <View
              style={{
                justifyContent: "flex-start",
                paddingVertical: theme.primaryPadding,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingHorizontal: theme.primaryPadding,
                }}
              >
                <View style={{ height: 88 }}>
                  <View style={{ flex: 1, width: 160 }}>
                    <PrestoText
                      fontStyle="500.medium"
                      color="#FFFFFF"
                      size={16}
                    >
                      {I18n.t("screen_messages.refer_and_earn.referring_offer")}
                    </PrestoText>
                  </View>
                </View>
              </View>
            </View>
          </ImageBackground>

          <View style={{ marginTop: 30 }}>
            <PrestoText
              fontStyle="400.normal"
              color={theme.secondary}
              size={14}
            >
              {I18n.t("screen_messages.refer_and_earn.description")}
            </PrestoText>
          </View>
          <View style={{ marginTop: 15 }}>
            <PrestoText fontStyle="500.medium" color={theme.darkText} size={16}>
              {I18n.t("screen_messages.refer_and_earn.how_this_works")}
            </PrestoText>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: theme.containerPadding,
            }}
          >
            <View
              style={{ flexDirection: "column", justifyContent: "flex-start" }}
            >
              <View
                style={{
                  backgroundColor: theme.secondary,
                  height: 50,
                  width: 50,
                  borderRadius: 100,
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  color={theme.white}
                  size={16}
                  extraStyle={styles.textRoundContainer}
                >
                  1
                </PrestoText>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <View
                  style={{
                    borderStyle: "dashed",
                    borderWidth: 1,
                    height: 20,
                    borderRadius: 1,
                    borderColor: "#979797",
                  }}
                ></View>
              </View>

              <View
                style={{
                  backgroundColor: theme.secondary,
                  height: 50,
                  width: 50,
                  borderRadius: 100,
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  color={theme.white}
                  size={16}
                  extraStyle={styles.textRoundContainer}
                >
                  2
                </PrestoText>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <View
                  style={{
                    borderStyle: "dashed",
                    borderWidth: 1,
                    height: 20,
                    borderRadius: 1,
                    borderColor: "#979797",
                  }}
                ></View>
              </View>
              <View
                style={{
                  backgroundColor: theme.secondary,
                  height: 50,
                  width: 50,
                  borderRadius: 100,
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  color={theme.white}
                  size={16}
                  extraStyle={styles.textRoundContainer}
                >
                  3
                </PrestoText>
              </View>
            </View>

            <View
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                marginLeft: 10,
              }}
            >
              <View style={{ flexDirection: "column", alignContent: "center" }}>
                <PrestoText
                  fontStyle="500.medium"
                  color={theme.darkText}
                  extraStyle={{}}
                >
                  {I18n.t("screen_messages.refer_and_earn.msg_label_1")}
                </PrestoText>
                <PrestoText
                  color="#999999"
                  extraStyle={{
                    marginTop: 7,
                  }}
                >
                  {I18n.t("screen_messages.refer_and_earn.msg_text_1")}
                </PrestoText>
              </View>

              <View
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                  marginTop: 30,
                }}
              >
                <PrestoText
                  fontStyle="500.medium"
                  color={theme.darkText}
                  extraStyle={{}}
                >
                  {I18n.t("screen_messages.refer_and_earn.msg_label_2")}
                </PrestoText>
                <PrestoText
                  color="#999999"
                  fontStyle="400.normal"
                  extraStyle={{
                    marginTop: 7,
                  }}
                >
                  {I18n.t("screen_messages.refer_and_earn.msg_text_2")}
                </PrestoText>
              </View>

              <View
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                  marginTop: 30,
                }}
              >
                <PrestoText
                  fontStyle="500.medium"
                  color={theme.darkText}
                  extraStyle={{}}
                >
                  {I18n.t("screen_messages.refer_and_earn.msg_label_3")}
                </PrestoText>
                <PrestoText
                  fontStyle="400.normal"
                  color="#999999"
                  extraStyle={{
                    marginTop: 7,
                  }}
                >
                  {I18n.t("screen_messages.refer_and_earn.msg_text_3")}
                </PrestoText>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 40, marginHorizontal: 20 }}>
          <TextInputWithButtonComponent
            labelText=""
            theme={theme}
            value={shareableLink}
            autoCapitalize="words"
            buttonText={Platform.OS === "web" ? "Copy" : "Share"}
            onPressButton={onShareReferralLink}
            autoCorrect={false}
            editable={false}
            placeholderTextcolor={theme.darkText}
            textInputStyle={{ color: theme.darkText }}
          />

          <VerticalSpacing size={10} />
          <LineDivider lineHeight={1} width="100%" dividerColor="#F7F7F7" />
        </View>
      </ScrollView>
    </View>
  );
}

function getStyles(theme) {
  return {
    textRoundContainer: { position: "absolute", left: 20, top: 14 },
  };
}
