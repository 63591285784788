import * as React from "react";
import Svg, { Rect } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect
        x={19}
        y={10}
        width={2}
        height={20}
        rx={1}
        fill={props.primaryColor || "#000"}
      />
      <Rect
        x={30}
        y={19}
        width={2}
        height={20}
        rx={1}
        transform="rotate(90 30 19)"
        fill={props.primaryColor || "#000"}
      />
    </Svg>
  );
}

export default SvgComponent;
