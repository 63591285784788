import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      className="prefix__footer-active"
      width={27}
      height={18}
      viewBox="0 0 27 22"
      {...props}
      fill={props.primaryColor || "#FFCF31"}
    >
      <G fillRule="nonzero">
        <Path
          d="M15.75 20.73v-4.483a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v4.487a.75.75 0 01-.746.75l-5.254.013a.75.75 0 01-.75-.75v-7.682l8.643-7.118a.571.571 0 01.717 0l8.64 7.113v7.687a.75.75 0 01-.75.75l-5.25-.014a.75.75 0 01-.75-.753z"
          opacity={0.4}
        />
        <Path d="M24.887 13.106L13.86 4.025a.571.571 0 00-.717 0L2.117 13.106a.563.563 0 01-.792-.076L.13 11.575a.563.563 0 01.076-.791L12.07 1.013a2.25 2.25 0 012.859 0l11.865 9.772a.565.565 0 01.078.791l-1.195 1.454a.562.562 0 01-.79.076z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
