import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import TextTicker from "react-native-text-ticker";

export default function Marquee(props) {
  const { textStyles = {}, alignItems } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        backgroundColor: props?.bgColor || theme.primary,
        color: theme.white,
        padding: 10,
        paddingHorizontal: props.paddingHorizontal || 10,
        width: props?.width,
        alignItems: alignItems,
      }}
    >
      <TextTicker
        style={{
          fontSize: 16,
          fontWeight: props.fontWeight || "400",
          fontFamily: theme.primaryFont,
          backgroundColor: props?.bgColor || theme.primary,
          color: theme.white,
          ...textStyles,
        }}
        duration={10000}
        loop
        bounce={false}
        repeatSpacer={50}
        shouldAnimateTreshold={40}
        marqueeDelay={0}
      >
        {props.text}
      </TextTicker>
    </View>
  );
}
