import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function UnSelectedCheckbox(props) {
  return (
    <Svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M28.444 2.667H3.556a.9.9 0 00-.89.889v24.888a.9.9 0 00.89.89h24.888a.9.9 0 00.89-.89V3.556a.9.9 0 00-.89-.89zm0-2.667C30.4 0 32 1.6 32 3.556v24.888C32 30.4 30.4 32 28.444 32H3.556A3.566 3.566 0 010 28.444V3.556C0 1.6 1.6 0 3.556 0h24.888z"
        fill="#000"
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default UnSelectedCheckbox;
