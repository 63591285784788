import React, { useContext, createRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import * as Linking from "expo-linking";
import config from "@presto-common/config";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { PrestoTextButton } from "@presto-components/PrestoButton";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useAssets } from "presto-react-components";
import utils from "../../utils/index";

export default function Footer({
  ScrollToTop,
  horizonalPadding = "7%",
  marginTopDownloadButton = 10,
  showFooterText,
  showWhatsappIcon = true,
}) {
  const isDesktop = utils.isDesktop();
  const { images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyle(theme, horizonalPadding, isDesktop);
  const scroll = createRef();

  const gotoAppleStore = () => {
    Platform.OS === "web"
      ? window.open(config.constants.goto_applestore_url, "_blank")
      : Linking.openURL(config.constants.goto_applestore_url);
  };

  const gotoPlayStore = () => {
    Platform.OS === "web"
      ? window.open(config.constants.goto_playstore_url, "_blank")
      : Linking.openURL(config.constants.goto_playstore_url);
  };

  const DummyLink = () => {
    Platform.OS === "web"
      ? window.open("https//www.google.com", "_blank")
      : Linking.openURL(config.constants.goto_playstore_url);
  };

  const GotoPresto = () => {
    Platform.OS === "web"
      ? window.open(config.constants.presto_website, "_blank")
      : Linking.openURL(config.constants.presto_website);
  };
  const GotoFarmTheory = () => {
    Platform.OS === "web"
      ? window.open(config.constants.farmtheory_in, "_blank")
      : Linking.openURL(config.constants.farmtheory_in);
  };
  const GotoAboutUs = () => {
    Platform.OS === "web"
      ? window.open(config.constants.about_us, "_blank")
      : Linking.openURL(config.constants.about_us);
  };
  const GotoPrivacyPolicy = () => {
    Platform.OS === "web"
      ? window.open(config.constants.privacy_policy, "_blank")
      : Linking.openURL(config.constants.privacy_policy);
  };
  const GotoTermsConditions = () => {
    Platform.OS === "web"
      ? window.open(config.constants.terms_conditions, "_blank")
      : Linking.openURL(config.constants.terms_conditions);
  };
  const onClickPhoneNumber = () => {
    Platform.OS === "web"
      ? window.open(`tel:${I18n.t("screen_messages.contact.phone_number")}`)
      : Linking.openURL(
          `tel:${I18n.t("screen_messages.contact.phone_number")}`
        );
  };

  const onClckMail = () => {
    Platform.OS === "web"
      ? window.open(`mailto:${I18n.t("screen_messages.contact.email")}`)
      : Linking.openURL(`mailto:${I18n.t("screen_messages.contact.email")}`);
  };
  const onClickWhatsapp = () => {
    Platform.OS === "web"
      ? window.open(
          `https://wa.me/${I18n.t("screen_messages.contact.phone_number")}`
        )
      : Linking.openURL(
          `https://wa.me/${I18n.t("screen_messages.contact.phone_number")}`
        );
  };
  const gotoAboutFarmtheory = () => {
    Navigator.emit({
      event: "modal",
      params: {
        nextUrl: `/about`,
        screenName: "aboutus",
      },
    });
  };
  return (
    <View style={styles.Container}>
      <View style={styles.SubContainer}>
        <View style={styles.SubContainer}>
          {/* Explore */}
          {!isDesktop ? (
            <View style={{ paddingBottom: 25 }}>
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => ScrollToTop()}
              >
                <Image
                  source={images.logo_transparent}
                  style={styles.LogoImage}
                  resizeMode="cover"
                />
              </TouchableOpacity>
            </View>
          ) : null}
          <View>
            <PrestoText fontStyle="medium" size={14} color={theme.darkText}>
              {I18n.t("screen_messages.common.explore_our_app")}
            </PrestoText>
            <View
              style={{
                flexDirection: "row",
                marginTop: marginTopDownloadButton,
              }}
            >
              <TouchableOpacity
                onPress={() => gotoAppleStore()}
                activeOpacity={0.5}
              >
                <Image
                  source={images.App_store_symbol}
                  style={styles.ExploreImage}
                  resizeMode="cover"
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => gotoPlayStore()}
                activeOpacity={0.5}
              >
                <Image
                  source={images.Google_play_symbol}
                  style={styles.ExploreImage}
                  resizeMode="cover"
                />
              </TouchableOpacity>
            </View>
          </View>
          {/* Information */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: isDesktop ? undefined : "space-between",
              paddingRight: isDesktop ? undefined : 20,
              paddingLeft: isDesktop ? 45 : undefined,
              paddingVertical: isDesktop ? undefined : 20,
            }}
          >
            <View style={{ marginLeft: isDesktop ? "10%" : undefined }}>
              <PrestoText
                fontStyle="500.medium"
                size={14}
                color={theme.darkText}
                extraStyle={{ marginBottom: 15 }}
              >
                {I18n.t("screen_messages.common.information")}
              </PrestoText>
              <View style={{ alignItems: "flex-start" }}>
                <PrestoTextButton
                  title={I18n.t("screen_messages.common.about_us")}
                  titleColor={theme.paragraph}
                  titleSize={14}
                  onPress={() => gotoAboutFarmtheory()}
                  titleFontStyle="400.normal"
                  titleExtraStyle={{ paddingBottom: 5 }}
                />
                <PrestoTextButton
                  title={I18n.t("screen_messages.privacy_policy.title")}
                  titleColor={theme.paragraph}
                  titleSize={14}
                  onPress={() => GotoPrivacyPolicy()}
                  titleFontStyle="400.normal"
                  titleExtraStyle={{ paddingBottom: 5 }}
                />
                <PrestoTextButton
                  title={I18n.t("screen_messages.common.terms_and_conditions")}
                  titleColor={theme.paragraph}
                  titleSize={14}
                  onPress={() => GotoTermsConditions()}
                  titleFontStyle="400.normal"
                  titleExtraStyle={{ paddingBottom: 5 }}
                />
              </View>
            </View>
            {/* Contact Us */}
            <View
              style={{
                marginLeft: isDesktop ? "10%" : undefined,
                paddingLeft: isDesktop ? 50 : undefined,
              }}
            >
              <PrestoText
                fontStyle="500.medium"
                size={14}
                color={theme.darkText}
                extraStyle={{ marginBottom: 15 }}
              >
                {I18n.t("screen_messages.contact.connect_with_us")}
              </PrestoText>
              <View
                style={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <PrestoTextButton
                  title={I18n.t("screen_messages.contact.phone_number")}
                  titleColor={theme.paragraph}
                  titleSize={14}
                  onPress={() => onClickPhoneNumber()}
                  titleFontStyle="400.normal"
                  titleExtraStyle={{ paddingBottom: 5 }}
                />
                <PrestoTextButton
                  title={I18n.t("screen_messages.contact.email")}
                  titleColor={theme.paragraph}
                  titleSize={14}
                  onPress={() => onClckMail()}
                  titleFontStyle="400.normal"
                  titleExtraStyle={{ paddingBottom: 5 }}
                />
                {showWhatsappIcon ? (
                  <View style={{ flexDirection: "row", marginRight: 5 }}>
                    <TouchableOpacity
                      activeOpacity={0.5}
                      onPress={() => onClickWhatsapp()}
                    >
                      <Image
                        source={images.whatsappIcon}
                        style={styles.SocialIcons}
                        resizeMode="cover"
                      />
                    </TouchableOpacity>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
        {/* Logo */}
        {isDesktop ? (
          <View>
            <TouchableOpacity activeOpacity={0.5} onPress={() => ScrollToTop()}>
              <Image
                source={images.logo_transparent}
                style={styles.LogoImage}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
      <LineDivider
        lineHeight={1}
        width="100%"
        dividerColor={theme.lightText}
        paddingVertical={15}
      />
      <View style={styles.BottomContainer}>
        <View style={{ flexDirection: "row" }}>
          <PrestoText
            color={theme.paragraph}
            size={14}
            fontStyle="400.normal"
            extraStyle={{ paddingBottom: isDesktop ? undefined : 5 }}
          >{`${"\u00A9"} 2024, ${config.applicationName}`}</PrestoText>
          {showFooterText ? (
            <PrestoText
              color={theme.tertiary}
              size={14}
              fontStyle="500.medium"
              extraStyle={{
                paddingBottom: isDesktop ? undefined : 5,
                marginLeft: 20,
              }}
            >
              {I18n.t("screen_messages.footer_text")}
            </PrestoText>
          ) : null}
        </View>

        <TouchableOpacity activeOpacity={0.5} onPress={() => GotoPresto()}>
          <Image
            source={images.PoweredByPrestoIcon}
            style={styles.PoweredByImage}
            resizeMode="cover"
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

function getStyle(theme, horizonalPadding, isDesktop) {
  return {
    ExploreImage: {
      height: 35,
      width: isDesktop ? 130 : 109,
      marginRight: 10,
      borderRadius: 8,
    },
    LogoImage: {
      height: isDesktop ? 50 : 40,
      width: isDesktop ? 160 : 125,
    },
    PoweredByImage: {
      height: 18,
      width: 135,
    },
    SocialIcons: {
      height: 25,
      width: 25,
      marginRight: 7,
    },
    Container: {
      backgroundColor: "#E5E5E5",
      paddingHorizontal: horizonalPadding,
      paddingVertical: 30,
    },
    SubContainer: {
      flexDirection: isDesktop ? "row" : "column",
      flex: 1,
    },
    BottomContainer: {
      flexDirection: isDesktop ? "row" : "column",
      alignItems: isDesktop ? "center" : "flex-start",
      justifyContent: "space-between",
    },
  };
}
