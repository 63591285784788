import React, { useState, useEffect } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import CheckBox from "@presto-components/CheckBoxes/CheckBox";
import PrestoIcon from "@presto-components/PrestoIcon";

export default function CheckBoxWithText(props) {
  const [state, setstate] = useState(props.state);
  const {
    text,
    theme,
    onPress,
    fontStyle = "medium",
    fontSize = 14,
    checkBoxUnSelectedColor = props.checkBoxUnSelectedColor || theme.primary,
    borderColorCheckedIcon = props.checkBoxUnSelectedColor || theme.primary,
    fontColor = theme.dark,
    defaultTickedColor = props.defaultTickedColor ?? theme.defaultTickedColor,
    icon,
    reverse = false,
    checkBoxSize = 32,
    leftSideContainerStyles = {},
    price,
    disabled,
  } = props;

  const onClick = () => {
    if (onPress) {
      onPress(!state);
    } else {
      setstate(!state);
    }
  };
  useEffect(() => {
    setstate(props.state);
  }, [props.state]);
  const styles = getStyles(theme, props);

  const TextComponent = () => (
    <View style={{ flexDirection: "row" }}>
      {icon ? (
        <View style={{ paddingRight: 10 }}>
          <PrestoIcon icon={icon} />
        </View>
      ) : null}

      <PrestoText
        size={fontSize}
        color={fontColor}
        fontStyle={fontStyle}
        extraStyle={styles.textStyle}
      >
        {text}
      </PrestoText>
    </View>
  );

  const IconComponent = () => (
    <View style={{ paddingRight: reverse ? 10 : 0 }}>
      <CheckBox
        state={state}
        theme={theme}
        color={defaultTickedColor}
        checkBoxUnSelectedColor={checkBoxUnSelectedColor}
        borderColorCheckedIcon={borderColorCheckedIcon}
        checkBoxHeight={checkBoxSize}
        onPress={() => onClick()}
        disabled={disabled}
      />
    </View>
  );

  let components = [TextComponent, IconComponent];

  if (reverse) {
    components.reverse();
  }

  return (
    <Pressable
      onPress={() => onClick()}
      style={[
        { paddingHorizontal: props.paddingHorizontal || 0 },
        styles.container,
      ]}
    >
      <View style={{ flexDirection: "row", ...leftSideContainerStyles }}>
        {icon ? (
          <View style={{ paddingRight: 10 }}>
            <PrestoIcon icon={icon} />
          </View>
        ) : null}

        <PrestoText
          size={fontSize}
          color={fontColor}
          fontStyle={fontStyle}
          extraStyle={styles.textStyle}
        >
          {text}
        </PrestoText>
      </View>

      <View style={{ flexDirection: "row" }}>
        {price ? (
          <PrestoText
            fontStyle="600.semibold"
            size={14}
            color={theme.black}
            extraStyle={{ paddingHorizontal: 10, paddingTop: 23 }}
          >
            {"₹ " + price}
          </PrestoText>
        ) : null}
        <CheckBox
          state={state}
          theme={theme}
          color={defaultTickedColor}
          checkBoxUnSelectedColor={checkBoxUnSelectedColor}
          borderColorCheckedIcon={borderColorCheckedIcon}
          checkBoxHeight={32}
          onPress={() => onClick()}
        />
      </View>
    </Pressable>
  );
}

function getStyles(theme, { containerStyles }) {
  return {
    textStyle: {
      letterSpacing: 0.4,
    },
    container: {
      borderBottomWidth: 1,
      borderColor: "#e3e3e3",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      ...containerStyles,
    },
  };
}
