import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import StatementDownloadButton from "@presto-cards/StatementCard/StatementDownloadButton";
import moment from "moment";
import I18n from "i18n-js";

export default function StatementCard({ item, onPressGenerate, setLoading }) {
  console.log("item===>", item);
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);

  const range = `${moment(item.start_time).format("DD/MM/YYYY")} to ${moment(
    item.end_time
  ).format("DD/MM/YYYY")}`;

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={{ width: "70%" }}>
          <View>
            <PrestoText fontStyle="500.medium" color={theme.dark}>
              {range}
            </PrestoText>
          </View>

          <View style={{ marginTop: 10 }}>
            <PrestoText fontStyle="400.normal" color={theme.subHeader}>
              {I18n.t("screen_messages.general_statement.requested_on", {
                date: moment(item.created_at).format("DD/MM/YYYY"),
              })}
            </PrestoText>
          </View>
        </View>

        <View style={{ width: "30%" }}>
          <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
            {item.state === "completed" ? (
              <View>
                <StatementDownloadButton
                  item={item}
                  onPressGenerate={onPressGenerate}
                  setLoading={setLoading}
                />
              </View>
            ) : (
              <View>
                <PrestoText
                  fontStyle="500.medium"
                  color={
                    item.state === "failed"
                      ? theme.primary
                      : "rgba(0, 150, 79, 0.81)"
                  }
                  size={16}
                >
                  {item.state}
                </PrestoText>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return StyleSheet.create({
    container: {
      backgroundColor: "#EDEDED",
      padding: theme.primaryPadding,
      borderRadius: 8,
      ...theme.lightShadow,
    },
    row: {
      flexDirection: "row",
      width: "100%",
    },
  });
}
