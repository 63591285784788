import * as React from "react";
import Svg, { G, Rect, Path, Circle } from "react-native-svg";

function SvgComponent({ width = 40, height = 40 }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G stroke="#333" strokeWidth={1} fill="none" fillRule="evenodd">
        <G strokeWidth={1.5} transform="translate(4 6)">
          <Rect x={0.75} y={3.75} width={30.5} height={23.5} rx={4} />
          <G strokeLinecap="round" strokeLinejoin="round">
            <Path d="M.5 0v8.775M10.5 0v8.775" transform="translate(10.016)" />
          </G>
          <Path d="M1.75 11.75H30.25V12.75H1.75z" />
        </G>
        <G transform="translate(4 6) translate(5 17)" fill="#333">
          <Circle cx={1} cy={1} r={1} />
          <Circle cx={7} cy={1} r={1} />
          <Circle cx={14} cy={1} r={1} />
          <Circle cx={21} cy={1} r={1} />
          <Circle cx={1} cy={5} r={1} />
          <Circle cx={7} cy={5} r={1} />
          <Circle cx={14} cy={5} r={1} />
          <Circle cx={21} cy={5} r={1} />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
