import * as React from "react"
import Svg, { Rect } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect
        x={26.95}
        y={21.45}
        width={3.3}
        height={19.8}
        rx={1.397}
        transform="rotate(90 26.95 21.45)"
        stroke={props.primaryColor || "#000"}
        strokeWidth={1.397}
      />
      <Rect
        x={36.85}
        y={21.45}
        width={3.3}
        height={6.6}
        rx={1.397}
        transform="rotate(90 36.85 21.45)"
        stroke={props.primaryColor || "#000"}
        strokeWidth={1.397}
      />
      <Rect
        x={26.95}
        y={32.45}
        width={3.3}
        height={19.8}
        rx={1.397}
        transform="rotate(90 26.95 32.45)"
        stroke={props.primaryColor || "#000"}
        strokeWidth={1.397}
      />
      <Rect
        x={36.85}
        y={32.45}
        width={3.3}
        height={6.6}
        rx={1.397}
        transform="rotate(90 36.85 32.45)"
        stroke={props.primaryColor || "#000"}
        strokeWidth={1.397}
      />
      <Rect
        x={26.95}
        y={10.45}
        width={3.3}
        height={19.8}
        rx={1.397}
        transform="rotate(90 26.95 10.45)"
        stroke={props.primaryColor || "#000"}
        strokeWidth={1.397}
      />
      <Rect
        x={36.85}
        y={10.45}
        width={3.3}
        height={6.6}
        rx={1.397}
        transform="rotate(90 36.85 10.45)"
        stroke={props.primaryColor || "#000"}
        strokeWidth={1.397}
      />
    </Svg>
  )
}

export default SvgComponent
