import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import StylesContext from "@presto-contexts/StylesContext";
import UserContext from "@presto-contexts/UserContext";
import TopDrawerHeaderWithLogoComponent from "@presto-components/Headers/TopDrawerHeaderWithLogo";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useAssets } from "presto-react-components";
import RightPaneContext from "@presto-contexts/RightPaneContext";

export default function TopDrawerHeaderWithLogo({
  scene,
  previous,
  navigation,
  title,
  onBurgerClick,
  hideSearch = false,
  hideCart = false,
}) {
  const { svgs } = useAssets();
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const { appStyles } = useContext(StylesContext);
  const { user, authState, currentAddress } = useContext(UserContext);
  const { setPaneState } = useContext(RightPaneContext);
  const onHamburgerClick = () => {
    if (onBurgerClick) {
      onBurgerClick();
    } else {
      Navigator.emit({
        event: "toggleDrawer",
      });
    }
  };

  const goToSearch = () => {
    Navigator.emit({
      event: "modal",
      params: {
        nextUrl: "/search",
        screenName: "SEARCH",
      },
    });
  };
  const goToWallet = () => {
    if (!user) {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "LOGIN",
        },
      });
      return;
    }
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "WALLET_SCREEN",
      },
    });
  };

  const onOrder = () => {
    if (!user) {
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/login",
          screenName: "LOGIN",
        },
      });
      return;
    } else {
      setPaneState(true);
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/cart",
          screenName: "CART",
        },
      });
    }
  };

  return (
    <TopDrawerHeaderWithLogoComponent
      appStyles={appStyles}
      theme={theme}
      onHamburgerClick={onHamburgerClick}
      rightIcon2={!hideCart ? <svgs.CartIcon1 width="40" height="36" /> : null}
      rightIcon1={
        !hideSearch ? <svgs.SearchIconV2 width="36" height="36" /> : null
      }
      onPressRightIcon2={onOrder}
      onPressRightIcon1={goToSearch}
    />
  );
}
