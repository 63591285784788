import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="nonzero">
        <Path
          d="M21.747 6.592l.15.124 13.856 12.316a.735.735 0 01-.881 1.172l-.096-.072-3.296-2.93v14.787a2.837 2.837 0 01-2.656 2.83l-.18.007h-2.97a2.837 2.837 0 01-2.831-2.657l-.006-.18v-5.796c0-.707-.537-1.289-1.226-1.358l-.14-.008h-2.938c-.707 0-1.289.538-1.358 1.227l-.008.14v5.795a2.837 2.837 0 01-2.657 2.83l-.179.007h-2.976a2.837 2.837 0 01-2.831-2.657l-.006-.18V17.208l-3.295 2.924a.735.735 0 01-1.059-1.014l.083-.086L18.13 6.715a2.837 2.837 0 013.617-.123zm-2.526 1.131l-.115.092-9.118 8.089v16.085c0 .707.538 1.289 1.227 1.359l.14.007h2.976c.707 0 1.289-.538 1.359-1.226l.007-.14v-5.796a2.837 2.837 0 012.657-2.83l.18-.006h2.938a2.837 2.837 0 012.83 2.657l.006.18v5.795c0 .707.538 1.289 1.226 1.359l.14.007h2.97c.708 0 1.29-.538 1.36-1.226l.006-.14V15.895l-9.09-8.08a1.366 1.366 0 00-1.699-.092z"
          fill={props.color || "#333"}
        />
        <Path
          d="M21.28 32a.757.757 0 01.102 1.507l-.102.007h-2.523a.757.757 0 01-.103-1.507l.103-.007h2.523zM27.28 18a.757.757 0 01.102 1.507l-.102.007h-2.523a.757.757 0 01-.103-1.507l.103-.007h2.523zM15.28 18a.757.757 0 01.102 1.507l-.102.007h-2.523a.757.757 0 01-.103-1.507l.103-.007h2.523z"
          fill={props.color || "#000"}
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
