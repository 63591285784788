import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function HamburgerIcon(props) {
  return (
    <Svg
    width="47.0000002px"
    height="33.103685px"
    viewBox="0 0 47.0000002 33.103685"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G
      transform="translate(1.5 1)"
      stroke="#000"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <Path d="M0 0.5L44 0.5" />
      <Path d="M0 15.5L34 15.6036848" />
      <Path d="M0 30.5L27 30.6036848" />
    </G>
  </Svg>
  );
}

export default HamburgerIcon;
