import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm-.469-5.469c8.385 0 14.063-5.9 14.063-14.191 0-.967-.094-1.682-.229-2.426H19.531v4.998h8.25c-.34 2.139-2.496 6.264-8.25 6.264-4.957 0-9.006-4.108-9.006-9.176 0-8.168 9.621-11.918 14.795-6.926l3.955-3.803c-2.537-2.367-5.83-3.802-9.744-3.802A14.514 14.514 0 005 20c0 8.04 6.492 14.531 14.531 14.531z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
