import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={27} viewBox="0 0 18 27" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.9}
          d="M15.181.442H3.373a2.56 2.56 0 00-1.79.725 2.448 2.448 0 00-.74 1.75V24.37c0 .657.266 1.287.74 1.75a2.56 2.56 0 001.79.726H15.18a2.56 2.56 0 001.79-.725 2.449 2.449 0 00.74-1.75V2.916c0-.656-.266-1.286-.74-1.75a2.56 2.56 0 00-1.79-.725zm-3.374 23.31a.612.612 0 01-.185.437.64.64 0 01-.447.181H7.379a.64.64 0 01-.447-.18.612.612 0 01-.185-.438v-.413c0-.164.066-.322.185-.438a.64.64 0 01.447-.18h3.796a.64.64 0 01.447.18.612.612 0 01.185.438v.413zm3.374-4.126a.612.612 0 01-.185.438.64.64 0 01-.447.18H4.005a.64.64 0 01-.447-.18.612.612 0 01-.185-.438V3.536c0-.164.066-.322.185-.438a.64.64 0 01.447-.18H14.55a.64.64 0 01.447.18.612.612 0 01.185.438v16.09z"
        />
        <Path
          opacity={0.4}
          d="M15.181 19.626a.612.612 0 01-.185.438.64.64 0 01-.447.18H4.006a.64.64 0 01-.448-.18.612.612 0 01-.185-.438V3.536c0-.164.067-.322.185-.438a.64.64 0 01.448-.18h10.543a.64.64 0 01.447.18.612.612 0 01.185.438v16.09z"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill={props.secondaryColor || "#FFF"}
            transform="translate(.842 .442)"
            d="M0 0h16.869v26.404H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
