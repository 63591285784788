import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import I18n from "i18n-js";
import PrestoSdk from "@presto-services/global/PrestoSdk";

export default function StatementDownloadButton({
  item,
  onPressGenerate,
  setLoading,
}) {
  const { theme } = useContext(ThemeContext);

  const download = () => {
    setLoading(true);
    const url =
      PrestoSdk.getHostName() +
      `/user/v1/ledger_tasks/${item.id}/download_csv.json`;

    window.open(url, "_self");
    onPressGenerate(null);
  };

  return (
    <PrestoFramedButton
      buttonStyle="primary"
      size={"large"}
      bgColor={"rgba(253,91,83,0.1)"}
      titleColor={theme.primary}
      borderWidth={1}
      title={I18n.t("screen_messages.common.download")}
      onPress={download}
      titleExtraStyle={{ padding: 10 }}
    />
  );
}
