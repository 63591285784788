import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={props.width||"40px"}
      height={props.height||"40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(8 7)"
        strokeWidth={1}
        stroke="none"
        fill="none"
        fillRule="evenodd"
      >
        <Circle stroke={props.stroke||"#D97904"} cx={12.5} cy={6.25} r={6.25} />
        <Path
          d="M12.5 15.625c-6.875 0-12.5 2.813-12.5 6.25V25h25v-3.125c0-3.438-5.625-6.25-12.5-6.25z"
          stroke={props.color||"#707070"}
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
