import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07 14.08h25.11l2.45 22.52a2.97 2.97 0 01-2.953 3.292H9.57A2.97 2.97 0 016.617 36.6L9.07 14.08zm23.728 1.539H10.45L8.148 36.767l-.008.155c0 .79.64 1.43 1.43 1.43h24.107a1.43 1.43 0 001.422-1.585L32.797 15.62z"
        fill={props.primaryColor || "#000"}
      />
      <Path
        d="M32.072 29.7c.332 0 .601.246.601.55 0 .279-.226.509-.52.545l-.081.005H11.275c-.332 0-.602-.246-.602-.55 0-.278.227-.509.52-.545l.082-.005h20.797zM21.673 4.4c5.716 0 9.23 3.463 10.44 10.144.071.399-.214.777-.637.845-.424.069-.826-.2-.898-.599-1.092-6.031-4.027-8.924-8.905-8.924-4.878 0-7.812 2.893-8.904 8.924-.072.4-.475.668-.898.6-.424-.069-.709-.447-.636-.846C12.444 7.864 15.957 4.4 21.672 4.4z"
        fill={props.primaryColor || "#000"}
      />
    </Svg>
  )
}

export default SvgComponent
