import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fill={props.color || "#707070"}
        stroke={props.color || "#707070"}
        strokeWidth={1}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.71958923 11.1757336L26.8256149 11.1757336 16 21.8052444z"
      />
    </Svg>
  );
}
