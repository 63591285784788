import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function PrePaidIcon(props) {
  return (
    <Svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <G
        fill={props.color || "#333333"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Path
          d="M22.716 18.42H9.977c-.276 0-.5-.22-.5-.491 0-.272.224-.492.5-.492h12.238V8.548H9.977c-.276 0-.5-.22-.5-.49 0-.272.224-.493.5-.493h12.739c.276 0 .5.22.5.492v9.872c0 .27-.224.49-.5.49"
          transform="translate(7 6)"
        />
        <Path
          d="M13.163 16.493a.502.502 0 01-.262-.073.487.487 0 01-.163-.676c.496-.791.759-1.743.759-2.751 0-1.009-.263-1.96-.76-2.752a.486.486 0 01.164-.676.507.507 0 01.689.161c.594.946.907 2.076.907 3.267 0 1.19-.313 2.32-.907 3.266a.502.502 0 01-.427.234M9.977 11.517c-.276 0-.5-.22-.5-.49v-.398c0-.272.224-.492.5-.492.277 0 .5.22.5.492v.397a.495.495 0 01-.5.491"
          transform="translate(7 6)"
        />
        <Path
          d="M9.977 15.45c-.867 0-1.573-.661-1.573-1.474 0-.271.224-.49.5-.49s.5.219.5.49.257.492.573.492c.317 0 .573-.221.573-.492s-.256-.492-.573-.492c-.867 0-1.573-.662-1.573-1.474 0-.813.706-1.475 1.573-1.475.868 0 1.574.662 1.574 1.475 0 .27-.225.49-.5.49-.277 0-.501-.22-.501-.49 0-.272-.256-.493-.573-.493-.316 0-.573.221-.573.493 0 .27.257.492.573.492.868 0 1.574.66 1.574 1.474 0 .813-.706 1.475-1.574 1.475"
          transform="translate(7 6)"
        />
        <Path
          d="M9.977 15.902c-.276 0-.5-.22-.5-.492v-.45c0-.272.224-.492.5-.492.277 0 .5.22.5.492v.45a.496.496 0 01-.5.492M18.219 13.484h-1.873c-.276 0-.5-.22-.5-.491 0-.272.224-.491.5-.491h1.873c.275 0 .499.22.499.49a.496.496 0 01-.5.492"
          transform="translate(7 6)"
        />
        <Path
          d="M19.349 16.493h-3.334c-.276 0-.5-.22-.5-.492 0-.27.224-.49.5-.49h2.912c.161-.49.556-.878 1.054-1.037v-2.962a1.63 1.63 0 01-1.054-1.037h-2.912c-.276 0-.5-.22-.5-.49 0-.272.224-.492.5-.492h3.334c.276 0 .5.22.5.491 0 .343.284.622.633.622.277 0 .5.22.5.49v3.792a.495.495 0 01-.5.491c-.349 0-.633.28-.633.622a.496.496 0 01-.5.492"
          transform="translate(7 6)"
        />
        <Path
          d="M14.906 28H2.901C1.301 28 0 26.722 0 25.151V2.85C0 1.278 1.302 0 2.901 0h12.005c1.6 0 2.902 1.278 2.902 2.85V6.45a.496.496 0 01-.5.491c-.277 0-.5-.22-.5-.49V2.848c0-1.03-.854-1.866-1.902-1.866H2.901c-1.047 0-1.9.837-1.9 1.866v22.302c0 1.03.853 1.867 1.9 1.867h12.005c1.048 0 1.901-.838 1.901-1.867v-5.436c0-.272.224-.491.5-.491.277 0 .5.22.5.49v5.437c0 1.571-1.301 2.849-2.9 2.849"
          transform="translate(7 6)"
        />
        <Path
          d="M4.477.983V2.21c0 .162.134.294.3.294h8.253c.167 0 .3-.132.3-.294V.983H4.478zm8.553 2.505H4.777a1.29 1.29 0 01-1.3-1.277V.49c0-.271.224-.491.5-.491h9.855c.275 0 .499.22.499.491v1.72a1.29 1.29 0 01-1.3 1.277zM13.568 26.036H3.564c-.276 0-.5-.22-.5-.492s.224-.491.5-.491h10.004c.277 0 .5.22.5.491a.496.496 0 01-.5.492M29.5 13.484h-4.477c-.276 0-.5-.22-.5-.491 0-.272.224-.491.5-.491H29.5c.276 0 .5.22.5.49a.496.496 0 01-.5.492M27.257 9.988h-2.534c-.276 0-.5-.22-.5-.491s.224-.491.5-.491h2.534c.276 0 .5.22.5.49a.496.496 0 01-.5.492M27.257 16.979h-2.534a.494.494 0 01-.5-.49c0-.272.224-.492.5-.492h2.534c.276 0 .5.22.5.491 0 .272-.224.49-.5.49"
          transform="translate(7 6)"
        />
      </G>
    </Svg>
  );
}

export default PrePaidIcon;
