import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={27} height={27} viewBox="0 0 27 27" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M18.143 16.944H8.628a29.927 29.927 0 010-6.6h9.515a29.734 29.734 0 010 6.6zM8.867 8.693h9.037c-.021-.12-.044-.238-.066-.356-.309-1.591-.742-3.01-1.293-4.218-.507-1.11-1.101-2-1.716-2.57-.498-.461-.983-.695-1.444-.695-.46 0-.945.234-1.44.695-.618.57-1.21 1.46-1.716 2.57-.549 1.207-.986 2.627-1.293 4.218-.027.118-.048.237-.069.356zM6.8 13.643c-.001-1.102.056-2.203.172-3.3H1.053a12.895 12.895 0 000 6.601h5.918a31.422 31.422 0 01-.172-3.3zm18.917-3.3h-5.918a31.42 31.42 0 01.003 6.601h5.917a12.893 12.893 0 000-6.6h-.002zm-.562-1.65a12.82 12.82 0 00-3.31-4.625 12.786 12.786 0 00-4.98-2.733c1.24 1.702 2.206 4.288 2.714 7.358h5.576zm-8.29 17.26a12.785 12.785 0 004.98-2.733 12.82 12.82 0 003.31-4.625h-5.576c-.508 3.07-1.473 5.656-2.715 7.358zm1.04-7.358H8.867c.021.119.044.238.066.356.309 1.59.742 3.01 1.293 4.217.507 1.111 1.1 2 1.716 2.57.498.464.983.695 1.444.695.46 0 .945-.233 1.443-.695.617-.57 1.209-1.459 1.716-2.57.55-1.207.986-2.626 1.293-4.217.024-.118.045-.237.066-.357zM9.908 1.334a12.784 12.784 0 00-4.982 2.733 12.82 12.82 0 00-3.31 4.625h5.577c.508-3.07 1.473-5.656 2.715-7.358zm-8.292 17.26a12.82 12.82 0 003.31 4.625 12.785 12.785 0 004.982 2.733C8.666 24.25 7.7 21.665 7.193 18.595H1.616z"
        />
        <Path d="M19.973 13.644c0-1.103-.057-2.204-.172-3.3h5.918a12.692 12.692 0 00-.563-1.651h-5.578c-.508-3.07-1.473-5.656-2.715-7.358a12.748 12.748 0 00-3.477-.48c.46 0 .946.233 1.443.694.618.57 1.209 1.46 1.716 2.57.55 1.207.986 2.627 1.293 4.218.023.118.045.237.066.356H8.867l.066-.356c.31-1.591.742-3.01 1.293-4.218.507-1.11 1.101-2 1.719-2.57.495-.461.98-.695 1.44-.695-1.175 0-2.345.161-3.477.48-1.242 1.703-2.207 4.289-2.714 7.359H1.616a12.69 12.69 0 00-.562 1.65h5.918a31.728 31.728 0 000 6.601H1.054c.15.563.337 1.114.562 1.65h5.578c.507 3.07 1.472 5.657 2.714 7.358 1.132.32 2.302.482 3.478.481-.46 0-.946-.233-1.444-.695-.617-.57-1.209-1.459-1.716-2.57-.55-1.207-.985-2.626-1.293-4.217a25.653 25.653 0 01-.066-.357h9.037c-.021.12-.043.239-.066.357-.309 1.59-.742 3.01-1.293 4.217-.507 1.111-1.1 2-1.716 2.57-.498.464-.983.695-1.443.695 1.175.001 2.346-.16 3.477-.48 1.242-1.702 2.207-4.289 2.715-7.358h5.577c.225-.537.413-1.088.562-1.65h-5.918c.116-1.097.174-2.199.174-3.301zm-1.83 3.3H8.629a29.927 29.927 0 010-6.6h9.515a29.71 29.71 0 010 6.6z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill={props.secondaryColor || "#FFF"}
            transform="translate(.623 .442)"
            d="M0 0h25.524v26.404H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
