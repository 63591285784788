import React, { useContext } from "react";
import { View } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import Divider from "@presto-components/Dividers/Divider";
import I18n from "i18n-js";
import { PrestoDottedButton } from "@presto-components/PrestoButton";
import CouponButton from "@presto-screen-components/ButtonComponent/CouponButton";

export default function Coupon({ coupon, onApplyRemoveCoupon }) {
  return (
    <BaseCoupon coupon={coupon} onApplyRemoveCoupon={onApplyRemoveCoupon} />
  );
}

function BaseCoupon({ coupon, onApplyRemoveCoupon }) {
  const { theme } = useContext(ThemeContext);
  return (
    <View>
      <View style={{ padding: 15 }}>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              marginTop: 5,
              marginRight: 20,
              flex: 1,
            }}
          >
            <PrestoText fontStyle="500.medium" color={theme.darkText}>
              {coupon.title}
            </PrestoText>
            <PrestoText fontStyle="500.medium" color={theme.darkText}>
              {coupon.description}
            </PrestoText>
          </View>
          <View
            style={{
              flexDirection: "column",
              width: 100,
            }}
          >
            <View>
              <CouponButton title={coupon.couponCode} onPress={() => {}} />
            </View>
            <VerticalSpacing size={10} />
            {onApplyRemoveCoupon ? (
              <TouchableOpacity onPress={onApplyRemoveCoupon}>
                <PrestoText
                  fontStyle="medium"
                  color={theme.secondary}
                  size={15}
                  extraStyle={{
                    textTransform: "uppercase",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {coupon.applyRemoveTitle}
                </PrestoText>
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
      </View>
      <Divider dividerColor={theme.lightText} opacity={0.5} size={0.5} />
      <View style={{ padding: 15 }}>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              marginTop: 5,
              marginRight: 20,
              flex: 1,
            }}
          >
            <PrestoText fontStyle="500.medium" color={theme.paragraph}>
              {I18n.t("screen_messages.register.terms_conditions")}
            </PrestoText>
            <VerticalSpacing size={10} />
            <PrestoText fontStyle="400.normal" size={12} color={theme.darkText}>
              {coupon.validityTextWithDescription}
            </PrestoText>
            <VerticalSpacing size={10} />
            <PrestoText weight="400" size={12} color={theme.paragraph}>
              {coupon.otherOfferText}
            </PrestoText>
          </View>
        </View>
      </View>
      <Divider dividerColor={theme.primary} opacity={0.5} size={0.5} />
    </View>
  );
}
