import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const PaymentResource = function ($http, PrestoSdk) {
  function initiateRazorPayPayment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/payments/razor/new_payment.json?" +
      params.id_type +
      "=" +
      params.id;
    return $http.get(url);
  }

  function initiateAppyPayPayment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/payments/appypay/new_payment.json?" +
      params.id_type +
      "=" +
      params.id;
    return $http.post(url, params);
  }

  function initiateRazorPayPaymentV1(params) {
    var url =
      PrestoSdk.getHostName() +
      "/payments/razor/new_payment_v1.json?" +
      params.id_type +
      "=" +
      params.id;
    return $http.get(url);
  }

  function captureRazorPayPayment(params) {
    var url = params.razorPayUrl;
    return $http.post(url, { razorpay_payment_id: params.razorPayPaymentId });
  }

  function captureRazorPayPaymentV1(params) {
    var url = params.razorPayUrl;
    if (url.indexOf("http") != 0) {
      url = PrestoSdk.getHostName() + url;
    }
    return $http.post(url, {
      razorpay_payment_id: params.paymentResponse.razorpay_payment_id,
      razorpay_order_id: params.paymentResponse.razorpay_order_id,
      razorpay_signature: params.paymentResponse.razorpay_signature,
    });
  }

  function getPaymentDetails(params) {
    var url = params.url;
    return $http.get(url);
  }

  function captureWorldPayPayment(params) {
    var url = params.worldPayUrl;
    return $http.post(url, params);
  }

  function createAgentCompositePayment(params) {
    var url = PrestoSdk.getHostName() + "/cc/composite_payments.json";
    return $http.post(url, params);
  }

  function getSavedCards(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/saved_cards.json";
    return $http.get(url, params);
  }

  function deleteSavedCard(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/saved_cards/" + params.id + ".json";
    return $http.delete(url, params);
  }

  function payViaPaygateSavedCard(params) {
    var url =
      PrestoSdk.getHostName() + "/payments/paygate/pay_with_saved_card.json";
    return $http.post(url, params);
  }

  function getNewPaymentV1(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v1/payments/razorpay/new_payment.json?payable_type=" +
      params.payable_type +
      "&payable_id=" +
      params.payable_id;
    return $http.get(url);
  }

  return {
    initiateRazorPayPayment: initiateRazorPayPayment,
    initiateRazorPayPaymentV1: initiateRazorPayPaymentV1,
    captureRazorPayPayment: captureRazorPayPayment,
    captureRazorPayPaymentV1: captureRazorPayPaymentV1,
    getPaymentDetails: getPaymentDetails,
    captureWorldPayPayment: captureWorldPayPayment,
    createAgentCompositePayment: createAgentCompositePayment,
    getSavedCards: getSavedCards,
    deleteSavedCard: deleteSavedCard,
    payViaPaygateSavedCard: payViaPaygateSavedCard,
    getNewPaymentV1: getNewPaymentV1,
    initiateAppyPayPayment: initiateAppyPayPayment,
  };
};

export default PaymentResource($http, PrestoSdk);
