import React, { useContext } from "react";
import InCartItemCard from "@presto-components/ItemCards/InCartItemCard";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function InCartItemCard2(props) {
  const { theme } = useContext(ThemeContext);
  const propsObj = {
    ...props,
    color: theme?.primary,
    textColor: theme?.plusTextColor,
  };
  return <InCartItemCard {...propsObj} />;
}
