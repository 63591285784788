import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#FFF"}>
        <Path
          opacity={0.4}
          d="M23.25 18h-15a.75.75 0 00-.75.75v1.5a.75.75 0 00.75.75h15a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zm0-15h-15a.75.75 0 00-.75.75v1.5a.75.75 0 00.75.75h15a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zm0 7.5h-15a.75.75 0 00-.75.75v1.5a.75.75 0 00.75.75h15a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75z"
        />
        <Path d="M2.25 2.25a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm0 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm0 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
