import * as React from "react";
import Svg, { Path, G, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={35} height={25} viewBox="0 0 35 25" fill="none" {...props}>
      <Path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.617 17.837A1.825 1.825 0 010 16.487V9.48h16.233a9.725 9.725 0 00-1.447 3.821H8.951c-.14 0-.273.05-.372.14a.456.456 0 00-.154.338v1.592c0 .127.055.249.154.338.099.09.233.14.372.14h5.782c.097.89.313 1.745.633 2.548H2.106a2.22 2.22 0 01-1.49-.56zm2.345-4.396a.456.456 0 00-.154.338v1.592c0 .127.056.249.154.338.1.09.233.14.373.14h3.159c.14 0 .274-.05.372-.14a.456.456 0 00.155-.338V13.78a.456.456 0 00-.155-.338.555.555 0 00-.372-.14h-3.16c-.139 0-.273.05-.372.14zM2.106.562h21.062a2.22 2.22 0 011.489.56c.395.359.617.845.617 1.351v1.911H0v-1.91c0-.507.222-.993.617-1.352a2.22 2.22 0 011.49-.56z"
        fill={props.primaryColor || "#1C1649"}
      />
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M24.459 6.88a7.897 7.897 0 00-7.898 7.899 7.897 7.897 0 007.898 7.898 7.897 7.897 0 007.898-7.898 7.897 7.897 0 00-7.898-7.898zm4.586 8.79a.382.382 0 01-.383.383h-2.93v2.93a.382.382 0 01-.382.382h-1.783a.382.382 0 01-.382-.382v-2.93h-2.93a.382.382 0 01-.382-.382v-1.784a.382.382 0 01.382-.382h2.93v-2.93a.382.382 0 01.382-.382h1.783a.382.382 0 01.383.382v2.93h2.93a.382.382 0 01.382.382v1.784z"
        />
        <Path d="M29.045 15.671a.382.382 0 01-.383.382h-2.93v2.93a.382.382 0 01-.382.382h-1.783a.382.382 0 01-.382-.382v-2.93h-2.93a.382.382 0 01-.382-.382v-1.783a.382.382 0 01.382-.383h2.93v-2.93a.382.382 0 01.382-.382h1.783a.382.382 0 01.383.383v2.93h2.93a.382.382 0 01.382.382v1.783z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill="#fff"
            transform="translate(16.306 6.626)"
            d="M0 0h16.306v16.306H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
