import Utils from "../common/Utils";
import DeliverySlotObject from "./DeliverySlotObject";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const OutletConfigObject = function (
  Utils,
  DeliverySlotObject,
  ErrorConstants
) {
  function OutletConfigObject() {
    this.delivery_slots = [];
    this.delivery_charge = {};
    this.delivery_slots_configured = false;
    this.min_bill = {};
    this.outlet_id = "";
    this.live_order_cut_off_enabled = "";
    this.live_order_cut_off_message = "";
    this.live_order_slots = [];
    this.payment_config = {};
    this.excluded_categories = {};
    this.packing_charge = {};
  }
  OutletConfigObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawOutletConfigObject) {
      this.delivery_slots = [];
      this.accepting_orders =
        rawOutletConfigObject.accepting_orders != undefined
          ? rawOutletConfigObject.accepting_orders
          : true;
      if (rawOutletConfigObject.delivery_slots_configured) {
        var slots = rawOutletConfigObject.delivery_slots;
        var parsedSlots = [];
        slots.forEach(function (rawDeliverySlot, index, slots) {
          var deliverySlotObj = new DeliverySlotObject();
          deliverySlotObj.buildObject(rawDeliverySlot);
          parsedSlots.push(deliverySlotObj);
        });
        this.delivery_slots = parsedSlots;
      }
      this.delivery_charge = rawOutletConfigObject.delivery_charge;
      this.delivery_slots_configured =
        rawOutletConfigObject.delivery_slots_configured;
      this.min_bill = rawOutletConfigObject.min_bill;
      this.outlet_id = rawOutletConfigObject.outlet_id;
      this.live_order_cut_off_enabled =
        rawOutletConfigObject.live_order_cut_off_enabled;
      this.live_order_cut_off_message =
        rawOutletConfigObject.live_order_cut_off_message;
      this.live_order_slots = [];
      if (rawOutletConfigObject.live_order_cut_off_enabled) {
        var slots = rawOutletConfigObject.live_order_slots;
        var parsedSlots = [];
        slots.forEach(function (rawDeliverySlot, index, slots) {
          var deliverySlotObj = new DeliverySlotObject();
          deliverySlotObj.buildObject(rawDeliverySlot);
          parsedSlots.push(deliverySlotObj);
        });
        this.live_order_slots = parsedSlots;
      }
      this.payment_config = rawOutletConfigObject.payment_config;
      this.excluded_categories = rawOutletConfigObject.excluded_categories;
      this.packing_charge = rawOutletConfigObject.packing_charge;
    },
  };
  return OutletConfigObject;
};

export default OutletConfigObject(Utils, DeliverySlotObject, ErrorConstants);
