import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function CallIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(6 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <G transform="translate(-.02)">
            <Path
              fill="none"
              d="M0.0206000862 0H29.0206000862V29H0.0206000862z"
            />
            <Path
              d="M16.851 20.715l3.107-2.85a.952.952 0 01.778-.258l.518.259 7.25 3.366c.518.259.518.518.518 1.035a6.565 6.565 0 01-2.071 4.66 7.417 7.417 0 01-5.438 2.071 15.769 15.769 0 01-7.25-1.812 33.943 33.943 0 01-7.25-5.179 44.104 44.104 0 01-3.886-5.695A35.791 35.791 0 01.54 10.617a15.934 15.934 0 01-.518-4.92 6.804 6.804 0 011.812-3.883A7.873 7.873 0 016.753 0c.405.008.785.198 1.034.518l3.884 7.25a.906.906 0 00.26.519.952.952 0 01-.258.775L8.306 12.17a1.156 1.156 0 000 1.294 24.726 24.726 0 003.108 4.144 26.144 26.144 0 004.143 3.365c.517.26 1.035.26 1.294-.257z"
              fill={props.color || "#707070"}
              fillRule="nonzero"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default CallIcon;
