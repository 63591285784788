import React, { useContext } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";

const UpcomingOrderButton = ({ _isActive }) => {
  const windowWidth = Dimensions.get("window").width;
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(windowWidth, theme);
  return (
    <View
      style={{
        ...styles.buttonContainer,
        paddingVertical: 12,
        height: 40,
        width: "100%",
        alignItems: "center",
        borderRadius: 30,
        backgroundColor: _isActive("upcoming") ? theme.primary : "#EDEDED",
        borderTopLeftRadius: _isActive("upcoming") ? 30 : 0,
        borderBottomLeftRadius: _isActive("upcoming") ? 30 : 0,
        borderTopRightRadius: _isActive("upcoming") ? 30 : 0,
        borderBottomRightRadius: _isActive("upcoming") ? 30 : 0,
      }}
    >
      <PrestoText
        fontStyle={_isActive("upcoming") ? "500.medium" : "400.normal"}
        color={_isActive("upcoming") ? theme.white : theme.darkText}
        extraStyle={{
          cursor: "pointer",
        }}
      >
        {I18n.t("screen_messages.orders.upcoming_order_title")}
      </PrestoText>
    </View>
  );
};

function getStyles(windowWidth, theme) {
  let minusWidth = theme.primaryPadding / 2;
  return StyleSheet.create({
    buttonContainer: {
      paddingVertical: 12,
      height: 40,
      width: windowWidth / 2 - minusWidth,
      alignItems: "center",
      borderRadius: 30,
    },
  });
}

export default UpcomingOrderButton;
