import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={18} height={14} viewBox="0 0 18 14" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M12.688 9.625a2.188 2.188 0 100 4.376 2.188 2.188 0 000-4.376zm-7.876 0a2.187 2.187 0 100 4.375 2.187 2.187 0 000-4.375zm1.97-5.688h-.657v-1.53a.219.219 0 00-.219-.22H5.47a.219.219 0 00-.22.22v2.187a.219.219 0 00.22.218H6.78A.219.219 0 007 4.595v-.438a.219.219 0 00-.219-.218z"
        />
        <Path d="M17.063 9.625h-.438V6.669c0-.348-.14-.681-.386-.927l-2.731-2.731a1.313 1.313 0 00-.927-.386h-1.206V1.312A1.313 1.313 0 0010.062 0h-8.75A1.313 1.313 0 000 1.313v8.75a1.313 1.313 0 001.313 1.312h.496C2.023 9.897 3.275 8.75 4.813 8.75a3.059 3.059 0 013.018 2.625h1.838a3.058 3.058 0 013.018-2.625c1.54 0 2.79 1.147 3.004 2.625h1.371a.438.438 0 00.438-.438v-.874a.438.438 0 00-.438-.438zM5.688 7.437a3.062 3.062 0 110-6.124 3.062 3.062 0 010 6.125zM15.313 7h-3.938V3.937h1.206l2.732 2.732V7z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill={props.color||"#fff"} d="M0 0h17.5v14H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;

