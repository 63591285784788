import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import BaseItemCard from "@presto-cards/ItemCards/BaseItemCard";

export default function SoldOutItemCard(props) {
  const { updating, onNotifyMe, variation } = props;
  const { theme } = useContext(ThemeContext);

  const AddButton = () => {
    return <View style={{}}></View>;
  };

  return <BaseItemCard {...props} right={<AddButton />} />;
}
