import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import SectionLinkWithLeftIconComponent from "@presto-components/SectionLinks/SectionLinkWithLeftIconComponent";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";

export default function CouponApply({
  cart,
  openCouponApply,
  removeCouponFromCart,
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  return (
    <View>
      {!cart.coupon_code ? (
        <SectionLinkWithLeftIconComponent
          theme={theme}
          leftText={I18n.t("screen_messages.coupons.apply_coupon")}
          rightText={I18n.t("screen_messages.coupons.view_apply")}
          leftIcon={
            <svgs.CouponIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          rightIcon={<svgs.RightIcon2 width="18" height="18" />}
          onPressButton={openCouponApply}
        />
      ) : (
        <SectionLinkWithLeftIconComponent
          theme={theme}
          leftText={I18n.t(
            "screen_messages.coupons.coupon_applied_prefix_code",
            {
              code: cart.coupon_code,
            }
          )}
          rightText={
            <PrestoText color={theme.primary}>
              {I18n.t("screen_messages.common.remove")}
            </PrestoText>
          }
          leftIcon={
            <svgs.CouponIcon
              style={{ marginRight: 10 }}
              width="32"
              height="32"
            />
          }
          onPressButton={() => removeCouponFromCart(cart.coupon_code)}
        />
      )}
    </View>
  );
}
