import React, { createContext, useContext } from "react";
import AppStyles from "@presto-app/styles";
import TemplateStyles from "@presto-app/templateStyles";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useAssets } from "presto-react-components";

function mix(...sources) {
  const result = {};
  for (const source of sources) {
    const props = Object.keys(source);
    for (const prop of props) {
      const descriptor = Object.getOwnPropertyDescriptor(source, prop);
      Object.defineProperty(result, prop, descriptor);
    }
  }
  return result;
}

const generateStyles = (theme) => {
  const { images, svgs } = useAssets();

  return mix(
    {},
    TemplateStyles(theme, svgs, images),
    AppStyles(theme, svgs, images)
  );
};

const StylesContext = createContext();

export function StylesContextProvider(props) {
  const { theme } = useContext(ThemeContext);
  const appStyles = generateStyles(theme);
  return (
    <StylesContext.Provider value={{ appStyles }}>
      {props.children}
    </StylesContext.Provider>
  );
}

export default StylesContext;
