import { PrestoFramedButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import _ from "lodash";
import React, { useContext } from "react";
import { Image, Linking, TouchableOpacity, View } from "react-native";
import ReadMore from "react-native-read-more-text";
import DeepLinkManager from "../../common/DeepLinkManager";
import NavigationManager from "../../common/NavigationManager";
import moment from "moment";
import I18n from "i18n-js";

export default function NotificationCard({ notification }) {
  const { user, setUser } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const handleTextReady = () => {};

  const canShowButton = (link) => {
    if (link.indexOf("button_text") > -1) {
      return true;
    } else {
      return false;
    }
  };
  const getButtonName = (link) => {
    if (_.startsWith(link, "http")) {
      if (link.indexOf("?") > -1) {
        link = _.split(link, "?")[1];
        link = _.split(link, "=")[1];
        link = _.replace(link, '"', "");
        return link;
      } else {
        return "OPEN IN BROWSER";
      }
    } else {
      if (link.indexOf("?") > -1) {
        link = _.split(link, "?")[1];
        link = _.split(link, "=")[1];
        link = _.replace(link, '"', "");
        console.log(link);
        return link;
      }
    }
  };

  const onNavigate = (link) => {
    if (link) {
      if (_.startsWith(link, "http") || link.indexOf("http") > -1) {
        link = _.split(link, "?")[0];
        link = _.replace(link, '"', " ");
        Linking.openURL(link.trim()).catch((err) =>
          console.error("An error occurred", err)
        );
      } else {
        if (link.indexOf("?") > -1) {
          link = _.split(link, "?")[0];
          link = _.replace(link, '"', " ");
          DeepLinkManager.navigateTo(link.trim());
        }
      }
    }
  };

  const renderTruncatedFooter = (handlePress) => {
    return (
      <PrestoText
        color={notification.isUnRead ? theme.white : theme.subHeader}
        weight="600"
        size={13}
        extraStyle={{ marginTop: 5 }}
        onPress={handlePress}
      >
        {I18n.t("screen_messages.read_more")}
      </PrestoText>
    );
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() =>
          notification.deep_link
            ? NavigationManager.navigateTo(notification.deep_link)
            : null
        }
        style={{
          borderRadius: 8,
          backgroundColor: notification.isUnRead ? "#ADA4A3" : "#EDEDED",
          marginHorizontal: theme.containerPadding,
          ...theme.lightShadow,
        }}
      >
        <View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: 15,
              flex: 1,
            }}
          >
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <View>
                  <PrestoText
                    color={notification.isUnRead ? theme.white : theme.black}
                    size={16}
                    fontStyle="500.medium"
                  >
                    {notification.title}
                  </PrestoText>
                </View>
                <View>
                  <PrestoText
                    color={
                      notification.isUnRead ? theme.white : theme.secondary
                    }
                    size={16}
                    fontStyle="400.normal"
                  >
                    {moment(notification.created_at).format("MMM d")}
                  </PrestoText>
                </View>
              </View>
            </View>
            <VerticalSpacing size={5} />
            <ReadMore
              numberOfLines={10}
              renderTruncatedFooter={renderTruncatedFooter}
              renderRevealedFooter={() => {}}
              onReady={handleTextReady}
            >
              <PrestoText
                color={notification.isUnRead ? theme.white : theme.subHeader}
                size={14}
              >
                {notification.msg}
              </PrestoText>
            </ReadMore>
          </View>
          {notification.picture ? (
            <View
              style={{
                maxWidth: 150,
                paddingLeft: theme.containerPadding,
              }}
            >
              <Image
                source={{ uri: notification.picture }}
                style={{ maxHeight: 150, minHeight: 100 }}
                resizeMode="contain"
              />
            </View>
          ) : undefined}
        </View>
        <View
          style={{
            paddingHorizontal: 15,
            paddingBottom: 15,
            paddingTop: 0,
            flexDirection: "row-reverse",
          }}
        >
          {notification.deep_link && canShowButton(notification.deep_link) ? (
            <View
              style={{
                minWidth: 100,
              }}
            >
              <PrestoFramedButton
                buttonStyle="primary"
                size={"large"}
                bgColor={"rgba(253,91,83,1)"}
                titleColor={theme.white}
                borderWidth={1}
                title={getButtonName(notification.deep_link)}
                onPress={() => onNavigate(notification.deep_link)}
                extraStyle={{ paddingHorizontal: theme.primaryPadding }}
              />
            </View>
          ) : null}
          <VerticalSpacing size={15} />
        </View>
      </TouchableOpacity>
      <View
        style={{ width: "100%", height: 20, backgroundColor: "transparent" }}
      />
    </>
  );
}
