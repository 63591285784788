import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import LoadingContainer from "@presto-components/LoadingContainer";
import {
  PrestoSolidButton,
  PrestoTextButton,
} from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";

import { VerticalSpacing } from "@presto-components/Spacing";
import TextInputComponent from "@presto-components/TextInputs/TextInputComponent";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import LoginObject from "@presto-services/data_models/LoginObject";
import SessionManager from "@presto-services/features/session/SessionManager";
import I18n from "i18n-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dimensions, Image, Keyboard, ScrollView, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import asyncify from "../../common/Asyncify";
import utils from "../../utils/index";
import { useAssets } from "presto-react-components";
import SkipText from "@presto-screen-components/Login/SkipText";
import RegisterText from "@presto-screen-components/Login/RegisterText";
import Config from "@presto-common/config";

const AsyncSessionManager = asyncify(SessionManager);
export default function LoginScreen() {
  const { svgs, images: Images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { setUser } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const { setPaneState } = useContext(RightPaneContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  useEffect(() => {
    setShown(true);
  }, []);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [padding, setPadding] = useState(0);
  const [showPassword, setShowPassword] = useState(true);
  const scrollRef = useRef();

  const onPhoneNumberChange = ({ nativeEvent: { text } }) => {
    setPhoneNumber(text);
  };

  const onPasswordChange = ({ nativeEvent: { text } }) => {
    setPassword(text);
  };

  const onSignup = () => {
    Navigator.emit({
      event: "modal",
      params: { screenName: "SIGNUP_SCREEN" },
    });
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      console.log("in showSubscription");
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      console.log("in hidesubscription");
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    console.log("Padding : padding", padding);
    scrollRef.current?.scrollToEnd({ animated: true });
  }, [padding]);

  const onLogin = () => {
    setLoading(true);
    async function login() {
      let lobj = new LoginObject();
      lobj.user = {
        login: phoneNumber,
        password: password,
      };

      const [error, response] = await AsyncSessionManager.login(lobj);
      if (error) {
        alert(utils.errorMessage(error));
        console.log(" login error==>", error);
      } else {
        setShown(true);
        onPressSkip();
        setPaneState(false);
        setUser(response.data);
        console.log(" login Res==>", response);
      }
      setLoading(false);
    }
    login();
  };

  const onPressSkip = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  const onEyePress = () => {
    setShowPassword(!showPassword);
  };
  const insets = useSafeArea();

  return (
    <LoadingContainer
      bgColor={theme.appBackgroundColor}
      style={{ flex: 1 }}
      loading={loading}
    >
      <ScrollView
        ref={scrollRef}
        enableOnAndroid={true}
        scrollEnabled={true}
        style={{ backgroundColor: theme.appBackgroundColor, flex: 1 }}
        contentContainerStyle={{
          paddingTop: insets.top + 50,
          minHeight: "100%",
        }}
      >
        {shown ? (
          <View
            style={{
              flex: 1,
              flexDirextion: "column",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: padding,
            }}
          >
            <FullWidthHorizontalPaddedBox>
              <View
                style={{
                  position: "absolute",
                  right: 20,
                  top: -30,
                }}
              >
                <SkipText isDesktop={isDesktop} onPressSkip={onPressSkip} />
              </View>
              <VerticalSpacing size={40} />
              <View style={{ alignItems: "center", flex: 1 }}>
                <Image
                  source={Images.logo_transparent}
                  style={{ width: 120, height: 100, marginBottom: 50 }}
                  resizeMode="contain"
                />
              </View>
              <View style={{ alignItems: "center" }}>
                <PrestoText
                  fontStyle="400.normal"
                  color={theme.darkText}
                  size={22}
                >
                  {I18n.t("screen_messages.login.title")}
                </PrestoText>
                <VerticalSpacing size={15} />
                <PrestoText color="#666666" size={14} fontStyle="400.normal">
                  {I18n.t("screen_messages.login_description")}
                </PrestoText>
              </View>
              <VerticalSpacing size={40} />
              <TextInputComponent
                theme={theme}
                placeholder={I18n.t(
                  "screen_messages.login.enter_your_phone_number"
                )}
                value={phoneNumber}
                keyboardType={"phone-pad"}
                onChange={onPhoneNumberChange}
                backgroundColor={theme.white}
                textContentType={"username"}
                autoCompleteType={"username"}
                importantForAutofill={"yes"}
                labelText={I18n.t("screen_messages.common.phone_number")}
                labelColor="#666666"
                textInputHeight={40}
                labelFontWeight="400"
                noBorder
              ></TextInputComponent>
              <VerticalSpacing size={30} />
              <TextInputComponent
                theme={theme}
                placeholder={I18n.t(
                  "screen_messages.login.enter_your_password"
                )}
                value={password}
                onChange={onPasswordChange}
                backgroundColor={theme.white}
                textContentType={"password"}
                autoCompleteType={"password"}
                importantForAutofill={"yes"}
                labelText={I18n.t("screen_messages.login.password")}
                labelColor="#666666"
                textInputHeight={40}
                labelFontWeight="400"
                secureTextEntry={showPassword}
                onEyePress={onEyePress}
                noBorder
                icon={
                  showPassword ? (
                    <svgs.EyeClose width="40" height="40" />
                  ) : (
                    <svgs.EyeOpen width="40" height="40" />
                  )
                }
              />
              <VerticalSpacing size={40} />
              <PrestoSolidButton
                size="extra-large"
                buttonStyle="primary"
                titleColor={theme.white}
                title={I18n.t("screen_messages.login.login_btn_name")}
                onPress={onLogin}
              />
              <VerticalSpacing size={20} />
            </FullWidthHorizontalPaddedBox>
            {!Config.constants.hide_register && (
              <View style={{ marginBottom: 80, paddingLeft: 5 }}>
                <RegisterText onSignup={onSignup} />
              </View>
            )}
          </View>
        ) : null}
      </ScrollView>
    </LoadingContainer>
  );
}
