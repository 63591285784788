import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { View, ScrollView, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import OrderManager from "@presto-services/features/orders/OrderManager";
import svgs from "@presto-common-assets/svgs";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import OrderReview from "@presto-components/OrderReview/OrderReview";
import { useFocusEffect } from "@react-navigation/native";
import SearchManager from "@presto-services/features/search/SearchManager";
import LineItemCard from "@presto-screen-components/LineItemCard/LineItemCard";
import AddressCard from "@presto-cards/AddressCard/AddressCard";
import PrestoText from "@presto-components/PrestoText";
import AddressCardModel from "@presto-component-models/AddressCardModel";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import { PrestoFramedButton } from "@presto-components/PrestoButton";

const DELIVERY_OPTIONS = [
  {
    label: "screen_messages.tracking_accepted_str",
    key: "accepted_at",
  },
  {
    label: "screen_messages.tracking_dispatched_str",
    key: "dispatched_at",
  },
  {
    label: "screen_messages.tracking_delivered_str",
    key: "delivered_at",
  },
];

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload?.id]: payload } };
  }
  return state;
}

export default function TrackOrderScreen({ route }) {
  const params = route.params || {};

  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const { items } = state;
  const { orderId } = params;
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  let isDesktop = Dimensions.get("window").width > 940 ? true : false;

  const getCategoryItem = (id) => {
    SearchManager.searchCategoryItem(
      { id },
      (response) => {
        console.log(
          "Category Item for id : ",
          id,
          JSON.stringify(response, null, 2)
        );
        if (response?.hits?.hits?.length) {
          dispatch({
            type: "SET_CATEGORY_ITEM",
            payload: response.hits.hits[0]?._source,
          });
        }
      },
      () => {}
    );
  };

  const downloadInvoice = () => {
    setLoading(true);
    const url =
      PrestoSdk.getHostName() +
      `/user/v0/orders/${order?.id}/download_invoice.json`;
    window.open(url, "_self");
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useFocusEffect(
    useCallback(() => {
      _.forEach(order?.items, (lineItem) => {
        getCategoryItem(lineItem.item_id);
      });
    }, [order])
  );
  useEffect(() => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        console.log(
          "After get order : ",
          JSON.stringify(response.data, null, 2)
        );
        setOrder(response.data);
        setLoading(false);
      },
      (error) => {
        console.log("Error : ", error);
        alert(I18n.t("error_messages.error_loading_order"));
      }
    );
  }, [orderId]);

  const renderProgressIcon = (checked) => {
    return (
      <View
        style={{
          width: 30,
          height: 30,
          borderRadius: 16,
          borderWidth: 2,
          justifyContent: "center",
          alignItems: "center",
          borderColor: theme.primary,
          backgroundColor: checked ? theme.primary : theme.white,
        }}
      >
        {checked ? <svgs.TickIcon primaryColor="#A6A6A6" /> : undefined}
      </View>
    );
  };

  const renderLineProgressBar = (order) => {
    const options = DELIVERY_OPTIONS;
    let components = [];
    _.forEach(options, (option, index) => {
      let done = false;
      if (option.key == "accepted_at") {
        done = _.includes(
          ["ACCEPTED", "DISPATCHED", "DELIVERED"],
          order.status
        );
      } else if (option.key == "dispatched_at") {
        done = _.includes(["DISPATCHED", "DELIVERED"], order.status);
      } else if (option.key === "delivered_at") {
        done = order.status === "DELIVERED";
      }
      console.log("Order key : ", option);
      const lineHeight = index === DELIVERY_OPTIONS.length - 1 ? 0 : "100%";
      components.push(
        <View
          key={option.key}
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            paddingHorizontal: 15,
          }}
        >
          <View
            style={{
              height: "100%",
              width: 51,
              alignItems: "center",
            }}
          >
            <View
              style={{
                backgroundColor: theme.primary,
                height: lineHeight,
                width: 3,
                position: "absolute",
                top: 30,
                bottom: 0,
                left: 24,
              }}
            ></View>
            <View>{option.label ? renderProgressIcon(done) : undefined}</View>
          </View>

          <View
            style={{
              flex: 1,
              height: 60,
              paddingLeft: 15,
            }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", height: 30 }}
            >
              <PrestoText
                fontStyle="400.normal"
                color={theme.darkText}
                size={14}
                extraStyle={{
                  textTransform: "capitalize",
                }}
              >
                {I18n.t(option.label)}{" "}
                {option.key === "delivered_at" &&
                done &&
                order.sub_status === "PARTIAL_DELIVERY"
                  ? `- (${I18n.t("screen_messages.partial_delivery")})`
                  : null}
              </PrestoText>
            </View>
            <VerticalSpacing size={4} />
          </View>
        </View>
      );
    });

    return components;
  };

  const renderCartItem = (lineItem) => {
    const item = items[lineItem.item_id];
    const variation = lineItem.variation;
    lineItem.order_sub_status = order.sub_status;
    let excessQuantityAmount = lineItem.excess_quantity_amount;
    if (item) {
      return (
        <LineItemCard
          key={lineItem.id}
          item={item}
          lineItem={lineItem}
          variation={variation}
          showWhite={true}
          excessQuantityAmount={excessQuantityAmount}
        />
      );
    } else {
      return null;
    }
  };

  console.log("order", order);

  return (
    <LoadingContainer loading={loading}>
      <ScrollView style={{ flex: 1, paddingTop: 10 }}>
        {order ? (
          <>
            <FullWidthHorizontalPaddedBox>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: theme.containerPadding,
                  borderBottomColor: theme.paragraph,
                  borderBottomWidth: 1,
                  paddingBottom: 15,
                }}
              >
                <PrestoText
                  color={theme.darkText}
                  fontStyle="700.bold"
                  size={16}
                  extraStyle={{
                    alignItems: "center",
                  }}
                >{`Order#${order.friendly_id}`}</PrestoText>
                <PrestoText
                  fontStyle="400.normal"
                  color={theme.secondary}
                  size={14}
                  extraStyle={{
                    textTransform: "capitalize",
                  }}
                >
                  {order.status === "RECEIVED"
                    ? "Received"
                    : order.status === "DELIVERED"
                    ? "Delivered"
                    : order.status === "REJECTED"
                    ? "Rejected"
                    : order.status === "DISPATCHED"
                    ? "Dispatched"
                    : "Accepted"}
                </PrestoText>
              </View>

              {order.status !== "REJECTED" ? (
                <View style={{ marginBottom: 15, alignItems: "flex-start" }}>
                  <PrestoText
                    color={theme.darkText}
                    fontStyle="500.medium"
                    size={16}
                  >
                    {I18n.t("screen_messages.order_tracker_text")}
                  </PrestoText>
                </View>
              ) : null}
            </FullWidthHorizontalPaddedBox>

            {order.status !== "REJECTED" ? (
              <>
                <View style={{ width: "100%" }}>
                  {renderLineProgressBar(order)}
                </View>
                <VerticalSpacing size={30} />
              </>
            ) : null}

            <FullWidthHorizontalPaddedBox>
              <OrderReview order={order} />
              <VerticalSpacing size={20} />
              {order.status === "DELIVERED" ? (
                <>
                  <PrestoFramedButton
                    buttonStyle="primary"
                    size={"large"}
                    title={I18n.t("screen_messages.download_invoice")}
                    onPress={() => {
                      downloadInvoice();
                    }}
                  />
                  <VerticalSpacing size={30} />
                </>
              ) : null}

              <PrestoText
                fontStyle="bold"
                size={16}
                fontSize={16}
                color={theme.darkText}
              >
                {I18n.t("screen_messages.items_in_order_str")}
              </PrestoText>
              <VerticalSpacing size={15} />
            </FullWidthHorizontalPaddedBox>
            <View style={{ paddingHorizontal: isDesktop ? 20 : 0 }}>
              {_.map(order.items, (item) => {
                return renderCartItem(item);
              })}
            </View>
            {order.address ? (
              <View>
                <FullWidthHorizontalPaddedBox>
                  <VerticalSpacing size={15} />
                  <PrestoText fontStyle="bold" size={16} color={theme.darkText}>
                    {I18n.t("screen_messages.delivery_address_title")}
                  </PrestoText>
                  <VerticalSpacing size={15} />
                </FullWidthHorizontalPaddedBox>
                <AddressCard
                  fontColor={theme.paragraph}
                  boldLine1
                  address={new AddressCardModel({ address: order.address })}
                />
              </View>
            ) : null}
            <VerticalSpacing size={40} />
          </>
        ) : null}
      </ScrollView>
    </LoadingContainer>
  );
}
