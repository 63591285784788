import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function SvgComponent(props) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm4.425 11.616c-1.816 0-3.296 1.099-4.227 1.099-1.007 0-2.334-1.038-3.906-1.038-2.99 0-6.026 2.472-6.026 7.14 0 2.9 1.129 5.966 2.517 7.95 1.19 1.678 2.228 3.051 3.723 3.051 1.48 0 2.136-.992 3.982-.992 1.877 0 2.289.962 3.937.962 1.476 0 2.508-1.247 3.454-2.578l.269-.382a13.43 13.43 0 001.601-3.262l.031-.096.015-.045c-.106-.03-3.204-1.297-3.204-4.852 0-3.082 2.441-4.47 2.579-4.577-1.618-2.319-4.074-2.38-4.745-2.38zm.366-5.798c-1.205.046-2.655.809-3.525 1.831-.686.778-1.312 1.999-1.312 3.25 0 .183.03.366.046.427.076.015.198.03.32.03 1.084 0 2.442-.732 3.25-1.708.733-.885 1.267-2.121 1.267-3.357 0-.168-.016-.336-.046-.473z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default SvgComponent;
