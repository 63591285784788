import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  const fill1 = props.fill1 || "#000"
  const fill2 = props.fill2 || "#FFF"
  return (
    <Svg
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="none">
        <Path
          fill={fill1}
          d="M0 361.415h90.351V512H0zM225.88 1.275c-48.415 8.11-82.42 54.188-74.03 104.143 7.157 42.731 44.317 75.284 89.086 75.284 49.9 0 90.342-40.443 90.342-90.34 0-55.777-50.112-98.37-105.398-89.087z"
          transform="translate(180.702)"
        />

        <Path
          fill={fill2}
          d="M259.576 39v32.5h32.5v37h-32.5V141h-37v-32.5h-32.5v-37h32.5V39z"
          transform="translate(180.702)"
        />

        <Path
          fill={fill1}
          d="M60.234 289.48V512h90.351V331.298h150.586V512h90.35V289.48l-165.643-47.328zM105.41 90.361H45.176v49.822l60.234-17.21z"
        />

        <Path
          d="M315.894 148.07l-90.016-28.194L0 184.413v90.955l225.878-64.537 225.878 64.537v-68.35c-52.293 13.51-108.848-9.507-135.862-58.948z"
          fill={fill1}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
