import $ from "../global/util";

const ServerCartObject = function (MediaItemObject) {
  function ServerCartObject() {
    this.type = "";
    this.instructions = "";
    this.item_count = "";
    this.sub_total_price = "";
    this.total_charges = 0;
    this.total_taxes = 0;
    this.total_discounts = 0;
    this.total_price = 0;
    this.charges = [];
    this.taxes = [];
    this.discounts = [];
    this.coupon_code = "";
    this.id = "";
    this.user_id = "";
    this.merchant_id = "";
    this.outlet_id = "";
    this.address = {};
    this.payment_type = "";
    this.delivery_type = "";
    this.items = [];
    this.invalid_line_items = [];
    this.other_data = {};
    this.source = "";
    this.discount_response = {};
    this.original_gross_amount = "";
    this.invalid_line_items = [];
    this.discount_available_items = [];
    this.item_promotion_discounts = 0;
    this.aggregate_discounts = 0;
  }

  ServerCartObject.prototype = {
    validateObject: function () {},

    buildObject: function (rawObject) {
      this.type = rawObject.type;
      this.instructions = rawObject.instructions;
      this.item_count = rawObject.item_count;
      this.sub_total_price = rawObject.sub_total_price;
      this.total_charges = rawObject.total_charges;
      this.total_taxes = rawObject.total_taxes;
      this.total_discounts = rawObject.total_discounts;
      this.total_price = rawObject.total_price;
      this.charges = rawObject.charges;
      this.taxes = rawObject.taxes;
      this.discounts = rawObject.discounts;
      this.coupon_code = rawObject.coupon_code;
      this.id = rawObject.id;
      this.user_id = rawObject.user_id;
      this.merchant_id = rawObject.merchant_id;
      this.outlet_id = rawObject.outlet_id;
      this.address = rawObject.address;
      this.payment_type = rawObject.payment_type;
      this.delivery_type = rawObject.delivery_type;
      this.items = rawObject.items;
      this.other_data = rawObject.other_data;
      this.source = rawObject.source;
      this.discount_response = rawObject.discount_response;
      this.invalid_line_items = rawObject.invalid_line_items;
      this.original_gross_amount = rawObject.original_gross_amount || "";
      this.discount_available_items = rawObject.discount_available_items || [];
      this.item_promotion_discounts = rawObject.item_promotion_discounts || 0;
      this.aggregate_discounts = rawObject.aggregate_discounts || 0;
    },
  };

  return ServerCartObject;
};

export default ServerCartObject();
