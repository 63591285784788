import MerchantResource from "./MerchantResource";
import Utils from "../../common/Utils";
import OutletConfigObject from "../../data_models/OutletConfigObject";

const MerchantManager = function (MerchantResource, Utils, OutletConfigObject) {
  function getOrderConfig(successCallback, errorCallback, params) {
    MerchantResource.getOrderConfig(params).then(
      function (orderConfig) {
        var orderConfigObj = new OutletConfigObject();
        orderConfigObj.buildObject(orderConfig.data);
        orderConfig.data = orderConfigObj;
        successCallback(orderConfig);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(errorCallback);
      }
    );
  }
  function getMerchantConfig(params, successCallback, errorCallback) {
    MerchantResource.getMerchantConfig(params).then(
      function (orderConfig) {
        var orderConfigObj = new OutletConfigObject();
        orderConfigObj.buildObject(orderConfig.data);
        orderConfig.data = orderConfigObj;
        successCallback(orderConfig);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(errorCallback);
      }
    );
  }
  function getVersionConfig(successCallback, errorCallback) {
    MerchantResource.getVersionConfig().then(
      function (versionConfig) {
        successCallback(versionConfig);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(errorCallback);
      }
    );
  }
  function getFavouriteMerchants(successCallback, errorCallback) {
    MerchantResource.getFavouriteMerchants().then(
      function (results) {
        successCallback(results);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function deleteFavouriteMerchant(params, successCallback, errorCallback) {
    MerchantResource.deleteFavouriteMerchant(params).then(
      function (results) {
        successCallback(results);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function createFavouriteMerchant(params, successCallback, errorCallback) {
    MerchantResource.createFavouriteMerchant(params).then(
      function (results) {
        successCallback(results);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateAdmin(params, successCallback, errorCallback) {
    MerchantResource.updateAdmin(params).then(
      function (updateAdminResponse) {
        successCallback(updateAdminResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function createMerchant(params, successCallback, errorCallback) {
    MerchantResource.createMerchant(params).then(
      function (createMerchantResp) {
        successCallback(createMerchantResp);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getMerchant(params, successCallback, errorCallback) {
    MerchantResource.getMerchant(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        console.log("error", error);
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getOrderConfig: getOrderConfig,
    getVersionConfig: getVersionConfig,
    getFavouriteMerchants: getFavouriteMerchants,
    deleteFavouriteMerchant: deleteFavouriteMerchant,
    createFavouriteMerchant: createFavouriteMerchant,
    getMerchantConfig: getMerchantConfig,
    updateAdmin: updateAdmin,
    createMerchant: createMerchant,
    getMerchant: getMerchant,
  };
};

export default MerchantManager(MerchantResource, Utils, OutletConfigObject);
