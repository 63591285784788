import * as React from "react";
import Svg, { Defs, Path, G, Circle, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path d="M1 0h14v13H0V1a1 1 0 011-1z" id="a" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G
          transform="translate(6 5)"
          stroke={props.color || "#707070"}
          strokeWidth={1.5}
        >
          <Circle cx={14.5} cy={7.25} r={7.25} />
          <Path d="M14.5 18.125c-7.975 0-14.5 3.262-14.5 7.25V29h29v-3.625c0-3.988-6.525-7.25-14.5-7.25z" />
        </G>
        <G transform="translate(21 21) translate(0 2)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Use fill="#FFF" xlinkHref="#a" />
          <G mask="url(#b)">
            <G transform="translate(-.002 -2.002)">
              <Path
                d="M.467 2.62v11.897h13.966V2.62H.467zm11.722-.743h1.875a.886.886 0 01.938.937v11.25a.886.886 0 01-.938.938H.94a.886.886 0 01-.937-.938V2.814a.886.886 0 01.937-.937h1.875V.939a.886.886 0 01.938-.937.886.886 0 01.937.937v.938h5.625V.939a.937.937 0 111.875 0v.938z"
                stroke="none"
                fill={props.color || "#707070"}
                fillRule="evenodd"
              />
              <Path
                stroke={props.color || "#707070"}
                strokeWidth={0.5}
                fill="none"
                d="M6.71711551 6.19987413H8.28608103V7.76883965H6.71711551z"
              />
              <Path
                stroke={props.color || "#707070"}
                strokeWidth={0.5}
                fill="none"
                d="M6.71711551 9.82056378H8.28608103V11.3895293H6.71711551z"
              />
              <Path
                stroke={props.color || "#707070"}
                strokeWidth={0.5}
                fill="none"
                d="M4.13090861 9.82056378H5.6998741299999995V11.3895293H4.13090861z"
              />
              <Path
                stroke={props.color || "#707070"}
                strokeWidth={0.5}
                fill="none"
                d="M9.30332241 9.82056378H10.872287929999999V11.3895293H9.30332241z"
              />
              <Path
                stroke={props.color || "#707070"}
                strokeWidth={0.5}
                fill="none"
                d="M9.30332241 6.19987413H10.872287929999999V7.76883965H9.30332241z"
              />
            </G>
          </G>
        </G>
        <Path
          d="M4 0a1 1 0 011 1v1H3V1a1 1 0 011-1zM10 0a1 1 0 011 1v1H9V1a1 1 0 011-1z"
          fill={props.color || "#707070"}
          transform="translate(21 21)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;