import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import { PrestoTextButton } from "@presto-components/PrestoButton";

export default function SkipText({ isDesktop, onPressSkip, skipColor }) {
  const { theme } = useContext(ThemeContext);
  return (
    <PrestoTextButton
      buttonStyle="secondary"
      titleColor={skipColor || theme.secondary}
      size="normal"
      titleFontStyle="400.normal"
      title={!isDesktop && "SKIP"}
      onPress={onPressSkip}
    />
  );
}
