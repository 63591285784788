import * as React from "react";
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="33px"
      height="55px"
      viewBox="0 0 33 55"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <LinearGradient x1="50%" y1="99.6572901%" x2="50%" y2="3.4%" id="map">
          <Stop stopColor="#D97904" stopOpacity={0.974713626} offset="0%" />
          <Stop stopColor="#D97904" offset="100%" />
        </LinearGradient>
      </Defs>
      <Path
        d="M32.5 13.014C30.49 5.272 24.132.232 16.736.02V0c-.08 0-.158.009-.238.01-.08.002-.157-.01-.237-.01v.02C8.867.232 2.51 5.272.5 13.014c-1 3.86-.434 7.581 1.117 11.192 1.569 3.656 3.782 6.895 5.998 10.137 3.589 5.247 6.43 10.867 8.027 17.114.236.923.388 1.866.596 2.796.006.027.018.055.025.08V55l.237-.01.238.01v-.668c.007-.026.019-.054.025-.079.207-.93.36-1.874.596-2.796 1.595-6.247 4.437-11.867 8.026-17.114 2.216-3.24 4.43-6.481 5.998-10.137 1.552-3.61 2.117-7.332 1.116-11.192zm-16 7.187c-2.318 0-4.198-1.898-4.198-4.24 0-2.342 1.88-4.24 4.198-4.24 2.318-.001 4.198 1.897 4.198 4.239 0 2.342-1.88 4.24-4.198 4.24z"
        transform="translate(-171 -407) translate(171 407)"
        fill={props.primaryColor || "#000000"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default SvgComponent;
