import React, { useState, useContext, useCallback } from "react";
import {
  View,
  FlatList,
  TouchableWithoutFeedback,
  ImageBackground,
  Platform,
} from "react-native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { Dimensions } from "react-native";
import BannersSlide from "@presto-components/BannersSlide";
import PrestoText from "@presto-components/PrestoText";
import { LinearGradient } from "expo-linear-gradient";

export default function CardsGallery({
  navigation,
  title,
  name,
  slidesToShow,
  borderRadius,
  width,
  height,
  onItemClicked,
  fontStyle,
  fontSize,
  description,
  numColumns,
}) {
  const { theme } = useContext(ThemeContext);
  const [gallery, setGallery] = useState([]);
  const windowWidth = Dimensions.get("window").width;
  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response?.data?.media_items);
  };

  useFocusEffect(
    useCallback(() => {
      getGallery(name);
    }, [])
  );

  let isDesktop = windowWidth > 940 ? true : false;
  const renderItem = ({ item, index }) => {
    console.log("ite,", item);
    return (
      <TouchableWithoutFeedback
        onPress={() => onItemClicked && onItemClicked(item)}
      >
        <View style={{ flexDirection: "column", paddingBottom: 20 }}>
          <ImageBackground
            style={{
              width:
                width || isDesktop
                  ? (windowWidth - 180) / 2
                  : windowWidth - theme.primaryPadding * 2,
              height: height || isDesktop ? 320 : 170,
              borderRadius: borderRadius || 0,
              overflow: "hidden",
              marginRight: isDesktop ? theme.primaryPadding : 0,
              marginBottom: theme.primaryPadding,
              position: "relative",
              justifyContent: "flex-end",
            }}
            source={{ uri: item.file_url }}
            resizeMode={"cover"}
          ></ImageBackground>

          {item.name && (
            <View
              style={{
                width: "100%",
                paddingBottom: 10,
              }}
            >
              <PrestoText
                fontStyle="400.bold"
                color={theme.secondary}
                size={isDesktop ? 22 : 20}
              >
                {item.name}
              </PrestoText>
            </View>
          )}
          {item.description && (
            <View
              style={{
                width: "100%",
                paddingBottom: isDesktop ? 30 : 20,
              }}
            >
              <PrestoText color={theme.subHeader} size={isDesktop ? 18 : 16}>
                {item.description}
              </PrestoText>
            </View>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  };

  return (
    <View style={{}}>
      <View
        style={{
          // paddingLeft: Platform.OS === "web" ? 30 : 0,
          paddingVertical: 30,
        }}
      >
        {title ? (
          <View style={{}}>
            <PrestoText
              fontStyle="400.medium"
              color={theme.title}
              size={22}
              extraStyle={{ textAlign: "center", textTransform: "uppercase" }}
            >
              {title}
            </PrestoText>
          </View>
        ) : null}
        {description ? (
          <View
            style={{
              paddingVertical: theme.primaryPadding,
              alignItems: "center",
            }}
          >
            <PrestoText
              fontStyle="400.medium"
              color={theme.subHeader}
              size={16}
              extraStyle={{ textAlign: "center", width: "80%" }}
            >
              {description}
            </PrestoText>
          </View>
        ) : null}
      </View>
      <FlatList
        keyExtractor={(_, index) => "index-" + index}
        data={gallery}
        renderItem={renderItem}
        layout={"default"}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
        style={{
          overflow: "visible",
        }}
        numColumns={numColumns}
      />
    </View>
  );
}
