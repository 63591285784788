import React, { useContext } from "react";
import { View, Dimensions, Platform } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import HorizontalButtonList from "@presto-components/HorizontalButtonList";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import Category from "@presto-cards/Category/Category";
import LoadingContainer from "@presto-components/LoadingContainer";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import CategoryModel from "@presto-component-models/CategoryModel";
import { useMediaQuery } from "react-responsive";

export default function CategoriesSection(props) {
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 941 ? true : false;
  const isWeb = Platform.OS == "web";
  const isMobile = useMediaQuery({ maxWidth: 940 });
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const catalog = useCatalog((state) => state.catalog);

  const goToCategory = (item) => {
    if (isWeb && !isMobile) {
      WebNavigator.emit({
        event: "modal",
        params: {
          screenName: "BROWSE_CATEGORIES",
          screenParams: {
            categoryId: item.id,
          },
        },
      });
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "BROWSE_CATEGORIES",
          screenParams: {
            categoryId: item.id,
          },
        },
      });
    }
  };

  const categories = _.values(catalog?.categories) || [];

  const renderCategoryButtons = (item) => {
    return (
      <Category
        category={new CategoryModel({ category: item })}
        theme={theme}
        onPressItem={() => {
          goToCategory(item);
        }}
      />
    );
  };

  const RenderCategories = () => {
    return (
      <>
        <View style={{ paddingLeft: theme.containerPadding }}>
          <SectionHeader
            fontSize={isDesktop ? 22 : 18}
            leftText={I18n.t("screen_messages.category.explore_categories")}
          />
        </View>
        <VerticalSpacing size={10} />
        <HorizontalButtonList
          style={{
            paddingLeft: 10,
          }}
          renderButton={renderCategoryButtons}
          items={categories}
          spacing={5}
        />
      </>
    );
  };
  const renderWebCategoryButtons = (item) => {
    return (
      <Pressable onPress={() => goToCategory(item)}>
        <View style={{ paddingRight: 20 }}>
          <PrestoText
            fontStyle="medium"
            color={theme.darkText}
            extraStyle={{
              textTransform: "uppercase",
            }}
          >
            {item.name}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const RenderWebCategories = () => {
    return (
      <HorizontalButtonList
        style={{
          paddingRight: 10,
        }}
        renderButton={renderWebCategoryButtons}
        items={categories}
        spacing={5}
      />
    );
  };
  if (isDesktop) {
    return (
      <View
        style={{
          width: "100%",
          backgroundColor: "#EFEDED",
          paddingVertical: 10,
        }}
      >
        {categories && categories.length > 0 ? <RenderWebCategories /> : null}
      </View>
    );
  }
  return categories ? (
    <LoadingContainer style={{}} loading={false}>
      <View style={{ flex: 1 }}>
        {categories && categories.length > 0 ? (
          <>
            <VerticalSpacing size={10} />
            <RenderCategories />
          </>
        ) : null}
      </View>
    </LoadingContainer>
  ) : null;
}
