import * as React from "react";
import Svg, { Defs, Path, G } from "react-native-svg";

export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <Defs></Defs>
      <G
        stroke={props.color || "#707070"}
        strokeWidth={1}
        fill={props.color || "#707070"}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <G
          transform="translate(7.824 8.058)"
          stroke={props.color || "#707070"}
          strokeWidth={2}
        >
          <Path d="M1.42108547e-14 0L24.1922365 24.1922365" />
          <Path
            transform="rotate(90 12.096 12.096)"
            d="M1.42108547e-14 0L24.1922365 24.1922365"
          />
        </G>
      </G>
    </Svg>
  );
}

