import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M27.058 32.826v-7.63c0-3.56-2.942-6.457-6.558-6.457-3.616 0-6.558 2.897-6.558 6.457v7.63H7.981V16.364a2.663 2.663 0 001.789-.955c.492.599 1.244.982 2.086.982.842 0 1.594-.383 2.086-.983.493.6 1.245.983 2.087.983.842 0 1.594-.383 2.086-.983.493.6 1.245.983 2.087.983h.596c.842 0 1.594-.383 2.087-.983.492.6 1.244.983 2.086.983.842 0 1.594-.383 2.087-.983.492.6 1.244.983 2.086.983.842 0 1.594-.383 2.086-.982a2.664 2.664 0 001.79.958v16.46h-5.962zm-5.962 0h4.77v-7.043h-4.77v7.043zM15.17 24.61c.297-2.638 2.573-4.696 5.33-4.696s5.033 2.058 5.33 4.696H15.17zm-.035 8.217h4.769v-7.043h-4.77v7.043zM6.239 14.001a.591.591 0 00.214.042h2.69a1.482 1.482 0 01-1.541 1.172c-.66-.034-1.21-.551-1.363-1.214zm2.145-5.827h2.383L9.405 12.87H6.477l1.907-4.696zm26.14 4.696h-2.928l-1.363-4.696h2.383l1.907 4.696zm-3.92 1.173a1.487 1.487 0 01-1.46 1.174c-.72 0-1.321-.504-1.46-1.174h2.92zm-4.173 0a1.487 1.487 0 01-1.46 1.174c-.72 0-1.322-.504-1.46-1.174h2.92zm-4.173 0a1.487 1.487 0 01-1.46 1.174h-.596c-.72 0-1.322-.504-1.46-1.174h3.516zm-4.77 0a1.487 1.487 0 01-1.46 1.174c-.719 0-1.32-.504-1.46-1.174h2.92zm-4.172 0a1.487 1.487 0 01-1.46 1.174c-.72 0-1.322-.504-1.46-1.174h2.92zm4.286-1.173H14.72l.908-4.696h2.428l-.454 4.696zm4.599 0h-3.402l.454-4.696h2.494l.454 4.696zm4.08 0h-2.883l-.454-4.696h2.428l.908 4.696zm2.712-4.696l1.362 4.696h-2.86l-.909-4.696h2.407zm-14.58 0l-.907 4.696h-2.862l1.363-4.696h2.407zM33.4 15.215a1.478 1.478 0 01-1.108-.4 1.443 1.443 0 01-.434-.772h2.892c-.167.643-.706 1.139-1.35 1.172zM36 13.634v-.177a.58.58 0 00-.042-.218l-2.385-5.87A.597.597 0 0033.02 7H7.981a.597.597 0 00-.554.37l-2.385 5.869a.58.58 0 00-.042.218v.177c0 1.197.745 2.21 1.788 2.59v16.602h-.596a.591.591 0 00-.596.587c0 .325.267.587.596.587h28.616c.33 0 .596-.262.596-.587a.591.591 0 00-.596-.587h-.596V16.223A2.742 2.742 0 0036 13.633z"
        fill={props.color || "#707070"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default SvgComponent;
