import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G
          transform="translate(12 10)"
          stroke={props.color || "#FFF"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        ></G>
        <G fill={props.color || "#FFF"} fillRule="nonzero">
          <Path
            d="M28.737 8.43L20.884.273a.908.908 0 00-1.558.632V4.83h-.303c-6.501.007-11.77 5.278-11.778 11.781v1.818a.895.895 0 00.706.866.809.809 0 00.2.024.937.937 0 00.828-.516 9.908 9.908 0 018.912-5.511h1.435v3.927a.909.909 0 001.558.628l7.853-8.16a.909.909 0 000-1.257z"
            transform="translate(5 5)"
          />
          <Path
            d="M25.367 29H3.623A3.634 3.634 0 010 25.366V8.456c.005-2 1.624-3.62 3.623-3.626h3.624a1.211 1.211 0 010 2.423H3.623c-.67 0-1.211.542-1.211 1.211v16.902a1.212 1.212 0 001.21 1.211h21.745c.669 0 1.21-.542 1.21-1.211v-9.662a1.211 1.211 0 112.423 0v9.662A3.635 3.635 0 0125.367 29z"
            transform="translate(5 5)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
