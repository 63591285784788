import * as React from "react";
import Svg, { Polygon, Rect, Defs, G, Mask, Use } from "react-native-svg";

function RightArrowWithTail(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
      </Defs>
      <G
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="Forward-arrow" transform="translate(5.000000, 6.000000)">
          <Mask id="mask-2" fill="white">
            <Use xlinkHref="#path-1"></Use>
          </Mask>
          <G id="Rectangle"></G>
          <G
            id="Forward_arrow"
            mask="url(#mask-2)"
            fill={props.color || "#707070"}
            fillRule="nonzero"
          >
            <Polygon
              id="Path_10"
              points="14.5 0 11.8628125 2.6371875 21.8424375 12.6168125 0 12.6168125 0 16.3831875 21.84425 16.3831875 11.8628125 26.3628125 14.5 29 29 14.5"
            ></Polygon>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default RightArrowWithTail;
