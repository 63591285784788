import * as React from "react";
import Svg, { Path, G } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function CustomIcon(props) {
  return (
    <Svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <G
        transform="translate(6 6)"
        stroke={props.color || "#333"}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M0 9.57728815L9.61142386 9.57728815 9.61142386 0 0 0z" />
        <Path d="M0 25.2409429L9.61142386 25.2409429 9.61142386 15.6636548 0 15.6636548z" />
        <Path d="M15.6185638 9.57728815L25.2299876 9.57728815 25.2299876 0 15.6185638 0z" />
        <Path d="M25.230308 18.0244563L25.230308 15.6636548 15.6188842 15.6636548 15.6188842 25.2409429 17.9624697 25.2409429" />
        <Path d="M28 25.2409429L22.460616 25.2409429" />
        <Path d="M25.230308 22.4802896L25.230308 28" />
      </G>
    </Svg>
  );
}

export default CustomIcon;
