import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function ChefIcon(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M20 5c-2.434 0-5.43.9-7.027 1.162-1.047.17-2.017.604-2.755 1.293A3.752 3.752 0 009 10.228c0 1.408.787 2.567 1.874 3.298l-.712 4.684a.603.603 0 00.037.282v.018c-.159.462-.377 1.26-.543 2.717v.02c-.267.409-.45.955-.45 1.648 0 1.35.949 2.438 2.193 2.755.808 2.094 1.81 4.328 3.148 6.108C15.94 33.611 17.744 35 20 35c2.256 0 4.06-1.389 5.453-3.242 1.338-1.78 2.34-4.014 3.148-6.108 1.251-.312 2.193-1.401 2.193-2.755 0-.719-.18-1.274-.45-1.686-.166-1.446-.384-2.24-.543-2.699.037-.096.049-.199.037-.3l-.712-4.684a4.562 4.562 0 001.087-1.012l.112-.094a.625.625 0 00.188-.374c.302-.537.487-1.15.487-1.818 0-1.124-.48-2.099-1.218-2.792-.738-.693-1.717-1.122-2.773-1.274C24.985 5.869 22.394 5 20 5zm0 12.76c2.628 0 5.997.547 7.796.881v2.961l.693-.112s.283-.047.543.075c.26.121.563.337.563 1.33a1.66 1.66 0 01-1.5 1.668l-.356.037-.13.356c-.807 2.12-1.84 4.38-3.112 6.071-1.271 1.692-2.733 2.774-4.497 2.774-1.764 0-3.226-1.082-4.497-2.774-1.272-1.69-2.287-3.951-3.092-6.07l-.132-.357-.374-.037a1.658 1.658 0 01-1.5-1.668c0-.946.29-1.16.563-1.293.271-.133.562-.112.562-.112l.674.075V18.64c1.8-.334 5.168-.88 7.796-.88z"
          fill="#707070"
        />
        <Path
          d="M20 6.237c2.082 0 4.636.824 6.84 1.143.834.12 1.579.466 2.099.955.52.49.824 1.108.824 1.893 0 .478-.11.92-.318 1.312-.101.086-.734.6-1.612.824-.956.244-2.078.204-3.298-1.368a.622.622 0 00-1.066.131.617.617 0 00.091.638c1.38 1.775 3.053 2.08 4.33 1.836l.599 3.935c-1.155-.237-5.057-1.012-8.489-1.012-3.432 0-7.334.775-8.489 1.012l.6-3.898c.044-.035.042-.037.225-.15.29-.18.768-.43 1.499-.655a.622.622 0 10-.263-1.218c-.032.009-.063.023-.093.037-.83.255-1.417.532-1.8.768-.084.052-.137.082-.205.132a2.796 2.796 0 01-1.237-2.324c0-.785.302-1.387.824-1.874.523-.487 1.277-.836 2.118-.974 1.78-.293 4.703-1.143 6.821-1.143z"
          stroke="#D97904"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
}

export default ChefIcon;
