import React from "react";
import {
  StackActions,
  TabActions,
  DrawerActions,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import _ from "lodash";
import navigationConfig from "@presto-app/navigationConfig";
import SessionHelper from "../helpers/SessionHelper";
import { Navigator } from "@presto-contexts/NavigatorContext";
import {
  NavigationContainer,
  NavigationIndependentTree,
} from "@react-navigation/native";
import config from "@presto-common/config";
import AnalyticsManager from "./AnalyticsManager";
import { Linking, Platform } from "react-native";
import qs from "qs";
const { v4: uuidv4 } = require("uuid");

const forFade = ({ current }) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

const WithIndependentTreeForWeb = ({ children }) => (
  <>
    {Platform.OS === "web" ? (
      <NavigationIndependentTree>{children}</NavigationIndependentTree>
    ) : (
      children
    )}
  </>
);

class NavigationManager {
  initialize = () => {
    this.reactNavigationRef = React.createRef();
    this.mobileNavigationConfig = navigationConfig["mobile"];
    this.desktopNavigationConfig = navigationConfig["desktop"];
    this.isDesktop = false;
    this.showModal = true;
    Navigator.addObserver(this);
  };

  get ref() {
    return this.reactNavigationRef;
  }

  handleNavigationEvent = (event, params = {}) => {
    if (!this.reactNavigationRef.current?.getRootState()) {
      return;
    }
    const { tabName, tabRootName, screenName, screenParams = {}, pop } = params;
    console.log("Handling Event :NavigationManager ", event, {
      tabName,
      tabRootName,
      screenName,
      screenParams,
      pop,
    });
    if (this.isDesktop && event === "push") {
      event = "modal";
    }
    AnalyticsManager.reportScreenOpened(screenName || tabName);
    switch (event) {
      case "openDrawer":
        this.handleOpenDrawer();
        break;
      case "closeDrawer":
        this.handleCloseDrawer();
        break;
      case "toggleDrawer":
        this.handleToggleDrawer();
        break;
      case "switchDrawerItem":
        this.handleSwitchDrawerItem(tabName, pop);
        break;
      case "jumpTab":
        this.handleJumpTab(tabName, screenName, screenParams);
        break;
      case "goBack":
        this.goBack();
        break;
      case "navigate":
        this.handleNavigate(screenName, screenParams);
        break;
      case "navigateExisting":
        this.reactNavigate(screenName, screenParams, true);
        break;
      case "replace":
        this.handleReplace(screenName + "_MODAL", screenParams);
        break;
      case "push":
        this.handleNavigate(screenName, screenParams);
        break;
      case "modal":
        this.handleNavigate(screenName + "_MODAL", screenParams);
        break;
      case "switchTab":
        this.switchTab(tabName, pop);
        break;
      case "closeModal":
        this.closeModal(tabName, pop);
        break;
      case "openModal":
        this.openModal(tabName, pop);
        break;
      case "popToTop":
        this.popToTop(tabName);
        break;
    }
  };
  closeModal = () => {
    this.showModal = false;
  };
  openModal = () => {
    this.showModal = true;
  };
  getModalStatus = () => {
    return this.showModal;
  };
  handleToggleDrawer = () => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.toggleDrawer());
  };

  handleCloseDrawer = () => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.closeDrawer());
  };

  handleOpenDrawer = () => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.openDrawer());
  };

  handleSwitchDrawerItem = (tabName, pop = false) => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.jumpTo(tabName));
    if (pop) {
      this.reactNavigationRef.current?.dispatch(
        StackActions.popToTop({ animationEnabled: false })
      );
    }
  };

  handleJumpTab = (tabName, tabRootName, screenName, params = {}) => {
    this.jumpTab(tabName, tabRootName, screenName, params);
  };

  handleNavigate = (screen, params, useExisting = false) => {
    this.reactNavigate(screen, params, useExisting);
  };
  handleReplace = (screen, params) => {
    this.reactReplace(screen, params);
  };

  handlePush = (screen, params) => {
    this.push(screen, params);
  };

  goBack = () => {
    if (this.reactNavigationRef.current?.canGoBack()) {
      this.reactNavigationRef.current?.goBack();
    } else {
      if (Platform.OS === "web") {
        location.href = "/";
      }
    }
  };

  handleEmit = ({ event, params }) => {
    if (event.display == "push") {
      this.handlePush({ screen: event.screen, params: params });
    } else if (event.display == "modal") {
      this.handleNavigate({ screen: event.screen + "_MODAL", params: params });
    } else {
      this.handleReplace({ screen: event.screen + "_MODAL", params: params });
    }
  };

  switchTab = (tabName, pop = false) => {
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    if (pop) {
      if (this.reactNavigationRef.current?.canGoBack()) {
        this.reactNavigationRef.current?.dispatch(
          StackActions.popToTop({ animationEnabled: false })
        );
      }
    }
  };

  popToTop = (tabName) => {
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    if (this.reactNavigationRef.current?.canGoBack()) {
      this.reactNavigationRef.current?.dispatch(
        StackActions.popToTop({ animationEnabled: false })
      );
    }
  };

  renderTree = (loggedIn, desktop) => {
    this.isDesktop = desktop;
    this.navigationConfig = this.mobileNavigationConfig;
    return this.createNavigationTree(loggedIn, desktop);
  };

  findModalScreens(navigationConfig, ignoreTabsAndDrawer) {
    return _.filter(
      _.map(_.keys(navigationConfig.screens), (key) => {
        return {
          name: key,
          screen: navigationConfig.screens[key],
        };
      })
    );
  }

  findPushScreens(screen, navigationConfig, exclude) {
    return _.filter(
      _.map(_.keys(navigationConfig.screens), (key) => {
        return {
          name: key,
          screen: navigationConfig.screens[key],
        };
      }),
      (a) => {
        return a.name !== exclude;
      }
    );
  }

  createTabRootScreensNavigationTree = (root) => {
    const tabScreens = root.tabScreens;
    console.log("Root : ", root);

    const screens = _.map(tabScreens, (tabScreen) => {
      const screensOfTab = this.findPushScreens(
        this.navigationConfig.screens[tabScreen.screen],
        this.navigationConfig,
        tabScreen.screen
      );

      let Stack = createStackNavigator();
      const tabScreenStack = _.map(screensOfTab, (tab) => {
        // console.log("Hello world : ", tab.name, tab.screen);
        const initialParams = _.omit(tab.screen, [
          "component",
          "header",
          "headerParams",
          "path",
        ]);
        const Header = tab.screen.header;
        return (
          <Stack.Screen
            key={tab.name}
            component={tab.screen.component}
            name={tab.name}
            initialParams={initialParams}
            options={{
              header: ({ scene, previous, navigation }) => {
                if (tab.screen.header) {
                  const { options } = scene.descriptor;
                  return (
                    <Header
                      scene={scene}
                      previous={previous}
                      options={options}
                      title={options.title || tab.screen.title}
                      {...tab.screen.headerParams}
                      navigation={navigation}
                    />
                  );
                } else {
                  return undefined;
                }
              },
            }}
          ></Stack.Screen>
        );
      });

      console.log("Tabscreen.screen : ", tabScreen.screen, root);
      const Header = this.navigationConfig.screens[tabScreen.screen].header;
      if (this.navigationConfig.screens[tabScreen.screen].type === "TABS") {
        const tabs = this.navigationConfig.screens[tabScreen.screen];
        console.log("Root2 : ", tabs);
        const screens = this.createTabRootScreensNavigationTree(tabs);
        console.log("Screens for screens : ", tabScreen.screen, tabs);
        const Component = tabs.component;
        return (
          <Stack.Navigator
            mode={"modal"}
            headerMode="none"
            screenOptions={{
              headerShown: false,
              cardStyle: { backgroundColor: "transparent" },
              cardOverlayEnabled: true,
            }}
          >
            <Stack.Screen
              component={Component}
              name={tabScreen.screen + tabs}
              initialParams={{
                screens: screens,
                tabConfigs: tabs.tabScreens,
                hideTabbar: false,
              }}
            />
            {tabScreenStack}
          </Stack.Navigator>
        );
      } else {
        return (
          <Stack.Navigator
            initialRouteName={tabScreen.screen}
            headerMode="screen"
          >
            <Stack.Screen
              component={
                this.navigationConfig.screens[tabScreen.screen].component
              }
              options={{
                header: ({ scene, previous, navigation }) => {
                  if (Header) {
                    const { options } = scene.descriptor;
                    return (
                      <Header
                        scene={scene}
                        previous={previous}
                        options={options}
                        title={
                          options.title ||
                          this.navigationConfig.screens[tabScreen.screen]
                            .component
                        }
                        {...this.navigationConfig.screens[tabScreen.screen]
                          .headerParams}
                        navigation={navigation}
                      />
                    );
                  } else {
                    return undefined;
                  }
                },
              }}
              name={tabScreen.screen}
            ></Stack.Screen>
            {tabScreenStack}
          </Stack.Navigator>
        );
      }
    });

    return screens;
  };

  findAllModalScreens = (root) => {
    return this.findModalScreens(this.navigationConfig);
  };

  checkAndNavigateToRightScreen = () => {
    if (this.isDesktop) {
      return {};
    }
    if (window && window.localStorage) {
      console.log("OPATH Init Home Page = " + window.localStorage.o_path);
      var storedHref = window.localStorage.o_path;

      //delete window.localStorage.o_path;
      if (storedHref && storedHref.indexOf("?o_path=") >= 0) {
        let params = {};
        var urlPath = storedHref
          .split("?o_path=")[1]
          .split("#")[0]
          .replace("checkout", "")
          .replace("order_details", "my_orders")
          .replace("subscription_details", "my_subscriptions");

        if (urlPath != "") {
          if (urlPath[0] == "/") {
            urlPath = urlPath.substr(1);
          }
          if (_.includes(urlPath, "?")) {
            const splits = urlPath.split("?");
            urlPath = splits[0];
            params = qs.parse(splits[1]);
          }
          var currentPath = decodeURIComponent(urlPath);
          return { path: currentPath, params };
        }
      }
    }
    return {};
  };

  findMain = (loggedIn) => {
    if (this.navigationConfig["main"]) {
      return this.navigationConfig.main;
    } else {
      return this.navigationConfig.logged_out && !loggedIn
        ? this.navigationConfig.logged_out
        : this.navigationConfig.logged_in;
    }
  };

  generateLinkingForScreen = (modal, all, toplevel) => {
    if (modal.screen.type === "DRAWER") {
      return {
        DRAWER: {
          path: "/",
          screens: {
            HOME: {
              path: "/",
              screens: {
                HOME_SCREEN: {
                  path: "home",
                },
              },
            },
          },
        },
      };
    } else if (modal.screen.type === "TABS") {
      const screens = {};
      _.forEach(modal.screen.tabScreens, (tabScreen) => {
        screens[tabScreen.screen] = {
          path: tabScreen.path,
        };
      });
      return {
        TABS: {
          path: "/",
          screens: {
            HOME: {
              path: "/",
              screens,
            },
          },
        },
      };
    } else {
      if (toplevel) {
        return {
          [modal.name + "_MODAL"]: {
            path: modal.screen.path,
          },
        };
      }
    }
  };

  generateLinking = (loggedIn) => {
    if (this.isDesktop) {
      return undefined;
    }
    const modals = this.findAllModalScreens();
    let config = {
      screens: {},
    };
    _.forEach(modals, (modal) => {
      const c = this.generateLinkingForScreen(modal, modals, true);
      config = {
        ...config,
        screens: {
          ...config.screens,
          ...c,
        },
      };
    });
    return {
      config,
    };
  };

  findScreenFromPath = (path, linking) => {
    if (path === undefined) {
      return;
    }
    let m = undefined;
    _.forEach(_.keys(linking.config), (k) => {
      const screen = linking.config[k];

      if (screen.path === path) {
        m = k;
      }
    });
    if (m) return m;
  };

  renderModalScreen = (msc, RootStack, initialRoute) => {
    const Header = msc.screen.header;
    const Component = msc.screen.component;
    const type = msc.screen.type;

    if (type === "TABS") {
      return (
        <RootStack.Screen
          component={Component}
          name={msc.name}
          initialParams={{
            screens: [],
            tabConfigs: msc.screen.tabScreens,
            hideTabbar: false,
          }}
        />
      );
    } else {
      // console.log("names ", msc.name);
      return (
        <RootStack.Screen
          key={`${msc.name + "_MODAL"}`}
          component={msc.screen.component}
          name={`${msc.name + "_MODAL"}`}
          initialParams={
            `${msc.name + "_MODAL"}` === initialRoute
              ? initialParams
              : undefined
          }
          options={{
            headerShown: !!Header,
            header: ({ scene, previous, navigation }) => {
              if (Header) {
                const { options } = scene.descriptor;
                return (
                  <Header
                    scene={scene}
                    previous={previous}
                    options={options}
                    title={options.title || msc.screen.title}
                    {...msc.screen.headerParams}
                    navigation={navigation}
                  />
                );
              } else {
                return undefined;
              }
            },
            cardStyleInterpolator: msc.screen?.fadeIn ? forFade : undefined,
          }}
        />
      );
    }
  };

  createNavigationTree = (loggedIn, independent = false) => {
    const linking = this.generateLinking(loggedIn);
    let Main = this.findMain(loggedIn);

    const {
      path: initialPath,
      params: initialParams,
    } = this.checkAndNavigateToRightScreen();

    const initialRoute =
      !this.isDesktop && initialPath
        ? this.findScreenFromPath(initialPath, linking)
        : undefined;

    const root = this.navigationConfig.screens[Main];
    const RootStack = createStackNavigator();
    const modalScreens = this.findAllModalScreens(root);

    if (root?.type === "TABS") {
      const Component = root.component;
      const screens = this.createTabRootScreensNavigationTree(root);
      const component = (
        <WithIndependentTreeForWeb>
          <NavigationContainer
            documentTitle={{
              formatter: () =>
                Platform.OS == "web"
                  ? config.web?.metatags?.title || config.applicationName
                  : config.applicationName,
            }}
            linking={independent ? undefined : linking}
            ref={this.ref}
            key={`user-${loggedIn}-${Main}`}
            independent={independent}
          >
            <RootStack.Navigator
              mode={"modal"}
              initialRouteName={initialRoute ? initialRoute : Main}
              headerMode="none"
              screenOptions={{
                headerShown: false,
                cardStyle: { backgroundColor: "transparent" },
                cardOverlayEnabled: true,
              }}
            >
              <RootStack.Screen
                component={Component}
                name={Main}
                initialParams={{
                  screens: screens,
                  tabConfigs: root.tabScreens,
                  hideTabbar: independent,
                }}
              />
              {_.map(modalScreens, (msc) => {
                const Header = msc.screen.header;
                return (
                  <RootStack.Screen
                    key={`${msc.name + "_MODAL"}`}
                    component={msc.screen.component}
                    name={`${msc.name + "_MODAL"}`}
                    initialParams={
                      `${msc.name + "_MODAL"}` === initialRoute
                        ? initialParams
                        : undefined
                    }
                    options={{
                      headerShown: !!Header,
                      header: ({ scene, previous, navigation }) => {
                        if (Header) {
                          const { options } = scene.descriptor;
                          return (
                            <Header
                              scene={scene}
                              previous={previous}
                              options={options}
                              title={options.title || msc.screen.title}
                              {...msc.screen.headerParams}
                              navigation={navigation}
                            />
                          );
                        } else {
                          return undefined;
                        }
                      },
                      cardStyleInterpolator: msc.screen?.fadeIn
                        ? forFade
                        : undefined,
                    }}
                  />
                );
              })}
            </RootStack.Navigator>
          </NavigationContainer>
        </WithIndependentTreeForWeb>
      );
      return component;
    } else if (root?.type === "DRAWER") {
      const Component = root.component;
      const screens = this.createTabRootScreensNavigationTree(root);
      const component = (
        <WithIndependentTreeForWeb>
          <NavigationContainer
            documentTitle={{
              formatter: () =>
                Platform.OS == "web"
                  ? config.web?.metatags?.title || config.applicationName
                  : config.applicationName,
            }}
            ref={this.ref}
            linking={independent ? undefined : linking}
            independent={independent}
            key={`user-${loggedIn}`}
          >
            <RootStack.Navigator
              mode="modal"
              initialRouteName={initialRoute ? initialRoute : Main}
              screenOptions={{
                headerShown: false,
                cardStyle: { backgroundColor: "transparent" },
                cardOverlayEnabled: true,
              }}
            >
              <RootStack.Screen
                component={Component}
                name={Main}
                initialParams={{
                  screens: screens,
                  tabConfigs: root.tabScreens,
                }}
              />
              {_.map(modalScreens, (msc) => {
                return this.renderModalScreen(msc, RootStack, initialRoute);
              })}
            </RootStack.Navigator>
          </NavigationContainer>
        </WithIndependentTreeForWeb>
      );
      return component;
    } else {
      const Component = root.component;
      const component = (
        <WithIndependentTreeForWeb>
          <NavigationContainer
            documentTitle={{
              formatter: () =>
                Platform.OS == "web"
                  ? config.web?.metatags?.title || config.applicationName
                  : config.applicationName,
            }}
            theme={{
              colors: {
                background: "transparent",
              },
            }}
            key={`user-${loggedIn}`}
            linking={independent ? undefined : linking}
            independent={true}
            ref={this.ref}
          >
            <RootStack.Navigator
              mode="modal"
              initialRouteName={initialRoute ? initialRoute : Main + "_MODAL"}
              screenOptions={{
                headerShown: false,
                cardStyle: { backgroundColor: "transparent" },
                cardOverlayEnabled: true,
              }}
            >
              <RootStack.Screen
                component={Component}
                name={Main}
                options={{ headerShown: false }}
              />
              {_.map(modalScreens, (msc) => {
                return (
                  <RootStack.Screen
                    key={`${msc.name + "_MODAL"}`}
                    component={msc.screen.component}
                    name={`${msc.name + "_MODAL"}`}
                    options={
                      msc.screen?.fadeIn
                        ? { cardStyleInterpolator: forFade }
                        : {}
                    }
                  />
                );
              })}
            </RootStack.Navigator>
          </NavigationContainer>
        </WithIndependentTreeForWeb>
      );
      return component;
    }
  };

  reactNavigate = (screen, params, useExisting = false) => {
    const attributes = {
      name: screen,
      params,
      key: useExisting ? undefined : uuidv4(),
    };
    console.log("Pushing : ", attributes);
    this.reactNavigationRef.current?.navigate(attributes);
  };

  reactReplace = (name, params) => {
    this.reactNavigationRef.current?.dispatch(
      StackActions.replace(name, params)
    );
    //this.reactNavigationRef.current?.replace(...args);
  };

  push = (...args) => {
    this.reactNavigationRef.current?.dispatch(StackActions.push(...args));
    //this.reactNavigationRef.current.push(...args);
  };

  jumpTab = (tabName, screenName, params = {}) => {
    const tabRootName = _.find(
      this.navigationConfig.screens["TABS"].tabScreens,
      (ts) => {
        return ts.name === tabName;
      }
    );

    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    const timeout = 300;
    if (screenName) {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        } else {
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        }
      }, timeout);
    } else {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
        }
      }, timeout);
    }
  };

  // TODO(amal) : ubu might not work
  navigateTo = (targetUrl, value) => {
    if (targetUrl === "app/offers") {
      SessionHelper.isLoggedInUser(
        () => {
          this.jumpTab("PROFILE", "OFFER_SCREEN");
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (targetUrl === "app/shop") {
      this.jumpTab("NEARBY_VENDORS");
    } else if (_.startsWith(targetUrl, "app/vendor")) {
      const vendorId = _.split(targetUrl, "/")[2];
      this.push("VENDOR", {
        vendor: { id: vendorId },
      });
    } else if (_.startsWith(targetUrl, "app/categoryItem")) {
      console.log(targetUrl);
      const merchantId = _.split(targetUrl, "/")[2];
      const categoryId = _.split(targetUrl, "/")[3];
      const itemId = _.split(targetUrl, "/")[4];
      this.jumpTab("DISCOVER", "DISCOVER_PAGE", "VENDOR_PAGE", {
        vendorId: merchantId,
        itemId: itemId,
        categoryId: categoryId,
      });
    } else if (_.startsWith(targetUrl, "app/searchCatalog")) {
      const categoryId = _.split(targetUrl, "/")[2];
      this.jumpTab("DISCOVER", "DISCOVER_PAGE", "VENDOR_PAGE", {
        vendorId: categoryId,
      });
    } else if (targetUrl === "app/wallet") {
      SessionHelper.isLoggedInUser(
        () => {
          this.jumpTab("WALLET");
        },
        () => {}
      );
    } else if (targetUrl === "app/home") {
      this.jumpTab("HOME");
    } else if (targetUrl === "app/profile") {
      SessionHelper.isLoggedInUser(
        () => {
          this.jumpTab("PROFILE");
        },
        () => {}
      );
    } else if (_.startsWith(targetUrl, "app/item/")) {
      const itemId = _.split(targetUrl, "/")[2];
      this.push("ITEM_DETAIL_SCREEN", {
        itemId: itemId || null,
      });
    } else if (targetUrl == "app/deals") {
      SessionHelper.isLoggedInUser(
        () => {
          this.push("VIEW_ALL_DEALS");
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/category")) {
      const categoryId = _.split(targetUrl, "/")[2];

      SessionHelper.isLoggedInUser(
        () => {
          if (categoryId) {
            this.push("SEARCH_RESULTS_SCREEN_MODAL", {
              searchParams: {
                category: { id: categoryId },
              },
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/itemDetails/")) {
      const id = _.split(targetUrl, "/")[2];

      SessionHelper.isLoggedInUser(
        () => {
          if (id) {
            this.push("DETAILED_ITEM_MODAL", {
              itemId: id,
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/brand")) {
      const brandName = _.split(targetUrl, "/")[2];
      SessionHelper.isLoggedInUser(
        () => {
          if (brandName) {
            this.push("SEARCH_RESULTS_SCREEN_MODAL", {
              searchParams: {
                brand_name: brandName,
              },
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/company")) {
      const name = _.split(targetUrl, "/")[2];
      SessionHelper.isLoggedInUser(
        () => {
          if (name) {
            this.push("SEARCH_RESULTS_SCREEN_MODAL", {
              searchParams: {
                company_name: name,
              },
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/molecule")) {
      const name = _.split(targetUrl, "/")[2];
      SessionHelper.isLoggedInUser(
        () => {
          if (name) {
            this.push("SEARCH_RESULTS_SCREEN_MODAL", {
              searchParams: {
                molecule: name,
              },
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/therapy")) {
      const name = _.split(targetUrl, "/")[2];
      SessionHelper.isLoggedInUser(
        () => {
          if (name) {
            this.push("SEARCH_RESULTS_SCREEN_MODAL", {
              searchParams: {
                therapy_area_simple: name,
              },
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/cart")) {
      SessionHelper.isLoggedInUser(
        () => {
          this.jumpTab("CART");
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/login")) {
      SessionHelper.isLoggedInUser(
        (resp) => {
          console.log("response", resp);
        },
        (error) => {
          setTimeout(() => {
            let params = {
              id: value,
            };
            this.handleNavigate("LOGIN_SCREEN_MODAL", params);
          }, 1000);
        }
      );
    } else if (_.startsWith(targetUrl, "app/autologin")) {
      setTimeout(() => {
        this.handleNavigate("AUTO_LOGIN_MODAL", value);
      }, 1000);
    } else if (_.startsWith(targetUrl, "order_notifications")) {
      const orderId = _.split(targetUrl, "/")[1];
      SessionHelper.isLoggedInUser(
        () => {
          if (orderId) {
            setTimeout(() => {
              Navigator.emit({
                event: "modal",
                params: {
                  screenName: "TRACK_ORDER_SCREEN",
                  screenParams: { orderId: orderId },
                },
              });
            }, 1000);
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "notifications")) {
      SessionHelper.isLoggedInUser(
        () => {
          setTimeout(() => {
            Navigator.emit({
              event: "modal",
              params: {
                screenName: "NOTIFICATIONS_SCREEN",
                screenParams: {},
              },
            });
          }, 1000);
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/loyalty")) {
      SessionHelper.isLoggedInUser(
        () => {
          setTimeout(() => {
            Navigator.emit({
              event: "jumpTab",
              params: {
                tabName: "ACCOUNT",
                screenName: "LOYALTY_POINT_TRANSACTIONS_SCREEN_MODAL",
              },
            });
          }, 1000);
        },
        () => {
          this.push("LOGIN");
        }
      );
    } else if (_.startsWith(targetUrl, "app/search")) {
      const name = _.split(targetUrl, "/")[2];
      SessionHelper.isLoggedInUser(
        () => {
          if (name) {
            this.push("SEARCH_RESULTS_SCREEN_MODAL", {
              searchParams: {
                query: name,
              },
            });
          }
        },
        () => {
          this.push("LOGIN");
        }
      );
    }
  };

  jumpNewTab = (tabName, tabRootName, screenName, params = {}) => {
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    const timeout = 600;

    if (screenName) {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        } else {
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        }
      }, timeout);
    } else {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
        }
      }, timeout);
    }
  };
}

export default new NavigationManager();
