import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function GoFindIcon(props) {
  return (
    <Svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <Defs>
        <Path id="a" d="M0 0H54V49H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Rect fill="#FFF" x={0} y={0} width={62} height={62} rx={31} />
      </G>
    </Svg>
  );
}

export default GoFindIcon;
