import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(5 5)">
          <Mask id="b" fill={props.color2 || "#fff"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <Path fill="none" d="M0 0.000639791772H29V29.000639791772H0z" />
            <Path
              d="M21.75 0c.482-.013.946.186 1.269.544.358.323.557.787.544 1.27v25.374a1.858 1.858 0 01-1.813 1.813H7.25a1.646 1.646 0 01-1.269-.544 1.646 1.646 0 01-.543-1.269V1.813A1.646 1.646 0 015.98.544 1.646 1.646 0 017.25.001h14.5zm-1.269 21.75V4.895H8.52v16.857H20.48zm-7.25 4.894c.323.359.787.558 1.269.544.482.014.946-.185 1.269-.544a1.753 1.753 0 000-2.537 1.646 1.646 0 00-1.269-.544 1.646 1.646 0 00-1.269.544 1.753 1.753 0 000 2.537z"
              fill={props.color || "#707070"}
              fillRule="nonzero"
            />
          </G>
        </G>
        <G fill={props.color2 || "#fff"} stroke={props.color || "#707070"}>
          <Path
            d="M5.241 17.193L.74 12.57C2 8.334 7.529 8.538 8.789 4.302l4.504 4.624c-1.262 4.234-6.79 4.031-8.052 8.267z"
            transform="translate(19.975 8.045) rotate(7 7.016 10.748)"
          />
          <Path
            d="M5.26 13.608L.755 8.984C2.018 4.75 7.546 4.953 8.807.717l4.504 4.623c-1.262 4.235-6.79 4.032-8.052 8.268z"
            transform="translate(19.975 8.045) rotate(7 7.034 7.162)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
