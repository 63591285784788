import * as React from "react";
import Svg, { G, Path, Text, TSpan } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#333" stroke="none" strokeWidth={1} fillRule="evenodd">
        <G fillRule="nonzero">
          <Path
            d="M15.295 0L27.38 12.066l-1.248.415c-3.541 1.179-5.327 3.044-7.797 7.35l-.788 1.398a.814.814 0 01-1.417-.798l.529-.94.533-.93c1.101-1.878 2.106-3.321 3.211-4.46-1.412-2.04-1.247-3.989-.083-5.619.181-.254.364-.468.531-.641L17.63 4.629c-2.246 1.952-4.609 1.865-6.845-.26-1.122 1.227-2.327 2.936-3.827 5.292L5.89 11.346c-1.985 3.1-3.216 4.723-4.729 5.988l-.028.022-.001-.012a.215.215 0 00-.034-.064L0 16.184c1.47-1.2 2.685-2.822 4.797-6.149l1.084-1.708c3.41-5.3 5.449-7.438 8.705-8.181l.283-.06.426-.086zm6.129 9.268c-.82 1.149-.963 2.433-.014 3.905a11.712 11.712 0 012.746-1.672l.25-.108-2.596-2.594a4.349 4.349 0 00-.386.47zM14.77 1.772l-.072.02c-1.062.285-1.998.79-2.949 1.625 1.684 1.59 3.25 1.675 4.92.254L14.77 1.772z"
            transform="translate(-4 1) rotate(45 14.832 35.756)"
          />
          <Path
            d="M16.012 0l12.85 12.63-1.329.436c-2.676.876-4.41 2.117-6.131 4.38-.7.92-1.98 1.43-2.739 2.734l-1.525 1.963c-.234.408-.789.687-1.205.456-.416-.23.134-.882.37-1.291l.662-.814.811-1.2c1.186-1.99 2.333-3.48 3.59-4.643-1.419-2.097-1.228-4.095-.012-5.772.193-.266.387-.49.565-.671l-3.424-3.363c-2.389 2.044-4.901 1.953-7.28-.27-1.193 1.283-2.473 3.073-4.068 5.538L6.01 11.877c-2.364 3.634-2.965 6.135-4.865 7.535-.254.188-.392.325-.413.413-.674-.78-.883-1.265-.628-1.453 1.563-1.256 2.498-4.385 4.744-7.867l1.153-1.789C9.626 3.17 11.795.93 15.258.152l.3-.063.454-.089zm6.516 9.702c-.86 1.186-1.02 2.511-.054 4.026a14.743 14.743 0 012.96-1.69l.265-.112-2.761-2.715c-.125.13-.267.294-.41.491zm-7.075-7.847l-.076.02c-1.13.299-2.125.828-3.136 1.702 1.79 1.665 3.456 1.753 5.232.265l-2.02-1.987z"
            transform="translate(-4 1) rotate(-135 18.29 15.279)"
          />
        </G>
        <Text
          fontFamily="Helvetica"
          fontSize={15}
          fontWeight="normal"
          transform="translate(-4 1)"
        >
          <TSpan x={20.369391} y={24.5701111}>
            {"\u20B9"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default SvgComponent;
