import * as React from "react";
import Svg, { Defs, Rect, G, Circle, Mask, Use } from "react-native-svg";

function EndServiceIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
        <Rect id="path-3" x="0" y="0" width="23" height="23"></Rect>
      </Defs>
      <G
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="icon/colour/End-service">
          <Circle
            id="Oval"
            stroke="#E21111"
            strokeWidth="0.9"
            fill="#E21111"
            cx="20"
            cy="20"
            r="17.55"
          ></Circle>
          <G id="Forward-arrow" transform="translate(5.000000, 6.000000)">
            <Mask id="mask-2" fill="white">
              <Use xlinkHref="#path-1"></Use>
            </Mask>
            <G id="Rectangle"></G>
          </G>
          <G id="No" transform="translate(9.000000, 9.000000)">
            <Mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </Mask>
            <G id="Rectangle"></G>
          </G>
          <Rect
            id="Rectangle"
            fill="#FFFFFF"
            x="12"
            y="12"
            width="16"
            height="16"
            rx="2"
          ></Rect>
        </G>
      </G>
    </Svg>
  );
}

export default EndServiceIcon;
