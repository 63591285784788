import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CartIcon1(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M31.249 28.565H14.065h-.002c-.352 0-.684-.137-.932-.387a1.314 1.314 0 01-.391-.939c0-.357.137-.686.387-.936a1.32 1.32 0 01.939-.393H31.076a.563.563 0 00.549-.428l3.779-15.117a.575.575 0 00-.102-.484.574.574 0 00-.445-.215h-24.16l-.814-3.24c-.016-.053-.066-.078-.092-.127-.037-.062-.062-.113-.119-.158-.062-.053-.131-.066-.207-.088C9.418 6.039 9.387 6 9.336 6H5.561A.564.564 0 005 6.562c0 .312.25.562.561.562h3.338l4.48 17.797c-.387.113-.754.289-1.049.584a2.432 2.432 0 00-.715 1.732c0 .658.256 1.273.721 1.736.066.066.154.096.229.154-.074.057-.166.086-.232.152a2.452 2.452 0 001.732 4.185h.006a2.442 2.442 0 002.449-2.447c0-.502-.188-.941-.445-1.328h13.166c-.262.395-.443.838-.443 1.328a2.451 2.451 0 002.449 2.447h.008a2.43 2.43 0 001.73-.717c.463-.465.717-1.08.717-1.73a2.454 2.454 0 00-2.453-2.452zm-.611-3.779H14.509l-2.553-10.213h21.238l-2.556 10.213zm3.5-13.994l-.664 2.656H11.673l-.664-2.656h23.129zM15.396 31.02a1.322 1.322 0 01-1.324 1.323h-.008a1.327 1.327 0 111.332-1.323zm16.794.936c-.252.25-.586.387-.936.387h-.01a1.327 1.327 0 01-.937-2.264c.252-.25.588-.387.947-.387.729 0 1.322.594 1.322 1.328a1.31 1.31 0 01-.386.936z"
        fill={props.color || "#333"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default CartIcon1;
