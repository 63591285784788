import React, { useContext, useState, useEffect } from "react";
import {
  View,
  ScrollView,
  Dimensions,
  SafeAreaView,
  Platform,
} from "react-native";
import asyncify from "../../common/Asyncify";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import TestimonialsComponent from "@presto-screen-components/Testimonials/Testimonials";
import CarouselGallery from "@presto-screen-components/CarouselGallery/CarouselGallery";
import CategoriesSection from "@presto-screen-components/CategoriesSection/CategoriesSection";
import ShopCategoriesSection from "@presto-screen-components/ShopCategoriesSection/ShopCategoriesSection";
import OnOfferItems from "@presto-screen-components/OnOfferItems/OnOfferItems";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import BestSellers from "@presto-screen-components/BestSellers/BestSellers";
import SearchComponent from "@presto-screen-components/Home/SearchComponent";
import OffersManager from "@presto-services/features/offers/OffersManager";
import I18n from "i18n-js";
const AsyncOfferManager = asyncify(OffersManager);
import MarqeeText from "@presto-components/Marquee/Marquee";
import AnalyticsManager from "../../common/AnalyticsManager";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import * as Linking from "expo-linking";
import ProfileHighlight from "@presto-screen-components/ProfileHighlight/ProfileHighlight";
import LoadingContainer from "@presto-components/LoadingContainer";
import OrderComponent from "@presto-screen-components/Home/OrderComponent";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import utils from "../../utils";

export default function Home({ navigation }) {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [rollingText, setRollingText] = useState("");
  const [itemPromotions, setItemPromotions] = useState(null);
  const [loading, setLoading] = useState(false);
  const windoHeight = Dimensions.get("window").height;
  const [extraOptions, setExtraOptions] = useState({
    miscellaneousCount: 0,
    testimonialsCount: 0,
  });
  const { WebNavigator } = useContext(WebNavigatorContext);
  const isWeb = Platform.OS == "web";
  const isMobileWeb = utils.isMobileWeb();

  useEffect(() => {
    getRollingText();
  }, []);

  const getRollingText = async () => {
    const [error, response] = await AsyncOfferManager.getHomePageOffers();
    if (response) {
      setRollingText(response.data.offer_text);
    }
  };
  const onSearchFocus = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SEARCH",
      },
    });
  };
  const showLogin = () => {
    Navigator.emit({
      event: "modal",
      params: { screenName: "LOGIN" },
    });
  };
  const onMiscClick = (item) => {
    if (!user) {
      showLogin();
      return;
    }
    Navigator.emit({
      event: "modal",
      params: { tabName: "REFER", screenName: "REFERRAL_SCREEN" },
    });
  };
  const getItemPromotions = () => {
    ServerCartManager.getProbableItemPromotions(
      {},
      (resp) => {
        console.log("resp :>> ", JSON.stringify(resp));
        setItemPromotions(resp);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const getBottomPadding = () => {
    let pad = 0;

    if (
      extraOptions.miscellaneousCount === 0 &&
      extraOptions.testimonialsCount === 0
    ) {
      pad = 80;
    }
    if (Platform.OS === "web") {
      pad += 150;
    }
    return pad;
  };

  const onOfferClick = (item) => {
    AnalyticsManager.send("click_on_banner_card", { banner: "Offers" });
    if (item.target?.type) {
      switch (item.target?.type) {
        case "category":
          if (isWeb && !isMobileWeb) {
            WebNavigator.emit({
              event: "modal",
              params: {
                screenName: "BROWSE_CATEGORIES",
                screenParams: {
                  categoryId: item.other_data.category_id,
                  subCategoryId: item.target.id_value,
                },
              },
            });
          } else {
            Navigator.emit({
              event: "push",
              params: {
                screenName: "BROWSE_CATEGORIES",
                screenParams: {
                  categoryId: item.other_data.category_id,
                  subCategoryId: item.target.id_value,
                },
              },
            });
          }
          return;
        case "url":
          Linking.openURL(item.target.id_value);
          return;
      }
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH",
          screenParams: {
            searchParams: {
              searchString: item?.other_data?.company_name,
            },
          },
        },
      });
    }
  };

  return (
    <LoadingContainer loading={loading}>
      <View
        style={{
          flex: 1,
          backgroundColor: theme.appBackgroundColor,
          zIndex: 100,
        }}
      >
        <View
          style={{
            paddingHorizontal: theme.primaryPadding,
            marginVertical: 10,
          }}
        >
          <SearchComponent
            query={""}
            placeholder={I18n.t(`screen_messages.common.search_for_an_item`)}
            showSoftInputOnFocus={false}
            onSearchFocus={onSearchFocus}
            textInputStyle={{
              paddingHorizontal: 8,
            }}
          />
        </View>
        <ScrollView
          scrollEnabled={true}
          style={{ width: "100%", height: windoHeight }}
          contentContainerStyle={{
            paddingBottom: getBottomPadding(),
          }}
        >
          {user ? <ProfileHighlight setParentLoading={setLoading} /> : null}
          {rollingText ? <MarqeeText text={rollingText} /> : null}
          <OrderComponent user={user} />
          <CategoriesSection theme={theme} />
          <CarouselGallery
            title=""
            name="OFFERS"
            onItemClicked={onOfferClick}
          />
          <OnOfferItems
            getItemPromotions={getItemPromotions}
            promotions={itemPromotions}
            fromScreen="home"
          />
          <View>
            <ShopCategoriesSection theme={theme} />
          </View>

          <BestSellers
            getItemPromotions={getItemPromotions}
            promotions={itemPromotions}
          />
          <CarouselGallery
            title=""
            name="MISCELLANEOUS"
            onItemClicked={onMiscClick}
            fontStyle="700.bold"
            fontSize={18}
            setItemCount={(count) =>
              setExtraOptions({ ...extraOptions, miscellaneousCount: count })
            }
          />
          <TestimonialsComponent
            fontStyle="700.bold"
            fontSize={18}
            setItemCount={(count) =>
              setExtraOptions({ ...extraOptions, testimonialsCount: count })
            }
          />
        </ScrollView>
        <FloatingCartComponent
          bottom={Platform.OS === "web" ? 80 : undefined}
        />
      </View>
    </LoadingContainer>
  );
}
