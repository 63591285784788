import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="34px"
      height="34px"
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <LinearGradient
          x1="17.1759193%"
          y1="28.694711%"
          x2="75.6338147%"
          y2="71.8862457%"
          id="a"
        >
          <Stop stopColor="#FFF" stopOpacity={0.24} offset="0%" />
          <Stop stopColor="#FFF" stopOpacity={0.71} offset="100%" />
        </LinearGradient>
      </Defs>
      <G
        transform="translate(-282 -125) translate(283 125)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          stroke="#FFF"
          strokeWidth={2}
          fillOpacity={0.71}
          fill="#FFF"
          opacity={0.7}
          strokeLinecap="round"
          d="M0.666666667 32.5L31.3366667 32.5"
        />
        <Path
          d="M0 23V8.938a1 1 0 011.816-.578l3.778 5.33a1 1 0 001.658-.04l7.901-12.329a1 1 0 011.687.004l7.81 12.325a1 1 0 001.656.05l3.883-5.388A1 1 0 0132 8.897V23a1 1 0 01-1 1H1a1 1 0 01-1-1z"
          fill="url(#a)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
