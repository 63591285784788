import * as React from "react"
import Svg, { G, Circle } from "react-native-svg"

function PayCircleIcon(props) {
  return (
    <Svg
      width={props.width || "62px"}
      height={props.height || "62px"}
      viewBox="0 0 62 62"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke="#007469"
          strokeWidth={8}
          fill="#FFF"
          cx={31.5}
          cy={30.5}
          r={25.5}
        />
        <Circle fill="#007469" cx={31.5} cy={30.5} r={9} />
      </G>
    </Svg>
  )
}

export default PayCircleIcon
