import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={22} height={30} viewBox="0 0 22 30" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M12.59 11.25h8.838L15.27.472A.937.937 0 0014.456 0h-3.838a.937.937 0 00-.901 1.195l2.872 10.055zM9.717 28.805a.937.937 0 00.9 1.195h3.838a.938.938 0 00.814-.472l6.158-10.778H12.59L9.72 28.805z"
        />
        <Path d="M28.125 18.75H6.562l-2.53 3.375a.938.938 0 01-.75.375H.936a.938.938 0 01-.908-1.165L1.875 15 .029 8.665A.937.937 0 01.937 7.5h2.344a.938.938 0 01.75.375l2.531 3.375h21.563c2.071 0 5.625 1.679 5.625 3.75 0 2.071-3.554 3.75-5.625 3.75z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h33.75v30H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
