import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import SectionLinkTextComponent from "@presto-components/SectionLinks/SectionLinkTextComponent";
import I18n from "i18n-js";
export default function RegisterText({ onSignup }) {
  const { theme } = useContext(ThemeContext);
  return (
    <SectionLinkTextComponent
      leftText1={I18n.t("screen_messages.register.register_btn_desc")}
      leftText2={I18n.t("screen_messages.register.register_btn")}
      onPressButton={() => onSignup()}
      theme={theme}
      linkTextColor={theme.secondary}
    />
  );
}
