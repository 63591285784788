import React from "react";
import { Pressable } from "react-native";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function PrestoPressable({
  eventName,
  eventAttr,
  onPress,
  ...props
}) {
  return (
    <Pressable
      {...props}
      onPress={() => {
        if (eventName) {
          AnalyticsManager.send(eventName, eventAttr);
        }
        onPress && onPress();
      }}
    />
  );
}
