import * as React from "react";
import Svg, { G, Defs, Rect, Mask, Use, Circle } from "react-native-svg";

function PlusIconWithCircle(props) {
  const fillColor = props?.fillColor || "#707070";
  const strokeColor = props?.strokeColor || "#707070";

  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Defs>
        <Circle id="path-1" cx="20" cy="20" r="15"></Circle>
      </Defs>
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/add-appointment">
          <Mask id="mask-2" fill="white">
            <Use xlinkHref="#path-1"></Use>
          </Mask>
          <Use
            id="Oval"
            stroke="#707070"
            stroke-width="1.5"
            xlinkHref="#path-1"
          ></Use>
          <G id="Group" mask="url(#mask-2)" fill="#707070" stroke="#707070">
            <G transform="translate(11.250000, 11.000000)">
              <Rect
                id="Rectangle"
                x="8.25"
                y="0.5"
                width="1"
                height="16"
              ></Rect>
              <Rect
                id="Rectangle-Copy"
                transform="translate(8.500000, 8.500000) rotate(90.000000) translate(-8.500000, -8.500000) "
                x="8.25"
                y="0.5"
                width="1"
                height="16"
              ></Rect>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default PlusIconWithCircle;
