import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const OffersResource = function ($http, PrestoSdk) {
  function getHomePageOffers() {
    var url =
      PrestoSdk.getHostName() +
      "/apps/" +
      PrestoSdk.getAppId() +
      "/home_page_offer.json";
    return $http.get(url);
  }
  return {
    getHomePageOffers: getHomePageOffers,
  };
};

export default OffersResource($http, PrestoSdk);
