import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";

const LedgerReportResource = function ($http, PrestoSdk) {
  function list(params) {
    var url = PrestoSdk.getHostName() + `/user/v1/ledger_tasks.json`;
    return $http.get(url, params);
  }

  function create(params) {
    var url = PrestoSdk.getHostName() + "/user/v1/ledger_tasks.json";
    return $http.post(url, params);
  }

  function show(params) {
    var url =
      PrestoSdk.getHostName() + `/user/v1/ledger_tasks/${params.id}.json`;
    return $http.get(url);
  }

  function downloadCSV(params) {
    var url =
      PrestoSdk.getHostName() +
      `/user/v1/ledger_tasks/${params.id}/download_csv.json`;
    return $http.get(url);
  }

  function downloadAccountStatement(params) {
    // TODO: filter_by choices: Status.All, Status.Outstanding
    var url =
      PrestoSdk.getHostName() +
      `/user/v1/account_statements/download?from_date=${params.from_date}&to_date=${params.to_date}&filter_by=${params.filter_by}`;
    return $http.get(url);
  }

  return {
    list,
    create,
    show,
    downloadCSV,
    downloadAccountStatement,
  };
};

export default LedgerReportResource($http, PrestoSdk);
