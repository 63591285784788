import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import CategoryObject from "./CategoryObject";
import ServiceItemObject from "./ServiceItemObject";
import CategoryItemObject from "./CategoryItemObject";
import $ from "../global/util";

const CatalogObject = function (
  Utils,
  ErrorConstants,
  CategoryObject,
  ServiceItemObject,
  CategoryItemObject
) {
  function CatalogObject() {
    this.filter = "";
    this.id = "";
    this.merchant_id = "";
    this.name = "";
    this.other_data = {};
    this.outlet_id = "";
    this.pic_url = "";
    this.reference_id = "";
    this.type = "";
    this.categories = [];
    this.services = [];
    this.items = [];
  }

  CatalogObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawCatalog) {
      this.filter = rawCatalog.filter;
      this.id = rawCatalog.id;
      this.merchant_id = rawCatalog.merchant_id;
      this.name = rawCatalog.name;
      this.other_data = rawCatalog.other_data;
      this.outlet_id = rawCatalog.outlet_id;
      this.pic_url = rawCatalog.pic_url;
      this.reference_id = rawCatalog.reference_id;
      this.type = rawCatalog.type;
      this.categories = [];
      var rawCategories = rawCatalog.categories;
      if (rawCategories) {
        var parsedCategories = [];
        rawCategories.forEach(function (rawCategory, index, rawCategories) {
          var categoryObject = new CategoryObject();
          categoryObject.buildObject(rawCategory);
          parsedCategories.push(categoryObject);
        });
        this.categories = parsedCategories;
      }
      var rawItems = rawCatalog.items;
      if (rawItems) {
        var parsedItems = [];
        rawItems.forEach(function (rawItem, index, rawItems) {
          var item = new CategoryItemObject();
          item.buildObject(rawItem);
          parsedItems.push(item);
        });
        this.items = parsedItems;
      }

      this.services = [];
      var rawServices = rawCatalog.services;
      if (rawServices) {
        var parsedServices = [];
        rawServices.forEach(function (rawService, index, rawServices) {
          var serviceItem = new ServiceItemObject();
          serviceItem.buildObject(rawService);
          parsedServices.push(serviceItem);
        });
        this.services = parsedServices;
      }
    },
  };

  return CatalogObject;
};

export default CatalogObject(
  Utils,
  ErrorConstants,
  CategoryObject,
  ServiceItemObject,
  CategoryItemObject
);
