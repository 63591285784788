import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={34}
      height={39}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.17 24.083c-.724-.563-1.63.141-1.63 1.266v.974h-9.905L30.912 9.932c1.982-1.993 2.29-5.097.579-7.47C30.34.864 28.38 0 26.372 0H4.843C2.169 0 0 2.107 0 4.706 0 7.304 2.168 9.41 4.843 9.41h13.13L1.694 25.801c-1.98 1.994-2.29 5.097-.577 7.471 1.153 1.597 3.113 2.462 5.122 2.462H24.54v.937c0 1.126.906 1.829 1.63 1.266l7.287-5.66c.724-.563.724-1.97 0-2.532l-7.287-5.662z"
        fill={props.color || "#53C175"}
      />
    </Svg>
  );
}

export default SvgComponent;
