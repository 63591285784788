import LedgerReportResource from "./LedgerReportResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import PrestoSdk from "../../global/PrestoSdk";

const LedgerReportManager = function (
  LedgerReportResource,
  Utils,
  ErrorConstants,
  PrestoSdk
) {
  function list(params, successCallback, errorCallback) {
    LedgerReportResource.list(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function create(params, successCallback, errorCallback) {
    LedgerReportResource.create(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function show(params, successCallback, errorCallback) {
    LedgerReportResource.show(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function downloadCSV(params, successCallback, errorCallback) {
    LedgerReportResource.downloadCSV(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function downloadAccountStatement(params, successCallback, errorCallback) {
    LedgerReportResource.downloadAccountStatement(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    list,
    create,
    show,
    downloadCSV,
    downloadAccountStatement,
  };
};

export default LedgerReportManager(
  LedgerReportResource,
  Utils,
  ErrorConstants,
  PrestoSdk
);
