import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const WalletObject = function (Utils, ErrorConstants) {
  function WalletObject() {
    //Current available amount in wallet
    this.current_balance = 0;
    //Below this user must be warned to recharge
    this.warning_balance = 0;
    //Below this wallet is unusable
    this.min_balance = 0;
    this.total_cashback_received = 0;
    this.blocked = false;
    this.blocked_at = "";
    this.blocked_reason = "";
    this.wallet_id = "";
    this.user_id = "";
    this.activated = false;
    this.activated_at = "";
    this.pin_set = null;
  }

  WalletObject.prototype = {
    validateObject: function () {},
    buildObject: function (rawWalletObject) {
      this.current_balance = rawWalletObject.balance;
      this.warning_balance = rawWalletObject.wallet_recharge_notify_balance;
      this.min_balance = rawWalletObject.wallet_min_balance;
      this.expiry_balance = rawWalletObject.expiry_balance;
      if (this.expiry_balance) {
        this.current_balance = this.current_balance + this.expiry_balance;
      }
      if (rawWalletObject.total_cashback_received) {
        this.total_cashback_received = rawWalletObject.total_cashback_received;
      }
      this.blocked = rawWalletObject.blocked;
      this.blocked_at = rawWalletObject.blocked_at;
      this.blocked_reason = rawWalletObject.blocked_reason;
      this.wallet_id = rawWalletObject.wallet_id;
      this.user_id = rawWalletObject.user_id;
      this.activated = rawWalletObject.activated;
      this.activated_at = rawWalletObject.activated_at;
      this.pin_set = rawWalletObject.pin_set;
    },
  };
  return WalletObject;
};

export default WalletObject(Utils, ErrorConstants);
