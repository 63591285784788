import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function CloseIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(6 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path d="M0 0H29V29H0z" />
          <Path
            fill={props.color || "#707070"}
            fillRule="nonzero"
            d="M14.097625 16.6605L2.562875 28.1970625 0 25.632375 11.53475 14.097625 0 2.562875 2.562875 0 14.097625 11.53475 25.632375 0 28.1970625 2.562875 16.6605 14.097625 28.19525 25.632375 25.6305625 28.1970625z"
          />
        </G>
      </G>
    </Svg>
  );
}

export default CloseIcon;
