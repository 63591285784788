import * as React from "react"
import Svg, {
  Defs,
  LinearGradient,
  Stop,
  G,
  Path,
  Circle
} from "react-native-svg"

function SvgComponent({
  width="45px",
  height="44px",
  fillColor1="#FFF",
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Defs>
        <LinearGradient
          x1="70.3061375%"
          y1="13.9259268%"
          x2="29.2227031%"
          y2="100%"
          id="a"
        >
          <Stop stopColor="#F9CF6F" offset="0%" />
          <Stop stopColor="#CEA03E" offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fill={fillColor1} d="M0 0H45V44H0z" />
        <Path
          d="M18.582.795l9.586 5.474a5.922 5.922 0 012.999 5.139v10.948a5.922 5.922 0 01-3 5.139l-9.585 5.474a6.055 6.055 0 01-5.998 0L3 27.495A5.922 5.922 0 010 22.356V11.408a5.922 5.922 0 012.999-5.139L12.584.795a6.055 6.055 0 015.998 0z"
          transform="translate(6 4) translate(1.417 1.416)"
          fill="#000"
          fillRule="nonzero"
        />
        <Path
          d="M13.298.981a7.473 7.473 0 017.404 0l9.585 5.474a7.378 7.378 0 012.716 2.688c.643 1.1.997 2.366.997 3.681v10.949c0 1.315-.354 2.58-.997 3.68a7.378 7.378 0 01-2.716 2.688l-9.585 5.475a7.473 7.473 0 01-7.404 0L3.713 30.14a7.378 7.378 0 01-2.716-2.688A7.296 7.296 0 010 23.773V12.824c0-1.315.354-2.58.997-3.68a7.378 7.378 0 012.716-2.689L13.298.981z"
          transform="translate(6 4)"
          fill="#000"
          fillRule="nonzero"
        />
        <Path
          d="M13.298.981a7.473 7.473 0 017.404 0l9.585 5.474a7.378 7.378 0 012.716 2.688c.643 1.1.997 2.366.997 3.681v10.949c0 1.315-.354 2.58-.997 3.68a7.378 7.378 0 01-2.716 2.688l-9.585 5.475a7.473 7.473 0 01-7.404 0L3.713 30.14a7.378 7.378 0 01-2.716-2.688A7.296 7.296 0 010 23.773V12.824c0-1.315.354-2.58.997-3.68a7.378 7.378 0 012.716-2.689L13.298.981z"
          transform="translate(6 4)"
          fill="#FFF"
          stroke="#53C175"
          strokeWidth={2}
        />
        <Path
          d="M19.411 5.651l7.606 4.344a4.699 4.699 0 012.38 4.077v8.688a4.699 4.699 0 01-2.38 4.077l-7.606 4.344a4.804 4.804 0 01-4.759 0l-7.606-4.344a4.699 4.699 0 01-2.38-4.077v-8.688c0-1.682.908-3.236 2.38-4.077l7.606-4.344a4.804 4.804 0 014.76 0z"
          fill="url(#a)"
          fillRule="nonzero"
          transform="translate(6 4)"
        />
        <G
          transform="translate(6 4) translate(11 11.5)"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#FFF"
        >
          <G transform="translate(0 1.5)">
            <Path strokeLinecap="round" d="M0 10.4907407L12 10.4907407" />
            <Path
              strokeWidth={0.5}
              strokeLinejoin="round"
              d="M0 7.94444444L0 4.40552436 2.41949026 6.1749844 6 2.11856003 9.54072571 6.1749844 12 4.40552436 12 7.94444444z"
            />
            <Path
              strokeWidth={0.5}
              strokeLinejoin="round"
              d="M1.8 5.82588441L1.8 2.28696432 3.49364318 4.05642437 6 0 8.478508 4.05642437 10.2 2.28696432 10.2 5.82588441z"
            />
          </G>
          <Circle cx={5.95833333} cy={1.125} r={1.125} />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
