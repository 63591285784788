import * as React from "react";
import Svg, { G, Circle } from "react-native-svg";

function ViewMoreIcon(props) {
  return (
    <Svg
      width={props.width || 25}
      height={props.height || 5}
      viewBox="0 0 25 5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        fill={props.color || "#707070"}
        stroke={props.color || "#707070"}
        transform="rotate(90 12.5 12.5)"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Circle cx={2.5} cy={2.5} r={2} />
        <Circle cx={2.5} cy={12.5} r={2} />
        <Circle cx={2.5} cy={22.5} r={2} />
      </G>
    </Svg>
  );
}

export default ViewMoreIcon;
