import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M29.079 17.372c1.846-4.56.926-9.979-2.766-13.676-4.916-4.927-12.89-4.927-17.806 0L5.225 6.983l12.393 12.392c2.701-3.638 7.705-4.535 11.461-2.003zm-4.7 1.378a5.625 5.625 0 100 11.25 5.625 5.625 0 000-11.25z"
        />
        <Path d="M.339 26.53a1.155 1.155 0 01.058-1.688l6.036-5.238-2.022-2.026c-2.285-2.29-2.613-5.788-1.014-8.443l12.915 12.908c-.217.745-.37 1.518-.364 2.327a8.35 8.35 0 00.645 3.228c-1.506-.165-2.965-.797-4.12-1.952l-2.091-2.091-5.233 6.052a1.146 1.146 0 01-1.681.059l-3.13-3.137z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h30v30H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
