import React, { createContext, useEffect, useState } from "react";
import { useMessages } from "presto-react-components";
import I18n from "i18n-js";
import AsyncStorage from "@react-native-community/async-storage";
import Config from "@presto-common/config";

I18n.fallbacks = true;
I18n.defaultLocale = "en-US";
I18n.locale = "en-US";

const MessagesContext = createContext();

export function MessagesContextProvider(props) {
  const messages = useMessages();
  /* console.log(
    "Messages : ",
    JSON.stringify(messages["en-US"].screen_messages, null, 2)
  ); */
  I18n.translations = messages;
  const default_language = Config?.merchant_config?.default_language || "en-US";
  const [locale, setLocale] = useState(default_language);

  useEffect(() => {
    I18n.translations = messages;
  }, [messages]);

  const setLanguage = (lang) => {
    setLocale(lang);
    AsyncStorage.setItem(Config.app + "_LANGUAGE", lang);
  };
  useEffect(() => {
    I18n.locale = locale;
  }, [locale]);

  useEffect(() => {
    async function languageFetch() {
      const language = await AsyncStorage.getItem(Config.app + "_LANGUAGE");
      setLocale(language || default_language);
    }
    languageFetch();
  }, []);

  return (
    <MessagesContext.Provider value={{ locale, setLanguage }}>
      {props.children}
    </MessagesContext.Provider>
  );
}

export default MessagesContext;
