import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 30}
      viewBox="0 0 480 327"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M139.2 242.88c23.329 0 42.24 18.911 42.24 42.24s-18.911 42.24-42.24 42.24-42.24-18.911-42.24-42.24 18.911-42.24 42.24-42.24zm223.68 0c23.329 0 42.24 18.911 42.24 42.24s-18.911 42.24-42.24 42.24-42.24-18.911-42.24-42.24 18.911-42.24 42.24-42.24zM139.2 263.04c-12.194 0-22.08 9.886-22.08 22.08 0 12.194 9.886 22.08 22.08 22.08 12.194 0 22.08-9.886 22.08-22.08 0-12.194-9.886-22.08-22.08-22.08zm223.68 0c-12.194 0-22.08 9.886-22.08 22.08 0 12.194 9.886 22.08 22.08 22.08 12.194 0 22.08-9.886 22.08-22.08 0-12.194-9.886-22.08-22.08-22.08zm-260.966-18.242c-9.985 8.421-17.042 20.203-19.427 33.601L7.68 278.4A7.68 7.68 0 010 270.72v-18.24a7.68 7.68 0 017.68-7.68l94.234-.002zm264.458-202.75c15.966 0 28.077 5.51 36.333 16.528l53.812 75.536V244.8h15.803a7.68 7.68 0 017.68 7.68v18.24a7.68 7.68 0 01-7.68 7.68l-53.766-.001c-4.96-26.768-28.43-47.039-56.634-47.039s-51.674 20.271-56.634 47.039H194.953c-2.385-13.398-9.442-25.18-19.427-33.601l105.707.002V55.905c0-9.238 4.526-13.857 13.577-13.857h71.562zM242.88 0c10.604 0 19.2 8.596 19.2 19.2v197.76a7.68 7.68 0 01-7.68 7.68H25.92a9.6 9.6 0 01-9.6-9.6V22.08C16.32 9.886 26.206 0 38.4 0h204.48zm125.718 77.568H318.72a6.72 6.72 0 00-6.72 6.72v47.04a4.8 4.8 0 004.8 4.8h89.648a5.76 5.76 0 004.647-9.163l-32.428-44.29a12.48 12.48 0 00-10.07-5.107z"
        fill={props.color || "#FFF"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default SvgComponent;
