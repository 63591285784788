import React, { useContext, useState, useCallback } from "react";
import { View, Dimensions, Alert, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import LastOrderCard from "@presto-cards/LastOrderCard/LastOrderCard";
import OrderManager from "@presto-services/features/orders/OrderManager";
import UserContext from "@presto-contexts/UserContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartContext from "@presto-contexts/CartContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import TrackOrderModel from "@presto-component-models/TrackOrderModel";

export default function LastOrderComponent({ HeadingText }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const { reOrder } = useContext(CartContext);
  const [order, setOrder] = useState(undefined);
  const [reOrdering, setReOrdering] = useState(false);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const { setPaneState } = useContext(RightPaneContext);

  const getOrder = (orderId) => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        setOrder(response.data);
      },
      (error) => {}
    );
  };

  const onReOrder = async () => {
    setReOrdering(true);
    const [error, _] = await reOrder(order);
    console.log("error : ", error);
    if (error === "error_adding_items") {
      if (Platform.OS === "web") {
        alert("Few items could not be added as they are not available");
      } else {
        Alert.alert(
          "Alert",
          "Few items could not be added as they are not available"
        );
      }
    }
    setReOrdering(false);
  };

  const onViewOrder = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: {
        nextUrl: `/orders/${order.id}`,
        screenName: "ORDER_DETAILS_SCREEN",
        screenParams: { orderId: order.id },
      },
    });
  };

  useFocusEffect(
    useCallback(() => {
      OrderManager.getMyOrders(
        {},
        (response) => {
          const filteredOrders = _.filter(response.data.orders, (order) => {
            const firstItem = _.first(order.items);
            return firstItem?.name !== "Wallet Recharge Pack";
          });
          const deliveredOrder = _.first(
            _.filter(filteredOrders, (o) => o.status === "DELIVERED")
          );
          if (deliveredOrder) {
            getOrder(deliveredOrder.id);
          }
        },
        () => {}
      );
    }, [user])
  );

  let dateObj = new Date(order?.delivered_at);

  return !order ? null : (
    <View
      style={{
        paddingHorizontal: isDesktop ? 0 : theme.containerPadding,
        paddingVertical: theme.containerPadding - 5,
      }}
    >
      {HeadingText && (
        <PrestoText
          fontStyle={isDesktop ? "900.bold" : "700.bold"}
          color={theme.darkText}
          size={isDesktop ? 22 : 18}
          extraStyle={{
            letterSpacing: 0.4,
            lineHeight: 20,
            paddingRight: 5,
            marginBottom: 30,
          }}
        >
          {HeadingText}
        </PrestoText>
      )}
      <LastOrderCard
        reOrdering={reOrdering}
        onReOrderPressed={() => onReOrder()}
        onViewOrderPressed={() => onViewOrder()}
        order={new TrackOrderModel({ order: order })}
      />
    </View>
  );
}
