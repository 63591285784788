import React, { useContext } from "react";
import { View, Platform, Text } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import _ from "lodash";
import Pressable from "@presto-components/Pressable/Pressable";
import { useAssets } from "presto-react-components";
let isAndroid = Platform.OS === "android";

export function Icon({ icon, styles }) {
  return (
    <>
      {icon ? (
        <View style={{ ...styles }}>
          <PrestoIcon icon={icon} />
        </View>
      ) : null}
    </>
  );
}
const getButtonObj = (theme, type) => {
  return _.get(theme, type);
};
const getStyleObject = (theme, button) => {
  return _.get(theme, button);
};
export function PrestoButton(props) {
  const {
    styles,
    leftIcon,
    title,
    rightIcon,
    fontStyle,
    numberOfLines,
    titleSize,
    titleColor,
    disable,
    onPress,
    titleExtraStyle,
  } = props;

  const arrayTitleExtraStyle = _.isArray(titleExtraStyle)
    ? titleExtraStyle
    : [titleExtraStyle];

  return (
    <Pressable disabled={disable} onPress={onPress} {...props}>
      <View
        style={[
          {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            elevation: Platform.OS == "android" ? 0 : 5,
          },
          ...styles,
        ]}
      >
        <Icon styles={{ marginRight: 10 }} icon={leftIcon} />
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <PrestoText
            fontStyle={fontStyle}
            numberOfLines={numberOfLines || 1}
            size={titleSize}
            color={titleColor}
            extraStyle={[
              titleExtraStyle,
              {
                textAlign: "center",
                textAlignVertical: "center",
              },
              ...arrayTitleExtraStyle,
            ]}
          >
            {title}
          </PrestoText>
        </View>
        <Icon styles={{ marginRight: 10 }} icon={rightIcon} />
      </View>
    </Pressable>
  );
}
export function PrestoSolidButton(props) {
  const {
    borderRadius,
    bgColor,
    titleColor,
    width,
    title,
    titleFontStyle,
    titleExtraStyle,
    disable,
    onPress,
    titleSize,
    rightIcon,
    leftIcon,
    size,
    numberOfLines,
    height,
    extraStyle,
    buttonStyle,
    borderColor,
    borderWidth,
    buttonWidth,
    opacity,
  } = props;
  const { theme } = useContext(ThemeContext);
  const buttonObj = getButtonObj(theme, size);
  const es = Array.isArray(extraStyle) ? extraStyle : [extraStyle];
  const buttonStyles = getStyleObject(theme, "solid-button-styles");
  return (
    <PrestoButton
      styles={[
        {
          width: buttonWidth || width,
          maxWidth: width,
          borderWidth: borderWidth ? borderWidth : 0,
          borderColor: borderColor
            ? borderColor
            : buttonStyle && buttonStyles
            ? buttonStyles[buttonStyle].backgroundColor
            : bgColor,
          height: height || buttonObj.height,
          borderRadius: borderRadius
            ? borderRadius
            : buttonObj
            ? buttonObj.borderRadius
            : "none",
          backgroundColor: bgColor
            ? bgColor
            : buttonStyle && buttonStyles
            ? buttonStyles[buttonStyle].backgroundColor
            : "transparent",
          opacity: opacity ? opacity : 1,
        },
        ...es,
      ]}
      leftIcon={leftIcon}
      disable={disable}
      onPress={onPress}
      rightIcon={rightIcon}
      title={title}
      fontStyle={
        buttonStyle && buttonStyles
          ? buttonStyles[buttonStyle].fontStyle
          : titleFontStyle || "600.semibold"
      }
      numberOfLines={numberOfLines}
      titleSize={titleSize || buttonObj.fontSize}
      titleColor={
        titleColor
          ? titleColor
          : buttonStyle && buttonStyles
          ? buttonStyles[buttonStyle].color
          : theme.primaryButtonText
      }
      titleExtraStyle={[{ textTransform: "capitalize" }, titleExtraStyle]}
    />
  );
}
export function PrestoDottedButton(props) {
  const {
    borderRadius,
    borderColor,
    titleColor,
    size,
    width,
    title,
    titleFontStyle,
    titleExtraStyle,
    disable,
    onPress,
    numberOfLines,
    rightIcon,
    leftIcon,
    titleSize,
    height,
    extraStyle,
    borderWidth,
    buttonStyle,
    bgColor,
  } = props;
  const { theme } = useContext(ThemeContext);
  const buttonObj = getButtonObj(theme, size);
  const buttonStyles = getStyleObject(theme, "dotted-button-styles");
  const es = Array.isArray(extraStyle) ? extraStyle : [extraStyle];
  return (
    <PrestoButton
      styles={[
        {
          width: width,
          maxWidth: width,
          height: height || buttonObj.height,
          borderRadius: borderRadius ?? buttonObj.height,
          borderWidth: borderWidth ?? buttonObj.borderWidth,
          borderColor: borderColor
            ? borderColor
            : buttonStyle && buttonStyles
            ? buttonStyles[buttonStyle].borderColor
            : bgColor,
          backgroundColor: bgColor || "transparent",
          borderStyle: "dashed",
        },
        ...es,
      ]}
      leftIcon={leftIcon}
      disable={disable}
      onPress={onPress}
      rightIcon={rightIcon}
      title={title}
      fontStyle={
        buttonStyle && buttonStyles
          ? buttonStyles[buttonStyle].fontStyle
          : titleFontStyle || "600.semibold"
      }
      numberOfLines={numberOfLines}
      titleSize={titleSize || buttonObj.fontSize}
      titleColor={
        titleColor
          ? titleColor
          : buttonStyle && buttonStyles
          ? buttonStyles[buttonStyle].color
          : theme.primaryButtonText
      }
      titleExtraStyle={titleExtraStyle}
    />
  );
}
export function PrestoFramedButton(props) {
  const {
    borderRadius = 4,
    borderColor,
    titleColor,
    size,
    width,
    title,
    titleFontStyle,
    titleExtraStyle,
    disable,
    onPress,
    numberOfLines,
    rightIcon,
    leftIcon,
    titleSize,
    height,
    extraStyle,
    borderWidth = 1,
    buttonStyle,
    bgColor,
    paddingVertical,
  } = props;
  // alert(JSON.stringify(titleExtraStyle));
  const { theme } = useContext(ThemeContext);
  const buttonObj = getButtonObj(theme, size);
  const es = Array.isArray(extraStyle) ? extraStyle : [extraStyle];
  const buttonStyles = getStyleObject(theme, "framed-button-styles");
  return (
    <PrestoButton
      styles={[
        {
          width: width,
          maxWidth: width,
          height: height || buttonObj?.height,
          borderRadius: borderRadius || buttonObj?.borderRadius,
          borderWidth: borderWidth || buttonObj?.borderWidth,
          borderColor: borderColor
            ? borderColor
            : buttonStyle && buttonStyles
            ? buttonStyles[buttonStyle].borderColor
            : bgColor,
          backgroundColor: bgColor || "transparent",
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: paddingVertical,
        },
        ...es,
      ]}
      leftIcon={leftIcon}
      disable={disable}
      onPress={onPress}
      rightIcon={rightIcon}
      title={title}
      fontStyle={
        buttonStyle && buttonStyles
          ? buttonStyles[buttonStyle].fontStyle
          : titleFontStyle || "600.semibold"
      }
      numberOfLines={numberOfLines}
      titleSize={titleSize || buttonObj?.fontSize}
      titleColor={
        titleColor
          ? titleColor
          : buttonStyle && buttonStyles
          ? buttonStyles[buttonStyle].color
          : theme.primaryButtonText
      }
      titleExtraStyle={[{ textTransform: "capitalize" }, titleExtraStyle]}
    />
  );
}
export function PrestoTextButton(props) {
  const {
    titleColor,
    width,
    title,
    titleFontStyle,
    disable,
    onPress,
    numberOfLines,
    rightIcon,
    leftIcon,
    titleSize,
    titleExtraStyle,
    extraStyle,
    size,
  } = props;
  const { theme } = useContext(ThemeContext);
  const buttonObj = getButtonObj(theme, size);
  const es = Array.isArray(extraStyle) ? extraStyle : [extraStyle];
  return (
    <PrestoButton
      styles={[
        {
          width: width,
          maxWidth: width,
        },
        ...es,
      ]}
      leftIcon={leftIcon}
      disable={disable}
      onPress={onPress}
      rightIcon={rightIcon}
      title={title}
      fontStyle={titleFontStyle || "600.semibold"}
      numberOfLines={numberOfLines}
      titleSize={titleSize || buttonObj?.fontSize}
      titleColor={titleColor || theme.darkText}
      titleExtraStyle={titleExtraStyle}
    />
  );
}
export function RowButton(props) {
  const { theme } = useContext(ThemeContext);
  const {
    title,
    showDisclosure,
    lightText,
    uppercase,
    rightTitle,
    onPress,
    rightColor,
    numberOfLines,
    disclosureColor,
    titleIcon,
    fontStyle,
    fontSize,
    titleColor,
  } = props;
  const { svgs } = useAssets();
  return (
    <Pressable onPress={onPress}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          minHeight: 56,
          backgroundColor: "transparent",
          paddingLeft: theme.primaryPadding,
        }}
      >
        <View style={{ flex: 1 }}>
          {lightText ? (
            <PrestoText
              size={14}
              color={theme.darkText}
              extraStyle={{
                textTransform: uppercase ? "uppercase" : "none",
              }}
              numberOfLines={numberOfLines || null}
            >
              {title}
            </PrestoText>
          ) : (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <PrestoText
                fontStyle={fontStyle ? fontStyle : "medium"}
                size={fontSize ? fontSize : 14}
                color={titleColor ? titleColor : theme.darkText}
                extraStyle={{
                  textTransform: uppercase ? "uppercase" : "none",
                }}
                numberOfLines={numberOfLines || null}
              >
                {title}
              </PrestoText>
              {titleIcon ? titleIcon : null}
            </View>
          )}
        </View>
        {rightTitle ? (
          <View style={{ alignContent: "flex-end" }}>
            <PrestoText
              size={16}
              fontStyle="medium"
              color={rightColor || theme.primary}
              extraStyle={{
                textTransform: uppercase ? "uppercase" : "none",
                marginRight: 10,
              }}
            >
              {rightTitle}
            </PrestoText>
          </View>
        ) : null}
        {showDisclosure && (
          <View style={{ alignContent: "flex-end", width: 50 }}>
            <PrestoIcon
              icon={
                <svgs.RightArrow
                  primaryColor={disclosureColor || theme.primary}
                />
              }
            />
          </View>
        )}
      </View>
    </Pressable>
  );
}

export default function LargeButton(props) {
  const { theme } = useContext(ThemeContext);
  const {
    title,
    icon,
    fontStyle,
    width = 160,
    height = 100,
    extraStyle,
    titleExtraStyle = {},
  } = props;
  return (
    <Pressable onPress={props.onPress}>
      <View
        style={{
          height: height,
          width: width,
          borderRadius: 8,
          backgroundColor: theme.largeButtonBgColor,
          margin: 10,
          ...extraStyle,
        }}
      >
        <View
          style={{
            flexDirection: "column",
          }}
        >
          <View
            style={{
              padding: 10,
            }}
          >
            <PrestoIcon
              style={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={icon}
            />
          </View>
          <View
            style={{
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={14}
              fontStyle={fontStyle || "600.semibold"}
              color={theme.darkText}
              extraStyle={titleExtraStyle}
            >
              {title}
            </PrestoText>
          </View>
        </View>
      </View>
    </Pressable>
  );
}
export function IconButton(props) {
  const {
    icon,
    onPress,
    borderColor,
    borderRadius,
    borderWidth,
    bgColor,
    disable,
    width,
    height,
    borderTopRightRadius,
    borderBotRightRadius,
  } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <Pressable disabled={disable} onPress={onPress}>
      <View
        style={{
          borderWidth: borderWidth || 1,
          borderColor: borderColor || theme.primary,
          width: width || 34,
          height: height || 34,
          justifyContent: "center",
          alignItems: "center",
          borderTopRightRadius: borderTopRightRadius ? borderTopRightRadius : 0,
          borderBottomRightRadius: borderBotRightRadius
            ? borderBotRightRadius
            : 0,
          borderRadius: isAndroid ? 0 : borderRadius || 6,
          backgroundColor: bgColor || "transparent",
        }}
      >
        <Icon icon={icon} />
      </View>
    </Pressable>
  );
}
