import * as React from "react";
import Svg, { Defs, Path, G, Circle, Mask, Use } from "react-native-svg";

function StartServiceIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          stroke="#00AF62"
          strokeWidth={0.9}
          fill="#00AF62"
          cx={20}
          cy={20}
          r={17.55}
        />
        <G transform="translate(5 6)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)" fill="#FFF" fillRule="nonzero">
            <Path
              transform="translate(4 3)"
              d="M11.5 0L9.4084375 2.0915625 17.3233125 10.0064375 0 10.0064375 0 12.9935625 17.32475 12.9935625 9.4084375 20.9084375 11.5 23 23 11.5z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default StartServiceIcon;
