import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";

export function SectionLinkTextComponent(props) {
  const {
    leftText1,
    leftText2,
    onPressButton,
    theme,
    linkTextColor,
    style,
    fontWeight,
  } = props;
  const styles = getStyles(theme, Platform.OS);
  return (
    <TouchableWithoutFeedback onPress={onPressButton}>
      <View
        style={{
          ...styles.default,
          ...style,
        }}
      >
        <View
          style={{
            flexDirection: "row",
          }}
        >
          {leftText1 ? (
            <PrestoText fontStyle="400.normal" extraStyle={styles.linkText}>
              {leftText1}
            </PrestoText>
          ) : null}
          {leftText2 ? (
            <PrestoText
              fontStyle="400.normal"
              extraStyle={styles.linkText}
              color={linkTextColor || theme.primary}
            >
              {leftText2}
            </PrestoText>
          ) : null}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default SectionLinkTextComponent;

function getStyles(theme, os) {
  let linkText = {
    fontSize: 14,
    paddingTop: 2,
  };
  if (os === "web") {
    linkText.cursor = "pointer";
  }
  return {
    default: {
      paddingVertical: 15,
      justifyContent: "center",
      alignItems: "center",
    },
    linkText: linkText,
  };
}
