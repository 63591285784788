import React, { useContext } from "react";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import _ from "lodash";
import LineItemCard from "@presto-components/ItemCards/LineItemCard";
import utils from "../../utils";
import I18n from "i18n-js";
import ItemCardModel from "@presto-component-models/ItemCardModel";

export default function ItemCardss({
  item,
  lineItem,
  inOrderDetail,
  variation,
  onClickItemCard,
  excessQuantityAmount,
}) {
  const product = item;
  const { Navigator } = useContext(NavigatorContext);

  const count = lineItem.quantity;
  const hasOffers = product?.variations && product?.variations.length;
  const offersCount = product?.variations?.length || 0;
  let unsoldVariations = _.filter(
    product?.variations || [],
    (varitation) => !(varitation.sold_out || varitation.stock_count === 0)
  );

  // let variation = undefined;
  // if (hasOffers && lineItem.variation) {
  //   variation = _.find(item.variations, (variation) => {});
  // }

  const heading = product?.name;
  const amount = count ?? 0;
  const originalPrice =
    product?.other_data?.old_price !== "0"
      ? `${
          utils.formattedPrice(parseFloat(product?.other_data?.old_price)) || 0
        } `
      : "";

  const price = `${
    utils.formattedPrice(product?.price.total_price * (amount || 1)) || 0
  }`;

  const tag = "";
  let itemPrice = `${utils.formattedPrice(product?.price.total_price) || 0}`;
  let oldCartPrice = "";
  if (product?.issues?.old_price) {
    oldCartPrice = `${
      utils.formattedPrice(product?.issues?.old_price / 100.0) || 0
    }`;
  }
  let division_number = 1;
  if (product.other_data?.display_name) {
    if (product.other_data?.display_name == "Kg") {
      division_number = product.other_data?.display_quantity;
    }
    if (product.other_data?.display_quantity > 1) {
      itemPrice = `${utils.formattedPrice(
        product.price.total_price / division_number
      )}/${
        product.other_data?.display_quantity +
        " " +
        product.other_data?.display_name
      }`;
    } else {
      itemPrice = `${
        utils.formattedPrice(product.price.total_price / division_number) || 0
      }/${product.other_data?.display_name}`;
    }
  }

  let offer = "";
  let savings = "";
  if (product.other_data?.old_price !== "0") {
    const priceDiff = product.other_data?.old_price - product.price.base_price;
    savings = I18n.t("screen_messages.billing.discount_save_message", {
      amount: utils.formattedPrice(priceDiff),
    });
    const percentage = (
      (priceDiff / product?.other_data?.old_price) *
      100
    ).toFixed(1);

    offer = `${percentage}%`;
  }
  let quantity = "";
  if (product.other_data?.display_quantity) {
    if (count > 0) {
      quantity = `${(
        (count * product?.other_data?.display_quantity) /
        product?.other_data?.display_price_multiplier
      ).toFixed(2)} ${product?.other_data?.display_name}`;
    } else {
      quantity = `${product?.other_data?.display_quantity} ${product?.other_data?.display_name}`;
    }
  } else {
    quantity = `${count || 1}`;
  }

  let deliveredQuantity = "";
  if (lineItem?.other_data?.display_quantity != null) {
    if (lineItem?.delivered_quantity > 0) {
      deliveredQuantity = `${(
        (lineItem?.delivered_quantity * lineItem.other_data?.display_quantity) /
        lineItem.other_data?.display_price_multiplier
      ).toFixed(2)} ${lineItem.other_data?.display_name}`;
    } else {
      deliveredQuantity = `${lineItem?.delivered_quantity} ${lineItem.other_data?.display_name}`;
    }
  } else {
    deliveredQuantity = `${lineItem?.delivered_quantity || 1}`;
  }

  const onPressItem = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "DETAILED_ITEM",
        screenParams: { item: item },
      },
    });
  };

  return (
    <LineItemCard
      inOrderDetail={inOrderDetail}
      variation={variation}
      product={
        new ItemCardModel({
          product,
          count,
          variation,
          lineItem,
        })
      }
      onClickItemCard={onClickItemCard}
      excessQuantityAmount={excessQuantityAmount}
    />
  );
}
