import $rootScope from "../global/rootScope";
import PrestoSdk from "../global/PrestoSdk";
import CartItem from "./CartItem";
import $ from "../global/util";

const CartService = function ($rootScope, PrestoSdk, CartItem) {
  var appConfig = PrestoSdk.getAppConfig();
  var cart = {
    no_of_items: 0,
    total_price: 0,
    total_tax: 0,
    total_service_tax: 0,
    delivery_charge: {},
    total_packaging_cost: 0,
    total_vat_amount: 0,
    total_base_price: 0,
    cartItems: {},
    minimum_bill_amount: 0,
    total_shipping_cost: 0,
    minimum_bill_amount_field: "bill_amount",
    total_base_price_with_tax: 0,
  };

  function getItems() {
    return cart.cartItems;
  }

  //Cart item id = productId + [variationId];
  function getCartItemId(productId, variationId) {
    if (variationId && null != variationId && "" != variationId) {
      return productId + ":" + variationId;
    } else {
      return productId;
    }
  }

  function getItem(itemId) {
    //console.log("cartItems=" + JSON.stringify(cart.cartItems));
    return cart.cartItems[itemId];
  }

  function createNewItem(itemId, product, variation, variationList) {
    var cartItem = new CartItem(itemId, product, variation, variationList);
    cartItem.added_at = Date.now();
    return cartItem;
  }

  function addNewItem(newItemId, newItem) {
    console.log("adding new cart item. itemId=" + newItemId);
    if (!cart.cartItems[newItemId]) {
      console.log("New item successfully added");
      cart.cartItems[newItemId] = newItem;
      //$rootScope.$broadcast('ON_CART_ITEM_UPDATED');
    }
  }

  function getTaxAmount() {
    if (cart.total_tax > 0) {
      return cart.total_tax;
    } else {
      return 0;
    }
  }

  function getTotalBasePriceWithTax() {
    if (cart.total_base_price_with_tax > 0) {
      return cart.total_base_price_with_tax;
    } else {
      return 0;
    }
  }

  function getVatAmount() {
    if (cart.total_vat_amount > 0) {
      return cart.total_vat_amount;
    } else {
      return 0;
    }
  }

  function updateItem(itemId, addCount, broadcast) {
    console.log("updateItem itemId=" + itemId + ",addCount=" + addCount);
    var cartItem = cart.cartItems[itemId];
    if (cartItem) {
      cart.cartItems[itemId].count += addCount;
      if (cartItem.other_data && cartItem.other_data.default_item_id) {
        //ignoring as its addon
      } else {
        cart.no_of_items += addCount;
      }
      if (!cartItem.hasVariations()) {
        console.log(
          "item count successfully incremented/decremented to " + addCount
        );
        cart.total_price +=
          Number(cartItem.product.price.total_price) * addCount;
        cart.total_base_price +=
          Number(cartItem.product.price.base_price) * addCount;
        cart.total_vat_amount +=
          Number(cartItem.product.price.vat_amount) * addCount;
        if (cartItem.product.price.packing_charge) {
          cart.total_packaging_cost +=
            Number(cartItem.product.price.packing_charge) * addCount;
        }
        cart.total_shipping_cost +=
          Number(cartItem.product.price.shipping_charge) * addCount;
        var item_tax_price;
        var item_total_base_price_with_tax;
        if (
          cartItem.product.price.total_tax_percentage &&
          cartItem.product.price.total_tax_percentage > 0
        ) {
          item_tax_price =
            (Number(cartItem.product.price.total_price) +
              Number(cartItem.product.price.packing_charge) +
              Number(cartItem.product.price.shipping_charge)) *
            (cartItem.product.price.total_tax_percentage / 100);
          item_total_base_price_with_tax =
            (Number(cartItem.product.price.total_price) *
              cartItem.product.price.total_tax_percentage) /
            100;
        } else if (cartItem.product.price.tax_percentage) {
          item_tax_price =
            (Number(cartItem.product.price.total_price) +
              Number(cartItem.product.price.packing_charge) +
              Number(cartItem.product.price.shipping_charge)) *
            (cartItem.product.price.tax_percentage / 100);
          item_total_base_price_with_tax =
            (Number(cartItem.product.price.total_price) *
              cartItem.product.price.tax_percentage) /
            100;
        } else {
          item_tax_price =
            (Number(cartItem.product.price.total_price) +
              Number(cartItem.product.price.packing_charge) +
              Number(cartItem.product.price.shipping_charge)) *
            (cartItem.product.price.service_tax_percentage / 100);
          item_total_base_price_with_tax =
            (Number(cartItem.product.price.total_price) *
              cartItem.product.price.service_tax_percentage) /
            100;
        }
        cart.total_tax += item_tax_price * addCount;
        cart.total_base_price_with_tax +=
          item_total_base_price_with_tax * addCount;
      } else {
        cart.total_price +=
          Number(cartItem.variation.price.total_price) * addCount;
        cart.total_base_price +=
          Number(cartItem.variation.price.base_price) * addCount;
        cart.total_vat_amount +=
          Number(cartItem.variation.price.vat_amount) * addCount;
        cart.total_packaging_cost +=
          Number(cartItem.variation.price.packing_charge) * addCount;
        cart.total_shipping_cost +=
          Number(cartItem.variation.price.shipping_charge) * addCount;
        var item_tax_price;
        var item_total_base_price_with_tax;
        if (
          cartItem.variation.price.total_tax_percentage &&
          cartItem.variation.price.total_tax_percentage > 0
        ) {
          item_tax_price =
            (Number(cartItem.variation.price.total_price) +
              Number(cartItem.variation.price.packing_charge) +
              Number(cartItem.variation.price.shipping_charge)) *
            (cartItem.variation.price.total_tax_percentage / 100);
          item_total_base_price_with_tax =
            (Number(cartItem.variation.price.total_price) *
              cartItem.variation.price.total_tax_percentage) /
            100;
        } else {
          item_tax_price =
            (Number(cartItem.variation.price.total_price) +
              Number(cartItem.variation.price.packing_charge) +
              Number(cartItem.variation.price.shipping_charge)) *
            (cartItem.variation.price.tax_percentage / 100);
          item_total_base_price_with_tax =
            (Number(cartItem.variation.price.total_price) *
              cartItem.variation.price.tax_percentage) /
            100;
        }
        cart.total_tax += item_tax_price * addCount;
        cart.total_base_price_with_tax +=
          item_total_base_price_with_tax * addCount;
      }
      if (!broadcast) {
        $rootScope.$broadcast("ON_CART_ITEM_UPDATED");
      }
    } else {
      console.log("updateItem() item not found in cart");
    }
  }

  function removeItem(itemId) {
    console.log("removeItem itemId=" + itemId);
    if (cart.cartItems[itemId]) {
      console.log("Item deleted");
      delete cart.cartItems[itemId];
      //$rootScope.$broadcast('ON_CART_ITEM_UPDATED');
    } else {
      console.log("no item found to delete");
    }
  }

  function clearCart() {
    cart.no_of_items = 0;
    cart.total_price = 0;
    cart.total_tax = 0;
    cart.total_service_tax = 0;
    cart.total_packaging_cost = 0;
    cart.total_vat_amount = 0;
    cart.total_base_price = 0;
    cart.total_shipping_cost = 0;
    cart.total_base_price_with_tax = 0;
    for (var item in cart.cartItems) {
      delete cart.cartItems[item];
    }
    cart.cartItems = {};
    //TODO:Do we need to clear these items on clear cart?
    //cart.minimum_bill_amount = 0;
    //cart.delivery_charge = {};
    //console.log("cartItems=" + JSON.stringify(cart.cartItems));
    $rootScope.$broadcast("ON_CART_ITEM_UPDATED");
  }

  function isDeliveryChargeToBeAdded() {
    var deliveryChargeToBeAdded = false;
    if (0 < cart.total_price) {
      var billAmt = getBillAmtWithoutDeliveryCharge();
      if (
        cart.delivery_charge &&
        cart.delivery_charge.has_delivery_charge &&
        cart.delivery_charge.delivery_charge_limit > billAmt
      ) {
        deliveryChargeToBeAdded = true;
      }
    }
    return deliveryChargeToBeAdded;
  }

  function getBillAmount() {
    var billAmt = 0;
    billAmt = getBillAmtWithoutDeliveryCharge();
    if (isDeliveryChargeToBeAdded()) {
      billAmt += Number(cart.delivery_charge.total_delivery_charge);
    }
    return billAmt;
  }

  function getCartItemsCount() {
    return cart.no_of_items;
  }

  function getTotalVat() {
    return cart.total_vat_amount;
  }

  function getCartPrice() {
    return cart.total_price;
  }

  function getTotalPackagingCost() {
    return cart.total_packaging_cost;
  }

  function getTotalShippingCost() {
    return cart.total_shipping_cost;
  }

  function getBillAmtWithoutDeliveryCharge() {
    return (
      cart.total_price +
      cart.total_tax +
      cart.total_packaging_cost +
      cart.total_shipping_cost
    );
  }

  function getGrossAmnt() {
    return (
      cart.total_price + cart.total_packaging_cost + cart.total_shipping_cost
    );
  }

  function getDeliveryCharge() {
    var deliveryCharge = 0;
    if (isDeliveryChargeToBeAdded()) {
      deliveryCharge = Number(cart.delivery_charge.total_delivery_charge);
    }
    return deliveryCharge;
  }

  function setDeliveryCharge(deliveryCharge) {
    cart.delivery_charge = deliveryCharge;
  }

  function setMinimumBillAmount(minBillAmt) {
    cart.minimum_bill_amount = Number(minBillAmt);
  }

  function getMinimumBillAmount() {
    return cart.minimum_bill_amount;
  }

  function isAboveMinBillAmt() {
    var billAmt;
    if (cart.minimum_bill_amount_field == "bill_amount") {
      billAmt = getBillAmtWithoutDeliveryCharge();
    } else if (cart.minimum_bill_amount_field == "total_amount") {
      billAmt = getBillAmount();
    } else if (cart.minimum_bill_amount_field == "gross_amount") {
      billAmt = getGrossAmnt();
    }
    if (billAmt > cart.minimum_bill_amount) {
      return true;
    } else {
      return false;
    }
  }

  function isCartLimitReached() {
    if (
      cart.no_of_items == appConfig.merchant_config.cart_config.max_items_count
    ) {
      return true;
    } else {
      return false;
    }
  }

  function setMinimumBillAmountField(minimumBillAmountField) {
    cart.minimum_bill_amount_field = minimumBillAmountField;
  }

  return {
    getItems: getItems,
    getCartItemId: getCartItemId,
    getItem: getItem,
    createNewItem: createNewItem,
    addNewItem: addNewItem,
    updateItem: updateItem,
    removeItem: removeItem,
    clearCart: clearCart,
    getBillAmount: getBillAmount,
    getCartItemsCount: getCartItemsCount,
    getTotalVat: getTotalVat,
    getCartPrice: getCartPrice,
    getDeliveryCharge: getDeliveryCharge,
    setDeliveryCharge: setDeliveryCharge,
    setMinimumBillAmount: setMinimumBillAmount,
    getMinimumBillAmount: getMinimumBillAmount,
    isAboveMinBillAmt: isAboveMinBillAmt,
    getTotalPackagingCost: getTotalPackagingCost,
    getTotalShippingCost: getTotalShippingCost,
    isCartLimitReached: isCartLimitReached,
    getTaxAmount: getTaxAmount,
    getVatAmount: getVatAmount,
    getBillAmtWithoutDeliveryCharge: getBillAmtWithoutDeliveryCharge,
    setMinimumBillAmountField: setMinimumBillAmountField,
    getGrossAmnt: getGrossAmnt,
    getTotalBasePriceWithTax: getTotalBasePriceWithTax,
  };
};

export default CartService($rootScope, PrestoSdk, CartItem);
