import * as React from "react";
import Svg, {
  Defs,
  Rect,
  G,
  Polygon,
  Mask,
  Use,
  Circle,
} from "react-native-svg";

function SvgComponent({
  width,
  height,
  fillColor = "#CCCCCC",
  color = "#7E5C5B",
}) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="23" height="23"></Rect>
      </Defs>
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/categories-with-circle">
          <Circle
            id="Oval"
            fill-opacity="0.5"
            fill={fillColor}
            cx="20"
            cy="20"
            r="20"
          ></Circle>
          <G id="Menu" transform="translate(9.000000, 8.000000)">
            <Mask id="mask-2" fill="white">
              <Use xlinkHref="#path-1"></Use>
            </Mask>
            <G id="Rectangle"></G>
            <G mask="url(#mask-2)">
              <Polygon
                id="Menu-2-path"
                stroke={color}
                fill-rule="nonzero"
                points="0 21.5625 0 18.6875 14.375 18.6875 14.375 21.5625"
              ></Polygon>
              <Polygon
                id="Menu-2-path"
                stroke={color}
                fill-rule="nonzero"
                points="0 12.9375 0 10.0625 23 10.0625 23 12.9375"
              ></Polygon>
              <Polygon
                id="Menu-2-path"
                stroke={color}
                fill-rule="nonzero"
                points="0 4.3125 0 1.4375 23 1.4375 23 4.3125"
              ></Polygon>
              <Rect
                id="Rectangle_1446"
                x="0"
                y="0"
                width="23"
                height="23"
              ></Rect>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
