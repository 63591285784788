import * as React from "react";
import Svg, { G, Text, Circle, TSpan } from "react-native-svg";

function SvgComponent({
  width = "36px",
  height = "36px",
  fillColor = "#00AF62",
  color = "#FFFFFF",
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="bupos-paid-icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="bupos-paid-icon/black/paid">
          <Circle
            id="bupos-paid-Oval"
            stroke={fillColor}
            fill={fillColor}
            cx="18"
            cy="18"
            r="17.5"
          ></Circle>
          <Text
            id="bupos-paid-Paid"
            font-family="Montserrat-SemiBold, Montserrat"
            font-size="10"
            font-weight="500"
            line-spacing="12"
            fill={color}
          >
            <TSpan x="6.5" y="22">
              Paid
            </TSpan>
          </Text>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
