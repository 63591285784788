import * as React from "react";
import Svg, { Path, G } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      data-name="Group 2964"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 50}
      height={props.height || 50}
      viewBox="0 0 50 50"
      {...props}
    >
      <Path
        data-name="Path 2519"
        d="M25 0A25 25 0 110 25 25 25 0 0125 0z"
        fill={props.color || "#34c559"}
      />
      <Path
        data-name="Path 2516"
        d="M188.8 173.293l12.855 12.855a24.984 24.984 0 0018.533-24.132v-.71L210.093 152z"
        transform="translate(-170.188 -137.016)"
        fill={props.color || "#34c559"}
      />
      <G data-name="Group 2963" fill={props.color || "#FFFFFF"}>
        <Path
          data-name="Path 2517"
          d="M102.432 210.523a2.953 2.953 0 010 4.1l-2.287 2.287a2.953 2.953 0 01-4.1 0l-10.017-10.094a2.953 2.953 0 010-4.1l2.287-2.287a2.953 2.953 0 014.1 0z"
          transform="translate(8.399 12.342) translate(-85.2 -192.266)"
        />
        <Path
          data-name="Path 2518"
          d="M182.578 126.028a2.953 2.953 0 014.1 0l2.287 2.287a2.953 2.953 0 010 4.1l-17.35 17.271a2.953 2.953 0 01-4.1 0l-2.287-2.287a2.953 2.953 0 010-4.1z"
          transform="translate(8.399 12.342) translate(-156.592 -125.2)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
