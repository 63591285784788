import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import PrestoSdk from "../global/PrestoSdk";
import $ from "../global/util";

const LoginObject = function (Utils, ErrorConstants, PrestoSdk) {
  /**
   * @ngdoc
   * @name DataModel.LoginObject#LoginObject
   * @methodOf DataModel.LoginObject
   * @description Constructor method to get Login object.
   * Method always returns a empty object
   */
  function LoginObject() {
    this.user = {
      login: "",
      password: "",
    };
    this.app_id = PrestoSdk.getAppId();
    this.merchant_id = PrestoSdk.getMerchantId();
  }

  LoginObject.prototype = {
    /**
     * @ngdoc
     * @name DataModel.LoginObject#validateObject
     * @methodOf DataModel.LoginObject
     * @constructor
     * @description Method to validate Login object.
     * @returns {boolean} - true if login object is valid otherwise false
     */
    validateObject: function () {
      console.log("this.loginObject=");
      console.log(this);
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      var login = this.user.login;
      var password = this.user.password;
      if (Utils.isEmpty(login)) {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.INVALID_EMAIL_OR_PHONE_NUMBER,
          false
        );
        return error;
      } else if (!Utils.isValidEmail(login)) {
        if (!Utils.isValidPhoneNumber(login)) {
          error = Utils.ErrorObject(
            ErrorConstants.ErrorCodes.INVALID_EMAIL_OR_PHONE_NUMBER,
            false
          );
          return error;
        }
      }

      if (Utils.isEmpty(password)) {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.PASSWORD_EMPTY,
          false
        );
        return error;
      } else if (!Utils.isValidPassword(password)) {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.INVALID_PASSWORD,
          false
        );
        return error;
      }
      return error;
    },
  };

  return LoginObject;
};

export default LoginObject(Utils, ErrorConstants, PrestoSdk);
