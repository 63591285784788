import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent({width=40, height=40, color="#00AF62"}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        transform="translate(7 10)"
        stroke="#00AF62"
        strokeWidth={2.592}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M4.568 9.74a13.915 13.915 0 0117.966 0M0 5.159a20.244 20.244 0 0127 0M9.072 14.335a7.586 7.586 0 018.868 0" />
        <Path d="M13.5 19.3547284L13.5127599 19.3547284" />
      </G>
    </Svg>
  )
}

export default SvgComponent
