import React, { useContext, useState, useEffect } from "react";
import { View, Keyboard, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import TextInputComponent from "@presto-components/TextInputs/TextInputComponent";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import asyncify from "../../common/Asyncify";
import utils from "../../utils/index";
import OperatorsManager from "@presto-services/features/operators/OperatorsManager";
const AsyncOperatorManager = asyncify(OperatorsManager);
import AppConfig from "@presto-common/config";
import LoadingContainer from "@presto-components/LoadingContainer";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import ModalContext from "@presto-contexts/ModalContext";
import WelcomeScreen from "@presto-screens/Welcome/WelcomeScreen";
import Utils from "@presto-services/common/Utils";

export default function RegisterScreen({ navigation, route }) {
  const { theme } = useContext(ThemeContext);
  const { inSideNavigation } = route?.params || {};
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [padding, setPadding] = useState(0);
  const { popRightModal, pushRightModal } = useContext(ModalContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const cancelBtn = AppConfig?.features?.cancel_btn;
  const isMobileWeb = utils.isMobileWeb();
  const isNextJsApp = utils.isNextJsApp();

  const onPhoneNumberChange = ({ nativeEvent: { text } }) => {
    setPhoneNumber(text);
  };

  const onNameChange = ({ nativeEvent: { text } }) => {
    setName(text);
  };

  const onEmailChange = ({ nativeEvent: { text } }) => {
    setEmail(text);
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      console.log("in showSubscription");
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      console.log("in hidesubscription");
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const goToThankYouScreen = () => {
    if (isMobileWeb && isNextJsApp) {
      pushRightModal(
        <View style={{ flex: 1, height: "100%", width: "100%" }}>
          <WelcomeScreen />
        </View>
      );
    } else {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "WELCOME_SCREEN",
          inSideNavigation: inSideNavigation,
        },
      });
    }
  };

  const validateOnSubmit = () => {
    let isValid = true,
      error = null;

    if (Utils.isEmpty(name)) {
      isValid = false;
      error = I18n.t("error_messages.common.name_required");
    } else if (Utils.isEmpty(phoneNumber)) {
      isValid = false;
      error = I18n.t("error_messages.common.phone_no_required");
    } else if (
      !Utils.isValidPhoneNumber(phoneNumber) ||
      phoneNumber.length !== 10
    ) {
      isValid = false;
      error = I18n.t("error_messages.common.please_enter_valid_number");
    } else if (Utils.isEmpty(email)) {
      isValid = false;
      error = I18n.t("error_messages.common.email_required");
    } else if (!Utils.isValidEmail(email)) {
      isValid = false;
      error = I18n.t("error_messages.common.please_enter_valid_email");
    }

    return { isValid, error };
  };

  const onSignup = () => {
    const { isValid, error } = validateOnSubmit();

    if (!isValid) {
      return alert(error);
    }

    // if (name.length == 0 || phoneNumber.length == 0 || email.length == 0) {
    //   alert(I18n.t("screen_messages.common.all_fields_mandatory"));
    //   return;
    // }
    setLoading(true);
    async function signup() {
      const postData = {
        operator_lead: {
          name: name,
          phone_number: phoneNumber,
          email: email,
        },
      };
      console.log("post data,", postData);
      const [error, response] = await AsyncOperatorManager.operatorLeads(
        postData
      );
      console.log("response", JSON.stringify(response));
      console.log("error", JSON.stringify(error));
      if (error) {
        setLoading(false);
        alert(
          I18n.t("screen_messages.users.profiles.request_account"),
          utils.errorMessage(error)
        );
      } else {
        setLoading(false);
        setName("");
        setPhoneNumber("");
        setEmail("");
        goToThankYouScreen();
      }
    }
    signup();
  };

  const goBack = () => {
    if (isNextJsApp && isMobileWeb) {
      popRightModal();
    } else {
      Navigator.emit({
        event: "goBack",
        params: {
          inSideNavigation: inSideNavigation,
        },
      });
    }
  };
  const insert = useSafeArea();

  return (
    <LoadingContainer
      bgColor={theme.primary}
      style={{ flex: 1, backgroundColor: theme.appBackgroundColor }}
      loading={loading}
    >
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        style={{ backgroundColor: theme.appBackgroundColor, flex: 1 }}
      >
        <View
          style={{
            flex: 1,
            flexDirextion: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: isDesktop ? 0 : padding,
          }}
        >
          <View
            style={{
              paddingTop: 30 + insert.top,
              paddingBottom: 10,
              paddingHorizontal: theme.containerPadding,
              width: "100%",
              alignItems: !isDesktop && "center",
            }}
          >
            <PrestoText fontStyle="500.medium" size={16} color={theme.darkText}>
              {I18n.t("screen_messages.register.title")}
            </PrestoText>
          </View>
          <VerticalSpacing size={30} />

          <FullWidthHorizontalPaddedBox>
            <TextInputComponent
              theme={theme}
              placeholder={I18n.t(
                "screen_messages.billing.enter_your_billing_name"
              )}
              labelText={I18n.t("screen_messages.common.name_text")}
              value={name}
              onChange={onNameChange}
              noBorder
              labelColor={theme.paragraph}
              labelColorlabelFontWeight="400"
              textInputHeight={40}
            ></TextInputComponent>
            <VerticalSpacing size={30} />
            <TextInputComponent
              theme={theme}
              value={phoneNumber}
              keyboardType={"phone-pad"}
              onChange={onPhoneNumberChange}
              noBorder
              labelColor={theme.paragraph}
              labelColorlabelFontWeight="400"
              textInputHeight={40}
              placeholder={I18n.t(
                "screen_messages.login.enter_your_phone_number"
              )}
              labelText={I18n.t("screen_messages.common.phone_number")}
            ></TextInputComponent>
            <VerticalSpacing size={30} />
            <TextInputComponent
              theme={theme}
              placeholder={I18n.t("screen_messages.login.enter_your_email")}
              labelText={I18n.t("screen_messages.login.email")}
              value={email}
              onChange={onEmailChange}
              noBorder
              labelColor={theme.paragraph}
              labelColorlabelFontWeight="400"
              textInputHeight={40}
            ></TextInputComponent>

            <VerticalSpacing size={50} />
            <FlexRowSpaceAligned>
              <View
                style={{
                  width: "50%",
                }}
              >
                {cancelBtn ? (
                  <PrestoSolidButton
                    buttonStyle={"white"}
                    bgColor={"white"}
                    borderWidth={1}
                    borderColor={theme.primary}
                    titleColor={theme.primary}
                    size="extra-large"
                    title={I18n.t("screen_messages.common.cancel_text")}
                    onPress={goBack}
                  />
                ) : (
                  <PrestoSolidButton
                    size="extra-large"
                    buttonStyle="secondary"
                    bgColor="#CCCCCC"
                    titleColor="white"
                    title={I18n.t("screen_messages.common.cancel_text")}
                    onPress={goBack}
                  />
                )}
              </View>
              <View
                style={{
                  width: "50%",
                  paddingLeft: 10,
                }}
              >
                <PrestoSolidButton
                  size="extra-large"
                  buttonStyle="primary"
                  titleColor="white"
                  title={I18n.t("screen_messages.register.register_btn")}
                  onPress={onSignup}
                />
              </View>
            </FlexRowSpaceAligned>
          </FullWidthHorizontalPaddedBox>
        </View>
      </KeyboardAwareScrollView>
    </LoadingContainer>
  );
}
