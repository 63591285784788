import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";

const ServiceCartResource = function ($http, PrestoSdk) {
  function createServiceCart(params) {
    console.log("ServiceCartResource params = > ", params);
    let url = PrestoSdk.getHostName() + "/user/v1/service_cart.json";
    return $http.post(url, { service_cart: params });
  }

  function fetchServiceCart() {
    let url = PrestoSdk.getHostName() + "/user/v1/service_cart.json";
    return $http.get(url);
  }

  function addServiceItem(params) {
    console.log("addServiceItem params = > ", params);
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/add_service.json";
    console.log("url ", url);
    return $http.put(url, { service_cart: params });
  }

  function removeServiceItem(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/remove_service.json";
    return $http.put(url, { service_cart: params });
  }

  function updateServiceItem(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/update_service.json";
    return $http.put(url, { service_cart: params });
  }

  function applyServiceCoupon(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/apply_coupon.json";
    return $http.put(url, { service_cart: params });
  }

  function removeServiceCoupon(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/remove_coupon.json";
    return $http.put(url, { service_cart: params });
  }

  function clearServiceCart(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/clear_service_cart.json";
    return $http.put(url, { service_cart: params });
  }

  function checkoutServiceCart(params) {
    //TODO: Passing _prid attribute to server for tracking response duration
    const now = new Date();
    const nowIso = now.toISOString();
    let url =
      PrestoSdk.getHostName() +
      `/user/v1/service_cart/checkout.json?_prid=${nowIso}`;
    return $http.put(url, { service_cart: params });
  }

  function placeAppointmentOrder(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v1/service_cart/book_appointment.json";
    return $http.post(url, { service_cart: params });
  }

  function fetchServiceCartIngredients(params) {
    var filePath = PrestoSdk.getAppConfig().other_config.ingrdients_map_path;
    let url =
      "https://staging-websites.s3.ap-southeast-1.amazonaws.com/" +
      params.appName +
      "/" +
      filePath +
      ".json";

    return $http.get(url);
  }
  function fetchServiceCartEquipments(params) {
    var filePath = PrestoSdk.getAppConfig().other_config.equipment_map_path;

    let url =
      "https://staging-websites.s3.ap-southeast-1.amazonaws.com/" +
      params.appName +
      "/" +
      filePath +
      ".json";
    return $http.get(url);
  }

  function fetchServiceCartComboList(params) {
    var filePath = PrestoSdk.getAppConfig().other_config.combo_map_path;
    let url =
      "https://staging-websites.s3.ap-southeast-1.amazonaws.com/" +
      params.appName +
      "/" +
      filePath +
      ".json";

    return $http.get(url);
  }

  return {
    createServiceCart: createServiceCart,
    fetchServiceCart: fetchServiceCart,
    addServiceItem: addServiceItem,
    removeServiceItem: removeServiceItem,
    updateServiceItem: updateServiceItem,
    applyServiceCoupon: applyServiceCoupon,
    removeServiceCoupon: removeServiceCoupon,
    clearServiceCart: clearServiceCart,
    checkoutServiceCart: checkoutServiceCart,
    placeAppointmentOrder: placeAppointmentOrder,
    fetchServiceCartIngredients: fetchServiceCartIngredients,
    fetchServiceCartEquipments: fetchServiceCartEquipments,
    fetchServiceCartComboList: fetchServiceCartComboList,
  };
};

export default ServiceCartResource($http, PrestoSdk);
