import * as React from "react";
import Svg, { Defs, Circle, G, Mask, Use, Path } from "react-native-svg";

function CircularUserIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Circle id="a" cx={20} cy={20} r={20} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Use
          stroke={props.color || "#707070"}
          fill={props.color || "#707070"}
          xlinkHref="#a"
        />
        <G mask="url(#b)" fill="#FFF" fillRule="nonzero">
          <Path
            d="M0 34.5v-5.667C0 22.6 6.3 17.5 14 17.5s14 5.1 14 11.333V34.5H0zM7 7a7 7 0 1114 0A7 7 0 017 7z"
            transform="translate(6 6)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default CircularUserIcon;
