import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";

import PrestoSdk from "../../global/PrestoSdk";

import ServiceCartObject from "../../data_models/ServiceCartObject";
import ServiceCartResource from "./ServiceCartResource";

const ServiceCartManager = function (
  Utils,
  ErrorConstants,
  ServiceCartResource,
  ServiceCartObject,
  PrestoSdk
) {
  function createServiceCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.createServiceCart(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchServiceCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.fetchServiceCart(params).then(
      function (response) {
        // console.log("fetchServiceCart-->", JSON.stringify(response, null, 2));
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addServiceItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.addServiceItem(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeServiceItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.removeServiceItem(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateServiceItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.updateServiceItem(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function applyServiceCoupon(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.applyServiceCoupon(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeServiceCoupon(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.removeServiceCoupon(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function clearServiceCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.clearServiceCart(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function checkoutServiceCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.checkoutServiceCart(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function placeAppointmentOrder(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServiceCartResource.placeAppointmentOrder(params).then(
      function (response) {
        var cartObject = new ServiceCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function fetchServiceCartIngredients(params, successCallback, errorCallback) {
    ServiceCartResource.fetchServiceCartIngredients(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function fetchServiceCartEquipments(params, successCallback, errorCallback) {
    ServiceCartResource.fetchServiceCartEquipments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchServiceCartComboList(params, successCallback, errorCallback) {
    ServiceCartResource.fetchServiceCartComboList(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    createServiceCart: createServiceCart,
    fetchServiceCart: fetchServiceCart,
    addServiceItem: addServiceItem,
    removeServiceItem: removeServiceItem,
    updateServiceItem: updateServiceItem,
    applyServiceCoupon: applyServiceCoupon,
    removeServiceCoupon: removeServiceCoupon,
    clearServiceCart: clearServiceCart,
    checkoutServiceCart: checkoutServiceCart,
    placeAppointmentOrder: placeAppointmentOrder,
    fetchServiceCartIngredients: fetchServiceCartIngredients,
    fetchServiceCartEquipments: fetchServiceCartEquipments,
    fetchServiceCartComboList: fetchServiceCartComboList,
  };
};

export default ServiceCartManager(
  Utils,
  ErrorConstants,
  ServiceCartResource,
  ServiceCartObject,
  PrestoSdk
);
