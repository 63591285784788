import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function SelectedCheckBox(props) {
  return (
    <Svg
      width={props.width || 32}
      height={props.height || 32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(4 4)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Rect
          stroke="#F15D2F"
          strokeWidth={2}
          x={0}
          y={0}
          width={24}
          height={24}
          rx={4}
        />
        <Path
          stroke="#6DCC36"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 13L9.33333333 18 20 8"
        />
      </G>
    </Svg>
  );
}

export default SelectedCheckBox;
