import * as React from "react";
import Svg, { Defs, G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 28}
      height={props.height || 28}
      viewBox="0 0 28 28"
      {...props}
    >
      <Defs></Defs>
      <G
        filter="url(#a)"
        stroke={props.color || "#000"}
        strokeWidth={1}
        fill={props.color || "#000"}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <G
          transform="rotate(45 5.464 17.536)"
          stroke={props.color || "#000"}
          strokeWidth={1.52}
        >
          <Path d="M-2.78038462e-14 -5.56076924e-14L18 18" />
          <Path
            transform="rotate(90 9 9)"
            d="M5.13888449e-14 -1.77011183e-14L18 18"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
