import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function OfferIcon(props) {
  return (
    <Svg width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <G
        stroke={props.color || "#000"}
        strokeWidth={1}
        fill={props?.color2 || "none"}
        fillRule="evenodd"
      >
        <Path
          d="M17.476 1.006l1.66 3.063 2.61-2.507.825 3.45 3.155-1.794-.1 3.671 3.507-1.026-1.044 3.51 3.657-.098-1.915 3.117 3.558.85-2.657 2.515L33.95 17.5l-3.217 1.742 2.657 2.516-3.56.85 1.916 3.117-3.657-.1 1.043 3.507-3.507-1.043.1 3.657-3.118-1.915-.85 3.558-2.515-2.656L17.5 33.95l-1.743-3.217-2.515 2.656-.85-3.558-3.117 1.915.1-3.657-3.507 1.043 1.042-3.507-3.656.1 1.914-3.118-3.558-.85 2.655-2.515L1.05 17.5l3.217-1.743-2.657-2.515 3.558-.85-1.914-3.117 3.656.098-1.042-3.505 3.487 1.036-.084-3.653 2.989 1.835.958-3.501 2.387 2.52 1.871-3.099z"
          transform="translate(3 2)"
        />
        <G
          transform="translate(3 2) translate(11.891 11.667)"
          strokeWidth={0.583333333}
        >
          <Path
            strokeLinecap="square"
            transform="rotate(45 5.609 5.609)"
            d="M5.60897436 -1.57051282L5.60897436 12.7884615"
          />
          <Circle cx={2.01923077} cy={2.01923077} r={1.27884615} />
          <Circle cx={8.75} cy={9.19871795} r={1.27884615} />
        </G>
      </G>
    </Svg>
  );
}

export default OfferIcon;
