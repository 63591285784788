import _ from "lodash";

function downloadObject({
  url,
  params = {},
  options = {},
  headers = {},
  onSuccess,
  onError,
}) {
  async function initDownload() {
    window.open(url, "_self");
    onSuccess({});
  }
  return { initDownload };
}

export default {
  downloadObject,
};
