import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  FlatList,
  RefreshControl,
  Platform,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import UserContext from "@presto-contexts/UserContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import CheckBoxWithText from "@presto-components/CheckBoxes/CheckBoxWithText";
import PrestoText from "@presto-components/PrestoText";
import TextInputWithStartTimeEndTimeAndButton from "@presto-components/TextInputButtons/TextInputWithStartTimeEndTimeAndButton";
import moment from "moment";
import svgs from "@presto-common-assets/svgs";
import { alertBox } from "../../common/Alert";
import _ from "lodash";
import StatementCard from "@presto-cards/StatementCard/StatementCard";
import LedgerReportManager from "@presto-services/features/ledger_reports/LedgerReportManager";
import LoadingContainer from "@presto-components/LoadingContainer";
import { PrestoSolidButton } from "../../components/PrestoButton";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import FileHelper from "@presto-helpers/FileHelper";

export default function GeneralStatement() {
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { user } = useContext(UserContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [statementFilter, setStatementFilter] = useState("Status.All");

  const listing = () => {
    const onSuccess = (response) => {
      setStatements(response.data);
      setLoading(false);
    };

    const onError = (error) => {
      console.log(`GeneralStatement listing error`, error);
      setStatements([]);
      setLoading(false);
    };

    setLoading(true);
    const params = {
      user_id: user.id,
      start_time: startDate?.toISOString(),
      end_time: endDate?.toISOString(),
    };
    LedgerReportManager.list(params, onSuccess, onError);
  };

  const onStartAndEndDatePress = (startTime, endTime) => {
    if (_.isEmpty(startTime) || _.isEmpty(endTime)) {
      return alertBox(
        I18n.t("error_messages.general_statement.select_date_range_required")
      );
    }

    const isDateType = _.every([startTime, endTime], (d) => {
      return moment.isMoment(d);
    });

    const isValidDateRange = isDateType && startTime <= endTime;

    if (!isValidDateRange) {
      setStartDate(null);
      setEndDate(null);
      return alertBox(
        I18n.t("error_messages.general_statement.select_date_range_incorrect")
      );
    }

    create({ startTime, endTime });
  };

  const create = ({ startTime, endTime }) => {
    setLoading(true);
    const params = {
      filter_by: statementFilter,
      from_date: moment(startTime).format("YYYY-MM-DD"),
      to_date: moment(endTime).format("YYYY-MM-DD"),
    };
    const sessionUrl = PrestoSdk.getHostName() + "/me.json";
    fetch(sessionUrl)
      .then((response) => {
        const url =
          PrestoSdk.getHostName() +
          `/user/v1/account_statements/download?from_date=${params.from_date}&to_date=${params.to_date}&filter_by=${params.filter_by}`;

        const downloadObject = FileHelper.downloadObject({
          url,
          params,
          options: {
            fileMime: "application/pdf",
            fileName: "AccountStatement.pdf",
            description: I18n.t(
              "success_messages.account_statement_downloaded"
            ),
          },
          headers: {
            "x-presto-st": response.headers.map
              ? response.headers.map["x-presto-st"]
              : "",
          },
          onSuccess: (response) => {
            setLoading(false);
            setStartDate(null);
            setEndDate(null);
            alertBox(I18n.t("success_messages.account_statement_downloaded"));
          },
          onError: (error) => {
            console.log(`debug download downloadObject error`, error);
            setLoading(false);
            alertBox(error.message);
          },
        });
        downloadObject.initDownload();
      })
      .catch((error) => {
        console.log(`debug download error`, error);
        alertBox(I18n.t("error_messages.unable_to_download_account_statement"));
        setLoading(false);
      });
  };

  const downloadCSV = (base64Data) => {
    setLoading(false);
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    listing();
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  };

  const renderItem = ({ item, index }) => {
    return (
      <React.Fragment key={index}>
        <View style={{ paddingVertical: theme.primaryPadding }}>
          <StatementCard
            item={item}
            onPressGenerate={downloadCSV}
            setLoading={setLoading}
          />
        </View>
      </React.Fragment>
    );
  };

  return (
    <LoadingContainer bgColor={theme.appBackgroundColor} loading={loading}>
      <ScrollView
        scrollEnabled={true}
        contentContainerStyle={{
          paddingTop: 15,
          width: "100%",
          height: "100%",
          backgroundColor: theme.appBackgroundColor,
          paddingHorizontal: theme.containerPadding,
        }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View
          style={{
            paddingVertical: theme.primaryPadding,
          }}
        >
          <PrestoText
            fontStyle="400.normal"
            extraStyle={{
              color: theme.secondary,
            }}
          >
            {I18n.t("screen_messages.general_statement.select_date_range")}
          </PrestoText>
        </View>

        <View style={{ height: 39 }}>
          <TextInputWithStartTimeEndTimeAndButton
            startTimePlaceHolder={I18n.t("screen_messages.datetime.dmy_format")}
            endTimePlaceHolder={I18n.t("screen_messages.datetime.dmy_format")}
            startTime={startDate ? moment(startDate) : null}
            endTime={endDate ? moment(endDate) : null}
            dateFormat="DD-MM-YYYY"
            datePickerMode="date"
            icon={<svgs.Calendar2 width={32} height={32} />}
            iconDisable={false}
            onChangeStartTime={setStartDate}
            onChangeEndTime={setEndDate}
            containerStyles={{
              height: 39,
              borderColor: "#979797",
              borderRadius: 6,
            }}
            iconContainerStyles={{
              width: 45,
              backgroundColor: theme.textInputBgColor,
              borderColor: "#979797",
              borderLeftWidth: 1,
            }}
          />
        </View>
        <View>
          <CheckBoxWithText
            theme={theme}
            text={I18n.t("screen_messages.general_statement.show_outstanding")}
            defaultTickedColor={theme.primary}
            borderColorCheckedIcon={theme.darkGrey}
            checkBoxUnSelectedColor={theme.darkGrey}
            onPress={(state) => {
              const value = state ? "Status.Outstanding" : "Status.All";
              setStatementFilter(value);
            }}
            state={statementFilter === "Status.Outstanding"}
            containerStyles={{ borderBottomWidth: 0 }}
          />
        </View>
        <View
          style={{
            paddingTop: 10,
          }}
        >
          <PrestoSolidButton
            buttonStyle="primary"
            size={"large"}
            buttonStyle="primary"
            size={"large"}
            title={I18n.t("screen_messages.general_statement.title")}
            onPress={() => onStartAndEndDatePress(startDate, endDate)}
            titleColor="#fff"
          />
        </View>

        {!_.isEmpty(statements) ? (
          _.isEmpty(statements) && !loading ? (
            <>
              <View
                style={{
                  paddingTop: 32,
                  paddingBottom: 23,
                }}
              >
                <PrestoText fontStyle="700.bold" color={theme.dark} size={16}>
                  {I18n.t("screen_messages.general_statement.statements")}
                </PrestoText>
              </View>
              <View
                style={{
                  paddingVertical: theme.containerPadding,
                }}
              >
                <PrestoText fontStyle="400.normal" color={theme.subHeader}>
                  {I18n.t("screen_messages.general_statement.no_statements")}
                </PrestoText>
              </View>
            </>
          ) : !loading ? (
            <View>
              <FlatList
                data={statements}
                renderItem={renderItem}
                keyExtractor={(item, index) => `${index}`}
              />
            </View>
          ) : null
        ) : null}
      </ScrollView>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return StyleSheet.create({});
}
