import $ from "../global/util";

const PriceObject = function () {
  function PriceObject() {
    this.base_price = 0;
    this.shipping_charge = 0;
    this.packing_charge = 0;
    this.tax = 0;
    this.total_price = 0;
    this.service_tax = 0;
    this.currency = "";
    this.tax_percentage = 0;
    this.service_tax_percentage = 0;
    this.vat_percentage = 0;
  }

  PriceObject.prototype = {
    validateObject: function () {},
    buildObject: function (rawPrice) {
      this.base_price = rawPrice.base_price;
      this.shipping_charge = rawPrice.shipping_charge;
      this.packing_charge = rawPrice.packing_charge;
      this.tax = rawPrice.tax;
      this.total_price = rawPrice.total_price;
      this.service_tax = rawPrice.service_tax;
      this.currency = rawPrice.currency;
      this.tax_percentage = rawPrice.tax_percentage;
      this.service_tax_percentage = rawPrice.service_tax_percentage;
      this.vat_percentage = rawPrice.vat_percentage;
    },
  };

  return PriceObject;
};

export default PriceObject();
