import OffersResource from "./OffersResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import $ from "../../global/util";

const OffersManager = function (OffersResource, Utils, ErrorConstants) {
  function getHomePageOffers(successCallback, errorCallback) {
    OffersResource.getHomePageOffers().then(
      function (homePageOffersResponse) {
        successCallback(homePageOffersResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(errorCallback);
      }
    );
  }
  return {
    getHomePageOffers: getHomePageOffers,
  };
};

export default OffersManager(OffersResource, Utils, ErrorConstants);
