import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function ItemsAddedBagIcon(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Path
          d="M25.073 8.8H2.245L.015 29.273A2.7 2.7 0 002.7 32.265h21.915a2.7 2.7 0 002.684-2.991L25.073 8.8z"
          fill={props.color || "#000"}
          transform="translate(6.297 4)"
        />
        <Path
          d="M23.156 23c.302 0 .547.224.547.5 0 .253-.206.462-.473.495l-.074.005H4.25c-.302 0-.547-.224-.547-.5 0-.253.206-.462.473-.495L4.25 23h18.906z"
          fill="#FFF"
          transform="translate(6.297 4)"
        />
        <Path
          d="M13.703 0c5.196 0 8.39 3.148 9.49 9.222.065.362-.194.707-.579.768-.385.062-.75-.182-.816-.544-.993-5.484-3.66-8.113-8.095-8.113-4.435 0-7.102 2.63-8.095 8.113-.066.362-.431.606-.816.544-.385-.061-.644-.406-.579-.768C5.313 3.148 8.507 0 13.703 0z"
          fill={props.color || "#000"}
          transform="translate(6.297 4)"
        />
      </G>
    </Svg>
  );
}

export default ItemsAddedBagIcon;
