import * as React from "react";
import Svg, { Path, G, Mask, Rect, Defs, Circle, Use } from "react-native-svg";

function CategoriesList(props) {
  const fillColor = props?.fillColor || "#707070";
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
      </Defs>
      <G
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="Menu" transform="translate(6.000000, 5.000000)">
          <Mask id="mask-2" fill="white">
            <Use xlinkHref="#path-1"></Use>
          </Mask>
          <G id="Rectangle"></G>
          <G mask="url(#mask-2)">
            <Path
              d="M0,27.1875 L0,23.5625 L18.125,23.5625 L18.125,27.1875 L0,27.1875 Z M0,16.3125 L0,12.6875 L29,12.6875 L29,16.3125 L0,16.3125 Z M0,5.4375 L0,1.8125 L29,1.8125 L29,5.4375 L0,5.4375 Z"
              id="Menu-2"
              fill={fillColor}
              fillRule="nonzero"
            ></Path>
            <Rect id="Rectangle_1446" x="0" y="0" width="29" height="29"></Rect>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default CategoriesList;
