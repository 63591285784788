import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#707070" fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path
          d="M13.757 7.428h7.214a.85.85 0 000-1.7h-7.214a.85.85 0 000 1.7z"
          transform="translate(5 5)"
        />
        <Path
          d="M29 11.646a.85.85 0 00-.85-.85h-2.606V.85a.85.85 0 00-.85-.85H10.028a.85.85 0 00-.6.25L3.704 5.976a.851.851 0 00-.249.601v6.314H.85a.85.85 0 00-.85.85V28.15c0 .47.38.85.85.85h27.3a.85.85 0 00.85-.85V11.646zm-5.155-.85h-4.37a.85.85 0 00-.793.546l-1.896 4.951h-1.49V11.68a.85.85 0 00-.849-.85H7.704a.85.85 0 00-.85.85v4.613H5.156V7.428h4.873c.47 0 .85-.38.85-.85V1.7h12.967v9.095zm-10.247 5.497H8.554V12.53h5.044v3.763zM6.358 5.727l2.82-2.824v2.824h-2.82zm-2.902 8.866v1.7H1.7v-1.7h1.757zM1.7 27.299v-9.305H17.37a.85.85 0 00.793-.546l1.897-4.952h7.24V27.3H1.7z"
          transform="translate(5 5)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
