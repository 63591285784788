import * as React from "react";
import Svg, { Path } from "react-native-svg";

function FacebookIconV2(props) {
  return (
    <Svg
      viewBox="0 0 32 32"
      width={props.width || 40}
      height={props.height || 40}
      {...props}
    >
      <Path
        fill={props.color || "#fff"}
        d="M19.254 2C15.312 2 13 4.082 13 8.826V13H8v5h5v12h5V18h4l1-5h-5V9.672C18 7.885 18.583 7 20.26 7H23V2.205C22.526 2.141 21.145 2 19.254 2z"
      />
    </Svg>
  );
}

export default FacebookIconV2;
