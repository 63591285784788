import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="16px"
      height="30px"
      viewBox="0 0 16 30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        stroke="#FFF"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <Path d="M.5 0v26.54M13.5 0v26.54" transform="translate(.529 1.61)" />
      </G>
    </Svg>
  )
}

export default SvgComponent
