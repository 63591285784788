import * as React from "react";
import Svg, { G, Path, Defs, Mask, Use } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(5 5)">
          <Mask id="b" fill={props.color2 || "#fff"}>
            <Use xlinkHref="#a" />
          </Mask>
          <G mask="url(#b)">
            <Path fill="none" d="M0 0.000639791772H29V29.000639791772H0z" />
            <Path
              d="M21.75 0c.482-.013.946.186 1.269.544.358.323.557.787.544 1.27v25.374a1.858 1.858 0 01-1.813 1.813H7.25a1.646 1.646 0 01-1.269-.544 1.646 1.646 0 01-.543-1.269V1.813A1.646 1.646 0 015.98.544 1.646 1.646 0 017.25.001h14.5zm-1.269 21.75V4.895H8.52v16.857H20.48zm-7.25 4.894c.323.359.787.558 1.269.544.482.014.946-.185 1.269-.544a1.753 1.753 0 000-2.537 1.646 1.646 0 00-1.269-.544 1.646 1.646 0 00-1.269.544 1.753 1.753 0 000 2.537z"
              fill={props.color || "#707070"}
              fillRule="nonzero"
            />
          </G>
        </G>
        <G fill={props.color || "#707070"} fillRule="nonzero">
          <Path
            d="M16 2.594V1.75A1.75 1.75 0 0014.25 0H1.75A1.75 1.75 0 000 1.75v.844c0 .086.07.156.156.156h15.688c.086 0 .156-.07.156-.156zM0 3.906V9.25C0 10.216.784 11 1.75 11h12.5A1.75 1.75 0 0016 9.25V3.906a.156.156 0 00-.156-.156H.156A.156.156 0 000 3.906zM4 8a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-.5A.5.5 0 013 7h.5a.5.5 0 01.5.5V8z"
            transform="translate(18 13)"
          />
        </G>
        <Path fill={props.color2 || "#fff"} d="M24.6 15.75H28.6V16.75H24.6z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
