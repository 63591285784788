import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={40} height={32} viewBox="0 0 40 32" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M38 18h-2v-5a5 5 0 10-10 0v5h-2a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2V20a2 2 0 00-2-2zm-7 9a2 2 0 110-4 2 2 0 010 4zm2-9h-4v-5a2 2 0 014 0v5z"
        />
        <Path d="M14 16a8 8 0 100-16 8 8 0 000 16zm6 4a3.941 3.941 0 01.506-1.906c-.3-.032-.593-.094-.906-.094h-1.044a10.88 10.88 0 01-9.112 0H8.4A8.402 8.402 0 000 26.4V29a3 3 0 003 3h17.556A3.972 3.972 0 0120 30V20z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h40v32H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
