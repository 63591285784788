import * as React from "react";
import Svg, { G, Path, Text, TSpan } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M32.354 17.955a1.016 1.016 0 010-.906l1.328-2.672a3.022 3.022 0 00-1.328-4.034l-2.656-1.394a1.027 1.027 0 01-.54-.733l-.524-2.93c-.282-1.624-1.823-2.73-3.473-2.494l-2.977.416a1.046 1.046 0 01-.872-.28L19.138.863a3.1 3.1 0 00-4.292 0L12.684 2.93a1.046 1.046 0 01-.872.28l-2.977-.415c-1.65-.238-3.191.869-3.473 2.494l-.52 2.927a1.027 1.027 0 01-.54.733l-2.656 1.394a3.022 3.022 0 00-1.328 4.034l1.328 2.672c.142.285.142.62 0 .906l-1.328 2.67a3.022 3.022 0 001.328 4.035l2.656 1.394c.285.148.485.42.54.733l.52 2.927c.252 1.46 1.532 2.526 3.03 2.525.148 0 .296-.01.443-.03l2.977-.416c.319-.046.64.058.872.28l2.162 2.068a3.1 3.1 0 004.292-.007l2.163-2.068c.23-.222.553-.325.872-.28l2.977.416c1.65.237 3.191-.87 3.473-2.494l.52-2.928c.055-.313.254-.584.54-.732l2.656-1.394a3.022 3.022 0 001.328-4.035l-1.313-2.664z"
          fill="#00AF62"
          transform="translate(3 2)"
        />
        <Text
          fontFamily="Montserrat-Medium, Montserrat"
          fontSize={8}
          fontWeight={400}
          fill="#FFF"
          transform="translate(3 2)"
        >
          <TSpan x={7} y={21}>
            {"New"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default SvgComponent;
