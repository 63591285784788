import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <Circle
        cx={15}
        cy={15}
        r={14.5}
        fill="transparent"
        stroke={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M15 22.08c.523 0 .946-.432.946-.922V15.96h5.064a.95.95 0 00.938-.946.955.955 0 00-.938-.946h-5.064V8.873c0-.498-.423-.922-.946-.922-.515 0-.946.424-.946.922v5.196H8.99a.966.966 0 00-.938.946.96.96 0 00.938.946h5.064v5.197c0 .49.431.921.946.921z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
