import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, Dimensions, SectionList } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import TransactionCardComponent from "@presto-cards/TransactionCard/TransactionCardComponent";
import { ScrollView } from "react-native-gesture-handler";
import utils from "../../utils";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import LoadingContainer from "@presto-components/LoadingContainer";
import moment from "moment";
import I18n from "i18n-js";
import { prestoAlert } from "../../common/Alert";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import PrestoText from "@presto-components/PrestoText";
import TransactionModel from "@presto-component-models/TransactionModel";

export default function TransactionScreen(props) {
  const [transactions, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [currentActive, setCurrentState] = useState("all");
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const styles = getStyles(theme, windowWidth, isDesktop);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  useEffect(() => {
    _loadTransactions();
  }, []);

  const loadNextPage = () => {
    const lastTransacion = _.last(transactions);
    const params = lastTransacion ? { older_than: lastTransacion.time } : {};
    setLoading(true);
    WalletManager.getTransactionHistory(
      params,
      (transactions) => {
        if (transactions && transactions.transactions) {
          let allTransactions = transactions.transactions;
          console.log(allTransactions);
          let credits = allTransactions.filter((item) => {
            return item.type === "CREDIT";
          });
          let debits = allTransactions.filter((item) => {
            return item.type === "DEBIT";
          });
          groupTransactions(allTransactions);
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const groupTransactions = (allTransactions) => {
    allTransactions.forEach((ele) => {
      ele.group_id = moment(ele.created_at).format("MMM yyy");
    });
    var result = _.chain(allTransactions)
      .groupBy("group_id")
      .map((value, key) => ({ title: key, data: value }))
      .value();
    console.log("allTransactions = ");
    console.log(JSON.stringify(result));
    setTransaction(result);
  };

  const _loadTransactions = () => {
    loadNextPage();
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const _isActive = (str) => {
    return str === currentActive;
  };

  const _onSetActive = (str) => {
    setCurrentState(str);
  };

  const _renderTransactions = ({ item, section }) => {
    const componentProps = {
      transaction: new TransactionModel({ transaction: item }),
      theme: theme,
    };

    return (
      <View>
        {currentActive == "all" ? (
          <TransactionCardComponent key={item.id} {...componentProps} />
        ) : null}
        {currentActive == "credit" && item.type == "CREDIT" ? (
          <TransactionCardComponent key={item.id} {...componentProps} />
        ) : null}
        {currentActive == "debit" && item.type == "DEBIT" ? (
          <TransactionCardComponent key={item.id} {...componentProps} />
        ) : null}
      </View>
    );
  };

  return (
    <LoadingContainer bgColor="transparent" loading={loading}>
      <ScrollView
        style={{
          paddingTop: 10,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 100,
          paddingHorizontal: 10,
        }}
      >
        <View style={styles.container}>
          <TouchableOpacity
            onPress={() => {
              _onSetActive("all");
            }}
          >
            <View
              style={[
                styles.buttonContainer,
                {
                  backgroundColor: _isActive("all")
                    ? theme.secondary
                    : "#EDEDED",
                  borderTopRightRadius: _isActive("all") ? 30 : 0,
                  borderBottomRightRadius: _isActive("all") ? 30 : 0,
                },
              ]}
            >
              <PrestoText
                fontStyle="medium"
                color={_isActive("all") ? theme.white : theme.darkText}
              >
                {I18n.t("screen_messages.common.all")}
              </PrestoText>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              _onSetActive("credit");
            }}
          >
            <View
              style={[
                styles.buttonContainer,
                {
                  backgroundColor: _isActive("credit")
                    ? theme.secondary
                    : "#EDEDED",
                  borderTopLeftRadius: _isActive("credit") ? 30 : 0,
                  borderBottomLeftRadius: _isActive("credit") ? 30 : 0,
                  borderTopRightRadius: _isActive("credit") ? 30 : 0,
                  borderBottomRightRadius: _isActive("credit") ? 30 : 0,
                },
              ]}
            >
              <PrestoText
                fontStyle="medium"
                color={_isActive("credit") ? theme.white : theme.darkText}
              >
                {I18n.t("screen_messages.transactions.credit")}
              </PrestoText>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              _onSetActive("debit");
            }}
          >
            <View
              style={[
                ,
                styles.buttonContainer,
                {
                  backgroundColor: _isActive("debit")
                    ? theme.secondary
                    : "#EDEDED",
                  borderTopLeftRadius: _isActive("debit") ? 30 : 0,
                  borderBottomLeftRadius: _isActive("debit") ? 30 : 0,
                },
              ]}
            >
              <PrestoText
                fontStyle="medium"
                color={_isActive("debit") ? theme.white : theme.darkText}
              >
                {I18n.t("screen_messages.transactions.debit")}
              </PrestoText>
            </View>
          </TouchableOpacity>
        </View>
        <View>
          {transactions && transactions.length > 0 ? (
            <SectionList
              sections={transactions}
              keyExtractor={(item, index) => item + index}
              renderItem={_renderTransactions}
              renderSectionHeader={({ section: { title } }) => (
                <View
                  style={{
                    paddingVertical: 20,
                  }}
                >
                  <SectionHeader
                    leftText={title}
                    fontSize={16}
                    fontStyle={isDesktop ? "500.medium" : "700.bold"}
                  />
                </View>
              )}
            />
          ) : null}
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}

function getStyles(theme, windowWidth, isDesktop) {
  let minusWidth = theme.primaryPadding / 2;
  return {
    container: {
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "#EDEDED",
      borderRadius: 30,
    },
    buttonContainer: {
      paddingVertical: 12,
      height: 40,
      width: windowWidth / 3 - minusWidth,
      maxWidth: isDesktop ? 125 : "100%",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 30,
    },
  };
}
