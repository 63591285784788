import Utils from "./Utils";
import $ from "../global/util";

const CartItem = function (Utils) {
  function CartItem(itemId, product, variation, variationList) {
    this.variation = variation;
    this.itemId = itemId;
    this.product = product;
    this.count = 0;
    this.variationsList = variationList;
  }

  CartItem.prototype.hasVariations = function () {
    console.log("variationId=" + this.variationId);
    if (this.variation && null != this.variation) {
      console.log("hasVariations=true");
      return true;
    } else {
      console.log("hasVariations=false");
      return false;
    }
  };

  CartItem.prototype.getVariation = function () {
    return this.variation;
  };

  CartItem.prototype.getImageUrl = function () {
    if (this.hasVariations()) {
      var variation = this.getVariation();
      console.log("variation.pic_url=" + variation.pic_url);
      if (
        variation.pic_url &&
        null != variation.pic_url &&
        "" != variation.pic_url
      ) {
        return variation.pic_url;
      } else {
        return this.product.pic_url;
      }
    } else {
      console.log("this.product.pic_url=" + this.product.pic_url);
      return this.product.pic_url;
    }
  };

  CartItem.prototype.getPrice = function () {
    if (this.hasVariations()) {
      var variation = this.getVariation();
      if (
        (variation.price && variation.price.total_price == 0) ||
        (variation.price &&
          null != variation.price &&
          0 < variation.price.total_price)
      ) {
        return variation.price.total_price;
      } else {
        return this.product.price.total_price;
      }
    } else {
      return this.product.price.total_price;
    }
  };

  CartItem.prototype.getPriceWithTax = function () {
    if (this.hasVariations()) {
      var variation = this.getVariation();
      if (
        variation.price &&
        null != variation.price &&
        0 < variation.price.total_price
      ) {
        return variation.price.total_price + variation.price.tax;
      } else {
        return this.product.price.total_price + this.product.price.tax;
      }
    } else {
      return this.product.price.total_price + this.product.price.tax;
    }
  };

  CartItem.prototype.getCountBasedPrice = function () {
    console.log("this.count=" + this.count);
    if (this.hasVariations()) {
      var variation = this.getVariation();
      console.log(
        "this.count * variation.price.total_price=" +
          this.count * variation.price.total_price
      );
      return this.count * variation.price.total_price;
    } else {
      if (this.product.price) {
        console.log(
          "this.count * this.product.price.total_price=" +
            this.count * this.product.price.total_price
        );
        //return this.count * this.product.price.total_price;
        return this.count * this.product.price.total_price;
      }
    }
  };

  CartItem.prototype.getDescription = function () {
    if (this.hasVariations()) {
      var variation = this.getVariation();
      if (
        variation.description &&
        "" != variation.description &&
        null != variation.description
      ) {
        return variation.description;
      } else {
        return this.product.description;
      }
    } else {
      return this.product.description;
    }
  };

  CartItem.prototype.getName = function () {
    if (this.hasVariations()) {
      var variation = this.getVariation();
      if (variation.name && "" != variation.name && null != variation.name) {
        return variation.name;
      } else {
        return this.product.name;
      }
    } else {
      return this.product.name;
    }
  };

  CartItem.prototype.getDisplayPrice = function () {
    var variation = this.variation;
    var basePrice;
    var priceMultipler;
    var displayQuantity;
    var displayName;
    if (variation) {
      basePrice = variation.price.base_price;
      priceMultipler = variation.other_data["display_price_multiplier"];
      displayQuantity = variation.other_data["display_quantity"];
      displayName = variation.other_data["display_name"];
    } else {
      basePrice = this.product.price.base_price;
      priceMultipler = this.product.other_data["display_price_multiplier"];
      displayQuantity = this.product.other_data["display_quantity"];
      displayName = this.product.other_data["display_name"];
    }
    var displayPrice = Utils.getDisplayPrice(
      basePrice,
      priceMultipler,
      displayQuantity,
      displayName
    );
    return displayPrice;
  };

  CartItem.prototype.getDisplayCount = function () {
    var variation = this.variation;
    var basePrice;
    var priceMultipler;
    var displayQuantity;
    var displayName;
    if (variation) {
      basePrice = variation.price.base_price;
      priceMultipler = variation.other_data["display_price_multiplier"];
      displayQuantity = variation.other_data["display_quantity"];
      displayName = variation.other_data["display_name"];
    } else {
      basePrice = this.product.price.base_price;
      priceMultipler = this.product.other_data["display_price_multiplier"];
      displayQuantity = this.product.other_data["display_quantity"];
      displayName = this.product.other_data["display_name"];
    }
    var displayCount = Utils.getDisplayCount(
      this.count,
      displayQuantity,
      priceMultipler,
      displayName
    );
    return displayCount;
  };
  return CartItem;
};

export default CartItem(Utils);
