import * as React from "react";
import Svg, { Defs, Circle, G, Mask, Use, Path } from "react-native-svg";

function BillCircularOutline(props) {
  const width = props.width || 40;
  const height = props.height || 40;
  const color = props.color || "#FAA85D";

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/bill">
          <Circle
            id="Oval"
            stroke={color}
            fill={color}
            cx="20"
            cy="20"
            r="19.5"
          ></Circle>
          <G id="bill" transform="translate(11.000000, 8.000000)">
            <Path
              d="M1.66926144,25 C0.748791487,25 0,24.2329403 0,23.2901329 L0,0.535098256 L0.955369046,1.51370715 C1.15907747,1.72237089 1.4893929,1.72237089 1.6931535,1.51370715 L3.17091337,-1.79995794e-15 L4.64867324,1.51370715 C4.85238167,1.72237089 5.1826971,1.72237089 5.38645769,1.51370715 L6.86421757,-1.79995794e-15 L8.34202961,1.51370715 C8.54573803,1.72237089 8.87605346,1.72237089 9.07976189,1.51370715 L10.5575218,-1.79995794e-15 L12.0352816,1.51370715 C12.2389901,1.72237089 12.5693055,1.72237089 12.7730661,1.51370715 L14.250826,-1.79995794e-15 L15.7285858,1.51370715 C15.9323464,1.72237089 16.2626619,1.72237089 16.4663181,1.51370715 L17.4224697,0.534350166 L17.4224697,12.3893361 C17.4224697,12.3941987 17.4230435,12.3989544 17.4232,12.403817 L17.4232,23.2901329 C17.4232,23.9346661 17.6391674,24.5281154 18,25 L1.66926144,25 Z"
              id="Path"
              stroke="#FFFFFF"
              stroke-width="0.9"
              fill-rule="nonzero"
              transform="translate(9.000000, 12.500000) scale(1, -1) translate(-9.000000, -12.500000) "
            ></Path>
            <Path
              d="M14.2159269,14.9617156 L5.95162468,14.9617156 C5.66351159,14.9617156 5.42996418,15.2009442 5.42996418,15.4960658 C5.42996418,15.7911874 5.66351159,16.0304159 5.95162468,16.0304159 L14.2159269,16.0304159 C14.50404,16.0304159 14.7375874,15.7911874 14.7375874,15.4960658 C14.7375874,15.2009442 14.50404,14.9617156 14.2159269,14.9617156 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M3.5749916,15.1182802 C3.47796274,15.0188911 3.34337433,14.9617156 3.20617762,14.9617156 C3.06898091,14.9617156 2.9343925,15.0188911 2.83736364,15.1182802 C2.74033479,15.2176693 2.68451712,15.3555317 2.68451712,15.4960658 C2.68451712,15.6365999 2.74033479,15.7744622 2.83736364,15.8738513 C2.9343925,15.9732405 3.06898091,16.0304159 3.20617762,16.0304159 C3.34337433,16.0304159 3.47796274,15.9732405 3.5749916,15.8738513 C3.67202045,15.7744622 3.72783812,15.6365999 3.72783812,15.4960658 C3.72783812,15.3555317 3.67202045,15.2176693 3.5749916,15.1182802 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M14.2159269,11.3138851 L5.95162468,11.3138851 C5.66351159,11.3138851 5.42996418,11.5531137 5.42996418,11.8482353 C5.42996418,12.1433569 5.66351159,12.3825855 5.95162468,12.3825855 L14.2159269,12.3825855 C14.50404,12.3825855 14.7375874,12.1433569 14.7375874,11.8482353 C14.7375874,11.5531137 14.50404,11.3138851 14.2159269,11.3138851 Z"
              id="Path-Copy-6"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M3.5749916,11.4704497 C3.47796274,11.3710606 3.34337433,11.3138851 3.20617762,11.3138851 C3.06898091,11.3138851 2.9343925,11.3710606 2.83736364,11.4704497 C2.74033479,11.5698389 2.68451712,11.7077012 2.68451712,11.8482353 C2.68451712,11.9887694 2.74033479,12.1266317 2.83736364,12.2260209 C2.9343925,12.32541 3.06898091,12.3825855 3.20617762,12.3825855 C3.34337433,12.3825855 3.47796274,12.32541 3.5749916,12.2260209 C3.67202045,12.1266317 3.72783812,11.9887694 3.72783812,11.8482353 C3.72783812,11.7077012 3.67202045,11.5698389 3.5749916,11.4704497 Z"
              id="Path-Copy-5"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M14.2159269,18.6180066 L5.95162468,18.6180066 C5.66351159,18.6180066 5.42996418,18.8572352 5.42996418,19.1523568 C5.42996418,19.4474784 5.66351159,19.6867069 5.95162468,19.6867069 L14.2159269,19.6867069 C14.50404,19.6867069 14.7375874,19.4474784 14.7375874,19.1523568 C14.7375874,18.8572352 14.50404,18.6180066 14.2159269,18.6180066 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M3.5749916,18.7745712 C3.47796274,18.6751821 3.34337433,18.6180066 3.20617762,18.6180066 C3.06898091,18.6180066 2.9343925,18.6751821 2.83736364,18.7745712 C2.74033479,18.8739603 2.68451712,19.0118227 2.68451712,19.1523568 C2.68451712,19.2928909 2.74033479,19.4307532 2.83736364,19.5301423 C2.9343925,19.6295315 3.06898091,19.6867069 3.20617762,19.6867069 C3.34337433,19.6867069 3.47796274,19.6295315 3.5749916,19.5301423 C3.67202045,19.4307532 3.72783812,19.2928909 3.72783812,19.1523568 C3.72783812,19.0118227 3.67202045,18.8739603 3.5749916,18.7745712 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M9.17033954,4.99083055 C8.65259149,4.99083055 8.2314028,4.62138085 8.2314028,4.16734351 C8.2314028,3.71325274 8.65264366,3.34380303 9.17033954,3.34380303 C9.6880818,3.34380303 10.1092763,3.71325274 10.1092763,4.16734351 C10.1092763,4.46246511 10.3428237,4.70169368 10.6309368,4.70169368 C10.9190499,4.70169368 11.1525973,4.46246511 11.1525973,4.16734351 C11.1525973,3.2962993 10.5326559,2.56135409 9.69200005,2.34216365 L9.69200005,1.98019485 C9.69200005,1.68507325 9.45845264,1.44584468 9.17033954,1.44584468 C8.88222645,1.44584468 8.64867904,1.68507325 8.64867904,1.98019485 L8.64867904,2.34216365 C7.80797097,2.56135409 7.1880818,3.29635274 7.1880818,4.16734351 C7.1880818,5.21066221 8.07735646,6.05947745 9.17033954,6.05947745 C9.6880818,6.05947745 10.1092763,6.42892715 10.1092763,6.88301792 C10.1092763,7.3371087 9.6880818,7.70650497 9.17033954,7.70650497 C8.65259149,7.70650497 8.2314028,7.33705526 8.2314028,6.88301792 C8.2314028,6.58789633 7.9978554,6.34866776 7.7097423,6.34866776 C7.4216292,6.34866776 7.1880818,6.58789633 7.1880818,6.88301792 C7.1880818,7.75406213 7.80802314,8.48900735 8.64867904,8.70819779 L8.64867904,9.14422752 C8.64867904,9.43934912 8.88222645,9.67857769 9.17033954,9.67857769 C9.45845264,9.67857769 9.69200005,9.43934912 9.69200005,9.14422752 L9.69200005,8.70819779 C10.5327081,8.48900735 11.1525973,7.75406213 11.1525973,6.88301792 C11.1525973,5.83969922 10.2633226,4.99083055 9.17033954,4.99083055 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M14.411254,4.99088399 L13.3945376,4.99088399 C13.1064245,4.99088399 12.8728771,5.23011256 12.8728771,5.52523415 C12.8728771,5.82035575 13.1064245,6.05958432 13.3945376,6.05958432 L14.411254,6.05958432 C14.6993671,6.05958432 14.9329145,5.82035575 14.9329145,5.52523415 C14.9329145,5.23011256 14.6993671,4.99088399 14.411254,4.99088399 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
            <Path
              d="M4.94619362,4.99088399 L3.9294773,4.99088399 C3.6413642,4.99088399 3.40781679,5.23011256 3.40781679,5.52523415 C3.40781679,5.82035575 3.6413642,6.05958432 3.9294773,6.05958432 L4.94619362,6.05958432 C5.23430671,6.05958432 5.46785412,5.82035575 5.46785412,5.52523415 C5.46785412,5.23011256 5.23430671,4.99088399 4.94619362,4.99088399 Z"
              id="Path"
              fill="#FFFFFF"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default BillCircularOutline;
