import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 44}
      height={props.height || 44}
      fill="none"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9 4.73l-.216.006A4.07 4.07 0 005.83 8.8v4.697l-3.512 4.469v2.72l.006.187a2.97 2.97 0 002.964 2.782h.54L5.83 36.3a4.07 4.07 0 004.07 4.07H33l.216-.006A4.07 4.07 0 0037.07 36.3l-.001-12.646.573.001a2.97 2.97 0 002.97-2.97v-2.721l-3.542-4.468V8.8A4.07 4.07 0 0033 4.73H9.9zm0 1.54H33a2.53 2.53 0 012.53 2.53v4.4H7.37V8.8A2.53 2.53 0 019.9 6.27zM7.158 14.3h28.584l2.754 3.475H4.426L7.158 14.3zm-3.3 4.575v1.81c0 .74.563 1.35 1.283 1.423l.147.007 2.081-.001L7.37 36.3a2.53 2.53 0 002.53 2.53h12.672a1.653 1.653 0 01-.022-.27V27.5c0-.911.739-1.65 1.65-1.65h7.7c.911 0 1.65.739 1.65 1.65v11.06c0 .072-.005.143-.014.213A2.53 2.53 0 0035.53 36.3V22.114l2.112.001c.79 0 1.43-.64 1.43-1.43v-1.81H3.858zM32.45 38.56c0 .098-.026.19-.07.27h-8.66a.548.548 0 01-.07-.27V27.5a.55.55 0 01.55-.55h7.7a.55.55 0 01.55.55v11.06zM14.29 15.411a.55.55 0 00-1.091.099v.88l.009.099a.55.55 0 001.09-.099v-.88l-.008-.099zM19.25 25.85h-7.7A1.65 1.65 0 009.9 27.5V33c0 .911.739 1.65 1.65 1.65h7.7A1.65 1.65 0 0020.9 33v-5.5a1.65 1.65 0 00-1.65-1.65zm-7.7 1.1h7.7a.55.55 0 01.55.55V33a.55.55 0 01-.55.55h-7.7A.55.55 0 0111 33v-5.5a.55.55 0 01.55-.55zM29.7 14.96a.55.55 0 01.541.451l.009.099v.88a.55.55 0 01-1.091.099l-.01-.099v-.88a.55.55 0 01.55-.55zm-7.159.451a.55.55 0 00-1.091.099v.88l.009.099a.55.55 0 001.09-.099v-.88l-.008-.099z"
        fill={props.primaryColor || "#000"}
      />
    </Svg>
  );
}

export default SvgComponent;
