import NavigationManager from "./NavigationManager";
import { Linking, Platform } from "react-native";
import SessionManager from "../services/features/session/SessionManager";
import SessionHelper from "../helpers/SessionHelper";
import Url from "url-parse";
import _ from "lodash";
import config from "@presto-common/config";
import Utils from "@presto-services/common/Utils";

class DeepLinkManager {
  navigateTo = (targetUrl) => {
    if (targetUrl === "app/offers") {
      SessionHelper.isLoggedInUser(
        () => {
          NavigationManager.jumpTab("PROFILE", "OFFER_SCREEN");
        },
        () => {}
      );
    } else if (targetUrl === "app/discover") {
      NavigationManager.jumpTab("DISCOVER", "DISCOVER_PAGE");
    } else if (_.startsWith(targetUrl, "app/browse")) {
      const categoryId = _.split(targetUrl, "/")[2];
      NavigationManager.jumpTab("DISCOVER", "DISCOVER_PAGE", "VENDOR_PAGE", {
        vendorId: categoryId,
      });
    } else if (_.startsWith(targetUrl, "app/categoryItem")) {
      console.log(targetUrl);
      const merchantId = _.split(targetUrl, "/")[2];
      const categoryId = _.split(targetUrl, "/")[3];
      const itemId = _.split(targetUrl, "/")[4];
      NavigationManager.jumpTab("DISCOVER", "DISCOVER_PAGE", "VENDOR_PAGE", {
        vendorId: merchantId,
        itemId: itemId,
        categoryId: categoryId,
      });
    } else if (_.startsWith(targetUrl, "app/searchCatalog")) {
      const categoryId = _.split(targetUrl, "/")[2];
      NavigationManager.jumpTab("DISCOVER", "DISCOVER_PAGE", "VENDOR_PAGE", {
        vendorId: categoryId,
      });
    } else if (targetUrl === "app/quickPay") {
      SessionHelper.isLoggedInUser(
        () => {
          NavigationManager.jumpTab("QUICK_ACTION");
        },
        () => {}
      );
    } else if (targetUrl === "app/home") {
      NavigationManager.jumpTab("HOME");
    } else if (targetUrl === "app/profile") {
      SessionHelper.isLoggedInUser(
        () => {
          NavigationManager.jumpTab("PROFILE");
        },
        () => {}
      );
    } else if (_.startsWith(targetUrl, "app/item")) {
      const itemId = _.split(targetUrl, "/")[2];
      NavigationManager.navigateTo("ITEM_DETAIL_SCREEN", {
        itemId: itemId || null,
      });
    }
  };

  navigateToNotification = (originalNotification) => {
    console.log("Clicked notification = ");
    console.log(originalNotification);
    let notification = originalNotification.data;

    var link = "";
    if (notification && notification.deepLink) {
      link = notification.deepLink;
      link = _.replace(link, '"', " ");
    }

    const isUrl = _.startsWith(link, "http") || link.indexOf("http") > -1;

    if (isUrl) {
      link = _.split(link, "?")[0];
      link = _.replace(link, '"', " ");
      Linking.openURL(link.trim()).catch((err) =>
        console.error("An error occurred", err)
      );
    } else if (config.features.deep_linking) {
      if (!_.isEmpty(link)) {
        NavigationManager.navigateTo(link);
      } else {
        NavigationManager.navigateTo("notifications");
      }
    }
  };

  navigateToDynamicLink = (link) => {
    console.log("Dynamic link : ", link);
    const url = new Url(link, Utils.queryStringParser);
    console.log(`navigateToDynamicLink url`, url);
    console.log("Dynamic link url : ", url.query);
    const path = url.query.path;
    if (path) {
      NavigationManager.navigateTo(path, url.query);
    }
    if (url.query.id) {
      NavigationManager.navigateTo("app/login", url.query.id);
    }
  };
}

export default new DeepLinkManager();
