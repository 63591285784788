import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function CashIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        fill={props.color || "#707070"}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Path
          d="M36.571.72a1.936 1.936 0 00-.254-.262A1.86 1.86 0 0035.103 0H1.897C.85 0 0 .862 0 1.923v21.154C0 24.139.85 25 1.897 25h33.206C36.15 25 37 24.14 37 23.077V1.923c0-.458-.165-.873-.429-1.204zM33.68 17.307c-2.357 0-4.269 1.937-4.269 4.327H7.59c0-2.39-1.912-4.327-4.27-4.327V7.692c2.358 0 4.27-1.936 4.27-4.327h21.82c0 2.39 1.912 4.327 4.27 4.327v9.616z"
          transform="translate(2 8)"
        />
        <Path
          d="M19 7c-2.655 0-4.808 1.833-4.808 5.5S16.345 18 19 18s4.808-1.833 4.808-5.5S21.655 7 19 7zM8.904 10.686C7.853 10.686 7 11.498 7 12.5c0 1.003.853 1.815 1.904 1.815 1.05 0 1.904-.812 1.904-1.815 0-1.002-.853-1.814-1.904-1.814zm20.192 0c-1.05 0-1.904.812-1.904 1.814 0 1.003.853 1.815 1.904 1.815S31 13.503 31 12.5c0-1.002-.853-1.814-1.904-1.814z"
          transform="translate(2 8)"
        />
      </G>
    </Svg>
  );
}

export default CashIcon;
