import React, { useContext, useState, useCallback } from "react";
import { View, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import TrackOrderCard from "@presto-cards/TrackOrderCard/TrackOrderCard";
import OrderManager from "@presto-services/features/orders/OrderManager";
import useFocusEffect from "@presto-common/useFocusEffect";
import UserContext from "@presto-contexts/UserContext";
import _ from "lodash";
import SectionHeaderWithRightLink from "@presto-components/SectionHeaders/SectionHeaderWithRightLink";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import OrdersHelper from "../../helpers/OrdersHelper";
import I18n from "i18n-js";
import utils from "../../utils";
import TrackOrderModel from "@presto-component-models/TrackOrderModel";
import SeeAllText from "@presto-screen-components/SeeAllText/SeeAllText";

export default function LastOrderComponent({}) {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [order, setOrder] = useState(undefined);
  const [orderList, setOrderList] = useState([]);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;

  const onTrackOrder = (orderId) => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: {
        nextUrl: `/orders/${orderId}`,
        screenName: "TRACK_ORDER_SCREEN",
        screenParams: {
          orderId: orderId,
        },
      },
    });
  };

  const getOrder = (orderId) => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        setOrder(response.data);
        if (orderList.length < 3) {
          let orders = orderList;
          orders.push(response.data);
          orders = OrdersHelper.upComingOrdersFilter(orders);
          orders = OrdersHelper.sortOrderOnLatest(orders);
          orders = _.uniqBy(orders, "id");
          setOrderList(orders);
        }
      },
      (error) => {}
    );
  };

  useFocusEffect(
    useCallback(() => {
      OrderManager.getMyOrders(
        {},
        (response) => {
          const filteredOrders = _.filter(response.data.orders, (order) => {
            const firstItem = _.first(order.items);
            return firstItem?.name !== "Wallet Recharge Pack";
          });
          const outForDeliverOrder = _.first(
            _.filter(filteredOrders, (o) => o.status === "DISPATCHED")
          );

          const deliveredOrder = _.first(
            _.filter(filteredOrders, (o) => o.status === "DELIVERED")
          );

          const acceptedOrder = _.first(
            OrdersHelper.upComingOrdersFilter(filteredOrders)
          );

          if (isDesktop) {
            filteredOrders.map((order, i) => {
              if (order.status !== "DELIVERED" && i < 3) {
                getOrder(order.id);
              }
            });
          } else {
            if (outForDeliverOrder) {
              getOrder(outForDeliverOrder.id);
            } else if (acceptedOrder) {
              if (acceptedOrder && acceptedOrder.id) {
                getOrder(acceptedOrder.id);
              }
            } else if (deliveredOrder) {
              getOrder(deliveredOrder.id);
            }
          }
        },
        () => {}
      );
    }, [user])
  );

  const OrderlistComponent = () =>
    orderList.length > 0 &&
    orderList.map((orderitems, index) => {
      return (
        <View key={index} style={{ marginRight: !isDesktop ? 20 : 0 }}>
          <TrackOrderCard
            minWidth={400}
            order={new TrackOrderModel({ order: orderitems })}
            onTrackOrderPressed={() => onTrackOrder(orderitems.id)}
          />
        </View>
      );
    });

  return order && order?.status !== "DELIVERED" ? (
    <View
      style={{
        padding: isDesktop ? 0 : theme.containerPadding,
        paddingBottom: theme.containerPadding,
      }}
    >
      <SeeAllText
        fontSize={isDesktop ? 22 : 18}
        rightText={I18n.t("screen_messages.common.see_all")}
        leftText={I18n.t("screen_messages.orders.upcoming_order_title")}
        onPressRightText={() => {
          setPaneState(true);
          Navigator.emit({
            event: "modal",
            params: {
              tabName: "MY_ORDERS",
              screenName: "MY_ORDERS",
              nextUrl: `/orders`,
            },
          });
        }}
      />
      {isDesktop ? (
        <View style={{ flexDirection: "row", flexWrap: "wrap", rowGap: 30 }}>
          <OrderlistComponent />
        </View>
      ) : (
        <TrackOrderCard
          order={new TrackOrderModel({ order: order })}
          onTrackOrderPressed={() => onTrackOrder(order.id)}
        />
      )}
    </View>
  ) : null;
}
