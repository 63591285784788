import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, Platform, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import ReactNativePinView from "react-native-pin-view";
import Container from "@presto-components/Containers/Container";
import { SectionSubHeader } from "@presto-components/SectionHeaders/SectionHeader";
import PrestoIcon from "@presto-components/PrestoIcon/PrestoIcon";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import { prestoAlert } from "../../common/Alert";
import I18n from "i18n-js";
import utils from "../../utils";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import LoadingContainer from "@presto-components/LoadingContainer";
import { useSafeArea } from "react-native-safe-area-context";
import SectionHeader from "@presto-components/SectionHeaders/SectionHeader";
import { useAssets } from "presto-react-components";

export default function TopUpPinScreen(props) {
  const { svgs } = useAssets();
  const amount = props.route.params?.amount;
  const [pin, setPin] = useState();
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeArea();
  let pinViewRef = React.createRef();
  const styles = createFormStyle(theme);
  const setEnteredPin = (value) => {
    setPin(value);
  };
  useEffect(() => {
    if (pin?.length === 4) {
      createTopupOrder();
    }
  }, [pin]);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };
  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };
  const handlePaymentOption = (id) => {
    const mode = "CC";
    var url =
      PrestoSdk.getHostName() +
      "/payments/paygate/new_payment?" +
      "order_id=" +
      id +
      "&mode=" +
      mode;
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "WEB_PAYMENTS",
        screenParams: {
          startUrl: url,
          endUrlCheck,
          onSuccess,
        },
      },
    });
  };
  const endUrlCheck = (url) => {
    return url.indexOf("gateway_response") !== -1;
  };
  const onSuccess = (url) => {
    setLoading(true);
    console.log("On Success url : ", url);
    var payment_url = url.substring(0, url.indexOf("gateway_response") - 1);
    payment_url = payment_url + ".json";
    var params = {
      url: payment_url,
    };
    PaymentManager.getPaymentDetails(
      params,
      function (paymentResponse) {
        console.log("Payment Status : ", paymentResponse);
        setLoading(false);
        if (paymentResponse.data.status === "SUCCESS") {
          if (props.route.params?.directPay) {
            props.route.params?.onDirectPay(pin);
          } else {
            utils.alert(
              I18n.t("screen_messages.common.sry_text"),
              paymentResponse.data.reason,
              "OK"
            );
          }
        } else {
          utils.alert(
            I18n.t("screen_messages.common.sry_text"),
            paymentResponse.data.reason,
            "OK"
          );
        }
      },
      function (error) {
        setLoading(false);
        console.log(
          "PaymentManager.getPaymentDetails error : ",
          JSON.stringify(error)
        );
      }
    );
  };
  const handleSavedCardPayment = (selectedCard, orderId) => {
    setLoading(true);
    const params = {
      saved_card_id: selectedCard.id,
      order_id: orderId,
    };
    console.log("Payment via Params : ", params);
    PaymentManager.payViaPaygateSavedCard(
      params,
      (response) => {
        setLoading(false);
        console.log("payment via pay gate saved card response : ", response);
        if (props.route.params?.directPay) {
          props.route.params?.onDirectPay(pin);
        }
      },
      (error) => {
        setLoading(false);
        utils.alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error.data)
        );
      }
    );
  };
  const createTopupOrder = () => {
    const params = {
      wallet_id: props.route.params?.wallet.wallet_id,
      amount: Math.round(amount),
      pin: pin,
      source: Platform.OS,
    };
    setLoading(true);
    WalletManager.onVerifyUserPinForWalletRecharge(
      params,
      (response) => {
        setLoading(false);
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "PAYMENTS",
            screenParams: {
              orderId: response.order_id,
              rechargeAmount: amount,
              wallet: props.route.params?.wallet,
              fromCheckout: props.route.params?.fromCheckout,
              showCart: props.route.params?.showCart,
            },
          },
        });
      },
      (error) => {
        setLoading(false);
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error) ||
            I18n.t("screen_messages.common.try_again_later")
        );
        pinViewRef.current?.clearAll();
        console.log("error =  ", JSON.stringify(error));
        if (error && error.code == "1013024") {
          Navigator.emit({
            event: "replace",
            params: {
              screenName: "PIN_SETUP",
              screenParams: {
                directPay: props.route.params?.directPay,
                description: I18n.t(
                  "screen_messages.pin.create_pin_description"
                ),
              },
            },
          });
        }
      }
    );
  };
  return (
    <LoadingContainer
      bgColor="transaparent"
      style={{ backgroundColor: theme.appBackgroundColor }}
      loading={loading}
    >
      <ScrollView
        style={{
          paddingTop: insets.top,
          backgroundColor: theme.appBackgroundColor,
          paddingBottom: 100,
        }}
      >
        <View>
          <View
            style={{
              paddingBottom: 15,
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <VerticalSpacing size={20} />
            <SectionHeader
              leftText={`${I18n.t("screen_messages.pin.confirm_app_pin")}`}
            />
            <VerticalSpacing size={20} />
            <Container>
              <SectionSubHeader
                theme={theme}
                leftText={I18n.t("screen_messages.pin.confirm_transaction_msg")}
              />
              <VerticalSpacing size={50}></VerticalSpacing>
              <ReactNativePinView
                style={{ backgroundColor: "transparent" }}
                inputSize={11}
                ref={pinViewRef}
                pinLength={4}
                buttonSize={50}
                onValueChange={(value) => setEnteredPin(value)}
                buttonAreaStyle={styles.buttonAreaStyle}
                inputAreaStyle={styles.inputAreaStyle}
                inputViewEmptyStyle={styles.inputViewEmptyStyle}
                inputViewFilledStyle={styles.inputViewFilledStyle}
                buttonViewStyle={[styles.buttonViewStyle]}
                buttonTextStyle={styles.buttonTextStyle}
                onButtonPress={(key) => {
                  if (key === "custom_right") {
                    pinViewRef.current.clear();
                  }
                }}
                customRightButton={
                  <View
                    style={{
                      alignSelf: "center",
                      height: "100%",
                      paddingTop: 10,
                    }}
                  >
                    <PrestoIcon icon={<svgs.BackSpaceIcon />}></PrestoIcon>
                  </View>
                }
              />
            </Container>
          </View>
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}

function createFormStyle(theme) {
  return {
    buttonAreaStyle: {
      marginTop: 24,
    },
    inputAreaStyle: {
      marginBottom: 24,
    },
    inputViewEmptyStyle: {
      backgroundColor: "#D8D8D8",
      marginHorizontal: 20,
    },
    inputViewFilledStyle: {
      marginHorizontal: 20,
      backgroundColor: theme.primary,
    },
    buttonViewStyle: {
      marginBottom: 15,
      backgroundColor: "#D8D8D8",
      borderRadius: 20,
    },
    buttonTextStyle: {
      color: theme.secondary,
      fontFamily: theme.primaryFont,
      fontSize: 28,
    },
  };
}
