import React, { useContext } from "react";
import { View, ScrollView, Dimensions, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import BannersGallery from "@presto-screen-components/Banners/BannersGallery";
import CardsGallery from "@presto-screen-components/CardsGallery/CardsGallery";
import WebLayout from "@presto-screens/WebLayout/WebLayout";
import * as Linking from "expo-linking";
import Footer from "@presto-screen-components/Footer/Footer";
import { VerticalSpacing } from "@presto-components/Spacing";
import VideoViewCard from "@presto-screen-components/VideoViewCards/VideoView";
import TestimonialsComponent from "@presto-screen-components/Testimonials/Testimonials";
import PrestoText from "@presto-components/PrestoText";
import I18n from "i18n-js";

export default function About() {
  const { theme } = useContext(ThemeContext);
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;

  const renderScreen = () => {
    return (
      <ScrollView
        style={{
          width: "100%",
          height: windowHeight,
          backgroundColor: theme.appBackgroundColor,
        }}
      >
        <BannersGallery title="" name="ABOUT_FARMTHEORY_TOP" />
        <View
          style={{
            paddingVertical: 30,
            paddingHorizontal: isDesktop ? 80 : theme.primaryPadding,
          }}
        >
          <CardsGallery
            title={I18n.t("screen_messages.about.title")}
            description={I18n.t("screen_messages.about.description")}
            numColumns={isDesktop ? 2 : 1}
            borderRadius={8}
          />
        </View>
        <VerticalSpacing size={20} />
        <VideoViewCard name="ABOUT_FARMTHEORY_FOOTER" />
        <VerticalSpacing size={80} />
        <View
          style={{
            paddingVertical: 20,
            paddingHorizontal: isDesktop ? 80 : theme.primaryPadding,
          }}
        >
          <TestimonialsComponent
            fontStyle="700.bold"
            fontSize={22}
            hideTitle={true}
            renderHeader={(title) => {
              return (
                <View
                  style={{
                    width: "100%",
                    paddingBottom: isDesktop ? 30 : 20,
                  }}
                >
                  <PrestoText
                    fontStyle="400.medium"
                    color={theme.title}
                    size={22}
                    extraStyle={{
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </PrestoText>
                </View>
              );
            }}
          />
        </View>

        {isDesktop && <Footer />}
      </ScrollView>
    );
  };
  if (isDesktop) {
    return (
      <>
        <WebLayout>{renderScreen()}</WebLayout>
      </>
    );
  } else {
    return <>{renderScreen()}</>;
  }
}
