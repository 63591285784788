import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 28}
      height={props.height || 5}
      viewBox="0 0 28 5"
      {...props}
    >
      <Path
        stroke={props.color || "#000"}
        strokeWidth={1.52}
        transform="rotate(135 13.728 2.5)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.53553391 -6.69238816L22.9203102 11.6923882"
      />
    </Svg>
  );
}

