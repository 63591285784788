import React from "react";
import LastOrderComponent from "@presto-screen-components/LastOrderComponent/LastOrderComponent";
import TrackOrderComponent from "@presto-screen-components/TrackOrderComponent/TrackOrderComponent";
import I18n from "i18n-js";

export default function OrderComponent({ user }) {
  return user ? (
    <>
      <TrackOrderComponent />
      <LastOrderComponent
        HeadingText={I18n.t("screen_messages.orders.past_order_title")}
      />
    </>
  ) : null;
}
