import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use, Rect, Polygon } from "react-native-svg";

function RefreshIcon2({
  color="#027EC5"
}) {

  return (
    <Svg width={40} height={40} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <Defs>
            <Path d="M15.8590971,26.4615486 C22.465278,26.4615486 27.8206457,21.1061809 27.8206457,14.5 C27.8206457,7.89381912 22.465278,2.5384514 15.8590971,2.5384514 C11.2651977,2.5384514 7.27615769,5.12816265 5.27223149,8.92733086 C4.39444485,10.5914935 3.89754849,12.4877186 3.89754849,14.5 C3.89754849,21.1061809 9.25291621,26.4615486 15.8590971,26.4615486 Z" id="path-1"></Path>
            <Mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="2" y="0" width="27.9230972" height="27.9230972">
                <Rect x="1.89754849" y="0.538451398" width="27.9230972" height="27.9230972" fill="white"></Rect>
                <Use xlinkHref="#path-1" fill="black"></Use>
            </Mask>
        </Defs>
        <G id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
            <G id="previous-location" stroke={color} transform="translate(4.640903, 5.000000)">
                <Use id="Oval" mask="url(#mask-2)" strokeWidth={4} strokeLinecap="square" strokeDasharray={20} transform="translate(15.859097, 14.500000) rotate(14.000000) translate(-15.859097, -14.500000) " xlinkHref="#path-1"></Use>
                <Polygon id="Path-38" strokeWidth="1.2" fill="#707070" strokeLinecap="round" transform="translate(3.859097, 18.000000) rotate(-59.000000) translate(-3.859097, -18.000000) " points="2.01845745 15 1.35909709 21 6.35909709 18.8027552"></Polygon>
                <Polygon id="Path-38-Copy" strokeWidth="1.2" fill="#707070" strokeLinecap="round" transform="translate(29.118062, 12.117706) scale(-1, 1) rotate(-98.000000) translate(-29.118062, -12.117706) " points="27.277422 9.1177063 26.6180616 15.1177063 31.6180616 12.9204615"></Polygon>
            </G>
        </G>
    </Svg>
  )
}

export default RefreshIcon2;
