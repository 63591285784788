import React from "react";
import { View, Image, Platform } from "react-native";
import { useAssets } from "presto-react-components";

export default function Logo() {
  const { images: Images } = useAssets();
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        marginLeft: 20,
      }}
    >
      <Image
        source={Images.textLogo}
        resizeMode="contain"
        style={{
          height: 34,
          width: 110,
          marginLeft: Platform.OS === "web" ? 0 : -15,
          minWidth: Platform.OS === "web" ? 130 : 0,
        }}
      />
    </View>
  );
}
