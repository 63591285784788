import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SortSelectedFilterIcon(props) {
  return (
    <Svg
      width={props.width || "32px"}
      height={props.height || "32px"}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M18.184 0H.822a.818.818 0 00-.73.432.79.79 0 00.064.84l6.361 8.743.006.009c.231.304.356.673.357 1.052v7.126a.782.782 0 00.236.564.822.822 0 00.896.171l3.575-1.33a.757.757 0 00.533-.746v-5.785c0-.379.126-.748.357-1.052l.006-.009 6.361-8.743a.79.79 0 00.065-.84.818.818 0 00-.725-.432z"
          transform="translate(7 7)"
          fill={props.color || "#000"}
          fillRule="nonzero"
        />
        <Circle fill={props.color2 || "#618C03"} cx={22.5} cy={7.5} r={3.5} />
      </G>
    </Svg>
  );
}

export default SortSelectedFilterIcon;
