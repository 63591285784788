import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={28}
      height={28}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={28}
        height={28}
        {...props}
      >
        <Path
          d="M16 24l10-10h-6V2h-8l.036 12H6zM4 28v4h24v-8h-4v4H8v-4H4z"
          fill="#fff"
          className="color000 svgShape"
        />
      </Svg>
    </Svg>
  )
}

export default SvgComponent
