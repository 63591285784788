import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M5.833 0S3.5 3.358 3.5 4.64a2.326 2.326 0 002.333 2.32 2.326 2.326 0 002.334-2.32C8.167 3.358 5.833 0 5.833 0zM14 0s-2.333 3.358-2.333 4.64A2.326 2.326 0 0014 6.96a2.326 2.326 0 002.333-2.32C16.333 3.358 14 0 14 0zm8.167 0s-2.334 3.358-2.334 4.64a2.326 2.326 0 002.334 2.32A2.326 2.326 0 0024.5 4.64C24.5 3.358 22.167 0 22.167 0zM3.5 9.28v4.808C1.498 14.61 0 16.414 0 18.56c0 1.16.45 2.213 1.167 3.03v5.09A2.326 2.326 0 003.5 29h21a2.326 2.326 0 002.333-2.32v-5.09C27.55 20.772 28 19.72 28 18.56c0-2.146-1.498-3.95-3.5-4.472V9.28h-4.667v4.64h-3.5V9.28h-4.666v4.64h-3.5V9.28H3.5zm1.167 6.96h18.666a2.309 2.309 0 012.334 2.32c0 1.295-1.03 2.32-2.334 2.32a2.294 2.294 0 01-1.551-.605l-.782-.7-.782.7a2.294 2.294 0 01-3.103 0l-.782-.7-.781.7a2.294 2.294 0 01-3.104 0l-.781-.7-.782.7a2.294 2.294 0 01-3.103 0l-.782-.7-.782.7c-.424.38-.953.605-1.551.605a2.309 2.309 0 01-2.334-2.32c0-1.295 1.03-2.32 2.334-2.32z"
        transform="translate(4 4)"
        fill={props.color || "#FAA85D"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={props.strokeWidth || 1}
      />
    </Svg>
  );
}

export default SvgComponent;
