import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function WalletFilledIcon(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        fill={props.color || "#707070"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      >
        <Path
          d="M6.777 5.33l11.557-3.175L17.72.91A1.627 1.627 0 0015.54.17L5.195 5.33h1.582zM21.925 2.246c-.145 0-.29.02-.43.06l-2.718.746-8.294 2.279H24l-.508-1.874a1.627 1.627 0 00-1.567-1.21zM25.699 6.474H1.632c-.496 0-.964.227-1.273.617A1.644 1.644 0 000 8.112V26.36c0 .435.172.853.478 1.16.306.308.72.481 1.153.481h24.066c.9 0 1.631-.735 1.631-1.64v-4.57h-9.661a2.778 2.778 0 01-2.767-2.784v-3.645c0-.725.28-1.421.784-1.94a2.734 2.734 0 011.98-.84h9.664v-4.47c0-.435-.17-.852-.476-1.16a1.619 1.619 0 00-1.153-.477z"
          transform="translate(6 6)"
        />
        <Path
          d="M28.466 14.15a1.63 1.63 0 00-1.097-.433h-9.704c-.9 0-1.63.735-1.63 1.64v3.647c0 .435.17.852.477 1.16.306.307.72.48 1.153.48h9.707a1.65 1.65 0 001.097-.432c.338-.31.531-.748.531-1.208v-3.646c0-.46-.194-.9-.534-1.209zm-7.405 3.356c0 .453-.365.82-.815.82h-.544a.818.818 0 01-.815-.82v-.547a.82.82 0 01.814-.815h.543c.45 0 .816.368.816.82v.548l.001-.006z"
          transform="translate(6 6)"
        />
      </G>
    </Svg>
  );
}

export default WalletFilledIcon;
