import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="26px"
      height="28px"
      viewBox="0 0 26 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-524 -564) translate(109 210) translate(181 341) translate(221) translate(14 14)"
        stroke="#686868"
        strokeWidth={1.2}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M20 24H.961C.431 24 0 23.595 0 23.095V5M20 24v-3.57m0-6.477V4.947L24 1v15.958"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.74193548 4.92464474L0 4.8840631 5.2431166 0 24 0.355027418 19.7992614 5 13.24081 4.96159622"
        />
        <Path d="M13.183 0L8 4.633V10a1 1 0 001 1h3.048a1 1 0 001-1V4.633h0L18 0" />
        <Path strokeLinecap="round" d="M2 19.5L6 19.5" />
        <Path strokeLinecap="round" d="M2 21.5L7.47944051 21.4333315" />
        <G strokeLinecap="round">
          <Path
            d="M5.503 2.166c3.34.44 5.16 2.07 5.461 4.89.488 4.576-4.127 6.046-6.312 5.935 0 0 5.762.475 4.09-5.592-.333-1.203-1.31-2.078-3.005-2.568a4.368 4.368 0 00-.483-.114"
            strokeLinejoin="round"
            transform="translate(13 13)"
          />
          <Path
            d="M1.302 5.947H.656c-.527 0-1.393.124-1.393-.421 0-.185.49-.933.584-1.09l1.954-3.235A.936.936 0 013.116.887c.123.08.226.187.303.314l1.954 3.235c.279.462.909 1.222.462 1.51-.152.099-1.093 0-1.272 0h-.769"
            transform="translate(13 13) rotate(-90 2.627 3.364)"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
