import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import $ from "../../global/util";

const WalletResource = function (Utils, PrestoSdk, $http) {
  function getWalletInfo(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/wallets/" +
      params.walletId +
      "/wallet_info.json";
    return $http.get(url);
  }
  //TODO:Is this gonna be appointment specific. Do we need a
  //Separate end point for order?
  function makePayment(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/payments/wallets/make_payment.json?" +
      params.id_type +
      "=" +
      params.id;
    return $http.post(url, params);
  }

  function getTransactionHistory(params, successCallback, errorCallback) {
    var url = PrestoSdk.getHostName() + "/user/v0/wallets/transactions.json";
    if (params?.page) {
      url += "?page_no=" + params.page;
    }
    return $http.get(url, { params: params });
  }

  function makePaymentWithPin(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() + "/payments/wallets/make_payment_with_pin.json?";
    return $http.post(url, params);
  }

  function onSetUserPin(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/wallets/" +
      params.wallet_id +
      "/reset_pin.json";
    return $http.post(url, params);
  }

  function onSetUserPinOtp(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/wallets/" +
      params.wallet_id +
      "/initiate_reset_pin.json";
    return $http.post(url);
  }

  function onVerifyUserPinForWalletRecharge(
    params,
    successCallback,
    errorCallback
  ) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/wallets/" +
      params.wallet_id +
      "/recharge_wallet.json";
    return $http.post(url, params);
  }

  function onWalletRechargeWithoutPin(params) {
    var url =
      PrestoSdk.getHostName() +
      "/wallets/" +
      params.wallet_id +
      "/recharge_wallet.json";
    return $http.post(url, params);
  }

  function requestUnBlock(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/wallets/" +
      params.wallet_id +
      "/request_unblock.json";
    return $http.post(url);
  }

  function activate(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/wallets/" +
      params.wallet_id +
      "/activate.json";
    return $http.post(url);
  }

  function getRistaLoyaltyInfo(successCallback, errorCallback) {
    var url = PrestoSdk.getHostName() + "/integrations/loyalty/rista/info.json";
    return $http.get(url);
  }

  function payViaRistaLoyalty(params) {
    var url = PrestoSdk.getHostName() + "/payments/rista/pay_via_loyalty.json";
    return $http.post(url, params);
  }

  function getWalletPromotions(successCallback, errorCallback) {
    var url = PrestoSdk.getHostName() + "/user/v0/promotions.json";
    return $http.get(url);
  }

  function getWalletRedeemedPromotions(successCallback, errorCallback) {
    var url = PrestoSdk.getHostName() + "/user/v0/promotions/redeemed.json";
    return $http.get(url);
  }

  function getEmployeeWalletInfo(params) {
    var url =
      PrestoSdk.getHostName() + "/employee/v0/wallets/" + params.id + ".json";
    return $http.get(url);
  }

  function makeUserPayment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/wallets/" +
      params.id +
      "/make_payment.json";

    return $http.post(url, params);
  }
  function walletDebit(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/wallets/" +
      params.id +
      "/debit.json";

    return $http.post(url, params);
  }

  function walletCredit(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/wallets/" +
      params.id +
      "/credit.json";
    return $http.post(url, params);
  }

  function initiateWalletRedemption(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/payments/initiate_wallet_redemption.json`;
    return $http.post(url, params);
  }

  function confirmWalletRedemption(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/payments/confirm_wallet_redemption.json`;
    return $http.post(url, params);
  }

  return {
    getWalletInfo: getWalletInfo,
    walletDebit: walletDebit,
    walletCredit: walletCredit,
    makeUserPayment: makeUserPayment,
    getEmployeeWalletInfo: getEmployeeWalletInfo,
    makePayment: makePayment,
    getTransactionHistory: getTransactionHistory,
    makePaymentWithPin: makePaymentWithPin,
    onSetUserPin: onSetUserPin,
    onSetUserPinOtp: onSetUserPinOtp,
    onVerifyUserPinForWalletRecharge: onVerifyUserPinForWalletRecharge,
    onWalletRechargeWithoutPin: onWalletRechargeWithoutPin,
    requestUnBlock: requestUnBlock,
    activate: activate,
    getRistaLoyaltyInfo: getRistaLoyaltyInfo,
    payViaRistaLoyalty: payViaRistaLoyalty,
    getWalletPromotions: getWalletPromotions,
    getWalletRedeemedPromotions: getWalletRedeemedPromotions,
    initiateWalletRedemption: initiateWalletRedemption,
    confirmWalletRedemption: confirmWalletRedemption,
  };
};

export default WalletResource(Utils, PrestoSdk, $http);
