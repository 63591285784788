import React from "react";
import { StatusBar, View } from "react-native";
import _ from "lodash";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import TopHeaderWithTitle from "@presto-screen-components/Headers/TopHeaderWithTitle";

export default function Header(props) {
  const insets = useSafeAreaInsets();
  return (
    <>
      <View style={{ top: insets.top, backgroundColor: "transparent" }}>
        <StatusBar barStyle="dark-content" />
      </View>
      <TopHeaderWithTitle title={"Coupons"} goBack={true} />
    </>
  );
}
