import StylistResource from "./StylistResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";

const StylistManager = function (StylistResource, Utils) {
  function getAllStylists(params, successCallback, errorCallback) {
    StylistResource.getAllStylists(params).then(
      function (getAllStylistsResponse) {
        successCallback(getAllStylistsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createStylist(params, successCallback, errorCallback) {
    StylistResource.createStylist(params).then(
      function (response) {
        response.message = Utils.getSuccessMessage("stylist_created");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeStylist(params, successCallback, errorCallback) {
    StylistResource.removeStylist(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addOperatorServices(params, successCallback, errorCallback) {
    StylistResource.addOperatorServices(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeOperatorServices(params, successCallback, errorCallback) {
    StylistResource.removeOperatorServices(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showOperator(params, successCallback, errorCallback) {
    StylistResource.showOperator(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateOperator(params, successCallback, errorCallback) {
    StylistResource.updateOperator(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function startBooking(params, successCallback, errorCallback) {
    if (params && Utils.isEmpty(params.id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.BOOKING_ID_EMPTY,
        false
      );
      errorObject = Utils.updateErrorObject(errorObject);
      errorCallback(errorObject);
    } else {
      StylistResource.startBooking(params).then(
        function (startBookingResponse) {
          successCallback(startBookingResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function endBooking(params, successCallback, errorCallback) {
    if (params && Utils.isEmpty(params.id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.BOOKING_ID_EMPTY,
        false
      );
      errorObject = Utils.updateErrorObject(errorObject);
      errorCallback(errorObject);
    } else {
      StylistResource.endBooking(params).then(
        function (endBookingResponse) {
          successCallback(endBookingResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getStylistStats(params, successCallback, errorCallback) {
    StylistResource.getStylistStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createOrder(params, successCallback, errorCallback) {
    StylistResource.createOrder(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addItem(params, successCallback, errorCallback) {
    StylistResource.addItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeItem(params, successCallback, errorCallback) {
    StylistResource.removeItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateItem(params, successCallback, errorCallback) {
    StylistResource.updateItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showOrder(params, successCallback, errorCallback) {
    StylistResource.showOrder(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getStatsForStylist(params, successCallback, errorCallback) {
    StylistResource.getStatsForStylist(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getStoreStats(params, successCallback, errorCallback) {
    StylistResource.getStoreStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getAllStylists: getAllStylists,
    createStylist: createStylist,
    removeStylist: removeStylist,
    addOperatorServices: addOperatorServices,
    showOperator: showOperator,
    updateOperator: updateOperator,
    removeOperatorServices: removeOperatorServices,
    startBooking: startBooking,
    endBooking: endBooking,
    getStylistStats: getStylistStats,
    getStatsForStylist: getStatsForStylist,
    createOrder: createOrder,
    addItem: addItem,
    removeItem: removeItem,
    updateItem: updateItem,
    showOrder: showOrder,
    getStoreStats: getStoreStats,
  };
};

export default StylistManager(StylistResource, Utils);
