import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { View, FlatList, Dimensions, Platform } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";
import HorizontalButtonList from "@presto-components/HorizontalButtonList";
import LoadingContainer from "@presto-components/LoadingContainer";
import { useCatalog } from "@presto-stores/CatalogStore";
import SearchManager from "@presto-services/features/search/SearchManager";
import { usePagination } from "../../hooks/pagination";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import {
  PrestoSolidButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import useFocusEffect from "@presto-common/useFocusEffect";
import DynamicPriceManager from "@presto-services/common/DynamicPriceManager";
import Config from "@presto-common/config";
import utils from "../../utils";

export default function Browse({ route, navigation }) {
  const { categoryId, subCategoryId } = route.params || {};
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const topCategories = catalog?.categories || [];
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const { Navigator } = useContext(NavigatorContext);
  let isDesktop = windowWidth > 940 ? true : false;
  const [itemPromotions, setItemPromotions] = useState(null);
  const itemsListingRef = useRef(null);
  const extraOptions = useRef({
    triggerScrollToIndex: true,
  });
  const isDynamicPricingEnabled =
    Config.merchant_config.features.dynamic_pricing;
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categoryId || _.first(topCategories)?.id
  );
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(
    subCategoryId || "all_items"
  );

  const isCategoryIdPresentInCatalog = (categoryId) => {
    return !!_.find(topCategories, { id: categoryId });
  };

  useEffect(() => {
    if (!topCategories || topCategories.length === 0) {
      return;
    }
    if (!categoryId || !isCategoryIdPresentInCatalog(categoryId)) {
      setSelectedCategoryId(_.first(topCategories)?.id);
      setSelectedSubCategoryId("all_items");
    } else if (
      categoryId &&
      isCategoryIdPresentInCatalog(categoryId) &&
      selectedSubCategoryId !== "all_items"
    ) {
      const selectedCategory = _.find(
        catalog.categories,
        (cat) => cat.id === categoryId
      );
      if (!_.find(selectedCategory?.categories, { id: subCategoryId })) {
        setSelectedSubCategoryId("all_items");
      }
    }
  }, [topCategories]);

  const searchPage = (pageNumber, success, failure) => {
    const selectedCategory = _.find(
      catalog.categories,
      (cat) => cat.id === selectedCategoryId
    );
    let catIds = [selectedCategoryId];
    const allSubIds = _.map(
      selectedCategory?.categories || [],
      (cat) => cat.id
    );
    if (
      selectedCategory?.categories &&
      selectedCategory?.categories.length > 0
    ) {
      if (selectedSubCategoryId === "all_items") {
        catIds = allSubIds;
      } else {
        catIds = [selectedSubCategoryId];
      }
    }

    const params = {
      category_ids: catIds,
      catalog_id: catalog.id,
      page: pageNumber,
    };

    SearchManager.searchCategoryItem(
      params,
      (response) => {
        if (isDynamicPricingEnabled) {
          getItems(response, success);
        } else {
          success(response.hits.hits);
        }
      },
      (error) => {
        failure(error);
      }
    );
  };
  const [
    results,
    loadNextPage,
    loadFirstPage,
    loading,
    pageLoaded,
  ] = usePagination(searchPage);

  useEffect(() => {
    if (catalog) {
      if (selectedCategoryId && catalog) {
        loadFirstPage();
      }
    }
  }, [selectedCategoryId, selectedSubCategoryId, catalog]);

  useEffect(() => {
    if (results.length && extraOptions.current.triggerScrollToIndex) {
      itemsListingRef.current.scrollToIndex({
        index: 0,
      });
      extraOptions.current.triggerScrollToIndex = false;
    }
  }, [results]);

  useEffect(() => {
    if (navigation) {
      navigation.setParams({
        categoryId: selectedCategoryId,
        subCategoryId: selectedSubCategoryId,
      });
    }
  }, [selectedCategoryId, selectedSubCategoryId]);

  useFocusEffect(
    useCallback(() => {
      getItemPromos();
    }, [])
  );

  const getItems = async (response, success) => {
    try {
      const dynamicPriceManager = new DynamicPriceManager();
      dynamicPriceManager.getMasterCatalogItems(response.hits.hits, success);
    } catch (err) {
      console.log("error dynamic manager:>>", err);
    }
  };

  const onSubCategoryPressed = (item) => {
    setSelectedSubCategoryId(item.id);
    extraOptions.current.triggerScrollToIndex = true;
  };

  const onCategoryPressed = (item) => {
    setSelectedCategoryId(item.id);
    setSelectedSubCategoryId("all_items");
  };
  const renderSubCategoryButton = (item) => {
    return (
      <Pressable onPress={() => onSubCategoryPressed(item)}>
        <View
          style={{
            height: 50,
            marginRight: 10,
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <PrestoText
            size={18}
            color={
              item.id == selectedSubCategoryId && !isDesktop
                ? theme.primary
                : theme.darkText
            }
          >
            {item.name}
          </PrestoText>
          <View
            style={{
              height: 2,
              backgroundColor:
                item.id == selectedSubCategoryId
                  ? theme.primary
                  : "transparent",
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        </View>
      </Pressable>
    );
  };

  const renderCategoryButtons = (item) => {
    if (item.id == selectedCategoryId) {
      return (
        <View style={{ marginRight: 0, flex: 1 }}>
          <PrestoSolidButton
            size="normal"
            width={150}
            title={item.name}
            titleFontStyle="medium"
            buttonStyle="primary"
            titleColor="white"
          />
        </View>
      );
    } else {
      return (
        <View style={{ marginRight: 0, flex: 1 }}>
          <PrestoFramedButton
            size="normal"
            width={150}
            buttonStyle="primary"
            title={item.name}
            onPress={() => onCategoryPressed(item)}
          />
        </View>
      );
    }
  };

  function getItemPromos() {
    ServerCartManager.getProbableItemPromotions(
      {},
      (resp) => {
        console.log("resp :>> ", JSON.stringify(resp));
        setItemPromotions(resp);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onClickItem = (item) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "ITEM_DETAIL_SCREEN",
        screenParams: {
          item: item,
        },
      },
    });
  };

  const renderItem = ({ item, index }) => {
    return (
      <>
        {!isDesktop ? (
          <ItemCard
            key={item._source.id}
            item={item._source}
            getItemPromotions={getItemPromos}
            promotions={itemPromotions}
            onClickItemCard={() => onClickItem(item)}
            theme={theme}
          />
        ) : (
          <View
            key={item._source.id}
            style={{
              flex: 1,
              width: 400,
              marginLeft: theme.primaryPadding,
              marginBottom: theme.primaryPadding,
            }}
          >
            <ItemCard item={item._source} />
          </View>
        )}
      </>
    );
  };

  const renderSelectedCategoryBrowsing = () => {
    const selectedCategory = _.find(
      catalog.categories,
      (cat) => cat.id === selectedCategoryId
    );
    const subCategories = selectedCategory?.categories;

    if (subCategories && subCategories.length > 0) {
      return (
        <View style={{ flex: 1 }}>
          <View
            style={{
              backgroundColor: "white",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              key={selectedCategoryId}
              style={{
                backgroundColor: "white",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <HorizontalButtonList
                style={{
                  width: windowWidth,
                  paddingHorizontal: 20,
                }}
                renderButton={renderSubCategoryButton}
                items={[
                  { name: "All Items", id: "all_items", key: "all_items" },
                  ...subCategories,
                ]}
                spacing={15}
              />
            </View>
          </View>

          <LoadingContainer style={{ flex: 1 }} loading={loading}>
            <View
              style={{ width: "100%", flex: 1 }}
              loading={loading && pageLoaded == 0}
            >
              <FlatList
                ref={itemsListingRef}
                data={results}
                renderItem={renderItem}
                keyExtractor={(item) => item._source.id}
                style={{
                  paddingTop: 5,
                  paddingBottom: 200,
                  height: windowHeight,
                }}
                onEndReached={
                  results.length > 0
                    ? () => {
                        loadNextPage();
                      }
                    : undefined
                }
                onEndReachedThreshold={0.25}
                contentContainerStyle={[
                  isDesktop && {
                    flex: 1,
                    flexFlow: "wrap",
                    width: "100%",
                  },
                  { paddingBottom: 100 },
                ]}
              />
            </View>
          </LoadingContainer>
        </View>
      );
    } else {
      return (
        <LoadingContainer style={{ flex: 1 }} loading={loading}>
          <View
            style={{ width: "100%", flex: 1 }}
            loading={loading && pageLoaded == 0}
          >
            <FlatList
              ref={itemsListingRef}
              data={results}
              style={{
                paddingTop: 5,
                paddingBottom: 200,
                height: windowHeight,
              }}
              renderItem={renderItem}
              keyExtractor={(item) => item._source.id}
              onEndReached={
                results.length > 0
                  ? () => {
                      loadNextPage();
                    }
                  : undefined
              }
              onEndReachedThreshold={0.25}
              contentContainerStyle={[
                isDesktop && {
                  flexFlow: "wrap",
                  width: "100%",
                  flex: 1,
                },
                { paddingBottom: 100 },
              ]}
            />
          </View>
        </LoadingContainer>
      );
    }
  };

  const renderCategoriesBrowsing = () => {
    return (
      <View
        style={{
          backgroundColor: "#F7F7F7",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            backgroundColor: "#F7F7F7",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 10,
            width: isDesktop ? "90%" : "100%",
          }}
        >
          <HorizontalButtonList
            style={{
              paddingLeft: 20,
              width: "100%",
            }}
            renderButton={renderCategoryButtons}
            items={topCategories}
            spacing={15}
          />
        </View>
      </View>
    );
  };

  return (
    topCategories && (
      <>
        <View
          style={{
            flex: 1,
            backgroundColor: theme.appBackgroundColor,
          }}
        >
          {catalog && renderCategoriesBrowsing()}
          <View
            style={{
              width: isDesktop ? "90%" : "100%",
              alignSelf: "center",
              flex: 1,
            }}
          >
            {catalog && renderSelectedCategoryBrowsing()}
          </View>
        </View>
        {!utils.isDesktop() && (
          <FloatingCartComponent
            bottom={Platform.OS === "web" ? 80 : undefined}
          />
        )}
      </>
    )
  );
}
