import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function PlayIcon(props) {
  return (
    <Svg
      width="53px"
      height="53px"
      viewBox="0 0 53 53"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-114 -68) translate(114 68)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle fill="#0070B9" cx={26.5} cy={26.5} r={26.5} />
        <Path
          fill="#D8D8D8"
          d="M17.4142857 15.9L17.4142857 36.7953683 40.1034319 26.3476842z"
        />
      </G>
    </Svg>
  )
}

export default PlayIcon
