import * as React from "react";
import Svg, { Path, G, Defs, Mask, Use, Rect } from "react-native-svg";

export default function SvgComponent({
  width="40px",
  height="40px",
  fillColor="#707070"
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Defs>
        <Rect id="path-1" x="0" y="0" width="29" height="29"></Rect>
      </Defs>
    
      <G id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <G id="Places" transform="translate(5.000000, 6.000000)">
              <Mask id="mask-2" fill="white">
                  <Use xlinkHref="#path-1"></Use>
              </Mask>
              <G id="Rectangle"></G>
              <G mask="url(#mask-2)">
                  <Rect id="Rectangle_305" x="0" y="0" width="29" height="29"></Rect>
                  <Path d="M13.6934375,17.2550082 C15.0358226,17.2574113 16.3239211,16.7252184 17.2731322,15.7760072 C18.2223434,14.8267961 18.7545363,13.5386976 18.752125,12.1963125 C18.7719848,9.36440553 16.5242895,7.03617657 13.6934375,6.95636326 C12.3507386,6.95348116 11.0621736,7.48546108 10.1125699,8.4347247 C9.16296615,9.38398831 8.63052465,10.6723627 8.63292933,12.0150625 C8.67795807,14.8212797 10.890491,17.1122657 13.6934375,17.2550082 Z M5.2000625,3.5235 C9.8903235,-1.16676086 17.4947389,-1.1667608 22.1849999,3.52350013 C26.8752608,8.21376106 26.8752609,15.8181765 22.185,20.5084375 L13.6934375,29 L5.2000625,20.5084375 C0.682699447,15.7478799 0.682699447,8.28405764 5.2000625,3.5235 Z" id="Path_114" fill={fillColor}></Path>
              </G>
          </G>
      </G>
    </Svg>
  );
}


