import _ from "lodash";

function upComingOrdersFilter(orders) {
  return _.filter(orders, (o) =>
    ["RECEIVED", "DISPATCHED", "ACCEPTED"].includes(o.status)
  );
}

function sortOrderOnLatest(orders) {
  return orders.sort((a, b) => {
    let date1 = new Date(a.created_at);
    let date2 = new Date(b.created_at);
    if (date1 >= date2) {
      return -1;
    } else {
      return 0;
    }
  });
}

export default {
  upComingOrdersFilter: upComingOrdersFilter,
  sortOrderOnLatest: sortOrderOnLatest,
};
