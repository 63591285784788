import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.125a7.375 7.375 0 104.664 13.088c.06.12.138.232.238.332l4.42 4.42a1.25 1.25 0 101.767-1.769l-4.42-4.419a1.244 1.244 0 00-.462-.293A7.375 7.375 0 0010.5 3.125zm0 1a6.375 6.375 0 110 12.75 6.375 6.375 0 010-12.75z"
        fill={props.color || "#999"}
        transform="scale(1.3) translate(-1, -2)"
      />
    </Svg>
  );
}

export default SvgComponent;
