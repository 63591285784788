import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import { IconButton } from "@presto-components/PrestoButton";
import DateTimePicker from "@presto-components/CalendarComponent/DateTimePicker";
import moment from "moment";
import _ from "lodash";
import svgs from "@presto-common-assets/svgs";
import PrestoText from "@presto-components/PrestoText";

export default function TextInputWithStartTimeEndTimeAndButton({
  startTimePlaceHolder,
  endTimePlaceHolder,
  onPress,
  startTime,
  onChangeStartTime,
  endTime,
  onChangeEndTime,
  dateFormat = "hh:mm A",
  datePickerMode = "time",
  maximumDate = new Date(),
  icon,
  iconDisable = false,
  ...props
}) {
  const [selectTimeFor, setSelectTimeFor] = useState(null);
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);

  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme, props);

  useEffect(() => {
    setStartTimeValue(startTime);
    setEndTimeValue(endTime);
  }, [startTime, endTime]);

  const dateChanged = (date_object, selectTimeForType) => {
    let changedDate = moment(date_object);
    if (selectTimeForType == "start_time") {
      setStartTimeValue(changedDate);

      if (onChangeStartTime) {
        onChangeStartTime(changedDate);
      }
    }
    if (selectTimeForType == "end_time") {
      setEndTimeValue(changedDate);
      if (onChangeEndTime) {
        onChangeEndTime(changedDate);
      }
    }

    setSelectTimeFor(null);
  };

  const getSelectedDate = () => {
    let dateValue =
      selectTimeFor == "start_time"
        ? startTimeValue
        : selectTimeFor == "end_time"
        ? endTimeValue
        : null;

    return moment.isMoment(dateValue)
      ? new Date(dateValue.toISOString())
      : dateValue;
  };

  const DateComponent = ({ type, placeholder }) => {
    return (
      <DateTimePicker
        selectedDate={getSelectedDate()}
        placeholder={placeholder}
        onDateChange={(dateObject) => {
          dateChanged(dateObject, type);
        }}
        modal={selectTimeFor}
        mode={datePickerMode}
        disableMinimumDate={true}
        maximumDate={maximumDate}
        onCancel={() => {
          setSelectTimeFor(null);
        }}
        customInputProps={{
          containerProps: {
            style: {
              width: "100%",
              paddingLeft: 10,
              height: "100%",
            },
          },
          component: (
            <View
              style={{
                height: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrestoText fontStyle="400.normal" size={12} color="#979797">
                {placeholder}
              </PrestoText>
            </View>
          ),
        }}
        yearStartRange={2000}
      />
    );
  };

  const formatTime = (date_obj) => {
    return moment(date_obj.toISOString()).format(dateFormat);
  };

  const formatStartTime = () => {
    return startTimeValue ? formatTime(startTimeValue) : startTimePlaceHolder;
  };

  const formatEndTime = () => {
    return endTimeValue ? formatTime(endTimeValue) : endTimePlaceHolder;
  };

  const datePlaceholder = ({ type = null, placeholder }) => {
    return DateComponent({
      type,
      placeholder,
    });
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.inner}>
          <View
            style={[styles.textInputContainer, { width: "40%", paddingTop: 8 }]}
          >
            {datePlaceholder({
              type: "start_time",
              placeholder: formatStartTime(),
            })}
          </View>
          <View
            style={[
              styles.textInputContainer,
              {
                width: "35%",
                borderLeftWidth: 1,
                borderColor: "#979797",
                paddingTop: 8,
              },
            ]}
          >
            {datePlaceholder({
              type: "end_time",
              placeholder: formatEndTime(),
            })}
          </View>
          <View
            style={{
              width: "25%",
              height: "100%",
              backgroundColor: theme.primary,
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
              opacity: iconDisable ? 0.4 : 1,
              ...props?.iconContainerStyles,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <IconButton
                borderColor="transparent"
                icon={
                  icon ?? <svgs.PlusIconBuposOperator color={theme.white} />
                }
                onPress={() =>
                  !iconDisable ? onPress(startTimeValue, endTimeValue) : null
                }
              />
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

function getStyles(theme, { containerStyles = {} }) {
  return StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
      borderColor: theme.tertiary,
      height: 60,
      backgroundColor: theme.white,
      borderRadius: 8,
      borderWidth: 1,
      ...containerStyles,
    },
    inner: {
      flex: 1,
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    textInputContainer: {},
    textInputStyle: {
      height: "100%",
      width: "100%",
      paddingLeft: 10,
      fontSize: 12,
      color: "#979797",
      fontWeight: "400",
      fontFamily: theme.primaryFont,
    },
  });
}
