import React, { useContext, useEffect } from "react";
import * as SplashScreen from "expo-splash-screen";
import MainScreens from "@presto-screens/Main/Main";
import asyncify from "../../common/Asyncify";
import UserContext from "@presto-contexts/UserContext";
import SessionHelper from "@presto-helpers/SessionHelper";
import AsyncStorage from "@react-native-community/async-storage";

const AsyncSessionHelper = asyncify(SessionHelper);
import config from "@presto-common/config";

export default function LoadingScreen() {
  const { authState, setUser } = useContext(UserContext);

  const hideSplashScreen = async () => {
    const [error, response] = await AsyncSessionHelper.isLoggedInUser();
    await SplashScreen.hideAsync().catch(console.warn);
    if (error) {
      setUser(undefined);
    } else {
      setUser(response.data);
    }
  };

  const checkAndHideSplashScreen = async () => {
    const previouslyOpened = await AsyncStorage.getItem("PREVIOUSLY_OPENED");
    if (!config.splash_screen_delay || previouslyOpened === "true") {
      hideSplashScreen();
    } else {
      AsyncStorage.setItem("PREVIOUSLY_OPENED", "true");
      setTimeout(() => {
        hideSplashScreen();
      }, config.splash_screen_delay);
    }
  };

  useEffect(() => {
    checkAndHideSplashScreen();
  }, [authState]);

  return <MainScreens />;
}
