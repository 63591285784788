import React from "react";
import { View, TextInput, Dimensions } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PrestoIcon from "../PrestoIcon";
import ThemeContext from "@presto-contexts/ThemeContext";
import { withAssets } from "presto-react-components";

class PrestoSearchInput extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  focus() {
    this.inputRef.current.focus();
  }

  onClearSearch() {
    this.props.onChange({
      nativeEvent: { text: "" },
    });
  }

  render() {
    const { svgs } = this.props.assets;
    const {
      onSearch,
      onSearchFocus,
      onEndSearchFocusEnd,
      showSoftInputOnFocus,
    } = this.props;
    const { theme } = this.context;
    const icon = <svgs.SearchIconV2 color="#999999" width={28} height={30} />;
    const closeIcon = <svgs.CloseIcon width={30} height={30} />;
    let isDesktop = Dimensions.get("window").width > 940 ? true : false;
    const styles = getStyles(theme, icon, isDesktop);


    return (
      <View style={styles.container}>
        <View
          style={[
            styles.textinputContainer,
            {
              marginRight: icon ? 40 : theme.textInputBorderRadius,
            },
          ]}
        >
          <TextInput
            ref={this.inputRef}
            underlineColorAndroid="transparent"
            autoCapitalize={this.props.autoCapitalize || "none"}
            autoCompleteType={this.props.autoCompleteType || "off"}
            autoCorrect={this.props.autoCorrect || false}
            keyboardType={this.props.keyboardType || "default"}
            value={this.props.value}
            placeholder={this.props.placeholder}
            placeholderTextColor={
              this.props.textInputPlaceholderColor ||
              styles.placeHolder.textColor
            }
            showSoftInputOnFocus={showSoftInputOnFocus || true}
            returnKeyType={this.props.returnKeyType || "search"}
            onSubmitEditing={() => onSearch(this.props.value)}
            {...this.props}
            style={{
              ...styles.textInputStyle,
            }}
            numberOfLines={this.props.numberOfLines || 1}
            onFocus={onSearchFocus}
            onBlur={onEndSearchFocusEnd}
          ></TextInput>
          {icon && (
            <TouchableOpacity
              style={{ marginRight: 8 }}
              onPress={() => {
                if (this.props.value?.length > 0) {
                  this.onClearSearch();
                } else {
                  onSearchFocus(this.props.value);
                }
              }}
            >
              <PrestoIcon
                icon={this.props.value?.length > 0 ? closeIcon : icon}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }
}

export default withAssets(PrestoSearchInput);

const getStyles = (theme, icon, isDesktop) => {
  return {
    container: {
      width: "100%",
    },
    textInputStyle: {
      flex: 1,
      height: 50,
      borderWidth: 0,
      fontSize: 16,
      fontFamily: theme.primaryFont,
      paddingHorizontal: isDesktop ? 20 : 10,
      color: theme.textInputColor,
      borderRadius: theme.textInputBorderRadius || 25,
      margin: 0,
      outlineStyle: "none",
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: theme.textInputHeight,
      backgroundColor: "#E5E5E5",
      borderWidth: theme.textInputBorderWidth ?? 2,
      borderRadius: theme.textInputBorderRadius || 25,
      borderColor: theme.textInputBorderColor || "#CCCCCC",
    },
    placeHolder: {
      textColor: theme.placeHolderColor,
    },
  };
};
