import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const GalleryResource = function ($http, PrestoSdk) {
  function getGallery(params) {
    params.name = encodeURIComponent(params.name);
    var url =
      PrestoSdk.getHostName() +
      "/galleries/search.json?name=" +
      params.name +
      "&merchant_id=" +
      params.merchant_id;
    if (params.full_url) {
      url = params.full_url;
    }
    return $http.get(url);
  }
  return {
    getGallery: getGallery,
  };
};

export default GalleryResource($http, PrestoSdk);
