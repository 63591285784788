import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
   <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M24.66 6L26 7.488 15.117 19.933a.124.124 0 00-.023.124l.023.039L26 32.54l-.675.732-.869.727-10.883-12.445a2.33 2.33 0 01-.569-1.399 2.35 2.35 0 01.442-1.523l.127-.159L24.66 6z"
        fill={props.color || "#707070"}
        fillRule="nonzero"
        transform="matrix(-1 0 0 1 39 0)"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default SvgComponent;
