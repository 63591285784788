import React, { useState, useContext, useCallback } from "react";
import { View, ScrollView, Platform, Dimensions, FlatList } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import SearchManager from "@presto-services/features/search/SearchManager";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useSafeArea } from "react-native-safe-area-context";
import { ModalWithIcon } from "@presto-components/Modals/Modal";
import PrestoText from "@presto-components/PrestoText";
import { useFocusEffect } from "@react-navigation/native";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";

export default function ListItemsAvailableForDiscount({ route, navigation }) {
  const {
    availableDiscountItemIds = [],
    header,
    getItemPromotions,
  } = route.params;
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const { cart } = useContext(CartContext);
  const [state, setState] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  useFocusEffect(
    useCallback(() => {
      init();
    }, [cart])
  );

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const init = () => {
    const onSuccess = (response) => {
      const items = _.map(response?.hits?.hits, (item) => item._source);
      setAvailableProducts(items);
      setLoading(false);
    };

    const onError = (error) => {
      console.log(`init onError`, error);
      setLoading(false);
    };

    setLoading(true);
    let params = {
      ids: availableDiscountItemIds,
    };
    SearchManager.searchCategoryItem(params, onSuccess, onError);
  };

  const renderItem = ({ item, index }) => {
    return (
      <ItemCard
        key={item.id}
        item={item}
        getItemPromotions={getItemPromotions}
      />
    );
  };

  const insets = useSafeArea();
  const renderActionSheet = () => {
    return (
      <ModalWithIcon goBack={goBack} keyboardAvoidView={false}>
        <View
          style={{
            backgroundColor: "white",
            flex: 1,
            paddingTop: Platform.OS === "web" ? insets.top + 30 : 0,
            minHeight: windowHeight,
          }}
        >
          <LoadingContainer bgColor={"transparent"} loading={loading}>
            <ScrollView
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <View style={{ paddingHorizontal: theme.primaryPadding + 5 }}>
                <PrestoText
                  fontStyle={"semibold"}
                  size={theme.h2FontSize}
                  color={theme.darkText}
                >
                  {header
                    ? header
                    : I18n.t("screen_messages.cart.discounts_available_choose")}
                </PrestoText>
              </View>

              <VerticalSpacing size={theme.primaryPadding} />

              <View>
                <FlatList data={availableProducts} renderItem={renderItem} />
              </View>
            </ScrollView>
          </LoadingContainer>
        </View>
      </ModalWithIcon>
    );
  };

  return renderActionSheet();
}
