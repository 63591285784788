import * as React from "react";
import Svg, { Path, G, Mask, Defs, Use } from "react-native-svg";

 export default function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(6 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill={props.color || "#FFF"}>
          <Use xlinkHref="#a" />
        </Mask>
        <G mask="url(#b)">
          <Path
            d="M0 27.188v-3.625h18.125v3.625H0zm0-10.875v-3.625h29v3.624H0zM0 5.438V1.813h29v3.625H0z"
            fill={props.color2 || "#707070"}
            fillRule="nonzero"
          />
          <Path d="M0 0H29V29H0z" />
        </G>
      </G>
    </Svg>
  );
}

