import NotificationManager from "../services/features/notifications/NotificationManager";
import OrderManager from "../services/features/orders/OrderManager";
import AsyncStorage from "@react-native-community/async-storage";
import _ from "lodash";
import moment from "moment";
import utils from "../utils";

export const NOTIFICATIONS_OPENED_TIME_KEY = "NOTIFICATIONS_OPENED_TIME";

var deliveryTitleMap = {
  RECEIVED: "Order pending acceptance from vendor",
  ACCEPTED: "Order on route to be devlivered ",
  DISPATCHED: "Order on route for delivery",
  DELIVERED: "Order delivered",
  REJECTED: "Order declined",
  BREACHED: "Order was not received by vendor",
};

var pickupTitleMap = {
  RECEIVED: "Order pending acceptance from vendor",
  ACCEPTED: "Order accepted and in progress",
  DISPATCHED: "Order ready for collection",
  DELIVERED: "Order completed",
  REJECTED: "Order declined",
  ACCEPTED_INSTRUCTION: "Order ready estimation time ",
};

var deliveryDescMap = {
  RECEIVED:
    "thank you, order at VENDOR_NAME has been placed, awaiting confirmation from vendor",
  ACCEPTED: "Order at VENDOR_NAME on route to be delivered",
  DISPATCHED: "Order at VENDOR_NAME collected & delivery on the way",
  DELIVERED: "Order at VENDOR_NAME delivered",
  REJECTED: "Sorry, order at VENDOR_NAME declined : ",
  BREACHED:
    "Sorry your order at VENDOR_NAME was not received by vendor, please place another order ",
};
var pickupDescMap = {
  RECEIVED:
    "thank you, order at VENDOR_NAME has been placed, awaiting confirmation from vendor",
  ACCEPTED: "Order at VENDOR_NAME Accepted",
  DISPATCHED: "Order at VENDOR_NAME Ready",
  DELIVERED: "Order at VENDOR_NAME collected & completed",
  REJECTED: "Sorry, order at VENDOR_NAME declined : ",
  ACCEPTED_INSTRUCTION: "Order at VENDOR_NAME is progress",
};

var timeFieldMap = {
  ACCEPTED: "accepted_at",
  DISPATCHED: "dispatched_at",
  DELIVERED: "delivered_at",
  RECEIVED: "created_at",
  REJECTED: "updated_at",
  ACCEPTED_INSTRUCTION: "updated_at",
};

class NotificationsHelper {
  constructor() {}

  async fetchNotificationsCount(user) {
    const orderNotifications = await this._fetchOrderNotifications();
    const lastNotificationsOpenedTime = JSON.parse(
      await AsyncStorage.getItem(NOTIFICATIONS_OPENED_TIME_KEY)
    );

    if (!lastNotificationsOpenedTime) {
      console.log(
        "Last notification opened time : ",
        lastNotificationsOpenedTime
      );
      return user.unread_notification_count + orderNotifications.length;
    }
    console.log(
      "Last notification opened time : ",
      utils.formatTime(lastNotificationsOpenedTime)
    );
    const filteredOrderNotifications = _.filter(
      orderNotifications,
      (notification) => {
        return (
          moment(new Date(notification.created_at)).diff(
            moment(lastNotificationsOpenedTime)
          ) > 0
        );
      }
    );
    return user.unread_notification_count + filteredOrderNotifications.length;
  }

  async fetchPersistableNotificationsCount(user) {
    console.log("user = ", JSON.stringify(user));
    return user.unread_notification_count;
  }

  async fetchNotifications() {
    try {
      const pushNotificationDevice = JSON.parse(
        await AsyncStorage.getItem("PUSH_NOTIFICATION_DEVICE")
      );

      let notifications = [];
      if (pushNotificationDevice && pushNotificationDevice.id) {
        notifications = await this._fetchNotifications(
          pushNotificationDevice.id
        );
      }

      const orderNotifications = await this._fetchOrderNotifications();
      const allNotifications = [...notifications, ...orderNotifications];
      return this._processNotifications(allNotifications);
    } catch (exception) {
      console.log("Error in fetch Notifications : ", exception);
      throw exception;
    }
  }

  _fetchOrderNotifications = () => {
    return new Promise((resolve) => {
      var params = {};
      params.page = 1;
      var trackNotifications = [];
      OrderManager.getMyOrders(
        params,
        (myOrdersResponse) => {
          if (
            !myOrdersResponse ||
            !myOrdersResponse.data ||
            !myOrdersResponse.data.orders
          ) {
            resolve([]);
            return;
          }
          var orders = myOrdersResponse.data.orders;
          // console.log("orders ", orders);
          _.forEach(orders, (order) => {
            console.log("orders.steet= ", order.status);
            if (
              order.status !== "CREATED" &&
              (order.other_data.pickup || order.other_data.delivery)
            ) {
              this._addNotifications(trackNotifications, order);
            }
          });
          resolve(trackNotifications);
        },
        () => {
          resolve([]);
        }
      );
    });
  };

  _fetchNotifications = (pushNotificationDeviceId) => {
    return new Promise((resolve) => {
      NotificationManager.getNotifications(
        { deviceId: pushNotificationDeviceId, page: 1 },
        (response) => {
          resolve(response.data);
        },
        (error) => {
          resolve([]);
        }
      );
    });
  };

  _addNotifications = (notifications, order) => {
    if (order.status === "RECEIVED") {
      this._addNotification(notifications, order, "RECEIVED");
    }
    if (order.accepted_at) {
      this._addNotification(notifications, order, "ACCEPTED");
    }
    if (order.dispatched_at) {
      this._addNotification(notifications, order, "DISPATCHED");
    }
    if (order.delivered_at) {
      this._addNotification(notifications, order, "DELIVERED");
    }
    if (order.status === "REJECTED") {
      this._addNotification(notifications, order, "REJECTED");
    }
  };

  _addNotification = (notifications, order, statusKey) => {
    var notification = {};
    notification.created_at = order[timeFieldMap[statusKey]];
    notification.orderData = order;
    if (order.other_data.pickup) {
      notification.title = pickupTitleMap[statusKey];
      notification.msg = pickupDescMap[statusKey].replace(
        /VENDOR_NAME/i,
        order.merchant_hash.name
      );
      if (order.accept_instructions) {
        notification.title = pickupTitleMap["ACCEPTED_INSTRUCTION"];
        notification.msg = pickupDescMap["ACCEPTED_INSTRUCTION"].replace(
          /VENDOR_NAME/i,
          order.merchant_hash.name
        );
        var estimatedTime = moment(
          new Date(
            new Date(notification.created_at).getTime() +
              order.accept_instructions * 60000
          )
        ).format("hh:mm");
        notification.title += "" + estimatedTime;
      }
    } else {
      notification.title = deliveryTitleMap[statusKey];
      notification.msg = deliveryDescMap[statusKey].replace(
        /VENDOR_NAME/i,
        order.merchant_hash.name
      );
      if (order.accept_instructions && statusKey == "ACCEPTED") {
        notification.created_at = moment(
          new Date(
            new Date(notification.created_at).getTime() +
              order.accept_instructions * 60000
          )
        );
        notification.estimatedTime = "Estimated time of arrival ";
      }
    }

    if (statusKey == "REJECTED") {
      notification.msg += "" + order.reject_reason;
      if (order.reject_reason === "Orders sla breached") {
        notification.title = deliveryTitleMap["BREACHED"];
        notification.msg = deliveryDescMap["BREACHED"].replace(
          /VENDOR_NAME/i,
          order.merchant_hash.name
        );
      }
    }
    var today = moment();
    if (today.diff(moment(new Date(notification.created_at)), "days") < 5) {
      notifications.push(notification);
    }
  };

  _processNotifications = (notifications) => {
    console.log("All Notifications : ", notifications);
    const soredNotifications = notifications.sort((a, b) => {
      if (moment(a.created_at) < moment(b.created_at)) {
        return 1;
      } else if (moment(a.created_at) > moment(b.created_at)) {
        return -1;
      } else {
        return 0;
      }
    });
    return soredNotifications;
  };
}

export default new NotificationsHelper();
