import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import config from "@presto-common/config";

const ServerCartResource = function ($http, PrestoSdk) {
  function createCart(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart.json";
    return $http.post(url, { cart: params });
  }

  function fetchCart() {
    let url = PrestoSdk.getHostName() + "/user/v0/cart.json";
    return $http.get(url);
  }

  function addItem(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/add_item.json";
    console.log("url ", url);
    return $http.put(url, { cart: params });
  }

  function removeItem(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/remove_item.json";
    return $http.put(url, { cart: params });
  }

  function updateItem(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/update_item.json";
    return $http.put(url, { cart: params });
  }

  function applyCoupon(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/apply_coupon.json";
    return $http.put(url, { cart: params });
  }

  function removeCoupon(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/remove_coupon.json";
    return $http.put(url, { cart: params });
  }

  function clearCart(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/clear_cart.json";
    return $http.put(url, { cart: params });
  }

  function checkout(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/checkout.json";
    return $http.put(url, { cart: params });
  }

  function placeOrder(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/place_order.json";
    return $http.post(url, { cart: params });
  }

  function validateCart(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/validate.json";
    return $http.post(url, { cart: params });
  }

  function fetchBooks(params) {
    let url = config.constants.booksmap_json;
    return $http.get(url);
  }

  function getProbableItemPromotions(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v0/cart/probable_item_promotions.json";
    return $http.get(url);
  }

  return {
    createCart: createCart,
    fetchCart: fetchCart,
    addItem: addItem,
    removeItem: removeItem,
    updateItem: updateItem,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    clearCart: clearCart,
    checkout: checkout,
    placeOrder: placeOrder,
    validateCart: validateCart,
    fetchBooks: fetchBooks,
    getProbableItemPromotions: getProbableItemPromotions,
  };
};

export default ServerCartResource($http, PrestoSdk);
