import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";
import SelectedCheckbox from "@presto-components/SelectedCheckBox";
import UnSelectedCheckbox from "@presto-components/UnSelectedCheckBox";
import Pressable from "@presto-components/Pressable/Pressable";

export default function RadioButtonWithText(props) {
  const {
    text,
    theme,
    onPress,
    value,
    option,
    padding,
    description,
    icon,
    status,
    checkBoxProps,
  } = props;
  let pushText = icon ? 15 : 0;
  return (
    <Pressable
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
    >
      <FlexRowSpaceAligned
        styleSet={{
          paddingHorizontal: props.paddingHorizontal || 0,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            width: "85%",
          }}
        >
          {icon ? <PrestoIcon icon={icon} /> : null}
          <View style={{ width: "90%" }}>
            <PrestoText
              fontStyle="400.normal"
              color="#333333"
              extraStyle={styles.textStyle}
            >
              {text}
            </PrestoText>
            {description ? (
              <PrestoText
                fontStyle="400.normal"
                color="#999999"
                extraStyle={styles.descriptionStyle}
              >
                {description}
              </PrestoText>
            ) : null}
          </View>
        </View>

        <View
          style={{
            ...styles.iconStyles,
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {props.rightText ? (
            <PrestoText fontStyle="bold" color={theme.primary}>
              {props.rightText}
            </PrestoText>
          ) : null}
          {status ? (
            <SelectedCheckbox checkBoxProps={checkBoxProps} />
          ) : (
            <UnSelectedCheckbox checkBoxProps={checkBoxProps} />
          )}
        </View>
      </FlexRowSpaceAligned>
    </Pressable>
  );
}

const styles = {
  textStyle: {
    fontSize: 14,
    letterSpacing: 0.4,
    paddingBottom: 5,
  },
  descriptionStyle: {
    letterSpacing: 0.4,
  },
  iconStyles: {
    paddingTop: 15,
    paddingBottom: 10,
  },
};
