import * as React from "react";
import Svg, { G, Rect, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <G filter="url(#prefix__filter0_dd)">
        <Rect
          x={0}
          y={0}
          width={24}
          height={24}
          rx={12}
          fill={props.primaryColor || "#1C1649"}
        />
      </G>
      <Path
        d="M12.571 0.571l-6.285 6.286L15.429 16"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Defs></Defs>
    </Svg>
  );
}

export default SvgComponent;
