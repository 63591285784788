import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={21} height={24} viewBox="0 0 21 24" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#000"}>
        <Path
          opacity={0.4}
          d="M21 18c-.005.769-.61 1.5-1.505 1.5H1.503C.61 19.5.003 18.769 0 18a1.465 1.465 0 01.403-1.018c.906-.973 2.6-2.437 2.6-7.232 0-3.642 2.554-6.558 5.998-7.273V1.5a1.5 1.5 0 013 0v.977c3.44.715 5.994 3.63 5.994 7.273 0 4.795 1.694 6.26 2.6 7.232.261.275.406.64.405 1.018z"
        />
        <Path d="M7.5 21h6a3 3 0 01-6 0z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h21v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
