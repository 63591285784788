import _ from "lodash";
import create from "zustand";
import moment from "moment";
import { devtools } from "zustand/middleware";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import asyncify from "../common/Asyncify";

const AsyncCatalogManager = asyncify(CatalogManager);

let createCatalog = (set, get) => ({
  ready: false,
  catalog: {},
  loading: false,
  catalogName: null,
  productCatalog: null,
  productCategories: [],
  serviceCategories: [],
  serviceCatalog: null,
  lastTryTime: moment().subtract(1, "day"),
  error: null,
  init: (catalogName) => {
    set({
      catalogName: catalogName,
      ready: true,
      lastTryTime: moment().subtract(1, "day"),
    });
  },
  fetch: async (force = false) => {
    const { loading: isLoading, lastTryTime } = get();
    if (!force && moment() - moment(lastTryTime) < 60000) {
      return;
    }
    console.log("Catalog Store :: fetch " + get().catalogName);
    if (!isLoading || force) {
      set({ loading: true, lastTryTime: new Date() });
      await fetchCatalog(set, get);
    }
  },
  refresh: () => {
    get().fetch(true);
  },
  get: () => {
    const catalog = get().catalog;
    if (catalog) {
      return catalog;
    } else {
      get().fetch();
      return {};
    }
  },
  setServiceCategories: (data) => {
    set({ serviceCategories: data });
  },
  setProductCategories: (data) => {
    set({ productCategories: data });
  },
  updateServiceCategory: (service) => {
    let serviceCategoriesArry = _.cloneDeep(get().serviceCategories).map(
      (item) => {
        if (service.category_id === item.id) {
          let index = _.findIndex(item.data, (i) => i.name === service.name);
          item.data[index] = service;
          return item;
        } else {
          return item;
        }
      }
    );
    set({ serviceCategories: serviceCategoriesArry });
  },
  updateProductCategory: (product) => {
    let productCategoriesArry = _.cloneDeep(get().productCategories).map(
      (item) => {
        if (product.category_id === item.id) {
          let index = _.findIndex(item.data, (i) => i.name === product.name);
          item.data[index] = product;
          return item;
        } else {
          return item;
        }
      }
    );
    set({ productCategories: productCategoriesArry });
  },
  getAllServices: () => {
    let services = [];
    let serviceCategoriesArry = _.cloneDeep(get().serviceCategories).map(
      (item) => {
        services = _.concat(services, item.data);
      }
    );
    return services;
  },
  getAllProducts: () => {
    let products = [];

    _.forEach(get().productCategories, (item) => {
      products = _.concat(products, item.data);
    });

    return products;
  },
  getServiceCatalog: (mId) => {
    fetchServiceCatalog(set, mId);
  },
  getProductCatalog: (mId) => {
    fetchProductCatalog(set, mId);
  },
  createCommonCatalog: (item) => {
    let params = {
      catalog: {
        reference_id: 1,
        listing_order: 1,
        filter: item.type,
        name: item.name,
        type: item.type,
        description: "",
        pic_url: "",
      },
    };
    set({ loading: true });
    CatalogManager.createCatalog(
      params,
      (response) => {
        set({ loading: false });
      },
      (error) => {
        set({ loading: false });
      }
    );
  },
  getGenericCatalog: async (mId, filter = "services") => {
    set({ loading: true });
    return new Promise((resolve, reject) => {
      CatalogManager.getFilterBasedCatalog(
        { merchant_id: mId, filter: filter },
        (response) => {
          if (response.data.id != undefined) {
            if (filter === "services") {
              set({ catalog: response.data, loading: false });
            } else if (filter === "products") {
              set({ productCatalog: response.data, loading: false });
            }
            resolve(true);
          } else {
            resolve(false);
            set({ loading: false, catalog: null });
          }
        },
        (error) => {
          reject(error);
          set({ loading: false });
        }
      );
    });
  },
  reset: () => {
    set({
      ready: false,
      catalog: {},
      loading: false,
      catalogName: null,
      productCatalog: null,
      serviceCatalog: null,
      serviceCategories: [],
      lastTryTime: moment().subtract(1, "day"),
      error: null,
    });
  },
});
const fetchServiceCatalog = async (set, mId) => {
  const [error, response] = await AsyncCatalogManager.getFilterBasedCatalog({
    filter: "services",
    merchant_id: mId,
  });

  if (!error) {
    set({ serviceCatalog: response.data, loading: false });
  }
};
const fetchProductCatalog = async (set, mId) => {
  const [error, response] = await AsyncCatalogManager.getFilterBasedCatalog({
    filter: "products",
    merchant_id: mId,
  });

  if (!error) {
    set({ productCatalog: response.data, loading: false });
  }
};
const fetchCatalog = async (set, get) => {
  const [error, response] = await AsyncCatalogManager.getFilterBasedCatalog({
    filter: get().catalogName,
  });

  if (!error) {
    set({ catalog: response.data, loading: false });
  }
};

export const useCatalog = create(createCatalog);
