import * as React from "react";

import Svg, { Path } from "react-native-svg";

function CouponIcon(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M33.928 6.251l-.468 14.052-13.835 13.622a2.7 2.7 0 01-3.813-.024l-9.428-9.519a2.7 2.7 0 01.01-3.809L20.197 6.768l13.73-.517zm-1.45 1.455l-11.679.44L7.383 21.563a1.3 1.3 0 00-.098 1.729l.094.105 9.428 9.518a1.3 1.3 0 001.836.012l13.436-13.23.399-11.991zm-8.903 12.528a.8.8 0 01.077 1.042l-.077.09-7.21 7.209a.8.8 0 01-1.208-1.042l.077-.09 7.21-7.209a.8.8 0 011.131 0zm-3.667-3.698a.8.8 0 01.077 1.042l-.077.09-7.21 7.209a.8.8 0 01-1.208-1.042l.077-.09 7.21-7.209a.8.8 0 011.13 0zM28 9.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="#000"
        fillRule="nonzero"
        stroke="#979797"
        strokeWidth={1}
      />
    </Svg>
  );
}

export default CouponIcon;
