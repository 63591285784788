import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import OrderObject from "../../data_models/OrderObject";
import OrderResource from "./OrderResource";
import PrestoSdk from "../../global/PrestoSdk";
import AddressObject from "../../data_models/AddressObject";
import UserObject from "../../data_models/UserObject";
import $ from "../../global/util";

const OrderManager = function (
  Utils,
  ErrorConstants,
  OrderObject,
  OrderResource,
  PrestoSdk,
  AddressObject,
  UserObject
) {
  function placeOrder(order, successCallback, errorCallback) {
    var params = {};
    params.app_id = PrestoSdk.getAppId();
    if (order.merchant_id) {
      params.merchant_id = order.merchant_id;
    } else {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    params.payment_type = order.payment_type;
    params.order = order;
    OrderResource.placeOrder(params).then(
      function (orderResponse) {
        var orderObj = new OrderObject();
        orderObj.buildObject(orderResponse.data);
        orderResponse.data = orderObj;
        orderResponse.message = Utils.getSuccessMessage("order_placed");
        successCallback(orderResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getMyOrders(params, successCallback, errorCallback) {
    if (!params) {
      params = {};
    }
    OrderResource.getMyOrders(params).then(
      function (myOrdersResponse) {
        var rawOrders = myOrdersResponse.data;
        var parsedOrders = [];
        for (var orderId in rawOrders.orders) {
          if (rawOrders.orders.hasOwnProperty(orderId)) {
            var order = new OrderObject();
            order.buildObject(rawOrders.orders[orderId], false);
            parsedOrders.push(order);
          }
        }
        myOrdersResponse.data.orders = parsedOrders;
        successCallback(myOrdersResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelOrder(cancelParams, successCallback, errorCallback) {
    var params = {
      order: cancelParams,
    };
    OrderResource.cancelOrder(params).then(
      function (cancelOrderResponse) {
        successCallback(cancelOrderResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getReturnConfig(params, successCallback, errorCallback) {
    OrderResource.getReturnConfig(params).then(
      function (getReturnConfigResponse) {
        successCallback(getReturnConfigResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getReturnReason(params, successCallback, errorCallback) {
    OrderResource.getReturnReason(params).then(
      function (getReturnReasonResponse) {
        successCallback(getReturnReasonResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createReturn(params, successCallback, errorCallback) {
    OrderResource.createReturn(params).then(
      function (createReturnResponse) {
        successCallback(createReturnResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrderReturns(params, successCallback, errorCallback) {
    OrderResource.getOrderReturns(params).then(
      function (getOrderReturnsResponse) {
        successCallback(getOrderReturnsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrder(params, successCallback, errorCallback) {
    OrderResource.getOrder(params).then(
      function (getOrderResponse) {
        var order = new OrderObject();
        order.buildObject(getOrderResponse.data);
        getOrderResponse.data = order;
        successCallback(getOrderResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function placeAgentOrder(params, successCallback, errorCallback) {
    params.app_id = PrestoSdk.getAppId();
    params.order.app_id = PrestoSdk.getAppId();
    OrderResource.placeAgentOrder(params).then(
      function (placeAgentOrderResponse) {
        successCallback(placeAgentOrderResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAgentOrders(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    OrderResource.getAgentOrders(params).then(
      function (getAgentOrdersResponse) {
        successCallback(getAgentOrdersResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrdersByPhone(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    OrderResource.getOrdersByPhone(params).then(
      function (getOrdersByPhoneResponse) {
        successCallback(getOrdersByPhoneResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrdersByOrderId(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    OrderResource.getOrdersByOrderId(params).then(
      function (getOrdersByOrderIdResponse) {
        successCallback(getOrdersByOrderIdResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrderByFriendlyId(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    OrderResource.getOrderByFriendlyId(params).then(
      function (getOrderByFreindlyIdResponse) {
        successCallback(getOrderByFreindlyIdResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateCCOrdersStatus(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    OrderResource.updateCCOrdersStatus(params).then(
      function (updateOrdersStatusResponse) {
        successCallback(updateOrdersStatusResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateCCOrderItem(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    OrderResource.updateCCOrderItem(params).then(
      function (updateOrdersStatusResponse) {
        successCallback(updateOrdersStatusResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelCCOrder(cancelParams, successCallback, errorCallback) {
    var params = {
      order: cancelParams,
    };
    OrderResource.cancelCCOrder(params).then(
      function (cancelCCOrderResponse) {
        successCallback(cancelCCOrderResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function rejectCCOrder(cancelParams, successCallback, errorCallback) {
    var params = {
      order: cancelParams,
    };
    OrderResource.rejectCCOrder(params).then(
      function (rejectCCOrderResponse) {
        successCallback(rejectCCOrderResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getConfigFile(successCallback, errorCallback) {
    OrderResource.getConfigFile().then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function calculateLoyaltyAmount(params, successCallback, errorCallback) {
    params.app_id = PrestoSdk.getAppId();
    OrderResource.calculateLoyaltyAmount(params).then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function claimOrder(params, successCallback, errorCallback) {
    OrderResource.claimOrder(params).then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function removeItem(params, successCallback, errorCallback) {
    OrderResource.removeItem(params).then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function addItem(params, successCallback, errorCallback) {
    OrderResource.addItem(params).then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }
  function updateItem(params, successCallback, errorCallback) {
    OrderResource.updateItem(params).then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        console.log("error", error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeOtherData(params, successCallback, errorCallback) {
    OrderResource.updateEmployeeOtherData(params).then(
      function (success) {
        successCallback(success);
      },
      function (error) {
        console.log("error", error);
        errorCallback(error);
      }
    );
  }

  return {
    placeOrder: placeOrder,
    getMyOrders: getMyOrders,
    cancelOrder: cancelOrder,
    getReturnConfig: getReturnConfig,
    getReturnReason: getReturnReason,
    createReturn: createReturn,
    getOrderReturns: getOrderReturns,
    getOrder: getOrder,
    placeAgentOrder: placeAgentOrder,
    getAgentOrders: getAgentOrders,
    getOrdersByPhone: getOrdersByPhone,
    getOrdersByOrderId: getOrdersByOrderId,
    updateCCOrdersStatus: updateCCOrdersStatus,
    cancelCCOrder: cancelCCOrder,
    rejectCCOrder: rejectCCOrder,
    getConfigFile: getConfigFile,
    getOrderByFriendlyId: getOrderByFriendlyId,
    calculateLoyaltyAmount: calculateLoyaltyAmount,
    claimOrder: claimOrder,
    updateCCOrderItem: updateCCOrderItem,
    removeItem: removeItem,
    addItem: addItem,
    updateItem: updateItem,
    updateEmployeeOtherData: updateEmployeeOtherData,
  };
};

export default OrderManager(
  Utils,
  ErrorConstants,
  OrderObject,
  OrderResource,
  PrestoSdk,
  AddressObject,
  UserObject
);
