import React, { useContext } from "react";
import { View, StyleSheet, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import { SkypeIndicator } from "react-native-indicators";

export default function LoadingContainer(props) {
  const { theme } = useContext(ThemeContext);

  return (
    <View
      style={[
        styles.container,
        {
          borderRadius: props.borderRadius || 0,
          backgroundColor: props.bgColor || theme.white,
          paddingTop: props.paddingTop || 0,
          position: "relative",
          ...props.style,
        },
      ]}
    >
      {props.children}
      {props.loading ? (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: props.loaderBgColor || "rgba(64,64,64, 0.4)",
              zIndex: 1000,
              elevation: 10000,
              ...props?.loadingContainerStyles,
            },
          ]}
        >
          <SkypeIndicator
            color={props.color || theme.primary}
            maxScale={1.2}
            minScale={0.2}
            size={60}
          />
        </View>
      ) : undefined}
    </View>
  );
}

const styles = {
  container: {
    flex: 1,
    height: "100%",
    zIndex: 11000,
    paddingTop: Platform.OS === "web" ? 15 : 0,
    position: Platform.OS === "web" ? "fixed" : "relative",
    width: Platform.OS === "web" ? "100%" : undefined,
  },
};
