import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="35px"
      viewBox="0 0 40 35"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <LinearGradient
          x1="9.42676324%"
          y1="33.2175047%"
          x2="61.0962304%"
          y2="63.092012%"
          id="a"
        >
          <Stop stopColor="#FFF" stopOpacity={0.24} offset="0%" />
          <Stop stopColor="#FFF" stopOpacity={0.71} offset="100%" />
        </LinearGradient>
      </Defs>
      <G
        transform="translate(-279 -124) translate(280 124)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          stroke="#FFF"
          strokeWidth={2}
          opacity={0.7}
          strokeLinecap="round"
          d="M0 33.1666667L38 33.1666667"
        />
        <Path
          d="M19.392.502l7.15 9.165a.5.5 0 00.7.088l4.252-3.29a.5.5 0 01.806.395l-.002 8.434a.5.5 0 00.83.376l4.043-3.539a.5.5 0 01.829.377v12.037a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5V12.52a.5.5 0 01.832-.374l4.034 3.59a.5.5 0 00.832-.373L5.7 6.87a.5.5 0 01.809-.392l4.162 3.274a.5.5 0 00.701-.083L18.605.5a.5.5 0 01.787.002z"
          fill="url(#a)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
