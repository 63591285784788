import React, { useContext, useReducer, useEffect } from "react";
import { View, FlatList, Dimensions } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import _ from "lodash";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import I18n from "i18n-js";
import SectionHeaderWithRightLink from "@presto-components/SectionHeaders/SectionHeaderWithRightLink";
import utils from "../../utils";
import SeeAllText from "@presto-screen-components/SeeAllText/SeeAllText";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_CATEGORY_ITEMS":
      return { ...state, items: payload };
  }
  return state;
}

export default function OnOfferItems({
  showAll,
  getItemPromotions,
  promotions,
}) {
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = utils.isDesktop();
  const [state, dispatch] = useReducer(reducer, {
    items: [],
  });

  const { favorites, items } = state;
  const getItems = () => {
    const ids = _.map(state.favorites, (fav) => fav.item_id);
    SearchManager.searchCategoryItem(
      { tag: ["bestseller"], catalog_id: catalog?.id },
      (response) => {
        const items = _.map(response.hits.hits, (item) => item._source);
        dispatch({
          type: "SET_CATEGORY_ITEMS",
          payload: _.slice(items, 0, showAll ? undefined : isDesktop ? 6 : 3),
        });
      },
      () => {}
    );
  };

  useEffect(() => {
    getItems();
  }, [catalog]);

  const renderItem = (item) => {
    if (item) {
      if (!isDesktop) {
        return (
          <ItemCard
            key={item._id}
            item={item}
            theme={theme}
            getItemPromotions={getItemPromotions}
            promotions={promotions}
          />
        );
      } else {
        return (
          <View
            style={{ flex: 1, width: 400, marginRight: 15, marginBottom: 15 }}
          >
            <ItemCard key={item._id} item={item} theme={theme} />
          </View>
        );
      }
    } else {
      return null;
    }
  };
  if (items.length === 0) {
    return null;
  }

  if (showAll) {
    let containerStyle = {};
    if (isDesktop) {
      containerStyle = {
        flexFlow: "wrap",
        width: "100%",
        flex: 1,
      };
    }
    return (
      <View
        style={{ paddingVertical: theme.primaryPadding, marginBottom: 130 }}
      >
        <View style={{ paddingHorizontal: theme.primaryPadding }}>
          <SectionHeaderWithRightLink
            leftText={I18n.t("screen_messages.vendors.best_sellers")}
          />
        </View>
        <FlatList
          keyExtractor={(_, index) => "index-" + index}
          data={items}
          contentContainerStyle={{ ...containerStyle }}
          renderItem={({ item }) => renderItem(item)}
        />
      </View>
    );
  }
  return (
    <View style={{ paddingVertical: isDesktop ? 0 : theme.primaryPadding }}>
      <View
        style={{ paddingHorizontal: isDesktop ? 0 : theme.containerPadding }}
      >
        <SeeAllText
          fontSize={isDesktop ? 22 : 18}
          fontWeight={
            isDesktop
              ? "700.medium"
              : theme.bestSellerTitleFontWeightForMobile || "600.medium"
          }
          rightText={I18n.t("screen_messages.common.see_all")}
          leftText={I18n.t("screen_messages.vendors.best_sellers")}
          onPressRightText={() => {
            Navigator.emit({
              nextUrl: `bestsellers`,
              event: "modal",
              params: {
                screenName: "ON_BEST_SELLER_SCREEN",
              },
            });
          }}
        />
      </View>
      {isDesktop ? (
        <FlatList
          keyExtractor={(_, index) => "index-" + index}
          data={items}
          contentContainerStyle={{
            flexFlow: "wrap",
            width: "100%",
            flex: 1,
          }}
          renderItem={({ item }) => renderItem(item)}
        />
      ) : (
        <>
          {_.map(items, (fav) => {
            return renderItem(fav);
          })}
        </>
      )}
    </View>
  );
}
