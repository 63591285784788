import * as React from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";

function CalendarFilledIcon(props) {
  const color = props?.color || "#000";
  const color1 = props?.color1 || "#FFF";
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="Calen1" d="M0 0H29V29H0z" />
      </Defs>
      <G
        transform="translate(5 6)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="Calen2" fill={color1}>
          <Use xlinkHref="#Calen1" />
        </Mask>
        <G mask="url(#Calen2)">
          <G transform="translate(-.003 -.003)">
            <Path
              stroke="none"
              fill="none"
              d="M0.00308998349 0.00308998349H29.00308998349V29.00308998349H0.00308998349z"
            />
            <Path
              d="M23.566 3.628h3.625a1.713 1.713 0 011.812 1.813v21.75a1.713 1.713 0 01-1.812 1.812H1.816a1.713 1.713 0 01-1.813-1.812V5.44a1.713 1.713 0 011.813-1.813H5.44V1.816A1.713 1.713 0 017.253.003a1.713 1.713 0 011.813 1.813v1.812H19.94V1.816a1.813 1.813 0 013.625 0v1.812z"
              stroke="none"
              fill={color}
              fillRule="evenodd"
            />
            <Path
              stroke={color1}
              strokeWidth={1}
              fill={color1}
              fillRule="evenodd"
              d="M13.00309 12.00309H16.00309V15.00309H13.00309z"
            />
            <Path
              stroke={color1}
              strokeWidth={1}
              fill={color1}
              fillRule="evenodd"
              d="M13.00309 19.00309H16.00309V22.00309H13.00309z"
            />
            <Path
              stroke={color1}
              strokeWidth={1}
              fill={color1}
              fillRule="evenodd"
              d="M8.00308998 19.00309H11.00308998V22.00309H8.00308998z"
            />
            <Path
              stroke={color1}
              strokeWidth={1}
              fill={color1}
              fillRule="evenodd"
              d="M18.00309 19.00309H21.00309V22.00309H18.00309z"
            />
            <Path
              stroke={color1}
              strokeWidth={1}
              fill={color1}
              fillRule="evenodd"
              d="M18.00309 12.00309H21.00309V15.00309H18.00309z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default CalendarFilledIcon;
