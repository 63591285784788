import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        fill="#4267B2"
        d="M25.667 12.513c0 6.551-5.03 11.933-11.42 12.446-.352.066-.71.066-1.062.066-.937 0-1.844-.095-2.71-.286C4.862 23.474.668 18.474.668 12.513.667 5.61 6.28.025 13.185.025c6.903 0 12.482 5.585 12.482 12.488"
      />
      <Path
        fill="#FFFFFE"
        d="M15.732 9.506c.513 0 1.128.072 1.707.233l.489-2.62c-1.002-.352-2.029-.453-3.097-.453s-1.903.167-2.583.549c-.68.388-1.128.811-1.384 1.432-.263.537-.388 1.48-.388 2.637v1.032h-2.07v3.001h2.07v9.422c.865.19 1.772.286 2.709.286.352 0 .71 0 1.062-.066v-9.642h2.744v-3h-2.744v-.967c0-.74.101-1.2.358-1.45.19-.227.578-.394 1.127-.394Z"
      />
    </G>
  </Svg>
);
export default SvgComponent;
