import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        stroke={props.color || "#333"}
        strokeWidth={0.9}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M16 0l1.612 2.975.202.027L20.141.545l.798 3.34.132.057L24 2.144l-.096 3.54.015.012 3.395-1.01L26.3 8.097 29.856 8l-1.862 3.031 3.46.828-2.583 2.446L32 16l-3.129 1.694 2.584 2.447-3.461.827L29.856 24l-3.557-.098 1.015 3.412-3.412-1.014.098 3.556-3.032-1.862-.827 3.46-2.447-2.584L16 32l-1.695-3.13-2.446 2.585-.828-3.461L8 29.856l.097-3.557-3.41 1.015L5.7 23.902 2.144 24l1.861-3.032-3.46-.827 2.583-2.447L0 16l3.129-1.695-2.584-2.446 3.46-.828L2.144 8l3.556.097-1.014-3.41 3.396 1.008.014-.011L8 2.144l2.93 1.798c.043-.02.087-.039.13-.057l.799-3.34 2.327 2.457c.067-.01.135-.019.202-.027L16 0z"
          strokeLinejoin="round"
          transform="translate(4 4)"
        />
        <G transform="translate(4 4) translate(11.068 10.863)">
          <Path
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(45 4.932 4.932)"
            d="M4.93161653 -1.63248604L4.93161653 11.4957191"
          />
          <Circle
            fill={props.color || "#333"}
            cx={1.64956524}
            cy={1.64956524}
            r={1}
          />
          <Circle
            fill={props.color || "#333"}
            cx={7.8034114}
            cy={8.21366781}
            r={1}
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
