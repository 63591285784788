import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import StylesContext from "@presto-contexts/StylesContext";
import UserContext from "@presto-contexts/UserContext";
import TopHeaderWithTitle from "@presto-components/Headers/TopHeaderWithTitle";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import utils from "../../utils/index";
import ModalContext from "@presto-contexts/ModalContext";

export default function TopHeaderWithTitleComponent({
  scene,
  previous,
  navigation,
  title,
  goBack = true,
  onPressBack,
  closeModal,
}) {
  const { popRightModal } = useContext(ModalContext);
  const { Navigator } = useContext(NavigatorContext);
  const isDesktop = utils.isDesktop();
  const isNextJsApp = utils.isNextJsApp();

  const onSearch = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SEARCH",
      },
    });
  };

  const onGoBack = () => {
    if (closeModal) {
      popRightModal();
      return;
    }
    if (onPressBack) {
      onPressBack();
    } else {
      let inSideNavigation;
      if (isDesktop && isNextJsApp) {
        inSideNavigation = true;
      }
      Navigator.emit({
        event: "goBack",
        params: {
          inSideNavigation: inSideNavigation,
        },
      });
    }
  };

  const { theme } = useContext(ThemeContext);
  const { appStyles } = useContext(StylesContext);
  const { user, authState, currentAddress } = useContext(UserContext);

  return (
    <TopHeaderWithTitle
      scene={scene}
      previous={previous}
      navigation={navigation}
      theme={theme}
      appStyles={appStyles}
      user={user}
      authState={authState}
      title={title}
      CurrentAddress={currentAddress || null}
      onSearch={() => onSearch()}
      onPressLeftButton={() => onGoBack()}
      shouldGoBack={goBack}
    />
  );
}
