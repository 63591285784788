import Roboto_Bold from "./fonts/Roboto-Bold.ttf";
import Roboto_Light from "./fonts/Roboto-Light.ttf";
import Roboto_Medium from "./fonts/Roboto-Medium.ttf";
import Roboto_Regular from "./fonts/Roboto-Regular.ttf";

export default {
  "Roboto-Bold": Roboto_Bold,
  "Roboto-Light": Roboto_Light,
  "Roboto-Medium": Roboto_Medium,
  "Roboto-Regular": Roboto_Regular
};
