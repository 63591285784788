import React, { useState, useContext, useCallback } from "react";
import {
  View,
  FlatList,
  TouchableWithoutFeedback,
  ImageBackground,
  Platform,
  Pressable,
} from "react-native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import { Dimensions } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import { WebView } from "react-native-webview";

export default function VideoViewCard({
  navigation,
  title,
  name,
  slidesToShow,
  borderRadius,
  width,
  height,
  onItemClicked,
  fontStyle,
  fontSize,
  description,
  numColumns,
}) {
  const { theme } = useContext(ThemeContext);
  const [gallery, setGallery] = useState([]);
  const windowWidth = Dimensions.get("window").width;
  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response?.data?.media_items);
  };
  console.log("gallery->", gallery);
  useFocusEffect(
    useCallback(() => {
      getGallery(name);
    }, [])
  );

  let isDesktop = windowWidth > 940 ? true : false;
  const renderItem = ({ item, index }) => {
    return (
      <>
        {item.name ? (
          <View
            style={{
              paddingBottom: 30,
            }}
          >
            <PrestoText
              fontStyle="400.medium"
              color={theme.title}
              size={22}
              extraStyle={{ textAlign: "center", textTransform: "uppercase" }}
            >
              {item.name}
            </PrestoText>
          </View>
        ) : null}
        <iframe
          src={item.target?.id_value}
          height={500}
          width={isDesktop ? windowWidth - 180 : windowWidth - 30}
        />
      </>
    );
  };

  return (
    <View style={{}}>
      <FlatList
        keyExtractor={(_, index) => "index-" + index}
        data={gallery}
        renderItem={renderItem}
        layout={"default"}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
        style={{
          overflow: "visible",
        }}
        numColumns={numColumns}
      />
    </View>
  );
}
