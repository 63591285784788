import CouponResource from "./CouponResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const CouponManager = function (
  CouponResource,
  Utils,
  ErrorConstants,
  PrestoSdk
) {
  function isValidCoupon(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.coupon_code)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.COUPON_CODE_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      CouponResource.isValidCoupon(params).then(
        function (couponResponse) {
          successCallback(couponResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function applyCoupon(appointmentObject, successCallback, errorCallback) {
    if (Utils.isEmpty(appointmentObject.coupon_code)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.COUPON_CODE_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else if (
      !appointmentObject.service_items ||
      appointmentObject.service_items.length == 0
    ) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.NO_SERVICE_ITEMS,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      var params = {
        appointment: appointmentObject,
      };
      CouponResource.applyCoupon(params).then(
        function (couponResponse) {
          successCallback(couponResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function applyCouponOnOrder(orderObject, successCallback, errorCallback) {
    if (Utils.isEmpty(orderObject.coupon_code)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.COUPON_CODE_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else if (!orderObject.line_items || orderObject.line_items.length == 0) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.NO_CATEGORY_ITEMS,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      var params = {
        app_id: PrestoSdk.getAppId(),
        merchant_id: PrestoSdk.getMerchantId(),
        order: orderObject,
        payment_type: orderObject.payment_type,
      };
      CouponResource.applyCouponOnOrder(params).then(
        function (couponResponse) {
          successCallback(couponResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function applyCouponOnSubscription(
    subscriptionObject,
    successCallback,
    errorCallback
  ) {
    if (Utils.isEmpty(subscriptionObject.coupon_code)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.COUPON_CODE_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else if (
      !subscriptionObject.line_items ||
      subscriptionObject.line_items.length == 0
    ) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.NO_CATEGORY_ITEMS,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      var params = {
        app_id: PrestoSdk.getAppId(),
        merchant_id: PrestoSdk.getMerchantId(),
        subscription: subscriptionObject,
        payment_type: subscriptionObject.payment_type,
      };
      CouponResource.applyCouponOnSubscription(params).then(
        function (couponResponse) {
          successCallback(couponResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getMyCoupons(successCallback, errorCallback, params) {
    CouponResource.getMyCoupons(params).then(
      function (couponResponse) {
        successCallback(couponResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  return {
    isValidCoupon: isValidCoupon,
    applyCoupon: applyCoupon,
    applyCouponOnOrder: applyCouponOnOrder,
    applyCouponOnSubscription: applyCouponOnSubscription,
    getMyCoupons: getMyCoupons,
  };
};

export default CouponManager(CouponResource, Utils, ErrorConstants, PrestoSdk);
