import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={23} height={18} viewBox="0 0 23 18" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#1C1649"}>
        <Path
          opacity={0.4}
          d="M6.051 3.56a10.95 10.95 0 015.199-1.31c4.322 0 8.096 2.517 10.003 6.237a1.136 1.136 0 010 1.026 11.693 11.693 0 01-2.848 3.595l-2.56-1.979a5.063 5.063 0 00-7.811-6.037L6.05 3.56zm8.402 6.495a3.341 3.341 0 00-4.094-4.296 1.678 1.678 0 01.289 1.355l3.805 2.94zm-3.203 4.008c-2.667 0-4.825-2.065-5.024-4.68l-3.688-2.85c-.485.608-.93 1.25-1.29 1.954a1.137 1.137 0 000 1.026c1.906 3.72 5.68 6.237 10.002 6.237.946 0 1.859-.14 2.738-.368l-1.824-1.411c-.301.058-.607.089-.914.091z"
        />
        <Path d="M22.382 16.895l-.69.888a.563.563 0 01-.79.098L.217 1.895a.563.563 0 01-.099-.79l.69-.888a.563.563 0 01.79-.098l20.685 15.986a.563.563 0 01.099.79z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill={props.secondaryColor || "#FFF"} d="M0 0h22.5v18H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
