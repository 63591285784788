import Utils from "../common/Utils";
import AddressObject from "./AddressObject";
import ErrorConstants from "../common/ErrorConstants";
import PrestoSdk from "../global/PrestoSdk";
import UserObject from "./UserObject";
import ServiceItemObject from "./ServiceItemObject";
import $ from "../global/util";

const AppointmentObject = function (
  Utils,
  AddressObject,
  ErrorConstants,
  PrestoSdk,
  UserObject,
  ServiceItemObject
) {
  function AppointmentObject() {
    this.merchant_id = PrestoSdk.getMerchantId();
    this.time_slot = "";
    this.date = new Date();
    this.services = [];
    this.gross_amount = 0;
    this.no_of_items = 0;
    this.quantity = 0;
    this.tax_amount = 0;
    this.bill_amount = 0;
    this.discount = 0;
    this.total_amount = 0;
    this.coupon_code = "";
    this.coupon_title = "";
    this.other_data = [];
    this.outlet_name = "";
    this.id = "";
    this.friendly_id = "";
    this.special_instructions = "";
    this.status = "";
    this.sub_status = "";
    this.operator = {};
    this.address = new AddressObject();
    this.chosen_services = [];
    this.user = new UserObject();
    this.service_items = [];
    this.source = "";
    this.resource_hash = "";
    this.payment_type = "";
    this.payment_status = "";
    this.booking_start_time = "";
    this.booking_end_time = "";
    this.customer_feedback_rating = "";
    this.outlet = null;
    this.recurring_appointment_id = "";
    this.completion_otp = "";
    this.start_otp = "";
    this.created_at = "";
    this.request_time = "";
    this.total_price_amount = "";
    this.current_payment_id = "";
    this.payments = "";
    this.total_taxes_amount = "";
    this.sub_total_price = 0;
    this.booked_at = "";
    this.started_at = "";
    this.completed_at = "";
    this.cancelled_at = "";
    this.total_discounts_amount = "";
    this.order_id = "";
    this.cancel_reason = "";
    this.invoice_number = "";
    this.balance_amount = 0;
  }

  AppointmentObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      if (Utils.isEmpty(this.address.toString())) {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.ADDRESS_EMPTY,
          false
        );
      }
      return error;
    },
    buildObject: function (rawAppointmentObj) {
      this.merchant_id = rawAppointmentObj.merchant_id;
      this.time_slot = rawAppointmentObj.time_slot;
      this.date = new Date(rawAppointmentObj.date);
      if (rawAppointmentObj.services && rawAppointmentObj.services.length > 0) {
        this.services = rawAppointmentObj.services;
      } else {
        this.services = [];
        this.services.push("No Services Chosen");
      }
      this.gross_amount = rawAppointmentObj.gross_amount;
      this.no_of_items = rawAppointmentObj.no_of_items;
      this.quantity = rawAppointmentObj.quantity;
      this.tax_amount = rawAppointmentObj.tax_amount;
      this.bill_amount = rawAppointmentObj.bill_amount;
      this.discount = rawAppointmentObj.discount;
      this.total_amount = rawAppointmentObj.total_amount;
      this.coupon_code = rawAppointmentObj.coupon_code;
      this.coupon_title = rawAppointmentObj.coupon_title;
      this.other_data = rawAppointmentObj.other_data;
      this.outlet_name = rawAppointmentObj.outlet_name;
      this.id = rawAppointmentObj.id;
      this.friendly_id = rawAppointmentObj.friendly_id;
      this.special_instructions = rawAppointmentObj.special_instructions;
      this.status = rawAppointmentObj.status;
      this.sub_status = rawAppointmentObj.sub_status;
      this.operator = new UserObject();
      this.address = new AddressObject();
      this.address =
        rawAppointmentObj.address_object || rawAppointmentObj.address;
      //        this.address.line1 = rawAppointmentObj.address;
      this.user = new UserObject();
      if (rawAppointmentObj.user_hash) {
        rawAppointmentObj.user_hash.phone_number =
          rawAppointmentObj.user_hash.phone;
        this.user.buildObject(rawAppointmentObj.user_hash);
      } else if (rawAppointmentObj.user) {
        this.user.buildObject(rawAppointmentObj.user);
      }
      this.service_items = rawAppointmentObj.service_items;
      this.operator = rawAppointmentObj.operator;
      this.source = rawAppointmentObj.source;
      this.resource_hash = rawAppointmentObj.resource_hash;
      this.payment_type = rawAppointmentObj.payment_type;
      this.payment_status = rawAppointmentObj.payment_status;
      this.booking_start_time = rawAppointmentObj.booking_start_time;
      this.booking_end_time = rawAppointmentObj.booking_end_time;
      this.customer_feedback_rating =
        rawAppointmentObj.customer_feedback_rating;
      this.outlet = rawAppointmentObj.outlet;
      this.location = rawAppointmentObj.location;
      this.recurring_appointment_id =
        rawAppointmentObj.recurring_appointment_id;
      this.completion_otp = rawAppointmentObj.completion_otp;
      this.start_otp = rawAppointmentObj.start_otp;
      this.created_at = rawAppointmentObj.created_at;
      this.request_time = rawAppointmentObj.request_time
        ? rawAppointmentObj.request_time
        : "";
      this.work_duration = rawAppointmentObj.work_duration;
      this.total_price_amount = rawAppointmentObj.total_price_amount;
      this.current_payment_id = rawAppointmentObj.current_payment_id;
      this.payments = rawAppointmentObj.payments;
      this.total_taxes_amount = rawAppointmentObj.total_taxes_amount;
      this.sub_total_price = rawAppointmentObj.sub_total_price;
      this.booked_at = rawAppointmentObj.booked_at;
      this.started_at = rawAppointmentObj.started_at;
      this.completed_at = rawAppointmentObj.completed_at;
      this.cancelled_at = rawAppointmentObj.cancelled_at;
      this.total_discounts_amount = rawAppointmentObj.total_discounts_amount;
      this.order_id = rawAppointmentObj.order_id;
      this.cancel_reason = rawAppointmentObj.cancel_reason;
      this.invoice_number = rawAppointmentObj?.invoice_number;
      this.balance_amount = rawAppointmentObj?.balance_amount;
    },
  };

  return AppointmentObject;
};

export default AppointmentObject(
  Utils,
  AddressObject,
  ErrorConstants,
  PrestoSdk,
  UserObject,
  ServiceItemObject
);
