import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(9 9)"
        stroke="#000"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M7.667 23H2.556A2.556 2.556 0 010 20.444V2.556A2.556 2.556 0 012.556 0h5.11" />
        <Path d="M16.6111111 17.8888889L23 11.5 16.6111111 5.11111111" />
        <Path d="M23 11.5L7.66666667 11.5" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
