import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M4.143 0c-1.12 0-2.132 1.065-3.027 2.006-.181.19-.356.374-.528.546-.859.858-.77 3.115.223 5.75C2.04 11.56 4.48 15.117 7.682 18.32c3.203 3.202 6.759 5.642 10.014 6.87 2.635.995 4.893 1.081 5.752.221.171-.17.354-.345.541-.523.964-.916 2.056-1.953 2.01-3.104-.033-.79-.609-1.64-1.712-2.527-3.3-2.651-4.222-1.713-5.752-.165l-.3.303c-.729.732-1.916.572-3.53-.478-1.116-.726-2.498-1.91-4.106-3.517-3.967-3.967-5.236-6.393-3.994-7.636l.3-.296c1.554-1.53 2.491-2.454-.161-5.754C5.857.61 5.007.036 4.216.002L4.143 0z"
        transform="translate(7 7)"
        fillRule="nonzero"
        stroke={props.stroke || "#333"}
        strokeWidth={1.5}
        fill="none"
      />
    </Svg>
  );
}

export default SvgComponent;
