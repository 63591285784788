import React, { useContext } from "react";
import Svg, { G, TSpan, Text, Circle } from "react-native-svg";

function SvgComponent({
  width,
  height,
  fillColor = "#CCCCCC",
  color = "#7E5C5B",
}) {
  return (
    <Svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/logo-upload-with-circle">
          <Circle
            id="Oval"
            fill-opacity="0.5"
            fill={fillColor}
            cx="20"
            cy="20"
            r="20"
          ></Circle>
          <Text
            id="LOGO"
            font-size={10}
            font-weight="500"
            fill={color}
          >
            <TSpan x="5" y="23">
              LOGO
            </TSpan>
          </Text>
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
