import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(-745 -564) translate(109 210) translate(181 341) translate(442) translate(13 13)"
        stroke="#686868"
        strokeWidth={1.3}
        fill="none"
        fillRule="evenodd"
      >
        <Circle cx={14} cy={14} r={8.35} />
        <Path d="M14 .65c.524 0 .838.46 1.18.834.487.532.995 1.03 1.643 1.208.622.17 1.404.03 2.187-.144.606-.135 1.239-.36 1.68-.103.303.175.416.541.507.93l.066.295.034.145c.188.751.412 1.479.87 1.927.499.489 1.23.736 1.971.956.59.175 1.239.289 1.524.799.143.256.076.583-.016.92l-.08.29c-.328 1.214-.42 1.85-.277 2.37.177.643.673 1.166 1.202 1.671l.216.2c.327.3.643.611.643 1.052 0 .564-.485.961-.888 1.379-.513.531-.996 1.076-1.172 1.708-.172.616-.018 1.349.174 2.08.135.513.362 1.05.13 1.455-.269.47-.902.562-1.47.72-.749.206-1.48.443-1.96.917-.47.466-.7 1.193-.896 1.938-.143.542-.216 1.153-.661 1.406-.468.267-1.036.029-1.59-.124-.741-.204-1.492-.378-2.176-.212-.518.127-1.002.521-1.469 1.002-.586.612-.921 1.081-1.372 1.081-.604 0-.943-.46-1.312-.852-.482-.514-.987-1.01-1.676-1.22-.551-.17-1.33-.03-2.119.156-.56.132-1.156.367-1.526.155-.472-.271-.59-.887-.765-1.447-.224-.712-.473-1.41-.938-1.878-.446-.45-1.136-.67-1.846-.858-.502-.133-1.068-.2-1.31-.61-.226-.38-.16-.826-.07-1.282l.068-.342c.133-.706.244-1.421.07-2.077-.157-.596-.603-1.147-1.08-1.689C1.113 14.968.65 14.543.65 14c0-.466.409-.781.762-1.11.867-.81 1.245-1.28 1.39-1.815.177-.652.014-1.431-.185-2.208-.148-.578-.391-1.18-.128-1.627.267-.453.811-.516 1.307-.642.685-.173 1.363-.376 1.858-.852.44-.425.714-1.135.962-1.87.194-.573.331-1.216.768-1.466.462-.264 1.02-.09 1.567.017.69.137 1.39.25 2.032.085.546-.14 1.055-.524 1.55-.952.647-.553 1.014-.91 1.467-.91z" />
        <Path strokeLinecap="round" d="M10 13.4093827L12.6944963 16 18 11" />
      </G>
    </Svg>
  )
}

export default SvgComponent
