import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={ props.width || 450}
      height={56}
      viewBox="0 0 450 56"
      {...props}
    >
      <Path
        d="M426 0c13.255 0 24 10.745 24 24v32H0V24C0 10.745 10.745 0 24 0h166c0 19.882 15.67 36 35 36s35-16.118 35-36h166z"
        fill="#fff"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default SvgComponent
