import React, { useContext } from "react";
import { Text } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";

export default function PrestoText(props) {
  const { theme } = useContext(ThemeContext);
  const fs = Array.isArray(props?.extraStyle)
    ? props?.extraStyle
    : [props?.extraStyle];

  const getFontObj = () => {
    return _.get(theme, props?.fontStyle);
  };

  const fontObj = getFontObj();

  return (
    <Text
      {...props}
      style={[
        {
          fontWeight: props?.weight || fontObj?.fontWeight || "normal",
          fontFamily: fontObj?.fontFamily || theme.primaryFont,
          fontSize: props?.size || fontObj?.fontSize,
          color: props?.color || theme.darkText,
          includeFontPadding: false,
        },
        ...fs,
      ]}
    >
      {props.children}
    </Text>
  );
}
