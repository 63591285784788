const themes = (svgs, images) => {
  return {
    name: "light",

    get linkDividerColor() {
      return "#CCCCCC";
    },

    get textFitButtonTextSize() {
      return 14;
    },
  };
};
export default themes;
