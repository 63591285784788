import React, {
  useState,
  useContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import { View, ScrollView, Image, Platform } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import UserContext from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import useFocusEffect from "@presto-common/useFocusEffect";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import CartReview from "@presto-components/CartReview/CartReview";
import SearchManager from "@presto-services/features/search/SearchManager";
import AddressCard from "@presto-cards/AddressCard/AddressCard";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import asyncify from "../../common/Asyncify";
import MerchantManager from "@presto-services/features/merchant/MerchantManager";
import utils from "../../utils";
const AsyncMerchantManager = asyncify(MerchantManager);
import { alertBox, prestoAlert } from "@presto-common/Alert";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import { useMediaQuery } from "react-responsive";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import AddressCardModel from "@presto-component-models/AddressCardModel";
import { useAssets } from "presto-react-components";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import config from "@presto-common/config";
import {
  useGlobalStateStore,
  GlobalStoreKeys,
} from "@presto-stores/GlobalStateStore";
import CouponApply from "@presto-screen-components/CouponApply/CouponApply";
import DynamicPriceManager from "@presto-services/common/DynamicPriceManager";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
    case "SET_MINIMUM_AMOUNT":
      return { ...state, minimum_amount: payload };
    case "SET_ITEMS":
      return { ...state, items: payload };
  }
  return state;
}

export default function Cart({ route }) {
  const { svgs, images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { cart, clearCart, removeCouponFromCart, validateCart } = useContext(
    CartContext
  );
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const globalStateStore = useGlobalStateStore();
  const [loading, setLoading] = useState(false);
  const [itemPromotions, setItemPromotions] = useState(null);
  const BrowseFromCart = config.constants.browse_from_cart;
  const { setPaneState } = useContext(RightPaneContext);
  const isDesktop = useMediaQuery({ minWidth: 941 });
  const [customer, setCustomer] = useState(null);
  const userTypeEmployee = config?.user_type === "employee";
  const [currentAddress, setCurrentAddress] = useState(
    _.first(userTypeEmployee ? customer?.addresses : user?.addresses || [])
  );
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const isDynamicPricingEnabled =
    config.merchant_config.features.dynamic_pricing;
  const { items } = state;

  useEffect(() => {
    setCustomer(globalStateStore.getKey(GlobalStoreKeys.customer, "customer"));
  }, [globalStateStore]);

  useEffect(() => {
    if (userTypeEmployee) {
      setCurrentAddress(_.first(customer?.addresses));
    }
  }, [customer]);

  function AddressButton({ address, currentAddress }) {
    return _.isArray(address)
      ? address.map((item, i) => (
          <Pressable key={i} onPress={() => setCurrentAddress({ ...item })}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                backgroundColor: "transparent",
              }}
            >
              <View style={{ flex: 1 }}>
                <AddressCard
                  boldLine1
                  address={new AddressCardModel({ address: item })}
                  selected={item.id === currentAddress?.id}
                  radioButton
                />
              </View>
            </View>
          </Pressable>
        ))
      : null;
  }

  const onPressClearCart = () => {
    prestoAlert(
      "Do you want to Clear Cart?",
      `You have ${cart.items.length} ${
        cart.items.length > 1 ? "Items" : "Item"
      } added in the cart.
Are you sure you want to clear cart?`,
      [
        {
          text: "No",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "Yes",
          onPress: () => {
            clearCart();
          },
        },
      ],
      false
    );
  };

  function webClereCart() {
    const confirmBox = window.confirm(
      `Do you want to Clear Cart?\n\nYou have ${cart.items.length} ${
        cart.items.length > 1 ? "Items" : "Item"
      } added in the cart. \nAre you sure you want to clear cart?`
    );
    if (confirmBox === true) {
      clearCart();
    }
  }

  const renderCartItem = (lineItem, hideBottomSection) => {
    const item = items[lineItem.item_id];
    const variation = lineItem.variation;

    if (item) {
      item.issues = lineItem.issues;
      return (
        <ItemCard
          key={lineItem.id}
          item={item}
          lineItem={lineItem}
          variation={variation}
          hideBottomSection={hideBottomSection}
          getItemPromotions={getItemPromotions}
          promotions={itemPromotions}
          theme={theme}
        />
      );
    } else {
      return null;
    }
  };

  const onValidateCart = () => {
    validateCart();
  };

  useEffect(() => {
    getMerchantConfig();
    onValidateCart();
  }, []);

  const getMerchantConfig = async () => {
    let params = {
      merchant_id: cart?.merchant_id,
    };
    const [error, response] = await AsyncMerchantManager.getMerchantConfig(
      params
    );
    if (!error) {
      if (response.data.min_bill) {
        dispatch({
          type: "SET_MINIMUM_AMOUNT",
          payload: response.data.min_bill.minimum_bill_amount,
        });
      }
    }
  };

  const getCategoryItem = (id) => {
    SearchManager.searchCategoryItem(
      { id },
      (response) => {
        if (response.hits.hits.length > 0) {
          dispatch({
            type: "SET_CATEGORY_ITEM",
            payload: response.hits.hits[0]._source,
          });
        }
      },
      () => {}
    );
  };

  const getItemPromotions = () => {
    ServerCartManager.getProbableItemPromotions(
      {},
      (resp) => {
        console.log("resp :>> ", JSON.stringify(resp));
        setItemPromotions(resp);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  useFocusEffect(
    useCallback(() => {
      getItemPromotions();
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (isDynamicPricingEnabled) {
        getItemsWithDynamicPrices();
      } else {
        _.forEach(cart?.items, (lineItem) => {
          getCategoryItem(lineItem.item_id);
        });
      }
    }, [cart])
  );

  const getItemsWithDynamicPrices = async () => {
    let array = cart.items;
    const dynamicPriceManager = new DynamicPriceManager();
    await dynamicPriceManager.getDynamicPricesForCartItems(array, setCartItems);
  };

  const setCartItems = (array) => {
    dispatch({
      type: "SET_ITEMS",
      payload: array,
    });
  };

  const onBrowse = () => {
    if (isDesktop) {
      setPaneState(false);
    } else if (BrowseFromCart) {
      Navigator.emit({ event: "goBack" });
      Navigator.emit({
        event: "switchTab",
        params: {
          tabName: "CATEGORIES",
          pop: true,
        },
      });
    } else {
      Navigator.emit({ event: "goBack" });
    }
  };

  const renderEmptyCart = () => {
    return (
      <View style={{ flex: 1 }}>
        <VerticalSpacing size={15} />
        <FullWidthHorizontalPaddedBox>
          <VerticalSpacing size={15} />
          <Image
            source={images.EmptyCart}
            style={{ height: 180, width: 210, alignSelf: "center" }}
          />
          <VerticalSpacing size={35} />
          <PrestoText
            fontStyle={"500.normal"}
            size={16}
            color={theme.darkText}
            extraStyle={{ textAlign: "center" }}
          >
            {I18n.t("screen_messages.cart.cart_empty_description")}
          </PrestoText>
        </FullWidthHorizontalPaddedBox>
        <View
          style={{
            width: "100%",
            height: utils.isDesktop() ? 60 : 120,
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        >
          <FullWidthHorizontalPaddedBox>
            <PrestoSolidButton
              size="large"
              buttonStyle="primary"
              titleColor={theme.white}
              title={I18n.t("screen_messages.cart.start_shopping")}
              onPress={onBrowse}
            />
          </FullWidthHorizontalPaddedBox>
        </View>
      </View>
    );
  };

  const validateOnCheckout = () => {
    let isValid = true,
      error = null;

    if (_.isEmpty(currentAddress)) {
      error = I18n.t("error_messages.cart.please_choose_delivery_addresss");
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const gotoCheckout = () => {
    const { isValid, error } = validateOnCheckout();

    if (!isValid) {
      return alertBox("", error);
    }

    Navigator.emit({
      event: "modal",
      params: {
        screenName: "CART_PAYMENT",
        screenParams: {
          address: currentAddress,
        },
      },
    });
  };

  const openCouponApply = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "COUPON_APPLY",
        screenParams: {},
      },
    });
  };

  const renderCartReview = () => {
    return (
      <View style={{ width: "100%", flex: 1 }}>
        <ScrollView
          style={{ width: "100%", flex: 1 }}
          contentContainerStyle={{
            paddingTop: 20,
            paddingBottom: utils.isDesktop() ? 100 : 150,
          }}
        >
          <VerticalSpacing size={10} />
          {state.minimum_amount ? (
            <>
              <View style={{ paddingHorizontal: theme.containerPadding }}>
                <FlexRowSpaceAligned>
                  <>
                    <PrestoText
                      fontStyle={"500.medium"}
                      size={16}
                      color={theme.darkText}
                    >
                      {I18n.t("screen_messages.cart.minimum_bill_amount")}
                    </PrestoText>
                    <PrestoText color={theme.secondary}>
                      {utils.formattedPrice(state.minimum_amount)}
                    </PrestoText>
                  </>
                </FlexRowSpaceAligned>
                <VerticalSpacing size={10} />
              </View>
              <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
              <VerticalSpacing size={30} />
            </>
          ) : null}
          <FullWidthHorizontalPaddedBox>
            <CartReview order={cart} />
          </FullWidthHorizontalPaddedBox>
          <VerticalSpacing size={5} />
          <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
          <VerticalSpacing size={30} />
          <PrestoText
            fontStyle={"semibold"}
            size={16}
            color={theme.darkText}
            extraStyle={{ marginLeft: theme.containerPadding }}
          >
            {I18n.t("screen_messages.orders.item_details")}
          </PrestoText>
          <VerticalSpacing size={10} />
          <View style={{ paddingHorizontal: isDesktop ? 20 : 0 }}>
            {_.map(cart.items, (item) => {
              return renderCartItem(item);
            })}
          </View>
          <VerticalSpacing size={40} />
          <FullWidthHorizontalPaddedBox>
            <PrestoText fontStyle={"semibold"} size={16} color={theme.darkText}>
              {I18n.t("screen_messages.coupons.coupons")}
            </PrestoText>
          </FullWidthHorizontalPaddedBox>
          <VerticalSpacing size={20} />
          <CouponApply
            cart={cart}
            openCouponApply={openCouponApply}
            removeCouponFromCart={removeCouponFromCart}
          />
          <VerticalSpacing size={20} />
          <FullWidthHorizontalPaddedBox>
            <PrestoText fontStyle={"semibold"} size={16} color={theme.darkText}>
              {"Choose Delivery Address"}
            </PrestoText>
          </FullWidthHorizontalPaddedBox>
          {console.log("currentAddress here ple", currentAddress)}
          <AddressButton
            address={userTypeEmployee ? customer?.addresses : user.addresses}
            currentAddress={currentAddress}
          />
          <VerticalSpacing size={30} />

          {cart?.invalid_line_items[0] ? (
            <>
              <PrestoText
                fontStyle={"semibold"}
                size={isDesktop ? theme.h1FontSize : 18}
                color={theme.darkText}
                extraStyle={{ marginLeft: theme.containerPadding }}
              >
                {I18n.t("screen_messages.cart.items_removed_from_cart")}
              </PrestoText>
              <VerticalSpacing size={10} />
              {_.map(cart.invalid_line_items, (item) => {
                return renderCartItem(item, true);
              })}
            </>
          ) : null}
        </ScrollView>
        <View
          style={{
            width: "100%",
            borderColor: "lightgrey",
            borderTopWidth: 1,
            height: utils.isDesktop() ? 100 : 150,
            paddingBottom: 20,
            position: "absolute",
            bottom: 0,
            left: 0,
            paddingHorizontal: theme.primaryPadding,
            flexDirection: "row",
            alignItems: "top",
            paddingTop: 20,
            justifyContent: "space-between",
            backgroundColor: theme.appBackgroundColor,
          }}
        >
          <View style={{ width: "46%" }}>
            <PrestoSolidButton
              size="extra-large"
              title={I18n.t("screen_messages.cart.clear_cart")}
              onPress={() =>
                Platform.OS === "web" ? webClereCart() : onPressClearCart()
              }
              buttonStyle="tertiary"
              titleColor={theme.primaryButtonText}
              bgColor="#CCCCCC"
            />
          </View>
          <View style={{ width: "52%" }}>
            <PrestoSolidButton
              size="extra-large"
              title={I18n.t("screen_messages.cart.go_to_payment")}
              buttonStyle="primary"
              titleColor={theme.primaryButtonText}
              onPress={gotoCheckout}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <LoadingContainer loading={loading}>
      {cart != null && !_.isEmpty(cart.items)
        ? renderCartReview()
        : renderEmptyCart()}
    </LoadingContainer>
  );
}
