import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M1.126 0a1.11 1.11 0 00-.975.556c-.201.348-.201.78 0 1.129.202.349.575.56.975.555h2.087c.25 0 .46.159.531.403l4.733 16.226a3.342 3.342 0 003.195 2.411h12.19c1.497 0 2.82-1.02 3.214-2.476l2.885-10.669a1.128 1.128 0 00-.189-.974 1.107 1.107 0 00-.883-.44H7.25L5.876 2.01h-.002A2.787 2.787 0 003.213 0H1.126zm11.106 23.52a2.23 2.23 0 00-2.222 2.24A2.23 2.23 0 0012.232 28a2.23 2.23 0 002.22-2.24 2.23 2.23 0 00-2.22-2.24zm11.105 0a2.23 2.23 0 00-2.221 2.24 2.23 2.23 0 002.22 2.24 2.23 2.23 0 002.222-2.24 2.23 2.23 0 00-2.221-2.24z"
        transform="translate(5 6)"
        fill={props.color || "#F2749B"}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={props.strokeWidth || 1}
      />
    </Svg>
  );
}

export default SvgComponent;
