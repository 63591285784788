import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="25px"
      height="25px"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#fff"
      {...props}
    >
      <Path d="M7.5 12L0 4h15l-7.5 8z" fill={props.fillColor ||"#c7c2c2"} />
    </Svg>
  )
}

export default SvgComponent
