import React from "react";
import * as Svg from "react-native-svg";

export default function VerticalDashedLine(props) {
  const {
    height = 2,
    width = 500,
    color = "#B6B6B6",
    x1 = 20,
    x2 = width - x1,
  } = props || {};
  return (
    <Svg.Svg height={height} width={width} style={{ alignSelf: "center" }}>
      <Svg.Line
        stroke={color}
        strokeWidth={width}
        strokeDasharray="8, 5"
        x1={x1}
        y1="0"
        x2={x2}
        y2={height}
      />
    </Svg.Svg>
  );
}
