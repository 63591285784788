import React, { useContext, useState, useCallback, useRef } from "react";
import { View, Alert, SectionList } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import I18n from "i18n-js";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import SessionManager from "@presto-services/features/session/SessionManager";
import NotificationCard from "@presto-components/Notificactions/NotificationCard";
import UserContext from "@presto-contexts/UserContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import NotificationsHelper, {
  NOTIFICATIONS_OPENED_TIME_KEY,
} from "../../helpers/NotificationsHelper";
import PrestoText from "@presto-components/PrestoText";
import { useFocusEffect } from "@react-navigation/native";

export default function NotificationsScreen() {
  const sectionListRef = useRef();
  const { user, setUser } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const [loading, setloading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [sections, setSections] = useState([
    {
      type: "UNREAD",
      data: [],
    },
    {
      type: "READ",
      data: [],
    },
  ]);

  useFocusEffect(
    useCallback(() => {
      setloading(true);
      try {
        NotificationsHelper.fetchNotifications().then((notifications) => {
          setNotificationsData(notifications);
          updateNotificationsOpenedTime();
          setloading(false);
        });
      } catch (e) {
        setloading(false);
        alert(
          I18n.t("screen_messages.common.sorry_text"),
          "Unable to load the notifications. Please try again later."
        );
      }
    }, [])
  );

  const setNotificationsData = (notifications) => {
    try {
      NotificationsHelper.fetchPersistableNotificationsCount(user).then(
        (count) => {
          const sectionsCloned = _.cloneDeep(sections);
          const unreadNotifications = _.take(notifications, count);
          const readNotifications = notifications.slice(count);
          if (unreadNotifications) {
            unreadNotifications.forEach((notification) => {
              notification.isUnRead = true;
            });
          }
          var finalSection = sectionsCloned.map((final) => {
            if (final.type === "UNREAD") {
              final.data = unreadNotifications;
              return final;
            } else if (final.type === "READ") {
              final.data = readNotifications;
              return final;
            }
            return final;
          });

          setSections(finalSection);
          setUnreadCount(count);
          setTimeout(() => {
            sectionListRef.current?.scrollToLocation({
              itemIndex: 0,
              sectionIndex: 1,
              animated: true,
              viewPosition: 0.3,
            });
          }, 300);
        }
      );
    } catch (error) {
      setloading(false);
      console.log("setNotificationsData Error", error);
    }
  };

  const updateNotificationsOpenedTime = () => {
    try {
      AsyncStorage.setItem(
        NOTIFICATIONS_OPENED_TIME_KEY,
        JSON.stringify(new Date())
      ).then(() => {
        refreshUser();
      });
    } catch (error) {
      console.log("updateNotificationsOpenedTime error", error);
    }
  };

  const refreshUser = () => {
    SessionManager.isLoggedInUser(
      (response) => {
        setUser(response.data);
      },
      () => {}
    );
  };

  const alert = (title, message) => {
    Alert.alert(
      title,
      message,
      [
        {
          text: "OK",
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  };

  const renderSectionItem = ({ item }) => {
    return (
      <View>
        <NotificationCard notification={item} />
      </View>
    );
  };

  const renderSectionFooter = () => {
    if (unreadCount > 0) {
      return (
        <View>
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
            }}
          >
            <PrestoText
              fontStyle={"400.normal"}
              color={theme.secondary}
              size={14}
              extraStyle={{}}
            >
              {I18n.t("screen_messages.unread_notification_with_count", {
                count: unreadCount,
              })}
            </PrestoText>
          </View>
          <VerticalSpacing size={15} />
        </View>
      );
    }
  };

  const hasItems = unreadCount > 0 || _.last(sections).data.length > 0;

  return (
    <LoadingContainer loading={loading}>
      <VerticalSpacing size={15} />
      {!loading ? (
        hasItems ? (
          <>
            {renderSectionFooter()}
            <SectionList
              ref={sectionListRef}
              style={{ flex: 1, backgroundColor: theme.backgroundColor }}
              stickySectionHeadersEnabled={false}
              onScrollToIndexFailed={(e) => {
                console.log(e);
              }}
              sections={sections}
              keyExtractor={(item, index) => item + index}
              renderItem={renderSectionItem}
              contentContainerStyle={{ paddingBottom: 100, paddingTop: 15 }}
            />
          </>
        ) : (
          <View>
            <View
              style={{
                paddingHorizontal: theme.containerPadding,
              }}
            >
              <PrestoText
                fontStyle={"400.normal"}
                color={theme.secondary}
                size={14}
                extraStyle={{}}
              >
                {I18n.t("screen_messages.no_notifications_available")}
              </PrestoText>
            </View>
          </View>
        )
      ) : null}
    </LoadingContainer>
  );
}
