import * as React from "react";
import Svg, { Path, Polygon, G, Mask, Use, Rect } from "react-native-svg";

function InventoryIconFilled(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {" "}
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="icon/black/inventory-filled" fill="#FFFFFF">
          <Path
            d="M31.0363831,16.0742135 L34.9913831,12.1212135 C35.1183831,11.9982135 35.1773831,11.8222135 35.1553831,11.6522135 C35.1303831,11.4742135 35.0283831,11.3222135 34.8783831,11.2342135 L24.2123831,5.07421349 C23.9913831,4.94721349 23.7143831,4.98621349 23.5343831,5.16821349 L19.5793831,9.11921349 L15.6243831,5.16821349 C15.4423831,4.98621349 15.1653831,4.94721349 14.9443831,5.07421349 L4.28038314,11.2342135 C4.12638314,11.3222135 4.02438314,11.4742135 4.00538314,11.6522135 C3.98238314,11.8222135 4.03838314,11.9982135 4.16138314,12.1212135 L8.11838314,16.0742135 L4.16138314,20.0312135 C4.03838314,20.1542135 3.98138314,20.3302135 4.00538314,20.5002135 C4.02538314,20.6722135 4.12638314,20.8262135 4.28038314,20.9142135 L8.34838314,23.2662135 L8.34838314,28.3872135 C8.34838314,28.5902135 8.45738314,28.7742135 8.62938314,28.8752135 L19.2973831,35.0352135 C19.3833831,35.0882135 19.4813831,35.1092135 19.5783831,35.1092135 C19.6743831,35.1092135 19.7713831,35.0882135 19.8593831,35.0352135 L30.5233831,28.8752135 C30.6953831,28.7732135 30.8043831,28.5902135 30.8043831,28.3872135 L30.8043831,23.2662135 L34.8763831,20.9142135 C35.0263831,20.8262135 35.1283831,20.6722135 35.1533831,20.5002135 C35.1743831,20.3242135 35.1163831,20.1542135 34.9893831,20.0312135 L31.0363831,16.0742135 L31.0363831,16.0742135 Z M24.0243831,6.26621349 L33.6783831,11.8462135 L30.1493831,15.3712135 L29.5713831,15.0372135 L20.4933831,9.79721349 L24.0243831,6.26621349 L24.0243831,6.26621349 Z M15.1333831,6.26621349 L18.6643831,9.79721349 L9.00638314,15.3712135 L5.47538314,11.8462135 L15.1333831,6.26621349 L15.1333831,6.26621349 Z M9.00638314,16.7772135 L18.6603831,22.3552135 L15.1313831,25.8802135 L9.19238314,22.4522135 L9.19038314,22.4522135 L5.47538314,20.3062135 L9.00638314,16.7772135 L9.00638314,16.7772135 Z M24.0223831,25.8812135 L20.4933831,22.3562135 L30.1493831,16.7782135 L33.6783831,20.3072135 L24.0223831,25.8812135 L24.0223831,25.8812135 Z"
            id="box_open_2"
          ></Path>
          <Polygon
            id="Path"
            points="29.1143831 16.0742135 19.5793831 21.5822135 10.0403831 16.0742135 17.1923831 11.9452135 19.5793831 10.5702135 28.2043831 15.5472135"
          ></Polygon>
        </G>
      </G>
    </Svg>
  );
}

export default InventoryIconFilled;
