import * as React from "react";
import Svg, { Path, G  } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={11} height={15} viewBox="0 0 11 15" fill={"none"} {...props}>
      <Path
        opacity={1}
        d="M5.092.287C2.338.287.103 2.615.103 5.483c0 2.095.702 2.68 4.476 8.381a.632.632 0 00.223.206.603.603 0 00.58 0 .632.632 0 00.222-.206c3.775-5.701 4.476-6.286 4.476-8.38 0-2.87-2.234-5.197-4.988-5.197zm0 7.795c-.494 0-.976-.153-1.386-.438a2.578 2.578 0 01-.919-1.166 2.699 2.699 0 01-.141-1.501c.096-.504.333-.967.682-1.33.349-.364.793-.611 1.277-.712.484-.1.985-.048 1.441.148.456.197.845.53 1.12.957.274.427.42.93.42 1.443 0 .69-.263 1.35-.73 1.838a2.445 2.445 0 01-1.764.76z"
        fill={props.primaryColor || "#000"}
      />
      <Path
        d="M5.092 7.215c-.33 0-.65-.101-.924-.291a1.719 1.719 0 01-.612-.778 1.8 1.8 0 01-.095-1c.064-.336.222-.645.455-.887.233-.243.529-.407.851-.474a1.6 1.6 0 01.961.098c.304.131.564.353.746.638.183.285.28.62.28.962 0 .46-.175.9-.487 1.225a1.63 1.63 0 01-1.175.507z"
        fill={props.primaryColor || "#000"}
      />
    </Svg>
  );
}

export default SvgComponent;

export function plainLocation(props) {
  return (
    <Svg
      width={props.width || 43 }
      height={props.height || 62 }
      viewBox="0 0 43 62"
      {...props}
    >
      <G fill={ props.color || "#007388"} stroke="none" strokeWidth={1} fillRule="evenodd">
      <Path
        d="M21.375 0c11.874 0 21.463 7.859 21.624 21.812.051 4.466-1.573 9.874-4.873 16.225l-.421.8a81.617 81.617 0 01-.895 1.626l-.474.829-.491.838-.509.848-.526.858-.543.867-.56.878-.288.442-.587.892-.3.45-.613.907c-.208.304-.418.61-.631.917l-.648.926-.666.936-.34.472-.691.951-.71.961-.36.485-.736.975-.375.492-.761.99-.388.499-.787 1.005-.806 1.015-.823 1.025-.84 1.035L21.5 62l-.852-1.014-.834-1.005-.817-.996-.8-.988-.782-.98-.766-.97-.748-.962-.368-.478-.722-.95-.705-.94-.688-.933-.67-.923-.655-.916-.32-.454-.628-.902-.307-.448-.602-.89-.295-.441-.576-.877C8.892 45.207 8.437 44.49 8 43.78l-.516-.846a88.097 88.097 0 01-1.215-2.08l-.456-.816C1.938 32.997 0 26.922 0 21.812 0 7.072 9.501 0 21.375 0zm.125 5.11c-9.487 0-15.663 6.046-15.663 16.702 0 1.987.403 4.236 1.214 6.738l.202.605c.07.204.143.409.219.615l.233.625.25.634.265.644c.319.757.67 1.533 1.053 2.329l.336.686.174.347.36.7.375.71c.128.239.259.479.392.72l.407.729c.138.244.28.49.423.738l.44.747.454.757.471.766.241.386.495.78.51.788.262.397.535.803.55.81c.186.273.375.546.567.821l.582.83.297.417.607.843c.102.141.205.283.31.424l.63.856.321.432.655.869.333.438.679.882.345.445.43.548.507-.658.677-.889.332-.44.654-.875.637-.865c.105-.143.21-.286.313-.429l.615-.85.598-.841.294-.417.576-.827.56-.816.544-.807.53-.798c.087-.132.173-.263.258-.395l.506-.782.49-.773.24-.383.468-.758.452-.748c.148-.247.293-.493.436-.738l.422-.728.204-.36.399-.713c.065-.118.13-.235.193-.353l.375-.697.36-.688c.117-.227.232-.453.344-.677l.33-.668.158-.33.306-.651c.05-.108.1-.215.148-.322l.283-.637.136-.314.26-.621c.211-.513.406-1.015.585-1.507l.207-.584c.864-2.509 1.3-4.722 1.312-6.625v-.226C37.043 11.605 30.61 5.11 21.5 5.11z"
        fillRule="nonzero"
        transform="translate(-10 -5) translate(10 5)"
      />
      <Path
        d="M21.374 13.347c-4.308 0-7.099 2.861-7.099 7.381s2.79 7.38 7.1 7.38c4.308 0 7.1-2.86 7.1-7.38 0-4.291-2.55-7.381-7.1-7.381"
        transform="translate(-10 -5) translate(10 5)"
      />
      </G>
    </Svg>
  )
}
