import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function ReportsIconFilled(props) {
  return (
    <Svg
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <G id="bill" transform="translate(7.000094, 5.000013)">
          <Path
            d="M26.2495542,14.414108 L21.9129136,14.414108 L21.9129136,0.585983013 C21.9129136,0.349029888 21.7701206,0.135338482 21.5512144,0.0446353568 C21.3321909,-0.0461263619 21.0801792,0.00414707557 20.9126597,0.171608013 L19.2527573,1.83145176 L17.5929722,0.171666607 C17.3641636,-0.0571419869 16.993148,-0.0571419869 16.7642808,0.171666607 L15.104437,1.83151036 L13.4447691,0.171666607 C13.2159605,-0.0571419869 12.8449448,-0.0571419869 12.6160777,0.171666607 L10.9562339,1.83151036 L9.29633156,0.171666607 C9.06752296,-0.0571419869 8.69650734,-0.0571419869 8.46769874,0.171666607 L6.80785499,1.83151036 L5.14812843,0.171666607 C4.91931984,-0.0571419869 4.54830421,-0.0571419869 4.31943703,0.171666607 L2.65959328,1.83151036 L0.999808119,0.171666607 C0.772405775,-0.0557357369 0.404612806,-0.0569076119 0.175218275,0.168443951 C0.054749525,0.286861919 -0.00366844374,0.444654888 0.000178241283,0.601393169 L0.000178241283,26.9532291 C0.000178241283,28.6332291 1.36713234,29.9999869 3.04713234,29.9999869 L23.7879136,29.9999869 C23.7880308,29.9999869 23.7880894,29.9999869 23.7882066,29.9999869 C23.7883823,29.9999869 23.7885581,29.9999869 23.7887339,29.9999869 C25.4687339,29.9999869 26.8354917,28.6332291 26.8354917,26.9532291 L26.8354917,14.9999869 C26.8354917,14.6763736 26.5731675,14.414108 26.2495542,14.414108 Z M25.6636167,26.9531705 C25.6636167,27.9869986 24.8225034,28.8281119 23.7886753,28.8281119 C22.7548472,28.8281119 21.9137339,27.9869986 21.9137339,26.9531705 L21.9137339,15.5859244 L25.6636167,15.5859244 L25.6636167,26.9531705 L25.6636167,26.9531705 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></Path>
          <Path
            d="M17.1397495,17.8207096 L7.85715187,17.8207096 C7.53353859,17.8207096 7.27121437,18.0830338 7.27121437,18.4066471 C7.27121437,18.7302604 7.53353859,18.9925846 7.85715187,18.9925846 L17.1397495,18.9925846 C17.4633628,18.9925846 17.725687,18.7302604 17.725687,18.4066471 C17.725687,18.0830338 17.4633628,17.8207096 17.1397495,17.8207096 Z"
            id="Path"
            fill="#707070"
          ></Path>
          <Path
            d="M5.18767921,17.9923893 C5.07869484,17.8834049 4.92752296,17.8207096 4.7734214,17.8207096 C4.61931984,17.8207096 4.46814796,17.8834049 4.35916359,17.9923893 C4.25017921,18.1013736 4.1874839,18.2525455 4.1874839,18.4066471 C4.1874839,18.5607486 4.25017921,18.7119205 4.35916359,18.8209049 C4.46814796,18.9298893 4.61931984,18.9925846 4.7734214,18.9925846 C4.92752296,18.9925846 5.07869484,18.9298893 5.18767921,18.8209049 C5.29666359,18.7119205 5.3593589,18.5607486 5.3593589,18.4066471 C5.3593589,18.2525455 5.29666359,18.1013736 5.18767921,17.9923893 Z"
            id="Path"
            fill="#707070"
          ></Path>
          <Path
            d="M17.1397495,5.82070958 L7.85715187,5.82070958 C7.53353859,5.82070958 7.27121437,6.08303379 7.27121437,6.40664708 C7.27121437,6.73026036 7.53353859,6.99258458 7.85715187,6.99258458 L17.1397495,6.99258458 C17.4633628,6.99258458 17.725687,6.73026036 17.725687,6.40664708 C17.725687,6.08303379 17.4633628,5.82070958 17.1397495,5.82070958 Z"
            id="Path-Copy-2"
            fill="#707070"
          ></Path>
          <Path
            d="M5.18767921,5.99238926 C5.07869484,5.88340489 4.92752296,5.82070958 4.7734214,5.82070958 C4.61931984,5.82070958 4.46814796,5.88340489 4.35916359,5.99238926 C4.25017921,6.10137364 4.1874839,6.25254551 4.1874839,6.40664708 C4.1874839,6.56074864 4.25017921,6.71192051 4.35916359,6.82090489 C4.46814796,6.92988926 4.61931984,6.99258458 4.7734214,6.99258458 C4.92752296,6.99258458 5.07869484,6.92988926 5.18767921,6.82090489 C5.29666359,6.71192051 5.3593589,6.56074864 5.3593589,6.40664708 C5.3593589,6.25254551 5.29666359,6.10137364 5.18767921,5.99238926 Z"
            id="Path-Copy"
            fill="#707070"
          ></Path>
          <Path
            d="M17.1397495,9.82070958 L7.85715187,9.82070958 C7.53353859,9.82070958 7.27121437,10.0830338 7.27121437,10.4066471 C7.27121437,10.7302604 7.53353859,10.9925846 7.85715187,10.9925846 L17.1397495,10.9925846 C17.4633628,10.9925846 17.725687,10.7302604 17.725687,10.4066471 C17.725687,10.0830338 17.4633628,9.82070958 17.1397495,9.82070958 Z"
            id="Path-Copy-4"
            fill="#707070"
          ></Path>
          <Path
            d="M5.18767921,9.99238926 C5.07869484,9.88340489 4.92752296,9.82070958 4.7734214,9.82070958 C4.61931984,9.82070958 4.46814796,9.88340489 4.35916359,9.99238926 C4.25017921,10.1013736 4.1874839,10.2525455 4.1874839,10.4066471 C4.1874839,10.5607486 4.25017921,10.7119205 4.35916359,10.8209049 C4.46814796,10.9298893 4.61931984,10.9925846 4.7734214,10.9925846 C4.92752296,10.9925846 5.07869484,10.9298893 5.18767921,10.8209049 C5.29666359,10.7119205 5.3593589,10.5607486 5.3593589,10.4066471 C5.3593589,10.2525455 5.29666359,10.1013736 5.18767921,9.99238926 Z"
            id="Path-Copy-3"
            fill="#707070"
          ></Path>
          <Path
            d="M17.1397495,13.8207096 L7.85715187,13.8207096 C7.53353859,13.8207096 7.27121437,14.0830338 7.27121437,14.4066471 C7.27121437,14.7302604 7.53353859,14.9925846 7.85715187,14.9925846 L17.1397495,14.9925846 C17.4633628,14.9925846 17.725687,14.7302604 17.725687,14.4066471 C17.725687,14.0830338 17.4633628,13.8207096 17.1397495,13.8207096 Z"
            id="Path-Copy-6"
            fill="#707070"
          ></Path>
          <Path
            d="M5.18767921,13.9923893 C5.07869484,13.8834049 4.92752296,13.8207096 4.7734214,13.8207096 C4.61931984,13.8207096 4.46814796,13.8834049 4.35916359,13.9923893 C4.25017921,14.1013736 4.1874839,14.2525455 4.1874839,14.4066471 C4.1874839,14.5607486 4.25017921,14.7119205 4.35916359,14.8209049 C4.46814796,14.9298893 4.61931984,14.9925846 4.7734214,14.9925846 C4.92752296,14.9925846 5.07869484,14.9298893 5.18767921,14.8209049 C5.29666359,14.7119205 5.3593589,14.5607486 5.3593589,14.4066471 C5.3593589,14.2525455 5.29666359,14.1013736 5.18767921,13.9923893 Z"
            id="Path-Copy-5"
            fill="#707070"
          ></Path>
          <Path
            d="M17.1397495,21.8299869 L7.85715187,21.8299869 C7.53353859,21.8299869 7.27121437,22.0923111 7.27121437,22.4159244 C7.27121437,22.7395377 7.53353859,23.0018619 7.85715187,23.0018619 L17.1397495,23.0018619 C17.4633628,23.0018619 17.725687,22.7395377 17.725687,22.4159244 C17.725687,22.0923111 17.4633628,21.8299869 17.1397495,21.8299869 Z"
            id="Path"
            fill="#707070"
          ></Path>
          <Path
            d="M5.18767921,22.0016666 C5.07869484,21.8926822 4.92752296,21.8299869 4.7734214,21.8299869 C4.61931984,21.8299869 4.46814796,21.8926822 4.35916359,22.0016666 C4.25017921,22.110651 4.1874839,22.2618229 4.1874839,22.4159244 C4.1874839,22.570026 4.25017921,22.7211979 4.35916359,22.8301822 C4.46814796,22.9391666 4.61931984,23.0018619 4.7734214,23.0018619 C4.92752296,23.0018619 5.07869484,22.9391666 5.18767921,22.8301822 C5.29666359,22.7211979 5.3593589,22.570026 5.3593589,22.4159244 C5.3593589,22.2618229 5.29666359,22.110651 5.18767921,22.0016666 Z"
            id="Path"
            fill="#707070"
          ></Path>
        </G>
      </G>
    </Svg>
  );
}

export default ReportsIconFilled;
