import React from "react";
import { animated, useSpring } from "@react-spring/web";

export default function SpringView({ from, to, children }) {
  const springs = useSpring({
    from,
    to,
  });
  return (
    <animated.div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      {children({})}
    </animated.div>
  );
}
