import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
      <Path
        opacity={0.4}
        d="M6.918 19.543a8.984 8.984 0 10-.574-13.59l2.479 2.48a.781.781 0 01-.553 1.333H1.172a.781.781 0 01-.781-.782V1.886a.781.781 0 011.333-.552l2.411 2.41A12.067 12.067 0 0112.5.392c6.68 0 12.097 5.409 12.11 12.086.012 6.677-5.43 12.128-12.101 12.132A12.06 12.06 0 014.9 21.93a1.172 1.172 0 01-.09-1.74l.55-.55a1.171 1.171 0 011.558-.096z"
        fill={props.primaryColor || "#1C1649"}
      />
      <Path
        d="M14.063 7.422v5.095l1.987 1.546a1.171 1.171 0 01.205 1.644l-.48.616a1.172 1.172 0 01-1.644.206l-3.194-2.484V7.422A1.172 1.172 0 0112.11 6.25h.782a1.172 1.172 0 011.171 1.172z"
        fill={props.primaryColor || "#1C1649"}
      />
    </Svg>
  );
}

export default SvgComponent;
