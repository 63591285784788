import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#fff"}>
        <Path
          opacity={0.4}
          d="M0 19.532v-7.826a.469.469 0 01.469-.469h7.825a.469.469 0 01.469.47v1.851a.469.469 0 01-.491.469l-3.98-.19a6.878 6.878 0 0012.393-2.225.469.469 0 01.456-.362h1.918a.469.469 0 01.46.554c-.844 4.488-4.785 7.884-9.519 7.884a9.665 9.665 0 01-7.373-3.405l.16 3.225A.469.469 0 012.32 20H.47A.469.469 0 010 19.532z"
        />
        <Path d="M.48 8.196C1.325 3.708 5.266.313 10 .313a9.664 9.664 0 017.379 3.411L17.223.491a.468.468 0 01.47-.491h1.851a.469.469 0 01.469.469v7.825a.469.469 0 01-.469.469H11.72a.469.469 0 01-.469-.469V6.442a.467.467 0 01.491-.469l3.966.191A6.877 6.877 0 003.315 8.388a.469.469 0 01-.456.362H.941a.469.469 0 01-.46-.554z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill={props.primaryColor || "#fff"} d="M0 0h20v20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
