import * as React from "react";
import Svg, { Defs, Circle, G, Mask, Use, Path } from "react-native-svg";

function UserIconCircularOutline(props) {
  const width = props.width || 40;
  const height = props.height || 40;
  const color = props.color || "#FAA85D";

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <G
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G id="icon/black/bill-customer-info">
          <Circle
            id="Oval"
            stroke={color}
            fill={color}
            cx="20"
            cy="20"
            r="19.5"
          ></Circle>
          <G
            id="Profile---outline"
            transform="translate(8.000000, 8.000000)"
            stroke="#FFFFFF"
            stroke-width="1.2"
          >
            <Circle id="Ellipse_3" cx="11.5" cy="5.75" r="5.75"></Circle>
            <Path
              d="M11.5,14.375 C5.175,14.375 0,16.9625 0,20.125 L0,23 L23,23 L23,20.125 C23,16.9625 17.825,14.375 11.5,14.375 Z"
              id="Path_6"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default UserIconCircularOutline;
