import React, { useState, useContext, useEffect } from "react";
import { Platform } from "react-native";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import utils from "../../utils/index";
import CategoryItemManager from "@presto-services/features/category_item/CategoryItemManager";
import _ from "lodash";
import I18n from "i18n-js";
import SoldOutItemCard from "@presto-components/ItemCards/SoldOutItemCard";
import RegularItemCard from "@presto-components/ItemCards/ItemCard";
import InCartItemCard from "@presto-components/ItemCards/InCartItemCard2";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import ItemCardModal from "../../component-models/ItemCardModel";
import { useCatalog } from "@presto-stores/CatalogStore";
import config from "@presto-common/config";
import { prestoConfirm } from "@presto-common/Alert";

export default function ItemCards({
  item,
  hideBottomSection,
  theme,
  getItemPromotions,
  promotions,
  onClickItemCard,
  containerStyle,
}) {
  const product = item;
  const lineItem = item;
  const { getCountOfItemInCart, updateItemInCart, cart } = useContext(
    CartContext
  );
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [promotionText, setPromotionText] = useState("");
  const [success, setSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [itemIds, setItemIds] = useState(null);
  const [modalCount, setModalCount] = useState(0);
  const { catalog, refresh: refreshCatalog } = useCatalog();
  const userTypeEmployee = config?.user_type === "employee";
  const isDynamicPricingEnabled =
    config.merchant_config.features.dynamic_pricing;
  const refitApp = config?.features?.refit_app;
  const hasOffers = product.variations && product.variations.length;
  let unsoldVariations = _.filter(
    product.variations || [],
    (varitation) => !(varitation.sold_out || varitation.stock_count === 0)
  );
  const variation = _.first(unsoldVariations);
  const { setPaneState } = useContext(RightPaneContext);

  const isWeb = Platform.OS == "web";

  const showLogin = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: { screenName: "LOGIN" },
    });
  };

  useEffect(() => {
    if (_.isEmpty(cart?.items)) {
      setModal(false);
    }
    filterItemPromotions(promotions?.data || {});
  }, [promotions]);

  const filterItemPromotions = async (data) => {
    const variationId = null;
    let entireItem = _.find(cart?.items, { item_id: item.id });
    if (!entireItem) {
      return;
    }
    let itemPromotionObject = data[entireItem.id];

    const countToBeAdded =
      itemPromotionObject?.prerequisite_item_count_to_be_added;
    let isMinimumPurchase =
      itemPromotionObject?.prerequisite_minimum_purchase_value > 0;
    setItemIds(itemPromotionObject?.entitled_item_ids);
    if (itemPromotionObject?.promotions_available) {
      if (itemPromotionObject.entitlement_type == "amount_discount") {
        if (itemPromotionObject.value_type == "percent") {
          let freeCount = itemPromotionObject?.value;
          let allocationLimit = itemPromotionObject?.allocation_limit;
          let displayName =
            item?.other_data.display_quantity +
            " " +
            item?.other_data.display_name;
          if (countToBeAdded === 0) {
            setSuccess(true);
            setPromotionText(
              allocationLimit > 1
                ? I18n.t(
                    "screen_messages.item_promotions.congrats_text_percent_2",
                    {
                      count: freeCount,
                    }
                  )
                : I18n.t(
                    "screen_messages.item_promotions.congrats_text_percent",
                    {
                      name: displayName,
                    }
                  )
            );
          } else {
            setSuccess(false);
            setPromotionText(
              I18n.t(
                "screen_messages.item_promotions.buy_more_to_get_percent_discount",
                {
                  count_1: countToBeAdded,
                  count_2: allocationLimit > 1 ? freeCount : allocationLimit,
                }
              )
            );
          }
        } else if (itemPromotionObject?.value_type == "fixed") {
          if (countToBeAdded === 0) {
            setSuccess(true);
            setPromotionText(
              I18n.t("screen_messages.item_promotions.congrats_text_rupees", {
                count: utils.formattedCurrencyCents(itemPromotionObject.value),
              })
            );
          } else {
            setSuccess(false);
            setPromotionText(
              I18n.t(
                "screen_messages.item_promotions.buy_more_to_get_rupee_discount",
                {
                  count_1: countToBeAdded,
                  count_2: utils.formattedCurrencyCents(
                    itemPromotionObject.value
                  ),
                }
              )
            );
          }
        }
      } else if (itemPromotionObject?.entitled_item_ids.length === 1) {
        if (itemPromotionObject?.entitled_item_ids[0] === item.id) {
          let freeCount =
            itemPromotionObject.prerequisite_to_entitlement_quantity_ratio
              .entitled_item_quantity;
          let itemToBeAdded = _.head(
            _.keys(itemPromotionObject.entitled_item_count_to_be_added)
          );
          let countToBeAddedInCart =
            itemPromotionObject.entitled_item_count_to_be_added[itemToBeAdded];
          if (countToBeAdded === 0) {
            const count = getCountOfItemInCart(item.id, null);
            setSuccess(true);
            setPromotionText(
              I18n.t("screen_messages.item_promotions.congrats_text_single", {
                name: item?.other_data?.display_quantity,
                qty: item?.other_data?.display_name,
              })
            );
            const [error, status] = await updateItemInCart(
              item.id,
              variationId,
              count + countToBeAddedInCart,
              user.merchant_id
            );
          } else {
            setPromotionText(
              I18n.t("screen_messages.item_promotions.buy_more_count_single", {
                count_1: countToBeAdded,
                count_2: freeCount,
              })
            );
            setSuccess(false);
          }
        } else if (itemPromotionObject.entitled_item_ids[0] !== item.id) {
          let freeCount =
            itemPromotionObject.prerequisite_to_entitlement_quantity_ratio
              .entitled_item_quantity;
          if (countToBeAdded === 0) {
            let itemToBeAdded = _.head(
              _.keys(itemPromotionObject.entitled_item_count_to_be_added)
            );
            setSuccess(true);
            setPromotionText(
              I18n.t("screen_messages.item_promotions.congrats_text")
            );
            const [error, status] = await updateItemInCart(
              itemToBeAdded,
              variationId,
              freeCount,
              user.merchant_id
            );
          } else {
            setPromotionText(
              I18n.t("screen_messages.item_promotions.buy_more_count_single", {
                count_1: countToBeAdded,
                count_2: freeCount,
              })
            );
            setSuccess(false);
          }
        }
      } else if (itemPromotionObject.entitled_item_ids.length > 1) {
        let freeCount =
          itemPromotionObject.prerequisite_to_entitlement_quantity_ratio
            .entitled_item_quantity;
        let length = itemPromotionObject.entitled_item_ids.length;
        if (countToBeAdded == 0) {
          let items = _.filter(
            cart?.items,
            (e) => e.promotion_discount_amount > 0
          );
          if (_.isEmpty(items)) {
            setModal(true);
            setModalCount(freeCount);
            setPromotionText("");
          } else {
            setSuccess(true);
            setModal(false);
            setModalCount(freeCount);
            setPromotionText(
              I18n.t("screen_messages.item_promotions.congrats_text")
            );
          }
        } else {
          setModal(false);
          setModalCount(freeCount);
          setPromotionText(
            I18n.t("screen_messages.item_promotions.buy_more_count_multiple", {
              count_1: countToBeAdded,
              name: item.name,
            })
          );
          setSuccess(false);
        }
      }
    } else {
      setPromotionText("");
    }
  };

  const openSelectionModal = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "LIST_ITEMS_AVAILABLE_FOR_DISCOUNT",
        screenParams: {
          availableDiscountItemIds: itemIds,
          header:
            modalCount > 1
              ? I18n.t("screen_messages.item_promotions.header_text", {
                  count: modalCount,
                })
              : I18n.t("screen_messages.item_promotions.header_text_single"),
          getItemPromotions: () => {},
        },
      },
    });
  };

  const onAdd = async () => {
    if (user === null || !user) {
      showLogin();
      return;
    }
    if (updating) {
      return true;
    }
    if (!catalog) {
      return;
    }

    if (!userTypeEmployee && !isDynamicPricingEnabled) {
      if (user.catalog_type !== catalog.filter) {
        refreshCatalog();
        return;
      }
      if (product.catalog_id !== catalog.id) {
        refreshCatalog();
        return;
      }
    }
    let variationId = null;
    if (unsoldVariations && unsoldVariations.length > 0) {
      variationId = variation.id;
    }

    if (
      product.other_data?.max_quantity &&
      count + 1 > parseInt(product.other_data?.max_quantity)
    ) {
      alert(
        I18n.t("screen_messages.order_quantity.max_quantity_of_product", {
          count: product.other_data?.max_quantity,
        })
      );
      return;
    }
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count + 1
    );
    if (_.isFunction(getItemPromotions)) {
      getItemPromotions();
    }
    if (status) {
      const count = getCountOfItemInCart();
    } else {
      alert(utils.errorMessage(error));
    }
    setUpdating(false);
  };

  const count = getCountOfItemInCart(item.id, null);
  const onSubtract = async () => {
    if (!user) {
      showLogin();
      return;
    }
    if (updating) {
      return true;
    }
    if (!catalog) {
      return;
    }

    if (!userTypeEmployee && !isDynamicPricingEnabled) {
      if (user.catalog_type !== catalog.filter) {
        refreshCatalog();
        return;
      }
      if (product.catalog_id !== catalog.id) {
        refreshCatalog();
        return;
      }
    }
    let variationId = null;
    if (unsoldVariations && unsoldVariations.length > 0) {
      variationId = variation.id;
    }

    if (
      count &&
      product.other_data?.min_quantity &&
      count == parseInt(product.other_data?.min_quantity)
    ) {
      showMinQtyAlert(variationId);
      return;
    }
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count - 1 > 0 ? count - 1 : 0
    );
    if (_.isFunction(getItemPromotions)) {
      getItemPromotions();
    }
    if (status) {
      const count = getCountOfItemInCart();
    } else {
      alert(I18n.t("error_messages.error_updating_cart"));
    }
    setUpdating(false);
  };

  const onPressItem = () => {
    console.log("Go to checkout");
    if (!user) {
      showLogin();
      return;
    } else if (item.description) {
      if (isWeb) {
        setPaneState(true);
      }
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "DETAILED_ITEM",
          screenParams: { item: item },
        },
      });
    }
  };

  const onNotifyMe = (variation) => {
    if (!user) {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "LOGIN",
        },
      });
      return;
    }
    CategoryItemManager.notifyWhenAvailable(
      {
        productId: product.id,
        itemId: product.id,
        variation_id: variation?.id,
        requestor: {
          name: user.name,
          phone: user.phone_number,
        },
      },
      () => {
        alert(I18n.t("screen_messages.notifications.notify_thanks_messages"));
      },
      (error) => {
        alert(error.message);
      }
    );
  };

  const showMinQtyAlert = (variationId) => {
    setUpdating(true);
    const buttons = [
      {
        text: I18n.t("screen_messages.common.yes_text"),
        onPress: async () => {
          const [error, status] = await updateItemInCart(
            item.id,
            variationId,
            0
          );
          setUpdating(undefined);
        },
      },
      {
        text: I18n.t("screen_messages.common.no_text"),
        onPress: () => {
          setUpdating(undefined);
        },
      },
    ];
    prestoConfirm(
      "Minimum Quantity",
      I18n.t("screen_messages.order_quantity.min_quantity_of_product", {
        count: product.other_data?.min_quantity,
      }) + ". Are you sure you want to remove all the items ?",
      buttons,
      true
    );
  };

  const onUpdate = async (variation, quantity) => {
    if (!user) {
      showLogin();
      return;
    }
    if (updating) {
      return true;
    }

    if (!catalog) {
      return;
    }

    if (!userTypeEmployee && !isDynamicPricingEnabled) {
      if (user.catalog_type !== catalog.filter) {
        refreshCatalog();
        return;
      }

      if (product.catalog_id !== catalog.id) {
        refreshCatalog();
        return;
      }
    }

    let qty = 0;
    if (product.other_data?.min_quantity && count < 1) {
      qty = parseInt(product.other_data?.min_quantity);
    }

    if (
      product.other_data?.max_quantity &&
      quantity > parseInt(product.other_data?.max_quantity)
    ) {
      alert(
        I18n.t("screen_messages.order_quantity.max_quantity_of_product", {
          count: product.other_data?.max_quantity,
        })
      );
      return;
    }

    if (
      count &&
      product.other_data?.min_quantity &&
      quantity < parseInt(product.other_data?.min_quantity)
    ) {
      showMinQtyAlert(variation?.id);
      return;
    }
    setUpdating(variation?.id || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variation?.id,
      qty ? qty : quantity
    );
    if (_.isFunction(getItemPromotions)) {
      getItemPromotions();
    }
    console.log("Error in onAmountEdit", error, status);
    if (!status) {
      alert(utils.errorMessage(error));
    }
    setUpdating(undefined);
  };

  const isSoldOut = product.sold_out || product.stock_count === 0;

  const heading = product?.name;
  const amount = count ?? 0;
  const originalPrice =
    product.other_data?.old_price !== "0"
      ? `${utils.formattedPrice(parseFloat(product.other_data?.old_price))} `
      : "";

  const price = `${utils.formattedPrice(
    product.price.total_price * (amount || 1)
  )}`;

  const tag = "";

  let itemPrice = `${utils.formattedPrice(product.price.total_price)}`;
  let oldCartPrice = "";
  if (product?.issues?.old_price) {
    oldCartPrice = `${utils.formattedPrice(product.issues?.old_price / 100.0)}`;
  }
  let division_number = 1;
  if (product.other_data?.display_name) {
    if (product.other_data?.display_name == "Kg") {
      division_number = product.other_data?.display_quantity;
    }
    if (product.other_data?.display_quantity > 1) {
      itemPrice = `${utils.formattedPrice(
        product.price.total_price / division_number
      )}/${
        product.other_data?.display_quantity +
        " " +
        product.other_data?.display_name
      }`;
    } else {
      itemPrice = `${utils.formattedPrice(
        product.price.total_price / division_number
      )}/${product.other_data?.display_name}`;
    }
  }

  let offer = "";
  let savings = "";
  if (product.other_data?.old_price !== "0") {
    const priceDiff = product.other_data?.old_price - product.price.base_price;
    savings = I18n.t("screen_messages.billing.discount_save_message", {
      amount: utils.formattedPrice(priceDiff),
    });
    const percentage = (
      (priceDiff / product.other_data?.old_price) *
      100
    ).toFixed(1);

    offer = `${percentage}%`;
  }
  let quantity = "";

  if (product.other_data?.display_quantity) {
    if (count > 0) {
      quantity = `${(
        (count * product.other_data?.display_quantity) /
        product.other_data?.display_price_multiplier
      ).toFixed(2)} ${product.other_data?.display_name}`;
    } else {
      quantity = `${product.other_data?.display_quantity} ${product.other_data?.display_name}`;
    }
  } else {
    quantity = `${count || 1}`;
  }

  let deliveredQuantity = "";
  if (lineItem?.other_data?.display_quantity != null) {
    if (lineItem?.delivered_quantity > 0) {
      deliveredQuantity = `${(
        (lineItem?.delivered_quantity * lineItem.other_data?.display_quantity) /
        lineItem.other_data?.display_price_multiplier
      ).toFixed(2)} ${lineItem.other_data?.display_name}`;
    } else {
      deliveredQuantity = `${lineItem?.delivered_quantity} ${lineItem.other_data?.display_name}`;
    }
  } else {
    deliveredQuantity = `${lineItem?.delivered_quantity || 1}`;
  }

  let discount;
  let itemObj = _.find(cart?.items, { item_id: item.id });
  if (!_.isEmpty(itemObj)) {
    if (itemObj?.promotion_discount_amount) {
      discount = utils.formattedCurrencyToRoundOff(
        itemObj?.promotion_discount_amount
      );
    } else {
      discount = "";
    }
  }

  if (isSoldOut) {
    return (
      <SoldOutItemCard
        product={new ItemCardModal({ product, variation, count })}
        onPress={onPressItem}
        onNotifyMe={onNotifyMe}
        onClickItemCard={onClickItemCard}
        containerStyle={containerStyle}
      />
    );
  }
  if (count > 0) {
    return (
      <InCartItemCard
        product={new ItemCardModal({ product, variation, count })}
        onPress={onPressItem}
        onAdd={onAdd}
        count={count}
        modal={modal}
        openSelectionModal={openSelectionModal}
        success={success}
        discount={discount}
        promotionText={promotionText}
        onSubtract={onSubtract}
        onUpdate={onUpdate}
        updating={updating}
        hideBottomSection={hideBottomSection}
        color={refitApp ? theme?.secondary : theme?.primary}
        onClickItemCard={onClickItemCard}
        containerStyle={containerStyle}
      />
    );
  } else {
    return (
      <RegularItemCard
        product={new ItemCardModal({ product, variation, count })}
        onPress={onPressItem}
        success={success}
        modal={modal}
        openSelectionModal={openSelectionModal}
        promotionText={promotionText}
        onAdd={onAdd}
        onSubtract={onSubtract}
        updating={updating}
        onUpdate={onUpdate}
        hideBottomSection={hideBottomSection}
        onClickItemCard={onClickItemCard}
        color={refitApp ? theme?.secondary : theme?.primary}
        containerStyle={containerStyle}
      />
    );
  }
}
