import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function EmployeeId(props) {
  return (
    <Svg
      width={props.width || "40px"}
      height={props.height || "40px"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          stroke={props.color || "#979797"}
          fill={props.color || "#979797"}
          d="M5.5 6.5H34.5V35.5H5.5z"
        />
        <G
          transform="translate(12 14)"
          fill="#FFF"
          stroke="#FFF"
          strokeWidth={0.9}
        >
          <Circle cx={8.5} cy={4.25} r={4.25} />
          <Path d="M8.5 10.625c-4.675 0-8.5 1.912-8.5 4.25V17h17v-2.125c0-2.338-3.825-4.25-8.5-4.25z" />
        </G>
        <G transform="translate(17.857 1)">
          <Path
            stroke={props.color1 || "#707070"}
            fill={props.color1 || "#707070"}
            d="M0.5 0.5H4.64285714V8.5H0.5z"
          />
          <Circle stroke="#FFF" fill="#FFF" cx={2.64285714} cy={3.5} r={1} />
        </G>
      </G>
    </Svg>
  );
}

export default EmployeeId;
