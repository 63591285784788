import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={props.width || 25}
      height={props.height || 25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...props}
    >
      <Path
        d="M12.5 1.136v22.728M23.864 12.5H1.136"
        stroke={  props.primaryColor || "#FD5B53"}
        strokeWidth={props.strokeWidth || 1.52}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
