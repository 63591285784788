import React, { useState, useContext, useEffect, useRef } from "react";
import { View, Alert, ScrollView, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import { VerticalSpacing } from "@presto-components/Spacing";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import CartContext from "@presto-contexts/CartContext";
import UserContext from "@presto-contexts/UserContext";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import utils from "../../utils/index";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartReview from "@presto-components/CartReview/CartReview";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import RazorpayCheckout from "react-native-razorpay";
import AppConfig from "@presto-common/config";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import RadioButtonWithText from "@presto-components/RadioButtons/RadioButtonWithText";
const AsyncMerchantManager = asyncify(MerchantManager);
import asyncify from "../../common/Asyncify";
import MerchantManager from "@presto-services/features/merchant/MerchantManager";
import { useAssets } from "presto-react-components";
import OrderManager from "@presto-services/features/orders/OrderManager";
import config from "@presto-common/config";
import { alertBox } from "@presto-common/Alert";
import {
  useGlobalStateStore,
  GlobalStoreKeys,
} from "@presto-stores/GlobalStateStore";

export default function CartPayment({ route }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const globalStateStore = useGlobalStateStore();
  const {
    cart,
    placeOrder,
    checkoutCart,
    clearCart,
    refreshCart,
    getTotalTax,
    getTotalPrice,
  } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const { address } = route.params;
  const loadingRef = useRef({
    loading: false,
  });

  const [wallet, setWallet] = useState(undefined);
  const { user } = useContext(UserContext);
  const [minimumBillAmount, setMinimumAmount] = useState();
  const [customer, setCustomer] = useState(null);
  const userTypeEmployee = config?.user_type === "employee";

  useEffect(() => {
    setCustomer(globalStateStore.getKey(GlobalStoreKeys.customer, customer));
  }, [globalStateStore]);

  useEffect(() => {
    if (userTypeEmployee) {
      setPaymentType("COD");
    } else {
      if (wallet && wallet.current_balance > cart?.total_price) {
        setPaymentType("WALLET");
      } else {
        setPaymentType("ONLINE");
      }
    }
  }, [wallet]);

  const makePaymentWithoutPin = (order, cart) => {
    const params = {
      id: order.id,
      id_type: "order_id",
    };
    setLoading(true);
    WalletManager.makePayment(
      params,
      (response) => {
        getOrder(order, cart);
      },
      (error) => {
        setLoading(false);
        loadingRef.current.loading = false;
        console.log("Cart pin error : ", error, utils.errorMessage(error));
        alert(
          I18n.t("screen_messages.common.sry_text"),
          utils.errorMessage(error)
        );
      }
    );
  };

  const goToNextScreen = (order, cart) => {
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "ORDER_SUCCESSFUL",
        screenParams: {
          orderId: order?.id,
          cart: cart,
        },
      },
    });
  };
  const clearCartFun = () => {
    Navigator.emit({ event: "goBack" });
  };

  const onPlaceOrder = async (payment, cart) => {
    const [error, response] = await placeOrder({});
    if (error) {
      alert(utils.errorMessage(error));
      setLoading(false);
      loadingRef.current.loading = false;
    } else {
      if (payment === "WALLET") {
        makePaymentWithoutPin(response.data, cart);
      } else if (payment === "ONLINE") {
        handleOnlinePayment(response.data, cart);
      } else {
        getOrder(response.data, cart);
      }
    }
  };

  const paymentSuccess = (response, order, cart, payment_data) => {
    var params = {};
    params.paymentResponse = response;
    params.razorPayUrl = payment_data.razor_post_url;

    PaymentManager.captureRazorPayPaymentV1(
      params,
      (resp) => {
        getOrder(order, cart);
      },
      (error) => {
        setLoading(false);
        loadingRef.current.loading = false;
        console.log("capture error -", error);
        paymentFail(error);
      }
    );
  };

  // const awaitPaymentSuccess = (order, cart) => {
  //   let counter = 0;
  //   const intervalId = setInterval(() => {
  //     counter += 1;
  //     if (counter < 3) {
  //       getOrder(order, cart, true);
  //     } else if (counter === 4) {
  //       clearInterval(intervalId);
  //       setTimeout(() => {
  //         getOrder(order, cart, false);
  //       }, 0);
  //     }
  //   }, 10000);
  //   setTimeout(() => {
  //     clearInterval(3);
  //   }, 30000);
  // };

  const getOrder = (order, cart, wait) => {
    const onSuccess = (response) => {
      refreshCart();
      loadingRef.current.loading = false;
      if (response?.data?.status === "RECEIVED") {
        goToNextScreen(order, cart);
      } else {
        checkOrderStatus(order, cart, 0);
      }
    };

    const onError = (error) => {
      setLoading(false);
      loadingRef.current.loading = false;
      console.log("getOrder error", error);
    };

    if (userTypeEmployee) {
      OrderManager.getOrdersByOrderId(
        { order_id: order.id },
        onSuccess,
        onError
      );
    } else {
      OrderManager.getOrder({ id: order?.id }, onSuccess, onError);
    }
  };

  const paymentFail = (error, order, cart, data, paymentData) => {
    console.log("coming to paymentfail", data);
    refreshCart();
    setLoading(false);
    loadingRef.current.loading = false;
    Alert.alert(
      I18n.t("screen_messages.payments.failed_title"),
      error?.description ||
        I18n.t("screen_messages.payments.failed_description"),
      [
        {
          text: I18n.t("screen_messages.common.retry"),
          onPress: () => {
            setLoading(true);
            initiateRazorPay(order, cart, data, paymentData);
          },
        },
        {
          text: I18n.t("screen_messages.common.cancel_text"),
          style: "cancel",
          onPress: () => {
            Navigator.emit({
              event: "goBack",
            });
          },
        },
      ],
      { cancelable: false }
    );
  };

  const checkOrderStatus = (order, cart, retry) => {
    if (retry === 3) {
      if (Platform.OS == "web") {
        alert(I18n.t("screen_messages.payment_failed_message"));
      } else {
        Alert.alert(
          I18n.t("screen_messages.payment_failed_title"),
          I18n.t("screen_messages.payment_failed_message"),
          [
            {
              text: I18n.t("screen_messages.common.button_ok"),
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
      }
      loadingRef.current = { loading: false };
      setLoading(false);
      return;
    }
    loadingRef.current = { loading: true };
    const onSuccess = (response) => {
      if (response?.data?.status === "RECEIVED") {
        refreshCart();
        loadingRef.current = { loading: false };
        setLoading(false);
        goToNextScreen(order, cart);
      } else {
        setTimeout(() => {
          checkOrderStatus(order, cart, retry + 1);
        }, retry * 10 * 1000);
      }
    };

    const onError = (error) => {
      setTimeout(() => {
        checkOrderStatus(order, cart, retry);
      }, retry * 10 * 1000);
    };
    if (userTypeEmployee) {
      OrderManager.getOrdersByOrderId(
        { order_id: order.id },
        onSuccess,
        onError
      );
    } else {
      OrderManager.getOrder({ id: order?.id }, onSuccess, onError);
    }
  };

  const captureRazorPayPayment = (response, order, cart, payment_data) => {
    var params = {};
    params.paymentResponse = response;
    params.razorPayUrl = payment_data.razor_post_url;

    PaymentManager.captureRazorPayPaymentV1(
      params,
      (resp) => {
        checkOrderStatus(order, cart, 0);
      },
      (error) => {
        setLoading(false);
        loadingRef.current.loading = false;
        console.log("capture error -", error);
        checkOrderStatus(order, cart, 0);
      }
    );
  };

  const initiateRazorPay = (order, cart, data, payment_data) => {
    var paymentConfig = AppConfig.payment_config.razor_pay;
    var options = {
      description: "Payment",
      image: paymentConfig.merchant_logo_path,
      currency: paymentConfig.currency,
      key: paymentConfig.razor_pay_api_key,
      amount: data.total_amount,
      name: paymentConfig.app_name,
      prefill: {
        email: data.user_email,
        contact: data.user_phone_number,
        name: data.user_name,
      },
      theme: { color: paymentConfig.razor_pay_theme_color },
      order_id: data.order_id,
    };
    RazorpayCheckout.open(options)
      .then((response) => {
        paymentSuccess(response, order, cart, payment_data);
      })
      .catch((error) => {
        handleRazorPayFailure({}, order, cart, payment_data);
      });
  };

  const handleRazorPayFailure = (response, order, cart, payment_data) => {
    captureRazorPayPayment(response, order, cart, payment_data);
  };

  const handleOnlinePayment = (order, cart) => {
    var params = {};
    setLoading(true);
    params.id_type = "order_id";
    params.id = order.id;
    params.user = user;
    params.total_amount = cart.total_price;
    PaymentManager.initiateRazorPayPaymentV1(
      params,
      (response) => {
        const data = response.data.payment_response.payment_data;
        var paymentConfig = AppConfig.payment_config.razor_pay;
        const payment_data = response.data.payment_response;
        var options = {
          description: "Payment",
          image: paymentConfig.merchant_logo_path,
          currency: paymentConfig.currency,
          key: paymentConfig.razor_pay_api_key,
          amount: data.total_amount,
          name: paymentConfig.app_name,
          prefill: {
            email: data.user_email,
            contact: data.user_phone_number,
            name: data.user_name,
          },
          theme: { color: paymentConfig.razor_pay_theme_color },
          order_id: data.order_id,
        };

        if (Platform.OS === "web") {
          options.handler = (resp) => {
            paymentSuccess(resp, order, cart, payment_data);
          };
          options.modal = {
            ondismiss: function () {
              setLoading(true);
              loadingRef.current = { loading: true };
              checkOrderStatus(order, cart, 0);
              refreshCart();
            },
          };
          var rzp1 = new Razorpay(options);
          rzp1.open();

          rzp1.on("payment.failed", function (error) {
            console.log("failed ", error);
            paymentFail(error);
          });
        } else {
          initiateRazorPay(order, cart, data, payment_data);
        }
      },
      (error) => {
        loadingRef.current.loading = false;
        console.log("handleOnlinePayment error -", error);
      }
    );
  };

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    getMerchantConfig();
    WalletManager.getWalletInfo(
      { walletId: user.wallet_id },
      (response) => {
        setWallet(response.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, [user?.id]);

  const getMerchantConfig = async () => {
    let params = {
      merchant_id: cart?.merchant_id,
    };
    const [error, response] = await AsyncMerchantManager.getMerchantConfig(
      params
    );
    if (!error) {
      if (response.data.min_bill) {
        setMinimumAmount(response.data.min_bill.minimum_bill_amount);
      }
    }
  };

  const onCartCheckout = async () => {
    if (paymentType != null) {
      if (utils.convertPaisaToRupees(cart.total_price) < minimumBillAmount) {
        alert(
          I18n.t("screen_messages.cart.min_cart_amount", {
            minimum_amount: minimumBillAmount,
          })
        );
      } else {
        loadingRef.current.loading = true;
        setLoading(true);
        if (!userTypeEmployee) {
          const [error, response] = await checkoutCart({
            address: address,
            instructions: "",
            payment_type: paymentType != "COD" ? "ONLINE" : paymentType,
            delivery_type: "Delivery",
            other_data: {},
            source: "MOBILE",
          });
          if (error) {
            console.log(
              I18n.t("error_messages.error_checking_out"),
              error,
              response
            );
            alert(I18n.t("error_messages.error_checking_out"));
            setLoading(false);
            loadingRef.current.loading = false;
          } else {
            onPlaceOrder(paymentType, response.data);
          }
        } else {
          placeOrderByAgent();
        }
      }
    }
  };

  const placeOrderByAgent = () => {
    const onSuccess = (response) => {
      setLoading(false);
      loadingRef.current.loading = false;
      clearCart().then((...args) => {
        getOrder(response.data, cart);
      });
    };

    const onError = (error) => {
      console.log("error", error);
      setLoading(false);
      loadingRef.current.loading = false;
      alertBox(error.message);
    };

    let params = {
      merchant_id: customer?.merchant_id,
      order: {
        outlet_id: "",
        merchant_id: customer?.merchant_id,
        line_items: getLineItems(),
        instructions: "",
        payment_type: "COD",
        source: "",
        tax_amount: getTotalTax(),
        total_amount: getTotalPrice(),
        address: address,
        user: customer,
        coupon_code: cart?.coupon_code,
        aggregate_discount: cart.total_discounts,
      },
    };

    if (_.isEmpty(params, "order.address")) {
      params.order.address = _.first(customer.addresses);
    }

    OrderManager.placeAgentOrder(params, onSuccess, onError);
  };

  const getLineItems = () => {
    let array = [];
    _.forEach(cart?.items, (item) => {
      const variation = item.variation;
      let rate = item.rate / 100;
      let totalPrice = item.total_price;
      if (!_.isEmpty(variation)) {
        totalPrice = _.get(variation, "price.total_price");
        rate = _.get(variation, "price.total_price");
      }

      if (isNaN(totalPrice)) {
        throw new Error("Item total price cannot be zero");
      }

      array.push({
        item_id: item.item_id,
        quantity: item.quantity,
        total_price: rate,
        variation_id: item.variation ? item.variation.id : "",
      });
    });
    return array;
  };

  const cartInfo = {
    [I18n.t("screen_messages.cart.total_price")]: utils.formattedCurrencyCents(
      cart?.sub_total_price || 0
    ),
    [I18n.t("screen_messages.common.savings")]: utils.formattedCurrencyCents(
      cart?.total_discounts || 0
    ),
    [I18n.t("screen_messages.billing.taxes")]: utils.formattedCurrencyCents(
      cart?.total_taxes || 0
    ),
    [I18n.t("screen_messages.common.you_pay")]: utils.formattedCurrencyCents(
      cart?.total_price || 0
    ),
  };

  return (
    <LoadingContainer loading={loading}>
      <View style={{ width: "100%", flex: 1 }}>
        <ScrollView
          style={{ width: "100%", flex: 1 }}
          contentContainerStyle={{
            paddingTop: 20,
            paddingBottom: utils.isDesktop() ? 150 : 200,
          }}
        >
          {cart ? (
            <>
              <FullWidthHorizontalPaddedBox>
                <CartReview order={cart} />
              </FullWidthHorizontalPaddedBox>
              <VerticalSpacing size={10} />
              <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
              <VerticalSpacing size={30} />
            </>
          ) : null}

          {!loading ? (
            <>
              <PrestoText
                fontStyle={"700.normal"}
                size={16}
                extraStyle={{ marginLeft: theme.containerPadding }}
              >
                {I18n.t("screen_messages.payments.choose_payment_method")}
              </PrestoText>
              <VerticalSpacing size={20} />
              {userTypeEmployee ? (
                <>
                  <View style={{ paddingHorizontal: 20 }}>
                    <RadioButtonWithText
                      text={I18n.t("screen_messages.payments.cod")}
                      description={I18n.t(
                        "screen_messages.payments.description"
                      )}
                      status={paymentType === "COD"}
                      onPress={() => {
                        setPaymentType("COD");
                      }}
                      icon={
                        <svgs.LineWalletIcon
                          style={{ marginRight: 10 }}
                          width="32"
                          height="32"
                        />
                      }
                    />
                  </View>
                </>
              ) : (
                <>
                  <View style={{ paddingHorizontal: 20 }}>
                    <RadioButtonWithText
                      text={I18n.t("screen_messages.wallet.wallet_text")}
                      status={paymentType === "WALLET" ? true : false}
                      description={I18n.t(
                        "screen_messages.wallet.wallet_balance_text",
                        {
                          balance: wallet?.current_balance / 100 || 0,
                        }
                      )}
                      onPress={() => {
                        if (
                          wallet &&
                          wallet.current_balance > cart.total_price
                        ) {
                          setPaymentType("WALLET");
                        } else {
                          alert(
                            I18n.t(
                              "screen_messages.wallet.insufficient_balance"
                            )
                          );
                        }
                      }}
                      icon={
                        <svgs.LineWalletIcon
                          style={{ marginRight: 10 }}
                          width="32"
                          height="32"
                        />
                      }
                    />
                    <VerticalSpacing size={20} />
                  </View>

                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                  <VerticalSpacing size={20} />

                  <View style={{ paddingHorizontal: 20 }}>
                    <RadioButtonWithText
                      text={I18n.t("screen_messages.payments.online_text")}
                      status={paymentType === "ONLINE" ? true : false}
                      description={I18n.t(
                        "screen_messages.payments.card_payments_with_netbanking"
                      )}
                      onPress={() => setPaymentType("ONLINE")}
                      icon={
                        <svgs.Online
                          style={{ marginRight: 10 }}
                          width="32"
                          height="32"
                        />
                      }
                    />
                  </View>
                </>
              )}

              <VerticalSpacing size={10} />
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            </>
          ) : null}
        </ScrollView>
        <View
          style={{
            width: "100%",
            height: utils.isDesktop() ? 100 : 150,
            borderColor: "lightgrey",
            borderTopWidth: 1,
            position: "absolute",
            bottom: 0,
            left: 0,
            paddingHorizontal: theme.primaryPadding,
            backgroundColor: theme.appBackgroundColor,
            flexDirection: "row",
            alignItems: "top",
            paddingTop: 20,
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: "46%" }}>
            <PrestoSolidButton
              size="extra-large"
              title={"Cancel"}
              onPress={() => clearCartFun()}
              buttonStyle="tertiary"
              titleColor={theme.primaryButtonText}
              bgColor="#CCCCCC"
            />
          </View>
          <View
            style={{
              width: "52%",
              opacity: loadingRef.current.loading ? 0.4 : 1,
            }}
          >
            <PrestoSolidButton
              size="extra-large"
              title={
                userTypeEmployee
                  ? I18n.t("screen_messages.orders.place_order")
                  : I18n.t("screen_messages.common.pay")
              }
              buttonStyle="primary"
              onPress={() => {
                if (!loadingRef.current.loading) {
                  onCartCheckout();
                }
              }}
              titleColor={theme.primaryButtonText}
            />
          </View>
        </View>
      </View>
    </LoadingContainer>
  );
}
