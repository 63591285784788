import I18n from "i18n-js";
import { Alert, Platform, Dimensions } from "react-native";
import AddressObject from "../services/data_models/AddressObject";
import CartService from "../services/common/CartService";
import Qs from "qs";
import http from "../services/global/http";
import _ from "lodash";
import * as geolib from "geolib";
import moment from "moment";
import * as Location from "expo-location";
import * as Calendar from "expo-calendar";
import Config from "@presto-common/config";
import { colorConstants } from "../../src/constants/ColorConstants";

export function errorMessage(error) {
  if (typeof error?.message === "string") {
    return error?.message;
  } else {
    return I18n.t("error_messages.server_error");
  }
}

function alert(title, message, cancelText, cancellable = false) {
  Alert.alert(
    title,
    message,
    [
      {
        text: cancelText,
        style: "cancel",
      },
    ],
    { cancelable: cancellable }
  );
}

function formattedCurrencyCents(
  cents,
  fixed = true,
  currency = Config.merchant?.currency ||
    I18n.t("screen_messages.common.currency")
) {
  let num = cents / 100.0;
  if (fixed || Math.floor(num) !== num) {
    return `${currency} ${num?.toFixed(2)}`;
  } else {
    return `${currency} ${num}`;
  }
}

function configFormattedCurrencyCents({
  cents,
  fixed = true,
  currency = Config.merchant?.currency ||
    I18n.t("screen_messages.common.currency"),
}) {
  return formattedCurrencyCents(cents, fixed, currency);
}

function formattedCurrencySeparator(cents, divide = true) {
  let currency =
    Config.merchant?.currency || I18n.t("screen_messages.common.currency");
  let num = divide ? cents / 100.0 : cents;
  const totalprice = Math.round(num)?.toFixed(2);
  return `${currency} ${totalprice
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

function configFormattedCurrencySeparator({ cents, divide = true }) {
  return formattedCurrencySeparator(cents, divide);
}

function configFormattedCurrencyToRoundOff({
  cents,
  currency = Config.merchant?.currency ||
    I18n.t("screen_messages.common.currency"),
  showDecimal = false,
}) {
  return formattedCurrencyToRoundOff(cents, currency, showDecimal);
}

function formattedCurrencyToRoundOff(
  cents,
  currency = Config.merchant?.currency ||
    I18n.t("screen_messages.common.currency"),
  showDecimal = false
) {
  let num = cents / 100.0;
  let totalprice = !showDecimal ? Math.round(num) : Number(num).toFixed(2);
  totalprice = Number(totalprice);

  const priceCurrencyDecimalPoint = _.get(
    Config,
    "merchant_config.priceCurrencyDecimalPoint",
    ","
  );

  let pattern = `\\b[${priceCurrencyDecimalPoint}]\\b`;
  let regexPattern = new RegExp(pattern, "g");
  const hasDecimalPoint = totalprice.toString().match(regexPattern);

  if (!_.isEmpty(hasDecimalPoint)) {
    const replacerPoint = priceCurrencyDecimalPoint === "." ? "," : ".";
    //totalprice = totalprice.toString().replace(regexPattern, replacerPoint);
  }

  const replacerPoint = priceCurrencyDecimalPoint === "." ? "." : ",";

  if (Config.features.is_zambia_app) {
    return `${totalprice
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, replacerPoint)} ${currency}`;
  } else {
    return `${currency} ${totalprice
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, replacerPoint)}`;
  }
}

function formattedGiftPrice(price, noCurrency = false, separator = ".") {
  let price1 = price?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, `$&${separator}`);
  if (noCurrency) {
    return `${price1.substring(-1, price1.length - 3)}`;
  } else {
    return `${I18n.t("screen_messages.common.currency")} ${price1.substring(
      -1,
      price1.length - 3
    )}`;
  }
}

function getColorHexCode(colorName) {
  const colorHex = _.find(colorConstants.colors, (item) => {
    return _.includes(
      _.lowerCase(colorName),
      item["color_name"]?.toLowerCase()
    );
  });
  if (!_.isEmpty(colorHex)) {
    return colorHex.hexcode;
  } else {
    return "#fff";
  }
}

function actualCurrency(
  price,
  currency = Config.merchant?.currency ||
    I18n.t("screen_messages.common.currency")
) {
  let price1 = price?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return `${currency} ${price1}`;
}

function formattedCurrency(
  cents,
  currency = Config.merchant?.currency ||
    I18n.t("screen_messages.common.currency"),
  defaultCurrency
) {
  return `${defaultCurrency || currency} ${cents}`;
}

function formattedPriceRoundOff(price, showDecimal = false) {
  let totalprice = !showDecimal ? Math.round(price) : Number(price).toFixed(2);
  totalprice = Number(totalprice);
  let currency =
    Config.merchant?.currency || I18n.t("screen_messages.common.currency");
  if (_.has(this, "currency")) {
    currency = this.currency;
  }

  const priceCurrencyDecimalPoint = _.get(
    Config,
    "merchant_config.priceCurrencyDecimalPoint",
    ","
  );

  let pattern = `\\b[${priceCurrencyDecimalPoint}]\\b`;
  let regexPattern = new RegExp(pattern, "g");
  const hasDecimalPoint = totalprice.toString().match(regexPattern);

  if (!_.isEmpty(hasDecimalPoint)) {
    const replacerPoint = priceCurrencyDecimalPoint === "." ? "," : ".";
    totalprice = totalprice.toString().replace(regexPattern, replacerPoint);
  }

  if (Config.features.is_zambia_app) {
    const replacerPoint = priceCurrencyDecimalPoint === "." ? "." : ",";
    return `${totalprice
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, replacerPoint)} ${currency}`;
  } else {
    const replacerPoint = priceCurrencyDecimalPoint === "." ? "." : ",";
    return `${currency} ${totalprice
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, replacerPoint)}`;
  }
}

function formatPriceToLocal(price) {
  const totalprice = Math.round(price);
  return `${totalprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

function formattedPrice(price, showDecimal = true) {
  if (Math.floor(price) == price && !showDecimal) {
    if (Config.features.is_zambia_app) {
      return `${price} ${
        Config.merchant?.currency || I18n.t("screen_messages.common.currency")
      }`;
    } else {
      return `${
        Config.merchant?.currency || I18n.t("screen_messages.common.currency")
      } ${price}`;
    }
  } else {
    if (Config.features.is_zambia_app) {
      return `${price?.toFixed(2)} ${
        Config.merchant?.currency || I18n.t("screen_messages.common.currency")
      }`;
    } else {
      return `${
        Config.merchant?.currency || I18n.t("screen_messages.common.currency")
      } ${price?.toFixed(2)}`;
    }
  }
}

function formattedPriceWithoutCurrency(price, showDecimal = true) {
  if (Math.floor(price) == price && !showDecimal) {
    return `${price}`;
  } else {
    return ` ${price?.toFixed(2)}`;
  }
}

function getAddressFromGoogleResult(address, location) {
  var addressObj = new AddressObject();

  if (address.address_components) {
    address.address_components.forEach(function (component, index) {
      var typeString = component.types.toString();
      if (
        typeString.indexOf("premise") >= 0 ||
        typeString.indexOf("street_number") >= 0
      ) {
        if (addressObj.line1.indexOf(component.long_name) == -1) {
          if (addressObj.line1 != "") {
            addressObj.line1 += ", ";
          }
          addressObj.line1 += component.long_name;
        }
      } else if (
        typeString.indexOf("sublocality_level_1") >= 0 ||
        typeString.indexOf("sublocality_level_2") >= 0 ||
        typeString.indexOf("route") >= 0
      ) {
        if (addressObj.line2.indexOf(component.long_name) == -1) {
          if (addressObj.line2 != "") {
            addressObj.line2 += ", ";
          }
          addressObj.line2 += component.long_name;
        }
      } else if (
        typeString.indexOf("sublocality") >= 0 ||
        typeString.indexOf("postal_town") >= 0 ||
        typeString.indexOf("locality") >= 0 ||
        typeString.indexOf("natural_feature" >= 0)
      ) {
        if (addressObj.area.indexOf(component.long_name) == -1) {
          if (addressObj.area != "") {
            addressObj.area += ", ";
          }
          addressObj.area += component.long_name;
        }
      }
      if (typeString.indexOf("postal_code") >= 0) {
        addressObj.pincode += component.long_name;
      }
      if (
        typeString.indexOf("administrative_area_level_2") >= 0 ||
        typeString.indexOf("administrative_area_level_3") >= 0
      ) {
        addressObj.city = component.long_name;
      }
      if (typeString.indexOf("country") >= 0) {
        addressObj.country += component.long_name;
      }
      if (typeString.indexOf("administrative_area_level_1") >= 0) {
        addressObj.state += component.long_name;
      }
    });
  }
  if (addressObj.line1 === "") {
    addressObj.line1 = address.name;
  }
  console.log("location in utils", location);
  if (location && location.latitude && location.longitude) {
    addressObj.location = [location.latitude, location.longitude];
  }
  return addressObj;
}

function getAddressFromNativeResult(address, location) {
  var addressObj = new AddressObject();
  addressObj.line1 = address.name;
  addressObj.line2 = address.street;
  addressObj.area = address.district;
  addressObj.pincode = address.postalCode;
  addressObj.country = address.country;
  addressObj.state = address.region;
  addressObj.city = address.city;
  if (location && location.latitude && location.longitude) {
    addressObj.location = [location.latitude, location.longitude];
  }
  return addressObj;
}

function reverseGeocode(coords) {
  const osConfig = Platform.select({
    ios: { key: Config.ios.googleMapsKey },
    android: { key: Config.android.googleMapsKey },
    web: { key: Config.web.mapsApiKey },
  });
  Location.setGoogleApiKey(osConfig.key);
  return Location.reverseGeocodeAsync(coords)
    .then((addresses) => {
      if (addresses.length > 0) {
        let addressObject = getAddressFromNativeResult(addresses[0], coords);
        return addressObject;
      } else return null;
    })
    .catch((error) => {
      throw error;
    });
}

function webReverseGeocodeGoogle(coords) {
  const geocoder = new google.maps.Geocoder();
  const latlng = {
    lat: coords.latitude,
    lng: coords.longitude,
  };

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        let addressObject = getAddressFromGoogleResult(results[0], coords);
        resolve(addressObject);
      } else {
        reject(new Error("Unable to fetch current location"));
      }
    });
  });
}

function getOutletWithinDeliveryZone(
  address,
  outlets,
  successCallback,
  errorCallback
) {
  var outletWithinDeliveryZone = undefined;
  if (outlets && address && address.location && address.location.length > 0) {
    var coords = {};
    coords.latitude = address.location[0];
    coords.longitude = address.location[1];
    var sortedOutlets = sortOutletsByDistance(outlets, coords);
    if (!appConfig.merchant_config.features.delivery_based_on_serving_area) {
      for (var i = 0; i < sortedOutlets.length; i++) {
        if (
          sortedOutlets[i].delivery_radius >=
          sortedOutlets[i].distance / 1000
        ) {
          outletWithinDeliveryZone = sortedOutlets[i];
          break;
        }
      }
      if (successCallback) {
        successCallback(outletWithinDeliveryZone);
      }
    } else {
      ProgressBarService.showLoading();
      console.log("getServingOutletsaddress");
      console.log(address);
      if (address.pincode) {
        OutletManager.getServingOutlets(
          address.pincode,
          function (getServingOutletsResponse) {
            console.log("onSuccess() getServingOutletsResponse=");
            var servingOutlets = getServingOutletsResponse.data;
            console.log(servingOutlets);
            ProgressBarService.stopLoading();
            if (successCallback) {
              successCallback(servingOutlets[0]);
            }
          },
          function (error) {
            ProgressBarService.stopLoading();
            if (errorCallback) {
              errorCallback(error);
            }
          }
        );
      } else {
        ProgressBarService.stopLoading();
        successCallback(outletWithinDeliveryZone);
      }
    }
  }
}

function isAddressWithinDeliveryZone(address, outlets) {
  var outletWithinDeliveryZone = getOutletWithinDeliveryZone(address, outlets);
  if (outletWithinDeliveryZone) {
    return true;
  } else {
    return false;
  }
}

function getMerchantWithinDeliveryZone(address, merchants) {
  var addressWithinDeliveryZone = undefined;
  if (address.location && address.location.length > 2) {
    address.location = address.location.split(",");
  }
  if (merchants && address && address.location && address.location.length > 0) {
    var coords = {};
    coords.latitude = address.location[0];
    coords.longitude = address.location[1];
    //console.log('Geo distance : ', coords, merchants[0], geolib.getDistance(merchants[0], coords))
    var sortedOutlets = sortOutletsByDistance(merchants, coords);
    //console.log('sorted outlets : ', sortedOutlets);
    for (var i = 0; i < sortedOutlets.length; i++) {
      const distance = geolib.getDistance(sortedOutlets[i], coords) / 1000;
      console.log(
        "Delivery radius : ",
        sortedOutlets[i].other_data.delivery_radius,
        distance
      );
      if (sortedOutlets[i].other_data.delivery_radius >= distance) {
        addressWithinDeliveryZone = sortedOutlets[i];
        break;
      }
    }
    return addressWithinDeliveryZone;
  } else {
    return addressWithinDeliveryZone;
  }
}

function getAddressesWithinDeliveryZone(merchant, addresses) {
  if (merchant && addresses) {
    for (var i = 0; i < addresses.length; i++) {
      const distance = geolib.getDistance(merchant, addresses[i]) / 1000;
      addresses[i].distance = distance;
    }
    return addresses;
  } else {
    return addresses;
  }
}

function isUserAddressWithinDeliveryZone(address, merchant) {
  var merchants = [];
  if (
    !merchant.location ||
    !Array.isArray(merchant.location) ||
    merchant.location.length < 2
  ) {
    return false;
  }
  merchant.latitude = merchant.location[1];
  merchant.longitude = merchant.location[0];
  merchants.push(merchant);

  var result = getMerchantWithinDeliveryZone(address, merchants);
  return result;
}

function sortOutletsByDistance(outlets, coords) {
  console.log("coordinates=" + JSON.stringify(coords));
  if (coords && outlets) {
    var sortedOutlets = geolib.orderByDistance(
      {
        latitude: coords.latitude,
        longitude: coords.longitude,
      },
      outlets
    );
    return sortedOutlets;
  } else {
    return outlets;
  }
}

function sortAddressesByDistance(origin, addresses) {
  console.log("coordinates=" + JSON.stringify(addresses));
  if (origin && addresses) {
    var sortedOutlets = geolib.orderByDistance(origin, addresses);
    return sortedOutlets;
  } else {
    return addresses;
  }
}

function getCities(outlets) {
  var citiesMap = {};
  var uniqueCities = [];
  for (var i = 0; i < outlets.length; i++) {
    console.log("outlets[i].address.city=" + outlets[i].address.city);
    if (!(outlets[i].address.city in citiesMap)) {
      citiesMap[outlets[i].address.city] = "";
    }
  }
  console.log("citiesMap=" + JSON.stringify(citiesMap));
  //Valid only on ES5 supporting browsers
  //uniqueCities = Object.keys(citiesMap);
  for (var city in citiesMap) {
    uniqueCities.push(city);
  }
  console.log("uniqueCities=" + JSON.stringify(uniqueCities));
  return uniqueCities;
}

function getAppointmentDateAsIsoString(date, choosenSlot) {
  if (!date) {
    return "";
  } else {
    if (choosenSlot) {
      date.setHours(choosenSlot.start_hour);
      date.setMinutes(choosenSlot.start_minute);
      return date.toISOString();
    } else {
      return date.toISOString();
    }
  }
}

function getChoosenServices() {
  var choosenServices = [];
  var cartItems = CartService.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        choosenServices.push({
          service_id: cartItems[itemId].product.id,
          quantity: cartItems[itemId].count,
        });
      }
    }
  }
  return choosenServices;
}

function getLineItems(cart, appConfig) {
  var lineItems = [];
  var cartItems = cart.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        var variation = cartItems[itemId].getVariation();
        var variationId = null;
        if (variation) {
          variationId = variation.id;
        }
        var other_data = cartItems[itemId].product.other_data;
        if (variation && variation.other_data) {
          other_data = variation.other_data;
        }
        var lItem = {
          quantity: cartItems[itemId].count,
          total_price: cartItems[itemId].getPrice(),
          variation_id: variationId,
          other_data: other_data,
        };
        if (other_data.instructions) {
          lItem["instructions"] = other_data.instructions;
        }
        console.log(
          "Market Place - " + appConfig.merchant_config.features.market_place
        );
        if (appConfig.merchant_config.features.market_place) {
          lItem.seller_item_id = cartItems[itemId].product.id;
          lItem.seller_id = cartItems[itemId].product.seller_id;
        } else {
          lItem.item_id = cartItems[itemId].product.id;
        }
        if (lItem.quantity > 0) {
          lineItems.push(lItem);
        }
      }
    }
  }
  return lineItems;
}

function getOutletBasedLineItems(cart) {
  var outletBasedItems = {};

  var lineItems = [];
  var cartItems = cart.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        console.log("Item - ");
        console.log(cartItems[itemId]);
        var variation = cartItems[itemId].getVariation();
        var variationId = null;
        outletId = cartItems[itemId].product.other_data.vendor_id;
        if (variation) {
          variationId = variation.other_data.vendor_item_variation_id;
        }
        if (!outletBasedItems[outletId]) {
          outletBasedItems[outletId] = [];
        }
        outletBasedItems[outletId].push({
          item_id: cartItems[itemId].product.other_data.vendor_item_id,
          quantity: cartItems[itemId].count,
          total_price: cartItems[itemId].getPrice(),
          variation_id: variationId,
        });
      }
    }
  }
  console.log(outletBasedItems);
  return outletBasedItems;
}

function getServiceIds(cart) {
  var serviceIds = [];
  var cartItems = cart.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      serviceIds.push(itemId);
    }
  }
  return serviceIds;
}

function isCartLimitReached(cart) {
  return cart.isCartLimitReached();
}

function getServices(cart) {
  var serviceNames = [];
  var cartItems = cart.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        serviceNames.push(cartItems[itemId].product.name);
      }
    }
  }
  return serviceNames;
}

function getLocationAsString(locationArray) {
  if (locationArray && locationArray.length >= 1) {
    return locationArray[0] + "," + locationArray[1];
  } else {
    return "";
  }
}

//Checks for all mandatory address fields
function isValidAddress(address, appConfig) {
  if (appConfig.merchant_config.features.island_mandatory) {
    if (address && address.reference_id && address.reference_id != "") {
      return true;
    } else {
      // console.log(address);
      return false;
    }
  } else {
    if (
      address &&
      !Utils.isEmpty(address.line1) &&
      !Utils.isEmpty(address.pincode) &&
      address.location &&
      address.location.length > 0
    ) {
      return true;
    } else {
      // console.log(address);
      return false;
    }
  }
}

//get valid address
function getValidAddress(addresses, appConfig) {
  for (var i = 0; i <= addresses.length; i++) {
    if (isValidAddress(addresses[i], appConfig)) {
      return addresses[i];
    }
  }
}

function getLineItemsForSubscription() {
  var lineItems = [];
  var cartItems = SubscriptionCartService.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        var variation = cartItems[itemId].getVariation();
        var variationId = null;
        var instructions = "";
        if (variation) {
          variationId = variation.id;
          instructions = variation.instructions;
        }
        lineItems.push({
          item_id: cartItems[itemId].product.id,
          quantity: cartItems[itemId].count,
          total_price: cartItems[itemId].getPrice(),
          variation_id: variationId,
          instructions: instructions,
        });
      }
    }
  }
  return lineItems;
}

function getCategoryById(catalog, categoryId) {
  var matchedCategory = null;
  if (catalog && catalog.categories && catalog.categories.length > 0) {
    var categories = catalog.categories;
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].id === categoryId) {
        matchedCategory = categories[i];
        break;
      }
    }
  }
  return matchedCategory;
}

function getLinkedAccount(linkedAccounts, provider) {
  for (var i = 0; i < linkedAccounts.length; i++) {
    if (
      linkedAccounts[i].provider_name == provider &&
      linkedAccounts[i].selected
    ) {
      return linkedAccounts[i];
    }
  }
}

function findItemById(data, item) {
  for (var j = 0; j < data.length; j++) {
    if (data[j].id == item.id) {
      return item;
    }
  }
  return null;
}

function getObjectLength(obj) {
  var length = 0;
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      length++;
    }
  }
  return length;
}

function getAddressSpecificBins(addressId, binList) {
  var binArray = [];
  for (var i = 0; i < binList.length; i++) {
    if (addressId == binList[i].other_data.address.id) {
      binArray.push(binList[i]);
    }
  }
  return binArray;
}

function getSelectedAddress(userProfile) {
  console.log("userProfile");
  if (!userProfile || userProfile.addresses.length == 0) {
    return null;
  }
  console.log(userProfile.addresses);
  if (
    localStorage.selectedAddress &&
    localStorage.selectedAddress != null &&
    localStorage.selectedAddress != "null"
  ) {
    var selectedAddress = JSON.parse(localStorage.selectedAddress);
    for (var i = 0; i < userProfile.addresses.length; i++) {
      if (userProfile.addresses[i].id == selectedAddress.id) {
        localStorage.selectedAddress = JSON.stringify(userProfile.addresses[i]);
        return userProfile.addresses[i];
      }
    }
  }
  localStorage.selectedAddress = JSON.stringify(userProfile.addresses[0]);
  return userProfile.addresses[0];
}

function getItemIdFromOrderLineItems(items) {
  for (var itemId in items) {
    if (items.hasOwnProperty(itemId)) {
      return items[itemId].item_id.$oid;
    }
  }
}

function removeExpiredItemFromCart(cart, count, cartItem) {
  count = -1 * count;
  if (cartItem && cartItem.itemId) {
    cart.updateItem(cartItem.itemId, count, true);
    cart.removeItem(cartItem.itemId);
    return true;
  } else {
    return false;
  }
}

function isExpiredItem(item) {
  var date = Date.now();
  var diff = (date - item.added_at) / 1000 / 60;
  if (diff > appConfig.merchant_config.features.expiry_time_in_cart) {
    return true;
  } else {
    return false;
  }
}

function isSimItem(item) {
  if (
    item &&
    item.variation &&
    item.variation.other_data &&
    item.variation.other_data.msisdn_selected
  ) {
    return true;
  } else {
    return false;
  }
}

function getAddressString(address) {
  var addressString = "";
  if (!address) {
    return "";
  }
  addressString += address.line1;
  if (address.line2 && address.line2 != "") {
    addressString += ", " + address.line2;
  }
  if (address.city && address.city != "") {
    addressString += ", " + address.city;
  }
  if (address.state && address.state != "") {
    addressString += ", " + address.state;
  }
  if (address.country && address.country != "") {
    addressString += ", " + address.country;
  }
  if (address.pincode && address.pincode != "") {
    addressString += ", " + address.pincode;
  }
  return addressString;
}

function checkItemInCart(id, cart) {
  var cartItems = cart.getItems();
  var result = false;
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        if (id == cartItems[itemId].product.id) {
          result = true;
        } else {
          result = false;
        }
      }
    }
  }
  return result;
}

function isItemPresent(cartItems, item) {
  var result = false;
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        if (item.id == cartItems[itemId].itemId) {
          result = true;
        } else {
          result = false;
        }
      }
    }
  }
  return result;
}

function generateRandomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

var variationUtils = {
  createVariationsTree: function (variationArg) {
    if (!variationArg) {
      return null;
    }
    var variations = angular.copy(variationArg);
    for (var i = 0; i < variations.length; i++) {
      if (variations[i].outer_variation_id) {
        this.findParent(variations, variations[i]);
        variations.splice(i, 1);
        i--;
      }
    }
    return variations;
  },

  findParent: function (variations, variation) {
    for (var i = 0; i < variations.length; i++) {
      if (variation.outer_variation_id == variations[i].id) {
        !variations[i].inner_variations &&
          (variations[i].inner_variations = []);
        variations[i].inner_variations.push(variation);
        return true;
      } else if (variations[i].inner_variations) {
        this.findParent(variations[i].inner_variations, variation);
      }
    }
  },
};

//DETERMINE IF THE COLOR IS LIGHT OR DARK.
//IF THE COLOR IS LIGHT, RETURN LIGHT, IF THE COLOR IS DARK, RETURN DARK
function lightOrDark(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 150) {
    return "light";
  } else {
    return "dark";
  }
}

function isTicketFlow() {
  var cartItems = CartService.getItems();
  var result = false;
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        if (cartItems[itemId].product.other_data.live_link) {
          result = true;
        } else {
          result = false;
        }
      }
    }
  }
  return result;
}

function hasTicketItem(items) {
  var result = false;
  if (items) {
    for (var itemId in items) {
      if (items.hasOwnProperty(itemId)) {
        if (items[itemId].other_data.live_link) {
          result = true;
        } else {
          result = false;
        }
      }
    }
  }
  return result;
}

function isDeliveryChargeItemAvailable(items) {
  var result = false;
  if (items) {
    for (var itemId in items) {
      if (items.hasOwnProperty(itemId)) {
        if (items[itemId].reference_id == "DELIVERY_CHARGE") {
          return true;
        }
      }
    }
  }
  return result;
}

function getTotalUbuCashBack(items) {
  var amount = 0;
  if (items) {
    for (var itemId in items) {
      if (items.hasOwnProperty(itemId)) {
        if (
          items[itemId].reference_id != "DELIVERY_CHARGE" &&
          items[itemId].reference_id != "TIPS"
        ) {
          amount += items[itemId].total_price;
        }
      }
    }
    return (amount * 10) / 100;
  }
}

function removeInvalidItems() {
  var cartItems = CartService.getItems();
  if (cartItems) {
    for (var itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        if (
          cartItems[itemId].product.reference_id == "TIPS" ||
          cartItems[itemId].product.reference_id == "DELIVERY_CHARGE" ||
          cartItems[itemId].product.reference_id == "CUSTOM_PAYMENT"
        ) {
          count = -1 * cartItems[itemId].count;
          CartService.updateItem(cartItems[itemId].itemId, count, true);
          CartService.removeItem(cartItems[itemId].itemId);
        }
      }
    }
    $rootScope.$broadcast("ON_CART_ITEM_UPDATED");
  }
}

function createMerchantObjectsFromSearchResults(rawCategories) {
  return _.map(rawCategories, (rawCategory) => {
    //let a = new CategoryObject();
    //a.buildObject(rawCategory._source);
    //console.log('hello');
    //console.log('A : ', a);

    return rawCategory._source;
  });
}

function formattedDate(time) {
  return moment(time).format("MMM DD, yyyy");
}

function formattedCouponTime(time) {
  return moment(time).format("MMM DD, yyyy h:mm a");
}

function formatTime(time) {
  return moment(time).format("h:mm a DD/MM/yyyy");
}

function amountToString(amount) {
  let num = amount / 100.0;
  if (fixed || Math.floor(num) !== num) {
    return num?.toFixed(2);
  } else {
    return `${num}`;
  }
}

function convertPaisaToRupees(amount) {
  amount = Number(amount);
  let num = !isNaN(amount) ? Number(parseFloat(amount / 100)?.toFixed(2)) : 0;
  return num;
}

async function addToCalendar(ticketEvent) {
  const { status } = await Calendar.requestCalendarPermissionsAsync();
  if (status !== "granted") {
    throw new Error(
      "Please enable calendar permissions for the app and try again later"
    );
  }
  const calendars = await Calendar.getCalendarsAsync(
    Calendar.EntityTypes.EVENT
  );

  console.log("Calenders length ", calendars);
  if (calendars.length < 1) {
    throw new Error(
      "No calendars in your system. Please create a calendar and try it again"
    );
  }

  let calendar = undefined;

  if (Platform.OS == "ios") {
    const defaultCalendars = calendars.filter((each) => {
      console.log("each source name : ", each);
      return each.source.name === "Default";
    });
    const ownedCalendars = calendars.filter((each) => {
      console.log("each source name : ", each);
      return each.allowsModifications;
    });
    console.log(
      "Calends :",
      defaultCalendars[0],
      ownedCalendars[0],
      calendars[0]
    );
    calendar = defaultCalendars[0] || ownedCalendars[0];
  } else {
    const ownedCalendars = calendars.filter((each) => {
      console.log("each source name : ", each);
      return each.accessLevel === "owner";
    });
    if (ownedCalendars.length > 0) {
      const googleCalendars = ownedCalendars.filter((each) => {
        return each.name === "Google";
      });
      calendar = googleCalendars[0] || ownedCalendars[0];
    } else {
      throw new Error("We could not find calendar to add the event to.");
    }
  }

  console.log("Calendar chosen : ", calendar);

  if (!calendar) {
    throw new Error("Unable to find a editable Calendar to add event in.");
  }

  const eventDetails = {
    title: ticketEvent.name,
    startDate: moment(ticketEvent.start_time).toDate(),
    endDate: moment(ticketEvent.end_time).toDate(),
  };

  try {
    await Calendar.createEventAsync(calendar.id, eventDetails);
    return calendar.title;
  } catch (error) {
    throw new Error("Unable to Create the event.");
  }
}

function getMerchantAddress(address) {
  if (!address) {
    return "";
  }
  var str = "";
  if (address.line1 && address.line1 != " " && address.line1 != "") {
    str += address.line1;
  }
  if (address.line2 && address.line2 != " " && address.line2 != "") {
    str += ", " + address.line2;
  }
  if (address.area && address.area != " " && address.area != "") {
    str += ", " + address.area;
  }
  if (address.city && address.city != " " && address.city != "") {
    str += ", " + address.city;
  }
  if (address.state && address.state != " " && address.state != "") {
    str += ", " + address.state;
  }
  if (address.country && address.country != " " && address.country != "") {
    str += ", " + address.country;
  }
  if (address.pincode && address.pincode != " " && address.pincode != "") {
    str += ", " + address.pincode;
  }
  return str;
}

function isMobile() {
  return Platform.OS == "ios" || Platform.OS == "android";
}

function isMobileWeb() {
  return !isDesktop() && isWeb();
}

function isNextJsApp() {
  return isWeb() && window && window.next;
}

function isWeb() {
  return Platform.OS == "web";
}

function isDesktop() {
  return Dimensions.get("window").width > (Config.mobile_web_max_width || 939);
}

function isValidEmail(email) {
  console.log("isValidEmail(), email=" + email);
  var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email) {
    return emailPattern.test(email);
  } else {
    return false;
  }
}

function isValidPhone(phone) {
  console.log("isValidPhone(), phone=" + phone);
  var phonePattern = /^\d{10}$/;
  if (phone) {
    return phonePattern.test(phone);
  } else {
    return false;
  }
}

function getDefaultCurrency(data) {
  if (data?.other_data?.currency) {
    return data?.other_data?.currency;
  }
}

// change the function create-site-map script when ever you change this.
function wordsToURLComponent(data) {
  const words = _.filter(_.split(data, " "), (x) => !_.isEmpty(x));
  const urlEscapedWords = _.map(words, encodeURIComponent);
  return _.join(urlEscapedWords, "-");
}

export default {
  alert,
  errorMessage,
  configFormattedCurrencyCents,
  configFormattedCurrencySeparator,
  configFormattedCurrencyToRoundOff,
  formattedCurrencyCents,
  formattedCurrencySeparator,
  formattedPrice,
  reverseGeocode,
  webReverseGeocodeGoogle,
  getAddressFromGoogleResult,
  createMerchantObjectsFromSearchResults,
  formattedCurrencyToRoundOff,
  formattedPriceRoundOff,
  formattedCurrency,
  actualCurrency,
  formattedGiftPrice,
  formatPriceToLocal,
  formattedPriceWithoutCurrency,
  sortOutletsByDistance: sortOutletsByDistance,
  getCities: getCities,
  getServices: getServices,
  getServiceIds: getServiceIds,
  getChoosenServices: getChoosenServices,
  getAppointmentDateAsIsoString: getAppointmentDateAsIsoString,
  getLineItems: getLineItems,
  getOutletWithinDeliveryZone: getOutletWithinDeliveryZone,
  getLocationAsString: getLocationAsString,
  isAddressWithinDeliveryZone: isAddressWithinDeliveryZone,
  isValidAddress: isValidAddress,
  getLineItemsForSubscription: getLineItemsForSubscription,
  getOutletBasedLineItems: getOutletBasedLineItems,
  getCategoryById: getCategoryById,
  getLinkedAccount: getLinkedAccount,
  isCartLimitReached: isCartLimitReached,
  findItemById: findItemById,
  getObjectLength: getObjectLength,
  getValidAddress: getValidAddress,
  getAddressSpecificBins: getAddressSpecificBins,
  getSelectedAddress: getSelectedAddress,
  getItemIdFromOrderLineItems: getItemIdFromOrderLineItems,
  removeExpiredItemFromCart: removeExpiredItemFromCart,
  isExpiredItem: isExpiredItem,
  isSimItem: isSimItem,
  getAddressString: getAddressString,
  checkItemInCart: checkItemInCart,
  generateRandomString: generateRandomString,
  variationUtils: variationUtils,
  formattedCouponTime: formattedCouponTime,
  lightOrDark: lightOrDark,
  isTicketFlow: isTicketFlow,
  hasTicketItem: hasTicketItem,
  isDeliveryChargeItemAvailable: isDeliveryChargeItemAvailable,
  getTotalUbuCashBack: getTotalUbuCashBack,
  isUserAddressWithinDeliveryZone: isUserAddressWithinDeliveryZone,
  removeInvalidItems: removeInvalidItems,
  formatTime: formatTime,
  formattedDate: formattedDate,
  addToCalendar: addToCalendar,
  sortAddressesByDistance: sortAddressesByDistance,
  getAddressesWithinDeliveryZone: getAddressesWithinDeliveryZone,
  getMerchantAddress: getMerchantAddress,
  convertPaisaToRupees: convertPaisaToRupees,
  isMobile: isMobile,
  isWeb: isWeb,
  isValidEmail: isValidEmail,
  isValidPhone: isValidPhone,
  isItemPresent: isItemPresent,
  isDesktop: isDesktop,
  isMobileWeb: isMobileWeb,
  isNextJsApp: isNextJsApp,
  amountToString: amountToString,
  getDefaultCurrency,
  getColorHexCode: getColorHexCode,
  wordsToURLComponent: wordsToURLComponent,
};
