import $ from "../global/util";

const ServiceCartObject = function (MediaItemObject) {
  function ServiceCartObject() {
    this.type = "";
    this.instructions = "";
    this.service_count = "";
    this.sub_total_price = "";
    this.total_charges = 0;
    this.total_taxes = 0;
    this.total_discounts = 0;
    this.total_price = 0;
    this.charges = [];
    this.taxes = [];
    this.discounts = [];
    this.coupon_code = "";
    this.id = "";
    this.user_id = "";
    this.merchant_id = "";
    this.outlet_id = "";
    this.address = {};
    this.payment_type = "";
    this.delivery_type = "";
    this.services = [];
    this.other_data = {};
    this.source = "";
    this.discount_response = {};
    this.checkout_detail = {};
    this.original_gross_amount = "";
  }

  ServiceCartObject.prototype = {
    validateObject: function () {},

    buildObject: function (rawObject) {
      this.type = rawObject.type;
      this.instructions = rawObject.instructions;
      this.service_count = rawObject.services_count;
      this.sub_total_price = rawObject.sub_total_price;
      this.total_charges = rawObject.total_charges;
      this.total_taxes = rawObject.total_taxes_amount;
      this.total_discounts = rawObject.total_discounts_amount;
      this.total_price = rawObject.total_price_amount;
      this.charges = rawObject.charges;
      this.taxes = rawObject.taxes;
      this.discounts = rawObject.discounts;
      this.coupon_code = rawObject.coupon_code;
      this.id = rawObject.id;
      this.user_id = rawObject.user_id;
      this.merchant_id = rawObject.merchant_id;
      this.outlet_id = rawObject.outlet_id;
      this.address = rawObject.address;
      this.payment_type = rawObject.payment_type;
      this.delivery_type = rawObject.delivery_type;
      this.services = rawObject.service_items;
      this.other_data = rawObject.other_data;
      this.checkout_detail = rawObject.checkout_detail;
      this.source = rawObject.source;
      this.discount_response = rawObject.discount_response;
      this.original_gross_amount = rawObject.original_gross_amount || "";
    },
  };

  return ServiceCartObject;
};

export default ServiceCartObject();
