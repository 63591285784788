import Config from "@presto-common/config";

class PrestoSdk {
  initialize() {
    this.appConfig = Config;
  }

  getHostName() {
    if (this.appConfig) {
      return this.appConfig.server_config.host;
    } else {
      return undefined;
    }
  }

  getAppId() {
    if (this.appConfig) {
      return this.appConfig.server_config.app_id;
    } else {
      return undefined;
    }
  }

  getMerchantId() {
    // console.log("PrestoSdk MerchatId : ", this.appConfig.server_config);
    if (this.appConfig) {
      return this.appConfig.server_config.merchant_id;
    } else {
      return undefined;
    }
  }

  getGcmSenderId() {
    if (this.appConfig) {
      return this.appConfig.merchant_config.mobile_app_config.gcm_sender_id;
    } else {
      return undefined;
    }
  }

  getAppConfig() {
    return this.appConfig;
  }

  /*
  updateSuccessMessages(language) {
    var key = "success_messages_" + language;
    this.appConfig.merchant_config[
      "success_messages"
    ] = this.appConfig.merchant_config[key];
  }

  updateErrorMessages(language) {
    var key = "error_messages_" + language;
    this.appConfig.merchant_config[
      "error_messages"
    ] = this.appConfig.merchant_config[key];
  }

  updateScreenMessages(language) {
    var key = "screen_messages_" + language;
    this.appConfig.merchant_config[
      "screen_messages"
    ] = this.appConfig.merchant_config[key];
  } */

  getPrestoPaymentFileUrl() {
    if (this.appConfig) {
      return this.appConfig.server_config.presto_payment_file;
    } else {
      return undefined;
    }
  }
}

export default new PrestoSdk();
