import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        transform="translate(7 7)"
        stroke={props.color || "#000"}
        strokeWidth={1.2}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          strokeLinejoin="round"
          d="M3.24154298 16.4235835L3.24154298 26 7.24026291 26 7.24026291 16.8712113 7.24026291 5.73622793 21.5399783 0 21.5399783 21.1884387 7.24026291 15.4686151"
        />
        <Path d="M16.6121736 2.30417787L16.6121736 19.2078834" />
        <Path d="M21.54 7.395c2.307.966 3.46 2.086 3.46 3.361 0 1.275-1.153 2.654-3.46 4.136" />
        <Path
          d="M7.24 5.736H2.18c-.402.13-.71.319-.925.565C.387 7.296.018 8.85.018 10.2c0 1.229-.173 3.41.544 4.691.244.434.783.812 1.618 1.134h4.675M7.96 20.722c.905.15 1.569-.037 1.99-.563.421-.526.58-1.456.476-2.79"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
