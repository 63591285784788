import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)" fill={props.primaryColor || "#FFCF31"}>
        <Path
          opacity={0.9}
          d="M17.625 24.504h-.064c-2.058 0-4.13-1.6-5.643-2.77-.246-.19-10.132-7.436-11.825-8.76v14.812a3.281 3.281 0 003.28 3.28h28.438a3.281 3.281 0 003.282-3.28v-14.81c-1.672 1.31-11.58 8.568-11.827 8.758-1.512 1.17-3.584 2.77-5.641 2.77zM31.81 4.817H3.374A3.281 3.281 0 00.093 8.098v1.299a1.646 1.646 0 00.629 1.292c2.091 1.634 2.782 2.215 11.853 8.798 1.149.834 3.432 2.857 5.018 2.83 1.586.027 3.869-1.996 5.017-2.83 9.072-6.583 9.762-7.157 11.854-8.798a1.636 1.636 0 00.629-1.292V8.098a3.282 3.282 0 00-3.282-3.281z"
        />
        <Path
          opacity={0.5}
          d="M35.093 9.397v3.58c-1.672 1.31-11.58 8.567-11.827 8.758-1.512 1.168-3.584 2.77-5.641 2.77h-.064c-2.058 0-4.13-1.602-5.643-2.77-.246-.19-10.132-7.437-11.825-8.76V9.396a1.646 1.646 0 00.629 1.292c2.091 1.634 2.782 2.215 11.853 8.798 1.141.828 3.401 2.83 4.986 2.83h.064c1.584 0 3.844-2.002 4.985-2.83 9.072-6.583 9.762-7.157 11.854-8.798a1.636 1.636 0 00.629-1.292z"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill="#fff"
            transform="translate(.093 .442)"
            d="M0 0h35v35H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
