import React, { useState, useEffect } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import _ from "lodash";
import * as RNLocalize from "react-native-localize";
import PrestoText from "@presto-components/PrestoText";

function count(str, ch) {
  return _.countBy(str)[ch] || 0;
}

export default function PrestoAmountInput(props) {
  const settings = RNLocalize.getNumberFormatSettings();
  const separator = settings.decimalSeparator || ".";
  let [amount, setAmount] = useState(props.value || "");

  useEffect(() => {
    setAmount(props.value);
  }, [props.value]);

  const setAmountValue = (val) => {
    setAmount(val);
    props.onAmountChange(val);
  };

  const onTextChange = (text) => {
    text = _.replace(text, separator, ".");
    console.log("Text: ", text, count(text, "."), _.findLastIndex(text, "."));
    if (_.isNaN(+text)) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }
    if (text[text.length - 1] === "." && count(text, ".") > 1) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }

    if (
      _.lastIndexOf(text, ".") !== -1 &&
      text.length - _.lastIndexOf(text, ".") > 3
    ) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }
    setAmountValue(text);
  };
  return (
    <View style={defaultStyles.container}>
      <View style={defaultStyles.labelContainer}>
        <PrestoText extraStyle={defaultStyles.label}>
          {props.labelText}
        </PrestoText>
      </View>

      <View style={defaultStyles.textinputContainer}>
        <TextInput
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={props.textInputPlaceholderColor}
          {...props}
          value={_.replace(amount, ".", separator)}
          onChangeText={onTextChange}
          style={defaultStyles.textInputStyle}
        ></TextInput>
      </View>
    </View>
  );
}

const defaultStyles = {
  container: {
    width: "100%",
  },
  labelContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingBottom: 6,
  },
  label: {
    fontSize: 12,
    color: "#333333",
    width: "100%",
  },
  textInputStyle: {
    width: "100%",
    height: 50,
    borderWidth: 0,
    borderRadius: 5,
    fontSize: 14,
    padding: 10,
    outline: "none",
  },
  textinputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 40,
    borderRadius: 4,
    borderColor: "#CCCCCC",
    backgroundColor: "#E3E3E3",
  },
};
