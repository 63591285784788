import $http from "../../global/http";
import SessionConstants from "./SessionConstants";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const SessionResource = function ($http, SessionConstants, PrestoSdk) {
  $http.defaults.withCredentials = true;

  return {
    login: function (loginObject) {
      console.log("SessionResource.Login");
      var url = PrestoSdk.getHostName() + SessionConstants.LOGIN.PATH;
      return $http.post(url, loginObject);
    },

    logout: function () {
      var url = PrestoSdk.getHostName() + SessionConstants.LOGOUT.PATH;
      var appConfig = PrestoSdk.getAppConfig();
      if (appConfig.merchant_config.features.sign_out_via_post) {
        return $http.post(url, { _method: "delete" });
      } else {
        return $http.delete(url);
      }
    },

    socialLogin: function (params) {
      var url = PrestoSdk.getHostName() + "/users/social_token_login.json";
      return $http.post(url, params);
    },

    forgotPassword: function (forgotPasswordObject) {
      var url = PrestoSdk.getHostName() + SessionConstants.FORGOT_PASSWORD.PATH;
      return $http.post(url, forgotPasswordObject);
    },

    resetPassword: function (resetPasswordObject) {
      var url = PrestoSdk.getHostName() + SessionConstants.RESET_PASSWORD.PATH;
      return $http.post(url, resetPasswordObject);
    },

    signup: function (user) {
      var url = PrestoSdk.getHostName() + SessionConstants.SIGNUP.PATH;
      return $http.post(url, user);
    },

    isLoggedInUser: function () {
      var url = PrestoSdk.getHostName() + SessionConstants.LOGIN_STATUS.PATH;
      let resp = $http.get(url);
      console.log("resp", resp);
      return resp;
    },

    verifyOtp: function (params) {
      var url = PrestoSdk.getHostName() + "/users/verify_otp.json";
      return $http.post(url, params);
    },

    getOtp: function () {
      var url = PrestoSdk.getHostName() + "/users/get_otp.json";
      return $http.get(url);
    },

    linkAccount: function (params) {
      var url = PrestoSdk.getHostName() + "/linked_accounts.json";
      return $http.post(url, params);
    },

    verifyAccount: function (params) {
      var url = PrestoSdk.getHostName() + "/linked_accounts/verify.json";
      return $http.post(url, params);
    },

    startOtpLogin: function (params) {
      var url = PrestoSdk.getHostName() + "/users/start_otp_login.json";
      return $http.post(url, params);
    },

    completeOtpLogin: function (params) {
      var url = PrestoSdk.getHostName() + "/users/complete_otp_login.json";
      return $http.post(url, params);
    },

    removeAccount: function (params) {
      var url =
        PrestoSdk.getHostName() +
        "/linked_accounts/" +
        params.id +
        "/unlink.json";
      return $http.post(url);
    },

    switchAccount: function (params) {
      var url =
        PrestoSdk.getHostName() +
        "/linked_accounts/" +
        params.id +
        "/select.json";
      return $http.post(url);
    },

    operatorLogin: function (params) {
      var url = PrestoSdk.getHostName() + "/operators/sign_in.json";
      return $http.post(url, params);
    },

    operatorLogout: function () {
      var url = PrestoSdk.getHostName() + "/operators/sign_out.json";
      return $http.delete(url);
    },

    agentLogin: function (params) {
      var url = PrestoSdk.getHostName() + "/employees/sign_in.json";
      return $http.post(url, params);
    },

    agentLogout: function () {
      var url = PrestoSdk.getHostName() + "/employees/sign_out.json";
      return $http.delete(url);
    },

    adminLogin: function (params) {
      var url = PrestoSdk.getHostName() + "/merchants/sign_in.json";
      return $http.post(url, params);
    },

    adminLogout: function () {
      var url = PrestoSdk.getHostName() + "/merchants/sign_out.json";
      return $http.delete(url);
    },

    isMerchantLoggedIn: function () {
      var url = PrestoSdk.getHostName() + "/merchant.json";
      return $http.get(url);
    },
    getEmployee: function () {
      var url = PrestoSdk.getHostName() + "/employee.json";
      return $http.get(url);
    },

    updateEmployeeAvailabilityStatus: function (params) {
      var url =
        PrestoSdk.getHostName() + "/employees/" + params.employee.id + ".json";
      return $http.put(url, params);
    },

    updateEmployeeCitizenId: function (params) {
      var url =
        PrestoSdk.getHostName() +
        "/employee/v0/employees/update_citizen_user_id.json";
      return $http.post(url, params);
    },

    updateEmployeeLocation: function (params) {
      var url =
        PrestoSdk.getHostName() + "/employee/v0/employees/update_location.json";
      return $http.put(url, params);
    },

    getReferralCode: function (params) {
      var url = "https://ubureferral.herokuapp.com/keys?fp=" + btoa(params);
      return $http.get(url);
    },

    getEmployeeStatus: function () {
      var url = PrestoSdk.getHostName() + "/employee_status.json";
      return $http.get(url);
    },

    sendEmployeeLocation: function (params) {
      var url =
        PrestoSdk.getHostName() + "/employees/" + params.employee_id + ".json";
      return $http.put(url, params);
    },

    setEmployeeStatus: function (params) {
      var url =
        PrestoSdk.getHostName() +
        "/employee/v0/employees/" +
        params.employee.id +
        "/available.json";
      return $http.post(url, params);
    },

    getUsersByIds: function (params) {
      let str = Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join("&");

      var url = PrestoSdk.getHostName() + "/employee/v1/users.json?" + str;

      return $http.get(url);
    },

    isOperatorLoggedIn: function () {
      var url = PrestoSdk.getHostName() + SessionConstants.OPERATOR_STATUS.PATH;
      let resp = $http.get(url);
      console.log("resp", resp);
      return resp;
    },

    employeeOtpLogin: function (params) {
      let url =
        PrestoSdk.getHostName() + "/employee/v1/employees/start_otp_login.json";
      return $http.post(url, params);
    },

    employeeVerifyOtpLogin: function (params) {
      let url =
        PrestoSdk.getHostName() +
        "/employee/v1/employees/complete_otp_login.json";
      return $http.post(url, params);
    },

    operatorOtpLogin: function (params) {
      let url = PrestoSdk.getHostName() + "/operator/v1/start_otp_login.json";
      return $http.post(url, params);
    },

    operatorVerifyOtpLogin: function (params) {
      let url =
        PrestoSdk.getHostName() + "/operator/v1/complete_otp_login.json";
      return $http.post(url, params);
    },
  };
};

export default SessionResource($http, SessionConstants, PrestoSdk);
