import Utils from "../common/Utils";
import $ from "../global/util";

const DeliverySlotObject = function (Utils) {
  function DeliverySlotObject() {
    this.end_hour = 0;
    this.end_minute = 0;
    this.id = "";
    this.name = "";
    this.other_data = {};
    this.start_hour = 0;
    this.start_minute = 0;
    this.active_lead_time = 0;
  }
  DeliverySlotObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawDeliverySlotObject) {
      this.end_hour = rawDeliverySlotObject.end_hour;
      this.end_minute = rawDeliverySlotObject.end_minute;
      this.id = rawDeliverySlotObject.id;
      this.name = rawDeliverySlotObject.name;
      this.other_data = rawDeliverySlotObject.other_data;
      this.start_hour = rawDeliverySlotObject.start_hour;
      this.start_minute = rawDeliverySlotObject.start_minute;
      this.active_lead_time = rawDeliverySlotObject.active_lead_time;
    },
  };
  return DeliverySlotObject;
};

export default DeliverySlotObject(Utils);
