import React, { useContext, useEffect, useState } from "react";
import {
  View,
  FlatList,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
  Pressable,
  Platform,
  Alert,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartContext from "@presto-contexts/CartContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import OrderManager from "@presto-services/features/orders/OrderManager";
import TrackOrderCard from "@presto-cards/TrackOrderCard/TrackOrderCard";
import LastOrderCard from "@presto-cards/LastOrderCard/LastOrderCard";
import UpcomingOrderButton from "@presto-screen-components/OrderList/UpcomingOrderButton";
import PastOrderButton from "@presto-screen-components/OrderList/PastOrderButton";
import _ from "lodash";
import { usePagination } from "../../hooks/appointmentPagination";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import TrackOrderModel from "@presto-component-models/TrackOrderModel";

export default function OrdersListScreen() {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { reOrder } = useContext(CartContext);
  const [reOrdering, setReOrdering] = useState([]);
  const [currentActive, setCurrentState] = useState("upcoming");
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const styles = getStyles(theme, windowWidth);
  const flatListRef = React.useRef();

  const loadOrders = (lastItem, success, failure) => {
    const lastTransaction = lastItem;
    const params = lastTransaction
      ? { oldest_time: `${lastTransaction.created_at}` }
      : {};

    OrderManager.getMyOrders(
      params,
      (response) => {
        console.log("My Orders : ", JSON.stringify(response.data, null, 2));
        const filteredOrders = _.filter(response.data.orders, (order) => {
          const firstItem = _.first(order.items);
          return (
            firstItem?.name !== "Wallet Recharge Pack" &&
            order.status !== "CREATED"
          );
        });
        success(filteredOrders);
      },
      (error) => {
        failure(error);
      }
    );
  };

  const [
    orders,
    loadNextPage,
    loadFirstPage,
    loading,
    pageLoaded,
  ] = usePagination(loadOrders, undefined);

  useEffect(() => {
    loadFirstPage();
  }, []);

  const onViewOrderClicked = (order) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "TRACK_ORDER_SCREEN",
        screenParams: { orderId: order.id },
      },
    });
  };

  const onTrackOrderClicked = (order) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "TRACK_ORDER_SCREEN",
        screenParams: {
          orderId: order.id,
        },
      },
    });
  };

  const onReOrderClicked = async (order) => {
    setReOrdering([...reOrdering, order.id]);
    const [error] = await reOrder(order);
    if (error === "error_adding_items") {
      if (Platform.OS === "web") {
        alert("Few items could not be added as they are not available");
      } else {
        Alert.alert(
          "Alert",
          "Few items could not be added as they are not available"
        );
      }
    }
    Navigator.emit({ event: "closeDrawer" });
    Navigator.emit({ event: "goBack" });
    setReOrdering(_.filter(reOrdering, (id) => id !== order.id));
  };

  const pastOrders = _.filter(orders, (order) => {
    return ["REJECTED", "DELIVERED"].includes(order.status);
  });
  const undeliveredOrders = _.filter(orders, (o) =>
    ["RECEIVED", "DISPATCHED", "ACCEPTED"].includes(o.status)
  );

  const _isActive = (str) => {
    return str === currentActive;
  };

  const _onSetActive = (str) => {
    setCurrentState(str);
  };

  const renderOrder = ({ item }) => {
    if (item && item.status !== "DELIVERED" && currentActive == "upcoming") {
      return (
        <View style={{ width: "100%" }}>
          <View
            style={{
              paddingHorizontal: theme.containerPadding - 5,
              paddingVertical: theme.containerPadding - 5,
            }}
          >
            <TrackOrderCard
              order={new TrackOrderModel({ order: item })}
              onTrackOrderPressed={() => onTrackOrderClicked(item)}
            />
          </View>
        </View>
      );
    } else if (currentActive == "past") {
      const isBeingReordered =
        _.findIndex(reOrdering, (a) => a === item.id) !== -1;

      return (
        <View style={{ width: "100%" }}>
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
              paddingVertical: theme.containerPadding - 5,
            }}
          >
            <LastOrderCard
              reOrdering={isBeingReordered}
              onReOrderPressed={() => onReOrderClicked(item)}
              onViewOrderPressed={() => onViewOrderClicked(item)}
              order={new TrackOrderModel({ order: item })}
            />
          </View>
        </View>
      );
    }
  };

  const onEndReached = () => {
    console.log("On End Reached");
    if (
      (undeliveredOrders && undeliveredOrders.length > 0) ||
      (pastOrders && pastOrders.length > 0)
    ) {
      loadNextPage();
    }
  };

  return (
    <LoadingContainer loading={loading && pageLoaded == 0}>
      <View
        style={[
          styles.container,
          {
            marginTop: isDesktop ? 20 : 10,
            overflow: "hidden",
          },
        ]}
      >
        <Pressable
          style={{ flex: 1 }}
          onPress={() => {
            flatListRef.current.scrollToOffset({ animated: false, offset: 0 });
            _onSetActive("upcoming");
          }}
        >
          <UpcomingOrderButton _isActive={_isActive} />
        </Pressable>
        <Pressable
          style={{ flex: 1 }}
          onPress={() => {
            flatListRef.current.scrollToOffset({ animated: false, offset: 0 });
            _onSetActive("past");
          }}
        >
          <PastOrderButton _isActive={_isActive} />
        </Pressable>
      </View>

      <View style={{ flex: 1, paddingTop: 10 }}>
        {undeliveredOrders &&
        undeliveredOrders.length == 0 &&
        currentActive == "upcoming" ? (
          <PrestoText fontStyle="400.normal" extraStyle={[styles.noItems]}>
            {I18n.t("screen_messages.orders.no_upcoming_orders_title")}
          </PrestoText>
        ) : null}
        {pastOrders && pastOrders.length == 0 && currentActive == "past" ? (
          <PrestoText fontStyle="400.normal" extraStyle={[styles.noItems]}>
            {I18n.t("screen_messages.orders.no_past_orders_title")}
          </PrestoText>
        ) : null}

        <FlatList
          ref={flatListRef}
          data={currentActive == "upcoming" ? undeliveredOrders : pastOrders}
          renderItem={renderOrder}
          style={{ width: "100%" }}
          keyExtractor={(item, index) => `${index}`}
          ListFooterComponent={() => (
            <View style={{ height: 20, backgroundColor: "transparent" }}></View>
          )}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.3}
        />
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme, windowWidth) {
  let minusWidth = theme.primaryPadding / 2;
  return {
    noItems: {
      fontSize: 14,
      fontWeight: "400",
      paddingTop: 15,
      paddingHorizontal: theme.containerPadding,
      color: theme.secondary,
    },
    container: {
      flexDirection: "row",
      backgroundColor: "#EDEDED",
      borderRadius: 30,
      marginHorizontal: theme.primaryPadding,
    },
    buttonContainer: {
      paddingVertical: 12,
      height: 40,
      width: windowWidth / 2 - minusWidth,
      alignItems: "center",
      borderRadius: 30,
    },
  };
}
