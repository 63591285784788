import React, { useEffect } from "react";
import { View } from "react-native";
import { RadioButton } from "react-native-paper";

export default function RadioButtonGroup(props) {
  const { theme, onPress } = props;
  const [value, setValue] = React.useState(props.value);
  const setOption = (newValue) => {
    setValue(newValue);
    onPress(newValue);
  };
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <View>
      <RadioButton.Group
        onValueChange={(newValue) => setOption(newValue)}
        value={value}
      >
        {props.children}
      </RadioButton.Group>
    </View>
  );
}
