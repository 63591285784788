import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import StylesContext from "@presto-contexts/StylesContext";
import UserContext from "@presto-contexts/UserContext";
import TopHeader from "@presto-components/Headers/TopHeaderWithLogoAndBack";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useAssets } from "presto-react-components";
import { Platform } from "react-native";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import utils from "../../utils/index";

export default function TopDrawerHeaderWithLogo({
  hideSearch,
  showCart,
  showWallet,
  extraStyle,
}) {
  const { svgs } = useAssets();
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const { appStyles } = useContext(StylesContext);
  const { user } = useContext(UserContext);
  const { setPaneState } = useContext(RightPaneContext);
  const isMobileWeb = utils.isMobileWeb();
  const isDesktop = utils.isDesktop();

  const onBack = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  const goToSearch = () => {
    Navigator.emit({
      event: "modal",
      params: {
        nextUrl: "/search",
        screenName: "SEARCH",
      },
    });
  };

  const onLogoClick = () => {
    if (isMobileWeb) {
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/",
        },
      });
    } else if (isDesktop) {
      window.location.href = "/";
    }
  };
  const goToWallet = () => {
    if (!user) {
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/login",
          screenName: "LOGIN",
        },
      });
      return;
    }
    Navigator.emit({
      event: "modal",
      params: {
        nextUrl: "/wallet",
        screenName: "WALLET_SCREEN",
      },
    });
  };

  const goToCart = () => {
    if (!user) {
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/login",
          screenName: "LOGIN",
        },
      });
      return;
    } else {
      setPaneState(true);
      Navigator.emit({
        event: "modal",
        params: {
          nextUrl: "/cart",
          screenName: "CART",
        },
      });
    }
  };

  return (
    <TopHeader
      appStyles={appStyles}
      theme={theme}
      onBack={onBack}
      onLogoClick={onLogoClick}
      rightIcon2={<svgs.LineWalletIcon width="36" height="36" />}
      rightIcon1={!hideSearch ? <svgs.SearchIconV2 /> : undefined}
      onPressRightIcon2={goToWallet}
      onPressRightIcon1={goToSearch}
      onPressCartIcon={goToCart}
      showWallet={showWallet}
      showCart={showCart}
      showCartIcon={<svgs.CartIcon1 width="36" height="36" />}
      extraStyle={extraStyle}
    />
  );
}
