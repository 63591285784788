import * as React from "react";
import Svg, { G, Rect, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <G filter="url(#prefix__filter0_dd)">
        <Rect
          x={8}
          y={4}
          width={20}
          height={20}
          rx={10}
          fill={props.primaryColor || "#1C1649"}
        />
      </G>
      <Path
        d="M21.81 11.143L16.57 16.38 14.191 14"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Defs></Defs>
    </Svg>
  );
}

export default SvgComponent;
