import React, { useContext } from "react";
import { View } from "react-native";
import BaseItemCard from "@presto-cards/ItemCards/BaseItemCard";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function SoldOutItemCard(props) {
  const { onNotifyMe, variation } = props;
  const { theme } = useContext(ThemeContext);
  const NotifyButton = () => {
    return (
      <View style={{ width: 100 }}>
        <PrestoSolidButton
          buttonStyle="primary"
          size={"large"}
          bgColor={theme.primaryLightColor}
          borderWidth={1}
          borderColor={theme.primary}
          titleColor={theme.primary}
          title={I18n.t("screen_messages.stocks.out_of_stock")}
          titleExtraStyle={{
            textTransform: "none",
          }}
          onPress={() => {}}
        />
      </View>
    );
  };

  return (
    <BaseItemCard
      {...props}
      right={<NotifyButton />}
      containerStyle={{ opacity: 0.4 }}
    />
  );
}
